import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ImportJobDetails } from '../../../models/import';

@Component({
  selector: 'cube-failed-import-dialog',
  templateUrl: './failed-import-dialog.component.html',
  styleUrls: ['./failed-import-dialog.component.scss'],
})
export class FailedImportDialogComponent {
  jobDetails: ImportJobDetails;

  constructor(@Inject(MAT_DIALOG_DATA) private matDialogData: any) {
    this.jobDetails = this.matDialogData.jobDetails;
  }
}
