import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ConfirmationDialogData } from '../../../models/confirmation-dialog-data.dto';

@Component({
  selector: 'cube-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  confirmationData: ConfirmationDialogData;

  constructor(@Inject(MAT_DIALOG_DATA) private matDialogData: any) {
    this.confirmationData = {
      ...this.matDialogData,
    };
  }
}
