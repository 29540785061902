import { NgxLoggerLevel } from 'ngx-logger';
import { EnvironmentConfiguration } from '../app/models/environment-configuration';

export const PROFILE_NOT_VALID = new Error('PROFILE NOT VALID');
export let TENANT_CONFIG: EnvironmentConfiguration = {
  settings: {
    routes: [],
  },
};

export const LOGGER_CONFIG = {
  serverLoggingUrl: '/gateway/logs',
  level: NgxLoggerLevel.WARN,
  serverLogLevel: NgxLoggerLevel.ERROR,
  enableSourceMaps: true,
};

export const MAX_SELECTABLE_SHIPS = 20;

export const DATE_FORMAT = 'MMM D, YYYY - HH:mm';

export const SHIP_COLOR_SETTINGS = {
  label_ship_status: 'status',
  STATUS_COLORS: {
    default: 'green',
  },
  VESSELS_COLORS: {
    default: './assets/vessels/arrow_green.svg',
  },
};

export function changeConfig(configData: any): void {
  TENANT_CONFIG = configData;
}
export function addConfig(tag: string, content: any): void {
  TENANT_CONFIG[tag] = content;
}

export const AUTH_CONFIG = {
  refreshing_time: 5000,
  token_lifespan: 60,
};

export const TIMEOUTS_CONFIG = {
  render_delay: 400,
  refreshing_time: '7s',
  warn_timeout: 5000,
  error_timeout: 10000,
  snackbar_duration: 1500,
  snackbar_error_duration: 3000,
  es_reindex_duration: 2000,
};

export const MAP_CONFIGURATIONS = {
  mapbox: {
    accessToken:
      'pk.eyJ1IjoiYWxibXUiLCJhIjoiY2p0eTN3emR5MGR1bDN5cjN3b2pvOW1pNSJ9.aB49CLoKOHwwUsnIPoFJgg',
    url: 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
  },
  navionics: {
    enabled: !TENANT_CONFIG.settings.onboard,
    accessToken: 'Navionics_webapi_03199',
  },
  tidetech: {
    enabled: true,
    protocol: 'https://',
    domain: 'wmts.tidetech.org',
    version: '1.0.0',
    key: 'ZVZvU3hURVZwcDhrTU1IcGpGbnQ6NjU3Q2o1Q0tsT0RKOWRLWFF3WWlVSzhXQ1dLdGNQaENzRUpPZFo4UExORFVrUzlMOFRRY0xhYWxwc3RU',
  },
};

export const MAP_FILTERS = {
  syncAP: true,
  fleets: {
    values: [],
  },
};
