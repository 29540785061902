<!-- Close Button -->
<button
  class="close-icon"
  tabindex="-1"
  type="button"
  mat-dialog-close
  mat-icon-button
>
  <mat-icon>close</mat-icon>
</button>

<!-- Content -->
<mat-dialog-content>
  <div class="dialog-form">
    <p>
      <span>
        {{
          (jobDetails.reportUrl === reportErrors.noSuccessfulInspection
            ? "failed_report_dialog.no_successful_inspection"
            : "failed_report_dialog.report_download_failed"
          ) | translate
        }}
      </span>
    </p>

    <div class="report-details">
      <ng-container [ngSwitch]="reportMode">
        <!-- Single Report - Codice IOP -->
        <p *ngSwitchCase="reportModeConfig.single">
          <span>{{ "failed_report_dialog.codice_iop" | translate }}: </span>
          {{ jobDetails.codiceIop }}
        </p>

        <!-- Periodic Report - Period -->
        <p *ngSwitchCase="reportModeConfig.periodic">
          <span>{{ "failed_report_dialog.period" | translate }}: </span>
          {{ jobDetails.quarter + "/" + jobDetails.year }}
        </p>

        <!-- IOP Request CSV Report - Created Date -->
        <p *ngSwitchCase="reportModeConfig.iop_request">
          <span>{{ "failed_report_dialog.created_date" | translate }}: </span>
          {{ jobDetails.createdDate | date : "dd-MM-yyyy hh:mm a" }}
        </p>
      </ng-container>

      <!-- Report Name -->
      <p>
        <span>{{ "failed_report_dialog.report_name" | translate }}: </span>
        {{ jobDetails.reportName | translate }}
      </p>

      <!-- Failed Reason -->
      <p *ngIf="reportMode === reportModeConfig.single">
        <span>{{ "failed_report_dialog.failed_reason" | translate }}: </span>
        {{ failedReason | translate }}
      </p>
    </div>
  </div>
</mat-dialog-content>
