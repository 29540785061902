import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'cube-note-dialog',
  templateUrl: './note-dialog.component.html',
  styleUrls: ['./note-dialog.component.scss'],
})
export class NoteDialogComponent {
  disabled: boolean = false;
  note: string;
  title: string;

  constructor(@Inject(MAT_DIALOG_DATA) private matDialogData: any) {
    this.disabled = this.matDialogData.disabled;
    this.note = this.matDialogData.note;
    this.title = this.matDialogData.title;
  }
}
