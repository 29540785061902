import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { environment } from '../../../../environments/environment';
import { Response } from '../../../models/api';
import { ReportJobDetails } from '../../../models/report';
import { InspectionQuarterPlan } from '../../../models/inspection';
import { DownloadReportDialogComponent } from '../../../shared/components/download-report-dialog/download-report-dialog.component';
import { FailedReportDialogComponent } from '../../../shared/components/failed-report-dialog/failed-report-dialog.component';
import { RequestReportDialogComponent } from '../../../shared/components/request-report-dialog/request-report-dialog.component';
import { AppFeature } from '../../../shared/configs/app-feature.config';
import { ReportErrors } from '../../../shared/configs/report-errors.config';
import { ReportMode } from '../../../shared/configs/report-mode.config';
import { ReportType } from '../../../shared/configs/report-type.config';
import { ReportWorkerMessageType } from '../../../shared/configs/report-worker-message-type.config';
import { CubeService } from '../../cube/cube.service';
import { UserService } from '../../user/user.service';
import { API } from '../api.const';
import { ApiService } from '../api.service';

import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReportService extends ApiService implements OnDestroy {
  endpoint = API.REPORT;

  private destroy$: Subject<void> = new Subject<void>();
  private reportStatusUpdated$: Subject<Partial<ReportJobDetails>> =
    new Subject<Partial<ReportJobDetails>>();
  private requestReportDialogRef?: MatDialogRef<RequestReportDialogComponent>;
  private reportWebWorker: Worker;

  constructor(
    public httpClient: HttpClient,
    public logger: NGXLogger,
    public snackBar: MatSnackBar,
    public translateService: TranslateService,
    private cubeService: CubeService,
    private matDialog: MatDialog,
    private userService: UserService
  ) {
    super(httpClient, logger, snackBar, translateService);
    this.createReportWebWorker();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addAllOngoingReportJobsToWorker(): void {
    this.reportWebWorker.postMessage({
      type: ReportWorkerMessageType.addAllOngoingReportJobs,
    });
  }

  addJobToReportWebWorker(jobDetails: Partial<ReportJobDetails>): void {
    if (!this.reportWebWorker) this.createReportWebWorker();

    // Adding new job to worker
    this.reportWebWorker.postMessage({
      jobDetails,
      type: ReportWorkerMessageType.addJob,
    });
  }

  getExcelOutputReport(filters: any): Observable<any> {
    return this.get<Response>({
      metadata: { ...this.getHttpParams(filters) },
      operation: 'getExcelOutputReport',
      url: this.endpoint.getExcelOutputReport(),
    });
  }

  getJobsList(reportMode: ReportMode): Observable<Response<any[]>> {
    return this.get<Response<any[]>>({
      metadata: { ...this.getHttpParams({ report_mode: reportMode }) },
      operation: 'getJobList',
      url: this.endpoint.getJobList(),
    });
  }

  generateIopRequestCsvReport(filters: any): Observable<any> {
    return this.get<Response<any>>({
      metadata: { ...this.getHttpParams(filters) },
      operation: 'generateIopRequestCsvReport',
      url: this.endpoint.iopGenerateCSV(),
    });
  }

  generateMultiplePdfReport(filters: any): Observable<any> {
    return this.get<Response<any>>({
      metadata: { ...this.getHttpParams(filters) },
      operation: 'generateMultiplePdfReport',
      url: this.endpoint.generateMultiplePdfReport(),
    });
  }

  generatePhotosZip(formData: any): Observable<any> {
    return this.post<Response<any>>({
      body: formData,
      operation: 'generatePhotosZip',
      url: this.endpoint.defectImageZipFileDownload(),
    });
  }

  generateSinglePdfReport(filters: any): Observable<any> {
    return this.get<Response<any>>({
      metadata: { ...this.getHttpParams(filters) },
      operation: 'generateSinglePdfReport',
      url: this.endpoint.generateSinglePdfReport(),
    });
  }

  getInspectionQuarterPlans(
    filters: any
  ): Observable<Response<InspectionQuarterPlan[]>> {
    return this.get<Response<InspectionQuarterPlan[]>>({
      metadata: { ...this.getHttpParams(filters) },
      operation: 'getInspectionQuarterPlans',
      url: this.endpoint.getInspectionQuarterPlans(),
    });
  }

  getQuarterlyAndOperationsReport(isCustomer: '1' | '0'): Observable<Response> {
    return this.get<Response>({
      metadata: { ...this.getHttpParams({ is_customer: isCustomer }) },
      operation: 'getQuarterlyAndOperationsReport',
      url: this.endpoint.getQuarterlyAndOperationReport(),
    });
  }

  getReportMode(reportType: ReportType): ReportMode {
    switch (reportType) {
      case ReportType.defectImageZipFile:
      case ReportType.output2Inspection:
      case ReportType.output2InspectionL0:
      case ReportType.output2InspectionL0TravatePonti:
      case ReportType.output2InspectionL1:
      case ReportType.output2InspectionL1FraneIdraulici:
      case ReportType.output2Defect:
      case ReportType.output5:
      case ReportType.output6:
        return ReportMode.single;

      case ReportType.iopRequest:
        return ReportMode.iop_request;

      case ReportType.output1:
      case ReportType.output3Inspection:
      case ReportType.output3Defect:
      case ReportType.output4:
        return ReportMode.periodic;
    }
  }

  getReportName(reportType: ReportType): string {
    switch (reportType) {
      case ReportType.defectImageZipFile:
        return 'report_names.download_photos_zip';

      case ReportType.iopRequest:
        return 'report_names.iop_request';

      case ReportType.output1:
        return 'report_names.tabellone_difetti';

      case ReportType.output2Inspection:
      case ReportType.output3Inspection:
        return 'report_names.ispezioni_linee_guida';

      case ReportType.output2InspectionL0:
        return 'report_names.scheda_di_censimento';

      case ReportType.output2InspectionL0TravatePonti:
        return 'report_names.allegato_d';

      case ReportType.output2InspectionL1:
        return 'report_names.scheda_descrittiva_di_ispezione';

      case ReportType.output2InspectionL1FraneIdraulici:
        return 'report_names.scheda_fenomeni_frana_e_fenomeni_idraulici';

      case ReportType.output2Defect:
      case ReportType.output3Defect:
        return 'report_names.scheda_difettologica';

      case ReportType.output4:
      case ReportType.output5:
        return 'report_names.ispezioni_altro_formato';

      case ReportType.output6:
        return 'report_names.scheda_livello_classi_di_attenzione';

      default:
        return '';
    }
  }

  getReportStatusUpdated$(): Observable<Partial<ReportJobDetails>> {
    return this.reportStatusUpdated$.asObservable();
  }

  getSingleReportErrorMessage(reportError: ReportErrors): string {
    switch (reportError) {
      case ReportErrors.errorInGeneratingImage:
        return 'report_errors.error_in_generating_image';

      case ReportErrors.errorInZipGeneration:
        return 'report_errors.errorInZipGeneration';

      case ReportErrors.fileNotFound:
        return 'report_errors.file_not_found';

      case ReportErrors.noInspectionFileInBlob:
        return 'report_errors.no_inspection_file_in_blob';

      case ReportErrors.noOutputTemplate:
      case ReportErrors.noOutputFileInBlob:
        return 'report_errors.no_output_template';

      case ReportErrors.noInspectionData:
        return 'report_errors.no_data_available_single';

      case ReportErrors.errorInService:
      default:
        return 'report_errors.unexpected_error';
    }
  }

  openDownloadReportDialog(
    jobDetails: Partial<ReportJobDetails>,
    token?: string
  ): void {
    this.requestReportDialogRef?.close();

    switch (true) {
      // Single PDF - No data error
      case [
        ReportType.output2InspectionL0TravatePonti,
        ReportType.output2InspectionL1,
        ReportType.output2InspectionL1FraneIdraulici,
        ReportType.output2Defect,
        ReportType.output5,
        ReportType.output6,
      ].includes(jobDetails.reportType) &&
        jobDetails.reportUrl === ReportErrors.noInspectionData:
        this.cubeService.confirm({
          isCancelButtonVisible: false,
          message: 'report_errors.no_data_available_single',
          title: 'common.info',
        });
        this.setReportStatusUpdated$(jobDetails);

        break;

      // Multiple PDF - No data error
      case [
        ReportType.output1,
        ReportType.output3Inspection,
        ReportType.output3Defect,
        ReportType.output4,
      ].includes(jobDetails.reportType) &&
        jobDetails.reportUrl === ReportErrors.noInspectionData:
        this.cubeService.confirm({
          isCancelButtonVisible: false,
          message: 'report_errors.no_data_available_multiple',
          title: 'common.info',
        });
        this.setReportStatusUpdated$(jobDetails);

        break;

      // IOP Request CSV Report - No data error // TODO is this needed???
      case jobDetails.reportType === ReportType.iopRequest &&
        jobDetails.reportUrl === ReportErrors.noInspectionData:
        this.cubeService.confirm({
          isCancelButtonVisible: false,
          message: 'report_errors.no_data_available_iop_request',
          title: 'common.info',
        });
        this.setReportStatusUpdated$(jobDetails);

        break;

      // Defect Image Zip - No images error
      case jobDetails.reportType === ReportType.defectImageZipFile &&
        jobDetails.reportUrl === ReportErrors.noInspectionImages:
        this.cubeService.confirm({
          isCancelButtonVisible: false,
          message: 'report_errors.no_inspection_images',
          title: 'common.info',
        });
        this.setReportStatusUpdated$(jobDetails);

        break;

      // Opening from Report Status page
      case token != null:
        // Download report
        const encodedUrl = new URL(`${jobDetails.reportUrl}?${token}`);
        window.open(encodedUrl.toString());

        break;

      default:
        // Show download dialog if no errors are present
        this.matDialog.open(DownloadReportDialogComponent, {
          data: { jobDetails },
          disableClose: true,
        });
        this.setReportStatusUpdated$(jobDetails);

        break;
    }
  }

  openReportDialog(jobDetails: Partial<ReportJobDetails>): void {
    // If URL is present open download dialog
    if (jobDetails.reportUrl) {
      this.openDownloadReportDialog(jobDetails);

      return;
    }

    // If new job is created open request report dialog
    this.requestReportDialogRef = this.matDialog.open(
      RequestReportDialogComponent,
      {
        data: { jobDetails },
        disableClose: true,
      }
    );

    this.requestReportDialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        delete this.requestReportDialogRef;
      });
  }

  setReportStatusUpdated$(jobDetails: Partial<ReportJobDetails>): void {
    this.reportStatusUpdated$.next(jobDetails);
  }

  private createReportWebWorker(): void {
    this.reportWebWorker = new Worker(
      new URL('../../../report.worker.ts', import.meta.url)
    );

    // Handling messages from from web worker
    this.reportWebWorker.onmessage = ({ data }) => {
      const { jobDetails } = data;

      switch (data.type) {
        case ReportWorkerMessageType.reportCompleted:
          this.openDownloadReportDialog(jobDetails);

          break;

        case ReportWorkerMessageType.reportFailed:
          this.requestReportDialogRef?.close();
          this.matDialog.open(FailedReportDialogComponent, {
            data: { jobDetails },
            disableClose: true,
          });
          this.setReportStatusUpdated$(jobDetails);

          break;

        case ReportWorkerMessageType.reportOngoing:
          this.setReportStatusUpdated$(jobDetails);

          break;

        case ReportWorkerMessageType.workerClosed:
          delete this.reportWebWorker;

          break;

        default:
          break;
      }
    };

    const apiUrls: Record<string, string> = {
      checkReportStatus: this.endpoint.checkReportStatus(''),
    };

    // Check if the user has the feature for single reports
    if (
      this.userService.isUserAllowedByFeature([AppFeature.portalInspectionList])
    ) {
      // Add the single reports API URL to the array
      apiUrls.getSingleJobsList = `${this.endpoint.getJobList()}?report_mode=${
        ReportMode.single
      }`;
    }

    // Check if the user has the feature for periodic reports
    if (
      this.userService.isUserAllowedByFeature([
        AppFeature.portalStatisticPeriodicReport,
      ])
    ) {
      // Add the periodic reports API URL to the array
      apiUrls.getPeriodicJobsList = `${this.endpoint.getJobList()}?report_mode=${
        ReportMode.periodic
      }`;
    }

    // Check if the user has the feature for iop request csv reports
    if (
      this.userService.isUserAllowedByFeature(
        // TODO Updated with correct feature
        // [AppFeature.portalIopRequest]
        [AppFeature.portalHome]
      )
    ) {
      // Add the iop request csv reports API URL to the array
      apiUrls.getIopRequestJobsList = `${this.endpoint.getJobList()}?report_mode=${
        ReportMode.iop_request
      }`;
    }

    // Initializing worker values
    this.reportWebWorker.postMessage({
      ...this.cubeService.getWorkerHeaders(),
      apiUrls,
      reportWorkerInterval: environment.reportWorkerInterval,
      type: ReportWorkerMessageType.workerInitialization,
      username: this.userService.getUser().username,
    });

    // Continuously pass token into web worker
    this.cubeService
      .token()
      .pipe(
        takeWhile(() => !!this.reportWebWorker),
        takeUntil(this.destroy$)
      )
      .subscribe((token) => {
        this.reportWebWorker.postMessage({
          token,
          type: ReportWorkerMessageType.tokenUpdated,
        });
      });
  }
}
