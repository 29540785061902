export enum ReportType {
  defectImageZipFile = 'defectImageZipFile',
  iopRequest = 'iopRequest',
  output1 = 'output1',
  output2Defect = 'output2Defect',
  output2Inspection = 'output2Inspection',
  output2InspectionL0 = 'output2InspectionL0',
  output2InspectionL0TravatePonti = 'output2InspectionL0TravatePonti',
  output2InspectionL1 = 'output2InspectionL1',
  output2InspectionL1FraneIdraulici = 'output2InspectionL1FraneIdraulici',
  output3Defect = 'output3Defect',
  output3Inspection = 'output3Inspection',
  output4 = 'output4',
  output5 = 'output5',
  output6 = 'output6',
}
