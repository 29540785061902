import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private loader$: BehaviorSubject<boolean>;
  private reqCount: number = 0;

  constructor() {
    this.loader$ = new BehaviorSubject<boolean>(false);
  }

  getLoader$(): Observable<boolean> {
    return this.loader$.asObservable();
  }

  requestEnded(): void {
    this.reqCount -= 1;

    if (this.reqCount === 0) this.loader$.next(false);
  }

  requestStarted(): void {
    this.reqCount++;
    this.loader$.next(true);
  }
}
