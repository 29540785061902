<!-- Back Button -->
<button
  class="close-icon"
  tabindex="-1"
  type="button"
  mat-icon-button
  (click)="uploadStatus = false"
  *ngIf="uploadStatus"
>
  <mat-icon>arrow_back</mat-icon>
</button>

<!-- Close Button -->
<button
  tabindex="-1"
  type="button"
  mat-icon-button
  [ngClass]="uploadStatus ? 'close-icon' : 'close-icon-right-align'"
  (click)="onClickClose()"
  *ngIf="!uploadStatus"
>
  <mat-icon>close</mat-icon>
</button>

<!-- Title -->
<h5 mat-dialog-title>Localizzazione</h5>

<mat-dialog-content>
  <form
    class="dialog-form"
    [formGroup]="localizzazioneForm"
    (ngSubmit)="onSubmit()"
    *ngIf="!uploadStatus; else defectsDialog"
  >
    <table aria-describedby="localizzazione table">
      <!-- Headings -->
      <thead>
        <tr>
          <!-- Localizzazione -->
          <th colspan="3" id="Localizzazione L" rowspan="2">
            Localizzazione (L)
          </th>

          <!-- Sezione Trasversale -->
          <th colspan="7" id="Sezione Trasversale">Sezione Trasversale</th>

          <!-- N° foto -->
          <th rowspan="2" id="N° foto">N° foto</th>

          <!-- Note -->
          <th rowspan="2" id="Note">Note</th>
        </tr>

        <tr>
          <!-- PS -->
          <th class="checkbox" id="PS">PS</th>

          <!-- RS -->
          <th class="checkbox" id="RS">RS</th>

          <!-- RS1 -->
          <th class="checkbox" id="RS1">RS1</th>

          <!-- CH -->
          <th class="checkbox" id="CH">CH</th>

          <!-- RD1 -->
          <th class="checkbox" id="RD1">RD1</th>

          <!-- RD -->
          <th class="checkbox" id="RD">RD</th>

          <!-- PD -->
          <th class="checkbox" id="PD">PD</th>
        </tr>
      </thead>

      <!-- Body -->
      <tbody>
        <!-- (0 - 5) m -->
        <tr formGroupName="sezioneLongitudinale1">
          <!-- Sezione Longitudinale -->
          <td rowspan="4">Sezione Longitudinale</td>

          <!-- Section No. -->
          <td>1</td>

          <!-- Section -->
          <td>(0 - 5) m</td>

          <!-- Sezione Trasversale Checkboxes -->
          <ng-container
            [ngTemplateOutlet]="tableCheckboxSection"
            [ngTemplateOutletContext]="{
              formGroup: localizzazioneForm,
              formGroupName: 'sezioneLongitudinale1'
            }"
          ></ng-container>

          <!-- N° foto -->
          <td
            class="n-foto-center-align"
            (click)="onClickUpload('sezioneLongitudinale1')"
          >
            {{ fileCount1 }}
          </td>

          <!-- Note -->
          <td class="note-field">
            <cube-defect-table-note-renderer
              [localizzazioneKey]="'sezioneLongitudinale1'"
              [localizzazioneParams]="params"
              (noteDialogClosed)="
                onNoteDialogClosed($event, 'sezioneLongitudinale1')
              "
            ></cube-defect-table-note-renderer>
          </td>
        </tr>

        <!-- (5 - 10) m -->
        <tr formGroupName="sezioneLongitudinale2">
          <!-- Section No. -->
          <td>2</td>

          <!-- Section -->
          <td>(5 - 10) m</td>

          <!-- Sezione Trasversale Checkboxes -->
          <ng-container
            [ngTemplateOutlet]="tableCheckboxSection"
            [ngTemplateOutletContext]="{
              formGroup: localizzazioneForm,
              formGroupName: 'sezioneLongitudinale2'
            }"
          ></ng-container>

          <!-- N° foto -->
          <td
            class="n-foto-center-align"
            (click)="onClickUpload('sezioneLongitudinale2')"
          >
            {{ fileCount2 }}
          </td>

          <!-- Note -->
          <td class="note-field">
            <cube-defect-table-note-renderer
              [localizzazioneKey]="'sezioneLongitudinale2'"
              [localizzazioneParams]="params"
              (noteDialogClosed)="
                onNoteDialogClosed($event, 'sezioneLongitudinale2')
              "
            ></cube-defect-table-note-renderer>
          </td>
        </tr>

        <!-- (10 - 15) m -->
        <tr formGroupName="sezioneLongitudinale3">
          <!-- Section No. -->
          <td>3</td>

          <!-- Section -->
          <td>(10 - 15) m</td>

          <!-- Sezione Trasversale Checkboxes -->
          <ng-container
            [ngTemplateOutlet]="tableCheckboxSection"
            [ngTemplateOutletContext]="{
              formGroup: localizzazioneForm,
              formGroupName: 'sezioneLongitudinale3'
            }"
          ></ng-container>

          <!-- N° foto -->
          <td
            class="n-foto-center-align"
            (click)="onClickUpload('sezioneLongitudinale3')"
          >
            {{ fileCount3 }}
          </td>

          <!-- Note -->
          <td class="note-field">
            <cube-defect-table-note-renderer
              [localizzazioneKey]="'sezioneLongitudinale3'"
              [localizzazioneParams]="params"
              (noteDialogClosed)="
                onNoteDialogClosed($event, 'sezioneLongitudinale3')
              "
            ></cube-defect-table-note-renderer>
          </td>
        </tr>

        <!-- (15 - 20) m -->
        <tr formGroupName="sezioneLongitudinale4">
          <!-- Section No. -->
          <td>4</td>

          <!-- Section -->
          <td>(15 - 20) m</td>

          <!-- Sezione Trasversale Checkboxes -->
          <ng-container
            [ngTemplateOutlet]="tableCheckboxSection"
            [ngTemplateOutletContext]="{
              formGroup: localizzazioneForm,
              formGroupName: 'sezioneLongitudinale4'
            }"
          ></ng-container>

          <!-- N° foto -->
          <td
            class="n-foto-center-align"
            (click)="onClickUpload('sezioneLongitudinale4')"
          >
            {{ fileCount4 }}
          </td>

          <!-- Note -->
          <td class="note-field">
            <cube-defect-table-note-renderer
              [localizzazioneKey]="'sezioneLongitudinale4'"
              [localizzazioneParams]="params"
              (noteDialogClosed)="
                onNoteDialogClosed($event, 'sezioneLongitudinale4')
              "
            ></cube-defect-table-note-renderer>
          </td>
        </tr>

        <!-- Sezione Trasversale Checkboxes -->
        <ng-template
          #tableCheckboxSection
          let-formGroup="formGroup"
          let-formGroupName="formGroupName"
        >
          <ng-container [formGroup]="formGroup">
            <ng-container [formGroupName]="formGroupName">
              <!-- PS -->
              <td class="checkbox">
                <mat-checkbox
                  formControlName="PS"
                  [disabled]="disabled"
                  [name]=""
                ></mat-checkbox>
              </td>

              <!-- RS -->
              <td class="checkbox">
                <mat-checkbox
                  formControlName="RS"
                  [disabled]="disabled"
                  [name]=""
                ></mat-checkbox>
              </td>

              <!-- RS1 -->
              <td class="checkbox">
                <mat-checkbox
                  formControlName="RS1"
                  [disabled]="disabled"
                  [name]=""
                ></mat-checkbox>
              </td>

              <!-- CH -->
              <td class="checkbox">
                <mat-checkbox
                  formControlName="CH"
                  [disabled]="disabled"
                  [name]=""
                ></mat-checkbox>
              </td>

              <!-- RD1 -->
              <td class="checkbox">
                <mat-checkbox
                  formControlName="RD1"
                  [disabled]="disabled"
                  [name]=""
                ></mat-checkbox>
              </td>

              <!-- RD -->
              <td class="checkbox">
                <mat-checkbox
                  formControlName="RD"
                  [disabled]="disabled"
                  [name]=""
                ></mat-checkbox>
              </td>

              <!-- PD -->
              <td class="checkbox">
                <mat-checkbox
                  formControlName="PD"
                  [disabled]="disabled"
                  [name]=""
                ></mat-checkbox>
              </td>
            </ng-container>
          </ng-container>
        </ng-template>
      </tbody>
    </table>

    <!-- Submit Button -->
    <button
      class="btn-margin"
      type="submit"
      mat-flat-button
      color="primary"
      [disabled]="!localizzazioneForm.valid"
    >
      Submit
    </button>
  </form>

  <!-- Upload Defect Files -->
  <ng-template #defectsDialog>
    <cube-upload-defect-files-dialog
      [localizzazioneParams]="params"
      (uploadDefectsClosed)="onUploadDefectsClosed(params)"
    ></cube-upload-defect-files-dialog>
  </ng-template>
</mat-dialog-content>
