import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'cube-changed-planned-inspections-dialog',
  templateUrl: './changed-planned-inspections-dialog.component.html',
  styleUrls: ['./changed-planned-inspections-dialog.component.scss'],
})
export class ChangedPlannedInspectionsDialogComponent {
  plannedInspections: Record<'name', string>[];

  constructor(@Inject(MAT_DIALOG_DATA) private matDialogData: any) {
    this.plannedInspections = this.matDialogData.plannedInspections;
  }
}
