import { environment } from '../../../environments/environment';

const localJSON = './assets/json';
/*
 ****** The index is sorted by alphabetical order for scan it faster,
 ****** please when you add a voice take a minute to add it in the right spot
 ****** keep the order!
 */
export const API = {
  ADMIN_TOOL: {
    roleFeatures: (role: string) =>
      `${environment.ADMIN_TOOL_URL}/tenants/current/roles/${role}/platforms/CUBE/solutions/${environment.SOLUTION}/features`,

    tenants: (username: string) =>
      `${environment.ADMIN_TOOL_URL}/users/${username}/solutions/${environment.SOLUTION}/tenants`,

    userProfile: (username: string) =>
      `${environment.ADMIN_TOOL_URL}/tenants/current/users/${username}/profile`,
  },
  DYNAMIC_FORM: {
    getLatestTemplate: () =>
      `${environment.REST}/DynamicFormAPI/GetLatestTemplate`,

    getTemplate: (templateId: string) =>
      `${environment.REST}/DynamicFormAPI/GetTemplate/${templateId}`,
  },
  INSPECTION: {
    getAttentionClassList: () =>
      `${environment.REST}/InspectionAPI/GetAttentionClassList`,

    getAttentionClassXlsReport: () =>
      `${environment.REST}/InspectionAPI/DownloadAttentionClassList`,

    getInspection: (inspectionId: string) =>
      `${environment.REST}/InspectionAPI/GetInspection/${inspectionId}`,

    getInspectionLog: (inspectionId: string) =>
      `${environment.REST}/InspectionAPI/GetInspectionLog/${inspectionId}`,

    getInspections: () => `${environment.REST}/InspectionAPI/GetInspections`,

    getInspectionsForIop: () =>
      `${environment.REST}/InspectionAPI/GetInspectionsForIOP`,

    mapInspections: () => `${environment.REST}/InspectionAPI/MapInspections`,

    updateInspectionsStatus: () =>
      `${environment.REST}/InspectionAPI/UpdateInspectionsStatus`,
  },
  INSPECTION_CRUD: {
    createPlannedInspection: () =>
      `${environment.REST}/InspectionAPICRUD/CreatePlannedInspection`,

    deleteInspection: (inspectionId: string) =>
      `${environment.REST}/InspectionAPICRUD/DeleteInspection/${inspectionId}`,

    deletePlannedInspection: (inspectionId: string) =>
      `${environment.REST}/InspectionAPICRUD/DeletePlannedInspection/${inspectionId}`,

    getFileUploadDetails: (jobId: string) =>
      `${environment.REST}/InspectionAPICRUD/GetFileUploadDetails/${jobId}`,

    getMassiveImportDetails: () =>
      `${environment.REST}/InspectionAPICRUD/GetMassiveImportDetails`,

    getPlannedInspection: (inspectionId: string) =>
      `${environment.REST}/InspectionAPICRUD/GetPlannedInspection/${inspectionId}`,

    getPlannedInspections: () =>
      `${environment.REST}/InspectionAPICRUD/GetPlannedInspections`,

    massiveImport: () => `${environment.REST}/InspectionAPICRUD/MassiveImport`,

    migrateInspection: (inspectionId: string) =>
      `${environment.REST}/InspectionAPICRUD/MigrateInspection/${inspectionId}`,

    reviewInspection: (inspectionId: string) =>
      `${environment.REST}/InspectionAPICRUD/ReviewInspection/${inspectionId}`,

    updatePlannedInspection: (inspectionId: string) =>
      `${environment.REST}/InspectionAPICRUD/UpdatePlannedInspection/${inspectionId}`,
  },
  INSPECTION_GROUPS: {
    createUserGroup: () =>
      `${environment.REST}/InspectionGroupsAPI/CreateUserGroup`,

    deleteUserGroup: (userGroupId: string) =>
      `${environment.REST}/InspectionGroupsAPI/DeleteUserGroup/${userGroupId}`,

    getUserGroups: () =>
      `${environment.REST}/InspectionGroupsAPI/GetUserGroups`,

    updateUserGroup: (userGroupId: string) =>
      `${environment.REST}/InspectionGroupsAPI/UpdateUserGroup/${userGroupId}`,
  },
  PROFILE: {
    createUser: () => `${environment.REST}/SignatureProfileAPICRUD/CreateUser`,

    getUser: (userId: string) =>
      `${environment.REST}/SignatureProfileAPICRUD/GetUser/${userId}`,

    getUsers: () => `${environment.REST}/SignatureProfileAPICRUD/GetUsers`,

    updateUser: (userId: string) =>
      `${environment.REST}/SignatureProfileAPICRUD/UpdateUser/${userId}`,
  },
  REPORT: {
    checkReportStatus: (jobId: string) =>
      `${environment.PDF_REPORT_URL}/ReportAPI/CheckReportStatus/${jobId}`,

    defectImageZipFileDownload: () =>
      `${environment.REST}/ReportAPI/DefectImageZipFileDownload`,

    generateMultiplePdfReport: () =>
      `${environment.PDF_REPORT_URL}/ReportAPI/GenerateMultiplePdfReport`,

    generateSinglePdfReport: () =>
      `${environment.PDF_REPORT_URL}/ReportAPI/GenerateSinglePdfReport`,

    getExcelOutputReport: () =>
      `${environment.REST}/ReportAPI/ExcelOutputReport`,

    getInspectionQuarterPlans: () =>
      `${environment.REST}/ReportAPI/GETInspectionQuarterPlans`,

    getJobList: () => `${environment.PDF_REPORT_URL}/ReportAPI/JobLists`,

    getQuarterlyAndOperationReport: () =>
      `${environment.REST}/ReportAPI/GetQuarterlyAndOperationReport`,

    iopGenerateCSV: () => `${environment.REST}/ReportAPI/IOPGenerateCSV`,
  },
  TENANT: {
    getEnabledTenants: () => `${environment.REST}/TenantAPI/GetEnabledTenants`,

    getTenants: () => `${environment.REST}/TenantAPI/GetTenants`,

    getTenant: (tenantId: string) =>
      `${environment.REST}/TenantAPI/GetTenant/${tenantId}`,

    updateTenant: (tenantId: string) =>
      `${environment.REST}/TenantAPI/UpdateTenant/${tenantId}`,
  },
};
