<!-- Close Button -->
<button
  class="close-icon"
  tabindex="-1"
  type="button"
  mat-dialog-close
  mat-icon-button
  *ngIf="dialogMode !== defectDialogMode.tunnelDefectUpload"
>
  <mat-icon>close</mat-icon>
</button>

<!-- Title -->
<h5 mat-dialog-title>
  {{
    dialogMode === defectDialogMode.tunnelDefectView
      ? "View Images"
      : "Upload Images"
  }}
</h5>

<!-- Content -->
<mat-dialog-content>
  <!-- Form -->
  <form class="dialog-form" (ngSubmit)="onSubmitForm()">
    <!-- Accepted File Types -->
    <!-- INFO: Functionality not applicable in PORTAL -->

    <div *ngFor="let section of sectionList; let i = index">
      <!-- Section Title -->
      <div
        class="section-name"
        *ngIf="dialogMode === defectDialogMode.bridgeDefectUpload"
      >
        <!-- Name -->
        <h3>Section {{ i + 1 }}</h3>

        <!-- Delete -->
        <!-- INFO: Functionality not applicable in PORTAL -->
      </div>

      <!-- Add Files -->
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="20px"
        *ngIf="dialogMode !== defectDialogMode.tunnelDefectView"
      >
        <!-- Add Button -->
        <!-- INFO: Functionality not applicable in PORTAL -->

        <!-- File Input -->
        <!-- INFO: Functionality not applicable in PORTAL -->

        <!-- Open Camera -->
        <!-- INFO: Functionality not applicable in PORTAL -->
      </div>

      <mat-action-list>
        <!-- Old Files -->
        <ng-container *ngFor="let oldFile of section.oldFiles | keyvalue">
          <!-- File Name -->
          <a
            mat-list-item
            class="list-files"
            [ngClass]="{ strike: deletedFileKeys.includes(oldFile.key) }"
            (click)="onClickOldFile(oldFile)"
          >
            {{ getFileNameWithoutSectionNo(oldFile.key) }}

            <!-- Delete -->
            <!-- INFO: Functionality not applicable in PORTAL -->
          </a>

          <!-- Undo -->
          <!-- INFO: Functionality not applicable in PORTAL -->
        </ng-container>

        <!-- New Files -->
        <!-- INFO: Functionality not applicable in PORTAL -->
      </mat-action-list>
    </div>

    <!-- Action Buttons -->
    <div class="action-buttons">
      <!-- Add Section -->
      <!-- INFO: Functionality not applicable in PORTAL -->

      <!-- Cancel -->
      <button
        type="button"
        mat-dialog-close
        mat-flat-button
        color="warn"
        *ngIf="dialogMode !== defectDialogMode.tunnelDefectUpload"
      >
        {{ "common.cancel" | translate }}
        <mat-icon>remove_circle_outline</mat-icon>
      </button>

      <!-- Save -->
      <!-- INFO: Functionality not applicable in PORTAL -->
    </div>
  </form>
</mat-dialog-content>
