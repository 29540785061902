import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ReportJobDetails } from '../../../models/report';
import { ReportService } from '../../../services/api/report/report.service';
import { ReportMode } from '../../configs/report-mode.config';

@Component({
  selector: 'cube-download-report-dialog',
  templateUrl: './download-report-dialog.component.html',
  styleUrls: ['./download-report-dialog.component.scss'],
})
export class DownloadReportDialogComponent {
  jobDetails: ReportJobDetails;
  reportMode: ReportMode;
  reportModeConfig: typeof ReportMode = ReportMode;

  constructor(
    @Inject(MAT_DIALOG_DATA) private matDialogData: any,
    private matDialogRef: MatDialogRef<DownloadReportDialogComponent>,
    private reportService: ReportService
  ) {
    this.jobDetails = this.matDialogData.jobDetails;
    this.jobDetails.reportName = this.reportService.getReportName(
      this.jobDetails.reportType
    );
    this.reportMode = this.reportService.getReportMode(
      this.jobDetails.reportType
    );
  }

  onClickDownload(): void {
    const encodedUrl = new URL(this.jobDetails.reportUrl);
    window.open(encodedUrl.toString());
    this.matDialogRef.close();
  }
}
