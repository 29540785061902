import { MatDialog } from '@angular/material/dialog';

import { PROFILE_NOT_VALID, TENANT_CONFIG } from '../environments/config';
import { environment } from '../environments/environment';
import { User } from './models/cube';
import { AdminToolService } from './services/api/admin-tool/admin-tool.service';
import { ProfileService } from './services/api/profile/profile.service';
import { CubeService } from './services/cube/cube.service';
import { STORAGE_KEYS } from './services/storage/storage.service';
import { UserService } from './services/user/user.service';
import { ChangedPlannedInspectionsDialogComponent } from './shared/components/changed-planned-inspections-dialog/changed-planned-inspections-dialog.component';

import { KeycloakOptions, KeycloakService } from 'keycloak-angular';

// https://medium.com/@gmurop/managing-dependencies-among-app-initializers-in-angular-652be4afce6f
// https://www.intertech.com/Blog/angular-4-tutorial-run-code-during-app-initialization/

export function authInitializer(
  adminToolService: AdminToolService,
  cubeService: CubeService,
  keycloakService: KeycloakService,
  matDialog: MatDialog,
  profileService: ProfileService,
  userService: UserService
): () => Promise<any> {
  try {
    return (): Promise<any> =>
      initKC(
        adminToolService,
        cubeService,
        keycloakService,
        matDialog,
        profileService,
        userService
      ).catch((reason) => {
        if (reason === PROFILE_NOT_VALID) {
          window.location.href = '//www.rina.org';
        }
      });
  } catch (e) {
    console.error(e);
  }
}

function initKC(
  adminToolService: AdminToolService,
  cubeService: CubeService,
  keycloakService: KeycloakService,
  matDialog: MatDialog,
  profileService: ProfileService,
  userService: UserService
): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      // INFO: Keycloak API calls
      const kcOption: KeycloakOptions = {
        bearerExcludedUrls: environment.auth.bearerExcludedUrls,
        bearerPrefix: 'Bearer',
        config: environment.keycloak,
        initOptions: {
          checkLoginIframe: false,
          onLoad: 'check-sso',
          // silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
        },
        loadUserProfileAtStartUp: false,
      };
      const authentication = await keycloakService.init(kcOption);

      if (!authentication) {
        // If the user is not authenticated, redirect to the login page
        keycloakService
          .login({ idpHint: TENANT_CONFIG.settings.company?.identityProvider })
          .catch((e) => console.error(e));

        return reject(false);
      }

      // Initializing the application by loading the user profile, retrieving the token, and getting the Keycloak instance from the Keycloak service
      const [kcUser, token, instance] = await Promise.all([
        keycloakService.loadUserProfile(),
        keycloakService.getToken(),
        keycloakService.getKeycloakInstance(),
      ]);

      if (!kcUser) throw PROFILE_NOT_VALID;

      // INFO: Admin Tool API calls
      // Configuring current tenant details from Admin Tool
      const tenantsList = await userService
        .configureCurrentTenant(kcUser.username)
        .toPromise();

      if (!tenantsList?.length) throw PROFILE_NOT_VALID;

      // Configuring user details and roles from Admin Tool
      const user = kcUser as User;
      try {
        // Getting user profile from Admin Tool
        const userProfile = await adminToolService
          .getProfile(kcUser.username)
          .toPromise();

        // Adding roles and modules to user
        user.rolesAndModules = [];
        userProfile.roles?.forEach((role) =>
          user.rolesAndModules.push({ name: role.name, roleModules: [] })
        );
        // Adding user groups to user
        user.userGroups = userProfile.userGroups.map((e) => e.name).sort();
        // Adding assets and asset groups to user
        user.assets = userProfile.assets;
        user.assetGroups = userProfile.assetGroups;
        // Adding token to user
        user.token = token;
        // Adding sub from keycloak id (the unique identifier for the user) to user
        user.id = instance.idTokenParsed.sub;
      } catch (e) {
        throw PROFILE_NOT_VALID;
      }

      // Storing user in service
      userService.setUser(user);

      // Storing active role in service and local storage
      const activeRole =
        localStorage.getItem(STORAGE_KEYS.activeRole) || // Active role from local storage
        user.rolesAndModules.find(
          (r) => r.name === TENANT_CONFIG.settings.admin_tool?.tags.supervisor
        )?.name || // Supervisor role from user roles
        user.rolesAndModules[0]?.name; // First role from user roles
      userService.setActiveRole(activeRole);

      try {
        // Setting user's rolesAndModules based on the active role
        await userService
          .composeRoleFeatures(userService.getActiveRole())
          .toPromise();
      } catch (e) {
        throw PROFILE_NOT_VALID;
      }

      cubeService.configureLocalStorageVariables(token, instance);
      // Updating token in cube service
      cubeService.updateToken(token);

      // INFO: Business logic API calls
      try {
        // Getting current tenant details from tenant service
        await userService.getCurrentTenantDetails().toPromise();
      } catch (e) {
        throw PROFILE_NOT_VALID;
      }

      // Adding user to DB if not present (will be checked in BE)
      const createUserData = {
        company_email: '',
        company: '',
        name: user.firstName,
        preferred_language: 'en',
        roles: [activeRole],
        surname: user.lastName,
        user_email: user.email,
      };
      const res = await profileService.createUser(createUserData).toPromise();

      // Getting list of planned inspections that were modified by an administrator or a client after this user's last login
      const { planned_inspectons: plannedInspections } = res.data as any;
      if (plannedInspections.length) {
        matDialog.open(ChangedPlannedInspectionsDialogComponent, {
          data: { plannedInspections },
          width: '500px',
        });
      }

      return resolve(true);
    } catch (error) {
      return reject(error);
    }
  });
}
