<!-- Close Button -->
<button
  class="close-icon"
  tabindex="-1"
  type="button"
  mat-dialog-close
  mat-icon-button
>
  <mat-icon>close</mat-icon>
</button>

<!-- Title -->
<h5 mat-dialog-title>
  {{ "request_report_dialog.request_report" | translate }}
</h5>

<!-- Content -->
<mat-dialog-content>
  <div class="dialog-form">
    <p>
      {{ "request_report_dialog.report_generation_description" | translate }}
    </p>

    <!-- Buttons -->
    <div class="action-buttons">
      <!-- Go to report page -->
      <button
        type="button"
        mat-dialog-close
        mat-flat-button
        color="primary"
        [routerLink]="reportStatusPageUrl"
      >
        {{ "request_report_dialog.go_to_report_page" | translate }}
      </button>
    </div>
  </div>

  <ng-template #goToReportsPage> </ng-template>
</mat-dialog-content>
