import { StructureConstants } from '../constants';

export const BridgeConstants = {
  levels: {
    ...StructureConstants.levels,
    spalle: 'spalle',
    pile: 'pile',
    archi: 'archi',
    impalcati: 'impalcati',
    giunti: 'giunti',
    apparecchiSismici: 'apparecchisismici',
    fileDiAppoggi: 'filediappoggi',
    level2: 'level2',
    level2Campata: 'level2Campata',
  },
  level2RiskSheets: {
    strutturaleEFondazionale: 'Strutturale e fondazionale',
    sismica: 'Sismica',
    frane: 'Frane',
    idraulica: 'Idraulica',
    globale: 'Globale',
  },
  partNo: 'Campata No',
  partsList: 'campatelist',
  defectElements: {
    spalle: 'Spalle',
    pile: 'Pile',
    archi: 'Archi',
    impalcati: 'Impalcati',
    giunti: 'Giunti',
    apparecchiSismici: 'Apparecchi Sismici',
    fileDiAppoggi: 'File di Appoggi',
  },
  elementiAccessori: 'Elementi Accessori',
  eventualiNote: 'Eventuali Note',
};
