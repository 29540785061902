export enum ReportErrors {
  errorInGeneratingImage = 'errorInGeneratingImage',
  errorInService = 'errorInService',
  errorInZipGeneration = 'errorInZipGeneration',
  fileNotFound = 'fileNotFound',
  noInspectionData = 'noInspectionData',
  noInspectionFileInBlob = 'noInspectionFileInBlob',
  noInspectionImages = 'noInspectionImages',
  noOutputFileInBlob = 'noOutputFileInBlob',
  noOutputTemplate = 'noOutputTemplate',
  noSuccessfulInspection = 'noSuccessfulInspection',
}
