import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { STORAGE_KEYS, StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private _darkTheme: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  isDarkTheme = this._darkTheme.asObservable();

  constructor(private storageService: StorageService) {
    const themeFromStorage = this.storageService.getFromStorage(
      STORAGE_KEYS.darkTheme
    );
    if (themeFromStorage === null) {
      this._darkTheme = new BehaviorSubject<boolean>(true);
    } else {
      this._darkTheme = new BehaviorSubject<boolean>(themeFromStorage);
    }
    this.isDarkTheme = this._darkTheme.asObservable();
  }

  setDarkTheme(isDarkTheme: boolean): void {
    this._darkTheme.next(isDarkTheme);
    this.storageService.addToStorage(
      STORAGE_KEYS.darkTheme,
      this._darkTheme.value
    );
  }
}
