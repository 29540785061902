<!-- Close Button -->
<button
  class="close-icon"
  tabindex="-1"
  type="button"
  mat-dialog-close
  mat-icon-button
>
  <mat-icon>close</mat-icon>
</button>

<!-- Title -->
<h5 mat-dialog-title>
  {{ "import_completed_dialog.import_completed" | translate }}
</h5>

<!-- Content -->
<mat-dialog-content>
  <div class="dialog-form">
    <p>
      <span>
        {{ "import_completed_dialog.import_completed_description" | translate }}
      </span>
    </p>

    <div class="import-details">
      <!-- File Name -->
      <p>
        <span>{{ "import_completed_dialog.file_name" | translate }}:</span>
        {{ jobDetails.fileName }}
      </p>

      <!-- Inspections Imported -->
      <p>
        <span
          >{{ "import_completed_dialog.inspections_imported" | translate }}:
        </span>
        {{ jobDetails.completed + " / " + jobDetails.totalRows }}
      </p>
    </div>
  </div>
</mat-dialog-content>
