import { Module } from '../app/models/cube';
import { AppFeature } from '../app/shared/configs/app-feature.config';

// Top level routes are registered using this config
// Sidebar menu items are generated using this config and are shown in this order
export const MODULES: Record<string, Module> = {
  'home-module': {
    roles: [AppFeature.portalHome],
    route: {
      path: 'home',
      loadChildren: () =>
        import('../app/pages/home/home.module').then((m) => m.HomeModule),
      data: { module: 'home-module' },
    },
    menu: {
      name: 'side_menu.home',
      mat_icon: 'home',
      open: false,
      link: 'home',
    },
  },
  'map-inspection-module': {
    roles: [AppFeature.portalMap],
    route: {
      path: 'portal-map-inspection',
      loadChildren: () =>
        import('../app/pages/map-inspection/map-inspection.module').then(
          (m) => m.MapInspectionModule
        ),
      data: { module: 'map-inspection-module' },
    },
    menu: {
      name: 'side_menu.map_inspection',
      mat_icon: 'public',
      open: false,
      link: 'portal-map-inspection',
    },
  },
  'inspections-module': {
    roles: [
      AppFeature.portalInspectionList,
      AppFeature.portalSingleReportStatus,
    ],
    route: {
      path: 'inspections',
      loadChildren: () =>
        import('../app/pages/inspections/inspections.module').then(
          (m) => m.InspectionsModule
        ),
      data: {
        breadcrumb: 'breadcrumbs.inspections',
        module: 'inspections-module',
      },
    },
    menu: {
      name: 'side_menu.inspections',
      mat_icon: 'assignment',
      open: false,
      link: false,
      sub: [
        {
          key: 'inspection-work-report-page',
          name: 'side_menu.inspection_opera_report',
          open: false,
          mat_icon: 'view_list',
          link: 'inspections/inspection-report',
        },
        {
          key: 'single-report-status-page',
          name: 'side_menu.single_report_status',
          open: false,
          mat_icon: 'summarize',
          link: 'inspections/single-report-status',
        },
      ],
    },
  },
  'inspection-work-report-page': {
    roles: [AppFeature.portalInspectionList],
  },
  'single-report-status-page': {
    roles: [AppFeature.portalSingleReportStatus],
  },
  'attention-class-module': {
    roles: [AppFeature.portalAttentionClass],
    route: {
      path: 'attention-class',
      loadChildren: () =>
        import('../app/pages/attention-class/attention-class.module').then(
          (m) => m.AttentionClassModule
        ),
      data: {
        breadcrumb: 'breadcrumbs.attention_class',
        module: 'attention-class-module',
      },
    },
    menu: {
      name: 'side_menu.attention_class',
      mat_icon: 'warning',
      open: false,
      link: false,
      sub: [
        {
          key: 'attention-class-page',
          name: 'side_menu.attention_class',
          open: false,
          mat_icon: 'warning',
          link: 'attention-class',
        },
      ],
    },
  },
  'attention-class-page': {
    roles: [AppFeature.portalAttentionClass],
  },
  'plan-inspections-module': {
    roles: [
      AppFeature.portalCalendarInspection,
      AppFeature.portalPlanInspection,
    ],
    route: {
      path: 'plan-inspections',
      loadChildren: () =>
        import('../app/pages/plan-inspections/plan-inspections.module').then(
          (m) => m.PlanInspectionsModule
        ),
      data: {
        breadcrumb: 'breadcrumbs.plan_inspections',
        module: 'plan-inspections-module',
      },
    },
    menu: {
      name: 'side_menu.plan_inspections',
      mat_icon: 'date_range',
      open: false,
      link: false,
      sub: [
        {
          key: 'plan-inspection-page',
          name: 'side_menu.plan_inspections',
          mat_icon: 'event',
          open: false,
          link: 'plan-inspections/plan-inspections',
        },
        {
          key: 'quarters-plans-page',
          name: 'side_menu.quarters_plans',
          mat_icon: 'date_range',
          open: false,
          link: 'plan-inspections/quarters-plans',
        },
      ],
    },
  },
  'plan-inspection-page': {
    roles: [AppFeature.portalPlanInspection],
  },
  'quarters-plans-page': {
    roles: [AppFeature.portalCalendarInspection],
  },
  'import-inspections-module': {
    roles: [AppFeature.portalInspectionImport],
    route: {
      path: 'import-inspections',
      loadChildren: () =>
        import(
          '../app/pages/import-inspections/import-inspections.module'
        ).then((m) => m.ImportInspectionsModule),
      data: { module: 'import-inspections-module' },
    },
    menu: {
      name: 'side_menu.import_inspections',
      mat_icon: 'upload',
      open: false,
      link: 'import-inspections',
    },
  },
  'periodic-reporting-module': {
    roles: [
      AppFeature.portalReportStatus,
      AppFeature.portalStatisticPeriodicReport,
    ],
    route: {
      path: 'periodic-reporting',
      loadChildren: () =>
        import(
          '../app/pages/periodic-reporting/periodic-reporting.module'
        ).then((m) => m.PeriodicReportingModule),
      data: {
        breadcrumb: 'breadcrumbs.periodic_reporting',
        module: 'periodic-reporting-module',
      },
    },
    menu: {
      name: 'side_menu.periodic_reporting',
      mat_icon: 'addchart',
      open: false,
      link: false,
      sub: [
        {
          key: 'periodic-reporting-page',
          name: 'side_menu.periodic_reporting',
          open: false,
          mat_icon: 'addchart',
          link: 'periodic-reporting',
        },
        {
          key: 'periodic-report-status-page',
          name: 'side_menu.periodic_report_status',
          open: false,
          mat_icon: 'summarize',
          link: 'periodic-reporting/periodic-report-status',
        },
      ],
    },
  },
  'periodic-reporting-page': {
    roles: [AppFeature.portalStatisticPeriodicReport],
  },
  'periodic-report-status-page': {
    roles: [AppFeature.portalReportStatus],
  },
  'iop-request-module': {
    roles: [AppFeature.portalIopRequest, AppFeature.portalIopRequestStatus],
    route: {
      path: 'iop-request',
      loadChildren: () =>
        import('../app/pages/iop-request/iop-request.module').then(
          (m) => m.IopRequestModule
        ),
      data: {
        breadcrumb: 'breadcrumbs.iop_request',
        module: 'iop-request-module',
      },
    },
    menu: {
      name: 'side_menu.iop_requests',
      mat_icon: 'list_alt',
      open: false,
      link: false,
      sub: [
        {
          key: 'iop-request-page',
          name: 'side_menu.iop_requests',
          open: false,
          mat_icon: 'list_alt',
          link: 'iop-request',
        },
        {
          key: 'request-report-status-page',
          name: 'side_menu.request_report_status',
          open: false,
          mat_icon: 'summarize',
          link: 'iop-request/request-report-status',
        },
      ],
    },
  },
  'iop-request-page': {
    roles: [AppFeature.portalIopRequest],
  },
  'request-report-status-page': {
    roles: [AppFeature.portalIopRequestStatus],
  },
  'configuration-module': {
    roles: [
      AppFeature.portalGroupManagement,
      AppFeature.portalTenantConfigurationManagement,
      AppFeature.portalUserManagement,
    ],
    route: {
      path: 'configuration',
      loadChildren: () =>
        import('../app/pages/configuration/configuration.module').then(
          (m) => m.ConfigurationModule
        ),
      data: {
        breadcrumb: 'breadcrumbs.configuration',
        module: 'configuration-module',
      },
    },
    menu: {
      name: 'side_menu.configuration',
      mat_icon: 'settings',
      open: false,
      link: false,
      sub: [
        {
          key: 'users-page',
          name: 'side_menu.users',
          mat_icon: 'person',
          open: false,
          link: 'configuration/users',
        },
        {
          key: 'user-groups-page',
          name: 'side_menu.user_groups',
          mat_icon: 'group',
          open: false,
          link: 'configuration/user-groups',
        },
        {
          key: 'tenant-configuration-page',
          name: 'side_menu.tenant_configuration',
          mat_icon: 'domain',
          open: false,
          link: 'configuration/tenant-configuration',
        },
      ],
    },
  },
  'users-page': {
    roles: [AppFeature.portalUserManagement],
  },
  'user-groups-page': {
    roles: [AppFeature.portalGroupManagement],
  },
  'tenant-configuration-page': {
    roles: [AppFeature.portalTenantConfigurationManagement],
  },
  'about-module': {
    roles: [],
    route: {
      path: 'about',
      loadChildren: () =>
        import('../app/pages/about/about.module').then((m) => m.AboutModule),
      data: { module: 'about-module' },
    },
    menu: {
      name: 'side_menu.about',
      mat_icon: 'info',
      open: false,
      link: 'about',
    },
  },
  // TEMP: For migration
  'migration-module': {
    roles: [AppFeature.portalMigration],
    route: {
      path: 'migration',
      loadChildren: () =>
        import('../app/pages/migration/migration.module').then(
          (m) => m.MigrationModule
        ),
      data: { module: 'migration-module' },
    },
    menu: {
      name: 'side_menu.migration',
      mat_icon: 'update',
      open: false,
      link: 'migration',
    },
  },
};
