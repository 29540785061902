import { HttpContext } from '@angular/common/http'; // TEMP: For migration
import { Injectable } from '@angular/core';

import { Response } from '../../../models/api';
import { Inspection } from '../../../models/inspection';
import {
  EXCLUDE_LOADER,
  HANDLED_ERRORS,
  HttpContexts,
} from '../../../shared/utils/http-context';
import { API } from '../api.const';
import { ApiService } from '../api.service';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InspectionCrudService extends ApiService {
  endpoint = API.INSPECTION_CRUD;

  addPlannedInspection(
    inspection: Inspection
  ): Observable<Response<Inspection>> {
    return this.post<Response<Inspection>>({
      body: inspection,
      operation: 'addPlannedInspection',
      url: this.endpoint.createPlannedInspection(),
    });
  }

  deleteInspection(inspectionId: string): Observable<Response<null>> {
    return this.delete<Response<null>>({
      operation: 'deleteInspection',
      url: this.endpoint.deleteInspection(inspectionId),
    });
  }

  deletePlannedInspection(inspectionId: string): Observable<Response<null>> {
    return this.delete<Response<null>>({
      operation: 'deletePlannedInspection',
      url: this.endpoint.deletePlannedInspection(inspectionId),
    });
  }

  getMassiveImportDetails(): Observable<Response<any[]>> {
    return this.get<Response<any[]>>({
      operation: 'getMassiveImportDetails',
      url: this.endpoint.getMassiveImportDetails(),
    });
  }

  getPlannedInspection(inspectionId: string): Observable<Response<Inspection>> {
    return this.get<Response<Inspection>>({
      operation: 'getPlannedInspection',
      url: this.endpoint.getPlannedInspection(inspectionId),
    });
  }

  getPlannedInspections(
    filters: any,
    page: number,
    limit: number
  ): Observable<Response<Inspection[]>> {
    return this.get<Response<Inspection[]>>({
      metadata: {
        ...this.getHttpParams({
          ...filters,
          limit,
          page,
        }),
      },
      operation: 'getPlannedInspections',
      url: this.endpoint.getPlannedInspections(),
    });
  }

  massiveImport(formData: any): Observable<Response<any>> {
    return this.post<Response<any>>({
      body: formData,
      operation: 'massiveImport',
      url: this.endpoint.massiveImport(),
    });
  }

  // TEMP: For migration
  migrateInspection(
    inspectionId: string,
    inspection: FormData,
    httpContexts: HttpContexts = {}
  ): Observable<any> {
    const { excludeLoader, handledErrors } = httpContexts;
    const context = new HttpContext().set(EXCLUDE_LOADER, excludeLoader);

    if (handledErrors != null) context.set(HANDLED_ERRORS, handledErrors);

    return this.put<any>({
      body: inspection,
      operation: 'migrateInspection',
      metadata: { context },
      url: this.endpoint.migrateInspection(inspectionId),
    });
  }

  reviewInspection(
    inspectionId: string,
    inspection: FormData
  ): Observable<any> {
    return this.put<any>({
      body: inspection,
      operation: 'reviewInspection',
      url: this.endpoint.reviewInspection(inspectionId),
    });
  }

  updatePlannedInspection(
    inspection: Inspection
  ): Observable<Response<Inspection>> {
    return this.put<Response<Inspection>>({
      body: inspection,
      operation: 'updatePlannedInspection',
      url: this.endpoint.updatePlannedInspection(inspection.id),
    });
  }
}
