import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '../core/core.module';
import { BreadCrumbToolbarComponent } from './components/bread-crumb-toolbar/bread-crumb-toolbar.component';
import { ChangedPlannedInspectionsDialogComponent } from './components/changed-planned-inspections-dialog/changed-planned-inspections-dialog.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { DownloadReportDialogComponent } from './components/download-report-dialog/download-report-dialog.component';
import { FailedReportDialogComponent } from './components/failed-report-dialog/failed-report-dialog.component';
import { FailedImportDialogComponent } from './components/failed-import-dialog/failed-import-dialog.component';
import { ImportCompletedDialogComponent } from './components/import-completed-dialog/import-completed-dialog.component';
import { LoaderComponent } from './components/loader/loader.component';
import { RequestReportDialogComponent } from './components/request-report-dialog/request-report-dialog.component';
import { StartImportDialogComponent } from './components/start-import-dialog/start-import-dialog.component';
import { ValidationErrorComponent } from './components/validation-error/validation-error.component';

@NgModule({
  declarations: [
    BreadCrumbToolbarComponent,
    ChangedPlannedInspectionsDialogComponent,
    ConfirmationDialogComponent,
    DownloadReportDialogComponent,
    FailedImportDialogComponent,
    FailedReportDialogComponent,
    ImportCompletedDialogComponent,
    LoaderComponent,
    RequestReportDialogComponent,
    StartImportDialogComponent,
    ValidationErrorComponent,
  ],
  imports: [CommonModule, CoreModule],
  exports: [
    BreadCrumbToolbarComponent,
    ChangedPlannedInspectionsDialogComponent,
    ConfirmationDialogComponent,
    DownloadReportDialogComponent,
    FailedImportDialogComponent,
    FailedReportDialogComponent,
    ImportCompletedDialogComponent,
    LoaderComponent,
    RequestReportDialogComponent,
    StartImportDialogComponent,
    ValidationErrorComponent,
  ],
})
export class SharedModule {}
