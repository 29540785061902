import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DefectDialogMode } from '../../../../../shared/configs/defect-dialog-mode.config';

import { Subject } from 'rxjs';

@Component({
  selector: 'cube-tunnel-localizzazione-dialog',
  templateUrl: './tunnel-localizzazione-dialog.component.html',
  styleUrls: ['./tunnel-localizzazione-dialog.component.scss'],
})
export class TunnelLocalizzazioneDialogComponent implements OnDestroy {
  backupLocalizzazioneFormValue = {};
  backupParams = {};
  defaultValues = {
    'N° foto': '',
    CH: false,
    Note: '',
    PD: false,
    PS: false,
    RD: false,
    RD1: false,
    RS: false,
    RS1: false,
  };
  defectDialogMode: typeof DefectDialogMode = DefectDialogMode;
  disabled: boolean = false;
  fileCount1: string = '0';
  fileCount2: string = '0';
  fileCount3: string = '0';
  fileCount4: string = '0';
  localizzazioneForm: FormGroup;
  params: any = {};
  uploadStatus: boolean = false;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) private matDialogData: any,
    private formBuilder: FormBuilder,
    private matDialogRef: MatDialogRef<TunnelLocalizzazioneDialogComponent>
  ) {
    this.params = this.matDialogData.params;
    this.backupParams = this.matDialogData.params;
    this.disabled = this.params.disabled;
    this.localizzazioneForm = this.createLocalizzazioneForm();
    this.backupLocalizzazioneFormValue = this.localizzazioneForm.value;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onClickClose(): void {
    this.matDialogRef.close({
      data: {
        ...this.backupLocalizzazioneFormValue,
      },
      params: this.backupParams,
    });
  }

  onClickUpload(localizzazioneKey: string): void {
    // INFO: Functionality not applicable in PORTAL
  }

  onNoteDialogClosed(note: string, localizzazioneKey: string): void {
    // INFO: Functionality not applicable in PORTAL
  }

  onSubmit(): void {
    this.matDialogRef.close({ data: { ...this.localizzazioneForm.value } });
  }

  onUploadDefectsClosed(params): void {
    // INFO: Functionality not applicable in PORTAL
  }

  private createLocalizzazioneForm(): FormGroup {
    return this.formBuilder.group({
      sezioneLongitudinale1: this.createSezioneLongitudinaleGroup(
        this.params.data.L
          ? this.params.data.L.sezioneLongitudinale1
          : this.defaultValues,
        1
      ),
      sezioneLongitudinale2: this.createSezioneLongitudinaleGroup(
        this.params.data.L
          ? this.params.data.L.sezioneLongitudinale2
          : this.defaultValues,
        2
      ),
      sezioneLongitudinale3: this.createSezioneLongitudinaleGroup(
        this.params.data.L
          ? this.params.data.L.sezioneLongitudinale3
          : this.defaultValues,
        3
      ),
      sezioneLongitudinale4: this.createSezioneLongitudinaleGroup(
        this.params.data.L
          ? this.params.data.L.sezioneLongitudinale4
          : this.defaultValues,
        4
      ),
    });
  }

  private createSezioneLongitudinaleGroup(data, rowNumber: number): FormGroup {
    const fileCount = data['N° foto']
      ? Object.keys(data['N° foto']).length.toString()
      : '0';
    switch (rowNumber) {
      case 1:
        this.fileCount1 = fileCount;

        break;

      case 2:
        this.fileCount2 = fileCount;

        break;

      case 3:
        this.fileCount3 = fileCount;

        break;

      case 4:
        this.fileCount4 = fileCount;

        break;

      default:
        this.fileCount1 =
          this.fileCount2 =
          this.fileCount3 =
          this.fileCount4 =
            '0';

        break;
    }

    return this.formBuilder.group({
      'N° foto': [data['N° foto']],
      CH: [data.CH],
      Note: [data.Note],
      PD: [data.PD],
      PS: [data.PS],
      RD: [data.RD],
      RD1: [data.RD1],
      RS: [data.RS],
      RS1: [data.RS1],
    });
  }
}
