import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { UserService } from '../user/user.service';

import { Observable } from 'rxjs';

@Injectable()
export class HttpHeadersInterceptor implements HttpInterceptor {
  constructor(private userService: UserService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headers = this.getHeaders(request);

    return next.handle(request.clone({ headers }));
  }

  private getHeaders({ headers }: HttpRequest<any>): HttpHeaders {
    // Adding 'x-company' header (tenant)
    if (this.userService.getCurrentTenant()) {
      /* const xCompany = this.userService
    .getCurrentTenant()
    .toLowerCase()
    .replace(/^["'](.*?)["']$/, '$1'); */

      headers = headers.set(
        'x-company',
        this.userService.getCurrentTenant().toLowerCase()
      );
    }

    // Adding 'x-solution' header (solution name from environment)
    headers = headers.set('x-solution', environment.SOLUTION);

    // Adding 'x-functions-key' header (Azure Functions key)
    // headers = headers.set('x-functions-key', '/DcnDugVUfXpLjg3MNdThe7/NZZimP5C4FIROvqIbLCmnzu6DkxSPQ==')

    return headers;
  }
}
