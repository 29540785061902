import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ImportJobDetails } from '../../../models/import';
import { ImportService } from '../../../services/api/inspection-import/import.service';

@Component({
  selector: 'cube-start-import-dialog',
  templateUrl: './start-import-dialog.component.html',
  styleUrls: ['./start-import-dialog.component.scss'],
})
export class StartImportDialogComponent implements OnInit {
  private jobDetails: Partial<ImportJobDetails>;

  constructor(
    @Inject(MAT_DIALOG_DATA) private matDialogData: any,
    private importService: ImportService
  ) {
    this.jobDetails = this.matDialogData.jobDetails;
  }

  ngOnInit(): void {
    this.addJobToImportWebWorker();
  }

  private addJobToImportWebWorker(): void {
    this.importService.addJobToImportWebWorker(this.jobDetails);
  }
}
