import { Component } from '@angular/core';

import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';

export interface ICustomHeaderParams {
  subTitle: string;
  title: string;
}

@Component({
  selector: 'cube-defect-table-header-renderer.component',
  template: `
    <div>
      <!-- Title -->
      <div class="customHeaderLabel label">
        <div class="display-flex main-sec-table">
          <div
            class="flex-1 inti-sec"
            *ngFor="let title of params.title; let index = index"
          >
            {{ title }}
          </div>
        </div>
      </div>

      <div
        class="customSortDownLabel"
        [ngClass]="ascSort"
        (click)="onSortRequested('asc', $event)"
        *ngIf="params.enableSorting"
      >
        <i class="fa fa-long-arrow-alt-down"></i>
      </div>

      <div
        class="customSortUpLabel"
        [ngClass]="descSort"
        (click)="onSortRequested('desc', $event)"
        *ngIf="params.enableSorting"
      >
        <i class="fa fa-long-arrow-alt-up"></i>
      </div>

      <div
        class="customSortRemoveLabel"
        [ngClass]="noSort"
        (click)="onSortRequested('', $event)"
        *ngIf="params.enableSorting"
      >
        <i class="fa fa-times"></i>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        flex: 1;
      }

      .customHeaderLabel,
      .customSortDownLabel,
      .customSortUpLabel,
      .customSortRemoveLabel {
        float: center;
        margin: 0 0 0 0;
      }

      .customSortUpLabel {
        margin: 0;
      }

      .customSortRemoveLabel {
        font-size: 11px;
      }

      .active {
        color: cornflowerblue;
      }

      .label {
        align-items: center;
        display: flex;
        flex-direction: column;
      }

      .divider {
        width: 100%;
      }

      .main-sec-table {
        height: 46px;
        width: 100%;
      }

      .main-sec-table .inti-sec {
        border-right: 1px solid #9e9c9c;
        padding-top: 14px;
        text-align: center;
      }

      .main-sec-table .inti-sec:last-child {
        border-right: 0px;
      }
    `,
  ],
})
export class DefectTableHeaderRendererComponent implements IHeaderAngularComp {
  public ascSort = 'inactive';
  public descSort = 'inactive';
  public noSort = 'inactive';
  public params!: IHeaderParams & ICustomHeaderParams;

  agInit(params: IHeaderParams & ICustomHeaderParams): void {
    this.params = params;
    params.column.addEventListener(
      'sortChanged',
      this.onSortChanged.bind(this)
    );
    this.onSortChanged();
  }

  onSortChanged() {
    this.ascSort = this.descSort = this.noSort = 'inactive';
    if (this.params.column.isSortAscending()) {
      this.ascSort = 'active';
    } else if (this.params.column.isSortDescending()) {
      this.descSort = 'active';
    } else {
      this.noSort = 'active';
    }
  }

  onSortRequested(order: 'asc' | 'desc' | null, event: any) {
    this.params.setSort(order, event.shiftKey);
  }

  refresh(params: IHeaderParams<any>): boolean {
    return false;
  }
}
