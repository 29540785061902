export interface EnvironmentConfiguration {
  settings: any;
  company_asset_table?: any;
  filter_layout?: any;
  kpi_status?: any;
  measures_groups?: any[];
}
export enum Solutions {
  TI_BSM = 'ti-bsm',
}
