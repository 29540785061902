import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DefectDialogMode } from '../../../../shared/configs/defect-dialog-mode.config';
import { TunnelLocalizzazioneDialogComponent } from './tunnel-localizzazione-dialog/tunnel-localizzazione-dialog.component';
import { UploadDefectFilesDialogComponent } from './upload-defect-files-dialog/upload-defect-files-dialog.component';

import { AgRendererComponent } from 'ag-grid-angular';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

@Component({
  selector: 'cube-defect-table-localizzazione-renderer',
  template: `
    <div *ngIf="params">
      <!-- Image Preview -->
      <button
        class="upload-btn filename"
        type="button"
        (click)="onClickButton()"
      >
        {{ fileCount }}
      </button>
    </div>
  `,
  styles: [
    `
      .upload-btn {
        cursor: pointer;
        background-color: inherit;
        border: 0px;
        height: 35px;
        margin: 2px;
        width: 100%;
      }

      .filename {
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
      }
    `,
  ],
})
export class DefectTableLocalizzazioneRendererComponent
  implements AgRendererComponent
{
  fileCount: string;
  params: any;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private matDialog: MatDialog) {}

  agInit(params: any): void {
    this.setNfotoFileCount(params);
    this.params = params;
  }

  onClickButton() {
    if (this.params.colDef.field === 'L') {
      const dialogRef = this.matDialog.open(
        TunnelLocalizzazioneDialogComponent,
        {
          disableClose: true,
          minWidth: '550px',
          data: {
            params: this.params,
          },
        }
      );

      dialogRef
        .afterClosed()
        .pipe(
          filter((result) => result != null),
          takeUntil(this.destroy$)
        )
        .subscribe((result) => {
          this.params.data['L'] = result.data;
          this.params.api.refreshCells();
        });
    } else {
      this.matDialog.open(UploadDefectFilesDialogComponent, {
        width: '600px',
        height: '500px',
        data: {
          mode: DefectDialogMode.tunnelDefectView,
          params: this.params,
        },
      });
    }
  }

  refresh(params: any): boolean {
    this.setNfotoFileCount(params);
    this.params.node.setDataValue('N° foto', this.fileCount);

    return true;
  }

  private setNfotoFileCount(params: any): void {
    if (params.data['L']) {
      if (
        params.data['L'].sezioneLongitudinale1['N° foto'] ||
        params.data['L'].sezioneLongitudinale2['N° foto'] ||
        params.data['L'].sezioneLongitudinale3['N° foto'] ||
        params.data['L'].sezioneLongitudinale4['N° foto']
      ) {
        params.data['N° foto'] = {
          ...params.data['L'].sezioneLongitudinale1['N° foto'],
          ...params.data['L'].sezioneLongitudinale2['N° foto'],
          ...params.data['L'].sezioneLongitudinale3['N° foto'],
          ...params.data['L'].sezioneLongitudinale4['N° foto'],
        };
        this.fileCount = (
          Object.keys(params.data['L'].sezioneLongitudinale1['N° foto'])
            .length +
          Object.keys(params.data['L'].sezioneLongitudinale2['N° foto'])
            .length +
          Object.keys(params.data['L'].sezioneLongitudinale3['N° foto'])
            .length +
          Object.keys(params.data['L'].sezioneLongitudinale4['N° foto']).length
        ).toString();
      } else {
        this.fileCount = '0';
      }
    } else {
      this.fileCount = '0';
    }
  }
}
