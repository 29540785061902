<!-- Close Button -->
<button
  class="close-icon"
  tabindex="-1"
  type="button"
  mat-dialog-close
  mat-icon-button
>
  <mat-icon>close</mat-icon>
</button>

<!-- Content -->
<mat-dialog-content>
  <div class="dialog-form">
    <p>
      <span>
        {{ "failed_import_dialog.import_failed" | translate }}
      </span>
    </p>

    <div class="import-details">
      <!-- File Name -->
      <p>
        <span>{{ "failed_import_dialog.file_name" | translate }}: </span>
        {{ jobDetails.fileName }}
      </p>

      <!-- Failure Reason -->
      <p>
        <span>{{ "failed_import_dialog.failure_reason" | translate }}: </span>
        {{ jobDetails.failedReason | translate }}
      </p>
    </div>
  </div>
</mat-dialog-content>
