import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { BridgeConstants } from '../../../../../assets/structure-config';
import { DefectNoteMode } from '../../../../shared/configs/defect-note-mode.config';
import { NoteDialogComponent } from './note-dialog/note-dialog.component';

import { AgRendererComponent } from 'ag-grid-angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cube-defect-table-note-renderer',
  template: `
    <a (click)="onClickNote()">
      <p>{{ note ? note : '&nbsp;' }}</p>
    </a>
  `,
  styles: [
    `
      a {
        cursor: pointer;
        height: auto;
        width: 100%;
      }

      p {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
      }
    `,
  ],
})
export class DefectTableNoteRendererComponent
  implements AgRendererComponent, OnInit, OnDestroy
{
  @Input() localizzazioneKey: string;
  @Input() localizzazioneParams: any;

  @Output() noteDialogClosed: EventEmitter<any> = new EventEmitter<any>();

  note: string;

  private destroy$: Subject<void> = new Subject<void>();
  private disabled: boolean;
  private noteMode: DefectNoteMode;
  private params: any;
  private title: string;

  constructor(private matDialog: MatDialog) {}

  agInit(params: any): void {
    this.params = params;
    this.noteMode = this.params.mode;
    this.setProperties();
  }

  ngOnInit(): void {
    if (this.localizzazioneParams) {
      this.params = this.localizzazioneParams;
      this.noteMode = DefectNoteMode.tunnelLocalizzazione;
      this.setProperties();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onClickNote(): void {
    const dialogRef = this.matDialog.open(NoteDialogComponent, {
      width: '500px',
      height: '300px',
      disableClose: true,
      data: {
        disabled: this.disabled,
        note: this.note,
        title: this.title,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((note: string) => this.onNoteDialogClosed(note));
  }

  refresh(params: any): boolean {
    return true;
  }

  private onNoteDialogClosed(note: string): void {
    this.note = note;

    switch (this.noteMode) {
      case DefectNoteMode.bridgeDefect:
      case DefectNoteMode.elementiAccessori:
      case DefectNoteMode.tunnelDefect:
        this.params.data.Note = this.note;
        this.refresh(this.params);

        break;

      case DefectNoteMode.bridgeEventualiNote:
        this.params.data.visto = this.note;
        this.refresh(this.params);

        break;

      case DefectNoteMode.tunnelLocalizzazione:
        this.noteDialogClosed.emit(this.note);

        break;

      // case DefectNoteMode.tunnelEventualiNote:
    }
  }

  private setProperties(): void {
    switch (this.noteMode) {
      case DefectNoteMode.bridgeDefect:
      case DefectNoteMode.elementiAccessori:
      case DefectNoteMode.tunnelDefect:
        this.disabled = this.params.colDef.disabled;
        this.note = this.params.data.Note;

        break;

      case DefectNoteMode.bridgeEventualiNote:
        this.disabled = this.params.colDef.disabled;
        this.note = this.params.data.visto;
        this.title = BridgeConstants.eventualiNote;

        break;

      case DefectNoteMode.tunnelLocalizzazione:
        this.disabled = this.params.disabled;
        this.note = this.params.data.L?.[this.localizzazioneKey]?.Note;

        break;

      // case DefectNoteMode.tunnelEventualiNote:
    }

    this.note ??= '';
  }
}
