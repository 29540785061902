export const BridgeCombinedDefects = {
  // INFO: Spalle
  spalle: [
    // INFO: Spalle > Elevazione > Calcestruzzo
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      id: 'b5a60dec-cfa1-42b0-ac8e-e7375008bcbf',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      id: 'affc1d74-7a96-423c-ae13-61a9ceefee58',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      id: 'feeb539c-5452-42b7-858d-a648484e5b1e',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      id: 'ed0c81f5-4c1f-4e3f-a1b2-370de7925121',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      id: '34a710f4-daca-42c0-abf1-999e2a75953b',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Vespai',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      id: '38f9e6bb-9537-47f0-a570-1468219e2ad9',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      id: '59eb3b8c-ca11-4ccb-a33a-aca5f95c84d0',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Armatura ossidata/corrosa',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      id: 'a07469a1-605e-4815-bebe-fa14eda97474',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      id: '6648d71f-6ce9-4e88-931c-08f6cf1fbcde',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._8',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure orizzontali',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      id: 'd1ab30fc-4c2a-4f62-866b-9f6fcd4842fa',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._9',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure verticali',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      id: '23706ea0-3acb-4c49-846c-97ac99ae2463',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      id: 'cd73b8a1-a16b-4177-b5b6-5060307f39a7',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._11',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Lesioni attacco pilastri (spalle a telaio)',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      id: 'b6490a47-167e-445f-99ff-e7124feb4ffd',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      id: '4921324e-75e6-41ce-b80f-6d011008bc19',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Danni da urto',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      id: '60ee80b2-d803-4f81-bf90-8849c3f2585e',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_6',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fuori piombo',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '5',
      id: '14e5afef-c6af-420d-9a73-abddf68165cf',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1a',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Scalzamento (Fondazioni profonde)',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: '577b689d-c05b-447e-9c0f-6cfea1a69020',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1b',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Scalzamento (Fondazioni superficiali)',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: '424998d0-16ee-4254-9ecd-01f43d01427a',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_2',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dilavamento del rilevato',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '1',
      id: 'd2c5cf72-47ff-43e7-af0d-2c4c738a992b',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_3',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dissesto del rilevato - deformazioni',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '2',
      id: '267bc838-9229-4b65-9630-fb69736e9d40',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_4',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dissesto del rilevato - stabilità',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '4',
      id: 'fc2728d3-6ab6-4f6d-8352-dad27568a916',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_5',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Movimenti di fondazione',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: '2a924f93-b4e4-4d79-9d98-b3f9833768a2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._13',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Lesioni da schiacciamento',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      id: '7d61132e-ab90-4695-a5ea-d37140ab1e89',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_4',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Lesioni caratteristiche zone appoggio',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      id: '886fd150-016a-4d46-b86e-7833c076b41c',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Elevazione',
      component_type: 'Spalle',
      element: 'spalle',
      id: '28465cee-6d02-4bdb-94a0-6819af7c1162',
      isQuarterRow: true,
      material: 'Calcestruzzo',
      valorized: true,
      Visto: '',
    },
    // INFO: Spalle > Elevazione > Calcestruzzo
    {
      'Codice difetto': 'Mur_1',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure orizzontali',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      id: 'bb35d006-0db5-4a4f-92b1-c84d02de9ec2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_2',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure verticali',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      id: 'ee3b2264-3c34-4acf-b11d-be6ccd91c07d',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_3',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      id: '6fcc7c44-5266-465b-8a92-da8bb9b58e7d',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_9',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Macchie di umidità / risalita',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: '3ba2e5b9-92f1-4ae6-b769-588ff4102201',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      id: 'd99396f7-d6f8-4bd0-8316-1f201e0e0cdc',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_10',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dilavamento',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '2',
      id: 'ac831f91-905d-4792-9a0b-7182139f49f7',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      id: 'c0c90613-4fda-484a-bd12-e232b47e59c0',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_4',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Porzione di muratura mancante',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      id: '05392b6c-8f1a-4ab1-9a45-dc1805271f58',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Danni da urto',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      id: 'e5aafd0a-b696-4894-b693-6c0306ead737',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_8',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: '96da9b2f-f205-4a91-a030-94022eeddc86',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_11',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Macchie di colore scuro',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: 'a9ad8acc-5f63-485e-857d-b8bb6d39eb50',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_12',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Efflorescenze',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: '1e934768-eed2-4320-bfee-56e65e8fdfc4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_13',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Patina biologica',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: 'b6929cdc-66dc-4294-8ba5-4ed34f320914',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_14',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Polverizzazione',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      id: 'd586097c-619f-4589-aa12-1f54cbcddf26',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_15',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Esfoliazione',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: 'e2cd1acd-bce6-431c-bf82-79e929767fb2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_6',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fuori piombo',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '5',
      id: 'e8c29f3f-9ef6-4398-b4b7-6ee991c70e4a',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1a',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Scalzamento (Fondazioni profonde)',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: '154db40e-ddfd-4bd9-97fe-caed2d672f36',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1b',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Scalzamento (Fondazioni superficiali)',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: '956ad534-368f-4379-9b55-4bdd225aeae2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_2',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dilavamento del rilevato',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '1',
      id: '34192fbc-471e-4319-9a5f-fba76a915682',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_3',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dissesto del rilevato - deformazioni',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '2',
      id: 'a23a3eea-4df2-42d1-ba65-685a1b66b3d7',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_4',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dissesto del rilevato - stabilità',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '4',
      id: '9189c9aa-678a-467f-95f0-5ca3394d29aa',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_5',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Movimenti di fondazione',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: 'e4090a40-acf8-4f86-8c0d-8068c4e45693',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_4',
      component: 'Elevazione',
      component_type: 'Spalle',
      'Descrizione difetto': 'Lesioni caratteristiche zone appoggio',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      id: '93b0e480-4bd9-4cce-9105-700d647bf252',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Elevazione',
      component_type: 'Spalle',
      element: 'spalle',
      id: 'ac6fa120-a95c-42bd-b484-032412d383a5',
      isQuarterRow: true,
      material: 'Muratura',
      valorized: true,
      Visto: '',
    },
    // INFO: Spalle > Paraghiaia > Calcestruzzo
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      id: 'b5a60dec-cfa1-42b0-ac8e-e7375008bcbf',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      id: 'affc1d74-7a96-423c-ae13-61a9ceefee58',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      id: 'feeb539c-5452-42b7-858d-a648484e5b1e',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      id: 'ed0c81f5-4c1f-4e3f-a1b2-370de7925121',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      id: '34a710f4-daca-42c0-abf1-999e2a75953b',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Vespai',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      id: '38f9e6bb-9537-47f0-a570-1468219e2ad9',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      id: '59eb3b8c-ca11-4ccb-a33a-aca5f95c84d0',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Armatura ossidata/corrosa',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      id: 'a07469a1-605e-4815-bebe-fa14eda97474',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      id: '6648d71f-6ce9-4e88-931c-08f6cf1fbcde',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._8',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure orizzontali',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      id: 'd1ab30fc-4c2a-4f62-866b-9f6fcd4842fa',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._9',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure verticali',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      id: '23706ea0-3acb-4c49-846c-97ac99ae2463',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      id: 'cd73b8a1-a16b-4177-b5b6-5060307f39a7',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._11',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Lesioni attacco pilastri (spalle a telaio)',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      id: 'b6490a47-167e-445f-99ff-e7124feb4ffd',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      id: '4921324e-75e6-41ce-b80f-6d011008bc19',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Danni da urto',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      id: '60ee80b2-d803-4f81-bf90-8849c3f2585e',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_6',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fuori piombo',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '5',
      id: '14e5afef-c6af-420d-9a73-abddf68165cf',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1a',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Scalzamento (Fondazioni profonde)',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: '577b689d-c05b-447e-9c0f-6cfea1a69020',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1b',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Scalzamento (Fondazioni superficiali)',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: '424998d0-16ee-4254-9ecd-01f43d01427a',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_2',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dilavamento del rilevato',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '1',
      id: 'd2c5cf72-47ff-43e7-af0d-2c4c738a992b',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_3',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dissesto del rilevato - deformazioni',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '2',
      id: '267bc838-9229-4b65-9630-fb69736e9d40',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_4',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dissesto del rilevato - stabilità',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '4',
      id: 'fc2728d3-6ab6-4f6d-8352-dad27568a916',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_5',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Movimenti di fondazione',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: '2a924f93-b4e4-4d79-9d98-b3f9833768a2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._13',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Lesioni da schiacciamento',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      id: '7d61132e-ab90-4695-a5ea-d37140ab1e89',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_4',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Lesioni caratteristiche zone appoggio',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      id: '886fd150-016a-4d46-b86e-7833c076b41c',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      element: 'spalle',
      id: '28465cee-6d02-4bdb-94a0-6819af7c1162',
      isQuarterRow: true,
      material: 'Calcestruzzo',
      valorized: true,
      Visto: '',
    },
    // INFO: Spalle > Paraghiaia > Muratura
    {
      'Codice difetto': 'Mur_1',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure orizzontali',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      id: 'bb35d006-0db5-4a4f-92b1-c84d02de9ec2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_2',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure verticali',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      id: 'ee3b2264-3c34-4acf-b11d-be6ccd91c07d',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_3',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      id: '6fcc7c44-5266-465b-8a92-da8bb9b58e7d',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_9',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Macchie di umidità / risalita',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: '3ba2e5b9-92f1-4ae6-b769-588ff4102201',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      id: 'd99396f7-d6f8-4bd0-8316-1f201e0e0cdc',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_10',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dilavamento',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '2',
      id: 'ac831f91-905d-4792-9a0b-7182139f49f7',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      id: 'c0c90613-4fda-484a-bd12-e232b47e59c0',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_4',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Porzione di muratura mancante',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      id: '05392b6c-8f1a-4ab1-9a45-dc1805271f58',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Danni da urto',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      id: 'e5aafd0a-b696-4894-b693-6c0306ead737',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_8',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: '96da9b2f-f205-4a91-a030-94022eeddc86',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_11',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Macchie di colore scuro',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: 'a9ad8acc-5f63-485e-857d-b8bb6d39eb50',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_12',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Efflorescenze',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: '1e934768-eed2-4320-bfee-56e65e8fdfc4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_13',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Patina biologica',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: 'b6929cdc-66dc-4294-8ba5-4ed34f320914',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_14',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Polverizzazione',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      id: 'd586097c-619f-4589-aa12-1f54cbcddf26',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_15',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Esfoliazione',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: 'e2cd1acd-bce6-431c-bf82-79e929767fb2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_6',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fuori piombo',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '5',
      id: 'e8c29f3f-9ef6-4398-b4b7-6ee991c70e4a',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1a',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Scalzamento (Fondazioni profonde)',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: '154db40e-ddfd-4bd9-97fe-caed2d672f36',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1b',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Scalzamento (Fondazioni superficiali)',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: '956ad534-368f-4379-9b55-4bdd225aeae2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_2',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dilavamento del rilevato',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '1',
      id: '34192fbc-471e-4319-9a5f-fba76a915682',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_3',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dissesto del rilevato - deformazioni',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '2',
      id: 'a23a3eea-4df2-42d1-ba65-685a1b66b3d7',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_4',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dissesto del rilevato - stabilità',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '4',
      id: '9189c9aa-678a-467f-95f0-5ca3394d29aa',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_5',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Movimenti di fondazione',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: 'e4090a40-acf8-4f86-8c0d-8068c4e45693',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_4',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      'Descrizione difetto': 'Lesioni caratteristiche zone appoggio',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      id: '93b0e480-4bd9-4cce-9105-700d647bf252',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Paraghiaia',
      component_type: 'Spalle',
      element: 'spalle',
      id: 'ac6fa120-a95c-42bd-b484-032412d383a5',
      isQuarterRow: true,
      material: 'Muratura',
      valorized: true,
      Visto: '',
    },
    // INFO: Spalle > Contrafforte > Calcestruzzo
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      id: 'b5a60dec-cfa1-42b0-ac8e-e7375008bcbf',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      id: 'affc1d74-7a96-423c-ae13-61a9ceefee58',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      id: 'feeb539c-5452-42b7-858d-a648484e5b1e',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      id: 'ed0c81f5-4c1f-4e3f-a1b2-370de7925121',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      id: '34a710f4-daca-42c0-abf1-999e2a75953b',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Vespai',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      id: '38f9e6bb-9537-47f0-a570-1468219e2ad9',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      id: '59eb3b8c-ca11-4ccb-a33a-aca5f95c84d0',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Armatura ossidata/corrosa',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      id: 'a07469a1-605e-4815-bebe-fa14eda97474',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      id: '6648d71f-6ce9-4e88-931c-08f6cf1fbcde',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._8',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure orizzontali',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      id: 'd1ab30fc-4c2a-4f62-866b-9f6fcd4842fa',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._9',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure verticali',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      id: '23706ea0-3acb-4c49-846c-97ac99ae2463',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      id: 'cd73b8a1-a16b-4177-b5b6-5060307f39a7',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._11',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Lesioni attacco pilastri (spalle a telaio)',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      id: 'b6490a47-167e-445f-99ff-e7124feb4ffd',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      id: '4921324e-75e6-41ce-b80f-6d011008bc19',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Danni da urto',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      id: '60ee80b2-d803-4f81-bf90-8849c3f2585e',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_6',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fuori piombo',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '5',
      id: '14e5afef-c6af-420d-9a73-abddf68165cf',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1a',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Scalzamento (Fondazioni profonde)',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: '577b689d-c05b-447e-9c0f-6cfea1a69020',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1b',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Scalzamento (Fondazioni superficiali)',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: '424998d0-16ee-4254-9ecd-01f43d01427a',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_2',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dilavamento del rilevato',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '1',
      id: 'd2c5cf72-47ff-43e7-af0d-2c4c738a992b',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_3',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dissesto del rilevato - deformazioni',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '2',
      id: '267bc838-9229-4b65-9630-fb69736e9d40',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_4',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dissesto del rilevato - stabilità',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '4',
      id: 'fc2728d3-6ab6-4f6d-8352-dad27568a916',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_5',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Movimenti di fondazione',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: '2a924f93-b4e4-4d79-9d98-b3f9833768a2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._13',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Lesioni da schiacciamento',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      id: '7d61132e-ab90-4695-a5ea-d37140ab1e89',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_4',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Lesioni caratteristiche zone appoggio',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      id: '886fd150-016a-4d46-b86e-7833c076b41c',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Contrafforte',
      component_type: 'Spalle',
      element: 'spalle',
      id: '28465cee-6d02-4bdb-94a0-6819af7c1162',
      isQuarterRow: true,
      material: 'Calcestruzzo',
      valorized: true,
      Visto: '',
    },
    // INFO: Spalle > Contrafforte > Muratura
    {
      'Codice difetto': 'Mur_1',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure orizzontali',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      id: 'bb35d006-0db5-4a4f-92b1-c84d02de9ec2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_2',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure verticali',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      id: 'ee3b2264-3c34-4acf-b11d-be6ccd91c07d',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_3',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      id: '6fcc7c44-5266-465b-8a92-da8bb9b58e7d',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_9',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Macchie di umidità / risalita',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: '3ba2e5b9-92f1-4ae6-b769-588ff4102201',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      id: 'd99396f7-d6f8-4bd0-8316-1f201e0e0cdc',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_10',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dilavamento',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '2',
      id: 'ac831f91-905d-4792-9a0b-7182139f49f7',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      id: 'c0c90613-4fda-484a-bd12-e232b47e59c0',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_4',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Porzione di muratura mancante',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      id: '05392b6c-8f1a-4ab1-9a45-dc1805271f58',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Danni da urto',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      id: 'e5aafd0a-b696-4894-b693-6c0306ead737',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_8',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: '96da9b2f-f205-4a91-a030-94022eeddc86',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_11',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Macchie di colore scuro',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: 'a9ad8acc-5f63-485e-857d-b8bb6d39eb50',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_12',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Efflorescenze',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: '1e934768-eed2-4320-bfee-56e65e8fdfc4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_13',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Patina biologica',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: 'b6929cdc-66dc-4294-8ba5-4ed34f320914',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_14',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Polverizzazione',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      id: 'd586097c-619f-4589-aa12-1f54cbcddf26',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_15',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Esfoliazione',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: 'e2cd1acd-bce6-431c-bf82-79e929767fb2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_6',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fuori piombo',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '5',
      id: 'e8c29f3f-9ef6-4398-b4b7-6ee991c70e4a',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1a',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Scalzamento (Fondazioni profonde)',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: '154db40e-ddfd-4bd9-97fe-caed2d672f36',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1b',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Scalzamento (Fondazioni superficiali)',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: '956ad534-368f-4379-9b55-4bdd225aeae2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_2',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dilavamento del rilevato',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '1',
      id: '34192fbc-471e-4319-9a5f-fba76a915682',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_3',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dissesto del rilevato - deformazioni',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '2',
      id: 'a23a3eea-4df2-42d1-ba65-685a1b66b3d7',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_4',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dissesto del rilevato - stabilità',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '4',
      id: '9189c9aa-678a-467f-95f0-5ca3394d29aa',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_5',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Movimenti di fondazione',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: 'e4090a40-acf8-4f86-8c0d-8068c4e45693',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_4',
      component: 'Contrafforte',
      component_type: 'Spalle',
      'Descrizione difetto': 'Lesioni caratteristiche zone appoggio',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      id: '93b0e480-4bd9-4cce-9105-700d647bf252',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Contrafforte',
      component_type: 'Spalle',
      element: 'spalle',
      id: 'ac6fa120-a95c-42bd-b484-032412d383a5',
      isQuarterRow: true,
      material: 'Muratura',
      valorized: true,
      Visto: '',
    },
    // INFO: Spalle > Trave Cuscino > Calcestruzzo
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      id: 'b5a60dec-cfa1-42b0-ac8e-e7375008bcbf',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      id: 'affc1d74-7a96-423c-ae13-61a9ceefee58',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      id: 'feeb539c-5452-42b7-858d-a648484e5b1e',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      id: 'ed0c81f5-4c1f-4e3f-a1b2-370de7925121',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      id: '34a710f4-daca-42c0-abf1-999e2a75953b',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Vespai',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      id: '38f9e6bb-9537-47f0-a570-1468219e2ad9',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      id: '59eb3b8c-ca11-4ccb-a33a-aca5f95c84d0',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Armatura ossidata/corrosa',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      id: 'a07469a1-605e-4815-bebe-fa14eda97474',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      id: '6648d71f-6ce9-4e88-931c-08f6cf1fbcde',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._8',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure orizzontali',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      id: 'd1ab30fc-4c2a-4f62-866b-9f6fcd4842fa',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._9',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure verticali',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      id: '23706ea0-3acb-4c49-846c-97ac99ae2463',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      id: 'cd73b8a1-a16b-4177-b5b6-5060307f39a7',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._11',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Lesioni attacco pilastri (spalle a telaio)',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      id: 'b6490a47-167e-445f-99ff-e7124feb4ffd',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      id: '4921324e-75e6-41ce-b80f-6d011008bc19',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Danni da urto',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      id: '60ee80b2-d803-4f81-bf90-8849c3f2585e',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_6',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fuori piombo',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '5',
      id: '14e5afef-c6af-420d-9a73-abddf68165cf',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1a',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Scalzamento (Fondazioni profonde)',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: '577b689d-c05b-447e-9c0f-6cfea1a69020',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1b',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Scalzamento (Fondazioni superficiali)',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: '424998d0-16ee-4254-9ecd-01f43d01427a',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_2',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dilavamento del rilevato',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '1',
      id: 'd2c5cf72-47ff-43e7-af0d-2c4c738a992b',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_3',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dissesto del rilevato - deformazioni',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '2',
      id: '267bc838-9229-4b65-9630-fb69736e9d40',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_4',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dissesto del rilevato - stabilità',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '4',
      id: 'fc2728d3-6ab6-4f6d-8352-dad27568a916',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_5',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Movimenti di fondazione',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: '2a924f93-b4e4-4d79-9d98-b3f9833768a2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._13',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Lesioni da schiacciamento',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      id: '7d61132e-ab90-4695-a5ea-d37140ab1e89',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_4',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Lesioni caratteristiche zone appoggio',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      id: '886fd150-016a-4d46-b86e-7833c076b41c',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      element: 'spalle',
      id: '28465cee-6d02-4bdb-94a0-6819af7c1162',
      isQuarterRow: true,
      material: 'Calcestruzzo',
      valorized: true,
      Visto: '',
    },
    // INFO: Spalle > Trave Cuscino > Muratura
    {
      'Codice difetto': 'Mur_1',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure orizzontali',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      id: 'bb35d006-0db5-4a4f-92b1-c84d02de9ec2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_2',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure verticali',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      id: 'ee3b2264-3c34-4acf-b11d-be6ccd91c07d',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_3',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      id: '6fcc7c44-5266-465b-8a92-da8bb9b58e7d',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_9',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Macchie di umidità / risalita',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: '3ba2e5b9-92f1-4ae6-b769-588ff4102201',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      id: 'd99396f7-d6f8-4bd0-8316-1f201e0e0cdc',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_10',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dilavamento',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '2',
      id: 'ac831f91-905d-4792-9a0b-7182139f49f7',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      id: 'c0c90613-4fda-484a-bd12-e232b47e59c0',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_4',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Porzione di muratura mancante',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      id: '05392b6c-8f1a-4ab1-9a45-dc1805271f58',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Danni da urto',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      id: 'e5aafd0a-b696-4894-b693-6c0306ead737',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_8',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: '96da9b2f-f205-4a91-a030-94022eeddc86',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_11',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Macchie di colore scuro',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: 'a9ad8acc-5f63-485e-857d-b8bb6d39eb50',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_12',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Efflorescenze',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: '1e934768-eed2-4320-bfee-56e65e8fdfc4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_13',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Patina biologica',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: 'b6929cdc-66dc-4294-8ba5-4ed34f320914',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_14',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Polverizzazione',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      id: 'd586097c-619f-4589-aa12-1f54cbcddf26',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_15',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Esfoliazione',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: 'e2cd1acd-bce6-431c-bf82-79e929767fb2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_6',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fuori piombo',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '5',
      id: 'e8c29f3f-9ef6-4398-b4b7-6ee991c70e4a',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1a',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Scalzamento (Fondazioni profonde)',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: '154db40e-ddfd-4bd9-97fe-caed2d672f36',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1b',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Scalzamento (Fondazioni superficiali)',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: '956ad534-368f-4379-9b55-4bdd225aeae2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_2',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dilavamento del rilevato',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '1',
      id: '34192fbc-471e-4319-9a5f-fba76a915682',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_3',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dissesto del rilevato - deformazioni',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '2',
      id: 'a23a3eea-4df2-42d1-ba65-685a1b66b3d7',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_4',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dissesto del rilevato - stabilità',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '4',
      id: '9189c9aa-678a-467f-95f0-5ca3394d29aa',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_5',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Movimenti di fondazione',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: 'e4090a40-acf8-4f86-8c0d-8068c4e45693',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_4',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      'Descrizione difetto': 'Lesioni caratteristiche zone appoggio',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      id: '93b0e480-4bd9-4cce-9105-700d647bf252',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Trave Cuscino',
      component_type: 'Spalle',
      element: 'spalle',
      id: 'ac6fa120-a95c-42bd-b484-032412d383a5',
      isQuarterRow: true,
      material: 'Muratura',
      valorized: true,
      Visto: '',
    },
    // INFO: Spalle > Muro Andatore > Calcestruzzo
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      id: 'b5a60dec-cfa1-42b0-ac8e-e7375008bcbf',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      id: 'affc1d74-7a96-423c-ae13-61a9ceefee58',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      id: 'feeb539c-5452-42b7-858d-a648484e5b1e',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      id: 'ed0c81f5-4c1f-4e3f-a1b2-370de7925121',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      id: '34a710f4-daca-42c0-abf1-999e2a75953b',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Vespai',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      id: '38f9e6bb-9537-47f0-a570-1468219e2ad9',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      id: '59eb3b8c-ca11-4ccb-a33a-aca5f95c84d0',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Armatura ossidata/corrosa',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      id: 'a07469a1-605e-4815-bebe-fa14eda97474',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      id: '6648d71f-6ce9-4e88-931c-08f6cf1fbcde',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._8',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure orizzontali',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      id: 'd1ab30fc-4c2a-4f62-866b-9f6fcd4842fa',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._9',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure verticali',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      id: '23706ea0-3acb-4c49-846c-97ac99ae2463',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      id: 'cd73b8a1-a16b-4177-b5b6-5060307f39a7',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._11',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Lesioni attacco pilastri (spalle a telaio)',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      id: 'b6490a47-167e-445f-99ff-e7124feb4ffd',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      id: '4921324e-75e6-41ce-b80f-6d011008bc19',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Danni da urto',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      id: '60ee80b2-d803-4f81-bf90-8849c3f2585e',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_6',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fuori piombo',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '5',
      id: '14e5afef-c6af-420d-9a73-abddf68165cf',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1a',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Scalzamento (Fondazioni profonde)',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: '577b689d-c05b-447e-9c0f-6cfea1a69020',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1b',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Scalzamento (Fondazioni superficiali)',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: '424998d0-16ee-4254-9ecd-01f43d01427a',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_2',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dilavamento del rilevato',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '1',
      id: 'd2c5cf72-47ff-43e7-af0d-2c4c738a992b',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_3',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dissesto del rilevato - deformazioni',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '2',
      id: '267bc838-9229-4b65-9630-fb69736e9d40',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_4',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dissesto del rilevato - stabilità',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '4',
      id: 'fc2728d3-6ab6-4f6d-8352-dad27568a916',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_5',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Movimenti di fondazione',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: '2a924f93-b4e4-4d79-9d98-b3f9833768a2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._13',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Lesioni da schiacciamento',
      element: 'spalle',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      id: '7d61132e-ab90-4695-a5ea-d37140ab1e89',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_4',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Lesioni caratteristiche zone appoggio',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      id: '886fd150-016a-4d46-b86e-7833c076b41c',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      element: 'spalle',
      id: '28465cee-6d02-4bdb-94a0-6819af7c1162',
      isQuarterRow: true,
      material: 'Calcestruzzo',
      valorized: true,
      Visto: '',
    },
    // INFO: Spalle > Muro Andatore > Muratura
    {
      'Codice difetto': 'Mur_1',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure orizzontali',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      id: 'bb35d006-0db5-4a4f-92b1-c84d02de9ec2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_2',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure verticali',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      id: 'ee3b2264-3c34-4acf-b11d-be6ccd91c07d',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_3',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      id: '6fcc7c44-5266-465b-8a92-da8bb9b58e7d',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_9',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Macchie di umidità / risalita',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: '3ba2e5b9-92f1-4ae6-b769-588ff4102201',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      id: 'd99396f7-d6f8-4bd0-8316-1f201e0e0cdc',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_10',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dilavamento',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '2',
      id: 'ac831f91-905d-4792-9a0b-7182139f49f7',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      id: 'c0c90613-4fda-484a-bd12-e232b47e59c0',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_4',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Porzione di muratura mancante',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      id: '05392b6c-8f1a-4ab1-9a45-dc1805271f58',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Danni da urto',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      id: 'e5aafd0a-b696-4894-b693-6c0306ead737',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_8',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: '96da9b2f-f205-4a91-a030-94022eeddc86',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_11',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Macchie di colore scuro',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: 'a9ad8acc-5f63-485e-857d-b8bb6d39eb50',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_12',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Efflorescenze',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: '1e934768-eed2-4320-bfee-56e65e8fdfc4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_13',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Patina biologica',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: 'b6929cdc-66dc-4294-8ba5-4ed34f320914',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_14',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Polverizzazione',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      id: 'd586097c-619f-4589-aa12-1f54cbcddf26',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_15',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Esfoliazione',
      element: 'spalle',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      id: 'e2cd1acd-bce6-431c-bf82-79e929767fb2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_6',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Fuori piombo',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '5',
      id: 'e8c29f3f-9ef6-4398-b4b7-6ee991c70e4a',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1a',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Scalzamento (Fondazioni profonde)',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: '154db40e-ddfd-4bd9-97fe-caed2d672f36',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1b',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Scalzamento (Fondazioni superficiali)',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: '956ad534-368f-4379-9b55-4bdd225aeae2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_2',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dilavamento del rilevato',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '1',
      id: '34192fbc-471e-4319-9a5f-fba76a915682',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_3',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dissesto del rilevato - deformazioni',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '2',
      id: 'a23a3eea-4df2-42d1-ba65-685a1b66b3d7',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_4',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Dissesto del rilevato - stabilità',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '4',
      id: '9189c9aa-678a-467f-95f0-5ca3394d29aa',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_5',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Movimenti di fondazione',
      element: 'spalle',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      id: 'e4090a40-acf8-4f86-8c0d-8068c4e45693',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_4',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      'Descrizione difetto': 'Lesioni caratteristiche zone appoggio',
      element: 'spalle',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      id: '93b0e480-4bd9-4cce-9105-700d647bf252',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Muro Andatore',
      component_type: 'Spalle',
      element: 'spalle',
      id: 'ac6fa120-a95c-42bd-b484-032412d383a5',
      isQuarterRow: true,
      material: 'Muratura',
      valorized: true,
      Visto: '',
    },
  ],
  // INFO: Pile
  pile: [
    // INFO: Pile > Elevazione > Calcestruzzo
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Vespai',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Armatura ossidata/corrosa',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._15',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni in corrispondenza staffe',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._8',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure orizzontali',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._9',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure verticali',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._16',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Staffe scoperte/ossidate',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._11',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni attacco pilastri',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._23',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Rottura staffe',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._17',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Armatura longitudinale deformata',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Danni da urto',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_6',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Fuori piombo',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1a',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni profonde)',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1b',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni superficiali)',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_5',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Movimenti di fondazione',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._13',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni da schiacciamento',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_4',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni caratteristiche zone appoggio',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Elevazione',
      component_type: 'Pile',
      element: 'pile',
      isQuarterRow: true,
      material: 'Calcestruzzo',
      valorized: true,
      Visto: '',
    },
    // INFO: Pile > Elevazione > Metallo o Acciaio
    {
      'Codice difetto': 'Acc_1',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Difetti di saldature',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_2',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Rottura di saldature',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_3',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Sfogliamento vernice',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_4',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Difetti di chiodatura',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_5',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Bulloni allentati',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_6',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Bulloni tranciati',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_7',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Deformazioni anime / piattabande',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_8',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Deformazioni pareti travi scatolari',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_9',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni ai nodi',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_10',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Corrosione',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_11',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Ossidazione',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Danni da urto',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_6',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Fuori piombo',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1a',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni profonde)',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1b',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni superficiali)',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_5',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Movimenti di fondazione',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Elevazione',
      component_type: 'Pile',
      element: 'pile',
      isQuarterRow: true,
      material: 'Metallo o Acciaio',
      valorized: true,
      Visto: '',
    },
    // INFO: Pile > Elevazione > Muratura
    {
      'Codice difetto': 'Mur_1',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure orizzontali',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      fotoName: 'Pile 2  (P2) - Elevazione (1)',
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_2',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure verticali',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      fotoName: 'Pile 2  (P2) - Elevazione (1)',
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_3',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      fotoName: 'Pile 2  (P2) - Elevazione (1)',
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_9',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Macchie di umidità / risalita',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      fotoName: 'Pile 2  (P2) - Elevazione (1)',
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      fotoName: 'Pile 2  (P2) - Elevazione (1)',
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_10',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Dilavamento',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      fotoName: 'Pile 2  (P2) - Elevazione (1)',
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      fotoName: 'Pile 2  (P2) - Elevazione (1)',
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_4',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Porzione di muratura mancante',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      fotoName: 'Pile 2  (P2) - Elevazione (1)',
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Danni da urto',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      fotoName: 'Pile 2  (P2) - Elevazione (1)',
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_8',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      fotoName: 'Pile 2  (P2) - Elevazione (1)',
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_11',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Macchie di colore scuro',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      fotoName: 'Pile 2  (P2) - Elevazione (1)',
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_12',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Efflorescenze',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      fotoName: 'Pile 2  (P2) - Elevazione (1)',
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_13',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Patina biologica',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      fotoName: 'Pile 2  (P2) - Elevazione (1)',
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_14',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Polverizzazione',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      fotoName: 'Pile 2  (P2) - Elevazione (1)',
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_15',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Esfoliazione',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      fotoName: 'Pile 2  (P2) - Elevazione (1)',
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_6',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Fuori piombo',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      fotoName: 'Pile 2  (P2) - Elevazione (1)',
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1a',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni profonde)',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      fotoName: 'Pile 2  (P2) - Elevazione (1)',
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1b',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni superficiali)',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      fotoName: 'Pile 2  (P2) - Elevazione (1)',
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_5',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Movimenti di fondazione',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      fotoName: 'Pile 2  (P2) - Elevazione (1)',
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_4',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni caratteristiche zone appoggio',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      fotoName: 'Pile 2  (P2) - Elevazione (1)',
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Elevazione',
      component_type: 'Pile',
      'Descrizione difetto': '',
      element: 'pile',
      'Estensione K1': '',
      fotoName: 'Pile 2  (P2) - Elevazione (1)',
      G: '',
      'Intensità K2': '',
      isQuarterRow: true,
      material: 'Muratura',
      'N° foto': '',
      NA: '',
      Note: '',
      NP: '',
      NR: '',
      PS: '',
      'Sup Est Dif': '',
      valorized: true,
      visto: '',
      Visto: '',
    },
    // INFO: Pile > Pulvino > Calcestruzzo
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Vespai',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Armatura ossidata/corrosa',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._15',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni in corrispondenza staffe',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._8',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure orizzontali',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._9',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure verticali',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._16',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Staffe scoperte/ossidate',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._11',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni attacco pilastri',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._23',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Rottura staffe',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._17',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Armatura longitudinale deformata',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Danni da urto',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_6',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Fuori piombo',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1a',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni profonde)',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1b',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni superficiali)',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_5',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Movimenti di fondazione',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._13',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni da schiacciamento',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_4',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni caratteristiche zone appoggio',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Pulvino',
      component_type: 'Pile',
      element: 'pile',
      isQuarterRow: true,
      material: 'Calcestruzzo',
      valorized: true,
      Visto: '',
    },
    // INFO: Pile > Pulvino > Metallo o Acciaio
    {
      'Codice difetto': 'Acc_1',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Difetti di saldature',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_2',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Rottura di saldature',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_3',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Sfogliamento vernice',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_4',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Difetti di chiodatura',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_5',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Bulloni allentati',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_6',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Bulloni tranciati',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_7',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Deformazioni anime / piattabande',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_8',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Deformazioni pareti travi scatolari',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_9',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni ai nodi',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_10',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Corrosione',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_11',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Ossidazione',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Danni da urto',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_6',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Fuori piombo',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1a',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni profonde)',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1b',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni superficiali)',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_5',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Movimenti di fondazione',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Pulvino',
      component_type: 'Pile',
      element: 'pile',
      isQuarterRow: true,
      material: 'Metallo o Acciaio',
      valorized: true,
      Visto: '',
    },
    // INFO: Pile > Pulvino > Muratura
    {
      'Codice difetto': 'Mur_1',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure orizzontali',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_2',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure verticali',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_3',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_9',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Macchie di umidità / risalita',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_10',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Dilavamento',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_4',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Porzione di muratura mancante',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Danni da urto',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_8',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_11',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Macchie di colore scuro',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_12',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Efflorescenze',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_13',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Patina biologica',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_14',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Polverizzazione',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_15',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Esfoliazione',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_6',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Fuori piombo',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1a',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni profonde)',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1b',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni superficiali)',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_5',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Movimenti di fondazione',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_4',
      component: 'Pulvino',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni caratteristiche zone appoggio',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Pulvino',
      component_type: 'Pile',
      element: 'pile',
      isQuarterRow: true,
      material: 'Muratura',
      valorized: true,
      Visto: '',
    },
    // INFO: Pile > Interconnesioni Trasversali (compresi diaframmi) > Calcestruzzo
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Vespai',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Armatura ossidata/corrosa',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._15',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni in corrispondenza staffe',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._8',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure orizzontali',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._9',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure verticali',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._16',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Staffe scoperte/ossidate',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._11',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni attacco pilastri',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._23',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Rottura staffe',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._17',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Armatura longitudinale deformata',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Danni da urto',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_6',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Fuori piombo',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1a',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni profonde)',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1b',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni superficiali)',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_5',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Movimenti di fondazione',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._13',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni da schiacciamento',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_4',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni caratteristiche zone appoggio',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      element: 'pile',
      isQuarterRow: true,
      material: 'Calcestruzzo',
      valorized: true,
      Visto: '',
    },
    // INFO: Pile > Interconnesioni Trasversali (compresi diaframmi) > Metallo o Acciaio
    {
      'Codice difetto': 'Acc_1',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Difetti di saldature',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_2',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Rottura di saldature',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_3',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Sfogliamento vernice',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_4',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Difetti di chiodatura',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_5',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Bulloni allentati',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_6',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Bulloni tranciati',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_7',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Deformazioni anime / piattabande',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_8',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Deformazioni pareti travi scatolari',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_9',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni ai nodi',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_10',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Corrosione',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_11',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Ossidazione',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Danni da urto',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_6',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Fuori piombo',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1a',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni profonde)',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1b',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni superficiali)',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_5',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Movimenti di fondazione',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      element: 'pile',
      isQuarterRow: true,
      material: 'Metallo o Acciaio',
      valorized: true,
      Visto: '',
    },
    // INFO: Pile > Interconnesioni Trasversali (compresi diaframmi) > Muratura
    {
      'Codice difetto': 'Mur_1',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure orizzontali',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_2',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure verticali',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_3',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_9',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Macchie di umidità / risalita',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_10',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Dilavamento',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_4',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Porzione di muratura mancante',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Danni da urto',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_8',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_11',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Macchie di colore scuro',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_12',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Efflorescenze',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_13',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Patina biologica',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_14',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Polverizzazione',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_15',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Esfoliazione',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_6',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Fuori piombo',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1a',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni profonde)',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1b',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni superficiali)',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_5',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Movimenti di fondazione',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_4',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni caratteristiche zone appoggio',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Pile',
      element: 'pile',
      isQuarterRow: true,
      material: 'Muratura',
      valorized: true,
      Visto: '',
    },
    // INFO: Pile > Interconnesioni Longitudinali > Calcestruzzo
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Vespai',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Armatura ossidata/corrosa',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._15',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni in corrispondenza staffe',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._8',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure orizzontali',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._9',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure verticali',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._16',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Staffe scoperte/ossidate',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._11',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni attacco pilastri',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._23',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Rottura staffe',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._17',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Armatura longitudinale deformata',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Danni da urto',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_6',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Fuori piombo',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1a',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni profonde)',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1b',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni superficiali)',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_5',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Movimenti di fondazione',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._13',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni da schiacciamento',
      element: 'pile',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_4',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni caratteristiche zone appoggio',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      element: 'pile',
      isQuarterRow: true,
      material: 'Calcestruzzo',
      valorized: true,
      Visto: '',
    },
    // INFO: Pile > Interconnesioni Longitudinali > Metallo o Acciaio
    {
      'Codice difetto': 'Acc_1',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Difetti di saldature',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_2',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Rottura di saldature',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_3',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Sfogliamento vernice',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_4',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Difetti di chiodatura',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_5',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Bulloni allentati',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_6',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Bulloni tranciati',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_7',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Deformazioni anime / piattabande',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_8',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Deformazioni pareti travi scatolari',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_9',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni ai nodi',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_10',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Corrosione',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_11',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Ossidazione',
      element: 'pile',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Danni da urto',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_6',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Fuori piombo',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1a',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni profonde)',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1b',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni superficiali)',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_5',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Movimenti di fondazione',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      element: 'pile',
      isQuarterRow: true,
      material: 'Metallo o Acciaio',
      valorized: true,
      Visto: '',
    },
    // INFO: Pile > Interconnesioni Longitudinali > Muratura
    {
      'Codice difetto': 'Mur_1',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure orizzontali',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_2',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure verticali',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_3',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_9',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Macchie di umidità / risalita',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_10',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Dilavamento',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_4',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Porzione di muratura mancante',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Danni da urto',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_8',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_11',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Macchie di colore scuro',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_12',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Efflorescenze',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_13',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Patina biologica',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_14',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Polverizzazione',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_15',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Esfoliazione',
      element: 'pile',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_6',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Fuori piombo',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1a',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni profonde)',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1b',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni superficiali)',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_5',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Movimenti di fondazione',
      element: 'pile',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_4',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni caratteristiche zone appoggio',
      element: 'pile',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Pile',
      element: 'pile',
      isQuarterRow: true,
      material: 'Muratura',
      valorized: true,
      Visto: '',
    },
  ],
  // INFO: Archi
  archi: [
    // INFO: Archi > Struttura Principale > Calcestruzzo
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'archi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Vespai',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Armatura ossidata / corrosa',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._18',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._19',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._16',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Staffe scoperte/ossidate',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._20',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Distacco del timpano',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Danni da urto',
      element: 'archi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Struttura Principale',
      component_type: 'Archi',
      element: 'archi',
      isQuarterRow: true,
      material: 'Calcestruzzo',
      valorized: true,
      Visto: '',
    },
    // INFO: Archi > Struttura Principale > Legno
    {
      'Codice difetto': 'Legno_1',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di umidità',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      fotoName: 'Archi 1  (A1) - Struttura Principale (1)',
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_2',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Attacco di funghi',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      fotoName: 'Archi 1  (A1) - Struttura Principale (1)',
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_3',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Attacco di insetti',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      fotoName: 'Archi 1  (A1) - Struttura Principale (1)',
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_4',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure elicoidali',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      fotoName: 'Archi 1  (A1) - Struttura Principale (1)',
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_5',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      fotoName: 'Archi 1  (A1) - Struttura Principale (1)',
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_6',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      fotoName: 'Archi 1  (A1) - Struttura Principale (1)',
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Legno',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_7',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': "Ristagni / trappole d'acqua",
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      fotoName: 'Archi 1  (A1) - Struttura Principale (1)',
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_8',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Danni da urto',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      fotoName: 'Archi 1  (A1) - Struttura Principale (1)',
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_9',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Bulloni Allentati',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      fotoName: 'Archi 1  (A1) - Struttura Principale (1)',
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      material: 'Legno',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_10',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Delaminazione (legno lamellare)',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      fotoName: 'Archi 1  (A1) - Struttura Principale (1)',
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_11',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Connessioni deteriorate',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      fotoName: 'Archi 1  (A1) - Struttura Principale (1)',
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': '',
      element: 'archi',
      'Estensione K1': '',
      fotoName: 'Archi 1  (A1) - Struttura Principale (1)',
      G: '',
      'Intensità K2': '',
      isQuarterRow: true,
      material: 'Legno',
      'N° foto': '',
      NA: '',
      Note: '',
      NP: '',
      NR: '',
      PS: '',
      'Sup Est Dif': '',
      valorized: true,
      visto: '',
      Visto: '',
    },
    // INFO: Archi > Struttura Principale > Metallo o Acciaio
    {
      'Codice difetto': 'Acc_1',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Difetti di saldature',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      fotoName: 'Archi 1  (A1) - Struttura Principale (1)',
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_2',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Rottura di saldature',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      fotoName: 'Archi 1  (A1) - Struttura Principale (1)',
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_3',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Sfogliamento vernice',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      fotoName: 'Archi 1  (A1) - Struttura Principale (1)',
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_4',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Difetti di chiodatura',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      fotoName: 'Archi 1  (A1) - Struttura Principale (1)',
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_5',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Bulloni allentati',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      fotoName: 'Archi 1  (A1) - Struttura Principale (1)',
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_6',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Bulloni tranciati',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      fotoName: 'Archi 1  (A1) - Struttura Principale (1)',
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_7',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Deformazioni anime / piattabande',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      fotoName: 'Archi 1  (A1) - Struttura Principale (1)',
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_8',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Deformazioni pareti travi scatolari',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      fotoName: 'Archi 1  (A1) - Struttura Principale (1)',
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'archi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      fotoName: 'Archi 1  (A1) - Struttura Principale (1)',
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_9',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Lesioni ai nodi',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      fotoName: 'Archi 1  (A1) - Struttura Principale (1)',
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_10',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Corrosione',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      fotoName: 'Archi 1  (A1) - Struttura Principale (1)',
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_11',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Ossidazione',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      fotoName: 'Archi 1  (A1) - Struttura Principale (1)',
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': {},
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      'Sup Est Dif': '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': '',
      element: 'archi',
      'Estensione K1': '',
      fotoName: 'Archi 1  (A1) - Struttura Principale (1)',
      G: '',
      'Intensità K2': '',
      isQuarterRow: true,
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: '',
      Note: '',
      NP: '',
      NR: '',
      PS: '',
      'Sup Est Dif': '',
      valorized: true,
      visto: '',
      Visto: '',
    },
    // INFO: Archi > Struttura Principale > Muratura
    {
      'Codice difetto': 'Mur_1',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure orizzontali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_2',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure verticali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_3',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_7',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Distacco del timpano',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_9',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di umidità / risalita',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'archi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_10',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Dilavamento',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_4',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Porzione di muratura mancante',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_8',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_11',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di colore scuro',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_12',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Efflorescenze',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_13',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Patina biologica',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_14',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Polverizzazione',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_15',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Esfoliazione',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_6',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_5',
      component: 'Struttura Principale',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Struttura Principale',
      component_type: 'Archi',
      element: 'archi',
      isQuarterRow: true,
      material: 'Muratura',
      valorized: true,
      Visto: '',
    },
    // INFO: Archi > Soletta > Calcestruzzo
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'archi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Vespai',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Armatura ossidata / corrosa',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._18',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._19',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._16',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Staffe scoperte/ossidate',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._20',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Distacco del timpano',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Danni da urto',
      element: 'archi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Soletta',
      component_type: 'Archi',
      element: 'archi',
      isQuarterRow: true,
      material: 'Calcestruzzo',
      valorized: true,
      Visto: '',
    },
    // INFO: Archi > Soletta > Legno
    {
      'Codice difetto': 'Legno_1',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di umidità',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_2',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Attacco di funghi',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_3',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Attacco di insetti',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_4',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure elicoidali',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_5',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_6',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_7',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': "Ristagni / trappole d'acqua",
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_8',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Danni da urto',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_9',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Bulloni Allentati',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_10',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Delaminazione (legno lamellare)',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_11',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Connessioni deteriorate',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Soletta',
      component_type: 'Archi',
      element: 'archi',
      isQuarterRow: true,
      material: 'Legno',
      valorized: true,
      Visto: '',
    },
    // INFO: Archi > Soletta > Metallo o Acciaio
    {
      'Codice difetto': 'Acc_1',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Difetti di saldature',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_2',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Rottura di saldature',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_3',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Sfogliamento vernice',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_4',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Difetti di chiodatura',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_5',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Bulloni allentati',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_6',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Bulloni tranciati',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_7',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Deformazioni anime / piattabande',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_8',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Deformazioni pareti travi scatolari',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'archi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_9',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Lesioni ai nodi',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_10',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Corrosione',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_11',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Ossidazione',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Soletta',
      component_type: 'Archi',
      element: 'archi',
      isQuarterRow: true,
      material: 'Metallo o Acciaio',
      valorized: true,
      Visto: '',
    },
    // INFO: Archi > Soletta > Muratura
    {
      'Codice difetto': 'Mur_1',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure orizzontali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_2',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure verticali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_3',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_7',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Distacco del timpano',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_9',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di umidità / risalita',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'archi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_10',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Dilavamento',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_4',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Porzione di muratura mancante',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_8',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_11',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di colore scuro',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_12',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Efflorescenze',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_13',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Patina biologica',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_14',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Polverizzazione',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_15',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Esfoliazione',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_6',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_5',
      component: 'Soletta',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Soletta',
      component_type: 'Archi',
      element: 'archi',
      isQuarterRow: true,
      material: 'Muratura',
      valorized: true,
      Visto: '',
    },
    // INFO: Archi > Interconnesioni Trasversali (compresi diaframmi) > Calcestruzzo
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'archi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Vespai',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Armatura ossidata / corrosa',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._18',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._19',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._16',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Staffe scoperte/ossidate',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._20',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Distacco del timpano',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Danni da urto',
      element: 'archi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      element: 'archi',
      isQuarterRow: true,
      material: 'Calcestruzzo',
      valorized: true,
      Visto: '',
    },
    // INFO: Archi > Interconnesioni Trasversali (compresi diaframmi) > Legno
    {
      'Codice difetto': 'Legno_1',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di umidità',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_2',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Attacco di funghi',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_3',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Attacco di insetti',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_4',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure elicoidali',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_5',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_6',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_7',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': "Ristagni / trappole d'acqua",
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_8',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Danni da urto',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_9',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Bulloni Allentati',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_10',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Delaminazione (legno lamellare)',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_11',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Connessioni deteriorate',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      element: 'archi',
      isQuarterRow: true,
      material: 'Legno',
      valorized: true,
      Visto: '',
    },
    // INFO: Archi > Interconnesioni Trasversali (compresi diaframmi) > Metallo o Acciaio
    {
      'Codice difetto': 'Acc_1',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Difetti di saldature',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_2',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Rottura di saldature',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_3',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Sfogliamento vernice',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_4',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Difetti di chiodatura',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_5',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Bulloni allentati',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_6',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Bulloni tranciati',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_7',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Deformazioni anime / piattabande',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_8',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Deformazioni pareti travi scatolari',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'archi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_9',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Lesioni ai nodi',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_10',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Corrosione',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_11',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Ossidazione',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      element: 'archi',
      isQuarterRow: true,
      material: 'Metallo o Acciaio',
      valorized: true,
      Visto: '',
    },
    // INFO: Archi > Interconnesioni Trasversali (compresi diaframmi) > Muratura
    {
      'Codice difetto': 'Mur_1',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure orizzontali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_2',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure verticali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_3',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_7',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Distacco del timpano',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_9',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di umidità / risalita',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'archi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_10',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Dilavamento',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_4',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Porzione di muratura mancante',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_8',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_11',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di colore scuro',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_12',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Efflorescenze',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_13',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Patina biologica',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_14',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Polverizzazione',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_15',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Esfoliazione',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_6',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_5',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Interconnesioni Trasversali (compresi diaframmi)',
      component_type: 'Archi',
      element: 'archi',
      isQuarterRow: true,
      material: 'Muratura',
      valorized: true,
      Visto: '',
    },
    // INFO: Archi > Interconnesioni Longitudinali > Calcestruzzo
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'archi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Vespai',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Armatura ossidata / corrosa',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._18',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._19',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._16',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Staffe scoperte/ossidate',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._20',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Distacco del timpano',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Danni da urto',
      element: 'archi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      element: 'archi',
      isQuarterRow: true,
      material: 'Calcestruzzo',
      valorized: true,
      Visto: '',
    },
    // INFO: Archi > Interconnesioni Longitudinali > Legno
    {
      'Codice difetto': 'Legno_1',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di umidità',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_2',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Attacco di funghi',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_3',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Attacco di insetti',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_4',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure elicoidali',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_5',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_6',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_7',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': "Ristagni / trappole d'acqua",
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_8',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Danni da urto',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_9',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Bulloni Allentati',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_10',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Delaminazione (legno lamellare)',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_11',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Connessioni deteriorate',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      element: 'archi',
      isQuarterRow: true,
      material: 'Legno',
      valorized: true,
      Visto: '',
    },
    // INFO: Archi > Interconnesioni Longitudinali > Metallo o Acciaio
    {
      'Codice difetto': 'Acc_1',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Difetti di saldature',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_2',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Rottura di saldature',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_3',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Sfogliamento vernice',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_4',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Difetti di chiodatura',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_5',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Bulloni allentati',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_6',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Bulloni tranciati',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_7',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Deformazioni anime / piattabande',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_8',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Deformazioni pareti travi scatolari',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'archi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_9',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Lesioni ai nodi',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_10',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Corrosione',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_11',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Ossidazione',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      element: 'archi',
      isQuarterRow: true,
      material: 'Metallo o Acciaio',
      valorized: true,
      Visto: '',
    },
    // INFO: Archi > Interconnesioni Longitudinali > Muratura
    {
      'Codice difetto': 'Mur_1',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure orizzontali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_2',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure verticali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_3',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_7',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Distacco del timpano',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_9',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di umidità / risalita',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'archi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_10',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Dilavamento',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_4',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Porzione di muratura mancante',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_8',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_11',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di colore scuro',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_12',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Efflorescenze',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_13',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Patina biologica',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_14',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Polverizzazione',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_15',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Esfoliazione',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_6',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_5',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Interconnesioni Longitudinali',
      component_type: 'Archi',
      element: 'archi',
      isQuarterRow: true,
      material: 'Muratura',
      valorized: true,
      Visto: '',
    },
    // INFO: Archi > Timpano > Calcestruzzo
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'archi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Vespai',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Armatura ossidata / corrosa',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._18',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._19',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._16',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Staffe scoperte/ossidate',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._20',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Distacco del timpano',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Danni da urto',
      element: 'archi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Timpano',
      component_type: 'Archi',
      element: 'archi',
      isQuarterRow: true,
      material: 'Calcestruzzo',
      valorized: true,
      Visto: '',
    },
    // INFO: Archi > Timpano > Legno
    {
      'Codice difetto': 'Legno_1',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di umidità',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_2',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Attacco di funghi',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_3',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Attacco di insetti',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_4',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure elicoidali',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_5',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_6',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_7',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': "Ristagni / trappole d'acqua",
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_8',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Danni da urto',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_9',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Bulloni Allentati',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_10',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Delaminazione (legno lamellare)',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_11',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Connessioni deteriorate',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Timpano',
      component_type: 'Archi',
      element: 'archi',
      isQuarterRow: true,
      material: 'Legno',
      valorized: true,
      Visto: '',
    },
    // INFO: Archi > Timpano > Metallo o Acciaio
    {
      'Codice difetto': 'Acc_1',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Difetti di saldature',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_2',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Rottura di saldature',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_3',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Sfogliamento vernice',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_4',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Difetti di chiodatura',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_5',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Bulloni allentati',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_6',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Bulloni tranciati',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_7',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Deformazioni anime / piattabande',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_8',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Deformazioni pareti travi scatolari',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'archi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_9',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Lesioni ai nodi',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_10',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Corrosione',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_11',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Ossidazione',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Timpano',
      component_type: 'Archi',
      element: 'archi',
      isQuarterRow: true,
      material: 'Metallo o Acciaio',
      valorized: true,
      Visto: '',
    },
    // INFO: Archi > Timpano > Muratura
    {
      'Codice difetto': 'Mur_1',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure orizzontali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_2',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure verticali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_3',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_7',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Distacco del timpano',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_9',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di umidità / risalita',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'archi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_10',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Dilavamento',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_4',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Porzione di muratura mancante',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_8',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_11',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di colore scuro',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_12',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Efflorescenze',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_13',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Patina biologica',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_14',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Polverizzazione',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_15',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Esfoliazione',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_6',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_5',
      component: 'Timpano',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Timpano',
      component_type: 'Archi',
      element: 'archi',
      isQuarterRow: true,
      material: 'Muratura',
      valorized: true,
      Visto: '',
    },
    // INFO: Archi > Interconnesioni Longitudinali Tra Pile > Calcestruzzo
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'archi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Vespai',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Armatura ossidata / corrosa',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._18',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._19',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._16',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Staffe scoperte/ossidate',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._20',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Distacco del timpano',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'archi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Danni da urto',
      element: 'archi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      element: 'archi',
      isQuarterRow: true,
      material: 'Calcestruzzo',
      valorized: true,
      Visto: '',
    },
    // INFO: Archi > Interconnesioni Longitudinali Tra Pile > Legno
    {
      'Codice difetto': 'Legno_1',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di umidità',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_2',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Attacco di funghi',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_3',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Attacco di insetti',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_4',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure elicoidali',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_5',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_6',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_7',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': "Ristagni / trappole d'acqua",
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_8',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Danni da urto',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_9',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Bulloni Allentati',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_10',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Delaminazione (legno lamellare)',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_11',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Connessioni deteriorate',
      element: 'archi',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      element: 'archi',
      isQuarterRow: true,
      material: 'Legno',
      valorized: true,
      Visto: '',
    },
    // INFO: Archi > Interconnesioni Longitudinali Tra Pile > Metallo o Acciaio
    {
      'Codice difetto': 'Acc_1',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Difetti di saldature',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_2',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Rottura di saldature',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_3',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Sfogliamento vernice',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_4',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Difetti di chiodatura',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_5',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Bulloni allentati',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_6',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Bulloni tranciati',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_7',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Deformazioni anime / piattabande',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_8',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Deformazioni pareti travi scatolari',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'archi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_9',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Lesioni ai nodi',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_10',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Corrosione',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_11',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Ossidazione',
      element: 'archi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      element: 'archi',
      isQuarterRow: true,
      material: 'Metallo o Acciaio',
      valorized: true,
      Visto: '',
    },
    // INFO: Archi > Interconnesioni Longitudinali Tra Pile > Muratura
    {
      'Codice difetto': 'Mur_1',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure orizzontali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_2',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure verticali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_3',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_7',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Distacco del timpano',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_9',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di umidità / risalita',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'archi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_10',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Dilavamento',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_4',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Porzione di muratura mancante',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_8',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_11',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Macchie di colore scuro',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_12',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Efflorescenze',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_13',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Patina biologica',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_14',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Polverizzazione',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_15',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Esfoliazione',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_6',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_5',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'archi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Interconnesioni Longitudinali Tra Pile',
      component_type: 'Archi',
      element: 'archi',
      isQuarterRow: true,
      material: 'Muratura',
      valorized: true,
      Visto: '',
    },
  ],
  // INFO: Impalcati
  impalcati: [
    // INFO: Impalcati > Solettone > Calcestruzzo Armato
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._21',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato Testate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Vespai',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura ossidata/corrosa',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._15',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni in corrispondenza staffe',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._19',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._18',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._22',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni / distacco travi traversi',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._16',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Staffe scoperte/ossidate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._23',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura staffe',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._17',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura longitudinale deformata',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Solettone',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'C.a.',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Solettone > Calcestruzzo Armato Precompresso
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._21',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato Testate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Vespai',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura ossidata / corrosa',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._1',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni capillari agli ancoraggi',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._2',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Testate di ancoraggio non sigill.',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._3',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Distacco tamponi testate',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._4',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni su anima lungo i cavi',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._5',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni lungo suola del bulbo',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._6',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Guaine in vista',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._7',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Guaine degradate e fili ossidati',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._8',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Fili aderenti in vista ossidati',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._19',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._22',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni / distacco travi traversi',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._16',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Staffe scoperte/ossidate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._23',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura staffe',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._9',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Riduzione armatura di precompr.',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._10',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': "Umidità dall'interno",
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._11',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Arm. Scoperta / ossidata testate',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._12',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Fuoriuscita barre ancoraggio',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._17',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura longitudinale deformata',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Solettone',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'C.A.P.',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Solettone > Legno
    {
      'Codice difetto': 'Legno_1',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_2',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Attacco di funghi',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_3',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Attacco di insetti',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_4',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure elicoidali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_5',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_6',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_7',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni / trappole d'acqua",
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_8',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_9',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni Allentat',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_10',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Delaminazione (legno lamellare)',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_11',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Connessioni deteriorate',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Solettone',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'Legno',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Solettone > Metallo o Acciaio
    {
      'Codice difetto': 'Acc_1',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Difetti di saldature',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_2',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura di saldature',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_3',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Sfogliamento vernice',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_4',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Difetti di chiodatura',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_5',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni allentati',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_6',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni tranciati',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_7',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Deformazioni anime / piattabande',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_8',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Deformazioni pareti travi scatolari',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_9',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni ai nodi',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_10',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Corrosione',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_11',
      component: 'Solettone',
      component_type: 'Travi',
      'Descrizione difetto': 'Ossidazione',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Solettone',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'Metallo o Acciaio',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Travi > Calcestruzzo Armato
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._21',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato Testate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Vespai',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura ossidata/corrosa',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._15',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni in corrispondenza staffe',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._19',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._18',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._22',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni / distacco travi traversi',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._16',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Staffe scoperte/ossidate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._23',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura staffe',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._17',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura longitudinale deformata',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Travi',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'C.a.',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Travi > Calcestruzzo Armato Precompresso
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._21',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato Testate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Vespai',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura ossidata / corrosa',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._1',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni capillari agli ancoraggi',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._2',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Testate di ancoraggio non sigill.',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._3',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Distacco tamponi testate',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._4',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni su anima lungo i cavi',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._5',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni lungo suola del bulbo',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._6',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Guaine in vista',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._7',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Guaine degradate e fili ossidati',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._8',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fili aderenti in vista ossidati',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._19',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._22',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni / distacco travi traversi',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._16',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Staffe scoperte/ossidate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._23',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura staffe',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._9',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Riduzione armatura di precompr.',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._10',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': "Umidità dall'interno",
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._11',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Arm. Scoperta / ossidata testate',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._12',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fuoriuscita barre ancoraggio',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._17',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura longitudinale deformata',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Travi',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'C.A.P.',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Travi > Legno
    {
      'Codice difetto': 'Legno_1',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_2',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Attacco di funghi',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_3',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Attacco di insetti',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_4',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure elicoidali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_5',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_6',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_7',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni / trappole d'acqua",
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_8',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_9',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni Allentat',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_10',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Delaminazione (legno lamellare)',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_11',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Connessioni deteriorate',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Travi',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'Legno',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Travi > Metallo o Acciaio
    {
      'Codice difetto': 'Acc_1',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Difetti di saldature',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_2',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura di saldature',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_3',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Sfogliamento vernice',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_4',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Difetti di chiodatura',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_5',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni allentati',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_6',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni tranciati',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_7',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Deformazioni anime / piattabande',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_8',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Deformazioni pareti travi scatolari',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_9',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni ai nodi',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_10',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Corrosione',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_11',
      component: 'Travi',
      component_type: 'Travi',
      'Descrizione difetto': 'Ossidazione',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Travi',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'Metallo o Acciaio',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Traversi Testata > Calcestruzzo Armato
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._21',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato Testate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Vespai',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura ossidata/corrosa',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._15',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni in corrispondenza staffe',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._19',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._18',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._22',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni / distacco travi traversi',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._16',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Staffe scoperte/ossidate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._23',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura staffe',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._17',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura longitudinale deformata',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Traversi Testata',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'C.a.',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Traversi Testata > Calcestruzzo Armato Precompresso
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._21',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato Testate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Vespai',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura ossidata / corrosa',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._1',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni capillari agli ancoraggi',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._2',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Testate di ancoraggio non sigill.',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._3',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Distacco tamponi testate',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._4',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni su anima lungo i cavi',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._5',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni lungo suola del bulbo',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._6',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Guaine in vista',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._7',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Guaine degradate e fili ossidati',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._8',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Fili aderenti in vista ossidati',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._19',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._22',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni / distacco travi traversi',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._16',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Staffe scoperte/ossidate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._23',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura staffe',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._9',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Riduzione armatura di precompr.',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._10',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': "Umidità dall'interno",
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._11',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Arm. Scoperta / ossidata testate',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._12',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Fuoriuscita barre ancoraggio',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._17',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura longitudinale deformata',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Traversi Testata',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'C.A.P.',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Traversi Testata > Legno
    {
      'Codice difetto': 'Legno_1',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_2',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Attacco di funghi',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_3',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Attacco di insetti',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_4',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure elicoidali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_5',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_6',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_7',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni / trappole d'acqua",
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_8',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_9',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni Allentat',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_10',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Delaminazione (legno lamellare)',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_11',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Connessioni deteriorate',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Traversi Testata',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'Legno',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Traversi Testata > Metallo o Acciaio
    {
      'Codice difetto': 'Acc_1',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Difetti di saldature',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_2',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura di saldature',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_3',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Sfogliamento vernice',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_4',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Difetti di chiodatura',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_5',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni allentati',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_6',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni tranciati',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_7',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Deformazioni anime / piattabande',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_8',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Deformazioni pareti travi scatolari',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_9',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni ai nodi',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_10',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Corrosione',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_11',
      component: 'Traversi Testata',
      component_type: 'Travi',
      'Descrizione difetto': 'Ossidazione',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Traversi Testata',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'Metallo o Acciaio',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Anime > Metallo o Acciaio
    {
      'Codice difetto': 'Acc_1',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Difetti di saldature',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_2',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura di saldature',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_3',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Sfogliamento vernice',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_4',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Difetti di chiodatura',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_5',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni allentati',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_6',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni tranciati',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_7',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Deformazioni anime / piattabande',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_8',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Deformazioni pareti travi scatolari',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_9',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni ai nodi',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_10',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Corrosione',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_11',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Ossidazione',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Anime',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'Metallo o Acciaio',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Anime > C.a.
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Anime',
      component_type: 'Soletta',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Anime',
      component_type: 'Soletta',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Anime',
      component_type: 'Soletta',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Anime',
      component_type: 'Soletta',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Anime',
      component_type: 'Soletta',
      'Descrizione difetto': 'Vespai',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Anime',
      component_type: 'Soletta',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Anime',
      component_type: 'Soletta',
      'Descrizione difetto': 'Armatura ossidata / corrosa',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Anime',
      component_type: 'Soletta',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Anime',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._18',
      component: 'Anime',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._19',
      component: 'Anime',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._25',
      component: 'Anime',
      component_type: 'Soletta',
      'Descrizione difetto': 'Lesioni attacco trave-soletta',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Anime',
      component_type: 'Soletta',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Anime',
      component_type: 'Soletta',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'C.a.',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Anime > C.A.P.
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._21',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato Testate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Vespai',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura ossidata / corrosa',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._1',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni capillari agli ancoraggi',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._2',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Testate di ancoraggio non sigill.',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._3',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Distacco tamponi testate',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._4',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni su anima lungo i cavi',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._5',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni lungo suola del bulbo',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._6',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Guaine in vista',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._7',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Guaine degradate e fili ossidati',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._8',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Fili aderenti in vista ossidati',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._19',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._22',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni / distacco travi traversi',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._16',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Staffe scoperte/ossidate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._23',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura staffe',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._9',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Riduzione armatura di precompr.',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._10',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': "Umidità dall'interno",
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._11',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Arm. Scoperta / ossidata testate',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._12',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Fuoriuscita barre ancoraggio',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._17',
      component: 'Anime',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura longitudinale deformata',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Anime',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'C.A.P.',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Anime > Legno
    {
      'Codice difetto': 'Legno_1',
      component: 'Anime',
      component_type: 'Soletta',
      'Descrizione difetto': 'Macchie di umidità',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_2',
      component: 'Anime',
      component_type: 'Soletta',
      'Descrizione difetto': 'Attacco di funghi',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_3',
      component: 'Anime',
      component_type: 'Soletta',
      'Descrizione difetto': 'Attacco di insetti',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_4',
      component: 'Anime',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure elicoidali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_5',
      component: 'Anime',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_6',
      component: 'Anime',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_7',
      component: 'Anime',
      component_type: 'Soletta',
      'Descrizione difetto': "Ristagni / trappole d'acqua",
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_8',
      component: 'Anime',
      component_type: 'Soletta',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_9',
      component: 'Anime',
      component_type: 'Soletta',
      'Descrizione difetto': 'Bulloni Allentati',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_10',
      component: 'Anime',
      component_type: 'Soletta',
      'Descrizione difetto': 'Delaminazione (legno lamellare)',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_11',
      component: 'Anime',
      component_type: 'Soletta',
      'Descrizione difetto': 'Connessioni deteriorate',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Anime',
      component_type: 'Soletta',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'Legno',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Puntone > C.a.
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._21',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato Testate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Vespai',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura ossidata/corrosa',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._15',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni in corrispondenza staffe',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._19',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._18',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._22',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni / distacco travi traversi',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._16',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Staffe scoperte/ossidate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._23',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura staffe',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._17',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura longitudinale deformata',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Puntone',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'C.a.',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Puntone > C.A.P.
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._21',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato Testate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Vespai',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura ossidata / corrosa',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._1',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni capillari agli ancoraggi',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._2',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Testate di ancoraggio non sigill.',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._3',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Distacco tamponi testate',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._4',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni su anima lungo i cavi',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._5',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni lungo suola del bulbo',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._6',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Guaine in vista',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._7',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Guaine degradate e fili ossidati',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._8',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Fili aderenti in vista ossidati',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._19',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._22',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni / distacco travi traversi',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._16',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Staffe scoperte/ossidate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._23',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura staffe',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._9',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Riduzione armatura di precompr.',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._10',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': "Umidità dall'interno",
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._11',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Arm. Scoperta / ossidata testate',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._12',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Fuoriuscita barre ancoraggio',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._17',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura longitudinale deformata',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Puntone',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'C.A.P.',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Puntone > Legno
    {
      'Codice difetto': 'Legno_1',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_2',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Attacco di funghi',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_3',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Attacco di insetti',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_4',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure elicoidali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_5',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_6',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_7',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni / trappole d'acqua",
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_8',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_9',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni Allentat',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_10',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Delaminazione (legno lamellare)',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_11',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Connessioni deteriorate',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Puntone',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'Legno',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Puntone > Metallo o Acciaio
    {
      'Codice difetto': 'Acc_1',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Difetti di saldature',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_2',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura di saldature',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_3',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Sfogliamento vernice',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_4',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Difetti di chiodatura',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_5',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni allentati',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_6',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni tranciati',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_7',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Deformazioni anime / piattabande',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_8',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Deformazioni pareti travi scatolari',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_9',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni ai nodi',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_10',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Corrosione',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_11',
      component: 'Puntone',
      component_type: 'Travi',
      'Descrizione difetto': 'Ossidazione',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Puntone',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'Metallo o Acciaio',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Soletta > Metallo o Acciaio
    {
      'Codice difetto': 'Acc_1',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Difetti di saldature',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_2',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura di saldature',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_3',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Sfogliamento vernice',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_4',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Difetti di chiodatura',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_5',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni allentati',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_6',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni tranciati',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_7',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Deformazioni anime / piattabande',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_8',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Deformazioni pareti travi scatolari',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_9',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni ai nodi',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_10',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Corrosione',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_11',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Ossidazione',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Soletta',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'Metallo o Acciaio',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Soletta > C.a.
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Soletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Soletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Soletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Soletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Soletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Vespai',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Soletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Soletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Armatura ossidata / corrosa',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Soletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Soletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._18',
      component: 'Soletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._19',
      component: 'Soletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._25',
      component: 'Soletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Lesioni attacco trave-soletta',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Soletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Soletta',
      component_type: 'Soletta',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'C.a.',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Soletta > C.A.P.
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._21',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato Testate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Vespai',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura ossidata / corrosa',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._1',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni capillari agli ancoraggi',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._2',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Testate di ancoraggio non sigill.',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._3',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Distacco tamponi testate',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._4',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni su anima lungo i cavi',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._5',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni lungo suola del bulbo',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._6',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Guaine in vista',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._7',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Guaine degradate e fili ossidati',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._8',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Fili aderenti in vista ossidati',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._19',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._22',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni / distacco travi traversi',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._16',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Staffe scoperte/ossidate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._23',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura staffe',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._9',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Riduzione armatura di precompr.',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._10',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': "Umidità dall'interno",
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._11',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Arm. Scoperta / ossidata testate',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._12',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Fuoriuscita barre ancoraggio',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._17',
      component: 'Soletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura longitudinale deformata',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Soletta',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'C.A.P.',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Soletta > Legno
    {
      'Codice difetto': 'Legno_1',
      component: 'Soletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Macchie di umidità',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_2',
      component: 'Soletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Attacco di funghi',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_3',
      component: 'Soletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Attacco di insetti',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_4',
      component: 'Soletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure elicoidali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_5',
      component: 'Soletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_6',
      component: 'Soletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_7',
      component: 'Soletta',
      component_type: 'Soletta',
      'Descrizione difetto': "Ristagni / trappole d'acqua",
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_8',
      component: 'Soletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_9',
      component: 'Soletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Bulloni Allentati',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_10',
      component: 'Soletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Delaminazione (legno lamellare)',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_11',
      component: 'Soletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Connessioni deteriorate',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Soletta',
      component_type: 'Soletta',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'Legno',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Traversi Intermedi > C.a.
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._21',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato Testate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Vespai',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura ossidata/corrosa',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._15',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni in corrispondenza staffe',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._19',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._18',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._22',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni / distacco travi traversi',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._16',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Staffe scoperte/ossidate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._23',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura staffe',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._17',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura longitudinale deformata',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'C.a.',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Traversi Intermedi > C.A.P.
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._21',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato Testate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Vespai',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura ossidata / corrosa',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._1',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni capillari agli ancoraggi',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._2',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Testate di ancoraggio non sigill.',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._3',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Distacco tamponi testate',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._4',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni su anima lungo i cavi',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._5',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni lungo suola del bulbo',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._6',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Guaine in vista',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._7',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Guaine degradate e fili ossidati',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._8',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fili aderenti in vista ossidati',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._19',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._22',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni / distacco travi traversi',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._16',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Staffe scoperte/ossidate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._23',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura staffe',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._9',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Riduzione armatura di precompr.',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._10',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': "Umidità dall'interno",
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._11',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Arm. Scoperta / ossidata testate',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._12',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fuoriuscita barre ancoraggio',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._17',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura longitudinale deformata',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'C.A.P.',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Traversi Intermedi > Legno
    {
      'Codice difetto': 'Legno_1',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_2',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Attacco di funghi',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_3',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Attacco di insetti',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_4',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure elicoidali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_5',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_6',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_7',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni / trappole d'acqua",
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_8',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_9',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni Allentat',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_10',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Delaminazione (legno lamellare)',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_11',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Connessioni deteriorate',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'Legno',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Traversi Intermedi > Metallo o Acciaio
    {
      'Codice difetto': 'Acc_1',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Difetti di saldature',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_2',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura di saldature',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_3',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Sfogliamento vernice',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_4',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Difetti di chiodatura',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_5',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni allentati',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_6',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni tranciati',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_7',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Deformazioni anime / piattabande',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_8',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Deformazioni pareti travi scatolari',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_9',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni ai nodi',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_10',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Corrosione',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_11',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      'Descrizione difetto': 'Ossidazione',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Traversi Intermedi',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'Metallo o Acciaio',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Controsoletta > Metallo o Acciaio
    {
      'Codice difetto': 'Acc_1',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Difetti di saldature',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_2',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura di saldature',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_3',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Sfogliamento vernice',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_4',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Difetti di chiodatura',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_5',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni allentati',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_6',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni tranciati',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_7',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Deformazioni anime / piattabande',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_8',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Deformazioni pareti travi scatolari',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_9',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni ai nodi',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_10',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Corrosione',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_11',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Ossidazione',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Controsoletta',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'Metallo o Acciaio',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Controsoletta > C.a.
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Controsoletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Controsoletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Controsoletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Controsoletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Controsoletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Vespai',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Controsoletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Controsoletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Armatura ossidata / corrosa',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Controsoletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Controsoletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._18',
      component: 'Controsoletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._19',
      component: 'Controsoletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._25',
      component: 'Controsoletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Lesioni attacco trave-soletta',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Controsoletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Controsoletta',
      component_type: 'Soletta',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'C.a.',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Controsoletta > C.A.P.
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._21',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato Testate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Vespai',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura ossidata / corrosa',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._1',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni capillari agli ancoraggi',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._2',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Testate di ancoraggio non sigill.',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._3',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Distacco tamponi testate',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._4',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni su anima lungo i cavi',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._5',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni lungo suola del bulbo',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._6',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Guaine in vista',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._7',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Guaine degradate e fili ossidati',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._8',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Fili aderenti in vista ossidati',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._19',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._22',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni / distacco travi traversi',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._16',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Staffe scoperte/ossidate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._23',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura staffe',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._9',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Riduzione armatura di precompr.',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._10',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': "Umidità dall'interno",
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._11',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Arm. Scoperta / ossidata testate',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._12',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Fuoriuscita barre ancoraggio',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._17',
      component: 'Controsoletta',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura longitudinale deformata',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Controsoletta',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'C.A.P.',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Controsoletta > Legno
    {
      'Codice difetto': 'Legno_1',
      component: 'Controsoletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Macchie di umidità',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_2',
      component: 'Controsoletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Attacco di funghi',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_3',
      component: 'Controsoletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Attacco di insetti',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_4',
      component: 'Controsoletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure elicoidali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_5',
      component: 'Controsoletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_6',
      component: 'Controsoletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_7',
      component: 'Controsoletta',
      component_type: 'Soletta',
      'Descrizione difetto': "Ristagni / trappole d'acqua",
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_8',
      component: 'Controsoletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_9',
      component: 'Controsoletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Bulloni Allentati',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_10',
      component: 'Controsoletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Delaminazione (legno lamellare)',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_11',
      component: 'Controsoletta',
      component_type: 'Soletta',
      'Descrizione difetto': 'Connessioni deteriorate',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Controsoletta',
      component_type: 'Soletta',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'Legno',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Sbalzi Trasversali > C.a.
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Sbalzi Trasversali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Sbalzi Trasversali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Sbalzi Trasversali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Sbalzi Trasversali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Sbalzi Trasversali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Vespai',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Sbalzi Trasversali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Sbalzi Trasversali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Armatura ossidata / corrosa',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Sbalzi Trasversali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Sbalzi Trasversali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._18',
      component: 'Sbalzi Trasversali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._19',
      component: 'Sbalzi Trasversali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._25',
      component: 'Sbalzi Trasversali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Lesioni attacco trave-soletta',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Sbalzi Trasversali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Sbalzi Trasversali',
      component_type: 'Soletta',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'C.a.',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Sbalzi Trasversali > Legno
    {
      'Codice difetto': 'Legno_1',
      component: 'Sbalzi Trasversali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Macchie di umidità',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_2',
      component: 'Sbalzi Trasversali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Attacco di funghi',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_3',
      component: 'Sbalzi Trasversali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Attacco di insetti',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_4',
      component: 'Sbalzi Trasversali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure elicoidali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_5',
      component: 'Sbalzi Trasversali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_6',
      component: 'Sbalzi Trasversali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_7',
      component: 'Sbalzi Trasversali',
      component_type: 'Soletta',
      'Descrizione difetto': "Ristagni / trappole d'acqua",
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_8',
      component: 'Sbalzi Trasversali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_9',
      component: 'Sbalzi Trasversali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Bulloni Allentati',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_10',
      component: 'Sbalzi Trasversali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Delaminazione (legno lamellare)',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_11',
      component: 'Sbalzi Trasversali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Connessioni deteriorate',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Sbalzi Trasversali',
      component_type: 'Soletta',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'Legno',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Sbalzi Longitudinali > C.a.
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Sbalzi Longitudinali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Sbalzi Longitudinali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Sbalzi Longitudinali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Sbalzi Longitudinali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Sbalzi Longitudinali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Vespai',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Sbalzi Longitudinali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Sbalzi Longitudinali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Armatura ossidata / corrosa',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Sbalzi Longitudinali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Sbalzi Longitudinali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._18',
      component: 'Sbalzi Longitudinali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._19',
      component: 'Sbalzi Longitudinali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._25',
      component: 'Sbalzi Longitudinali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Lesioni attacco trave-soletta',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Sbalzi Longitudinali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Sbalzi Longitudinali',
      component_type: 'Soletta',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'C.a.',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Sbalzi Longitudinali > Legno
    {
      'Codice difetto': 'Legno_1',
      component: 'Sbalzi Longitudinali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Macchie di umidità',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_2',
      component: 'Sbalzi Longitudinali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Attacco di funghi',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_3',
      component: 'Sbalzi Longitudinali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Attacco di insetti',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_4',
      component: 'Sbalzi Longitudinali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure elicoidali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_5',
      component: 'Sbalzi Longitudinali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_6',
      component: 'Sbalzi Longitudinali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_7',
      component: 'Sbalzi Longitudinali',
      component_type: 'Soletta',
      'Descrizione difetto': "Ristagni / trappole d'acqua",
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_8',
      component: 'Sbalzi Longitudinali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_9',
      component: 'Sbalzi Longitudinali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Bulloni Allentati',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_10',
      component: 'Sbalzi Longitudinali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Delaminazione (legno lamellare)',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_11',
      component: 'Sbalzi Longitudinali',
      component_type: 'Soletta',
      'Descrizione difetto': 'Connessioni deteriorate',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Sbalzi Longitudinali',
      component_type: 'Soletta',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'Legno',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Diaframmi > Legno
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._21',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato Testate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Vespai',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura ossidata/corrosa',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._15',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni in corrispondenza staffe',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._19',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._18',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._22',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni / distacco travi traversi',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._16',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Staffe scoperte/ossidate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._23',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura staffe',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._17',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura longitudinale deformata',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Diaframmi',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'C.a.',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Diaframmi > C.A.P.
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._21',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato Testate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Vespai',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura ossidata / corrosa',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._1',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni capillari agli ancoraggi',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._2',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Testate di ancoraggio non sigill.',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._3',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Distacco tamponi testate',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._4',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni su anima lungo i cavi',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._5',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni lungo suola del bulbo',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._6',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Guaine in vista',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._7',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Guaine degradate e fili ossidati',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._8',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fili aderenti in vista ossidati',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._19',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._22',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni / distacco travi traversi',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._16',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Staffe scoperte/ossidate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._23',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura staffe',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._9',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Riduzione armatura di precompr.',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._10',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': "Umidità dall'interno",
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._11',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Arm. Scoperta / ossidata testate',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._12',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fuoriuscita barre ancoraggio',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._17',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura longitudinale deformata',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Diaframmi',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'C.A.P.',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Diaframmi > Legno
    {
      'Codice difetto': 'Legno_1',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_2',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Attacco di funghi',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_3',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Attacco di insetti',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_4',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure elicoidali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_5',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_6',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_7',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni / trappole d'acqua",
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_8',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_9',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni Allentat',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_10',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Delaminazione (legno lamellare)',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_11',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Connessioni deteriorate',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Diaframmi',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'Legno',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Diaframmi > Metallo o Acciaio
    {
      'Codice difetto': 'Acc_1',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Difetti di saldature',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_2',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura di saldature',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_3',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Sfogliamento vernice',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_4',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Difetti di chiodatura',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_5',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni allentati',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_6',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni tranciati',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_7',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Deformazioni anime / piattabande',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_8',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Deformazioni pareti travi scatolari',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_9',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni ai nodi',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_10',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Corrosione',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_11',
      component: 'Diaframmi',
      component_type: 'Travi',
      'Descrizione difetto': 'Ossidazione',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Diaframmi',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'Metallo o Acciaio',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Interconnesioni Longitunali > C.a.
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._21',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato Testate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Vespai',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura ossidata/corrosa',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._15',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni in corrispondenza staffe',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._19',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._18',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._22',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni / distacco travi traversi',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._16',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Staffe scoperte/ossidate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._23',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura staffe',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._17',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura longitudinale deformata',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.a.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'C.a.',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Interconnesioni Longitunali > C.A.P.
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._21',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Cls dilavato / ammalorato Testate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Vespai',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura ossidata / corrosa',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._1',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni capillari agli ancoraggi',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._2',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Testate di ancoraggio non sigill.',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._3',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Distacco tamponi testate',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._4',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni su anima lungo i cavi',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._5',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni lungo suola del bulbo',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._6',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Guaine in vista',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._7',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Guaine degradate e fili ossidati',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._8',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Fili aderenti in vista ossidati',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._19',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._22',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni / distacco travi traversi',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._16',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Staffe scoperte/ossidate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._23',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura staffe',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._9',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Riduzione armatura di precompr.',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._10',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': "Umidità dall'interno",
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._11',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Arm. Scoperta / ossidata testate',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a.p._12',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Fuoriuscita barre ancoraggio',
      element: 'impalcati',
      elemento: 'C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._17',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Armatura longitudinale deformata',
      element: 'impalcati',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'C.A.P.',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'C.A.P.',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Interconnesioni Longitunali > Legno
    {
      'Codice difetto': 'Legno_1',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Macchie di umidità',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_2',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Attacco di funghi',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_3',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Attacco di insetti',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_4',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure elicoidali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_5',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure longitudinali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_6',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Fessure trasversali',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_7',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni / trappole d'acqua",
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_8',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Danni da urto',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_9',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni Allentat',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': false,
        '1': false,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_10',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Delaminazione (legno lamellare)',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Legno_11',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Connessioni deteriorate',
      element: 'impalcati',
      elemento: 'Legno',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Legno',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'Legno',
      valorized: true,
      Visto: '',
    },
    // INFO: Impalcati > Interconnesioni Longitunali > Metallo o Acciaio
    {
      'Codice difetto': 'Acc_1',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Difetti di saldature',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_2',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura di saldature',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_3',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Sfogliamento vernice',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_4',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Difetti di chiodatura',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_5',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni allentati',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_6',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni tranciati',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_7',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Deformazioni anime / piattabande',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_8',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Deformazioni pareti travi scatolari',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'impalcati',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_9',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni ai nodi',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_10',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Corrosione',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_11',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      'Descrizione difetto': 'Ossidazione',
      element: 'impalcati',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Interconnesioni Longitunali',
      component_type: 'Travi',
      element: 'impalcati',
      isQuarterRow: true,
      material: 'Metallo o Acciaio',
      valorized: true,
      Visto: '',
    },
  ],
  // INFO: File di Appoggi
  filediappoggi: [
    // INFO: File di Appoggi > Dispositivi Di Appoggio
    {
      'Codice difetto': 'App_1',
      component: 'Dispositivi Di Appoggio',
      component_type: 'Appoggi',
      'Descrizione difetto': 'Piastra di base deformata',
      element: 'filediappoggi',
      elemento: 'Appoggio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'App_2',
      component: 'Dispositivi Di Appoggio',
      component_type: 'Appoggi',
      'Descrizione difetto': 'Ossidazione',
      element: 'filediappoggi',
      elemento: 'Appoggio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'App_3',
      component: 'Dispositivi Di Appoggio',
      component_type: 'Appoggi',
      'Descrizione difetto': 'Bloccaggio',
      element: 'filediappoggi',
      elemento: 'Appoggio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'App_4',
      component: 'Dispositivi Di Appoggio',
      component_type: 'Appoggi',
      'Descrizione difetto': 'Preregolazione sbagliata',
      element: 'filediappoggi',
      elemento: 'Appoggio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'App_5',
      component: 'Dispositivi Di Appoggio',
      component_type: 'Appoggi',
      'Descrizione difetto': 'Presenza di detriti',
      element: 'filediappoggi',
      elemento: 'Appoggio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'App_6',
      component: 'Dispositivi Di Appoggio',
      component_type: 'Appoggi',
      'Descrizione difetto': 'Schiacciamento/Fuoriuscita lastre di piombo',
      element: 'filediappoggi',
      elemento: 'Appoggio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': "Difetti d'appoggio in neoprene",
      component: 'Dispositivi Di Appoggio',
      component_type: 'Appoggi',
      element: 'filediappoggi',
      isFullRow: true,
      material: '',
      valorized: true,
    },
    {
      'Codice difetto': 'App_7',
      component: 'Dispositivi Di Appoggio',
      component_type: 'Appoggi',
      'Descrizione difetto': 'Invecchiamento neoprene',
      element: 'filediappoggi',
      elemento: 'Appoggio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'App_8',
      component: 'Dispositivi Di Appoggio',
      component_type: 'Appoggi',
      'Descrizione difetto': 'Deformazione orizzontale eccessiva',
      element: 'filediappoggi',
      elemento: 'Appoggio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'App_9',
      component: 'Dispositivi Di Appoggio',
      component_type: 'Appoggi',
      'Descrizione difetto': 'Schiacciamento/Fuoriuscita neoprene',
      element: 'filediappoggi',
      elemento: 'Appoggio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Difetti pendoli',
      component: 'Dispositivi Di Appoggio',
      component_type: 'Appoggi',
      element: 'filediappoggi',
      isFullRow: true,
      material: '',
      valorized: true,
    },
    {
      'Codice difetto': 'App_10',
      component: 'Dispositivi Di Appoggio',
      component_type: 'Appoggi',
      'Descrizione difetto': 'Ammoloramento pendoli in c.a.',
      element: 'filediappoggi',
      elemento: 'Appoggio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'App_11',
      component: 'Dispositivi Di Appoggio',
      component_type: 'Appoggi',
      'Descrizione difetto': 'Fuori piombo permanente',
      element: 'filediappoggi',
      elemento: 'Appoggio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Difetti carrelli (metallici)',
      component: 'Dispositivi Di Appoggio',
      component_type: 'Appoggi',
      element: 'filediappoggi',
      isFullRow: true,
      material: '',
      valorized: true,
    },
    {
      'Codice difetto': 'App_12',
      component: 'Dispositivi Di Appoggio',
      component_type: 'Appoggi',
      'Descrizione difetto': 'Ovalizzazione rulli metallici',
      element: 'filediappoggi',
      elemento: 'Appoggio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'App_13',
      component: 'Dispositivi Di Appoggio',
      component_type: 'Appoggi',
      'Descrizione difetto': 'Fuori sede rulli metallici',
      element: 'filediappoggi',
      elemento: 'Appoggio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Difetti di appoggio generici',
      component: 'Dispositivi Di Appoggio',
      component_type: 'Appoggi',
      element: 'filediappoggi',
      isFullRow: true,
      material: '',
      valorized: true,
    },
    {
      'Codice difetto': 'App_14',
      component: 'Dispositivi Di Appoggio',
      component_type: 'Appoggi',
      'Descrizione difetto': 'Deterioramento Teflon',
      element: 'filediappoggi',
      elemento: 'Appoggio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Dispositivi Di Appoggio',
      component_type: 'Appoggi',
      element: 'filediappoggi',
      isQuarterRow: true,
      material: '',
      valorized: true,
      Visto: '',
    },
    // INFO: File di Appoggi > Baggiolo > Calcestruzzo
    {
      'Codice difetto': 'C.a./C.a.p._1',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Macchie di umidità passiva',
      element: 'filediappoggi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._2',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Macchie di umidità attiva',
      element: 'filediappoggi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'filediappoggi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._3',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Cls dilavato / ammalorato',
      element: 'filediappoggi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'filediappoggi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._4',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Vespai',
      element: 'filediappoggi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._5',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Distacco del copriferro',
      element: 'filediappoggi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._6',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Armatura ossidata/corrosa',
      element: 'filediappoggi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._7',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni a ragnatela modeste',
      element: 'filediappoggi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._15',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni in corrispondenza staffe',
      element: 'filediappoggi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._8',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure orizzontali',
      element: 'filediappoggi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._9',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure verticali',
      element: 'filediappoggi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._10',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'filediappoggi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._16',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Staffe scoperte/ossidate',
      element: 'filediappoggi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._11',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni attacco pilastri',
      element: 'filediappoggi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._23',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Rottura staffe',
      element: 'filediappoggi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._17',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Armatura longitudinale deformata',
      element: 'filediappoggi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._12',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'filediappoggi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Danni da urto',
      element: 'filediappoggi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_6',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Fuori piombo',
      element: 'filediappoggi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1a',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni profonde)',
      element: 'filediappoggi',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1b',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni superficiali)',
      element: 'filediappoggi',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_5',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Movimenti di fondazione',
      element: 'filediappoggi',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'C.a./C.a.p._13',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni da schiacciamento',
      element: 'filediappoggi',
      elemento: 'C.a./C.a.p.',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_4',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni caratteristiche zone appoggio',
      element: 'filediappoggi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Calcestruzzo',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Baggiolo',
      component_type: 'Pile',
      element: 'filediappoggi',
      isQuarterRow: true,
      material: 'Calcestruzzo',
      valorized: true,
      Visto: '',
    },
    // INFO: File di Appoggi > Baggiolo > Metallo o Acciaio
    {
      'Codice difetto': 'Acc_1',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Difetti di saldature',
      element: 'filediappoggi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_2',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Rottura di saldature',
      element: 'filediappoggi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_3',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Sfogliamento vernice',
      element: 'filediappoggi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_4',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Difetti di chiodatura',
      element: 'filediappoggi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_5',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Bulloni allentati',
      element: 'filediappoggi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_6',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Bulloni tranciati',
      element: 'filediappoggi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_7',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Deformazioni anime / piattabande',
      element: 'filediappoggi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_8',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Deformazioni pareti travi scatolari',
      element: 'filediappoggi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'filediappoggi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_9',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni ai nodi',
      element: 'filediappoggi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_10',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Corrosione',
      element: 'filediappoggi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_11',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Ossidazione',
      element: 'filediappoggi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Danni da urto',
      element: 'filediappoggi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_6',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Fuori piombo',
      element: 'filediappoggi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1a',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni profonde)',
      element: 'filediappoggi',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1b',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni superficiali)',
      element: 'filediappoggi',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_5',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Movimenti di fondazione',
      element: 'filediappoggi',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Baggiolo',
      component_type: 'Pile',
      element: 'filediappoggi',
      isQuarterRow: true,
      material: 'Metallo o Acciaio',
      valorized: true,
      Visto: '',
    },
    // INFO: File di Appoggi > Baggiolo > Muratura
    {
      'Codice difetto': 'Mur_1',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure orizzontali',
      element: 'filediappoggi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_2',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure verticali',
      element: 'filediappoggi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_3',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Fessure diagonali',
      element: 'filediappoggi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_9',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Macchie di umidità / risalita',
      element: 'filediappoggi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_1',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Tracce di scolo',
      element: 'filediappoggi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_10',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Dilavamento',
      element: 'filediappoggi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'filediappoggi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_4',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Porzione di muratura mancante',
      element: 'filediappoggi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_3',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Danni da urto',
      element: 'filediappoggi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_8',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Riprese successive deteriorate',
      element: 'filediappoggi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_11',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Macchie di colore scuro',
      element: 'filediappoggi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_12',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Efflorescenze',
      element: 'filediappoggi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_13',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Patina biologica',
      element: 'filediappoggi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_14',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Polverizzazione',
      element: 'filediappoggi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Mur_15',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Esfoliazione',
      element: 'filediappoggi',
      elemento: 'Muratura',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_6',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Fuori piombo',
      element: 'filediappoggi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1a',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni profonde)',
      element: 'filediappoggi',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_1b',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Scalzamento (Fondazioni superficiali)',
      element: 'filediappoggi',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Ril/Fond_5',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Movimenti di fondazione',
      element: 'filediappoggi',
      elemento: 'Rilevati e Fondazioni',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_4',
      component: 'Baggiolo',
      component_type: 'Pile',
      'Descrizione difetto': 'Lesioni caratteristiche zone appoggio',
      element: 'filediappoggi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Muratura',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Baggiolo',
      component_type: 'Pile',
      element: 'filediappoggi',
      isQuarterRow: true,
      material: 'Muratura',
      valorized: true,
      Visto: '',
    },
    // INFO: File di Appoggi > Cerniere Di Taglio > Metallo o Acciaio
    {
      'Codice difetto': 'Acc_1',
      component: 'Cerniere Di Taglio',
      component_type: 'Travi',
      'Descrizione difetto': 'Difetti di saldature',
      element: 'filediappoggi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_2',
      component: 'Cerniere Di Taglio',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura di saldature',
      element: 'filediappoggi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_3',
      component: 'Cerniere Di Taglio',
      component_type: 'Travi',
      'Descrizione difetto': 'Sfogliamento vernice',
      element: 'filediappoggi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_4',
      component: 'Cerniere Di Taglio',
      component_type: 'Travi',
      'Descrizione difetto': 'Difetti di chiodatura',
      element: 'filediappoggi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_5',
      component: 'Cerniere Di Taglio',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni allentati',
      element: 'filediappoggi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_6',
      component: 'Cerniere Di Taglio',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni tranciati',
      element: 'filediappoggi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_7',
      component: 'Cerniere Di Taglio',
      component_type: 'Travi',
      'Descrizione difetto': 'Deformazioni anime / piattabande',
      element: 'filediappoggi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_8',
      component: 'Cerniere Di Taglio',
      component_type: 'Travi',
      'Descrizione difetto': 'Deformazioni pareti travi scatolari',
      element: 'filediappoggi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Cerniere Di Taglio',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'filediappoggi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Cerniere Di Taglio',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'filediappoggi',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_9',
      component: 'Cerniere Di Taglio',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni ai nodi',
      element: 'filediappoggi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_10',
      component: 'Cerniere Di Taglio',
      component_type: 'Travi',
      'Descrizione difetto': 'Corrosione',
      element: 'filediappoggi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_11',
      component: 'Cerniere Di Taglio',
      component_type: 'Travi',
      'Descrizione difetto': 'Ossidazione',
      element: 'filediappoggi',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Cerniere Di Taglio',
      component_type: 'Travi',
      element: 'filediappoggi',
      isQuarterRow: true,
      material: 'Metallo o Acciaio',
      valorized: true,
      Visto: '',
    },
  ],
  // INFO: Giunti
  giunti: [
    // INFO: Giunti > Portale O Monaco
    {
      'Codice difetto': 'Giunt_1',
      component: 'Portale O Monaco',
      component_type: 'Giunti',
      'Descrizione difetto': 'Dislivello giunto pavimentazione',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_2',
      component: 'Portale O Monaco',
      component_type: 'Giunti',
      'Descrizione difetto': 'Dislivello tra elementi contigui',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_3',
      component: 'Portale O Monaco',
      component_type: 'Giunti',
      'Descrizione difetto': 'Riparazioni Provvisorie Giunti',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_4',
      component: 'Portale O Monaco',
      component_type: 'Giunti',
      'Descrizione difetto': 'Massetti lesionati',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_5',
      component: 'Portale O Monaco',
      component_type: 'Giunti',
      'Descrizione difetto': 'Distacco tampone',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_6',
      component: 'Portale O Monaco',
      component_type: 'Giunti',
      'Descrizione difetto': 'Deformazione tampone',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_7',
      component: 'Portale O Monaco',
      component_type: 'Giunti',
      'Descrizione difetto': 'Ammaloramento profilati',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_8',
      component: 'Portale O Monaco',
      component_type: 'Giunti',
      'Descrizione difetto': 'Deformazione/Rottura elementi di continuità',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_9',
      component: 'Portale O Monaco',
      component_type: 'Giunti',
      'Descrizione difetto': 'Scossalina permeabile o assente',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Portale O Monaco',
      component_type: 'Giunti',
      element: 'giunti',
      isQuarterRow: true,
      material: '',
      valorized: true,
      Visto: '',
    },
    // INFO: Giunti > Soletta Intermedia
    {
      'Codice difetto': 'Giunt_1',
      component: 'Soletta Intermedia',
      component_type: 'Giunti',
      'Descrizione difetto': 'Dislivello giunto pavimentazione',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_2',
      component: 'Soletta Intermedia',
      component_type: 'Giunti',
      'Descrizione difetto': 'Dislivello tra elementi contigui',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_3',
      component: 'Soletta Intermedia',
      component_type: 'Giunti',
      'Descrizione difetto': 'Riparazioni Provvisorie Giunti',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_4',
      component: 'Soletta Intermedia',
      component_type: 'Giunti',
      'Descrizione difetto': 'Massetti lesionati',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_5',
      component: 'Soletta Intermedia',
      component_type: 'Giunti',
      'Descrizione difetto': 'Distacco tampone',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_6',
      component: 'Soletta Intermedia',
      component_type: 'Giunti',
      'Descrizione difetto': 'Deformazione tampone',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_7',
      component: 'Soletta Intermedia',
      component_type: 'Giunti',
      'Descrizione difetto': 'Ammaloramento profilati',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_8',
      component: 'Soletta Intermedia',
      component_type: 'Giunti',
      'Descrizione difetto': 'Deformazione/Rottura elementi di continuità',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_9',
      component: 'Soletta Intermedia',
      component_type: 'Giunti',
      'Descrizione difetto': 'Scossalina permeabile o assente',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Soletta Intermedia',
      component_type: 'Giunti',
      element: 'giunti',
      isQuarterRow: true,
      material: '',
      valorized: true,
      Visto: '',
    },
    // INFO: Giunti > Dispositivi Di Giunto
    {
      'Codice difetto': 'Giunt_1',
      component: 'Dispositivi Di Giunto',
      component_type: 'Giunti',
      'Descrizione difetto': 'Dislivello giunto pavimentazione',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_2',
      component: 'Dispositivi Di Giunto',
      component_type: 'Giunti',
      'Descrizione difetto': 'Dislivello tra elementi contigui',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_3',
      component: 'Dispositivi Di Giunto',
      component_type: 'Giunti',
      'Descrizione difetto': 'Riparazioni Provvisorie Giunti',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_4',
      component: 'Dispositivi Di Giunto',
      component_type: 'Giunti',
      'Descrizione difetto': 'Massetti lesionati',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_5',
      component: 'Dispositivi Di Giunto',
      component_type: 'Giunti',
      'Descrizione difetto': 'Distacco tampone',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_6',
      component: 'Dispositivi Di Giunto',
      component_type: 'Giunti',
      'Descrizione difetto': 'Deformazione tampone',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_7',
      component: 'Dispositivi Di Giunto',
      component_type: 'Giunti',
      'Descrizione difetto': 'Ammaloramento profilati',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_8',
      component: 'Dispositivi Di Giunto',
      component_type: 'Giunti',
      'Descrizione difetto': 'Deformazione/Rottura elementi di continuità',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_9',
      component: 'Dispositivi Di Giunto',
      component_type: 'Giunti',
      'Descrizione difetto': 'Scossalina permeabile o assente',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Dispositivi Di Giunto',
      component_type: 'Giunti',
      element: 'giunti',
      isQuarterRow: true,
      material: '',
      valorized: true,
      Visto: '',
    },
    // INFO: Giunti > Massetto
    {
      'Codice difetto': 'Giunt_1',
      component: 'Massetto',
      component_type: 'Giunti',
      'Descrizione difetto': 'Dislivello giunto pavimentazione',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_2',
      component: 'Massetto',
      component_type: 'Giunti',
      'Descrizione difetto': 'Dislivello tra elementi contigui',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_3',
      component: 'Massetto',
      component_type: 'Giunti',
      'Descrizione difetto': 'Riparazioni Provvisorie Giunti',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_4',
      component: 'Massetto',
      component_type: 'Giunti',
      'Descrizione difetto': 'Massetti lesionati',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_5',
      component: 'Massetto',
      component_type: 'Giunti',
      'Descrizione difetto': 'Distacco tampone',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_6',
      component: 'Massetto',
      component_type: 'Giunti',
      'Descrizione difetto': 'Deformazione tampone',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '1',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_7',
      component: 'Massetto',
      component_type: 'Giunti',
      'Descrizione difetto': 'Ammaloramento profilati',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_8',
      component: 'Massetto',
      component_type: 'Giunti',
      'Descrizione difetto': 'Deformazione/Rottura elementi di continuità',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Giunt_9',
      component: 'Massetto',
      component_type: 'Giunti',
      'Descrizione difetto': 'Scossalina permeabile o assente',
      element: 'giunti',
      elemento: 'Giunti',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: '',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Massetto',
      component_type: 'Giunti',
      element: 'giunti',
      isQuarterRow: true,
      material: '',
      valorized: true,
      Visto: '',
    },
  ],
  // INFO: Apparecchi Sismici
  apparecchisismici: [
    // INFO: Apparecchi Sismici > Dispositivi > Metallo o Acciaio
    {
      'Codice difetto': 'Acc_1',
      component: 'Dispositivi',
      component_type: 'Travi',
      'Descrizione difetto': 'Difetti di saldature',
      element: 'apparecchisismici',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_2',
      component: 'Dispositivi',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura di saldature',
      element: 'apparecchisismici',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_3',
      component: 'Dispositivi',
      component_type: 'Travi',
      'Descrizione difetto': 'Sfogliamento vernice',
      element: 'apparecchisismici',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_4',
      component: 'Dispositivi',
      component_type: 'Travi',
      'Descrizione difetto': 'Difetti di chiodatura',
      element: 'apparecchisismici',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_5',
      component: 'Dispositivi',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni allentati',
      element: 'apparecchisismici',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_6',
      component: 'Dispositivi',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni tranciati',
      element: 'apparecchisismici',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_7',
      component: 'Dispositivi',
      component_type: 'Travi',
      'Descrizione difetto': 'Deformazioni anime / piattabande',
      element: 'apparecchisismici',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_8',
      component: 'Dispositivi',
      component_type: 'Travi',
      'Descrizione difetto': 'Deformazioni pareti travi scatolari',
      element: 'apparecchisismici',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Dispositivi',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'apparecchisismici',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Dispositivi',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'apparecchisismici',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_9',
      component: 'Dispositivi',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni ai nodi',
      element: 'apparecchisismici',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_10',
      component: 'Dispositivi',
      component_type: 'Travi',
      'Descrizione difetto': 'Corrosione',
      element: 'apparecchisismici',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_11',
      component: 'Dispositivi',
      component_type: 'Travi',
      'Descrizione difetto': 'Ossidazione',
      element: 'apparecchisismici',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Dispositivi',
      component_type: 'Travi',
      element: 'apparecchisismici',
      isQuarterRow: true,
      material: 'Metallo o Acciaio',
      valorized: true,
      Visto: '',
    },
    // INFO: Apparecchi Sismici > Ritegni Sismici > Metallo o Acciaio
    {
      'Codice difetto': 'Acc_1',
      component: 'Ritegni Sismici',
      component_type: 'Travi',
      'Descrizione difetto': 'Difetti di saldature',
      element: 'apparecchisismici',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_2',
      component: 'Ritegni Sismici',
      component_type: 'Travi',
      'Descrizione difetto': 'Rottura di saldature',
      element: 'apparecchisismici',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_3',
      component: 'Ritegni Sismici',
      component_type: 'Travi',
      'Descrizione difetto': 'Sfogliamento vernice',
      element: 'apparecchisismici',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_4',
      component: 'Ritegni Sismici',
      component_type: 'Travi',
      'Descrizione difetto': 'Difetti di chiodatura',
      element: 'apparecchisismici',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_5',
      component: 'Ritegni Sismici',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni allentati',
      element: 'apparecchisismici',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_6',
      component: 'Ritegni Sismici',
      component_type: 'Travi',
      'Descrizione difetto': 'Bulloni tranciati',
      element: 'apparecchisismici',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_7',
      component: 'Ritegni Sismici',
      component_type: 'Travi',
      'Descrizione difetto': 'Deformazioni anime / piattabande',
      element: 'apparecchisismici',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_8',
      component: 'Ritegni Sismici',
      component_type: 'Travi',
      'Descrizione difetto': 'Deformazioni pareti travi scatolari',
      element: 'apparecchisismici',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '3',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_2',
      component: 'Ritegni Sismici',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua",
      element: 'apparecchisismici',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Dif.Gen_5',
      component: 'Ritegni Sismici',
      component_type: 'Travi',
      'Descrizione difetto': "Ristagni d'acqua nei cassoni",
      element: 'apparecchisismici',
      elemento: 'Difetti Generici',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_9',
      component: 'Ritegni Sismici',
      component_type: 'Travi',
      'Descrizione difetto': 'Lesioni ai nodi',
      element: 'apparecchisismici',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '5',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_10',
      component: 'Ritegni Sismici',
      component_type: 'Travi',
      'Descrizione difetto': 'Corrosione',
      element: 'apparecchisismici',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '4',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: false,
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Acc_11',
      component: 'Ritegni Sismici',
      component_type: 'Travi',
      'Descrizione difetto': 'Ossidazione',
      element: 'apparecchisismici',
      elemento: 'Acciaio',
      'Estensione K1': null,
      G: '2',
      'Intensità K2': null,
      'K1 options': {
        '0.2': true,
        '0.5': true,
        '1': true,
      },
      'K2 options': {
        '0.2': false,
        '0.5': false,
        '1': true,
      },
      material: 'Metallo o Acciaio',
      'N° foto': '',
      NA: false,
      Note: '',
      NP: false,
      NR: false,
      PS: '',
      valorized: false,
      visto: false,
    },
    {
      'Codice difetto': 'Eventuali Note',
      component: 'Ritegni Sismici',
      component_type: 'Travi',
      element: 'apparecchisismici',
      isQuarterRow: true,
      material: 'Metallo o Acciaio',
      valorized: true,
      Visto: '',
    },
  ],
  // INFO: Elementi Accessori
  'Elementi Accessori': [
    {
      component: 'Elementi Accessori',
      element: 'Elementi Accessori',
      'Elementi Accessori': {
        // INFO: Elementi Accessori > Scheda Ispezione Ponti di Livello 1
        'Scheda Ispezione Ponti di Livello 1 List2': [
          {
            checkbox: false,
            'Codice difetto': 'El.Acc_1a',
            'Descrizione difetto': 'Dislivello rilevato impalcato',
            elemento: 'Elementi Accessori',
            'Elemento ispezionato': 'Stato della pavimentazione',
            G: '1',
            'N° foto': '',
            Note: '',
          },
          {
            checkbox: false,
            'Codice difetto': 'El.Acc_1b',
            'Descrizione difetto': 'Presenza di dossi',
            elemento: 'Elementi Accessori',
            'Elemento ispezionato': 'Stato della pavimentazione',
            G: '1',
            'N° foto': '',
            Note: '',
          },
          {
            checkbox: false,
            'Codice difetto': 'El.Acc_1c',
            'Descrizione difetto': 'Fessure anomalie pavimentazione',
            elemento: 'Elementi Accessori',
            'Elemento ispezionato': 'Stato della pavimentazione',
            G: '1',
            'N° foto': '',
            Note: '',
          },
          {
            checkbox: false,
            'Codice difetto': 'El.Acc_2a',
            'Descrizione difetto': 'Assenti',
            elemento: 'Elementi Accessori',
            'Elemento ispezionato': 'Cordoli',
            G: '0',
            'N° foto': '',
            Note: '',
          },
          {
            checkbox: false,
            'Codice difetto': 'El.Acc_2b',
            'Descrizione difetto': 'Degradati',
            elemento: 'Elementi Accessori',
            'Elemento ispezionato': 'Cordoli',
            G: '1',
            'N° foto': '',
            Note: '',
          },
          {
            checkbox: false,
            'Codice difetto': 'El.Acc_3',
            'Descrizione difetto': 'Assenti',
            elemento: 'Elementi Accessori',
            'Elemento ispezionato': 'Convogliamento acque',
            G: '3',
            'N° foto': '',
            Note: '',
          },
          {
            checkbox: false,
            'Codice difetto': 'El.Acc_4',
            'Descrizione difetto': 'Pozzetti intasati',
            elemento: 'Elementi Accessori',
            'Elemento ispezionato': 'Convogliamento acque',
            G: '1',
            'N° foto': '',
            Note: '',
          },
          {
            checkbox: false,
            'Codice difetto': 'El.Acc_5',
            'Descrizione difetto': 'Scarichi corti',
            elemento: 'Elementi Accessori',
            'Elemento ispezionato': 'Convogliamento acque',
            G: '2',
            'N° foto': '',
            Note: '',
          },
          {
            checkbox: false,
            'Codice difetto': 'El.Acc_6',
            'Descrizione difetto': 'Scarichi ostruiti',
            elemento: 'Elementi Accessori',
            'Elemento ispezionato': 'Convogliamento acque',
            G: '1',
            'N° foto': '',
            Note: '',
          },
          {
            checkbox: false,
            'Codice difetto': 'El.Acc_7',
            'Descrizione difetto': 'Scarichi danneggiati',
            elemento: 'Elementi Accessori',
            'Elemento ispezionato': 'Convogliamento acque',
            G: '2',
            'N° foto': '',
            Note: '',
          },
          {
            checkbox: false,
            'Codice difetto': 'El.Acc_8a',
            'Descrizione difetto': 'Assenti',
            elemento: 'Elementi Accessori',
            'Elemento ispezionato': 'Marciapiedi',
            G: '0',
            'N° foto': '',
            Note: '',
          },
          {
            checkbox: false,
            'Codice difetto': 'El.Acc_8b',
            'Descrizione difetto': 'Pavimetazione degradata',
            elemento: 'Elementi Accessori',
            'Elemento ispezionato': 'Marciapiedi',
            G: '1',
            'N° foto': '',
            Note: '',
          },
          {
            checkbox: false,
            'Codice difetto': 'El.Acc_9a',
            'Descrizione difetto': 'Assenti',
            elemento: 'Elementi Accessori',
            'Elemento ispezionato': 'Pali di illuminazione',
            G: '0',
            'N° foto': '',
            Note: '',
          },
          {
            checkbox: false,
            'Codice difetto': 'El.Acc_9b',
            'Descrizione difetto': 'Mal ancorati',
            elemento: 'Elementi Accessori',
            'Elemento ispezionato': 'Pali di illuminazione',
            G: '2',
            'N° foto': '',
            Note: '',
          },
          {
            checkbox: false,
            'Codice difetto': 'El.Acc_9c',
            'Descrizione difetto': 'Danneggiati',
            elemento: 'Elementi Accessori',
            'Elemento ispezionato': 'Pali di illuminazione',
            G: '2',
            'N° foto': '',
            Note: '',
          },
          {
            checkbox: false,
            'Codice difetto': 'El.Acc_10',
            'Descrizione difetto': 'Arrugginiti',
            elemento: 'Elementi Accessori',
            'Elemento ispezionato': 'Pali di illuminazione',
            G: '1',
            'N° foto': '',
            Note: '',
          },
          {
            checkbox: false,
            'Codice difetto': 'El.Acc_11a',
            'Descrizione difetto': 'Assenti',
            elemento: 'Elementi Accessori',
            'Elemento ispezionato': 'Sottoservizi',
            G: '0',
            'N° foto': '',
            Note: '',
          },
          {
            checkbox: false,
            'Codice difetto': 'El.Acc_11b',
            'Descrizione difetto': 'Mal ancorati',
            elemento: 'Elementi Accessori',
            'Elemento ispezionato': 'Sottoservizi',
            G: '2',
            'N° foto': '',
            Note: '',
          },
        ],
        // INFO: Elementi Accessori > Caso 1
        caso1: {
          // INFO: Elementi Accessori > Caso 1 > Guardiavia
          GUARDIAVIA: [
            {
              checkbox: false,
              'Codice difetto': 'El.Acc_12_1',
              G: '4',
              Guardiavia: 'GUARDIAVIA',
              'Marciapiede assente': 'Assente',
              'N° foto': '',
              Notes: '',
              'S No': 1,
              'Sub Marciapiede assente': '',
            },
            {
              checkbox: false,
              'Codice difetto': 'El.Acc_12a_1',
              G: '',
              Guardiavia: '',
              'Marciapiede assente': 'Tipologia',
              'N° foto': '',
              Notes: '',
              'S No': '',
              'Sub Marciapiede assente': 'Muratura',
            },
            {
              checkbox: false,
              'Codice difetto': 'El.Acc_12b_1',
              G: '',
              Guardiavia: '',
              'Marciapiede assente': '',
              'N° foto': '',
              Notes: '',
              'S No': '',
              'Sub Marciapiede assente': 'Ringhiera',
            },
            {
              checkbox: false,
              'Codice difetto': 'El.Acc_12c_1',
              G: '',
              Guardiavia: '',
              'Marciapiede assente': '',
              'N° foto': '',
              Notes: '',
              'S No': '',
              'Sub Marciapiede assente': 'Metallico',
            },
            {
              checkbox: false,
              'Codice difetto': 'El.Acc_12d_1',
              G: '',
              Guardiavia: '',
              'Marciapiede assente': '',
              'N° foto': '',
              Notes: '',
              'S No': '',
              'Sub Marciapiede assente': 'Altro',
            },
            {
              checkbox: false,
              'Codice difetto': 'El.Acc_13a_1',
              G: '2',
              Guardiavia: '',
              'Marciapiede assente': 'Danneggiati',
              'N° foto': '',
              Notes: '',
              'S No': '',
              'Sub Marciapiede assente': 'Lievemente',
            },
            {
              checkbox: false,
              'Codice difetto': 'El.Acc_13b_1',
              G: '2',
              Guardiavia: '',
              'Marciapiede assente': '',
              'N° foto': '',
              Notes: '',
              'S No': '',
              'Sub Marciapiede assente': 'Gravemente',
            },
            {
              checkbox: false,
              'Codice difetto': 'El.Acc_13c_1',
              G: '2',
              Guardiavia: '',
              'Marciapiede assente': 'Ossidati',
              'N° foto': '',
              Notes: '',
              'S No': '',
              'Sub Marciapiede assente': 'Lievemente',
            },
            {
              checkbox: false,
              'Codice difetto': 'El.Acc_13d_1',
              G: '2',
              Guardiavia: '',
              'Marciapiede assente': '',
              'N° foto': '',
              Notes: '',
              'S No': '',
              'Sub Marciapiede assente': 'Gravemente',
            },
            {
              checkbox: false,
              'Codice difetto': 'El.Acc_13e_1',
              G: '2',
              Guardiavia: '',
              'Marciapiede assente': 'Mal ancorati',
              'N° foto': '',
              Notes: '',
              'S No': '',
              'Sub Marciapiede assente': '',
            },
            {
              checkbox: false,
              'Codice difetto': 'El.Acc_13f_1',
              G: '2',
              Guardiavia: '',
              'Marciapiede assente': 'Elementi di ancoraggio',
              'N° foto': '',
              Notes: '',
              'S No': '',
              'Sub Marciapiede assente': 'Danneggiati',
            },
            {
              checkbox: false,
              'Codice difetto': 'El.Acc_13g_1',
              G: '2',
              Guardiavia: '',
              'Marciapiede assente': '',
              'N° foto': '',
              Notes: '',
              'S No': '',
              'Sub Marciapiede assente': 'Ossidati',
            },
            {
              checkbox: '',
              'Codice difetto': '',
              G: '',
              Guardiavia: '',
              'Marciapiede assente': 'Altezza (cm)',
              'N° foto': '',
              Notes: '',
              'S No': '',
              'Sub Marciapiede assente': '',
            },
          ],
        },
        // INFO: Elementi Accessori > Caso 2
        caso2: {
          // INFO: Elementi Accessori > Caso 2 > Guardiavia
          GUARDIAVIA: [
            {
              Assente: 'Assente',
              checkbox: '',
              'Codice difetto': 'El.Acc_12_1',
              details: '',
              G: '4',
              Marciapiede: 'GUARDIAVIA',
              'N° foto': '',
              'S No': 1,
              'sub-assente': '',
            },
            {
              Assente: 'Tipologia',
              checkbox: '',
              'Codice difetto': 'El.Acc_12a_1',
              details: '',
              G: '',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Muratura',
            },
            {
              Assente: '',
              checkbox: '',
              'Codice difetto': 'El.Acc_12b_1',
              details: '',
              G: '',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Ringhiera',
            },
            {
              Assente: '',
              checkbox: '',
              'Codice difetto': 'El.Acc_12c_1',
              details: '',
              G: '',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Metallico',
            },
            {
              Assente: '',
              checkbox: '',
              'Codice difetto': 'El.Acc_12d_1',
              details: '',
              G: '',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Altro',
            },
            {
              Assente: 'Danneggiati',
              checkbox: '',
              'Codice difetto': 'El.Acc_13a_1',
              details: '',
              G: '2',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Lievemente',
            },
            {
              Assente: '',
              checkbox: '',
              'Codice difetto': 'El.Acc_13b_1',
              details: '',
              G: '2',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Gravemente',
            },
            {
              Assente: 'Ossidati',
              checkbox: '',
              'Codice difetto': 'El.Acc_13c_1',
              details: '',
              G: '2',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Lievemente',
            },
            {
              Assente: '',
              checkbox: '',
              'Codice difetto': 'El.Acc_13d_1',
              details: '',
              G: '2',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Gravemente',
            },
            {
              Assente: 'Mal ancorati',
              checkbox: '',
              'Codice difetto': 'El.Acc_13e_1',
              details: '',
              G: '2',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': '',
            },
            {
              Assente: 'Elementi di ancoraggio',
              checkbox: '',
              'Codice difetto': 'El.Acc_13f_1',
              details: '',
              G: '2',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Danneggiati',
            },
            {
              Assente: '',
              checkbox: '',
              'Codice difetto': 'El.Acc_13g_1',
              details: '',
              G: '2',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Ossidati',
            },
            {
              Assente: 'Altezza (cm)',
              checkbox: '',
              'Codice difetto': '',
              details: '',
              G: '',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': '',
            },
          ],
          // INFO: Elementi Accessori > Caso 2 > Parapetto
          PARAPETTO: [
            {
              Assente: 'Assente',
              checkbox: '',
              'Codice difetto': 'El.Acc_12_2',
              details: '',
              G: '4',
              Marciapiede: 'PARAPETTO(Guardiavia se 1 assente)',
              'N° foto': '',
              'S No': 2,
              'sub-assente': '',
            },
            {
              Assente: 'Tipologia',
              checkbox: '',
              'Codice difetto': 'El.Acc_12a_2',
              details: '',
              G: '',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Muratura',
            },
            {
              Assente: '',
              checkbox: '',
              'Codice difetto': 'El.Acc_12b_2',
              details: '',
              G: '',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Ringhiera',
            },
            {
              Assente: '',
              checkbox: '',
              'Codice difetto': 'El.Acc_12c_2',
              details: '',
              G: '',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Metallico',
            },
            {
              Assente: '',
              checkbox: '',
              'Codice difetto': 'El.Acc_12d_2',
              details: '',
              G: '',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Altro',
            },
            {
              Assente: 'Danneggiati',
              checkbox: '',
              'Codice difetto': 'El.Acc_13a_2',
              details: '',
              G: '2',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Lievemente',
            },
            {
              Assente: '',
              checkbox: '',
              'Codice difetto': 'El.Acc_13b_2',
              details: '',
              G: '2',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Gravemente',
            },
            {
              Assente: 'Ossidati',
              checkbox: '',
              'Codice difetto': 'El.Acc_13c_2',
              details: '',
              G: '2',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Lievemente',
            },
            {
              Assente: '',
              checkbox: '',
              'Codice difetto': 'El.Acc_13d_2',
              details: '',
              G: '2',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Gravemente',
            },
            {
              Assente: 'Mal ancorati',
              checkbox: '',
              'Codice difetto': 'El.Acc_13e_2',
              details: '',
              G: '2',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': '',
            },
            {
              Assente: 'Elementi di ancoraggio',
              checkbox: '',
              'Codice difetto': 'El.Acc_13f_2',
              details: '',
              G: '2',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Danneggiati',
            },
            {
              Assente: '',
              checkbox: '',
              'Codice difetto': 'El.Acc_13g_2',
              details: '',
              G: '2',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Ossidati',
            },
            {
              Assente: 'Altezza (cm)',
              checkbox: '',
              'Codice difetto': '',
              details: '',
              G: '',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': '',
            },
          ],
        },
        // INFO: Elementi Accessori > Caso 3
        caso3: {
          // INFO: Elementi Accessori > Caso 3 > Guardiavia
          GUARDIAVIA: [
            {
              Assente: 'Assente',
              checkbox: '',
              'Codice difetto': 'El.Acc_12_1',
              details: '',
              G: '4',
              Marciapiede: 'GUARDIAVIA',
              'N° foto': '',
              'S No': 1,
              'sub-assente': '',
            },
            {
              Assente: 'Presente',
              checkbox: '',
              'Codice difetto': 'El.Acc_12g_1',
              details: '',
              G: '',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Come guardiavia',
            },
            {
              Assente: '',
              checkbox: '',
              'Codice difetto': 'El.Acc_12p_1',
              details: '',
              G: '',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Come parapetto',
            },
            {
              Assente: 'Tipologia',
              checkbox: '',
              'Codice difetto': 'El.Acc_12a_1',
              details: '',
              G: '',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Muratura',
            },
            {
              Assente: '',
              checkbox: '',
              'Codice difetto': 'El.Acc_12b_1',
              details: '',
              G: '',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Ringhiera',
            },
            {
              Assente: '',
              checkbox: '',
              'Codice difetto': 'El.Acc_12c_1',
              details: '',
              G: '',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Metallico',
            },
            {
              Assente: '',
              checkbox: '',
              'Codice difetto': 'El.Acc_12d_1',
              details: '',
              G: '',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Altro',
            },
            {
              Assente: 'Danneggiati',
              checkbox: '',
              'Codice difetto': 'El.Acc_13a_1',
              details: '',
              G: '2',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Lievemente',
            },
            {
              Assente: '',
              checkbox: '',
              'Codice difetto': 'El.Acc_13b_1',
              details: '',
              G: '2',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Gravemente',
            },
            {
              Assente: 'Ossidati',
              checkbox: '',
              'Codice difetto': 'El.Acc_13c_1',
              details: '',
              G: '2',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Lievemente',
            },
            {
              Assente: '',
              checkbox: '',
              'Codice difetto': 'El.Acc_13d_1',
              details: '',
              G: '2',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Gravemente',
            },
            {
              Assente: 'Mal ancorati',
              checkbox: '',
              'Codice difetto': 'El.Acc_13e_1',
              details: '',
              G: '2',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': '',
            },
            {
              Assente: 'Elementi di ancoraggio',
              checkbox: '',
              'Codice difetto': 'El.Acc_13f_1',
              details: '',
              G: '2',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Danneggiati',
            },
            {
              Assente: '',
              checkbox: '',
              'Codice difetto': 'El.Acc_13g_1',
              details: '',
              G: '2',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Ossidati',
            },
            {
              Assente: 'Altezza (cm)',
              checkbox: '',
              'Codice difetto': '',
              details: '',
              G: '',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': '',
            },
          ],
          // INFO: Elementi Accessori > Caso 3 > Parapetto
          PARAPETTO: [
            {
              Assente: 'Assente (se guardiavia non necessario)',
              checkbox: '',
              'Codice difetto': 'El.Acc_12_2',
              details: '',
              G: '4',
              Marciapiede: 'PARAPETTO(Guardiavia se 1 assente)',
              'N° foto': '',
              'S No': 2,
              'sub-assente': '',
            },
            {
              Assente: 'Tipologia',
              checkbox: '',
              'Codice difetto': 'El.Acc_12a_2',
              details: '',
              G: '',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Muratura',
            },
            {
              Assente: '',
              checkbox: '',
              'Codice difetto': 'El.Acc_12b_2',
              details: '',
              G: '',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Ringhiera',
            },
            {
              Assente: '',
              checkbox: '',
              'Codice difetto': 'El.Acc_12c_2',
              details: '',
              G: '',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Metallico',
            },
            {
              Assente: '',
              checkbox: '',
              'Codice difetto': 'El.Acc_12d_2',
              details: '',
              G: '',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Altro',
            },
            {
              Assente: 'Danneggiati',
              checkbox: '',
              'Codice difetto': 'El.Acc_13a_2',
              details: '',
              G: '2',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Lievemente',
            },
            {
              Assente: '',
              checkbox: '',
              'Codice difetto': 'El.Acc_13b_2',
              details: '',
              G: '2',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Gravemente',
            },
            {
              Assente: 'Ossidati',
              checkbox: '',
              'Codice difetto': 'El.Acc_13c_2',
              details: '',
              G: '2',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Lievemente',
            },
            {
              Assente: '',
              checkbox: '',
              'Codice difetto': 'El.Acc_13d_2',
              details: '',
              G: '2',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Gravemente',
            },
            {
              Assente: 'Mal ancorati',
              checkbox: '',
              'Codice difetto': 'El.Acc_13e_2',
              details: '',
              G: '2',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': '',
            },
            {
              Assente: 'Elementi di ancoraggio',
              checkbox: '',
              'Codice difetto': 'El.Acc_13f_2',
              details: '',
              G: '2',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Danneggiati',
            },
            {
              Assente: '',
              checkbox: '',
              'Codice difetto': 'El.Acc_13g_2',
              details: '',
              G: '2',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': 'Ossidati',
            },
            {
              Assente: 'Altezza (cm)',
              checkbox: '',
              'Codice difetto': '',
              details: '',
              G: '',
              Marciapiede: '',
              'N° foto': '',
              'S No': '',
              'sub-assente': '',
            },
          ],
        },
      },
      material: '',
    },
  ],
  // INFO: Tipologia Elementi Structures
  tipologiaElementiStructures: [
    {
      component: 'tipologiaElementiStructures',
      element: 'tipologiaElementiStructures',
      material: '',
      // INFO: Tipologia Elementi Structures > Tipologia Elementi Strutturali
      tipologiaElementiStrutturali: [
        {
          impalcato: '',
          materiale: 'C.a.',
          n0_campate: '',
          n0_elementi: '',
          pile: '',
          spalle: '',
          tipologia_elementi_strutturali: '',
        },
        {
          impalcato: '',
          materiale: 'C.A.P',
          n0_campate: '',
          n0_elementi: '',
          pile: '',
          spalle: '',
          tipologia_elementi_strutturali: '',
        },
        {
          impalcato: '',
          materiale: 'Acciaio',
          n0_campate: '',
          n0_elementi: '',
          pile: '',
          spalle: '',
          tipologia_elementi_strutturali: '',
        },
        {
          impalcato: '',
          materiale: 'Acciaio - Calcestruzzo',
          n0_campate: '',
          n0_elementi: '',
          pile: '',
          spalle: '',
          tipologia_elementi_strutturali: '',
        },
        {
          impalcato: '',
          materiale: 'Muratura',
          n0_campate: '',
          n0_elementi: '',
          pile: '',
          spalle: '',
          tipologia_elementi_strutturali: '',
        },
        {
          impalcato: '',
          materiale: 'Legno',
          n0_campate: '',
          n0_elementi: '',
          pile: '',
          spalle: '',
          tipologia_elementi_strutturali: '',
        },
        {
          impalcato: '',
          materiale: 'Altro',
          n0_campate: '',
          n0_elementi: '',
          pile: '',
          spalle: '',
          tipologia_elementi_strutturali: '',
        },
      ],
      // INFO: Tipologia Elementi Structures > Tipologia Elementi Strutturali Impalcato
      tipologiaElementiStrutturaliImpalcato: [
        {
          Acciaio: '',
          'Acciaio Calcestruzzo': '',
          Altro: '',
          'C A': '',
          'C A P': '',
          'Elemento strutturale': 'Soletta [n° campate]',
          Legno: '',
          Muratura: '',
          'n° elementi totali': '',
        },
        {
          Acciaio: '',
          'Acciaio Calcestruzzo': '',
          Altro: '',
          'C A': '',
          'C A P': '',
          'Elemento strutturale': 'Travi [n° elementi]',
          Legno: '',
          Muratura: '',
          'n° elementi totali': '',
        },
        {
          Acciaio: '',
          'Acciaio Calcestruzzo': '',
          Altro: '',
          'C A': '',
          'C A P': '',
          'Elemento strutturale': 'Traversi [n° elementi]',
          Legno: '',
          Muratura: '',
          'n° elementi totali': '',
        },
        {
          Acciaio: '',
          'Acciaio Calcestruzzo': '',
          Altro: '',
          'C A': '',
          'C A P': '',
          'Elemento strutturale': 'Arco [n° elementi]',
          Legno: '',
          Muratura: '',
          'n° elementi totali': '',
        },
        {
          Acciaio: '',
          'Acciaio Calcestruzzo': '',
          Altro: '',
          'C A': '',
          'C A P': '',
          'Elemento strutturale': 'Altro',
          Legno: '',
          Muratura: '',
          'n° elementi totali': '',
        },
      ],
    },
  ],
};
