import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { LoaderService } from '../../../services/loader/loader.service';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cube-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit, OnDestroy {
  isLoaderVisible: boolean = false;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private cdRef: ChangeDetectorRef,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.setEventListeners();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setEventListeners(): void {
    this.loaderService
      .getLoader$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.isLoaderVisible = status;
        this.cdRef.detectChanges();
      });
  }
}
