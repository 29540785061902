export enum ReportWorkerMessageType {
  addAllOngoingReportJobs = 'addAllOngoingReportJobs',
  addJob = 'addJob',
  reportCompleted = 'reportCompleted',
  reportFailed = 'reportFailed',
  reportOngoing = 'reportOngoing',
  tokenUpdated = 'tokenUpdated',
  workerClosed = 'workerClosed',
  workerInitialization = 'workerInitialization',
}
