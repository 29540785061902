import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ReportJobDetails } from '../../../models/report';
import { ReportService } from '../../../services/api/report/report.service';
import { ReportErrors } from '../../configs/report-errors.config';
import { ReportMode } from '../../configs/report-mode.config';

@Component({
  selector: 'cube-failed-report-dialog',
  templateUrl: './failed-report-dialog.component.html',
  styleUrls: ['./failed-report-dialog.component.scss'],
})
export class FailedReportDialogComponent {
  failedReason?: string;
  jobDetails: ReportJobDetails;
  reportErrors: typeof ReportErrors = ReportErrors;
  reportMode: ReportMode;
  reportModeConfig: typeof ReportMode = ReportMode;

  constructor(
    @Inject(MAT_DIALOG_DATA) private matDialogData: any,
    private reportService: ReportService
  ) {
    this.jobDetails = this.matDialogData.jobDetails;
    this.jobDetails.reportName = this.reportService.getReportName(
      this.jobDetails.reportType
    );

    this.failedReason = this.getFailedReason();
    this.reportMode = this.reportService.getReportMode(
      this.jobDetails.reportType
    );
  }

  private getFailedReason(): string {
    if (!this.jobDetails.failedInspections?.length) return;

    const failedReason = this.reportService.getSingleReportErrorMessage(
      this.jobDetails.failedInspections[0].error_code
    );

    return failedReason;
  }
}
