import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { RoleModule, User } from '../../models/cube';
import { Tenant } from '../../models/tenant';
import { AppFeature } from '../../shared/configs/app-feature.config';
import { UserRole } from '../../shared/configs/user-role.config';
import { AdminToolService } from '../api/admin-tool/admin-tool.service';
import { TenantService } from '../api/tenant/tenant.service';
import { STORAGE_KEYS } from '../storage/storage.service';

import { KeycloakService } from 'keycloak-angular';
import { intersection } from 'lodash';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  onDepartmentChange: Subject<string> = new Subject();
  userTenantsList: string[] = [];

  private _role: string;
  private _tenant: Tenant;
  private _user: User = null;
  private logoutRedirect: string = window.location.origin;
  private profileRedirect =
    environment.keycloak.url +
    '/realms/' +
    environment.keycloak.realm +
    '/account/';

  constructor(
    private adminToolService: AdminToolService,
    private keycloakService: KeycloakService,
    private tenantService: TenantService
  ) {}

  composeRoleFeatures(activeRole: string): Observable<any> {
    return forkJoin([this.adminToolService.getRoleFeatures(activeRole)]).pipe(
      tap(([features]) => {
        if (!this._user) return;

        // Finding the active role in user's rolesAndModules and setting its roleModules property to an array containing features
        this._user.rolesAndModules.find(
          (r) => r.name === activeRole
        ).roleModules = [features];
      })
    );
  }

  configureCurrentTenant(username: string): Observable<string[]> {
    return this.adminToolService.getAllTenants(username).pipe(
      tap((tenants) => {
        if (!tenants?.length) return;

        this.userTenantsList = tenants.filter((t) => t !== 'root');

        let currentTenant = this.getCurrentTenant();
        if (currentTenant) return;

        // If there is no current tenant in local storage, set the first tenant as current tenant
        currentTenant = this.userTenantsList[0];
        localStorage.setItem(STORAGE_KEYS.currentTenant, currentTenant);
      })
    );
  }

  doLogin(): void {
    this.keycloakService.login();
  }

  doLogout(redirect?: string): void {
    this._user = null;
    this.keycloakService.logout(redirect || this.logoutRedirect);
  }

  getActiveRole = () => this._role;

  getActiveRoleScopes = () =>
    this._user?.rolesAndModules.find((r) => r.name === this._role).scopes;

  getAllRoles = () => this._user?.rolesAndModules.map((r) => r.name);

  getCurrentTenant(): string | null {
    // Return the tenant name
    return localStorage.getItem(STORAGE_KEYS.currentTenant);
  }

  getCurrentTenantDetails(): Observable<Tenant> {
    const currentTenant = this.getCurrentTenant();

    return this.tenantService.getTenant(currentTenant).pipe(
      map((res) => res.data),
      tap((tenant) => {
        this._tenant = tenant;
      })
    );
  }

  getTenant(): Tenant {
    // Return the complete tenant object
    return this._tenant;
  }

  getUser(): User {
    return this._user;
  }

  goToProfile() {
    window.location.href = this.profileRedirect;
  }

  hasRole(roles: string | string[]): boolean {
    roles = [].concat(roles);
    return (
      roles.includes('*') ||
      this._user.rolesAndModules
        .map((m) => m.name)
        .some((r) => roles.includes(r))
    );
  }

  hasTenantModules(
    featureName: string | string[],
    role: string = this._role
  ): boolean {
    const features = [].concat(featureName);
    const roleModulesFeatures = role
      ? [].concat(this._user.rolesAndModules.find((r) => r.name === role))
      : this._user.rolesAndModules;

    let has = false;
    roleModulesFeatures.forEach((modulesList) => {
      modulesList.roleModules.forEach((module) => {
        if (
          intersection(
            features,
            module.map((f) => f.name)
          ).length
        ) {
          has = true;
        }
      });
    });

    return has;
  }

  isLoggedIn(): boolean {
    return this._user != null;
  }

  isUserAllowedByFeature(featuresList: AppFeature[]): boolean {
    if (featuresList == null || featuresList.length === 0) return true;

    const roleModulesList: RoleModule[] = this._user.rolesAndModules.find(
      (userRolesAndModules) => userRolesAndModules.name === this._role
    )?.roleModules?.[0] as any;

    const isFeaturePresent = roleModulesList.some((roleModule) => {
      if (featuresList.includes(roleModule.name as any)) return true;

      if (!roleModule.features) return false;

      return roleModule.features.some((feature) =>
        featuresList.includes(feature.name as any)
      );
    });

    return isFeaturePresent;
  }

  isUserAllowedByRole(rolesList: UserRole[]): boolean {
    if (rolesList == null || rolesList.length === 0) return true;

    const isRolePresent = rolesList.includes(this._role as any);

    return isRolePresent;
  }

  setActiveRole(role): void {
    localStorage.setItem(STORAGE_KEYS.activeRole, role);
    this._role = role;
    this.onDepartmentChange.next(role);
  }

  setUser(user: User): void {
    this._user = user;
  }

  updateToken(token: string): void {
    this._user.token = token;
  }
}
