import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';

import { ImportService } from './services/api/inspection-import/import.service';
import { ReportService } from './services/api/report/report.service';
import { CubeService } from './services/cube/cube.service';
import { LanguageService } from './services/language/language.service';
import { IdbService } from './services/storage/idb.service';
import { STORAGE_KEYS } from './services/storage/storage.service';
import { ThemeService } from './services/theme/theme.service';

import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'cube-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isDarkTheme: boolean;

  private subscriptions: Subscription[] = [];

  constructor(
    private cubeService: CubeService,
    private languageService: LanguageService,
    private idbService: IdbService,
    private importService: ImportService,
    private overlayContainer: OverlayContainer,
    private primeNgConfig: PrimeNGConfig,
    private renderer: Renderer2,
    private reportService: ReportService,
    private themeService: ThemeService,
    translate: TranslateService
  ) {
    this.languageService.setDefaultLanguage();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.themeService.isDarkTheme.subscribe((isDark) => {
        this.isDarkTheme = isDark;
        this.overlayContainer
          .getContainerElement()
          .classList.remove(isDark ? 'light-theme' : 'dark-theme');
        this.overlayContainer
          .getContainerElement()
          .classList.add(!isDark ? 'light-theme' : 'dark-theme');
      })
    );

    fromEvent(window, 'storage')
      .pipe(filter((ev: StorageEvent) => ev.key === STORAGE_KEYS.currentTenant))
      .subscribe((storageEvent: StorageEvent) => {
        this.cubeService.cleanCache();
        window.location.reload();
      });

    this.primeNgConfig.ripple = true;
    this.addAllOngoingReportJobsToWorker();
    this.addAllOngoingImportJobsToWorker();

    this.renderer.setStyle(document.body, 'overscroll-behavior-y', 'contain');
    this.renderer.setStyle(document.body, 'overscroll-behavior-x', 'contain');

    // Setup indexedDB
    this.idbService.initializeDatabase();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  getRouteAnimation(outlet) {
    return outlet.activatedRouteData.animation;
  }

  private addAllOngoingReportJobsToWorker(): void {
    this.reportService.addAllOngoingReportJobsToWorker();
  }

  private addAllOngoingImportJobsToWorker(): void {
    this.importService.addAllOngoingImportJobsToWorker();
  }
}
