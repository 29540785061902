import { StructureConstants } from '../constants';

export const TunnelConstants = {
  levels: {
    ...StructureConstants.levels,
    defect1: 'defect1',
    defect2: 'defect2',
    defect3: 'defect3',
    defect4: 'defect4',
    defect5: 'defect5',
  },
  partNo: 'Concio No',
  partsList: 'conciolist',
  eventualiNote: 'Eventuali note',
};
