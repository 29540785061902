import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { environment } from '../../../../environments/environment';
import { FailedImportDialogComponent } from '../../../shared/components/failed-import-dialog/failed-import-dialog.component';
import { ImportCompletedDialogComponent } from '../../../shared/components/import-completed-dialog/import-completed-dialog.component';
import { ImportWorkerMessageType } from '../../../shared/configs/import-worker-message-type.config';
import { CubeService } from '../../cube/cube.service';
import { StartImportDialogComponent } from '../../../shared/components/start-import-dialog/start-import-dialog.component';
import { ImportJobDetails } from '../../../models/import';
import { InspectionCrudService } from '../inspection-crud/inspection-crud.service';

import { Observable, Subject } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ImportService implements OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  private importStatusUpdated$: Subject<ImportJobDetails> =
    new Subject<ImportJobDetails>();
  private importWebWorker: Worker;
  private startImportDialogRef?: MatDialogRef<StartImportDialogComponent>;

  constructor(
    private cubeService: CubeService,
    private inspectionCrudService: InspectionCrudService,
    private matDialog: MatDialog
  ) {
    this.createImportWebWorker();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addAllOngoingImportJobsToWorker(): void {
    this.importWebWorker.postMessage({
      type: ImportWorkerMessageType.addAllOngoingImportJobs,
    });
  }

  addJobToImportWebWorker(jobDetails: Partial<ImportJobDetails>): void {
    if (!this.importWebWorker) this.createImportWebWorker();

    // Adding new job to worker
    this.importWebWorker.postMessage({
      jobDetails,
      type: ImportWorkerMessageType.addJob,
    });
  }

  private createImportWebWorker(): void {
    this.importWebWorker = new Worker(
      new URL('../../../import.worker.ts', import.meta.url)
    );

    // Handling messages from from web worker
    this.importWebWorker.onmessage = ({ data }) => {
      const { jobDetails } = data;

      switch (data.type) {
        case ImportWorkerMessageType.importCompleted:
          this.startImportDialogRef?.close();
          this.matDialog.open(ImportCompletedDialogComponent, {
            data: { jobDetails },
            disableClose: true,
          });
          this.setImportStatusUpdated$(jobDetails);

          break;

        case ImportWorkerMessageType.importFailed:
          this.startImportDialogRef?.close();
          this.matDialog.open(FailedImportDialogComponent, {
            data: { jobDetails },
            disableClose: true,
          });
          this.setImportStatusUpdated$(jobDetails);

          break;

        case ImportWorkerMessageType.workerClosed:
          delete this.importWebWorker;

          break;

        case ImportWorkerMessageType.importOngoing:
          this.setImportStatusUpdated$(jobDetails);

          break;

        default:
          break;
      }
    };

    // Initializing worker values
    this.importWebWorker.postMessage({
      ...this.cubeService.getWorkerHeaders(),
      apiUrls: {
        getFileUploadDetails:
          this.inspectionCrudService.endpoint.getFileUploadDetails(''),
        getMassiveImportDetails:
          this.inspectionCrudService.endpoint.getMassiveImportDetails(),
      },
      importWorkerInterval: environment.importWorkerInterval,
      type: ImportWorkerMessageType.workerInitialization,
    });

    // Continuously pass token into web worker
    this.cubeService
      .token()
      .pipe(
        takeWhile(() => !!this.importWebWorker),
        takeUntil(this.destroy$)
      )
      .subscribe((token) => {
        this.importWebWorker.postMessage({
          token,
          type: ImportWorkerMessageType.tokenUpdated,
        });
      });
  }

  getImportStatusUpdated$(): Observable<ImportJobDetails> {
    return this.importStatusUpdated$.asObservable();
  }

  openImportDialog(jobDetails: Partial<ImportJobDetails>): void {
    this.startImportDialogRef = this.matDialog.open(
      StartImportDialogComponent,
      {
        data: { jobDetails },
        disableClose: true,
      }
    );

    this.startImportDialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        delete this.startImportDialogRef;
      });
  }

  setImportStatusUpdated$(jobDetails: ImportJobDetails): void {
    this.importStatusUpdated$.next(jobDetails);
  }
}
