import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EXCLUDE_LOADER } from '../../shared/utils/http-context';
import { LoaderService } from '../loader/loader.service';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class HttpLoaderInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.updateLoader(request, next);
  }

  private updateLoader(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Check if the request is excluded from the loader
    const isExcludeLoader = request.context.get(EXCLUDE_LOADER);

    // If the request is not excluded from the loader, show the spinner
    if (!isExcludeLoader) this.loaderService.requestStarted();

    return next.handle(request).pipe(
      finalize(() => {
        // If the request is not excluded from the loader, hide the spinner
        if (!isExcludeLoader) this.loaderService.requestEnded();
      })
    );
  }
}
