import { HttpContextToken, HttpStatusCode } from '@angular/common/http';

export const EXCLUDE_LOADER = new HttpContextToken<boolean>(() => false);

export const HANDLED_ERRORS = new HttpContextToken<HttpStatusCode[]>(
  () => null
);

export type HttpContexts = {
  excludeLoader?: boolean;
  handledErrors?: HttpStatusCode[];
};
