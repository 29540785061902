import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'cube-text-only-renderer',
  template: `<p>{{ params.pre }}{{ params.value }}{{ params.post }}</p>`,
  styles: [
    `
      p {
        margin: 0;
        overflow-wrap: break-word;
        white-space: normal;
      }
    `,
  ],
})
export class TextOnlyRendererComponent implements ICellRendererAngularComp {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams<any, any>): boolean {
    throw new Error('Method not implemented.');
  }
}
