import { formatDate } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

import { StructureConstants } from '../../../../../../assets/structure-config';
import { environment } from '../../../../../../environments/environment';
import { InspectionService } from '../../../../../services/api/inspection/inspection.service';
import { CubeService } from '../../../../../services/cube/cube.service';
import { IdbService } from '../../../../../services/storage/idb.service';
import { StorageService } from '../../../../../services/storage/storage.service';
import { DefectDialogMode } from '../../../../../shared/configs/defect-dialog-mode.config';

import { Subject } from 'rxjs';

interface DefectImageFile {
  file: File;
  isCameraImage: boolean;
}

interface DefectSection {
  newFiles: Set<DefectImageFile>;
  oldFiles: Record<string, string>;
  sectionNo: number;
}

@Component({
  selector: 'cube-upload-defect-files-dialog',
  templateUrl: './upload-defect-files-dialog.component.html',
  styleUrls: ['./upload-defect-files-dialog.component.scss'],
})
export class UploadDefectFilesDialogComponent implements OnInit, OnDestroy {
  @Input() localizzazioneParams;

  @Output('saved') saved: EventEmitter<void> = new EventEmitter<void>();
  @Output() uploadDefectsClosed: EventEmitter<any> = new EventEmitter<any>();

  @ViewChildren('fileUpload') fileUploadEl: QueryList<
    ElementRef<HTMLInputElement>
  >;

  acceptedFileTypes: string[] = [];
  defectDialogMode: typeof DefectDialogMode = DefectDialogMode;
  deletedFileKeys: string[] = [];
  dialogMode: DefectDialogMode;
  disabled: boolean = false;
  maxSectionLimit: number = 5;
  newFiles: Set<DefectImageFile> = new Set();
  oldFiles: any;
  params: any;
  sectionList: DefectSection[] = [];

  private componentRowIndex: number;
  private deletedFileUrls: string[] = [];
  private destroy$: Subject<void> = new Subject<void>();
  private fotoCount: number;
  private maxFilesPerSectionLimit: number = 4;
  private sectionNoList: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private matDialogData: any,
    private cubeService: CubeService,
    private idbService: IdbService,
    private inspectionService: InspectionService,
    private matDialog: MatDialog,
    private matDialogRef: MatDialogRef<UploadDefectFilesDialogComponent>,
    private storageService: StorageService
  ) {
    this.dialogMode =
      this.matDialogData.mode ?? DefectDialogMode.tunnelDefectUpload; // Mode will be present for all other cases
    this.setEventListeners();
  }

  ngOnInit(): void {
    this.params =
      this.dialogMode === DefectDialogMode.tunnelDefectUpload
        ? this.localizzazioneParams
        : this.matDialogData.params;
    this.acceptedFileTypes =
      this.params.acceptedFileTypes ??
      environment.file_upload.accepted_file_types.all;
    this.disabled = this.params.disabled;
    this.setSections();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getFileNameWithoutSectionNo(fileName: string): string {
    return fileName.split(StructureConstants.sectionNo).shift();
  }

  getNewFilesSize(): any {
    // INFO: Functionality not applicable in PORTAL
  }

  isFileLimitReached(index: number): any {
    // INFO: Functionality not applicable in PORTAL
  }

  onChangeFileUpload(index: number): void {
    // INFO: Functionality not applicable in PORTAL
  }

  onClickAddSection(): void {
    // INFO: Functionality not applicable in PORTAL
  }

  onClickCapture(index: number): void {
    // INFO: Functionality not applicable in PORTAL
  }

  onClickDeleteNewFile(deletedFile: DefectImageFile, index: number): void {
    // INFO: Functionality not applicable in PORTAL
  }

  onClickDeleteOldFile(e: MouseEvent, key: string, url: string): void {
    // INFO: Functionality not applicable in PORTAL
  }

  onClickDeleteSection(index: number): void {
    // INFO: Functionality not applicable in PORTAL
  }

  onClickNewFile(defectImageFile: DefectImageFile): void {
    // INFO: Functionality not applicable in PORTAL
  }

  async onClickOldFile({ key: fotoName, value: url }): Promise<void> {
    this.cubeService.openFilePreview(
      url,
      this.params.colDef.token,
      {
        fotoName,
        modelHash: this.params.colDef.modelData.modelHash,
      },
      this.params.colDef.modelData.structure_type
    );
  }

  onClickUndoDeleteFile(e: MouseEvent, key: string, url: string): void {
    // INFO: Functionality not applicable in PORTAL
  }

  onSubmitForm(): void {
    // INFO: Functionality not applicable in PORTAL
  }

  private addFileToSection(
    fileName: string,
    fileUrl: string,
    sectionNo: number
  ): void {
    const sectionListIndex = this.sectionList.findIndex(
      (el) => el.sectionNo === sectionNo
    );

    // Section already exists
    if (sectionListIndex !== -1) {
      this.sectionList[sectionListIndex].oldFiles[fileName] = fileUrl;

      return;
    }

    // Adding new section since section is not present in list
    this.sectionList.push({
      newFiles: new Set<DefectImageFile>(),
      oldFiles: { [fileName]: fileUrl },
      sectionNo,
    });
    this.sectionNoList.push(sectionNo);
  }

  private addSection(): void {
    const sortedArray = this.sectionNoList.sort((a, b) => a - b);
    const sectionNo = sortedArray.length
      ? sortedArray[sortedArray.length - 1] + 1
      : 1;
    this.sectionList.push({
      newFiles: new Set<DefectImageFile>(),
      oldFiles: {},
      sectionNo,
    });
    this.sectionNoList.push(sectionNo);
  }

  private deleteFiles(): void {
    // INFO: Functionality not applicable in PORTAL
  }

  private generateFileName(
    defectImageFile: DefectImageFile,
    index: number
  ): any {
    // INFO: Functionality not applicable in PORTAL
  }

  private groupImagesIntoSections(): void {
    const nFoto = this.params.data['N° foto'];
    if (!nFoto) {
      this.addSection();

      return;
    }

    // Adding existing files into sections
    Object.entries(nFoto).forEach(([fileName, fileUrl]: [string, string]) => {
      // Getting section number
      const sectionNoIndex = fileName.indexOf(StructureConstants.sectionNo);

      const sectionNo = Number(
        fileName.slice(sectionNoIndex + StructureConstants.sectionNo.length)
      );

      this.addFileToSection(fileName, fileUrl, sectionNo);
    });
  }

  private setEventListeners(): void {
    // INFO: Functionality not applicable in PORTAL
  }

  private setSections(): void {
    switch (this.dialogMode) {
      case DefectDialogMode.bridgeDefectUpload:
        // Bridge - Opening from N Foto column
        this.groupImagesIntoSections();

        break;

      case DefectDialogMode.elementiAccessori:
        // Bridge - Opening from Elementi Accessori
        this.addSection();
        this.sectionList[0].oldFiles = this.params.data['N° foto'];

        break;

      case DefectDialogMode.bridgeEventualiNote:
        // Bridge - Opening from eventuali note
        this.addSection();
        this.sectionList[0].oldFiles = this.params.data.Note;

        break;

      case DefectDialogMode.tunnelDefectUpload:
        // Tunnel - Opening from N Foto inside L column
        this.addSection();
        this.sectionList[0].oldFiles = this.params.data.L
          ? this.params.data.L[this.params.localizzazioneKey]['N° foto']
          : '';

        break;

      case DefectDialogMode.tunnelDefectView:
        // Tunnel - Opening from N Foto column
        this.addSection();
        this.sectionList[0].oldFiles = this.params.data.L
          ? {
              ...this.params.data.L.sezioneLongitudinale1['N° foto'],
              ...this.params.data.L.sezioneLongitudinale2['N° foto'],
              ...this.params.data.L.sezioneLongitudinale3['N° foto'],
              ...this.params.data.L.sezioneLongitudinale4['N° foto'],
            }
          : {};

        break;
    }
  }

  private async uploadAndDeleteFiles(): Promise<void> {
    // INFO: Functionality not applicable in PORTAL
  }

  private async uploadFiles(): Promise<void> {
    // INFO: Functionality not applicable in PORTAL
  }
}
