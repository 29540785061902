import { HttpContext } from '@angular/common/http'; // TEMP: For migration
import { Injectable } from '@angular/core';

import { Response } from '../../../models/api';
import { AttentionClass } from '../../../models/attention-class';
import { InspectionLog, InspectionReport } from '../../../models/inspection';
import {
  InspectionCollection,
  InspectionName,
} from '../../../models/map/inspection-item.model';
import {
  EXCLUDE_LOADER,
  HANDLED_ERRORS,
  HttpContexts,
} from '../../../shared/utils/http-context'; // TEMP: For migration
import { API } from '../api.const';
import { ApiService } from '../api.service';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InspectionService extends ApiService {
  endpoint = API.INSPECTION;

  private defectRowIndex$ = new BehaviorSubject(0);
  private imageCount$ = new BehaviorSubject(0);
  private repeatIndex$ = new BehaviorSubject(0);

  getAttentionClassList(
    filters: any,
    page: number,
    limit: number
  ): Observable<Response<AttentionClass[]>> {
    return this.get<Response<AttentionClass[]>>({
      metadata: {
        ...this.getHttpParams({
          ...filters,
          limit,
          page,
        }),
      },
      operation: 'getAttentionClassList',
      url: this.endpoint.getAttentionClassList(),
    });
  }

  getAttentionClassXlsReport(filters: any): Observable<any> {
    return this.get<Response>({
      metadata: { ...this.getHttpParams(filters), responseType: 'blob' },
      operation: 'getAttentionClassXlsReport',
      url: this.endpoint.getAttentionClassXlsReport(),
    });
  }

  getImageCount$(): Observable<number> {
    return this.imageCount$.asObservable();
  }

  getDefectRowIndex$(): Observable<number> {
    return this.defectRowIndex$.asObservable();
  }

  getInspection(
    inspectionId: string,
    httpContexts: HttpContexts = {} // TEMP: For migration
  ): Observable<Response<any>> {
    const { excludeLoader, handledErrors } = httpContexts; // TEMP: For migration
    const context = new HttpContext().set(EXCLUDE_LOADER, excludeLoader); // TEMP: For migration

    if (handledErrors != null) context.set(HANDLED_ERRORS, handledErrors); // TEMP: For migration

    return this.get<Response<any>>({
      metadata: { context }, // TEMP: For migration
      operation: 'getInspection',
      url: this.endpoint.getInspection(inspectionId),
    });
  }

  getInspectionLogList(
    selectedInspectionId: string,
    page: number,
    limit: number
  ): Observable<Response<InspectionLog[]>> {
    return this.get<Response<InspectionLog[]>>({
      metadata: {
        ...this.getHttpParams({
          limit,
          page,
        }),
      },
      operation: 'getInspectionLogList',
      url: this.endpoint.getInspectionLog(selectedInspectionId),
    });
  }

  getInspectionsList(
    filters: any,
    page: number,
    limit: number,
    httpContexts: HttpContexts = {} // TEMP: For migration
  ): Observable<Response<InspectionReport[]>> {
    const { excludeLoader } = httpContexts; // TEMP: For migration
    const context = new HttpContext().set(EXCLUDE_LOADER, excludeLoader); // TEMP: For migration

    return this.get<Response<InspectionReport[]>>({
      metadata: {
        ...this.getHttpParams({
          ...filters,
          limit,
          page,
        }),
        context: context, // TEMP: For migration
      },
      operation: 'getInspectionsList',
      url: this.endpoint.getInspections(),
    });
  }

  getIopInspectionsList(
    filters: any,
    page: number,
    limit: number
  ): Observable<Response<InspectionReport[]>> {
    return this.get<Response<InspectionReport[]>>({
      metadata: {
        ...this.getHttpParams({
          ...filters,
          limit,
          page,
        }),
      },
      operation: 'getIopInspectionsList',
      url: this.endpoint.getInspectionsForIop(),
    });
  }

  getMapInspections(
    isCustomer: '1' | '0'
  ): Observable<InspectionCollection<InspectionName>> {
    return this.get<Response>({
      metadata: { ...this.getHttpParams({ is_customer: isCustomer }) },
      operation: 'getMapInspections',
      url: this.endpoint.mapInspections(),
    }).pipe(map((file) => file.data));
  }

  getRepeatIndex$(): Observable<number> {
    return this.repeatIndex$.asObservable();
  }

  setDefectRowIndex$(defectRowIndex: number): void {
    this.defectRowIndex$.next(defectRowIndex);
  }

  setImageCount$(imageCount: number): void {
    this.imageCount$.next(imageCount);
  }

  setRepeatIndex$(repeatIndex: number): void {
    this.repeatIndex$.next(repeatIndex);
  }

  updateInspectionsStatus(
    inspectionIdsList: string[],
    status: string
  ): Observable<Response> {
    return this.put<Response>({
      body: {
        ids: inspectionIdsList,
        status,
      },
      operation: 'updateInspectionsStatus',
      url: this.endpoint.updateInspectionsStatus(),
    });
  }
}
