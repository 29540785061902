import { Injectable } from '@angular/core';

import { Response } from '../../../models/api';
import { EnabledTenant } from '../../../models/profile';
import { Tenant } from '../../../models/tenant';
import { API } from '../api.const';
import { ApiService } from '../api.service';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TenantService extends ApiService {
  endpoint = API.TENANT;

  getAllTenants(): Observable<Tenant[]> {
    return this.get<Response<Tenant[]>>({
      operation: 'getAllTenants',
      url: this.endpoint.getTenants(),
    }).pipe(
      map((res) =>
        // Filtering out RINA-TI tenant since it's only used for internal testing by RINA
        res.data?.filter(({ id }) => id !== 'rina-ti')
      )
    );
  }

  getEnabledTenants(): Observable<Response<EnabledTenant[]>> {
    return this.get<Response<EnabledTenant[]>>({
      operation: 'getEnabledTenants',
      url: this.endpoint.getEnabledTenants(),
    });
  }

  getTenant(tenant: string): Observable<Response<Tenant>> {
    return this.get<Response<Tenant>>({
      operation: 'getTenant',
      url: this.endpoint.getTenant(tenant),
    });
  }

  updateTenant(
    tenantId: string,
    formData: Tenant
  ): Observable<Response<Tenant>> {
    return this.put<Response<Tenant>>({
      body: formData,
      operation: 'updateTenant',
      url: this.endpoint.updateTenant(tenantId),
    });
  }
}
