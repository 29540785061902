import { globalEnvironment } from './global';
import { BASE_URL } from './commons/base-url';
import { TI_BSM_COMMON_ENV } from './commons/bms';
import { MODULES } from './module.config';

export const environment = {
  ...globalEnvironment,
  ...BASE_URL,
  ...TI_BSM_COMMON_ENV,
  routes: [
    MODULES['about-module'].route,
    MODULES['attention-class-module'].route,
    MODULES['configuration-module'].route,
    MODULES['home-module'].route,
    MODULES['import-inspections-module'].route,
    MODULES['inspections-module'].route,
    MODULES['iop-request-module'].route,
    MODULES['map-inspection-module'].route,
    MODULES['migration-module'].route, // TEMP: For migration
    MODULES['periodic-reporting-module'].route,
    MODULES['plan-inspections-module'].route,
  ],
  api: {
    ...globalEnvironment.api,
  },
  auth: {
    ...globalEnvironment.auth,
    bearerExcludedUrls: [
      ...globalEnvironment.auth.bearerExcludedUrls,
      'https://bmsfocstorageaccount.blob.core.windows.net/inspections',
    ],
  },
  devMode: {
    // INFO: When enabled, will disable defect template validations and allow to save defect template without filling all fields (default value = false)
    disableDefectsValidation: false,
    // INFO: When enabled, will load the local input template (default value = false)
    loadLocalInputTemplate: false,
    // INFO: When enabled, will enable the debug panel (default value = false)
    showDebugPanel: false,
  }, // TEMP: For development
};
