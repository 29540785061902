export const ComponentMaterialMappingObj = {
  // INFO: Spalle
  Spalle: {
    element_type_id: 2,
    element_code: 'S',
    component: [
      // INFO: Spalle > Elevazione
      {
        component_code: 'E',
        component_name: 'Elevazione',
        component_type_id: 3,
        label: 'Elevazione',
        value: 'Elevazione',
        materials: [
          {
            // component_type: 'Spalle',
            component_type: 'Elevazione',
            label: 'Calcestruzzo',
            value: 'Calcestruzzo',
          },
          {
            // component_type: 'Spalle',
            component_type: 'Elevazione',
            label: 'Muratura',
            value: 'Muratura',
          },
        ],
      },
      // INFO: Spalle > Paraghiaia
      {
        component_code: 'P',
        component_name: 'Paraghiaia',
        component_type_id: 5,
        label: 'Paraghiaia',
        value: 'Paraghiaia',
        materials: [
          {
            // component_type: 'Spalle',
            component_type: 'Paraghiaia',
            label: 'Calcestruzzo',
            value: 'Calcestruzzo',
          },
          {
            // component_type: 'Spalle',
            component_type: 'Paraghiaia',
            label: 'Muratura',
            value: 'Muratura',
          },
        ],
      },
      // INFO: Spalle > Contrafforte
      {
        component_code: 'C',
        component_name: 'Contrafforte',
        component_type_id: 2,
        label: 'Contrafforte',
        value: 'Contrafforte',
        materials: [
          {
            // component_type: 'Spalle',
            component_type: 'Contrafforte',
            label: 'Calcestruzzo',
            value: 'Calcestruzzo',
          },
          {
            // component_type: 'Spalle',
            component_type: 'Contrafforte',
            label: 'Muratura',
            value: 'Muratura',
          },
        ],
      },
      // INFO: Spalle > Trave Cuscino
      {
        component_code: 'T',
        component_name: 'Trave Cuscino',
        component_type_id: 4,
        label: 'Trave Cuscino',
        value: 'Trave Cuscino',
        materials: [
          {
            // component_type: 'Spalle',
            component_type: 'Trave Cuscino',
            label: 'Calcestruzzo',
            value: 'Calcestruzzo',
          },
          {
            // component_type: 'Spalle',
            component_type: 'Trave Cuscino',
            label: 'Muratura',
            value: 'Muratura',
          },
        ],
      },
      // INFO: Spalle > Muro Andatore
      {
        component_code: 'MA',
        component_name: 'Muro Andatore',
        component_type_id: 1,
        label: 'Muro Andatore',
        value: 'Muro Andatore',
        materials: [
          {
            // component_type: 'Spalle',
            component_type: 'Muro Andatore',
            label: 'Calcestruzzo',
            value: 'Calcestruzzo',
          },
          {
            // component_type: 'Spalle',
            component_type: 'Muro Andatore',
            label: 'Muratura',
            value: 'Muratura',
          },
        ],
      },
    ],
  },
  // INFO: Pile
  Pile: {
    element_type_id: 6,
    element_code: 'P',
    component: [
      // INFO: Pile > Elevazione
      {
        component_code: 'E',
        component_name: 'Elevazione',
        component_type_id: 1,
        label: 'Elevazione',
        value: 'Elevazione',
        materials: [
          {
            // component_type: 'Pile',
            component_type: 'Elevazione',
            label: 'Calcestruzzo',
            value: 'Calcestruzzo',
          },
          {
            // component_type: 'Pile',
            component_type: 'Elevazione',
            label: 'Metallo o Acciaio',
            value: 'Metallo o Acciaio',
          },
          {
            // component_type: 'Pile',
            component_type: 'Elevazione',
            label: 'Muratura',
            value: 'Muratura',
          },
        ],
      },
      // INFO: Pile > Pulvino
      {
        component_code: 'P',
        component_name: 'Pulvino',
        component_type_id: 2,
        label: 'Pulvino',
        value: 'Pulvino',
        materials: [
          {
            // component_type: 'Pile',
            component_type: 'Pulvino',
            label: 'Calcestruzzo',
            value: 'Calcestruzzo',
          },
          {
            // component_type: 'Pile',
            component_type: 'Pulvino',
            label: 'Metallo o Acciaio',
            value: 'Metallo o Acciaio',
          },
          {
            // component_type: 'Pile',
            component_type: 'Pulvino',
            label: 'Muratura',
            value: 'Muratura',
          },
        ],
      },
      // INFO: Pile > Interconnesioni Trasversali (compresi diaframmi)
      {
        component_code: 'I',
        component_name: 'Interconnesioni Trasversali (compresi diaframmi)',
        component_type_id: 3,
        label: 'Interconnesioni Trasversali (compresi diaframmi)',
        value: 'Interconnesioni Trasversali (compresi diaframmi)',
        materials: [
          {
            // component_type: 'Pile',
            component_type: 'Interconnesioni Trasversali (compresi diaframmi)',
            label: 'Calcestruzzo',
            value: 'Calcestruzzo',
          },
          {
            // component_type: 'Pile',
            component_type: 'Interconnesioni Trasversali (compresi diaframmi)',
            label: 'Metallo o Acciaio',
            value: 'Metallo o Acciaio',
          },
          {
            // component_type: 'Pile',
            component_type: 'Interconnesioni Trasversali (compresi diaframmi)',
            label: 'Muratura',
            value: 'Muratura',
          },
        ],
      },
      // INFO: Pile > Interconnesioni Longitudinali
      {
        component_code: 'N',
        component_name: 'Interconnesioni Longitudinali',
        component_type_id: 4,
        label: 'Interconnesioni Longitudinali',
        value: 'Interconnesioni Longitudinali',
        materials: [
          {
            // component_type: 'Pile',
            component_type: 'Interconnesioni Longitudinali',
            label: 'Calcestruzzo',
            value: 'Calcestruzzo',
          },
          {
            // component_type: 'Pile',
            component_type: 'Interconnesioni Longitudinali',
            label: 'Metallo o Acciaio',
            value: 'Metallo o Acciaio',
          },
          {
            // component_type: 'Pile',
            component_type: 'Interconnesioni Longitudinali',
            label: 'Muratura',
            value: 'Muratura',
          },
        ],
      },
    ],
  },
  // INFO: Archi
  Archi: {
    element_type_id: 5,
    element_code: 'A',
    component: [
      // INFO: Archi > Struttura Principale
      {
        component_code: 'P',
        component_name: 'Struttura Principale',
        component_type_id: 1,
        label: 'Struttura Principale',
        value: 'Struttura Principale',
        materials: [
          {
            // component_type: 'Archi',
            component_type: 'Struttura Principale',
            label: 'Calcestruzzo',
            value: 'Calcestruzzo',
          },
          {
            // component_type: 'Archi',
            component_type: 'Struttura Principale',
            label: 'Legno',
            value: 'Legno',
          },
          {
            // component_type: 'Archi',
            component_type: 'Struttura Principale',
            label: 'Metallo o Acciaio',
            value: 'Metallo o Acciaio',
          },
          {
            // component_type: 'Archi',
            component_type: 'Struttura Principale',
            label: 'Muratura',
            value: 'Muratura',
          },
        ],
      },
      // INFO: Archi > Soletta
      {
        component_code: 'S',
        component_name: 'Soletta',
        component_type_id: 6,
        label: 'Soletta',
        value: 'Soletta',
        materials: [
          {
            // component_type: 'Archi',
            component_type: 'Soletta',
            label: 'Calcestruzzo',
            value: 'Calcestruzzo',
          },
          {
            // component_type: 'Archi',
            component_type: 'Soletta',
            label: 'Legno',
            value: 'Legno',
          },
          {
            // component_type: 'Archi',
            component_type: 'Soletta',
            label: 'Metallo o Acciaio',
            value: 'Metallo o Acciaio',
          },
          {
            // component_type: 'Archi',
            component_type: 'Soletta',
            label: 'Muratura',
            value: 'Muratura',
          },
        ],
      },
      // INFO: Archi > Interconnesioni Trasversali (compresi diaframmi)
      {
        component_code: 'I',
        component_name: 'Interconnesioni Trasversali (compresi diaframmi)',
        component_type_id: 3,
        label: 'Interconnesioni Trasversali (compresi diaframmi)',
        value: 'Interconnesioni Trasversali (compresi diaframmi)',
        materials: [
          {
            // component_type: 'Archi',
            component_type: 'Interconnesioni Trasversali (compresi diaframmi)',
            label: 'Calcestruzzo',
            value: 'Calcestruzzo',
          },
          {
            // component_type: 'Archi',
            component_type: 'Interconnesioni Trasversali (compresi diaframmi)',
            label: 'Legno',
            value: 'Legno',
          },
          {
            // component_type: 'Archi',
            component_type: 'Interconnesioni Trasversali (compresi diaframmi)',
            label: 'Metallo o Acciaio',
            value: 'Metallo o Acciaio',
          },
          {
            // component_type: 'Archi',
            component_type: 'Interconnesioni Trasversali (compresi diaframmi)',
            label: 'Muratura',
            value: 'Muratura',
          },
        ],
      },
      // INFO: Archi > Interconnesioni Longitudinali
      {
        component_code: 'N',
        component_name: 'Interconnesioni Longitudinali',
        component_type_id: 4,
        label: 'Interconnesioni Longitudinali',
        value: 'Interconnesioni Longitudinali',
        materials: [
          {
            // component_type: 'Archi',
            component_type: 'Interconnesioni Longitudinali',
            label: 'Calcestruzzo',
            value: 'Calcestruzzo',
          },
          {
            // component_type: 'Archi',
            component_type: 'Interconnesioni Longitudinali',
            label: 'Legno',
            value: 'Legno',
          },
          {
            // component_type: 'Archi',
            component_type: 'Interconnesioni Longitudinali',
            label: 'Metallo o Acciaio',
            value: 'Metallo o Acciaio',
          },
          {
            // component_type: 'Archi',
            component_type: 'Interconnesioni Longitudinali',
            label: 'Muratura',
            value: 'Muratura',
          },
        ],
      },
      // INFO: Archi > Timpano
      {
        component_code: 'T',
        component_name: 'Timpano',
        component_type_id: 2,
        label: 'Timpano',
        value: 'Timpano',
        materials: [
          {
            // component_type: 'Archi',
            component_type: 'Timpano',
            label: 'Calcestruzzo',
            value: 'Calcestruzzo',
          },
          {
            // component_type: 'Archi',
            component_type: 'Timpano',
            label: 'Legno',
            value: 'Legno',
          },
          {
            // component_type: 'Archi',
            component_type: 'Timpano',
            label: 'Metallo o Acciaio',
            value: 'Metallo o Acciaio',
          },
          {
            // component_type: 'Archi',
            component_type: 'Timpano',
            label: 'Muratura',
            value: 'Muratura',
          },
        ],
      },
      // INFO: Archi > Interconnesioni Longitudinali Tra Pile
      {
        component_code: 'E',
        component_name: 'Interconnesioni Longitudinali Tra Pile',
        component_type_id: 5,
        label: 'Interconnesioni Longitudinali Tra Pile',
        value: 'Interconnesioni Longitudinali Tra Pile',
        materials: [
          {
            // component_type: 'Archi',
            component_type: 'Interconnesioni Longitudinali Tra Pile',
            label: 'Calcestruzzo',
            value: 'Calcestruzzo',
          },
          {
            // component_type: 'Archi',
            component_type: 'Interconnesioni Longitudinali Tra Pile',
            label: 'Legno',
            value: 'Legno',
          },
          {
            // component_type: 'Archi',
            component_type: 'Interconnesioni Longitudinali Tra Pile',
            label: 'Metallo o Acciaio',
            value: 'Metallo o Acciaio',
          },
          {
            // component_type: 'Archi',
            component_type: 'Interconnesioni Longitudinali Tra Pile',
            label: 'Muratura',
            value: 'Muratura',
          },
        ],
      },
    ],
  },
  // INFO: Impalcati
  Impalcati: {
    element_type_id: 4,
    element_code: 'I',
    component: [
      // INFO: Impalcati > Solettone
      {
        component_code: 'O',
        component_name: 'Solettone',
        component_type_id: 12,
        label: 'Solettone',
        value: 'Solettone',
        materials: [
          {
            // component_type: 'Travi',
            component_type: 'Solettone',
            label: 'Calcestruzzo Armato',
            value: 'C.a.',
          },
          {
            // component_type: 'Travi',
            component_type: 'Solettone',
            label: 'Calcestruzzo Armato Precompresso',
            value: 'C.A.P.',
          },
          {
            // component_type: 'Travi',
            component_type: 'Solettone',
            label: 'Legno',
            value: 'Legno',
          },
          {
            // component_type: 'Travi',
            component_type: 'Solettone',
            label: 'Metallo o Acciaio',
            value: 'Metallo o Acciaio',
          },
        ],
      },
      // INFO: Impalcati > Travi
      {
        component_code: 'T',
        component_name: 'Travi',
        component_type_id: 1,
        label: 'Travi',
        value: 'Travi',
        materials: [
          {
            component_type: 'Travi',
            label: 'Calcestruzzo Armato',
            value: 'C.a.',
          },
          {
            component_type: 'Travi',
            label: 'Calcestruzzo Armato Precompresso',
            value: 'C.A.P.',
          },
          {
            component_type: 'Travi',
            label: 'Legno',
            value: 'Legno',
          },
          {
            component_type: 'Travi',
            label: 'Metallo o Acciaio',
            value: 'Metallo o Acciaio',
          },
        ],
      },
      // INFO: Impalcati > Traversi Testata
      {
        component_code: 'TT',
        component_name: 'Traversi Testata',
        component_type_id: 2,
        label: 'Traversi Testata',
        value: 'Traversi Testata',
        materials: [
          {
            // component_type: 'Travi',
            component_type: 'Traversi Testata',
            label: 'Calcestruzzo Armato',
            value: 'C.a.',
          },
          {
            // component_type: 'Travi',
            component_type: 'Traversi Testata',
            label: 'Calcestruzzo Armato Precompresso',
            value: 'C.A.P.',
          },
          {
            // component_type: 'Travi',
            component_type: 'Traversi Testata',
            label: 'Legno',
            value: 'Legno',
          },
          {
            // component_type: 'Travi',
            component_type: 'Traversi Testata',
            label: 'Metallo o Acciaio',
            value: 'Metallo o Acciaio',
          },
        ],
      },
      // INFO: Impalcati > Anime
      {
        component_code: 'A',
        component_name: 'Anime',
        component_type_id: 10,
        label: 'Anime',
        value: 'Anime',
        materials: [
          {
            // component_type: 'Travi',
            component_type: 'Anime',
            label: 'Acciaio',
            value: 'Metallo o Acciaio',
          },
          {
            // component_type: 'Soletta',
            component_type: 'Anime',
            label: 'C.a.',
            value: 'C.a.',
          },
          {
            // component_type: 'Travi',
            component_type: 'Anime',
            label: 'C.A.P.',
            value: 'C.A.P.',
          },
          {
            // component_type: 'Soletta',
            component_type: 'Anime',
            label: 'Legno',
            value: 'Legno',
          },
        ],
      },
      // INFO: Impalcati > Puntone
      {
        component_code: 'P',
        component_name: 'Puntone',
        component_type_id: 6,
        label: 'Puntone',
        value: 'Puntone',
        materials: [
          {
            // component_type: 'Travi',
            component_type: 'Puntone',
            label: 'Calcestruzzo Armato',
            value: 'C.a.',
          },
          {
            // component_type: 'Travi',
            component_type: 'Puntone',
            label: 'Calcestruzzo Armato Precompresso',
            value: 'C.A.P.',
          },
          {
            // component_type: 'Travi',
            component_type: 'Puntone',
            label: 'Legno',
            value: 'Legno',
          },
          {
            // component_type: 'Travi',
            component_type: 'Puntone',
            label: 'Metallo o Acciaio',
            value: 'Metallo o Acciaio',
          },
        ],
      },
      // INFO: Impalcati > Soletta
      {
        component_code: 'S',
        component_name: 'Soletta',
        component_type_id: 11,
        label: 'Soletta',
        value: 'Soletta',
        materials: [
          {
            // component_type: 'Travi',
            component_type: 'Soletta',
            label: 'Acciaio',
            value: 'Metallo o Acciaio',
          },
          {
            component_type: 'Soletta',
            label: 'C.a.',
            value: 'C.a.',
          },
          {
            // component_type: 'Travi',
            component_type: 'Soletta',
            label: 'C.A.P.',
            value: 'C.A.P.',
          },
          {
            component_type: 'Soletta',
            label: 'Legno',
            value: 'Legno',
          },
        ],
      },
      // INFO: Impalcati > Traversi Intermedi
      {
        component_code: 'TR',
        component_name: 'Traversi Intermedi',
        component_type_id: 3,
        label: 'Traversi Intermedi',
        value: 'Traversi Intermedi',
        materials: [
          {
            // component_type: 'Travi',
            component_type: 'Traversi Intermedi',
            label: 'Calcestruzzo Armato',
            value: 'C.a.',
          },
          {
            // component_type: 'Travi',
            component_type: 'Traversi Intermedi',
            label: 'Calcestruzzo Armato Precompresso',
            value: 'C.A.P.',
          },
          {
            // component_type: 'Travi',
            component_type: 'Traversi Intermedi',
            label: 'Legno',
            value: 'Legno',
          },
          {
            // component_type: 'Travi',
            component_type: 'Traversi Intermedi',
            label: 'Metallo o Acciaio',
            value: 'Metallo o Acciaio',
          },
        ],
      },
      // INFO: Impalcati > Controsoletta
      {
        component_code: 'C',
        component_name: 'Controsoletta',
        component_type_id: 5,
        label: 'Controsoletta',
        value: 'Controsoletta',
        materials: [
          {
            // component_type: 'Travi',
            component_type: 'Controsoletta',
            label: 'Acciaio',
            value: 'Metallo o Acciaio',
          },
          {
            // component_type: 'Soletta',
            component_type: 'Controsoletta',
            label: 'C.a.',
            value: 'C.a.',
          },
          {
            // component_type: 'Travi',
            component_type: 'Controsoletta',
            label: 'C.A.P.',
            value: 'C.A.P.',
          },
          {
            // component_type: 'Soletta',
            component_type: 'Controsoletta',
            label: 'Legno',
            value: 'Legno',
          },
        ],
      },
      // INFO: Impalcati > Sbalzi Trasversali
      {
        component_code: 'B',
        component_name: 'Sbalzi Trasversali',
        component_type_id: 7,
        label: 'Sbalzi Trasversali',
        value: 'Sbalzi Trasversali',
        materials: [
          {
            // component_type: 'Soletta',
            component_type: 'Sbalzi Trasversali',
            label: 'C.a.',
            value: 'C.a.',
          },
          {
            // component_type: 'Soletta',
            component_type: 'Sbalzi Trasversali',
            label: 'Legno',
            value: 'Legno',
          },
        ],
      },
      // INFO: Impalcati > Sbalzi Longitudinali
      {
        component_code: 'L',
        component_name: 'Sbalzi Longitudinali',
        component_type_id: 8,
        label: 'Sbalzi Longitudinali',
        value: 'Sbalzi Longitudinali',
        materials: [
          {
            // component_type: 'Soletta',
            component_type: 'Sbalzi Longitudinali',
            label: 'C.a.',
            value: 'C.a.',
          },
          {
            // component_type: 'Soletta',
            component_type: 'Sbalzi Longitudinali',
            label: 'Legno',
            value: 'Legno',
          },
        ],
      },
      // INFO: Impalcati > Diaframmi
      {
        component_code: 'D',
        component_name: 'Diaframmi',
        component_type_id: 9,
        label: 'Diaframmi',
        value: 'Diaframmi',
        materials: [
          {
            // component_type: 'Travi',
            component_type: 'Diaframmi',
            label: 'Calcestruzzo Armato',
            value: 'C.a.',
          },
          {
            // component_type: 'Travi',
            component_type: 'Diaframmi',
            label: 'Calcestruzzo Armato Precompresso',
            value: 'C.A.P.',
          },
          {
            // component_type: 'Travi',
            component_type: 'Diaframmi',
            label: 'Legno',
            value: 'Legno',
          },
          {
            // component_type: 'Travi',
            component_type: 'Diaframmi',
            label: 'Metallo o Acciaio',
            value: 'Metallo o Acciaio',
          },
        ],
      },
      // INFO: Impalcati > Interconnesioni Longitunali
      {
        component_code: 'I',
        component_name: 'Interconnesioni Longitunali',
        component_type_id: 4,
        label: 'Interconnesioni Longitunali',
        value: 'Interconnesioni Longitunali',
        materials: [
          {
            // component_type: 'Travi',
            component_type: 'Interconnesioni Longitunali',
            label: 'Calcestruzzo Armato',
            value: 'C.a.',
          },
          {
            // component_type: 'Travi',
            component_type: 'Interconnesioni Longitunali',
            label: 'Calcestruzzo Armato Precompresso',
            value: 'C.A.P.',
          },
          {
            // component_type: 'Travi',
            component_type: 'Interconnesioni Longitunali',
            label: 'Legno',
            value: 'Legno',
          },
          {
            // component_type: 'Travi',
            component_type: 'Interconnesioni Longitunali',
            label: 'Metallo o Acciaio',
            value: 'Metallo o Acciaio',
          },
        ],
      },
    ],
  },
  // INFO: File di Appoggi
  'File di Appoggi': {
    element_type_id: 7,
    element_code: 'F',
    component: [
      // INFO: File di Appoggi > Dispositivi Di Appoggio
      {
        component_code: 'A',
        component_name: 'Dispositivi Di Appoggio',
        component_type_id: 1,
        // default_component_type: 'Appoggi',
        default_component_type: 'Dispositivi Di Appoggio',
        label: 'Dispositivi Di Appoggio',
        value: 'Dispositivi Di Appoggio',
        materials: [],
      },
      // INFO: File di Appoggi > Baggiolo
      {
        component_code: 'B',
        component_name: 'Baggiolo',
        component_type_id: 0,
        label: 'Baggiolo',
        value: 'Baggiolo',
        materials: [
          {
            // component_type: 'Pile',
            component_type: 'Baggiolo',
            label: 'Calcestruzzo',
            value: 'Calcestruzzo',
          },
          {
            // component_type: 'Pile',
            component_type: 'Baggiolo',
            label: 'Metallo o Acciaio',
            value: 'Metallo o Acciaio',
          },
          {
            // component_type: 'Pile',
            component_type: 'Baggiolo',
            label: 'Muratura',
            value: 'Muratura',
          },
        ],
      },
      // INFO: File di Appoggi > Cerniere Di Taglio
      {
        component_code: 'C',
        component_name: 'Cerniere Di Taglio',
        component_type_id: 2,
        label: 'Cerniere Di Taglio',
        value: 'Cerniere Di Taglio',
        materials: [
          {
            // component_type: 'Travi',
            component_type: 'Cerniere Di Taglio',
            label: 'Acciaio',
            value: 'Metallo o Acciaio',
          },
        ],
      },
    ],
  },
  // INFO: Giunti
  Giunti: {
    element_type_id: 9,
    element_code: 'G',
    component: [
      // INFO: Giunti > Portale O Monaco
      {
        component_code: 'A',
        component_name: 'Portale O Monaco',
        component_type_id: 4,
        // default_component_type: 'Giunti',
        default_component_type: 'Portale O Monaco',
        label: 'Portale O Monaco',
        value: 'Portale O Monaco',
        materials: [],
      },
      // INFO: Giunti > Soletta Intermedia
      {
        component_code: 'S',
        component_name: 'Soletta Intermedia',
        component_type_id: 3,
        // default_component_type: 'Giunti',
        default_component_type: 'Soletta Intermedia',
        label: 'Soletta Intermedia',
        value: 'Soletta Intermedia',
        materials: [],
      },
      // INFO: Giunti > Dispositivi Di Giunto
      {
        component_code: 'P',
        component_name: 'Dispositivi Di Giunto',
        component_type_id: 1,
        // default_component_type: 'Giunti',
        default_component_type: 'Dispositivi Di Giunto',
        label: 'Dispositivi Di Giunto',
        value: 'Dispositivi Di Giunto',
        materials: [],
      },
      // INFO: Giunti > Massetto
      {
        component_code: 'T',
        component_name: 'Massetto',
        component_type_id: 2,
        // default_component_type: 'Giunti',
        default_component_type: 'Massetto',
        label: 'Massetto',
        value: 'Massetto',
        materials: [],
      },
    ],
  },
  // INFO: Apparecchi Sismici
  'Apparecchi Sismici': {
    element_type_id: 8,
    element_code: 'S',
    component: [
      // INFO: Apparecchi Sismici > Dispositivi
      {
        component_code: 'M',
        component_name: 'Dispositivi',
        component_type_id: 1,
        label: 'Dispositivi',
        value: 'Dispositivi',
        materials: [
          {
            // component_type: 'Travi',
            component_type: 'Dispositivi',
            label: 'Acciaio',
            value: 'Metallo o Acciaio',
          },
        ],
      },
      // INFO: Apparecchi Sismici > Ritegni Sismici
      {
        component_code: 'R',
        component_name: 'Ritegni Sismici',
        component_type_id: 2,
        label: 'Ritegni Sismici',
        value: 'Ritegni Sismici',
        materials: [
          {
            // component_type: 'Travi',
            component_type: 'Ritegni Sismici',
            label: 'Acciaio',
            value: 'Metallo o Acciaio',
          },
        ],
      },
    ],
  },
};
