import { Injectable } from '@angular/core';

import { Response } from '../../../models/api';
import { UserCreate, UserDetails } from '../../../models/profile';
import { API } from '../api.const';
import { ApiService } from '../api.service';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends ApiService {
  endpoint = API.PROFILE;

  createUser(body: UserCreate): Observable<Response<UserDetails>> {
    return this.post<Response<UserDetails>>({
      body,
      operation: 'addUser',
      url: this.endpoint.createUser(),
    });
  }

  getAllUsers(): Observable<Response<UserDetails[]>> {
    return this.get<Response<UserDetails[]>>({
      operation: 'getAllUsers',
      url: this.endpoint.getUsers(),
    });
  }

  getUsers(page: number, limit: number): Observable<Response<UserDetails[]>> {
    return this.get<Response<UserDetails[]>>({
      metadata: {
        ...this.getHttpParams({
          page,
          limit,
        }),
      },
      operation: 'getUsers',
      url: this.endpoint.getUsers(),
    });
  }

  updateUser(formData: any, userId: string): Observable<Response<UserDetails>> {
    return this.put<Response<UserDetails>>({
      body: formData,
      operation: 'updateUser',
      url: this.endpoint.updateUser(userId),
    });
  }
}
