export enum IdbTable {
  bridgeDefectFiles = 'bridgeDefectFiles',
  bridgeDefectsMaster = 'bridgeDefectsMaster',
  inspectionDefectFiles = 'inspectionDefectFiles',
  inspectionFilesList = 'inspectionFilesList',
  inspectionModel = 'inspectionModel',
  inspectionTemplateDefectsList = 'inspectionTemplateDefectsList', // TEMP: For migration
  inspectionTemplateDetail = 'inspectionTemplateDetail',
  inspectionTemplateMaster = 'inspectionTemplateMaster', // TEMP: For migration
  inspectionTemplatesMaster = 'inspectionTemplatesMaster',
  tenantMaster = 'tenantMaster', // TEMP: For migration
  tenantsMaster = 'tenantsMaster',
  tunnelDefectFiles = 'tunnelDefectFiles',
  tunnelDefectsList = 'tunnelDefectsList', // TEMP: For migration
  tunnelDefectsMaster = 'tunnelDefectsMaster',
}
