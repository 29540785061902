<!-- Close Button -->
<button
  class="close-icon"
  tabindex="-1"
  type="button"
  mat-dialog-close
  mat-icon-button
>
  <mat-icon>close</mat-icon>
</button>

<!-- Title -->
<h5 mat-dialog-title>
  {{ "start_import_dialog.import_started" | translate }}
</h5>

<!-- Content -->
<mat-dialog-content>
  <div class="dialog-form">
    <p>
      {{ "start_import_dialog.import_description" | translate }}
    </p>
  </div>
</mat-dialog-content>
