import { Component } from '@angular/core';

import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';

export interface ICustomHeaderParams {
  subTitle: string;
  title: string;
}

@Component({
  selector: 'cube-header-renderer',
  template: `
    <div>
      <div class="customHeaderLabel label">
        <span>{{ params.title }}</span>
        <mat-divider class="divider"></mat-divider>
        <span>{{ params.subTitle }}</span>
      </div>
      <div
        *ngIf="params.enableSorting"
        (click)="onSortRequested('asc', $event)"
        [ngClass]="ascSort"
        class="customSortDownLabel"
      >
        <i class="fa fa-long-arrow-alt-down"></i>
      </div>
      <div
        *ngIf="params.enableSorting"
        (click)="onSortRequested('desc', $event)"
        [ngClass]="descSort"
        class="customSortUpLabel"
      >
        <i class="fa fa-long-arrow-alt-up"></i>
      </div>
      <div
        *ngIf="params.enableSorting"
        (click)="onSortRequested('', $event)"
        [ngClass]="noSort"
        class="customSortRemoveLabel"
      >
        <i class="fa fa-times"></i>
      </div>
    </div>
  `,
  styles: [
    `
      .customHeaderLabel,
      .customSortDownLabel,
      .customSortUpLabel,
      .customSortRemoveLabel {
        float: center;
        margin: 0 0 0 0;
      }

      .customSortUpLabel {
        margin: 0;
      }

      .customSortRemoveLabel {
        font-size: 11px;
      }

      .active {
        color: cornflowerblue;
      }

      .label {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .divider {
        width: 100%;
      }
    `,
  ],
})
export class HeaderRendererComponent implements IHeaderAngularComp {
  ascSort = 'inactive';
  descSort = 'inactive';
  noSort = 'inactive';
  params!: IHeaderParams & ICustomHeaderParams;

  agInit(params: IHeaderParams & ICustomHeaderParams): void {
    this.params = params;
    params.column.addEventListener(
      'sortChanged',
      this.onSortChanged.bind(this)
    );
    this.onSortChanged();
  }

  onSortChanged() {
    this.ascSort = this.descSort = this.noSort = 'inactive';
    if (this.params.column.isSortAscending()) {
      this.ascSort = 'active';
    } else if (this.params.column.isSortDescending()) {
      this.descSort = 'active';
    } else {
      this.noSort = 'active';
    }
  }

  onSortRequested(order: 'asc' | 'desc' | null, event: any) {
    this.params.setSort(order, event.shiftKey);
  }

  refresh(params: IHeaderParams<any>): boolean {
    return false;
  }
}
