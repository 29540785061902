<!-- Close Button -->
<button
  class="close-icon"
  tabindex="-1"
  type="button"
  mat-dialog-close
  mat-icon-button
>
  <mat-icon>close</mat-icon>
</button>

<!-- Title -->
<h5 mat-dialog-title>The following planned inspections have been changed:</h5>

<!-- Content -->
<mat-dialog-content>
  <!-- Planned Inspections List -->
  <ul>
    <li *ngFor="let plannedInspection of plannedInspections">
      {{ plannedInspection.name }}
    </li>
  </ul>

  <!-- Ok Button -->
  <div class="ok-button">
    <!-- OK -->
    <button type="submit" mat-dialog-close mat-flat-button color="primary">
      OK
    </button>
  </div>
</mat-dialog-content>
