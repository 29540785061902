import { Injectable } from '@angular/core';

import { BridgeConstants } from '../../../assets/structure-config';
import { DefectSearchParams } from '../../models/inspection';
import { IdbTable } from '../../shared/configs/idb-table.config';
import { StructureType } from '../../shared/configs/structure-type.config';

import Dexie, { liveQuery, Observable, PromiseExtended } from 'dexie';

export interface WhereConditions {
  basePathName?: string;
  campataNo?: number;
  casoType?: number;
  concioNo?: number;
  defectElementListName?: string;
  defectElementRowIndex?: number;
  fotoName?: string;
  imageType?: number;
  keyStructure?: string[];
  modelHash: string;
  sezioneLongitudinale?: number;
}

@Injectable({
  providedIn: 'root',
})
export class IdbService extends Dexie {
  constructor() {
    const databaseName: string = 'DexieDB'; // TODO rename DB to BMS-APP-OFFLINE-DB
    super(databaseName);
  }

  addDefectsToBridgeDefectsMaster(defectsList: any[]): void {
    // Consolidating the defects list based on component and material combination
    const consolidatedDefectsList = [];
    defectsList.forEach((defect) => {
      // Finding if defect template with same component and material is already added to consolidated defect template list
      const consolidatedDefectsItem = consolidatedDefectsList.find(
        ({ element }) => element === defect.element
      );

      if (consolidatedDefectsItem) {
        // If consolidatedDefectsItem is already added to list, then adding defect to defects list of consolidatedDefectsItem
        consolidatedDefectsItem.defects.push(defect);
      } else {
        // If consolidatedDefectsItem is not present in list, then adding new consolidatedDefectsItem
        consolidatedDefectsList.push({
          defects: [].concat(defect),
          element: defect.element,
        });
      }
    });

    // Adding to indexedDB
    this.table(IdbTable.bridgeDefectsMaster)
      .clear()
      .finally(() => {
        this.table(IdbTable.bridgeDefectsMaster)
          .bulkAdd(consolidatedDefectsList)
          .then((lastKey) => lastKey)
          .catch(Dexie.BulkError, () => {
            console.error('Error');
          });
      });
  }

  addDefectsToTunnelDefectsMaster(defectsList: any[]): void {
    // Consolidating the defects list based on defect type
    const consolidatedDefectsList = [];
    defectsList.forEach((defect) => {
      // Finding if defect with same defect type is already added to consolidated defect template list
      const consolidatedDefectsItem = consolidatedDefectsList.find(
        ({ defectType }) => defect.defectType === defectType
      );

      if (consolidatedDefectsItem) {
        // If consolidatedDefectsItem is already added to list, then adding defect to defects list of consolidatedDefectsItem
        consolidatedDefectsItem.defects.push(defect);
      } else {
        // If consolidatedDefectsItem is not present in list, then adding new consolidatedDefectsItem
        consolidatedDefectsList.push({
          defects: [].concat(defect),
          defectType: defect.defectType,
        });
      }
    });

    // Adding to indexedDB
    this.table(IdbTable.tunnelDefectsMaster)
      .clear()
      .finally(() => {
        this.table(IdbTable.tunnelDefectsMaster)
          .bulkAdd(consolidatedDefectsList)
          .then((lastKey) => lastKey)
          .catch(Dexie.BulkError, () => {
            console.error('Error');
          });
      });
  }

  addInspectionModel(inspectionModel: any): PromiseExtended {
    return this.table(IdbTable.inspectionModel)
      .add({
        model: JSON.stringify(inspectionModel),
        modelHash: inspectionModel.modelHash,
      })
      .then((lastKey) => lastKey)
      .catch(Dexie.BulkError, () => {
        console.error('Error');
      });
  }

  addInspectionTemplateDetail(templateId: string, template: any): void {
    this.table(IdbTable.inspectionTemplateDetail)
      .get({ templateId })
      .then((res) => {
        if (res) return;

        // If template does not exist, then add template
        this.table(IdbTable.inspectionTemplateDetail)
          .add({
            template,
            templateId,
          })
          .then((lastKey) => lastKey)
          .catch(Dexie.BulkError, () => {
            console.error('Error');
          });
      });
  }

  async addTemplateMaster(templatesList: any[]): Promise<void> {
    await this.table(IdbTable.inspectionTemplateMaster).clear();

    this.table(IdbTable.inspectionTemplateMaster)
      .bulkAdd(templatesList)
      .then((lastKey) => lastKey)
      .catch(Dexie.BulkError, () => {
        console.error('Error');
      });
  }

  addTenantMaster(tenantsList: any[]): void {
    const tenantMasterList = tenantsList.map((tenant, index) => ({
      id: index,
      tenant,
    }));

    this.table(IdbTable.tenantMaster).clear();
    this.table(IdbTable.tenantMaster)
      .bulkAdd(tenantMasterList)
      .then((lastKey) => lastKey)
      .catch(Dexie.BulkError, () => {
        console.error('Error');
      });
  }

  addUploadedFiles(
    filesList: any[],
    structureType?: StructureType
  ): PromiseExtended {
    const tableName = this.getUploadedFileTableName(structureType);

    return this.table(tableName)
      .bulkAdd(filesList, { allKeys: true })
      .then((lastKey) => lastKey);
  }

  deleteAllUploadedFilesUnderInspection(
    modelHash: string,
    structureType: StructureType
  ): void {
    // Deleting files
    this.table(IdbTable.inspectionFilesList).where({ modelHash }).delete();

    // Deleting defect files
    const tableName = this.getUploadedFileTableName(structureType);
    this.table(tableName).where({ modelHash }).delete();
  }

  deleteInspectionModel(key: number): Promise<any> {
    return this.table(IdbTable.inspectionModel).delete(key);
  }

  deleteUploadedFile(
    deletedFile: any,
    whereConditions: WhereConditions,
    structureType?: StructureType
  ): void {
    const { fileName, isDefect } = deletedFile;

    if (!isDefect) {
      whereConditions.basePathName = fileName;
    } else {
      whereConditions.fotoName = fileName;
    }

    const tableName = this.getUploadedFileTableName(structureType);
    this.table(tableName)
      .where({ ...whereConditions })
      .delete();
  }

  deleteUploadedFilesByKeys(
    keysList: number[],
    structureType?: StructureType
  ): void {
    const tableName = this.getUploadedFileTableName(structureType);

    this.table(tableName).bulkDelete(keysList);
  }

  getBridgeDefectsList(
    defectSearchParams: DefectSearchParams
  ): PromiseExtended {
    const { element } = defectSearchParams;

    if (element) {
      return this.table(IdbTable.bridgeDefectsMaster)
        .where({
          element,
        })
        .first()
        .then((consolidatedDefectsItem) => consolidatedDefectsItem.defects)
        .then((defectsList) =>
          this.getFilteredBridgeDefectsList(defectsList, defectSearchParams)
        );
    } else {
      return this.table(IdbTable.bridgeDefectsMaster)
        .toArray()
        .then((consolidatedDefectsList) => {
          const combinedDefects = {};
          consolidatedDefectsList.forEach(({ element, defects }) => {
            combinedDefects[element] = defects;
          });

          const selectedDefectsList = [];
          Object.values(combinedDefects).forEach((defectsList: any) => {
            selectedDefectsList.push(...defectsList);
          });

          return selectedDefectsList;
        })
        .then((defectsList) =>
          this.getFilteredBridgeDefectsList(defectsList, defectSearchParams)
        );
    }
  }

  getInspectionModel(modelId: number): Observable<any> {
    return liveQuery(() => this.table(IdbTable.inspectionModel).get(modelId));
  }

  getInspectionModelsList(): PromiseExtended {
    return this.table(IdbTable.inspectionModel).toArray();
  }

  getTemplateDetail(templateId: string): Observable<any> {
    return liveQuery(() =>
      this.table(IdbTable.inspectionTemplateDetail)
        .where({ templateId })
        .toArray()
    );
  }

  getTemplateMasterList(): Observable<any> {
    return liveQuery(() =>
      this.table(IdbTable.inspectionTemplateMaster).toArray()
    );
  }

  getTenantMasterList(): Observable<any> {
    return liveQuery(() => this.table(IdbTable.tenantMaster).toArray());
  }

  getTunnelDefectsList(defectType?: string): PromiseExtended<any> {
    return this.table(IdbTable.tunnelDefectsMaster)
      .where({ defectType })
      .first()
      .then((consolidatedDefectsItem) => consolidatedDefectsItem.defects);
  }

  getUploadedFilesCount(
    whereConditions: WhereConditions,
    structureType?: StructureType
  ): PromiseExtended<number> {
    const tableName = this.getUploadedFileTableName(structureType);

    return this.table(tableName)
      .where({ ...whereConditions })
      .count();
  }

  getUploadedFilesList(
    whereConditions: WhereConditions,
    structureType?: StructureType
  ): PromiseExtended<any[]> {
    const tableName = this.getUploadedFileTableName(structureType);

    return this.table(tableName)
      .where({ ...whereConditions })
      .toArray();
  }

  // TEMP: For migration. Files from old inspection defect table to migrate into the new tables
  getUploadedFilesListForMigration(
    whereConditions: WhereConditions
  ): PromiseExtended<any[]> {
    return this.table(IdbTable.inspectionDefectFiles)
      .where({ ...whereConditions })
      .toArray();
  }

  initializeDatabase(): void {
    const versionNumber: number = 4;
    this.createIndexedDbStores(versionNumber);
    this.openDatabaseConnection();
  }

  async migrateMasterTable(
    oldTableName: IdbTable,
    newTableName: IdbTable
  ): Promise<void> {
    // TEMP: For migration
    const tableContentsList = await this.table(oldTableName).toArray();

    try {
      await this.table(newTableName).bulkAdd(tableContentsList);
    } catch (err) {
      if (
        err.name !== 'BulkError' &&
        err.failures[0] !==
          'DOMException: Key already exists in the object store.'
      ) {
        throw err; // Rethrow the error if it's not a ConstraintError
      }
    }
  }

  updateDefectFotoNames(
    whereConditions: WhereConditions,
    structureType: StructureType,
    fotoName: string
  ): PromiseExtended<number> {
    const tableName = this.getUploadedFileTableName(structureType);

    return this.table(tableName)
      .where({ ...whereConditions })
      .modify({ fotoName });
  }

  async updateEventualiNoteFilesRowIndex(
    whereConditions: WhereConditions,
    structureType: StructureType,
    rowIndexUpdate: number
  ): Promise<void> {
    const tableName = this.getUploadedFileTableName(structureType);
    const defectFilesList = await this.table(tableName)
      .where({ ...whereConditions })
      .toArray();

    // Updating rowIndex of each eventuali note file
    defectFilesList.forEach((defectFile) => {
      if (!defectFile.fotoName.includes(BridgeConstants.eventualiNote)) return;

      this.table(tableName)
        .update(defectFile.id, {
          rowIndex: defectFile.rowIndex + rowIndexUpdate,
        })
        .then();
    });
  }

  updateInspectionModel(modelId: number, modelData: any): PromiseExtended {
    return this.table(IdbTable.inspectionModel)
      .update(modelId, { model: modelData })
      .then();
  }

  private createIndexedDbStores(versionNumber: number): void {
    this.version(versionNumber).stores({
      [IdbTable.bridgeDefectFiles]:
        '++id, file, modelHash, fotoName, campataNo, casoType, defectElement, defectElementListName, defectElementRowIndex, defectTemplateTableRowIndex',
      [IdbTable.bridgeDefectsMaster]: '++id, element, defects',
      [IdbTable.inspectionDefectFiles]:
        '++id, file, component, rowIndex, modelHash, campateNo, componentRowIndex, componentListName, fotoName, casoType',
      [IdbTable.inspectionFilesList]:
        '++id, file, keyStructure, imageType, modelHash, basePathName', // imageType values: 1 (single file), 2 (multi file)
      [IdbTable.inspectionModel]: '++id, model, modelHash',
      [IdbTable.inspectionTemplateDefectsList]:
        '++id, template, component, material', // TEMP: For migration
      [IdbTable.inspectionTemplateDetail]: '++id, templateId, template',
      [IdbTable.inspectionTemplateMaster]: '++id,id,name', // TEMP: For migration
      [IdbTable.inspectionTemplatesMaster]: '++id,id,name',
      [IdbTable.tenantMaster]: '++id,name', // TEMP: For migration
      [IdbTable.tenantsMaster]: '++id,name',
      [IdbTable.tunnelDefectFiles]:
        '++id, file, modelHash, fotoName, concioNo, sezioneLongitudinale, defectElement, defectElementListName, defectElementRowIndex, defectTemplateTableRowIndex',
      [IdbTable.tunnelDefectsList]: '++id, list, defectType', // TEMP: For migration
      [IdbTable.tunnelDefectsMaster]: '++id, defectType, defects',
    });
  }

  private getFilteredBridgeDefectsList(
    defectsList: any[],
    defectSearchParams: DefectSearchParams
  ): any[] {
    const { component, material, elemento } = defectSearchParams;

    const filteredDefectsList = defectsList.filter((defect) => {
      const hasComponent =
        component != null ? defect.component === component : true;
      const hasMaterial =
        material != null ? defect.material === material : true;
      const hasElemento =
        elemento != null ? defect.elemento === elemento : true;

      return hasComponent && hasMaterial && hasElemento;
    });

    return filteredDefectsList;
  }

  private getUploadedFileTableName(structureType?: StructureType): IdbTable {
    switch (structureType) {
      case StructureType.bridge:
        return IdbTable.bridgeDefectFiles;

      case StructureType.tunnel:
        return IdbTable.tunnelDefectFiles;

      default:
        return IdbTable.inspectionFilesList;
    }
  }

  private openDatabaseConnection(): void {
    this.open()
      .then((data) => {})
      .catch((err) => console.info(err.message));
  }
}
