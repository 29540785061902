import { Component } from '@angular/core';

import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  selector: 'cube-defect-table-checkbox-renderer',
  template: `<div *ngIf="params">
    <mat-checkbox
      [disabled]="params.colDef.disabled || !params.node.selectable"
      [(ngModel)]="params.value"
      (change)="onChangeCheckbox($event)"
    ></mat-checkbox>
  </div>`,
  styles: [
    `
      .mat-checkbox-disabled {
        cursor: not-allowed;
      }

      .mat-checkbox ::ng-deep .mat-checkbox-inner-container {
        height: 13px;
        width: 13px;
      }

      .mat-checkbox ::ng-deep .mat-checkbox-ripple {
        height: 30px;
        left: calc(50% - 15px);
        top: calc(50% - 15px);
        width: 30px;
      }

      .mat-checkbox
        ::ng-deep
        .mat-ripple-element:not(.mat-checkbox-persistent-ripple) {
        height: 30px !important;
        left: 0px !important;
        top: 0px !important;
        width: 30px !important;
      }
    `,
  ],
})
export class DefectTableCheckboxRendererComponent
  implements AgRendererComponent
{
  params: any;

  agInit(params: any): void {
    this.params = params;

    if (!params.node.selectable) {
      params.value = false;
      params.node.setDataValue(params.column.colId, false);
    }
  }

  onChangeCheckbox(e: any): void {
    const checked = e.checked;
    const colId = this.params.column.colId;

    this.params.node.setDataValue(colId, checked);

    if (colId !== 'PS' && colId !== 'visto') {
      this.params.data.valorized =
        this.params.data.NA || this.params.data.NR || this.params.data.NP;
    }
  }

  refresh(params: any): boolean {
    this.params.api.redrawRows({ rowNodes: [params.node] });

    return true;
  }
}
