import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { UserService } from '../../services/user/user.service';
import { UserRole } from '../../shared/configs/user-role.config';

@Directive({
  selector: '[cubeRole]',
})
export class RoleDirective implements OnInit {
  @Input() set cubeRoleIsNotAllowed(isNotAllowed: boolean) {
    this.isNotAllowedMode = isNotAllowed;
  }
  @Input() set cubeRole(rolesList: UserRole[]) {
    this.rolesList = rolesList;
  }

  private isNotAllowedMode: boolean;
  private rolesList!: UserRole[];

  constructor(
    private templateRef: TemplateRef<any>,
    private userService: UserService,
    private viewContainer: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.toggleTemplateVisibility();
  }

  private toggleTemplateVisibility(): void {
    if (this.isUserAllowedByRole()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private isUserAllowedByRole(): boolean {
    const isUserAllowedByRole: any = this.userService.isUserAllowedByRole(
      this.rolesList
    );

    return this.isNotAllowedMode ? !isUserAllowedByRole : isUserAllowedByRole;
  }
}
