import { ActionButtonsRendererComponent } from '../../../app/pages/inspections/shared/components/action-buttons-renderer.component';
import { DefectTableCheckboxRendererComponent } from '../../../app/pages/inspections/shared/components/defect-table-checkbox-renderer.component';
import { DefectTableHeaderRendererComponent } from '../../../app/pages/inspections/shared/components/defect-table-header-renderer.component';
import { DefectTableLocalizzazioneRendererComponent } from '../../../app/pages/inspections/shared/components/defect-table-localizzazione-renderer.component';
import { DefectTableNoteRendererComponent } from '../../../app/pages/inspections/shared/components/defect-table-note-renderer.component';
import { DefectTableRadioButtonsRendererComponent } from '../../../app/pages/inspections/shared/components/defect-table-radio-buttons-renderer.component';
import { TextOnlyRendererComponent } from '../../../app/pages/inspections/shared/components/text-only-renderer.component';
import { ViewTunnelInspectionComponent } from '../../../app/pages/inspections/view-tunnel-inspection/view-tunnel-inspection.component';
import { DefectNoteMode as DefectNoteModeConfig } from '../../../app/shared/configs/defect-note-mode.config';
import { InspectionStatus as InspectionStatusConfig } from '../../../app/shared/configs/inspection-status.config';
import { UserRole as UserRoleConfig } from '../../../app/shared/configs/user-role.config';
import { environment as env } from '../../../environments/environment';
import { TunnelConstants as TunnelConstantsConfig } from './constants';

const actionButtonsRendererComponent = ActionButtonsRendererComponent;
const DefectNoteMode = DefectNoteModeConfig;
const defectTableCheckboxRendererComponent =
  DefectTableCheckboxRendererComponent;
const defectTableHeaderRendererComponent = DefectTableHeaderRendererComponent;
const defectTableLocalizzazioneRendererComponent =
  DefectTableLocalizzazioneRendererComponent;
const defectTableNoteRendererComponent = DefectTableNoteRendererComponent;
const defectTableRadioButtonsRendererComponent =
  DefectTableRadioButtonsRendererComponent;
const environment = env;
const InspectionStatus = InspectionStatusConfig;
const textOnlyRendererComponent = TextOnlyRendererComponent;
const TunnelConstants = TunnelConstantsConfig;
const UserRole = UserRoleConfig;

export function TunnelInputTemplate(
  tunnelInputTemplateComponent: ViewTunnelInspectionComponent,
  isDisabled: boolean,
  userRole: string
) {
  tunnelInputTemplateComponent.token =
    'se=2024-05-24T18%3A30%3A12Z&sp=r&sv=2022-11-02&sr=c&sig=FPh7XdGqZbfux83ee3tmy0PIkm0xsbKb7Gp1uS6ZjXc%3D';

  return {
    token: tunnelInputTemplateComponent.token,
    schema_json: [
      // INFO: Version 3.1.1
      {
        model: {
          [TunnelConstants.levels.l0]: {
            'Inspection date': [
              {
                'Tecnico Rilevatore': '',
                'Data Ispezione': '',
              },
            ],
            'Aspetti geologici e idrogeologici': {
              'da Pk section': [
                {
                  'da Pk': '',
                  'a Pk': '',
                  'Copertura m': '',
                },
                {
                  'da Pk': '',
                  'a Pk': '',
                  'Copertura m': '',
                },
                {
                  'da Pk': '',
                  'a Pk': '',
                  'Copertura m': '',
                },
                {
                  'da Pk': '',
                  'a Pk': '',
                  'Copertura m': '',
                },
                {
                  'da Pk': '',
                  'a Pk': '',
                  'Copertura m': '',
                },
              ],
              'da Pk table section': [
                {
                  'da Pk': '',
                  'a Pk': '',
                  'Altezza battente acqua 1 m': '',
                },
                {
                  'da Pk': '',
                  'a Pk': '',
                  'Altezza battente acqua 1 m': '',
                },
                {
                  'da Pk': '',
                  'a Pk': '',
                  'Altezza battente acqua 1 m': '',
                },
                {
                  'da Pk': '',
                  'a Pk': '',
                  'Altezza battente acqua 1 m': '',
                },
                {
                  'da Pk': '',
                  'a Pk': '',
                  'Altezza battente acqua 1 m': '',
                },
              ],
            },
            'Tipologia e caratteristiche del rivestimento': {
              'Rivestimento di prima fase table section': [
                {
                  'da Pk': '',
                  'a Pk': '',
                  'Descrizione tipo di rivestimento': '',
                  'Tipologia di ammasso attraversato': '',
                },
                {
                  'da Pk': '',
                  'a Pk': '',
                  'Descrizione tipo di rivestimento': '',
                  'Tipologia di ammasso attraversato': '',
                },
                {
                  'da Pk': '',
                  'a Pk': '',
                  'Descrizione tipo di rivestimento': '',
                  'Tipologia di ammasso attraversato': '',
                },
              ],
            },
            'Pavimentazione stradale in galleria': {
              'Classe di indice IRI prevalente table section': [
                {
                  'Classe IRI': '',
                  'da Pk': '',
                  'a Pk': '',
                },
                {
                  'Classe IRI': '',
                  'da Pk': '',
                  'a Pk': '',
                },
                {
                  'Classe IRI': '',
                  'da Pk': '',
                  'a Pk': '',
                },
              ],
            },
            'Interventi di manutenzione': {
              'Tipo di manutenzione section': [
                {
                  'Intervento n': {
                    'Intervento n Data': '',
                    'Intervento n Data text': '',
                  },
                  'Allegato n': '',
                },
                {
                  'Intervento n': {
                    'Intervento n Data': '',
                    'Intervento n Data text': '',
                  },
                  'Allegato n': '',
                },
                {
                  'Intervento n': {
                    'Intervento n Data': '',
                    'Intervento n Data text': '',
                  },
                  'Allegato n': '',
                },
              ],
            },
            'Ispezioni pregresse': {
              'Metodologia ispezioni section': [
                {
                  'Ispezione n': {
                    'Ispezione n Data': '',
                    'Ispezione n Data text': '',
                  },
                  'Allegato n': '',
                },
                {
                  'Ispezione n': {
                    'Ispezione n Data': '',
                    'Ispezione n Data text': '',
                  },
                  'Allegato n': '',
                },
                {
                  'Ispezione n': {
                    'Ispezione n Data': '',
                    'Ispezione n Data text': '',
                  },
                  'Allegato n': '',
                },
              ],
            },
            'Documenti progettuali disponibili': {
              'Varianti in corso d’opera': {
                Varianti: [
                  {
                    'Documenti descrittivi Variante': '',
                    'Documenti grafici Variante': '',
                    'Documenti contrattuali e contabili Variante': '',
                  },
                ],
              },
              'Interventi di manutenzione': {
                Manutenzione: [
                  {
                    'Documenti descrittivi Manutenzione': '',
                    'Documenti grafici Manutenzione': '',
                    'Documenti contrattuali e contabili Manutenzione': '',
                  },
                ],
              },
              Segnalazioni: {
                Segnalazione: [
                  {
                    'Documenti descrittivi con date Segnalazione': '',
                    'Documenti grafici con date Segnalazione': '',
                  },
                ],
              },
              'Progetto di incremento del grado di sicurezza': {
                Progetto: [
                  {
                    'Documenti descrittivi Progetto': '',
                    'Documenti grafici Progetto': '',
                  },
                ],
              },
              'Interventi di incremento del grado di sicurezza': {
                Intervento: [
                  {
                    'Documenti descrittivi Intervento': '',
                    'Documenti grafici Intervento': '',
                  },
                ],
              },
            },
            'Documenti disponibili sui requisiti minimi di sicurezza ai sensi del D Lgs 264 del 2006':
              [
                {
                  'Documenti disponibili sui requisiti minimi di sicurezza ai sensi del D Lgs 264 del 2006 - text':
                    '',
                  'Documenti disponibili sui requisiti minimi di sicurezza ai sensi del D Lgs 264 del 2006 - files':
                    '',
                },
              ],
            'Storico di eventi rilevanti registrati in galleria': {
              'Eventi sulle strutture es distacco di materiale': [
                {
                  'Eventi sulle strutture es distacco di materiale - text': '',
                  'Eventi sulle strutture es distacco di materiale - files': '',
                },
              ],
              'Eventi idraulici es venute d’acqua allagamenti': [
                {
                  'Eventi idraulici es venute d’acqua allagamenti - text': '',
                  'Eventi idraulici es venute d’acqua allagamenti - files': '',
                },
              ],
              'Eventi di guasti ai sistemi di galleria es guasti impianti incendi materiali elettrici':
                [
                  {
                    'Eventi di guasti ai sistemi di galleria es guasti impianti incendi materiali elettrici - text':
                      '',
                    'Eventi di guasti ai sistemi di galleria es guasti impianti incendi materiali elettrici - files':
                      '',
                  },
                ],
              'Eventi sulla circolazione veicolare es incidenti incendi sversamento di merci pericolose':
                [
                  {
                    'Eventi sulla circolazione veicolare es incidenti incendi sversamento di merci pericolose - text':
                      '',
                    'Eventi sulla circolazione veicolare es incidenti incendi sversamento di merci pericolose - files':
                      '',
                  },
                ],
              'Ritorni di esperienza dagli eventi rilevanti in galleria note': [
                {
                  'Ritorni di esperienza dagli eventi rilevanti in galleria note - text':
                    '',
                  'Ritorni di esperienza dagli eventi rilevanti in galleria note - files':
                    '',
                },
              ],
            },
            'Storico dei rilievi pregressi dell’ambiente stradale in galleria':
              {
                'Rilievi in continuo della sezione stradale larghezza altezza':
                  [
                    {
                      'Rilievi in continuo della sezione stradale larghezza altezza - text':
                        '',
                      'Rilievi in continuo della sezione stradale larghezza altezza - files':
                        '',
                    },
                  ],
                'Rilievi in continuo dell’allineamento stradale planimetrico altimetrico':
                  [
                    {
                      'Rilievi in continuo dell’allineamento stradale planimetrico altimetrico - text':
                        '',
                      'Rilievi in continuo dell’allineamento stradale planimetrico altimetrico - files':
                        '',
                    },
                  ],
                'Rilievi in continuo dei margini della carreggiata stradale banchine marciapiede':
                  [
                    {
                      'Rilievi in continuo dei margini della carreggiata stradale banchine marciapiede - text':
                        '',
                      'Rilievi in continuo dei margini della carreggiata stradale banchine marciapiede - files':
                        '',
                    },
                  ],
                'Altra documentazione di rilievo': [
                  {
                    'Altra documentazione di rilievo - text': '',
                    'Altra documentazione di rilievo - files': '',
                  },
                ],
              },
          },
          [TunnelConstants.levels.l1]: {
            'Schemi geometrici di massima (Indicare le dimensioni principali ed eventuali quadri fessurativi)':
              {
                'Schemi geometrici di massima (Indicare le dimensioni principali ed eventuali quadri fessurativi) section':
                  [
                    {
                      'Sezione trasversale': null,
                      'Sezione longitudinale': null,
                      Pianta: null,
                    },
                  ],
              },
            'Mappatura degli elementi non strutturali impianti e servizi': {
              'Mappatura degli elementi non strutturali impianti e servizi section':
                [
                  {
                    'Sezione trasversale': null,
                    'Sezione longitudinale': null,
                    Pianta: null,
                  },
                ],
            },
          },
          sharedData: {
            schedaForniceN: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Scheda fornice n section',
                  'Scheda fornice n',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Scheda fornice',
                  'Scheda fornice n',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Scheda fornice',
                  'Scheda fornice n',
                ],
              },
            ],
            schedaForniceNRadio: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Scheda fornice n section',
                  'Scheda fornice n - radio',
                ],
              },
              {
                path: [TunnelConstants.levels.l1, 'Scheda fornice', 'radio'],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Scheda fornice',
                  'radio',
                ],
              },
            ],
            uniDirezionaleDirezione: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Scheda fornice n section',
                  'direzione',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Scheda fornice',
                  'direzione',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Scheda fornice',
                  'direzione',
                ],
              },
            ],
            direzione: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Scheda fornice n section',
                  'direzione',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Scheda fornice',
                  'direzione',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Scheda fornice',
                  'direzione',
                ],
              },
            ],
            codiceIop: [
              {
                path: [TunnelConstants.levels.l0, 'Codice IOP'],
              },
              {
                path: [TunnelConstants.levels.l1, 'Codice IOP'],
              },
              {
                path: [
                  TunnelConstants.levels.defect1,
                  'Scheda Ispezione Ponti di Livello 1',
                  'Codice IOP',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.defect2,
                  'Scheda Ispezione Ponti di Livello 1',
                  'Codice IOP',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.defect3,
                  'Scheda Ispezione Ponti di Livello 1',
                  'Codice IOP',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.defect4,
                  'Scheda Ispezione Ponti di Livello 1',
                  'Codice IOP',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.defect5,
                  'Scheda Ispezione Ponti di Livello 1',
                  'Codice IOP',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Codice IOP',
                ],
              },
            ],
            nomeGalleria: [
              {
                path: [TunnelConstants.levels.l0, 'Nome Ponte Viadotto'],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Nome Galleria/paramassi/sottopasso',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Nome Galleria/paramassi/sottopasso',
                ],
              },
            ],
            stradaDiAppartenenza: [
              {
                path: [TunnelConstants.levels.l0, 'Strada di appartenenza'],
              },
              {
                path: [TunnelConstants.levels.l1, 'Strada di appartenenza'],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Strada di appartenenza',
                ],
              },
            ],
            progressivaKmIniziale: [
              {
                path: [TunnelConstants.levels.l0, 'Progressiva km iniziale'],
              },
              {
                path: [TunnelConstants.levels.l1, 'Progressiva km iniziale'],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Progressiva km iniziale',
                ],
              },
            ],
            progressivaKmFinale: [
              {
                path: [TunnelConstants.levels.l0, 'Progressiva km finale'],
              },
              {
                path: [TunnelConstants.levels.l1, 'Progressiva km finale'],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Progressiva km finale',
                ],
              },
            ],
            provinciaRegione: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Localizzazione',
                  'Provincia Regione',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Localizzazione',
                  'Provincia/Regione',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione',
                  'Provincia/Regione',
                ],
              },
            ],
            comune: [
              {
                path: [TunnelConstants.levels.l0, 'Localizzazione', 'comune'],
              },
              {
                path: [TunnelConstants.levels.l1, 'Localizzazione', 'Comune'],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione',
                  'Comune',
                ],
              },
            ],
            localita: [
              {
                path: [TunnelConstants.levels.l0, 'Localizzazione', 'Località'],
              },
              {
                path: [TunnelConstants.levels.l1, 'Localizzazione', 'Località'],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione',
                  'Località',
                ],
              },
            ],
            coordinateGeografiche: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Coordinate Geografiche',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Coordinate Geografiche',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Coordinate Geografiche',
                ],
              },
            ],
            inizialeQuota: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Iniziale',
                  'Quota slm m',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Iniziale',
                  'Quota slm m',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Iniziale',
                  'Quota slm m',
                ],
              },
            ],
            inizialeLongitudine: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Iniziale',
                  'Longitudine',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Iniziale',
                  'Longitudine',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Iniziale',
                  'Longitudine',
                ],
              },
            ],
            inizialeLatitudine: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Iniziale',
                  'Latitudine',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Iniziale',
                  'Latitudine',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Iniziale',
                  'Latitudine',
                ],
              },
            ],
            finaleQuota: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Finale',
                  'Quota slm m',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Finale',
                  'Quota slm m',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Finale',
                  'Quota slm m',
                ],
              },
            ],
            finaleLongitudine: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Finale',
                  'Longitudine',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Finale',
                  'Longitudine',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Finale',
                  'Longitudine',
                ],
              },
            ],
            finaleLatitudine: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Finale',
                  'Latitudine',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Finale',
                  'Latitudine',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Localizzazione',
                  'Coordinate Geografiche',
                  'Finale',
                  'Latitudine',
                ],
              },
            ],
            l0Images: [],
            l1Images: [],
            l2Images: [],
            l3Images: [],
            tipoDiGalleriaNaturale: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Caratteristiche strutturali',
                  'Tipo di galleria',
                  'Naturale',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Caratteristiche strutturali',
                  'Tipo di galleria',
                  'Naturale',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Caratteristiche strutturali',
                  'Tipo di galleria',
                  'Naturale',
                ],
              },
            ],
            tipoDiGalleriaNatureArtificiale: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Caratteristiche strutturali',
                  'Tipo di galleria',
                  'Naturale artificiale',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Caratteristiche strutturali',
                  'Tipo di galleria',
                  'Naturale / artificiale',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Caratteristiche strutturali',
                  'Tipo di galleria',
                  'Naturale / artificiale',
                ],
              },
            ],
            tipoDiGalleriaArtificiale: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Caratteristiche strutturali',
                  'Tipo di galleria',
                  'Artificiale',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Caratteristiche strutturali',
                  'Tipo di galleria',
                  'Artificiale',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Caratteristiche strutturali',
                  'Tipo di galleria',
                  'Artificiale',
                ],
              },
            ],
            tipoDiGalleriaPresenzaDiFinestrature: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Caratteristiche strutturali',
                  'Tipo di galleria',
                  'presenza di finestrature',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Caratteristiche strutturali',
                  'Tipo di galleria',
                  'Presenza di finestrature',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Caratteristiche strutturali',
                  'Tipo di galleria',
                  'Presenza di finestrature',
                ],
              },
            ],
            sviluppoGalleriaNaturale: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Caratteristiche strutturali',
                  'Sviluppo galleria naturale',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Caratteristiche strutturali',
                  'Sviluppo galleria naturale m',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Caratteristiche strutturali',
                  'Sviluppo galleria naturale m',
                ],
              },
            ],
            sviluppoTrattiFinestrati: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Caratteristiche strutturali',
                  'Sviluppo tratti finestrati',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Caratteristiche strutturali',
                  'Sviluppo tratti finestrati m',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Caratteristiche strutturali',
                  'Sviluppo tratti finestrati m',
                ],
              },
            ],
            sviluppoGalleriaArtificiale: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Caratteristiche strutturali',
                  'Sviluppo galleria artificiale',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Caratteristiche strutturali',
                  'Sviluppo galleria artificiale m',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Caratteristiche strutturali',
                  'Sviluppo galleria artificiale m',
                ],
              },
            ],
            imbocco: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Caratteristiche strutturali',
                  'Pregalleria',
                  'Imbocco',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Caratteristiche strutturali',
                  'Pregalleria',
                  'Imbocco',
                  'radio',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Caratteristiche strutturali',
                  'Pregalleria',
                  'Imbocco',
                  'radio',
                ],
              },
            ],
            imboccoLunghezza: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Caratteristiche strutturali',
                  'Pregalleria',
                  'Lunghezza m',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Caratteristiche strutturali',
                  'Pregalleria',
                  'Imbocco',
                  'Lunghezza m',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Caratteristiche strutturali',
                  'Pregalleria',
                  'Imbocco',
                  'Lunghezza m',
                ],
              },
            ],
            sbocco: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Caratteristiche strutturali',
                  'Pregalleria',
                  'Sbocco',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Caratteristiche strutturali',
                  'Pregalleria',
                  'Sbocco',
                  'radio',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Caratteristiche strutturali',
                  'Pregalleria',
                  'Sbocco',
                  'radio',
                ],
              },
            ],
            sboccoLunghezza: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Caratteristiche strutturali',
                  'Pregalleria',
                  'Lunghezza m - Note',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Caratteristiche strutturali',
                  'Pregalleria',
                  'Sbocco',
                  'Lunghezza m',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Caratteristiche strutturali',
                  'Pregalleria',
                  'Sbocco',
                  'Lunghezza m',
                ],
              },
            ],
            spessore: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'spessore m',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'spessore m',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'spessore m',
                ],
              },
            ],
            calcestruzzoArmato: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Calcestruzzo armato',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Calcestruzzo armato',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Calcestruzzo armato',
                ],
              },
            ],
            calcestruzzoNonArmato: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Calcestruzzo non armato',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Calcestruzzo non armato',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Calcestruzzo non armato',
                ],
              },
            ],
            calcestruzzoProiettato: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Calcestruzzo proiettato',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Calcestruzzo proiettato',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Calcestruzzo proiettato',
                ],
              },
            ],
            muratura: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Muratura',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Muratura',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Muratura',
                ],
              },
            ],
            legno: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Legno',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Legno',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Legno',
                ],
              },
            ],
            conciPrefabbricati: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Conci prefabbricati',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Conci prefabbricati',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Conci prefabbricati',
                ],
              },
            ],
            altro: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Altro',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Altro',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Altro',
                ],
              },
            ],
            altroText: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Altro text',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Altro text',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Altro text',
                ],
              },
            ],
            armatura: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Armatura',
                  'radio',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Armatura',
                  'radio',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Armatura',
                  'radio',
                ],
              },
            ],
            diametro: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Armatura',
                  'Diametro mm',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Armatura',
                  'Diametro mm',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Armatura',
                  'Diametro mm',
                ],
              },
            ],
            copriferro: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Copriferro mm',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Copriferro mm',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Copriferro mm',
                ],
              },
            ],
            fibreMetallicheRadio: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Fibre metalliche',
                  'Fibre metalliche radio',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Fibre metalliche',
                  'Fibre metalliche radio',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Fibre metalliche',
                  'Fibre metalliche radio',
                ],
              },
            ],
            caratteristiche: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Fibre metalliche',
                  'Caratteristiche',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Fibre metalliche',
                  'Caratteristiche',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'Fibre metalliche',
                  'Caratteristiche',
                ],
              },
            ],
            numeroDiConci: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'In caso di utilizzo dii conci prefabbricati',
                  'Numero di conci',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'In caso di utilizzo dii conci prefabbricati',
                  'Numero di conci',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'In caso di utilizzo dii conci prefabbricati',
                  'Numero di conci',
                ],
              },
            ],
            geometriaConci: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'In caso di utilizzo dii conci prefabbricati',
                  'geometria conci',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'In caso di utilizzo dii conci prefabbricati',
                  'geometria conci',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'In caso di utilizzo dii conci prefabbricati',
                  'geometria conci',
                ],
              },
            ],
            tipologiaDelleGuarnizioniGasket: [
              {
                path: [
                  TunnelConstants.levels.l0,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'In caso di utilizzo dii conci prefabbricati',
                  'Tipologia delle guarnizioni (gasket)',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l1,
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'In caso di utilizzo dii conci prefabbricati',
                  'Tipologia delle guarnizioni (gasket)',
                ],
              },
              {
                path: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  'Tipologia e caratteristiche del rivestimento',
                  'Rivestimento definitivo',
                  'In caso di utilizzo dii conci prefabbricati',
                  'Tipologia delle guarnizioni (gasket)',
                ],
              },
            ],
          },
          sharedDataForList: {
            codiceIop: [
              {
                list: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  TunnelConstants.partsList,
                ],
                subList: [
                  'modelstructure',
                  `${TunnelConstants.levels.defect1}list`,
                ],
                path: [
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'Codice IOP',
                ],
                syncCondition: 'true',
              },
              {
                list: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  TunnelConstants.partsList,
                ],
                subList: [
                  'modelstructure',
                  `${TunnelConstants.levels.defect2}list`,
                ],
                path: [
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'Codice IOP',
                ],
                syncCondition: 'true',
              },
              {
                list: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  TunnelConstants.partsList,
                ],
                subList: [
                  'modelstructure',
                  `${TunnelConstants.levels.defect3}list`,
                ],
                path: [
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'Codice IOP',
                ],
                syncCondition: 'true',
              },
              {
                list: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  TunnelConstants.partsList,
                ],
                subList: [
                  'modelstructure',
                  `${TunnelConstants.levels.defect4}list`,
                ],
                path: [
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'Codice IOP',
                ],
                syncCondition: 'true',
              },
              {
                list: [
                  TunnelConstants.levels.l0,
                  'opereparzialilist',
                  0,
                  'modelstructure',
                  TunnelConstants.partsList,
                ],
                subList: [
                  'modelstructure',
                  `${TunnelConstants.levels.defect5}list`,
                ],
                path: [
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'Codice IOP',
                ],
                syncCondition: 'true',
              },
            ],
          },
          outputPaths: {
            output1: {
              keys: {
                defectsLists: [
                  `${TunnelConstants.levels.defect1}list`,
                  `${TunnelConstants.levels.defect2}list`,
                  `${TunnelConstants.levels.defect3}list`,
                  `${TunnelConstants.levels.defect4}list`,
                  `${TunnelConstants.levels.defect5}list`,
                ],
                modelStructure: 'modelstructure',
              },
              partialPaths: {
                concioNo: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>,
                  'modelstructure',
                  TunnelConstants.partNo,
                ],
                defects: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'Scheda Ispezione Ponti di Livello 1 List1',
                ],
                codUnique: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'uniquecode',
                ],
                codiceDifetto: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'Codice difetto',
                ],
                descrizioneDifetto: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'Descrizione difetto',
                ],
                g: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'G',
                ],
                k1: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'Estensione K1',
                ],
                k2: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'Intensità K2',
                ],
                psI: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'PS I',
                ],
                psG: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'PS g',
                ],
                NA: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'NA',
                ],
                NP: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'NP',
                ],
                NR: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'NR',
                ],
                note: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'Note',
                ],
              },
              codiceIop: [TunnelConstants.levels.l0, 'Codice IOP'],
              denominazione: [TunnelConstants.levels.l0, 'Nome Ponte Viadotto'],
              inspector: [
                TunnelConstants.levels.l0,
                'Inspection date',
                'Tecnico Rilevatore',
              ],
              data: [
                TunnelConstants.levels.l0,
                'Inspection date',
                'Data Ispezione',
              ],
              schedaForniceN: [
                TunnelConstants.levels.l0,
                'Scheda fornice n section',
                'Scheda fornice n',
              ],
              opereParzialiList: [
                TunnelConstants.levels.l0,
                'opereparzialilist',
              ],
              concioList: [
                TunnelConstants.levels.l0,
                'opereparzialilist',
                0,
                'modelstructure',
                'conciolist',
              ],
            },
            output5: {
              keys: {
                defectsLists: [
                  `${TunnelConstants.levels.defect1}list`,
                  `${TunnelConstants.levels.defect2}list`,
                  `${TunnelConstants.levels.defect3}list`,
                  `${TunnelConstants.levels.defect4}list`,
                  `${TunnelConstants.levels.defect5}list`,
                ],
                modelStructure: 'modelstructure',
              },
              partialPaths: {
                concioNo: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  TunnelConstants.partNo,
                ],
                defects: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'Scheda Ispezione Ponti di Livello 1 List1',
                ],
                codUnique: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  'modelstructure',
                  'Scheda Ispezione Ponti di Livello 1',
                  'uniquecode',
                ],
                codiceDifetto: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'Codice difetto',
                ],
                descrizioneDifetto: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'Descrizione difetto',
                ],
                g: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'G',
                ],
                k1: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'Estensione K1',
                ],
                k2: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'Intensità K2',
                ],
                psI: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'PS I',
                ],
                psG: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'PS g',
                ],
                NA: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'NA',
                ],
                NP: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'NP',
                ],
                NR: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'NR',
                ],
                note: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'Note',
                ],
                correlazioneConAltriDifetti: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'Correlazione con altri difetti',
                ],
                l: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'L',
                ],
                nFoto: [
                  // 'l0',
                  // 'opereparzialilist',
                  // 0,
                  // 'modelstructure',
                  // 'conciolist',
                  // <index>
                  // 'modelstructure',
                  // <defectsListItem>
                  // <index>
                  // 'modelstructure',
                  // 'Scheda Ispezione Ponti di Livello 1',
                  // 'Scheda Ispezione Ponti di Livello 1 List1',
                  // <index>
                  'N° foto',
                ],
              },
              codiceIop: [TunnelConstants.levels.l0, 'Codice IOP'],
              nomeDellOpera: [TunnelConstants.levels.l0, 'Nome Ponte Viadotto'],
              annoCostruzione: [
                TunnelConstants.levels.l0,
                'Informazioni generali',
                'Anno di costruzione ristrutturazione',
                'Ultimazione costruzione collaudo',
                'year',
              ],
              autostrada: [TunnelConstants.levels.l0, 'Strada di appartenenza'],
              ramo: [
                TunnelConstants.levels.l0,
                'opereparzialilist',
                0,
                'modelstructure',
                'Direzione (E/O, N/S)',
              ],
              tratto: [
                TunnelConstants.levels.l0,
                'opereparzialilist',
                0,
                'modelstructure',
                'Tratta stradale/autostradale',
              ],
              schedaForniceN: [
                TunnelConstants.levels.l0,
                'Scheda fornice n section',
                'Scheda fornice n',
              ],
              progressiva: [
                TunnelConstants.levels.l0,
                'Progressiva km iniziale',
              ],
              regione: [
                TunnelConstants.levels.l0,
                'Localizzazione',
                'Provincia Regione',
              ],
              comune: [TunnelConstants.levels.l0, 'Localizzazione', 'Comune'],
              latitudine: [
                TunnelConstants.levels.l0,
                'Localizzazione',
                'Coordinate Geografiche',
                'Iniziale',
                'Latitudine',
              ],
              longitudine: [
                TunnelConstants.levels.l0,
                'Localizzazione',
                'Coordinate Geografiche',
                'Iniziale',
                'Longitudine',
              ],
              altitudine: [
                TunnelConstants.levels.l0,
                'Localizzazione',
                'Coordinate Geografiche',
                'Iniziale',
                'Quota slm m',
              ],
              notes: [TunnelConstants.levels.l0, 'Notes'],
              media: [TunnelConstants.levels.l0, 'MediaList'],
              opereParzialiList: [
                TunnelConstants.levels.l0,
                'opereparzialilist',
              ],
              concioList: [
                TunnelConstants.levels.l0,
                'opereparzialilist',
                0,
                'modelstructure',
                'conciolist',
              ],
              tipoOpera: [
                TunnelConstants.levels.l0,
                'opereparzialilist',
                0,
                'modelstructure',
                'Nome Galleria/paramassi/sottopasso',
              ],
              tronco: [
                TunnelConstants.levels.l0,
                'opereparzialilist',
                0,
                'modelstructure',
                'Strada di appartenenza',
              ],
              dataDiIspezione: [
                TunnelConstants.levels.l0,
                'opereparzialilist',
                0,
                'modelstructure',
                'Informazioni Ispezione',
                'Data di ispezione',
              ],
              startTime: [
                TunnelConstants.levels.l0,
                'opereparzialilist',
                0,
                'modelstructure',
                'Informazioni Ispezione',
                'Fascia oraria',
                'startTime',
              ],
              endTime: [
                TunnelConstants.levels.l0,
                'opereparzialilist',
                0,
                'modelstructure',
                'Informazioni Ispezione',
                'Fascia oraria',
                'endTime',
              ],
              ispettore: [
                TunnelConstants.levels.l0,
                'opereparzialilist',
                0,
                'modelstructure',
                'Informazioni Ispezione',
                'Nome e firma degli ispettori',
              ],
              societa: [
                TunnelConstants.levels.l0,
                'opereparzialilist',
                0,
                'modelstructure',
                'Informazioni Ispezione',
                'Società',
              ],
              validatore: [
                TunnelConstants.levels.l0,
                'opereparzialilist',
                0,
                'modelstructure',
                'Informazioni Ispezione',
                'Validatore',
              ],
              quarter: [TunnelConstants.levels.l0, 'quarter'],
              year: [TunnelConstants.levels.l0, 'Year'],
              codiceOpera: [TunnelConstants.levels.l0, 'Codice Opera'],
            },
          },
          outputVersions: {
            output2: '2.3.1.1',
            output5: '5.3.1.1',
          },
          version: '3.1.1',
        },
      },
      {
        key: 'activeLevel',
        defaultValue: TunnelConstants.levels.l0,
      },
      {
        // Level: Level 0
        key: TunnelConstants.levels.l0,
        type: 'cube-tabs-type',
        templateOptions: {
          devMode: tunnelInputTemplateComponent.devMode, // TEMP: For development
        },
        hooks: {
          afterViewInit: () => {
            tunnelInputTemplateComponent.saveModelToIndexedDbForPlannedInspection();
          },
        },
        hideExpression: () =>
          tunnelInputTemplateComponent.model.activeLevel !==
          TunnelConstants.levels.l0,
        fieldGroup: [
          {
            templateOptions: { label: 'Level 0' },
            fieldGroup: [
              // LIVELLO 0 Scheda di censimento galleria – Parte 1
              // Top section
              {
                fieldGroupClassName: 'page-heading',
                fieldGroup: [
                  {
                    className: 'width-100',
                    template: `
                      <div class="page-heading-text">
                        <span class="prefix-block">LIVELLO 0</span>Scheda di censimento – conoscenza galleria
                      </div>`,
                  },
                  {
                    className: 'page-heading-image',
                    template: `<img src="assets/rina/RINA-dark.png">`,
                  },
                ],
              },
              {
                fieldGroupClassName: 'page-heading',
                fieldGroup: [
                  {
                    className: 'width-100',
                    template: `
                      <div class="page-heading-text">
                        <div>
                          <span class="font-weight-normal">
                            <span class="prefix-block">LIVELLO 0</span>Scheda di censimento galleria
                          </span> – Parte 1
                        </div>
                        <div>Dati di inquadramento dell’opera ed informazioni necessarie alla definizione delle classi di attenzione</div>
                      </div>`,
                  },
                ],
              },
              {
                fieldGroupClassName: 'display-flex grid-gap margin-top-30',
                key: 'Scheda fornice n section',
                fieldGroup: [
                  {
                    className: 'width-35 padding-top-10',
                    key: 'Scheda fornice n',
                    type: 'cube-number-type',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Scheda fornice n.',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      min: 1,
                      required: true,
                      onChange: (field, e) => {
                        tunnelInputTemplateComponent.syncSharedModelData(
                          'schedaForniceN',
                          e.target.value
                        );
                      },
                    },
                  },
                  {
                    key: 'Scheda fornice n - radio',
                    defaultValue: '',
                    type: 'radio',
                    className: 'remove-underline padding-left-40',
                    hooks: {
                      onInit: (field) => {
                        setTimeout(() => {
                          if (field.formControl.value !== 'Unidirezionale') {
                            field.formControl.parent
                              ?.get(['direzione'])
                              ?.setValue(null);
                            field.formControl.parent
                              ?.get(['direzione'])
                              ?.disable();
                          } else {
                            if (!isDisabled) {
                              field.formControl.parent
                                ?.get(['direzione'])
                                ?.enable();
                            }
                          }
                        }, 0);
                      },
                    },
                    templateOptions: {
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      key: 'Scheda fornice n - radio',
                      options: [
                        {
                          value: 'Unidirezionale',
                          label: 'Unidirezionale',
                        },
                      ],
                      change: (field, e) => {
                        if (field.formControl.value !== 'Unidirezionale') {
                          field.formControl.parent
                            ?.get(['direzione'])
                            ?.setValue(null);
                          field.formControl.parent
                            ?.get(['direzione'])
                            ?.disable();
                        } else {
                          field.formControl.parent
                            ?.get(['direzione'])
                            ?.enable();
                        }
                        tunnelInputTemplateComponent.syncSharedModelData(
                          'schedaForniceNRadio',
                          field.formControl.value
                        );
                      },
                      onReset: (field, e) => {
                        if (field.formControl.value !== 'Unidirezionale') {
                          field.formControl.parent
                            ?.get(['direzione'])
                            ?.setValue(null);
                          field.formControl.parent
                            ?.get(['direzione'])
                            ?.disable();
                          tunnelInputTemplateComponent.syncSharedModelData(
                            'schedaForniceNRadio',
                            field.formControl.value
                          );
                          tunnelInputTemplateComponent.syncSharedModelData(
                            'uniDirezionaleDirezione',
                            field.formControl.value
                          );
                        } else {
                          field.formControl.parent
                            ?.get(['direzione'])
                            ?.enable();
                        }
                      },
                    },
                  },
                  {
                    className: 'input-line width-20 padding-top-10',
                    key: 'direzione',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      label: 'direzione',
                      change: (field, e) => {
                        tunnelInputTemplateComponent.syncSharedModelData(
                          'uniDirezionaleDirezione',
                          field.formControl.value
                        );
                      },
                    },
                  },
                  {
                    key: 'Scheda fornice n - radio',
                    type: 'radio',
                    className: 'flex-1 remove-underline padding-left-40',
                    defaultValue: '',
                    templateOptions: {
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      key: 'Scheda fornice n - radio',
                      options: [
                        {
                          value: 'Bidirezionale',
                          label: 'Bidirezionale',
                        },
                      ],
                      change: (field, e) => {
                        if (field.formControl.value !== 'Unidirezionale') {
                          field.formControl.parent
                            ?.get(['direzione'])
                            ?.setValue(null);
                          field.formControl.parent
                            ?.get(['direzione'])
                            ?.disable();
                        } else {
                          field.formControl.parent
                            ?.get(['direzione'])
                            ?.enable();
                        }
                        tunnelInputTemplateComponent.syncSharedModelData(
                          'schedaForniceNRadio',
                          field.formControl.value
                        );
                      },
                      onReset: (field, e) => {
                        if (field.formControl.value !== 'Unidirezionale') {
                          field.formControl.parent
                            ?.get(['direzione'])
                            ?.setValue(null);
                          field.formControl.parent
                            ?.get(['direzione'])
                            ?.disable();
                          tunnelInputTemplateComponent.syncSharedModelData(
                            'schedaForniceNRadio',
                            field.formControl.value
                          );
                          tunnelInputTemplateComponent.syncSharedModelData(
                            'uniDirezionaleDirezione',
                            field.formControl.value
                          );
                        } else {
                          field.formControl.parent
                            ?.get(['direzione'])
                            ?.enable();
                        }
                      },
                    },
                  },
                ],
              },
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-1',
                    key: 'Codice IOP',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Codice IOP',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      required: true,
                      change: (field, e) => {
                        tunnelInputTemplateComponent.syncSharedModelData(
                          'codiceIop',
                          e.target.value
                        );
                        tunnelInputTemplateComponent.syncSharedModelDataForList(
                          'codiceIop',
                          e.target.value
                        );
                      },
                    },
                  },
                  {
                    className: 'flex-1',
                    key: 'Nome Ponte Viadotto',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Nome Galleria/paramassi/sottopasso ',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      required: true,
                      change: (field, e) => {
                        tunnelInputTemplateComponent.syncSharedModelData(
                          'nomeGalleria',
                          e.target.value
                        );
                      },
                    },
                  },
                ],
              },
              {
                fieldGroupClassName: 'display-flex grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-2',
                    key: 'Strada di appartenenza',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Strada di appartenenza',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      required: true,
                      change: (field, e) => {
                        tunnelInputTemplateComponent.syncSharedModelData(
                          'stradaDiAppartenenza',
                          e.target.value
                        );
                      },
                    },
                  },
                  {
                    className: 'flex-1',
                    key: 'Progressiva km iniziale',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Progressiva km iniziale',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      required: true,
                      change: (field, e) => {
                        tunnelInputTemplateComponent.syncSharedModelData(
                          'progressivaKmIniziale',
                          e.target.value
                        );
                      },
                    },
                  },
                  {
                    className: 'flex-1',
                    key: 'Progressiva km finale',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Progressiva km finale',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      required: true,
                      change: (field, e) => {
                        tunnelInputTemplateComponent.syncSharedModelData(
                          'progressivaKmFinale',
                          e.target.value
                        );
                      },
                    },
                  },
                ],
              },
              {
                fieldGroupClassName: 'display-flex-wrap grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-1',
                    key: 'Year',
                    type: 'cube-year-picker-type',
                    defaultValue: '',
                    templateOptions: {
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      label: 'Anno',
                      required: true,
                    },
                  },
                  {
                    className: 'flex-1',
                    key: 'quarter',
                    type: 'select',
                    defaultValue: 'T1',
                    templateOptions: {
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      label: 'Ispezioni Programmate',
                      required: true,
                      options: [
                        { value: 'T1', label: 'T1' },
                        { value: 'T2', label: 'T2' },
                        { value: 'T3', label: 'T3' },
                        { value: 'T4', label: 'T4' },
                      ],
                    },
                  },
                ],
              },
              {
                fieldGroupClassName: 'display-flex grid-gap',
                fieldGroup: [
                  {
                    className: 'flex-2',
                    key: 'Codice Opera',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Codice Opera',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      required: true,
                    },
                  },
                ],
              },
              {
                className: 'flex-2',
                fieldGroup: [
                  {
                    key: 'Inspection date',
                    type: 'cube-repeat-section-type',
                    templateOptions: {
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                    },
                    fieldArray: {
                      fieldGroupClassName: 'display-flex grid-gap',
                      fieldGroup: [
                        {
                          className: 'flex-1',
                          key: 'Tecnico Rilevatore',
                          type: 'input',
                          defaultValue: '',
                          templateOptions: {
                            disabled:
                              tunnelInputTemplateComponent.isFieldDisabled(
                                [],
                                isDisabled
                              ),
                            required: true,
                          },
                          expressionProperties: {
                            'templateOptions.label':
                              "'Tecnico Rilevatore n.' + (+field.parent.key + 1)",
                          },
                        },
                        {
                          className: 'flex-1',
                          key: 'Data Ispezione',
                          type: 'cube-datepicker-type',
                          defaultValue: '',
                          templateOptions: {
                            readonly: true,
                            disabled:
                              tunnelInputTemplateComponent.isFieldDisabled(
                                [],
                                isDisabled
                              ),
                            required: true,
                            max: new Date(),
                          },
                          expressionProperties: {
                            'templateOptions.label':
                              "'Data Ispezione n.' + (+field.parent.key + 1)",
                          },
                        },
                      ],
                    },
                  },
                ],
              },
              // Localizzazione
              {
                key: 'Localizzazione',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: { title: 'Localizzazione' },
                  },
                  {
                    fieldGroupClassName: 'display-flex-column',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex-wrap',
                        fieldGroup: [
                          {
                            className: 'flex-1',
                            fieldGroupClassName: 'display-flex-column',
                            fieldGroup: [
                              {
                                type: 'input',
                                defaultValue: '',
                                key: 'Provincia Regione',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  required: true,
                                  label: 'Provincia/Regione',
                                  change: (field, e) => {
                                    tunnelInputTemplateComponent.syncSharedModelData(
                                      'provinciaRegione',
                                      e.target.value
                                    );
                                  },
                                },
                              },
                              {
                                type: 'input',
                                defaultValue: '',
                                key: 'Comune',
                                templateOptions: {
                                  label: 'Comune',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  required: true,
                                  change: (field, e) => {
                                    tunnelInputTemplateComponent.syncSharedModelData(
                                      'comune',
                                      e.target.value
                                    );
                                  },
                                },
                              },
                              {
                                type: 'input',
                                defaultValue: '',
                                key: 'Località',
                                templateOptions: {
                                  label: 'Località',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  required: true,
                                  change: (field, e) => {
                                    tunnelInputTemplateComponent.syncSharedModelData(
                                      'localita',
                                      e.target.value
                                    );
                                  },
                                },
                              },
                            ],
                          },
                          {
                            className: 'flex-2',
                            key: 'Coordinate Geografiche',
                            type: 'cube-block-table-type',
                            templateOptions: {
                              grouptitle: 'Coordinate Geografiche',
                            },
                            fieldGroup: [
                              {
                                key: 'Coordinate Geografiche',
                                type: 'radio',
                                defaultValue: 'ETRF2000',
                                className:
                                  'sub-sec radio-sec text-align-center radio-sec-custom-aligment',
                                templateOptions: {
                                  key: 'Coordinate Geografiche',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  required: true,
                                  options: [
                                    {
                                      value: 'ETRF2000',
                                      label: 'ETRF2000',
                                    },
                                    {
                                      value: 'WGS84',
                                      label: 'WGS84',
                                    },
                                  ],
                                  change: (field, e) => {
                                    tunnelInputTemplateComponent.syncSharedModelData(
                                      'coordinateGeografiche',
                                      field.formControl.value
                                    );
                                  },
                                },
                              },
                              {
                                fieldGroup: [
                                  {
                                    key: 'Iniziale',
                                    wrappers: [
                                      'cube-coordinate-section-row-wrapper',
                                    ],
                                    templateOptions: {
                                      rowTitle: 'Iniziale',
                                    },
                                    fieldGroupClassName: 'display-flex-column',
                                    fieldGroup: [
                                      {
                                        key: 'Quota slm m',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          label: 'Quota s.l.m. [m]',
                                          disabled:
                                            tunnelInputTemplateComponent.isFieldDisabled(
                                              [],
                                              isDisabled
                                            ),
                                          required: true,
                                          change: (field, e) => {
                                            tunnelInputTemplateComponent.syncSharedModelData(
                                              'inizialeQuota',
                                              e.target.value
                                            );
                                          },
                                        },
                                      },
                                      {
                                        fieldGroupClassName:
                                          'display-flex grid-gap',
                                        fieldGroup: [
                                          {
                                            className: 'flex-1',
                                            key: 'Longitudine',
                                            type: 'cube-number-type',
                                            defaultValue: '',
                                            templateOptions: {
                                              label: 'Longitudine',
                                              disabled:
                                                tunnelInputTemplateComponent.isFieldDisabled(
                                                  [],
                                                  isDisabled
                                                ),
                                              required: true,
                                              onChange: (field, e) => {
                                                tunnelInputTemplateComponent.syncSharedModelData(
                                                  'inizialeLongitudine',
                                                  e.target.value
                                                );
                                              },
                                            },
                                          },
                                          {
                                            className: 'flex-1',
                                            key: 'Latitudine',
                                            type: 'cube-number-type',
                                            defaultValue: '',
                                            templateOptions: {
                                              label: 'Latitudine',
                                              required: true,
                                              disabled:
                                                tunnelInputTemplateComponent.isFieldDisabled(
                                                  [],
                                                  isDisabled
                                                ),
                                              onChange: (field, e) => {
                                                tunnelInputTemplateComponent.syncSharedModelData(
                                                  'inizialeLatitudine',
                                                  e.target.value
                                                );
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    key: 'Finale',
                                    wrappers: [
                                      'cube-coordinate-section-row-wrapper',
                                    ],
                                    templateOptions: {
                                      rowTitle: 'Finale',
                                    },
                                    fieldGroupClassName: 'display-flex-column',
                                    fieldGroup: [
                                      {
                                        key: 'Quota slm m',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          label: 'Quota s.l.m. [m]',
                                          required: true,
                                          disabled:
                                            tunnelInputTemplateComponent.isFieldDisabled(
                                              [],
                                              isDisabled
                                            ),
                                          change: (field, e) => {
                                            tunnelInputTemplateComponent.syncSharedModelData(
                                              'finaleQuota',
                                              e.target.value
                                            );
                                          },
                                        },
                                      },
                                      {
                                        fieldGroupClassName:
                                          'display-flex grid-gap',
                                        fieldGroup: [
                                          {
                                            className: 'flex-1',
                                            key: 'Longitudine',
                                            type: 'cube-number-type',
                                            defaultValue: '',
                                            templateOptions: {
                                              label: 'Longitudine',
                                              disabled:
                                                tunnelInputTemplateComponent.isFieldDisabled(
                                                  [],
                                                  isDisabled
                                                ),
                                              required: true,
                                              onChange: (field, e) => {
                                                tunnelInputTemplateComponent.syncSharedModelData(
                                                  'finaleLongitudine',
                                                  e.target.value
                                                );
                                              },
                                            },
                                          },
                                          {
                                            className: 'flex-1',
                                            key: 'Latitudine',
                                            type: 'cube-number-type',
                                            defaultValue: '',
                                            templateOptions: {
                                              label: 'Latitudine',
                                              required: true,
                                              disabled:
                                                tunnelInputTemplateComponent.isFieldDisabled(
                                                  [],
                                                  isDisabled
                                                ),
                                              onChange: (field, e) => {
                                                tunnelInputTemplateComponent.syncSharedModelData(
                                                  'finaleLatitudine',
                                                  e.target.value
                                                );
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      { template: `<hr/>` },
                    ],
                  },
                ],
              },
              // Informazioni generali
              {
                wrappers: ['cube-section-title-wrapper'],
                templateOptions: { title: 'Informazioni generali' },
              },
              {
                key: 'Informazioni generali',
                fieldGroupClassName: 'display-flex',
                fieldGroup: [
                  {
                    className: 'flex-1',
                    fieldGroupClassName: 'display-flex-column',
                    fieldGroup: [
                      {
                        type: 'input',
                        defaultValue: '',
                        key: 'Proprietario',
                        templateOptions: {
                          label: 'Proprietario',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          required: true,
                        },
                      },
                      {
                        type: 'input',
                        defaultValue: '',
                        key: 'Concessionario',
                        templateOptions: {
                          label: 'Concessionario',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          required: true,
                        },
                      },
                      {
                        type: 'input',
                        defaultValue: '',
                        key: 'Ente vigilante',
                        templateOptions: {
                          label: 'Ente vigilante',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          required: true,
                        },
                      },
                    ],
                  },
                  {
                    className: 'flex-1',
                    key: 'Anno di costruzione ristrutturazione',
                    type: 'cube-block-table-type',
                    templateOptions: {
                      grouptitle: 'Anno di costruzione / ristrutturazione',
                    },
                    fieldGroup: [
                      {
                        type: 'cube-block-table-content-type',
                        templateOptions: {
                          rowKeysList: ['year', 'type'],
                        },
                        fieldGroup: [
                          {
                            key: 'Ultimazione costruzione collaudo',
                            templateOptions: {
                              columnName: 'Ultimazione costruzione (collaudo)',
                            },
                            fieldGroup: [
                              {
                                key: 'year',
                                type: 'cube-year-picker-type',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  label: 'Anno',
                                  required: true,
                                },
                              },
                              {
                                key: 'type',
                                fieldGroup: [
                                  {
                                    key: 'Ultimazione costruzione collaudo',
                                    type: 'radio',
                                    defaultValue: '',
                                    className: 'sub-sec radio-sec',
                                    templateOptions: {
                                      key: 'Ultimazione costruzione collaudo',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      required: true,
                                      options: [
                                        {
                                          value: 'Effettivo',
                                          label: 'Effettivo',
                                        },
                                        {
                                          value: 'Presunto',
                                          label: 'Presunto',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            key: 'Eventuale ultimo intervento di manutenzione significativo',
                            templateOptions: {
                              columnName:
                                'Eventuale ultimo intervento di manutenzione significativo†',
                            },
                            fieldGroup: [
                              {
                                key: 'year',
                                type: 'cube-year-picker-type',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  label: 'Anno',
                                  required: true,
                                },
                              },
                              {
                                key: 'type',
                                fieldGroup: [
                                  {
                                    key: 'Eventuale ultimo intervento di manutenzione significativo',
                                    type: 'radio',
                                    defaultValue: '',
                                    className: 'sub-sec radio-sec',
                                    templateOptions: {
                                      key: 'Eventuale ultimo intervento di manutenzione significativo',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      required: true,
                                      options: [
                                        {
                                          value: 'Effettivo',
                                          label: 'Effettivo',
                                        },
                                        {
                                          value: 'Presunto',
                                          label: 'Presunto',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                template: `<div class="notes-font-size"> † Occorre valutare la tipologia dell’intervento e la sua efficacia nel riparare i difetti o i danneggiamenti conseguenti ai fenomeni di degrado. Questo influirà sulla definizione della classe di attenzione dell’opera secondo quanto riportato al §4 delle Linee Guida.</div>`,
              },
              // Dati di progetto
              {
                wrappers: ['cube-section-title-wrapper'],
                templateOptions: { title: 'Dati di progetto' },
              },
              {
                key: 'Dati di progetto',
                fieldGroup: [
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroupClassName: 'display-flex-column',
                        fieldGroup: [
                          {
                            type: 'input',
                            defaultValue: '',
                            key: 'Progettista',
                            templateOptions: {
                              label: 'Progettista',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                          {
                            fieldGroupClassName: 'display-flex grid-gap',
                            fieldGroup: [
                              {
                                className: 'flex-1',
                                type: 'input',
                                defaultValue: '',
                                key: 'Norma di progetto',
                                templateOptions: {
                                  label: 'Norma di progetto',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                },
                              },
                              {
                                className: 'flex-1',
                                key: 'Anno di approvazione',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Anno di approvazione ',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                },
                              },
                            ],
                          },
                          {
                            type: 'input',
                            defaultValue: '',
                            key: 'Ente approvatore',
                            templateOptions: {
                              label: 'Ente approvatore',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-1',
                        key: 'Anno di progettazione',
                        type: 'cube-block-table-type',
                        templateOptions: {
                          grouptitle: 'Anno di progettazione',
                        },
                        fieldGroup: [
                          {
                            type: 'cube-block-table-content-type',
                            templateOptions: {
                              rowKeysList: ['year', 'type'],
                            },
                            fieldGroup: [
                              {
                                key: 'Data inizio',
                                templateOptions: {
                                  columnName: 'Data inizio',
                                },
                                fieldGroup: [
                                  {
                                    key: 'year',
                                    type: 'cube-year-picker-type',
                                    defaultValue: '',
                                    templateOptions: {
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      label: '',
                                      isDeleteVisible: true,
                                    },
                                  },
                                  {
                                    key: 'type',
                                    fieldGroup: [
                                      {
                                        key: 'Data inizio',
                                        type: 'radio',
                                        defaultValue: '',
                                        className: 'sub-sec radio-sec',
                                        templateOptions: {
                                          key: 'Data inizio',
                                          disabled:
                                            tunnelInputTemplateComponent.isFieldDisabled(
                                              [],
                                              isDisabled
                                            ),
                                          options: [
                                            {
                                              value: 'Effettivo',
                                              label: 'Effettivo',
                                            },
                                            {
                                              value: 'Presunto',
                                              label: 'Presunto',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                key: 'Data fine',
                                templateOptions: {
                                  columnName: 'Data fine',
                                },
                                fieldGroup: [
                                  {
                                    key: 'year',
                                    type: 'cube-year-picker-type',
                                    defaultValue: '',
                                    templateOptions: {
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      label: '',
                                      isDeleteVisible: true,
                                    },
                                  },
                                  {
                                    key: 'type',
                                    fieldGroup: [
                                      {
                                        key: 'Data fine',
                                        type: 'radio',
                                        defaultValue: '',
                                        className: 'sub-sec radio-sec',
                                        templateOptions: {
                                          key: 'Data fine',
                                          disabled:
                                            tunnelInputTemplateComponent.isFieldDisabled(
                                              [],
                                              isDisabled
                                            ),
                                          options: [
                                            {
                                              value: 'Effettivo',
                                              label: 'Effettivo',
                                            },
                                            {
                                              value: 'Presunto',
                                              label: 'Presunto',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                key: 'Data approvazione',
                                templateOptions: {
                                  columnName: 'Data approvazione',
                                },
                                fieldGroup: [
                                  {
                                    key: 'year',
                                    type: 'cube-year-picker-type',
                                    defaultValue: '',
                                    templateOptions: {
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      label: '',
                                      isDeleteVisible: true,
                                    },
                                  },
                                  {
                                    key: 'type',
                                    fieldGroup: [
                                      {
                                        key: 'Data approvazione',
                                        type: 'radio',
                                        defaultValue: '',
                                        className: 'sub-sec radio-sec',
                                        templateOptions: {
                                          key: 'Data approvazione',
                                          disabled:
                                            tunnelInputTemplateComponent.isFieldDisabled(
                                              [],
                                              isDisabled
                                            ),
                                          options: [
                                            {
                                              value: 'Effettivo',
                                              label: 'Effettivo',
                                            },
                                            {
                                              value: 'Presunto',
                                              label: 'Presunto',
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              // Tipologia di opera e classificazione d’uso stradale
              {
                wrappers: ['cube-section-title-wrapper'],
                templateOptions: {
                  title: 'Tipologia di opera e classificazione d’uso stradale',
                },
              },
              {
                template: `<div>Tipologia di opera † </div>`,
              },
              {
                key: 'Tipologia di opera e classificazione d uso stradale',
                fieldGroup: [
                  {
                    key: 'Tipologia di opera e classificazione d uso stradale',
                    type: 'radio',
                    defaultValue: '',
                    className: 'sub-sec radio-sec',
                    templateOptions: {
                      key: 'Tipologia di opera e classificazione d uso stradale',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      flex: 'true',
                      options: [
                        {
                          value: 'Galleria',
                          label: 'Galleria',
                        },
                        {
                          value: 'Paramassi',
                          label: 'Paramassi',
                        },
                        {
                          value: 'Sottopasso',
                          label: 'Sottopasso',
                        },
                      ],
                    },
                  },
                  {
                    template: `<div class="notes-font-size"> † Secondo definizione di cui al Glossario.</div>`,
                  },
                  {
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Classificazione d’uso stradale',
                    },
                  },
                  {
                    key: 'Classificazione d uso stradale',
                    fieldGroup: [
                      {
                        type: 'cube-radio-buttons-table-type',
                        defaultValue: '',
                        key: 'Classificazione d uso stradale',
                        className: 'sub-sec radio-sec ',
                        templateOptions: {
                          className:
                            'flex-1 display-flex padding-section border-top-none border-right',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          key: 'Classificazione d uso stradale',
                          flex: 'true',
                          rowLength: 3,
                          columnLength: { 0: [0, 2], 1: [3, 5], 2: [6, 7] },
                          options: [
                            {
                              value: 'Autostrada extraurbana',
                              label: 'Autostrada extraurbana',
                            },
                            {
                              value: 'Autostrada urbana',
                              label: 'Autostrada urbana',
                            },
                            {
                              value: 'Strada extraurbana principale',
                              label: 'Strada extraurbana principale',
                            },
                            {
                              value: 'Strada extraurbana secondaria',
                              label: 'Strada extraurbana secondaria',
                            },
                            {
                              value: 'Strada urbana di scorrimento',
                              label: 'Strada urbana di scorrimento',
                            },
                            {
                              value: 'Strada urbana di quartiere',
                              label: 'Strada urbana di quartiere',
                            },
                            {
                              value: 'Strada locale extraurbana',
                              label: 'Strada locale extraurbana',
                            },
                            {
                              value: 'Strada locale urbana',
                              label: 'Strada locale urbana',
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    key: 'VMAX Progetto',
                    type: 'cube-number-type',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Vₘₐₓ progetto [km/h] ',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                    },
                  },
                  {
                    key: 'CCR Curvature Change Rate',
                    type: 'cube-number-type',
                    defaultValue: '',
                    templateOptions: {
                      label: 'CCR (Curvature Change Rate) [deg/km] ',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                    },
                  },
                ],
              },
              // Aspetti geologici e idrogeologici
              {
                key: 'Aspetti geologici e idrogeologici',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Aspetti geologici e idrogeologici',
                    },
                  },
                  {
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: { title: 'Posizione morfologica' },
                  },
                  {
                    key: 'Posizione morfologica',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            key: 'Galleria di base',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'border-right border-bottom width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Galleria di base',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              name: 'gender',
                              options: [
                                { value: true, label: 'Galleria di base ' },
                              ],
                            },
                          },
                          {
                            key: 'Galleria di base profonda',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'border-bottom width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Galleria di base profonda',
                              name: 'gender',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Galleria di base profonda (> 500 m) ',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            key: 'Galleria parietale',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'border-right border-bottom width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Galleria parietale',
                              name: 'gender',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Galleria parietale',
                                },
                              ],
                            },
                          },
                          {
                            key: 'Galleria parietale - pendio ripido',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'border-bottom width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Galleria parietale - pendio ripido',
                              name: 'gender',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label:
                                    'Galleria parietale - pendio ripido (> 25°)',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            key: 'Galleria di valico',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'border-right border-bottom width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Galleria di valico',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              name: 'gender',
                              options: [
                                {
                                  value: true,
                                  label: 'Galleria di valico',
                                },
                              ],
                            },
                          },
                          {
                            key: 'Galleria superficiale in zona urbana',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'width-100 border-bottom radio-sec flex-1',
                            templateOptions: {
                              key: 'Galleria superficiale in zona urbana',
                              name: 'gender',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Galleria superficiale in zona urbana',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            key: 'Galleria artificiale pianura',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'border-right border-bottom width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Galleria artificiale pianura ',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              name: 'gender',
                              options: [
                                {
                                  value: true,
                                  label: 'Galleria artificiale pianura ',
                                },
                              ],
                            },
                          },
                          {
                            key: 'Galleria artificiale pendio',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'width-100 border-bottom radio-sec flex-1',
                            templateOptions: {
                              key: 'Galleria artificiale pendio',
                              name: 'gender',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Galleria artificiale pendio',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            key: 'Sottopasso pianura ',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'border-right border-bottom width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Sottopasso pianura ',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              name: 'gender',
                              options: [
                                {
                                  value: true,
                                  label: 'Sottopasso pianura ',
                                },
                              ],
                            },
                          },
                          {
                            key: 'Sottopasso pendio',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'width-100 border-bottom radio-sec flex-1',
                            templateOptions: {
                              key: 'Sottopasso pendio',
                              name: 'gender',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Sottopasso pendio',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            key: 'Copertura massima m',
                            className: 'width-50 border-right',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              label: 'Copertura massima [m] ',
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    wrappers: ['cube-repeat-section-wrapper'],
                    templateOptions: {
                      minWidth: '1080px',
                    },
                    fieldGroup: [
                      {
                        fieldGroupClassName:
                          'display-flex repeat-header padding-top-10',
                        fieldGroup: [
                          {
                            className: 'flex-1 border-all',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'da Pk',
                              class: 'text-align-center',
                            },
                          },
                          {
                            className: 'flex-1 border-all',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'a Pk',
                              class: 'text-align-center',
                            },
                          },
                          {
                            className: 'flex-1 border-all',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Copertura [m]',
                              class: 'text-align-center',
                            },
                          },
                        ],
                      },
                      {
                        key: 'da Pk section',
                        type: 'cube-repeat-section-type',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                        fieldArray: {
                          fieldGroupClassName:
                            'display-flex border-left border-bottom',
                          fieldGroup: [
                            {
                              className:
                                'flex-1 border-right repeat-cell remove-underline',
                              key: 'da Pk',
                              type: 'input',
                              defaultValue: '',
                              templateOptions: {
                                disabled:
                                  tunnelInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                              },
                            },
                            {
                              className:
                                'flex-1 border-right repeat-cell remove-underline',
                              key: 'a Pk',
                              type: 'input',
                              defaultValue: '',
                              templateOptions: {
                                disabled:
                                  tunnelInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                              },
                            },
                            {
                              className:
                                'flex-1 border-right repeat-cell remove-underline',
                              key: 'Copertura m',
                              type: 'input',
                              defaultValue: '',
                              templateOptions: {
                                disabled:
                                  tunnelInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: { title: 'Ambiente geologico' },
                  },
                  {
                    key: 'Ambiente geologico',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'main-section',
                        fieldGroup: [
                          {
                            className:
                              'border-right border-bottom width-100 remove-underline',
                            key: 'Montano',
                            type: 'checkbox',
                            defaultValue: false,
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [{ label: 'Montano' }],
                            },
                          },
                          {
                            className:
                              'border-bottom width-100 remove-underline',
                            key: 'Collinare',
                            type: 'checkbox',
                            defaultValue: false,
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [{ label: 'Collinare ' }],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'main-section',
                        fieldGroup: [
                          {
                            className:
                              'border-right border-bottom width-100 remove-underline',
                            key: 'Piana intra montana',
                            type: 'checkbox',
                            defaultValue: false,
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  label: 'Piana intra montana',
                                },
                              ],
                            },
                          },
                          {
                            className:
                              'border-bottom width-100 remove-underline',
                            key: 'Piana costiera',
                            type: 'checkbox',
                            defaultValue: false,
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [{ label: 'Piana costiera' }],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'main-section',
                        fieldGroup: [
                          {
                            className:
                              'border-right width-100 remove-underline',
                            key: 'Piana fluviale e lacustre',
                            type: 'checkbox',
                            defaultValue: false,
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [{ label: 'Piana fluviale e lacustre' }],
                            },
                          },
                          {
                            className: 'width-100 remove-underline',
                            key: 'Area vulcanica',
                            type: 'checkbox',
                            defaultValue: false,
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  label: 'Area vulcanica',
                                },
                              ],
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: { title: 'Dati disponibili' },
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'width-100 flex-1 margin-auto',
                        template: `<p>Profilo geologico di progetto</p>`,
                      },
                      {
                        key: 'Profilo geologico di progetto',
                        type: 'radio',
                        defaultValue: '',
                        className: 'sub-sec radio-sec flex-3',
                        templateOptions: {
                          key: 'Profilo geologico di progetto',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          flex: true,
                          options: [
                            { value: 'SÌ', label: 'SÌ' },
                            { value: 'NO', label: 'NO' },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Condizioni di pericolosità geologica',
                    },
                  },
                  {
                    key: 'Condizioni di pericolosità geologica',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 border-bottom border-right padding-top-10',
                            template: `<p>Accelerazione attesa al bedrock</p>`,
                          },
                          {
                            key: 'Accelerazione attesa al bedrock',
                            type: 'radio',
                            defaultValue: '',
                            className:
                              'sub-sec radio-sec border-bottom flex-1 border-right',
                            templateOptions: {
                              key: 'Accelerazione attesa al bedrock',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                { value: 'G', label: 'G' },
                                { value: 'm/s2', label: 'm/s2' },
                              ],
                            },
                          },
                          {
                            className:
                              'width-100 input-line flex-2 border-bottom remove-underline padding-left-10',
                            key: 'Accelerazione attesa al bedrock input',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 border-bottom border-right padding-top-10',
                            template: `<p>Presenza di faglie capaci</p>`,
                          },
                          {
                            key: 'Presenza di faglie capaci',
                            type: 'radio',
                            defaultValue: '',
                            className:
                              'sub-sec radio-sec border-bottom flex-1 border-right',
                            templateOptions: {
                              key: 'Presenza di faglie capaci',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                { value: 'SI', label: 'SI' },
                                { value: 'NO', label: 'NO' },
                              ],
                            },
                          },
                          {
                            className:
                              'flex-1 border-bottom border-right padding-left-10',
                            template: `<p>Distanza in km</p>`,
                          },
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline',
                            key: 'Distanza in km',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 border-bottom border-right padding-top-10',
                            template: `<p>Presenza di instabilità di versante</p>`,
                          },
                          {
                            key: 'Presenza di instabilità di versante',
                            type: 'radio',
                            defaultValue: '',
                            className:
                              'sub-sec radio-sec border-bottom flex-1 border-right',
                            templateOptions: {
                              key: 'Presenza di instabilità di versante',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                { value: 'SI', label: 'SI' },
                                { value: 'NO', label: 'NO' },
                              ],
                            },
                          },
                          {
                            className:
                              'flex-1 border-right border-bottom padding-left-10',
                            template: `<p>Descrizione</p>`,
                          },
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline',
                            key: 'Descrizione',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 border-bottom border-right padding-top-10',
                            template: `<p>Presenza di rocce amiantifere</p>`,
                          },
                          {
                            key: 'Presenza di rocce amiantifere',
                            type: 'radio',
                            defaultValue: '',
                            className:
                              'sub-sec radio-sec border-bottom flex-1 border-right',
                            templateOptions: {
                              key: 'Presenza di rocce amiantifere',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                { value: 'SI', label: 'SI' },
                                { value: 'NO', label: 'NO' },
                              ],
                            },
                          },
                          {
                            className:
                              'flex-1 border-right border-bottom padding-left-10',
                            template: `<p>Progressive</p>`,
                          },
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline',
                            key: 'Progressive',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Condizioni idrogeologiche',
                    },
                  },
                  {
                    key: 'Condizioni idrogeologiche',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'main-section',
                        fieldGroup: [
                          {
                            className:
                              'border-right border-bottom width-100 remove-underline',
                            key: 'Falda assente',
                            type: 'checkbox',
                            defaultValue: false,
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [{ label: 'Falda assente' }],
                            },
                          },
                          {
                            className:
                              'border-bottom width-100 remove-underline',
                            key: 'Falda presente',
                            type: 'checkbox',
                            defaultValue: false,
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [{ label: 'Falda presente ' }],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'main-section',
                        fieldGroup: [
                          {
                            className:
                              'border-right border-bottom width-100 remove-underline',
                            key: 'Circolazione in rete',
                            type: 'checkbox',
                            defaultValue: false,
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  label: 'Circolazione in rete',
                                },
                              ],
                            },
                          },
                          {
                            className:
                              'border-bottom width-100 remove-underline',
                            key: 'Ammasso a bassa permeabilità',
                            type: 'checkbox',
                            defaultValue: false,
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                { label: 'Ammasso a bassa permeabilità' },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 border-bottom border-right padding-top-10',
                            template: `<p>Galleria integralmente sotto falda</p>`,
                          },
                          {
                            key: 'Galleria integralmente sotto falda',
                            type: 'radio',
                            defaultValue: '',
                            className:
                              'sub-sec radio-sec border-bottom flex-1 border-right',
                            templateOptions: {
                              key: 'Galleria integralmente sotto falda',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                { value: 'SI', label: 'SI' },
                                { value: 'NO', label: 'NO' },
                              ],
                            },
                          },
                          {
                            className: 'flex-1 border-right border-bottom',
                            template: `<p>Altezza battente acqua † [m]</p>`,
                          },
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline',
                            key: 'Altezza battente acqua m',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 border-bottom border-right padding-top-10',
                            template: `<p>Galleria parzialmente sotto falda</p>`,
                          },
                          {
                            key: 'Galleria parzialmente sotto falda',
                            type: 'radio',
                            defaultValue: '',
                            className:
                              'sub-sec radio-sec border-bottom flex-1 border-right',
                            templateOptions: {
                              key: 'Galleria parzialmente sotto falda',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                { value: 'SI', label: 'SI' },
                                { value: 'NO', label: 'NO' },
                              ],
                            },
                          },
                          {
                            className: 'flex-1 border-right border-bottom',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: '',
                            },
                          },
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline',
                            key: 'Galleria parzialmente sotto falda - text',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex-wrap border-bottom',
                        fieldGroup: [
                          {
                            className: 'flex-2',
                            fieldGroup: [
                              {
                                key: 'Dato non disponibile',
                                type: 'checkbox',
                                defaultValue: false,
                                className: 'width-100 radio-sec',
                                templateOptions: {
                                  key: 'Dato non disponibile',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  options: [
                                    {
                                      value: true,
                                      label: 'Dato non disponibile',
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            className: 'flex-6',
                            fieldGroup: [
                              {
                                className: 'sub-sec',
                                type: 'input',
                                defaultValue: '',
                                key: 'Dato non disponibile Input',
                                templateOptions: {
                                  label: '',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    wrappers: ['cube-repeat-section-wrapper'],
                    templateOptions: {
                      minWidth: '1080px',
                    },
                    fieldGroup: [
                      {
                        fieldGroupClassName:
                          'display-flex repeat-header padding-top-10',
                        fieldGroup: [
                          {
                            className: 'flex-1 border-all',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'da Pk',
                              class: 'text-align-center',
                            },
                          },
                          {
                            className: 'flex-1 border-all',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'a Pk',
                              class: 'text-align-center',
                            },
                          },
                          {
                            className: 'flex-1 border-all',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Altezza battente acqua †¹ [m]',
                              class: 'text-align-center',
                            },
                          },
                        ],
                      },
                      {
                        key: 'da Pk table section',
                        type: 'cube-repeat-section-type',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                        fieldArray: {
                          fieldGroupClassName:
                            'display-flex border-left border-bottom',
                          fieldGroup: [
                            {
                              className:
                                'flex-1 border-right repeat-cell remove-underline',
                              key: 'da Pk',
                              type: 'input',
                              defaultValue: '',
                              templateOptions: {
                                disabled:
                                  tunnelInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                              },
                            },
                            {
                              className:
                                'flex-1 border-right repeat-cell remove-underline',
                              key: 'a Pk',
                              type: 'input',
                              defaultValue: '',
                              templateOptions: {
                                disabled:
                                  tunnelInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                              },
                            },
                            {
                              className:
                                'flex-1 border-right repeat-cell remove-underline',
                              key: 'Altezza battente acqua 1 m',
                              type: 'input',
                              defaultValue: '',
                              templateOptions: {
                                disabled:
                                  tunnelInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    key: 'Acque potabili - section',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 border-bottom border-right padding-top-10',
                            template: `<p>Acque potabili</p>`,
                          },
                          {
                            key: 'Acque potabili',
                            type: 'radio',
                            defaultValue: '',
                            className:
                              'sub-sec radio-sec border-bottom flex-1 border-right',
                            templateOptions: {
                              key: 'Acque potabili',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                { value: 'SI', label: 'SI' },
                                { value: 'NO', label: 'NO' },
                              ],
                            },
                          },
                          {
                            className:
                              'flex-1 border-right border-bottom padding-left-10',
                            template: `<p>Note: </p>`,
                          },
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline',
                            key: 'Note - 1',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 border-bottom border-right padding-top-10',
                            template: `<p>Acque aggressive †²</p>`,
                          },
                          {
                            key: 'Acque aggressive',
                            type: 'radio',
                            defaultValue: '',
                            className:
                              'sub-sec radio-sec border-bottom flex-1 border-right',
                            templateOptions: {
                              key: 'Acque aggressive',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                { value: 'SI', label: 'SI' },
                                { value: 'NO', label: 'NO' },
                              ],
                            },
                          },
                          {
                            className:
                              'flex-1 border-right border-bottom padding-left-10',
                            template: `<p>Note: </p>`,
                          },
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline',
                            key: 'Note - 2',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 border-bottom border-right padding-top-10',
                            template: `<p>Acque carbonatiche</p>`,
                          },
                          {
                            key: 'Acque carbonatiche',
                            type: 'radio',
                            defaultValue: '',
                            className:
                              'sub-sec radio-sec border-bottom flex-1 border-right',
                            templateOptions: {
                              key: 'Acque carbonatiche',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                { value: 'SI', label: 'SI' },
                                { value: 'NO', label: 'NO' },
                              ],
                            },
                          },
                          {
                            className:
                              'flex-1 border-right border-bottom padding-left-10',
                            template: `<p>Note : </p>`,
                          },
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline',
                            key: 'Note - 3',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className: 'flex-1 border-bottom border-right',
                            template: `<p>Altre tipologie di acqua</p>`,
                          },
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom border-right remove-underline',
                            key: 'Altre tipologie di acqua',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                          {
                            className:
                              'flex-1 border-right border-bottom padding-left-10',
                            template: `<p>Note : </p>`,
                          },
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline',
                            key: 'Note - 4',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    template: `<div class="notes-font-size"> †¹ Altezza del battente d’acqua misurato rispetto al piano viabile.</div>
                      <div class="notes-font-size"> †² Secondo definizione di cui al Glossario.</div>`,
                  },
                  {
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Presenza di gas',
                    },
                  },
                  {
                    key: 'Presenza di gas',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 border-bottom border-right padding-top-10',
                            template: `<p>Presenza metano (CH₄)</p>`,
                          },
                          {
                            key: 'Presenza metano',
                            type: 'radio',
                            defaultValue: '',
                            className:
                              'sub-sec radio-sec border-bottom flex-1 border-right',
                            templateOptions: {
                              key: 'Presenza metano',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                { value: 'SI', label: 'SI' },
                                { value: 'NO', label: 'NO' },
                              ],
                            },
                          },
                          {
                            className:
                              'flex-1 border-right border-bottom padding-left-10',
                            template: `<p>Note : </p>`,
                          },
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline',
                            key: 'Presenza metano - Note',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 border-bottom border-right padding-top-10',
                            template: `<p>Presenza altri gas</p>`,
                          },
                          {
                            key: 'Presenza altri gas',
                            type: 'radio',
                            defaultValue: '',
                            className:
                              'sub-sec radio-sec border-bottom flex-1 border-right',
                            templateOptions: {
                              key: 'Presenza altri gas',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                { value: 'SI', label: 'SI' },
                                { value: 'NO', label: 'NO' },
                              ],
                            },
                          },
                          {
                            className:
                              'flex-1 border-right border-bottom padding-left-10',
                            template: `<p>Note : </p>`,
                          },
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline',
                            key: 'Presenza altri gas - Note',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex border-bottom',
                        fieldGroup: [
                          {
                            className: 'flex-1 margin-auto',
                            template: `<p>Dato non disponibile</p>`,
                          },
                          {
                            key: 'Dato non disponibile',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec flex-3 margin-auto',
                            templateOptions: {
                              key: 'Dato non disponibile',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [{ value: 'Checked', label: 'Checked' }],
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Temperatura',
                    },
                  },
                  {
                    key: 'Temperatura',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 border-bottom border-right padding-top-10',
                            template: `<p>Presenza di temperature elevate</p>`,
                          },
                          {
                            key: 'Presenza di temperature elevate',
                            type: 'radio',
                            defaultValue: '',
                            className:
                              'sub-sec radio-sec border-bottom flex-1 border-right',
                            templateOptions: {
                              key: 'Presenza di temperature elevate',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                { value: 'SI', label: 'SI' },
                                { value: 'NO', label: 'NO' },
                              ],
                            },
                          },
                          {
                            className:
                              'flex-1 border-right border-bottom padding-left-10',
                            template: `<p>Note : </p>`,
                          },
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline',
                            key: 'Presenza di temperature elevate - Note',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              // Caratteristiche dell’ammasso attraversato
              {
                key: 'Caratteristiche dell’ammasso attraversato',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Caratteristiche dell’ammasso attraversato',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'width-100 flex-1 margin-auto',
                        template: `<p>Assetto lito-strutturale</p>`,
                      },
                      {
                        key: 'Assetto lito-strutturale',
                        type: 'radio',
                        defaultValue: '',
                        className: 'sub-sec radio-sec flex-3',
                        templateOptions: {
                          key: 'Assetto lito-strutturale',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          flex: true,
                          options: [
                            { value: 'Semplice', label: 'Semplice' },
                            { value: 'Complesso', label: 'Complesso' },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    key: 'Formazioni sciolte prevalentemente granulari - section',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'main-section',
                        fieldGroup: [
                          {
                            className:
                              'border-right border-bottom width-100 remove-underline',
                            key: 'Formazioni sciolte prevalentemente granulari',
                            type: 'checkbox',
                            defaultValue: false,
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  label:
                                    'A) Formazioni sciolte prevalentemente granulari',
                                },
                              ],
                            },
                          },
                          {
                            className:
                              'border-bottom width-100 remove-underline',
                            key: 'Formazioni sciolte prevalentemente coesive',
                            type: 'checkbox',
                            defaultValue: false,
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  label:
                                    'B) Formazioni sciolte prevalentemente coesive',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'main-section',
                        fieldGroup: [
                          {
                            className:
                              'border-right border-bottom width-100 remove-underline',
                            key: 'Formazioni lapidee omogenee',
                            type: 'checkbox',
                            defaultValue: false,
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  label: 'C) Formazioni lapidee omogenee',
                                },
                              ],
                            },
                          },
                          {
                            className:
                              'border-bottom width-100 remove-underline',
                            key: 'Formazioni litologicamente complesse',
                            type: 'checkbox',
                            defaultValue: false,
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  label:
                                    'D) Formazioni litologicamente complesse',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'main-section',
                        fieldGroup: [
                          {
                            className: 'border-right width-50 remove-underline',
                            key: 'Formazioni litologicamente e strutturalmente complesse',
                            type: 'checkbox',
                            defaultValue: false,
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  label:
                                    'E) Formazioni litologicamente e strutturalmente complesse',
                                },
                              ],
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title:
                        'Disponibilità di dati di caratterizzazione geotecnica e geomeccanica',
                    },
                  },
                  {
                    key: 'Disponibilità di dati di caratterizzazione geotecnica e geomeccanica',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 border-bottom border-right padding-top-10',
                            template: `<p>Presenza di monitoraggio geotecnico interno galleria</p>`,
                          },
                          {
                            key: 'Presenza di monitoraggio geotecnico interno galleria',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec border-bottom flex-1',
                            templateOptions: {
                              key: 'Presenza di monitoraggio geotecnico interno galleria',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                { value: 'SI', label: 'SI' },
                                { value: 'NO', label: 'NO' },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 border-bottom border-right padding-top-10',
                            template: `<p>Presenza di monitoraggio geotecnico versante e imbocchi</p>`,
                          },
                          {
                            key: 'Presenza di monitoraggio geotecnico versante e imbocchi',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec border-bottom flex-1',
                            templateOptions: {
                              key: 'Presenza di monitoraggio geotecnico versante e imbocchi',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                { value: 'SI', label: 'SI' },
                                { value: 'NO', label: 'NO' },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 border-bottom border-right padding-top-10',
                            template: `<p>Presenza di monitoraggio idrogeologico</p>`,
                          },
                          {
                            key: 'Presenza di monitoraggio idrogeologico',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec border-bottom flex-1',
                            templateOptions: {
                              key: 'Presenza di monitoraggio idrogeologico',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                { value: 'SI', label: 'SI' },
                                { value: 'NO', label: 'NO' },
                              ],
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              // Caratteristiche strutturali
              {
                key: 'Caratteristiche strutturali',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Caratteristiche strutturali',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title: 'Tipo di galleria',
                        },
                      },
                      {
                        key: 'Tipo di galleria',
                        className: 'flex-2',
                        fieldGroupClassName: 'display-flex-wrap  grid-gap',
                        fieldGroup: [
                          {
                            fieldGroup: [
                              {
                                className: 'width-100 remove-underline',
                                key: 'Naturale',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  options: [{ label: 'Naturale' }],
                                  change: (field, e) => {
                                    tunnelInputTemplateComponent.syncSharedModelData(
                                      'tipoDiGalleriaNaturale',
                                      field.formControl.value
                                    );
                                  },
                                },
                              },
                              {
                                className: 'width-100 remove-underline',
                                key: 'Naturale artificiale',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  options: [
                                    {
                                      label: 'Naturale / artificiale',
                                    },
                                  ],
                                  change: (field, e) => {
                                    tunnelInputTemplateComponent.syncSharedModelData(
                                      'tipoDiGalleriaNatureArtificiale',
                                      field.formControl.value
                                    );
                                  },
                                },
                              },
                            ],
                          },
                          {
                            fieldGroup: [
                              {
                                className: 'width-100 remove-underline',
                                key: 'Artificiale',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  options: [{ label: 'Artificiale ' }],
                                  change: (field, e) => {
                                    tunnelInputTemplateComponent.syncSharedModelData(
                                      'tipoDiGalleriaArtificiale',
                                      field.formControl.value
                                    );
                                  },
                                },
                              },
                              {
                                className: 'width-100 remove-underline',
                                key: 'presenza di finestrature',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  options: [
                                    { label: 'Presenza di finestrature' },
                                  ],
                                  change: (field, e) => {
                                    tunnelInputTemplateComponent.syncSharedModelData(
                                      'tipoDiGalleriaPresenzaDiFinestrature',
                                      field.formControl.value
                                    );
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex padding-top-10',
                    fieldGroup: [
                      {
                        className: 'flex-1 border-right',
                        fieldGroup: [
                          {
                            key: 'Sviluppo galleria naturale',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Sviluppo galleria naturale[m]',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              onChange: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'sviluppoGalleriaNaturale',
                                  e.target.value
                                );
                              },
                            },
                          },
                          {
                            key: 'Sviluppo tratti finestrati',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Sviluppo tratti finestrati[m]',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              onChange: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'sviluppoTrattiFinestrati',
                                  e.target.value
                                );
                              },
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            key: 'Sviluppo galleria artificiale',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Sviluppo galleria artificiale[m]',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              onChange: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'sviluppoGalleriaArtificiale',
                                  e.target.value
                                );
                              },
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Pregalleria †',
                    },
                  },
                  {
                    key: 'Pregalleria',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 border-bottom border-right padding-top-10',
                            template: `<p>Imbocco</p>`,
                          },
                          {
                            key: 'Imbocco',
                            type: 'radio',
                            defaultValue: '',
                            className:
                              'sub-sec radio-sec border-bottom flex-1 border-right',
                            templateOptions: {
                              key: 'Imbocco',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                { value: 'SÌ', label: 'SÌ' },
                                { value: 'NO', label: 'NO' },
                              ],
                              change: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'imbocco',
                                  field.formControl.value
                                );
                              },
                            },
                          },
                          {
                            className:
                              'flex-1 border-right border-bottom padding-left-10',
                            template: `<p>Lunghezza [m]</p>`,
                          },
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline',
                            key: 'Lunghezza m',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              onChange: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'imboccoLunghezza',
                                  e.target.value
                                );
                              },
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 border-bottom border-right padding-top-10',
                            template: `<p>Sbocco</p>`,
                          },
                          {
                            key: 'Sbocco',
                            type: 'radio',
                            defaultValue: '',
                            className:
                              'sub-sec radio-sec border-bottom flex-1 border-right',
                            templateOptions: {
                              key: 'Sbocco',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                { value: 'SÌ', label: 'SÌ' },
                                { value: 'NO', label: 'NO' },
                              ],
                              change: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'sbocco',
                                  field.formControl.value
                                );
                              },
                            },
                          },
                          {
                            className:
                              'flex-1 border-right border-bottom padding-left-10',
                            template: `<p>Lunghezza [m]</p>`,
                          },
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline',
                            key: 'Lunghezza m - Note',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              onChange: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'sboccoLunghezza',
                                  e.target.value
                                );
                              },
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    template: `<div class="notes-font-size"> † Secondo definizione di cui al Glossario.</div>`,
                  },
                ],
              },
              // Infrastrutture potenzialmente interferenti
              {
                key: 'Infrastrutture potenzialmente interferenti',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Infrastrutture potenzialmente interferenti',
                    },
                  },
                  {
                    key: 'Infrastrutture potenzialmente interferent - checkbox',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'main-section',
                        fieldGroup: [
                          {
                            className: 'width-100 remove-underline',
                            key: 'Aree urbanizzate',
                            type: 'checkbox',
                            defaultValue: false,
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [{ label: 'Aree urbanizzate' }],
                            },
                          },
                          {
                            className: 'width-100 remove-underline',
                            key: 'Costruzioni rilevanti',
                            type: 'checkbox',
                            defaultValue: false,
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [{ label: 'Costruzioni rilevanti' }],
                            },
                          },
                          {
                            className: 'width-100 remove-underline',
                            key: 'Infrastrutture di trasporto',
                            type: 'checkbox',
                            defaultValue: false,
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  label:
                                    'Infrastrutture di trasporto (es. strade, ferrovie, ecc.)',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'main-section',
                        fieldGroup: [
                          {
                            className: 'width-100 remove-underline',
                            key: 'Infrastrutture idrauliche',
                            type: 'checkbox',
                            defaultValue: false,
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  label:
                                    'Infrastrutture idrauliche (es. canali, acquedotti, ecc.)',
                                },
                              ],
                            },
                          },
                          {
                            className: 'width-100 remove-underline',
                            key: 'Infrastrutture per trasporto energia',
                            type: 'checkbox',
                            defaultValue: false,
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  label: 'Infrastrutture per trasporto energia',
                                },
                              ],
                            },
                          },
                          {
                            className: 'width-100 remove-underline',
                            key: 'Acquedotti oleodotti gasdotti',
                            type: 'checkbox',
                            defaultValue: false,
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                { label: 'Acquedotti / oleodotti / gasdotti' },
                              ],
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    className:
                      'width-100 input-line flex-1 border-bottom remove-underline',
                    key: 'Note',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Note : ',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                    },
                  },
                ],
              },
              // Caratteristiche geometriche
              {
                key: 'Caratteristiche geometriche',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Caratteristiche geometriche',
                    },
                  },
                  {
                    key: 'Dimensioni',
                    fieldGroup: [
                      {
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title: 'Dimensioni',
                        },
                      },
                      {
                        fieldGroupClassName: 'display-flex grid-gap',
                        fieldGroup: [
                          {
                            className: 'flex-1 padding-left-10',
                            key: 'Lunghezza totale',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Lunghezza totale[m] ',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              min: 0,
                            },
                          },
                          {
                            className: 'flex-1 border-left padding-left-10',
                            key: 'Larghezza tra piedritti',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Larghezza tra piedritti † [m] ',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              min: 0,
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex grid-gap',
                        fieldGroup: [
                          {
                            className: 'flex-1 padding-left-10',
                            key: 'Lunghezza totale con eventuale becco di flauto',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label:
                                'Lunghezza totale con eventuale becco di flauto [m] ',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                          {
                            className: 'flex-1 border-left padding-left-10',
                            key: 'Larghezza piattaforma',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Larghezza piattaforma [m] ',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex grid-gap',
                        fieldGroup: [
                          {
                            className: 'flex-1 padding-left-10',
                            key: 'Altezza dell intradosso al centro della piattaforma',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label:
                                'Altezza dell’intradosso al centro della piattaforma [m] ',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                          {
                            className: 'flex-1 border-left padding-left-10',
                            key: 'Area sezione libera',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Area sezione libera [m²] ',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              min: 0,
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex grid-gap',
                        fieldGroup: [
                          {
                            className: 'flex-1',
                            fieldGroup: [
                              {
                                fieldGroupClassName: 'display-flex',
                                fieldGroup: [
                                  {
                                    className:
                                      'flex-1 padding-left-10 font-weight-bolder',
                                    template: `<p>Profilo longitudinale</p>`,
                                  },
                                  {
                                    key: 'Profilo longitudinale',
                                    type: 'radio',
                                    defaultValue: '',
                                    className: 'sub-sec radio-sec flex-1',
                                    templateOptions: {
                                      key: 'Profilo longitudinale',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      flex: true,
                                      options: [
                                        {
                                          value:
                                            'singola livelletta (pendenza unica)',
                                          label:
                                            'singola livelletta (pendenza unica)',
                                        },
                                        {
                                          value:
                                            'più livellette (variazioni di pendenza)',
                                          label:
                                            'più livellette (variazioni di pendenza)',
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex grid-gap',
                        fieldGroup: [
                          {
                            className: 'flex-1 padding-left-10',
                            key: 'Pendenza longitudinale massima',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Pendenza longitudinale massima [%] ',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                          {
                            className: 'flex-1 border-left padding-left-10',
                            key: 'Pendenza longitudinale media',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Pendenza longitudinale media †² [%] ',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              min: 0,
                            },
                          },
                        ],
                      },
                      {
                        template: `<div class="notes-font-size"> † Misurata a quota 1 m dal piano stradale </div>
                                  <div class="notes-font-size">  †² Convenzione: “+” salita, “-“ discesa.</div>`,
                      },
                    ],
                  },
                  {
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Deformazioni di sagoma della sezione',
                    },
                  },
                  {
                    key: 'Deformazioni di sagoma della sezione',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 border-bottom border-right padding-top-10',
                            template: `<p>Deformazioni stabili †</p>`,
                          },
                          {
                            key: 'Deformazioni stabili',
                            type: 'radio',
                            defaultValue: '',
                            className:
                              'sub-sec radio-sec border-bottom flex-1 border-right',
                            templateOptions: {
                              key: 'Deformazioni stabili',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                { value: 'SI', label: 'SI' },
                                { value: 'NO', label: 'NO' },
                              ],
                            },
                          },
                          {
                            className:
                              'flex-1 border-right border-bottom padding-left-10',
                            template: `<p>Note : </p>`,
                          },
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline',
                            key: 'note',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 border-bottom border-right padding-top-10',
                            template: `<p>Deformazioni evolutive †²</p>`,
                          },
                          {
                            key: 'Deformazioni evolutive',
                            type: 'radio',
                            defaultValue: '',
                            className:
                              'sub-sec radio-sec border-bottom flex-1 border-right',
                            templateOptions: {
                              key: 'Deformazioni evolutive',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                { value: 'SI', label: 'SI' },
                                { value: 'NO', label: 'NO' },
                              ],
                            },
                          },
                          {
                            className:
                              'flex-1 border-right border-bottom padding-left-10',
                            template: `<p>Note : </p>`,
                          },
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline',
                            key: 'Deformazioni evolutive - Note',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    template: `<div class="notes-font-size"> † Deformazioni stabili, di origine nota e che non hanno manifestato evoluzione nel tempo (es. difetti in fase realizzativa dell’opera).</div>
                              <div class="notes-font-size"> †² Deformazioni evolutive, di origine nota o non nota, che hanno manifestato variazioni nel tempo (es. deformazioni dovute ad alterazioni progressive dello stato di sollecitazione sul rivestimento della galleria).</div>`,
                  },
                ],
              },
              // Tipologia di forma della sezione
              {
                fieldGroupClassName: 'border-bottom',
                key: 'Tipologia di forma della sezione',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Tipologia di forma della sezione',
                    },
                  },
                  {
                    key: 'Tipologia di forma della sezione',
                    fieldGroup: [
                      {
                        key: 'Tipologia di forma della sezione - radio',
                        type: 'cube-radio-buttons-table-type',
                        defaultValue: '',
                        className: 'sub-sec radio-sec',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          key: 'Tipologia di forma della sezione - radio',
                          flex: 'true',
                          rowLength: 1,
                          columnLength: { 0: [0, 3] },
                          options: [
                            {
                              value: 'Policentrica',
                              label: 'Policentrica ',
                            },
                            {
                              value: 'Circolare (scavo TBM)',
                              label: 'Circolare (scavo TBM)',
                            },
                            {
                              value: 'Ferro di cavallo',
                              label: 'Ferro di cavallo',
                            },
                            {
                              value: 'Rettangolare',
                              label: 'Rettangolare',
                            },
                          ],
                        },
                      },
                      {
                        key: 'Altro specificare - radio',
                        className: 'flex-2 remove-underline ',
                        fieldGroupClassName: 'display-flex',
                        hooks: {
                          onInit: (field) => {
                            setTimeout(() => {
                              if (
                                field.formControl.get([
                                  'Altro specificare - radio',
                                ]).value !== 'Altro specificare'
                              ) {
                                field.formControl
                                  .get(['Altro specificare text'])
                                  ?.setValue(null);
                                field.formControl
                                  .get(['Altro specificare text'])
                                  ?.disable();
                              } else {
                                field.formControl
                                  .get(['Altro specificare text'])
                                  ?.enable();
                              }
                            }, 0);
                          },
                        },
                        fieldGroup: [
                          {
                            className: 'radio-sec padding-bottom-10',
                            type: 'radio',
                            key: 'Altro specificare - radio',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              key: 'Altro specificare - radio',
                              isTextLeftAlign: true,
                              className: 'flex-1',
                              options: [
                                {
                                  value: 'Altro specificare',
                                  label: 'Altro specificare',
                                },
                              ],
                              change: (field) => {
                                if (
                                  field.formControl.value !==
                                  'Altro specificare'
                                ) {
                                  field.formControl.parent
                                    ?.get(['Altro specificare text'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Altro specificare text'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Altro specificare text'])
                                    ?.enable();
                                }
                              },
                              onReset: (field) => {
                                if (
                                  field.formControl.value !==
                                  'Altro specificare'
                                ) {
                                  field.formControl.parent
                                    ?.get(['Altro specificare text'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Altro specificare text'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Altro specificare text'])
                                    ?.enable();
                                }
                              },
                            },
                          },
                          {
                            className:
                              'width-50 padding-left-35 padding-10 input-line',
                            key: 'Altro specificare text',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              label: '',
                            },
                          },
                        ],
                      },
                      {
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex',
                            fieldGroup: [
                              {
                                className:
                                  'flex-1 border-bottom border-right padding-top-10',
                                template: `<p>Arco rovescio</p>`,
                              },
                              {
                                key: 'Arco rovescio',
                                type: 'radio',
                                defaultValue: '',
                                className:
                                  'sub-sec radio-sec border-bottom flex-1 border-right',
                                templateOptions: {
                                  key: 'Arco rovescio',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                              {
                                className:
                                  'flex-1 border-right border-bottom padding-left-10',
                                template: `<p>spessore [m]</p>`,
                              },
                              {
                                className:
                                  'width-100 input-line flex-1 border-bottom remove-underline',
                                key: 'spessore m',
                                type: 'cube-number-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: '',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                },
                              },
                            ],
                          },
                          {
                            fieldGroupClassName: 'display-flex',
                            fieldGroup: [
                              {
                                className:
                                  'flex-1 border-bottom border-right padding-top-10',
                                template: `<p>Telaio chiuso</p>`,
                              },
                              {
                                key: 'Telaio chiuso',
                                type: 'radio',
                                defaultValue: '',
                                className:
                                  'sub-sec radio-sec border-bottom flex-1 border-right',
                                templateOptions: {
                                  key: 'Telaio chiuso',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                              // Empty field for design purpose
                              {
                                className:
                                  'flex-2 border-bottom padding-left-10',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              // Tipologia e caratteristiche del rivestimento
              {
                key: 'Tipologia e caratteristiche del rivestimento',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Tipologia e caratteristiche del rivestimento',
                    },
                  },
                  {
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Rivestimento di prima fase',
                    },
                  },
                  {
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex-wrap',
                        fieldGroup: [
                          {
                            className: 'flex-1 border-bottom border-right',
                            template: `<p>Rivestimento di prima fase</p>`,
                          },
                          {
                            key: 'Rivestimento di prima fase',
                            type: 'radio',
                            defaultValue: '',
                            className:
                              'sub-sec radio-sec border-bottom flex-1 border-right',
                            templateOptions: {
                              key: 'Rivestimento di prima fase',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                { value: 'SI', label: 'SI' },
                                { value: 'NO', label: 'NO' },
                              ],
                            },
                          },
                          {
                            className: 'flex-1 border-right border-bottom',
                            template: `<p class="padding-left-10">spessore [m]</p>`,
                          },
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline',
                            key: 'spessore m',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                      {
                        className: 'display-flex-column',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex-wrap',
                            fieldGroup: [
                              {
                                className:
                                  'border-right flex-1 remove-underline border-bottom',
                                key: 'Muratura',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  options: [
                                    {
                                      label: 'Muratura',
                                    },
                                  ],
                                },
                              },
                              {
                                className:
                                  'border-right flex-1 remove-underline border-bottom',
                                key: 'Calcestruzzo non armato',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  options: [
                                    {
                                      label: 'Calcestruzzo non armato',
                                    },
                                  ],
                                },
                              },
                              {
                                className:
                                  'border-right flex-1 remove-underline border-bottom',
                                key: 'Calcestruzzo proiettato',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  options: [
                                    {
                                      label: 'Calcestruzzo proiettato',
                                    },
                                  ],
                                },
                              },
                              {
                                className:
                                  'flex-1 remove-underline border-bottom',
                                key: 'Legno',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  options: [
                                    {
                                      label: 'Legno',
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            fieldGroupClassName: 'display-flex-wrap',
                            fieldGroup: [
                              {
                                className:
                                  'width-100 border-bottom remove-underline flex-1',
                                key: 'Altro',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  options: [
                                    {
                                      label: 'Altro',
                                    },
                                  ],
                                },
                              },
                              {
                                className:
                                  'border-bottom width-100 input flex-3',
                                key: 'Altro-text',
                                type: 'input',
                                defaultValue: '',
                              },
                            ],
                          },
                          {
                            fieldGroup: [
                              {
                                fieldGroupClassName: 'display-flex',
                                fieldGroup: [
                                  {
                                    className:
                                      'flex-1 border-bottom border-right padding-top-10',
                                    template: `<p>Rete metallica</p>`,
                                  },
                                  {
                                    key: 'Rete metallica',
                                    type: 'radio',
                                    defaultValue: '',
                                    className:
                                      'sub-sec radio-sec border-bottom flex-1 border-right',
                                    templateOptions: {
                                      key: 'Rete metallica',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      flex: true,
                                      options: [
                                        { value: 'SI', label: 'SI' },
                                        { value: 'NO', label: 'NO' },
                                      ],
                                    },
                                  },
                                  {
                                    className:
                                      'flex-1 border-right border-bottom padding-left-10',
                                    template: `<p>Diametro [mm]</p>`,
                                  },
                                  {
                                    className:
                                      'width-100 input-line flex-1 border-bottom remove-underline',
                                    key: 'Diametro',
                                    type: 'cube-number-type',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: '',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName: 'display-flex',
                                fieldGroup: [
                                  {
                                    className:
                                      'flex-1 border-bottom border-right',
                                    template: `<p>Dimensioni maglie</p>`,
                                  },
                                  {
                                    className:
                                      'flex-1 border-right border-bottom padding-left-10',
                                    template: `<p>larghezza [mm]</p>`,
                                  },
                                  {
                                    className:
                                      'width-100 input-line flex-1 border-right border-bottom remove-underline',
                                    key: 'larghezza - text',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: '',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                  {
                                    className:
                                      'flex-1 border-right border-bottom padding-left-10',
                                    template: `<p>altezza [mm]</p>`,
                                  },
                                  {
                                    className:
                                      'width-100 input-line flex-1 border-bottom remove-underline',
                                    key: 'altezza',
                                    type: 'cube-number-type',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: '',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName: 'display-flex',
                                fieldGroup: [
                                  {
                                    className:
                                      'flex-1 border-bottom border-right padding-top-10 padding-top-10',
                                    template: `<p>Fibre metalliche </p>`,
                                  },
                                  {
                                    key: 'Fibre metalliche',
                                    type: 'radio',
                                    defaultValue: '',
                                    className:
                                      'sub-sec radio-sec border-bottom flex-1 border-right',
                                    templateOptions: {
                                      key: 'Fibre metalliche',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      flex: true,
                                      options: [
                                        { value: 'SI', label: 'SI' },
                                        { value: 'NO', label: 'NO' },
                                      ],
                                    },
                                  },
                                  {
                                    className:
                                      'flex-1 border-right border-bottom padding-left-10',
                                    template: `<p>Caratteristiche</p>`,
                                  },
                                  {
                                    className:
                                      'width-100 input-line flex-1 border-bottom remove-underline',
                                    key: 'Caratteristiche',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: '',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName: 'display-flex',
                                fieldGroup: [
                                  {
                                    className:
                                      'flex-1 border-bottom border-right padding-top-10',
                                    template: `<p>Pre-sostegno †</p>`,
                                  },
                                  {
                                    key: 'Pre-sostegno',
                                    type: 'radio',
                                    defaultValue: '',
                                    className:
                                      'sub-sec radio-sec border-bottom flex-1 border-right',
                                    templateOptions: {
                                      key: 'Pre-sostegno',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      flex: true,
                                      options: [
                                        { value: 'SI', label: 'SI' },
                                        { value: 'NO', label: 'NO' },
                                      ],
                                    },
                                  },
                                  {
                                    className:
                                      'flex-1 border-right border-bottom padding-left-10',
                                    template: `<p>tipologia</p>`,
                                  },
                                  {
                                    className:
                                      'width-100 input-line flex-1 border-bottom remove-underline',
                                    key: 'tipologia - text 1',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: '',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName: 'display-flex',
                                fieldGroup: [
                                  {
                                    className:
                                      'flex-1 border-bottom border-right padding-top-10',
                                    template: `<p>Consolidamenti al fronteAcque aggressive †²</p>`,
                                  },
                                  {
                                    key: 'Consolidamenti al fronte',
                                    type: 'radio',
                                    defaultValue: '',
                                    className:
                                      'sub-sec radio-sec border-bottom flex-1 border-right',
                                    templateOptions: {
                                      key: 'Consolidamenti al fronte',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      flex: true,
                                      options: [
                                        { value: 'SI', label: 'SI' },
                                        { value: 'NO', label: 'NO' },
                                      ],
                                    },
                                  },
                                  {
                                    className:
                                      'flex-1 border-right border-bottom padding-left-10',
                                    template: `<p>tipologia</p>`,
                                  },
                                  {
                                    className:
                                      'width-100 input-line flex-1 border-bottom remove-underline',
                                    key: 'tipologia - text 2',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: '',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName: 'display-flex',
                                fieldGroup: [
                                  {
                                    className:
                                      'flex-1 border-bottom border-right padding-top-10',
                                    template: `<p>Miglioramento roccia †³</p>`,
                                  },
                                  {
                                    key: 'Miglioramento roccia',
                                    type: 'radio',
                                    defaultValue: '',
                                    className:
                                      'sub-sec radio-sec border-bottom flex-1 border-right',
                                    templateOptions: {
                                      key: 'Miglioramento roccia',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      flex: true,
                                      options: [
                                        { value: 'SI', label: 'SI' },
                                        { value: 'NO', label: 'NO' },
                                      ],
                                    },
                                  },
                                  {
                                    className:
                                      'flex-1 border-right border-bottom padding-left-10',
                                    template: `<p>tipologia</p>`,
                                  },
                                  {
                                    className:
                                      'width-100 input-line flex-1 border-bottom remove-underline',
                                    key: 'tipologia - text 3',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: '',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName: 'display-flex',
                                fieldGroup: [
                                  {
                                    className:
                                      'flex-1 border-bottom border-right padding-top-10',
                                    template: `<p>Bullonature radiali</p>`,
                                  },
                                  {
                                    key: 'Bullonature radiali',
                                    type: 'radio',
                                    defaultValue: '',
                                    className:
                                      'sub-sec radio-sec border-bottom flex-1 border-right',
                                    templateOptions: {
                                      key: 'Bullonature radiali',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      flex: true,
                                      options: [
                                        { value: 'SI', label: 'SI' },
                                        { value: 'NO', label: 'NO' },
                                      ],
                                    },
                                  },
                                  {
                                    className:
                                      'flex-1 border-right border-bottom padding-left-10',
                                    template: `<p>Tipologia di bulloni</p>`,
                                  },
                                  {
                                    className:
                                      'width-100 input-line flex-1 border-bottom remove-underline',
                                    key: 'Tipologia di bulloni',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: '',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                className: 'flex-1',
                                template: `<p> Descrizione del rivestimento di prima fase (dove variabile lungo lo sviluppo della galleria)<p>`,
                              },
                            ],
                          },
                          {
                            wrappers: ['cube-repeat-section-wrapper'],
                            templateOptions: {
                              minWidth: '1080px',
                            },
                            fieldGroup: [
                              {
                                fieldGroupClassName:
                                  'display-flex repeat-header padding-top-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1 border-all',
                                    wrappers: ['cube-sub-title-wrapper'],
                                    templateOptions: {
                                      title: 'da Pk',
                                      class: 'text-align-center',
                                    },
                                  },
                                  {
                                    className: 'flex-1 border-all',
                                    wrappers: ['cube-sub-title-wrapper'],
                                    templateOptions: {
                                      title: 'a Pk',
                                      class: 'text-align-center',
                                    },
                                  },
                                  {
                                    className: 'flex-1 border-all',
                                    wrappers: ['cube-sub-title-wrapper'],
                                    templateOptions: {
                                      title: 'Descrizione tipo di rivestimento',
                                      class: 'text-align-center',
                                    },
                                  },
                                  {
                                    className: 'flex-1 border-all',
                                    wrappers: ['cube-sub-title-wrapper'],
                                    templateOptions: {
                                      title:
                                        'Tipologia di ammasso attraversato',
                                      class: 'text-align-center',
                                    },
                                  },
                                ],
                              },
                              {
                                key: 'Rivestimento di prima fase table section',
                                type: 'cube-repeat-section-type',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                },
                                fieldArray: {
                                  fieldGroupClassName:
                                    'display-flex border-left border-bottom',
                                  fieldGroup: [
                                    {
                                      className:
                                        'flex-1 border-right repeat-cell remove-underline',
                                      key: 'da Pk',
                                      type: 'cube-number-type',
                                      defaultValue: '',
                                      templateOptions: {
                                        disabled:
                                          tunnelInputTemplateComponent.isFieldDisabled(
                                            [],
                                            isDisabled
                                          ),
                                      },
                                    },
                                    {
                                      className:
                                        'flex-1 border-right repeat-cell remove-underline',
                                      key: 'a Pk',
                                      type: 'cube-number-type',
                                      defaultValue: '',
                                      templateOptions: {
                                        disabled:
                                          tunnelInputTemplateComponent.isFieldDisabled(
                                            [],
                                            isDisabled
                                          ),
                                      },
                                    },
                                    {
                                      className:
                                        'flex-1 border-right repeat-cell remove-underline',
                                      key: 'Descrizione tipo di rivestimento',
                                      type: 'input',
                                      defaultValue: '',
                                      templateOptions: {
                                        disabled:
                                          tunnelInputTemplateComponent.isFieldDisabled(
                                            [],
                                            isDisabled
                                          ),
                                      },
                                    },
                                    {
                                      className:
                                        'flex-1 border-right repeat-cell remove-underline',
                                      key: 'Tipologia di ammasso attraversato',
                                      type: 'input',
                                      defaultValue: '',
                                      templateOptions: {
                                        disabled:
                                          tunnelInputTemplateComponent.isFieldDisabled(
                                            [],
                                            isDisabled
                                          ),
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            template: `<div class="notes-font-size"> † Indicare l’eventuale adozione di sistemi di pre-sostegno al contorno dello scavo e la relativa tipologia (es. ombrello di infilaggi in avanzamento, coronelle in VTR, archi di colonne di jet grouting etc.).</div>
                                      <div class="notes-font-size"> †² Indicare l’eventuale adozione di sistemi di consolidamento del fronte di scavo e la relativa tipologia.</div>
                                      <div class="notes-font-size border-bottom"> †³ Indicare l’eventuale adozione di interventi di miglioramento della roccia che possano averne modificato le caratteristiche e la relativa tipologia di intervento (es. iniezioni per permeazione).</div>
                                `,
                          },
                          {
                            key: 'Rivestimento definitivo',
                            fieldGroup: [
                              {
                                fieldGroupClassName: 'display-flex grid-gap',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    wrappers: ['cube-sub-title-wrapper'],
                                    templateOptions: {
                                      title: 'Rivestimento definitivo',
                                    },
                                  },
                                  {
                                    className: 'flex-1',
                                    fieldGroupClassName:
                                      'display-flex grid-gap',
                                    fieldGroup: [
                                      {
                                        className: 'flex-1',
                                        wrappers: ['cube-sub-title-wrapper'],
                                        templateOptions: {
                                          title: 'spessore [m]',
                                        },
                                      },
                                      {
                                        className: 'flex-1',
                                        key: 'spessore m',
                                        type: 'cube-number-type',
                                        defaultValue: '',
                                        templateOptions: {
                                          label: '',
                                          disabled:
                                            tunnelInputTemplateComponent.isFieldDisabled(
                                              [],
                                              isDisabled
                                            ),
                                          onChange: (field, e) => {
                                            tunnelInputTemplateComponent.syncSharedModelData(
                                              'spessore',
                                              e.target.value
                                            );
                                          },
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex-wrap border-top border-left border-bottom border-right',
                                fieldGroup: [
                                  {
                                    key: 'Calcestruzzo armato',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className: 'radio-sec flex-1 border-right',
                                    templateOptions: {
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      options: [
                                        {
                                          value: true,
                                          label: 'Calcestruzzo armato',
                                        },
                                      ],
                                      change: (field, e) => {
                                        tunnelInputTemplateComponent.syncSharedModelData(
                                          'calcestruzzoArmato',
                                          field.formControl.value
                                        );
                                      },
                                    },
                                  },
                                  {
                                    key: 'Calcestruzzo non armato',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className: 'radio-sec flex-1 border-right',
                                    templateOptions: {
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      options: [
                                        {
                                          value: true,
                                          label: 'Calcestruzzo non armato',
                                        },
                                      ],
                                      change: (field, e) => {
                                        tunnelInputTemplateComponent.syncSharedModelData(
                                          'calcestruzzoNonArmato',
                                          field.formControl.value
                                        );
                                      },
                                    },
                                  },
                                  {
                                    key: 'Calcestruzzo proiettato',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className: 'radio-sec flex-1 border-right',
                                    templateOptions: {
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      options: [
                                        {
                                          value: true,
                                          label: 'Calcestruzzo proiettato',
                                        },
                                      ],
                                      change: (field, e) => {
                                        tunnelInputTemplateComponent.syncSharedModelData(
                                          'calcestruzzoProiettato',
                                          field.formControl.value
                                        );
                                      },
                                    },
                                  },
                                  {
                                    key: 'Muratura',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className: 'radio-sec flex-1',
                                    templateOptions: {
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      options: [
                                        {
                                          value: true,
                                          label: 'Muratura',
                                        },
                                      ],
                                      change: (field, e) => {
                                        tunnelInputTemplateComponent.syncSharedModelData(
                                          'muratura',
                                          field.formControl.value
                                        );
                                      },
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex-wrap border-left border-bottom border-right',
                                fieldGroup: [
                                  {
                                    key: 'Legno',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className: 'radio-sec flex-1 border-right',
                                    templateOptions: {
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      options: [
                                        {
                                          value: true,
                                          label: 'Legno',
                                        },
                                      ],
                                      change: (field, e) => {
                                        tunnelInputTemplateComponent.syncSharedModelData(
                                          'legno',
                                          field.formControl.value
                                        );
                                      },
                                    },
                                  },
                                  {
                                    key: 'Conci prefabbricati',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className: 'radio-sec flex-1 border-right',
                                    templateOptions: {
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      options: [
                                        {
                                          value: true,
                                          label: 'Conci prefabbricati',
                                        },
                                      ],
                                      change: (field, e) => {
                                        tunnelInputTemplateComponent.syncSharedModelData(
                                          'conciPrefabbricati',
                                          field.formControl.value
                                        );
                                      },
                                    },
                                  },
                                  {
                                    key: 'Altro',
                                    type: 'checkbox',
                                    defaultValue: false,
                                    className: 'radio-sec flex-1 border-right',
                                    templateOptions: {
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      options: [
                                        {
                                          value: true,
                                          label: 'Altro',
                                        },
                                      ],
                                      change: (field) => {
                                        if (field.formControl.value) {
                                          field.formControl.parent
                                            .get('Altro text')
                                            .reset();
                                          field.formControl.parent
                                            .get('Altro text')
                                            .enable();
                                        } else {
                                          field.formControl.parent
                                            .get('Altro text')
                                            .reset();
                                          field.formControl.parent
                                            .get('Altro text')
                                            .disable();
                                        }

                                        tunnelInputTemplateComponent.syncSharedModelData(
                                          'altro',
                                          field.formControl.value
                                        );
                                      },
                                      hooks: {
                                        onInit: (field) => {
                                          if (field.formControl.value) {
                                            field.formControl.parent
                                              .get('Altro text')
                                              .reset();
                                            field.formControl.parent
                                              .get('Altro text')
                                              .enable();
                                          } else {
                                            field.formControl.parent
                                              .get('Altro text')
                                              .reset();
                                            field.formControl.parent
                                              .get('Altro text')
                                              .disable();
                                          }
                                        },
                                      },
                                    },
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'Altro text',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: '',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      change: (field, e) => {
                                        tunnelInputTemplateComponent.syncSharedModelData(
                                          'altroText',
                                          e.target.value
                                        );
                                      },
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex  border-left border-bottom border-right',
                                key: 'Armatura',
                                fieldGroup: [
                                  {
                                    className:
                                      'flex-1 border-right padding-top-10 padding-left-10',
                                    template: `<p>Armatura<p>`,
                                  },
                                  {
                                    key: 'radio',
                                    type: 'radio',
                                    defaultValue: '',
                                    className:
                                      'sub-sec radio-sec flex-2 border-right',
                                    templateOptions: {
                                      key: 'radio',
                                      flex: true,
                                      options: [
                                        { value: 'SI', label: 'SI' },
                                        { value: 'NO', label: 'NO' },
                                      ],
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      change: (field, e) => {
                                        tunnelInputTemplateComponent.syncSharedModelData(
                                          'armatura',
                                          field.formControl.value
                                        );
                                      },
                                    },
                                  },
                                  {
                                    className:
                                      'flex-1 border-right padding-left-10',
                                    template: `<p>Diametro [mm]<p>`,
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'Diametro mm',
                                    type: 'cube-number-type',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: '',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      onChange: (field, e) => {
                                        tunnelInputTemplateComponent.syncSharedModelData(
                                          'diametro',
                                          e.target.value
                                        );
                                      },
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex  border-left border-bottom border-right',
                                fieldGroup: [
                                  {
                                    className:
                                      'flex-1 border-right padding-left-10',
                                    template: `<p>Copriferro [mm]<p>`,
                                  },
                                  {
                                    className: 'flex-2',
                                    key: 'Copriferro mm',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: '',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      change: (field, e) => {
                                        tunnelInputTemplateComponent.syncSharedModelData(
                                          'copriferro',
                                          e.target.value
                                        );
                                      },
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex  border-left border-bottom border-right',
                                key: 'Fibre metalliche',
                                fieldGroup: [
                                  {
                                    className:
                                      'flex-1 border-right padding-left-10',
                                    template: `<p>Fibre metalliche<p>`,
                                  },
                                  {
                                    key: 'Fibre metalliche radio',
                                    type: 'radio',
                                    defaultValue: '',
                                    className:
                                      'sub-sec radio-sec flex-2 border-right',
                                    templateOptions: {
                                      key: 'Fibre metalliche radio',
                                      flex: true,
                                      options: [
                                        { value: 'SI', label: 'SI' },
                                        { value: 'NO', label: 'NO' },
                                      ],
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      change: (field, e) => {
                                        tunnelInputTemplateComponent.syncSharedModelData(
                                          'fibreMetallicheRadio',
                                          field.formControl.value
                                        );
                                      },
                                    },
                                  },
                                  {
                                    className:
                                      'flex-1 border-right padding-left-10',
                                    template: `<p>Caratteristiche<p>`,
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'Caratteristiche',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: '',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      change: (field, e) => {
                                        tunnelInputTemplateComponent.syncSharedModelData(
                                          'caratteristiche',
                                          e.target.value
                                        );
                                      },
                                    },
                                  },
                                ],
                              },
                              {
                                className:
                                  'display-flex  border-left border-bottom border-right padding-10 background-color-grey font-color-black',
                                template: `<div>In caso di utilizzo dii conci prefabbricati:</div>`,
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex  border-left border-bottom border-right',
                                key: 'In caso di utilizzo dii conci prefabbricati',
                                fieldGroup: [
                                  {
                                    className: 'flex-2 border-right',
                                    key: 'Numero di conci',
                                    type: 'cube-number-type',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Numero di conci',
                                      required: true,
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      min: 1,
                                      onChange: (field, e) => {
                                        tunnelInputTemplateComponent.syncSharedModelData(
                                          'numeroDiConci',
                                          e.target.value
                                        );
                                      },
                                      onBlur: (field, e) => {
                                        const concioNo =
                                          field.formControl.value;
                                        const conciolist =
                                          tunnelInputTemplateComponent.model[
                                            TunnelConstants.levels.l0
                                          ]?.opereparzialilist?.[0]
                                            ?.modelstructure?.conciolist;

                                        // If concios have already been added
                                        if (conciolist?.length) {
                                          const previousConcioNo =
                                            tunnelInputTemplateComponent
                                              .concioNoList.length;

                                          // If concio no has been decreased
                                          if (concioNo < previousConcioNo) {
                                            // Finding highest concio number which has already been added
                                            const conciNosList = conciolist.map(
                                              (concio) =>
                                                concio[TunnelConstants.partNo]
                                            );
                                            const highestConciNo = Math.max(
                                              ...conciNosList
                                            );

                                            if (concioNo < highestConciNo) {
                                              // If new concio no is less than the highest concio number
                                              tunnelInputTemplateComponent.getConfirmation(
                                                {
                                                  confirmButtonText: 'Ok',
                                                  isCancelButtonVisible: false,
                                                  message: `Details for concio no. ${highestConciNo} have already been added. Numero di conci cannot be less than ${highestConciNo}.`,
                                                  title: 'Info',
                                                }
                                              );

                                              // Reverting the concio number to previous concio number
                                              tunnelInputTemplateComponent.syncSharedModelData(
                                                'numeroDiConci',
                                                previousConcioNo
                                              );

                                              return;
                                            }
                                          }
                                        }

                                        // Setting concio no list
                                        tunnelInputTemplateComponent.concioNoList =
                                          Array(concioNo)
                                            .fill(0)
                                            .map((x, i) => ({
                                              value: i + 1,
                                              label: i + 1,
                                            }));
                                      },
                                    },
                                  },
                                  {
                                    className:
                                      'flex-1 border-right padding-left-10',
                                    template: `<p>geometria conci<p>`,
                                  },
                                  {
                                    className: 'flex-2',
                                    key: 'geometria conci',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: '',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      change: (field, e) => {
                                        tunnelInputTemplateComponent.syncSharedModelData(
                                          'geometriaConci',
                                          e.target.value
                                        );
                                      },
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex  border-left border-bottom border-right',
                                key: 'In caso di utilizzo dii conci prefabbricati',
                                fieldGroup: [
                                  {
                                    className:
                                      'flex-1 border-right padding-left-10',
                                    template: `<p>Tipologia delle guarnizioni (gasket)<p>`,
                                  },
                                  {
                                    className: 'flex-2 border-right',
                                    key: 'Tipologia delle guarnizioni (gasket)',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: '',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      change: (field, e) => {
                                        tunnelInputTemplateComponent.syncSharedModelData(
                                          'tipologiaDelleGuarnizioniGasket',
                                          e.target.value
                                        );
                                      },
                                    },
                                  },
                                ],
                              },
                              {
                                className: 'flex-1 padding-top-30',
                                wrappers: ['cube-sub-title-wrapper'],
                                templateOptions: {
                                  title:
                                    'Finitura rivestimento interno (es. pannelli metallici, onduline, rivestimento ceramico, ecc.)',
                                },
                              },
                              {
                                key: 'Rivestimento interno',
                                fieldGroupClassName:
                                  'display-flex  border-left border-top border-bottom border-right',
                                fieldGroup: [
                                  {
                                    className:
                                      'flex-1 border-right padding-left-10 padding-top-10',
                                    template: `<p>Rivestimento interno<p>`,
                                  },
                                  {
                                    key: 'Rivestimento interno',
                                    type: 'radio',
                                    defaultValue: '',
                                    className:
                                      'sub-sec radio-sec flex-2 border-right',
                                    templateOptions: {
                                      key: 'Rivestimento interno',
                                      flex: true,
                                      options: [
                                        { value: 'SI', label: 'SI' },
                                        { value: 'NO', label: 'NO' },
                                      ],
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                  {
                                    className:
                                      'flex-1 border-right padding-left-10',
                                    template: `<p>Tipo<p>`,
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'Tipo',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: '',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex  border-left border-top border-bottom border-right',
                                fieldGroup: [
                                  {
                                    className:
                                      'flex-1 border-right padding-left-10',
                                    template: `<p>Modalità di ancoraggio al rivestimento definitivo<p>`,
                                  },
                                  {
                                    className: 'flex-3',
                                    key: 'Modalità di ancoraggio al rivestimento definitivo',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: '',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                key: 'Sviluppo',
                                fieldGroupClassName:
                                  'display-flex  border-left border-top border-bottom border-right',
                                fieldGroup: [
                                  {
                                    className:
                                      'flex-1 border-right padding-left-10 padding-top-10',
                                    template: `<p>Sviluppo<p>`,
                                  },
                                  {
                                    key: 'Sviluppo',
                                    type: 'radio',
                                    defaultValue: '',
                                    className:
                                      'sub-sec radio-sec flex-2 border-right',
                                    templateOptions: {
                                      key: 'Sviluppo',
                                      flex: true,
                                      options: [
                                        {
                                          value: 'integrale †',
                                          label: 'integrale †',
                                        },
                                        {
                                          value: 'parziale',
                                          label: 'parziale',
                                        },
                                      ],
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                  {
                                    className:
                                      'flex-1 border-right padding-left-10',
                                    template: `<p>Sviluppo [m]<p>`,
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'Sviluppo m',
                                    type: 'cube-number-type',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: '',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                key: 'Altezza (dal piano viabile)',
                                fieldGroupClassName:
                                  'display-flex  border-left border-top border-bottom border-right',
                                fieldGroup: [
                                  {
                                    className:
                                      'flex-1 padding-top-10 border-right padding-left-10',
                                    template: `<p>Altezza (dal piano viabile)<p>`,
                                  },
                                  {
                                    key: 'Altezza radio',
                                    type: 'radio',
                                    defaultValue: '',
                                    className:
                                      'sub-sec radio-sec flex-2 border-right',
                                    templateOptions: {
                                      key: 'Altezza radio',
                                      flex: true,
                                      options: [
                                        {
                                          value: 'integrale*²',
                                          label: 'integrale †²',
                                        },
                                        {
                                          value: 'parziale',
                                          label: 'parziale',
                                        },
                                      ],
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                  {
                                    className:
                                      'flex-1 border-right padding-left-10 ',
                                    template: `<p>altezza [m]<p>`,
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'altezza m',
                                    type: 'cube-number-type',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: '',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                template: `<div class="notes-font-size"> † per l’intero sviluppo della galleria.</div>
                                          <div class="notes-font-size"> †² per l’intero sviluppo dell’intradosso del rivestimento della galleria.</div>`,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              // Drenaggio
              {
                key: 'Drenaggio',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Drenaggio',
                    },
                  },
                  {
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Drenaggio del rivestimento',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        key: 'Tipologia di drenaggio',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Tipologia di drenaggio',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                      {
                        className: 'flex-1 border-left',
                        key: 'Tipologia ancoraggi',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Tipologia ancoraggi',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-top-10 border-bottom',
                        template: `<p>Sistema di misura delle acque drenate</p>`,
                      },
                      {
                        key: 'Sistema di misura delle acque drenate',
                        type: 'radio',
                        defaultValue: '',
                        className:
                          'sub-sec radio-sec border-bottom flex-1 border-right',
                        templateOptions: {
                          key: 'Sistema di misura delle acque drenate',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          flex: true,
                          options: [
                            { value: 'SI', label: 'SI' },
                            { value: 'NO', label: 'NO' },
                          ],
                        },
                      },
                      {
                        className:
                          'input-line flex-2 border-bottom remove-underline',
                        key: 'Quantità d’acqua drenata',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Quantità d’acqua drenata [m³/s]',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-top-10 border-bottom',
                        template: `<p>Modalità di drenaggio</p>`,
                      },
                      {
                        key: 'Modalità di drenaggio',
                        type: 'radio',
                        defaultValue: '',
                        className:
                          'sub-sec radio-sec border-bottom flex-1 border-right',
                        templateOptions: {
                          key: 'Modalità di drenaggio',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          flex: true,
                          options: [
                            { value: 'a gravità', label: 'a gravità' },
                            { value: 'per pompaggio', label: 'per pompaggio' },
                            { value: 'misto', label: 'misto' },
                          ],
                        },
                      },
                      {
                        className:
                          'input-line flex-2 border-bottom remove-underline',
                        key: 'note',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Note',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    template: `<div class="padding-top-10">Canalette di scolo </div>`,
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        key: 'diametro',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'diametro[mm]',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                      {
                        className: 'flex-1 border-left padding-left-10',
                        key: 'pendenza[%]',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'pendenza',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        key: 'numero pozzetti',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'numero pozzetti',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                      {
                        className: 'flex-1 border-left padding-left-10',
                        key: 'distanza tra pozzetti',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'distanza tra pozzetti[m]',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Impermeabilizzazione',
                    },
                  },
                  {
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 padding-top-10 border-bottom border-right',
                            template: `<p>Impermeabilizzazione</p>`,
                          },
                          {
                            key: 'Impermeabilizzazione',
                            type: 'radio',
                            defaultValue: '',
                            className:
                              'sub-sec radio-sec border-bottom flex-1 border-right',
                            templateOptions: {
                              key: 'Impermeabilizzazione',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                { value: 'SI', label: 'SI' },
                                { value: 'NO', label: 'NO' },
                              ],
                            },
                          },
                          // Empty field for design purpose
                          {
                            className: 'flex-2 border-bottom padding-left-10',
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 padding-top-10 border-bottom border-right',
                            template: `<p>Tipo</p>`,
                          },
                          {
                            key: 'Tipo',
                            type: 'radio',
                            defaultValue: '',
                            className:
                              'sub-sec radio-sec border-bottom flex-1 border-right',
                            templateOptions: {
                              key: 'Tipo',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                { value: 'parziale', label: 'parziale' },
                                { value: 'integrale', label: 'integrale' },
                              ],
                            },
                          },
                          {
                            className:
                              'flex-1 border-right border-bottom padding-left-10',
                            template: `<p>materiale</p>`,
                          },
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline',
                            key: 'materiale',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 padding-top-10 border-bottom border-right',
                            template: `<p>Tipo di telo</p>`,
                          },
                          {
                            key: 'Tipo di telo',
                            type: 'radio',
                            defaultValue: '',
                            className:
                              'sub-sec radio-sec border-bottom flex-1 border-right',
                            templateOptions: {
                              key: 'Tipo di telo',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                {
                                  value: 'singolo strato',
                                  label: 'singolo strato',
                                },
                                {
                                  value: 'multi-strato',
                                  label: 'multi-strato',
                                },
                              ],
                            },
                          },
                          {
                            className:
                              'flex-1 border-right border-bottom padding-left-10',
                            template: `<p>spessore [m]</p>`,
                          },
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline',
                            key: 'spessore m',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                      {
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title: 'Drenaggio di piattaforma',
                        },
                      },
                      {
                        key: 'Drenaggio di piattaforma',
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className: 'flex-1 border-bottom border-right',
                            template: `<p>Tipo caditoia</p>`,
                          },
                          {
                            key: 'puntuale',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'border-right border-bottom width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'puntuale',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              name: 'gender',
                              options: [{ value: true, label: 'puntuale' }],
                            },
                          },
                          {
                            className:
                              'flex-1 border-right border-bottom padding-left-10',
                            template: `<p>Interdistanza [m]</p>`,
                          },
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline border-right',
                            key: 'Interdistanza m',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                          {
                            className:
                              'flex-1 border-right border-bottom padding-left-10',
                            template: `<p>Superficie drenante [m2] †</p>`,
                          },
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline',
                            key: 'Superficie drenante m2',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          // Empty field for design purpose
                          {
                            className:
                              'flex-1 border-bottom padding-left-10 border-right',
                          },
                          {
                            key: 'fessura continua',
                            type: 'checkbox',
                            defaultValue: false,
                            className:
                              'border-right border-bottom width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'fessura continua',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              name: 'gender',
                              options: [
                                { value: true, label: 'fessura continua' },
                              ],
                            },
                          },
                          {
                            className:
                              'flex-1 border-right border-bottom padding-left-10',
                            template: `<p>altezza fessura [cm]</p>`,
                          },
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline',
                            key: 'altezza fessura cm',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                          // Empty field for design purpose
                          {
                            className: 'flex-1 border-bottom padding-left-10',
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 padding-top-10 border-bottom border-right',
                            template: `<p>Sistema tagliafiamma (pozzetti sifonati)</p>`,
                          },
                          {
                            key: 'Sistema tagliafiamma pozzetti sifonati',
                            type: 'radio',
                            defaultValue: '',
                            className:
                              'sub-sec radio-sec border-bottom flex-1 border-right',
                            templateOptions: {
                              key: 'Sistema tagliafiamma pozzetti sifonati',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                { value: 'SI', label: 'SI' },
                                { value: 'NO', label: 'NO' },
                              ],
                            },
                          },
                          {
                            className:
                              'flex-1 border-right border-bottom padding-left-10',
                            template: `<p>Note</p>`,
                          },
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline',
                            key: 'Note',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 padding-top-10 border-bottom border-right',
                            template: `<p>Sistema di raccolta dei liquidi pericolosi distinto dalle acque meteoriche</p>`,
                          },
                          {
                            key: 'Sistema di raccolta dei liquidi pericolosi distinto dalle acque meteoriche',
                            type: 'radio',
                            defaultValue: '',
                            className:
                              'sub-sec radio-sec border-bottom flex-1 padding-left-10',
                            templateOptions: {
                              key: 'Sistema di raccolta dei liquidi pericolosi distinto dalle acque meteoriche',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                { value: 'SI', label: 'SI' },
                                { value: 'NO', label: 'NO' },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline border-right',
                            key: 'Numero di vasche di raccolta',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Numero di vasche di raccolta',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline padding-left-10',
                            key: 'Capacità complessiva m',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Capacità complessiva[m³]',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                      {
                        template: `<div class="notes-font-size"> † Per singolo pozzetto</div>`,
                      },
                    ],
                  },
                ],
              },
              // Pavimentazione stradale in galleria
              {
                key: 'Pavimentazione stradale in galleria',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Pavimentazione stradale in galleria',
                    },
                  },
                  {
                    template: `<p>Pavimentazione stradale </p>`,
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        key: 'Calcestruzzo',
                        type: 'checkbox',
                        defaultValue: false,
                        className:
                          'width-100 radio-sec border-bottom flex-1 border-right',
                        templateOptions: {
                          key: 'Calcestruzzo',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          options: [
                            {
                              value: true,
                              label: 'Calcestruzzo',
                            },
                          ],
                        },
                      },
                      {
                        key: 'Conglomerato bituminoso',
                        type: 'checkbox',
                        defaultValue: false,
                        className:
                          'width-100 radio-sec border-bottom flex-1 border-right',
                        templateOptions: {
                          key: 'Conglomerato bituminoso',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          options: [
                            {
                              value: true,
                              label: 'Conglomerato bituminoso',
                            },
                          ],
                        },
                      },
                      {
                        key: 'c b intasato SMA',
                        type: 'checkbox',
                        defaultValue: false,
                        className:
                          'width-100 radio-sec border-bottom flex-1 border-right',
                        templateOptions: {
                          key: 'c b intasato SMA',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          options: [
                            {
                              value: true,
                              label: 'c.b. intasato (SMA)',
                            },
                          ],
                        },
                      },
                      {
                        fieldGroupClassName: 'display-flex border-bottom',
                        fieldGroup: [
                          {
                            className: 'flex-1',
                            fieldGroup: [
                              {
                                key: 'Altro',
                                type: 'checkbox',
                                defaultValue: false,
                                className: 'width-100 radio-sec',
                                templateOptions: {
                                  key: 'Altro',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  options: [
                                    {
                                      value: true,
                                      label: 'Altro',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value != true) {
                                      field.formControl.parent
                                        ?.get(['Altro Input'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Altro Input'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Altro Input'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value != true) {
                                      field.formControl.parent
                                        ?.get(['Altro Input'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Altro Input'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Altro Input'])
                                        ?.enable();
                                    }
                                  },
                                },
                                hooks: {
                                  onInit: (field) => {
                                    setTimeout(() => {
                                      if (field.formControl.value != true) {
                                        field.formControl.parent
                                          ?.get(['Altro Input'])
                                          ?.setValue(null);
                                        field.formControl.parent
                                          ?.get(['Altro Input'])
                                          ?.disable();
                                      } else {
                                        field.formControl.parent
                                          ?.get(['Altro Input'])
                                          ?.enable();
                                      }
                                    }, 0);
                                  },
                                },
                              },
                            ],
                          },
                          {
                            className: 'flex-6',
                            fieldGroup: [
                              {
                                className: 'sub-sec',
                                type: 'input',
                                defaultValue: '',
                                key: 'Altro Input',
                                templateOptions: {
                                  label: 'Altro',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  required: true,
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'flex-1 border-bottom border-right',
                        template: `<p>Tipologia di pavimentazione</p>`,
                      },
                      {
                        key: 'flessibile',
                        type: 'checkbox',
                        defaultValue: false,
                        className:
                          'width-100 radio-sec border-bottom flex-1 border-right',
                        templateOptions: {
                          key: 'flessibile',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          options: [
                            {
                              value: true,
                              label: 'flessibile',
                            },
                          ],
                        },
                      },
                      {
                        key: 'semirigida',
                        type: 'checkbox',
                        defaultValue: false,
                        className:
                          'width-100 radio-sec border-bottom flex-1 border-right',
                        templateOptions: {
                          key: 'semirigida',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          options: [
                            {
                              value: true,
                              label: 'semirigida',
                            },
                          ],
                        },
                      },
                      {
                        key: 'rigida',
                        type: 'checkbox',
                        defaultValue: false,
                        className:
                          'width-100 radio-sec border-bottom flex-1 border-right',
                        templateOptions: {
                          key: 'rigida',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          options: [
                            {
                              value: true,
                              label: 'rigida',
                            },
                          ],
                        },
                      },
                      {
                        key: 'rigida ad armatura continua',
                        type: 'checkbox',
                        defaultValue: false,
                        className:
                          'width-100 radio-sec border-bottom flex-1 border-right',
                        templateOptions: {
                          key: 'rigida ad armatura continua',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          options: [
                            {
                              value: true,
                              label: 'rigida ad armatura continua',
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    type: 'cube-number-type',
                    defaultValue: '',
                    key: 'Spessore cm',
                    templateOptions: {
                      label: 'Spessore (cm)',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-top-10 border-bottom',
                        template: `<p>Stato di manutenzione</p>`,
                      },
                      {
                        key: 'Stato di manutenzione',
                        type: 'radio',
                        defaultValue: '',
                        className:
                          'sub-sec radio-sec border-bottom flex-1 border-right',
                        templateOptions: {
                          key: 'Stato di manutenzione',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          flex: true,
                          options: [
                            { value: 'buono', label: 'buono' },
                            { value: 'degradato', label: 'degradato' },
                          ],
                        },
                      },
                      {
                        className: 'flex-1 border-bottom padding-left-10',
                        template: `<p>Note : </p>`,
                      },
                      {
                        className:
                          'input-line flex-2 border-bottom remove-underline',
                        key: 'note',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Difettosità del piano viabile',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'width-100 flex-1 margin-auto',
                        template: `<p>Indicatore IRI (International Roughness Index) [mm/m]</p>`,
                      },
                      {
                        key: 'Indicatore IRI',
                        type: 'radio',
                        defaultValue: '',
                        className: 'sub-sec radio-sec flex-3',
                        templateOptions: {
                          key: 'Indicatore IRI',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          flex: true,
                          options: [
                            { value: 'SI', label: 'SI' },
                            { value: 'NO', label: 'NO' },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    className: 'width-100 flex-1 margin-auto',
                    template: `<p>Classe di indice IRI prevalente</p>`,
                  },
                  {
                    wrappers: ['cube-repeat-section-wrapper'],
                    templateOptions: {
                      minWidth: '1080px',
                    },
                    fieldGroup: [
                      {
                        fieldGroupClassName:
                          'display-flex repeat-header padding-top-10',
                        fieldGroup: [
                          {
                            className: 'flex-1 border-all',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Classe IRI',
                              class: 'text-align-center',
                            },
                          },
                          {
                            className: 'flex-1 border-all',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'da Pk',
                              class: 'text-align-center',
                            },
                          },
                          {
                            className: 'flex-1 border-all',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'a Pk',
                              class: 'text-align-center',
                            },
                          },
                        ],
                      },
                      {
                        key: 'Classe di indice IRI prevalente table section',
                        type: 'cube-repeat-section-type',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                        fieldArray: {
                          fieldGroupClassName:
                            'display-flex border-left border-bottom',
                          fieldGroup: [
                            {
                              className:
                                'flex-1 border-right repeat-cell remove-underline',
                              key: 'Classe IRI',
                              type: 'input',
                              defaultValue: '',
                              templateOptions: {
                                disabled:
                                  tunnelInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                              },
                            },
                            {
                              className:
                                'flex-1 border-right repeat-cell remove-underline',
                              key: 'da Pk',
                              type: 'cube-number-type',
                              defaultValue: '',
                              templateOptions: {
                                disabled:
                                  tunnelInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                              },
                            },
                            {
                              className:
                                'flex-1 border-right repeat-cell remove-underline',
                              key: 'a Pk',
                              type: 'cube-number-type',
                              defaultValue: '',
                              templateOptions: {
                                disabled:
                                  tunnelInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
              // Dati di traffico
              {
                key: 'Dati di traffico',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Dati di traffico',
                    },
                  },
                  {
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Dati di traffico',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'flex-1 border-bottom margin-top-20',
                        template: `<p>Fonte dati di traffico</p>`,
                      },
                      {
                        key: 'Fonte dati di traffico',
                        type: 'radio',
                        defaultValue: '',
                        className:
                          'sub-sec radio-sec border-bottom flex-1 border-right',
                        templateOptions: {
                          key: 'Fonte dati di traffico',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          flex: true,
                          options: [
                            { value: 'Rilevato', label: 'Rilevato' },
                            { value: 'Stimato', label: 'Stimato' },
                          ],
                        },
                      },
                      {
                        className:
                          'flex-1 border-bottom padding-left-10 border-right',
                        template: `<p>Anno di riferimento(di misura / di stima)</p>`,
                      },
                      {
                        className:
                          'input-line flex-2 border-bottom remove-underline',
                        key: 'Anno di riferimento di misura di stima',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'flex-1 border-bottom padding-top-10',
                        template: `<p>Traffico Medio Giornaliero (TGM)</p>`,
                      },
                      {
                        className:
                          'input-line flex-2 border-bottom remove-underline',
                        key: 'Traffico Medio Giornaliero TGM',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                      {
                        className: 'flex-1 border-bottom padding-top-10',
                        template: `<p>veicoli/giorno (sull’intera carreggiata)</p>`,
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className:
                          'width-100 flex-1 margin-top-20 border-bottom',
                        template: `<p>Classificazione del TGM</p>`,
                      },
                      {
                        key: 'Classificazione del TGM',
                        type: 'radio',
                        defaultValue: '',
                        className: 'sub-sec radio-sec border-bottom flex-2',
                        templateOptions: {
                          key: 'Classificazione del TGM',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          flex: true,
                          options: [
                            { value: 'TGM <10.000', label: 'TGM <10.000' },
                            {
                              value: '10.000≤TGM<25.000',
                              label: '10.000≤TGM<25.000',
                            },
                            {
                              value: '25.000≤ TGM <40.000',
                              label: '25.000≤ TGM <40.000',
                            },
                            { value: 'TGM ≥40.000 ', label: 'TGM ≥40.000' },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex border-bottom',
                    fieldGroup: [
                      {
                        className: 'width-100 flex-1 margin-auto',
                        template: `<p>Traffico Medio Giornaliero – Veicoli commerciali †</p>`,
                      },
                      {
                        className: 'flex-2 border-left',
                        key: 'Traffico Medio Giornaliero - Veicoli commerciali',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex',
                            fieldGroup: [
                              {
                                key: 'Traffico Medio Giornaliero – Veicoli commerciali first',
                                type: 'cube-number-type',
                                defaultValue: '',
                                className:
                                  'padding-left-10 border-bottom flex-1',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                },
                              },
                              {
                                className:
                                  'flex-1 border-bottom padding-top-10',
                                template: `<p>veicoli/giorno (sull’intera carreggiata)</p>`,
                              },
                              {
                                key: 'VP',
                                type: 'cube-number-type',
                                defaultValue: '',
                                className: 'border-bottom flex-2',
                                templateOptions: {
                                  label: '%VP',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                },
                              },
                            ],
                          },
                          {
                            fieldGroupClassName: 'display-flex',
                            fieldGroup: [
                              {
                                key: 'Traffico Medio Giornaliero – Veicoli commerciali second',
                                type: 'cube-number-type',
                                defaultValue: '',
                                className: 'padding-left-10 flex-2',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                },
                              },
                              {
                                className: 'flex-1 padding-top-10',
                                template: `<p>veicoli/giorno (medio per singola corsia di marcia)</p>`,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    template: `<div class="notes-font-size"> † veicoli la cui sagoma corrisponde a tipologie con massa superiore a 3,5 t (VP)</div>`,
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'flex-1 border-bottom border-right',
                        template: `<p>Traffico Medio Giornaliero - veicoli ADR</p>`,
                      },
                      {
                        className:
                          'input-line flex-2 border-bottom remove-underline',
                        key: 'Traffico Medio Giornaliero - veicoli ADR',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                      {
                        className: 'flex-1 border-bottom',
                        template: `<p>veicoli/giorno (sull’intera carreggiata)</p>`,
                      },
                      {
                        className:
                          'input-line flex-2 border-bottom remove-underline',
                        key: 'VP',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: '%VP',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                ],
              },
              // Rete stradale
              {
                key: 'Rete stradale',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Rete stradale',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        wrappers: ['cube-sub-title-wrapper'],
                        className: 'border-bottom flex-1 padding-top-10',
                        templateOptions: {
                          title: 'Itinerario Internazionale †',
                        },
                      },
                      {
                        key: 'Itinerario Internazionale',
                        type: 'radio',
                        defaultValue: '',
                        className:
                          'sub-sec radio-sec border-bottom flex-1 border-right',
                        templateOptions: {
                          key: 'Itinerario Internazionale',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          flex: true,
                          options: [
                            { value: 'SI', label: 'SI' },
                            { value: 'NO', label: 'NO' },
                          ],
                        },
                      },
                      {
                        wrappers: ['cube-sub-title-wrapper'],
                        className:
                          'border-bottom padding-left-10 flex-1 padding-top-10',
                        templateOptions: {
                          title: 'Rete TEN †',
                        },
                      },
                      {
                        key: 'Rete TEN',
                        type: 'radio',
                        defaultValue: '',
                        className: 'sub-sec radio-sec border-bottom flex-1',
                        templateOptions: {
                          key: 'Rete TEN',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          flex: true,
                          options: [
                            { value: 'SI', label: 'SI' },
                            { value: 'NO', label: 'NO' },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    template: `<div class="notes-font-size"> † Itinerario internazionale ai sensi del DM 28 luglio 2000 e s.m.i., rete TERN come definita dal Regolamento (UE) N. 1315/2013 del Parlamento Europeo e del Consigliodell'11 dicembre2013 e s.m.i.</div>`,
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        wrappers: ['cube-sub-title-wrapper'],
                        className:
                          'border-bottom padding-left-10 flex-1 padding-top-10',
                        templateOptions: {
                          title: 'Rete emergenza',
                        },
                      },
                      {
                        key: 'Rete emergenza',
                        type: 'radio',
                        defaultValue: '',
                        className:
                          'sub-sec radio-sec border-bottom flex-1 border-right',
                        templateOptions: {
                          key: 'Rete emergenza',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          flex: true,
                          options: [
                            { value: 'SI', label: 'SI' },
                            { value: 'NO', label: 'NO' },
                          ],
                        },
                      },
                      // Empty field for design purpose
                      {
                        className: 'flex-2 border-bottom padding-left-10',
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className:
                          'width-100 input-line flex-1 border-bottom remove-underline border-right',
                        key: 'N° di fornici',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'N° di fornici',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                      {
                        className:
                          'width-100 input-line flex-1 border-bottom remove-underline padding-left-10',
                        key: 'N° corsie fornice',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'N° corsie/fornice',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    wrappers: ['cube-sub-title-wrapper'],
                    className: 'border-bottom flex-1',
                    templateOptions: {
                      title: 'Percorsi alternativi',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex border-bottom',
                    fieldGroup: [
                      {
                        className: 'width-100 flex-1 margin-auto',
                        template: `<p>Presenza di alternative stradali (in caso di chiusure/limitazioni di traffico)</p>`,
                      },
                      {
                        key: 'Presenza di alternative stradali',
                        type: 'radio',
                        defaultValue: '',
                        className: 'sub-sec radio-sec flex-2',
                        templateOptions: {
                          key: 'Presenza di alternative stradali',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          flex: true,
                          options: [
                            { value: 'SÌ', label: 'SÌ' },
                            { value: 'NO', label: 'NO' },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex border-bottom',
                    fieldGroup: [
                      {
                        className: 'width-100 flex-1 margin-auto',
                        template: `<p>Presenza di alternative stradali praticabili per veicoli trasportanti merci pericolose (in caso di chiusure/limitazioni di traffico)</p>`,
                      },
                      {
                        key: 'Presenza di alternative stradali praticabili per veicoli trasportanti merci pericolose',
                        type: 'radio',
                        defaultValue: '',
                        className: 'sub-sec radio-sec flex-2',
                        templateOptions: {
                          key: 'Presenza di alternative stradali praticabili per veicoli trasportanti merci pericolose',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          flex: true,
                          options: [
                            { value: 'SI', label: 'SI' },
                            { value: 'NO', label: 'NO' },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex border-bottom',
                    key: 'Lunghezza Durata deviazione',
                    fieldGroup: [
                      {
                        className: 'width-100 flex-1 margin-auto',
                        template: `<p>Lunghezza/Durata deviazione</p>`,
                      },
                      {
                        className: 'width-100 flex-1 border-left',
                        key: 'Km',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Km',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                      {
                        className: 'width-100 flex-1 border-left',
                        key: 'Minuti',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Minuti',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    className: 'flex-1',
                    key: 'Categoria del percorso alternativo individuato',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Categoria del percorso alternativo individuato',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                    },
                  },
                  {
                    key: 'Disponibilità di studi trasportistici specifici',
                    fieldGroupClassName: 'display-flex border-bottom',
                    fieldGroup: [
                      {
                        className: 'width-100 flex-1 margin-auto',
                        template: `<p>Disponibilità di studi trasportistici specifici</p>`,
                      },
                      {
                        className: 'flex-3 remove-underline',
                        fieldGroupClassName:
                          'display-flex align-items-center  padding-10',
                        hooks: {
                          onInit: (field) => {
                            setTimeout(() => {
                              if (
                                field.formControl.get(['radio']).value !== 'SI'
                              ) {
                                field.formControl
                                  .get(['Allegato n'])
                                  ?.setValue('');
                                field.formControl
                                  ?.get(['Allegato n'])
                                  ?.disable();
                              } else {
                                field.formControl.get(['Allegato n'])?.enable();
                              }
                            }, 0);
                          },
                        },
                        fieldGroup: [
                          {
                            key: 'radio',
                            type: 'radio',
                            className: 'radio-sec',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              key: 'Disponibilità di studi trasportistici specifici',
                              options: [
                                {
                                  value: 'SI',
                                  label: 'SI',
                                },
                              ],
                              change: (field) => {
                                if (field.formControl.value !== 'SI') {
                                  tunnelInputTemplateComponent.deleteUploadedFiles(
                                    field.formControl.parent?.get([
                                      'Allegato n',
                                    ])?.value
                                  );
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.setValue('');
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.enable();
                                }
                              },
                              onReset: (field) => {
                                if (field.formControl.value !== 'SI') {
                                  tunnelInputTemplateComponent.deleteUploadedFiles(
                                    field.formControl.parent?.get([
                                      'Allegato n',
                                    ])?.value
                                  );
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.setValue('');
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.enable();
                                }
                              },
                            },
                          },
                          {
                            key: 'radio',
                            type: 'radio',
                            className: 'radio-sec',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              key: 'Disponibilità di studi trasportistici specifici',
                              options: [
                                {
                                  value: 'NO',
                                  label: 'NO',
                                },
                              ],
                              change: (field) => {
                                if (field.formControl.value !== 'SI') {
                                  tunnelInputTemplateComponent.deleteUploadedFiles(
                                    field.formControl.parent?.get([
                                      'Allegato n',
                                    ])?.value
                                  );
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.setValue('');
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.enable();
                                }
                              },
                              onReset: (field) => {
                                if (field.formControl.value !== 'SI') {
                                  tunnelInputTemplateComponent.deleteUploadedFiles(
                                    field.formControl.parent?.get([
                                      'Allegato n',
                                    ])?.value
                                  );
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.setValue('');
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.enable();
                                }
                              },
                            },
                          },
                          {
                            className: 'width-100 flex-1 input-line',
                            key: 'Allegato n',
                            type: 'cube-file-upload-type',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Allegato n',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              token: tunnelInputTemplateComponent.token,
                              modelData: tunnelInputTemplateComponent.model,
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Disponibilità di analisi di rischio su percorsi alternativi',
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'width-100 flex-1 margin-auto',
                        template: `<p>Disponibilità di analisi di rischio su percorsi alternativi</p>`,
                      },
                      {
                        className: 'flex-3 remove-underline',
                        fieldGroupClassName:
                          'display-flex align-items-center padding-10',
                        hooks: {
                          onInit: (field) => {
                            setTimeout(() => {
                              if (
                                field.formControl.get(['radio']).value !== 'SI'
                              ) {
                                field.formControl
                                  .get(['Allegato n'])
                                  ?.setValue('');
                                field.formControl
                                  .get(['Allegato n'])
                                  ?.disable();
                              } else {
                                field.formControl.get(['Allegato n'])?.enable();
                              }
                            }, 0);
                          },
                        },
                        fieldGroup: [
                          {
                            key: 'radio',
                            type: 'radio',
                            className: 'radio-sec',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              key: 'Disponibilità di analisi di rischio su percorsi alternativi',
                              options: [
                                {
                                  value: 'SI',
                                  label: 'SI',
                                },
                              ],
                              change: (field) => {
                                if (field.formControl.value !== 'SI') {
                                  tunnelInputTemplateComponent.deleteUploadedFiles(
                                    field.formControl.parent?.get([
                                      'Allegato n',
                                    ])?.value
                                  );
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.setValue('');
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.enable();
                                }
                              },
                              onReset: (field) => {
                                if (field.formControl.value !== 'SI') {
                                  tunnelInputTemplateComponent.deleteUploadedFiles(
                                    field.formControl.parent?.get([
                                      'Allegato n',
                                    ])?.value
                                  );
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.setValue('');
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.enable();
                                }
                              },
                            },
                          },
                          {
                            key: 'radio',
                            type: 'radio',
                            className: 'radio-sec',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              key: 'Disponibilità di analisi di rischio su percorsi alternativi',
                              options: [
                                {
                                  value: 'NO',
                                  label: 'NO',
                                },
                              ],
                              change: (field) => {
                                if (field.formControl.value !== 'SI') {
                                  tunnelInputTemplateComponent.deleteUploadedFiles(
                                    field.formControl.parent?.get([
                                      'Allegato n',
                                    ])?.value
                                  );
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.setValue('');
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.enable();
                                }
                              },
                              onReset: (field) => {
                                if (field.formControl.value !== 'SI') {
                                  tunnelInputTemplateComponent.deleteUploadedFiles(
                                    field.formControl.parent?.get([
                                      'Allegato n',
                                    ])?.value
                                  );
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.setValue('');
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.enable();
                                }
                              },
                            },
                          },
                          {
                            className: 'width-100 flex-1 input-line',
                            key: 'Allegato n',
                            type: 'cube-file-upload-type',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Allegato n',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              token: tunnelInputTemplateComponent.token,
                              modelData: tunnelInputTemplateComponent.model,
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              // LIVELLO 0 Scheda di censimento-conoscenza galleria – Parte 2
              {
                fieldGroupClassName: 'page-heading',
                fieldGroup: [
                  {
                    className: 'width-100',
                    template: `
                      <div class="page-heading-text">
                        <div> 
                          <span class="font-weight-normal">
                            <span class="prefix-block">LIVELLO 0</span> 
                            Scheda di censimento-conoscenza galleria 
                          </span> – Parte 2
                        </div>
                        <div>Dati necessari al corretto inquadramento della vulnerabilità al rischio della galleria.</div>
                      </div>`,
                  },
                ],
              },
              // Caratteristiche geometriche dinfrastruttura stradale
              {
                key: 'Caratteristiche geometriche dinfrastruttura stradale',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'Caratteristiche geometriche d’infrastruttura stradale',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        key: 'Larghezza carreggiata',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Larghezza carreggiata [m] ',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          min: 0,
                        },
                      },
                      {
                        className: 'flex-1 border-left',
                        key: 'Numero di corsie',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Numero di corsie',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap grid-gap',
                    fieldGroup: [
                      {
                        className: 'flex-1 margin-auto',
                        template: `<p>Larghezza delle corsie [m] †</p>`,
                      },
                      {
                        key: 'number 1',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                      {
                        template: `<p>(</p>`,
                      },
                      {
                        className: 'width-10',
                        key: 'string 1',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                      {
                        template: `<p>)</p>`,
                      },
                      {
                        key: 'number 2 ',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          min: 0,
                        },
                      },
                      {
                        template: `<p>(</p>`,
                      },
                      {
                        className: 'width-10',
                        key: 'string 2',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                      {
                        template: `<p>)</p>`,
                      },
                      {
                        key: 'number 3',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          min: 0,
                        },
                      },
                      {
                        template: `<p>(</p>`,
                      },
                      {
                        className: 'width-10',
                        key: 'string 3',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                      {
                        template: `<p>)</p>`,
                      },
                      {
                        key: 'number 4',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          min: 0,
                        },
                      },
                      {
                        template: `<p>(</p>`,
                      },
                      {
                        className: 'width-10',
                        key: 'string 4',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                      {
                        template: `<p>)</p>`,
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                template: `<p>Corsia di emergenza</p>`,
                              },
                              {
                                key: 'Corsia di emergenza',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Corsia di emergenza',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left',
                        key: 'Larghezza corsia emergenza',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Larghezza corsia emergenza [m] ',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                template: `<p>Banchina laterale destra</p>`,
                              },
                              {
                                key: 'Banchina laterale destra',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Banchina laterale destra',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left',
                        key: 'Larghezza banchina',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Larghezza banchina [m]  ',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto  ',
                                template: `<p>Distanziamento minimo obbligatorio</p>`,
                              },
                              {
                                key: 'Banchina laterale sinistra',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Banchina laterale sinistra',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left',
                        key: 'Larghezza banchina 1',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Larghezza banchina [m]  ',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto  ',
                                template: `<p>Marciapiede in destra</p>`,
                              },
                              {
                                key: 'Marciapiede in destra',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Marciapiede in destra',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left',
                        key: 'Larghezza marciapiede',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Larghezza marciapiede [m]',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto  ',
                                template: `<p>Marciapiede in sinistra</p>`,
                              },
                              {
                                key: 'Marciapiede in sinistra',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Marciapiede in sinistra',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left',
                        key: 'Larghezza marciapiede 1',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Larghezza marciapiede [m]   ',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto  ',
                                template: `<p>Profilo redirettivo in destra</p>`,
                              },
                              {
                                key: 'Profilo redirettivo in destra',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Profilo redirettivo in destra',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className:
                                  ' flex-1 margin-auto padding-left-10',
                                template: `<p>Profilo redirettivo in sinistra</p>`,
                              },
                              {
                                key: 'Profilo redirettivo in sinistra',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Profilo redirettivo in sinistra',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-left-10',
                        key: 'Altezza minima in piattaforma',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Altezza minima in piattaforma [m] †²',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                      {
                        className: 'flex-1 border-left',
                        key: 'Altezza minima in carreggiata',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Altezza minima in carreggiata [m] †²',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    key: 'Tracciato',
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title: 'Tracciato',
                        },
                      },
                      {
                        key: 'Tracciato - checkbox',
                        className: 'flex-2',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'main-section',
                            fieldGroup: [
                              {
                                className: 'width-100 remove-underline',
                                key: 'Rettilineo imbocchi in rettilineo',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  options: [
                                    {
                                      label:
                                        'Rettilineo / imbocchi in rettilineo',
                                    },
                                  ],
                                },
                              },
                              {
                                className: 'width-100 remove-underline',
                                key: 'Rettilineo imbocchi in curva',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  options: [
                                    { label: 'Rettilineo / imbocchi in curva' },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            fieldGroupClassName: 'main-section',
                            fieldGroup: [
                              {
                                className: 'width-100 remove-underline',
                                key: 'Curvilineo imbocchi in rettilineo',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  options: [
                                    {
                                      label:
                                        'Curvilineo / imbocchi in rettilineo',
                                    },
                                  ],
                                },
                              },
                              {
                                className: 'width-100 remove-underline',
                                key: 'Curvilineo imbocchi in curva',
                                type: 'checkbox',
                                defaultValue: false,
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  options: [
                                    {
                                      label: 'Curvilineo / imbocchi in curva',
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    template: `<div>
                          <sup> †</sup>Indicare la larghezza di ogni corsia, specificando tra parentesi la funzione della corsia (corsia di marcia “CM”, corsia di marcia veloce “CMV”, corsia di sorpasso “CS”).
                      </div>
                      <div>
                          <sup> †2</sup>Altezza minima libera da ostacoli (sia strutture sia impianti) misurata sulla verticale a partire da qualsiasi punto della carreggiata / piattaforma.
                      </div>`,
                  },
                ],
              },
              // Strutture secondarie in galleria
              {
                key: 'Strutture secondarie in galleria',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Strutture secondarie in galleria',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto  ',
                                template: `<p>Controsoffitto</p>`,
                              },
                              {
                                key: 'Controsoffitto',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Controsoffitto',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10',
                        key: 'spessore 1',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'spessore [m]',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex border-bottom',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex grid-gap flex-1',
                        className: 'flex-2',
                        fieldGroup: [
                          {
                            key: 'Calcestruzzo armato 1',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'radio-sec flex-1',
                            templateOptions: {
                              key: 'Calcestruzzo armato 1',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Calcestruzzo armato',
                                },
                              ],
                            },
                          },
                          {
                            key: 'Calcestruzzo non armato 1',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'radio-sec flex-1',
                            templateOptions: {
                              key: 'Calcestruzzo non armato 1',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Calcestruzzo non armato',
                                },
                              ],
                            },
                          },
                          {
                            key: 'Calcestruzzo proiettato 1',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'radio-sec flex-1',
                            templateOptions: {
                              key: 'Calcestruzzo proiettato 1',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Calcestruzzo proiettato',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName:
                          'display-inline-flex flex-1 width-100',
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            key: 'Altro 1',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Altro 1',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Altro',
                                },
                              ],
                              change: (field) => {
                                if (field.formControl.value !== true) {
                                  field.formControl.parent
                                    ?.get(['Altro Input 1'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Altro Input 1'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Altro Input 1'])
                                    ?.enable();
                                }
                              },
                              onReset: (field) => {
                                if (field.formControl.value !== true) {
                                  field.formControl.parent
                                    ?.get(['Altro Input 1'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Altro Input 1'])
                                    ?.disable();
                                }
                              },
                            },
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (field.formControl.value !== true) {
                                    field.formControl.parent
                                      ?.get(['Altro Input 1'])
                                      ?.setValue(null);
                                    field.formControl.parent
                                      ?.get(['Altro Input 1'])
                                      ?.disable();
                                  } else {
                                    field.formControl.parent
                                      ?.get(['Altro Input 1'])
                                      ?.enable();
                                  }
                                }, 0);
                              },
                            },
                          },
                          {
                            className: 'sub-sec flex-2',
                            type: 'input',
                            defaultValue: '',
                            key: 'Altro Input 1',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              label: 'Altro',
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto  ',
                                template: `<p>Soletta sottopavimentazione</p>`,
                              },
                              {
                                key: 'Soletta sottopavimentazione',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Soletta sottopavimentazione',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10 ',
                        key: 'spessore 2',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'spessore [m]',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex border-bottom',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex grid-gap flex-1',
                        className: 'flex-2',
                        fieldGroup: [
                          {
                            key: 'Calcestruzzo armato 2',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'radio-sec flex-1',
                            templateOptions: {
                              key: 'Calcestruzzo armato 2',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Calcestruzzo armato',
                                },
                              ],
                            },
                          },
                          {
                            key: 'Calcestruzzo non armato 2',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'radio-sec flex-1',
                            templateOptions: {
                              key: 'Calcestruzzo non armato 2',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Calcestruzzo non armato',
                                },
                              ],
                            },
                          },
                          {
                            key: 'Calcestruzzo proiettato 2',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'radio-sec flex-1',
                            templateOptions: {
                              key: 'Calcestruzzo proiettato 2',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Calcestruzzo proiettato',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName:
                          'display-inline-flex flex-1 width-100',
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            key: 'Altro 2',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Altro 2',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Altro',
                                },
                              ],
                              change: (field) => {
                                if (field.formControl.value !== true) {
                                  field.formControl.parent
                                    ?.get(['Altro Input 2'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Altro Input 2'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Altro Input 2'])
                                    ?.enable();
                                }
                              },
                              onReset: (field) => {
                                if (field.formControl.value !== true) {
                                  field.formControl.parent
                                    ?.get(['Altro Input 2'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Altro Input 2'])
                                    ?.disable();
                                }
                              },
                            },
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (field.formControl.value !== true) {
                                    field.formControl.parent
                                      ?.get(['Altro Input 2'])
                                      ?.setValue(null);
                                    field.formControl.parent
                                      ?.get(['Altro Input 2'])
                                      ?.disable();
                                  } else {
                                    field.formControl.parent
                                      ?.get(['Altro Input 2'])
                                      ?.enable();
                                  }
                                }, 0);
                              },
                            },
                          },
                          {
                            className: 'sub-sec flex-2',
                            type: 'input',
                            defaultValue: '',
                            key: 'Altro Input 2',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              label: 'Altro',
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto  ',
                                template: `<p>Parete cunicolo laterale</p>`,
                              },
                              {
                                key: 'Parete cunicolo laterale',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Parete cunicolo laterale',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10',
                        key: 'spessore 3',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'spessore [m]',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex border-bottom',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex grid-gap flex-1',
                        className: 'flex-2',
                        fieldGroup: [
                          {
                            key: 'Calcestruzzo armato 3',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'radio-sec flex-1',
                            templateOptions: {
                              key: 'Calcestruzzo armato 3',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Calcestruzzo armato',
                                },
                              ],
                            },
                          },
                          {
                            key: 'Calcestruzzo non armato 3',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'radio-sec flex-1',
                            templateOptions: {
                              key: 'Calcestruzzo non armato 3',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Calcestruzzo non armato',
                                },
                              ],
                            },
                          },
                          {
                            key: 'Calcestruzzo proiettato 3',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'radio-sec flex-1',
                            templateOptions: {
                              key: 'Calcestruzzo proiettato 3',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Calcestruzzo proiettato',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName:
                          'display-inline-flex flex-1 width-100',
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            key: 'Altro 3',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Altro 3',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Altro',
                                },
                              ],
                              change: (field) => {
                                if (field.formControl.value !== true) {
                                  field.formControl.parent
                                    ?.get(['Altro Input 3'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Altro Input 3'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Altro Input 3'])
                                    ?.enable();
                                }
                              },
                              onReset: (field) => {
                                if (field.formControl.value !== true) {
                                  field.formControl.parent
                                    ?.get(['Altro Input 3'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Altro Input 3'])
                                    ?.disable();
                                }
                              },
                            },
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (field.formControl.value !== true) {
                                    field.formControl.parent
                                      ?.get(['Altro Input 3'])
                                      ?.setValue(null);
                                    field.formControl.parent
                                      ?.get(['Altro Input 3'])
                                      ?.disable();
                                  } else {
                                    field.formControl.parent
                                      ?.get(['Altro Input 3'])
                                      ?.enable();
                                  }
                                }, 0);
                              },
                            },
                          },
                          {
                            className: 'sub-sec flex-2',
                            type: 'input',
                            defaultValue: '',
                            key: 'Altro Input 3',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              label: 'Altro',
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              // Infrastrutture di sicurezza e di servizio in galleria
              {
                key: 'Infrastrutture di sicurezza e di servizio in galleria',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'Infrastrutture di sicurezza e di servizio in galleria',
                    },
                  },
                  {
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className: 'flex-1 border-right',
                            fieldGroup: [
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set',
                                fieldGroup: [
                                  {
                                    className: 'width-100 flex-2 margin-auto',
                                    template: `<p>Piazzole di sosta – carreggiata DX</p>`,
                                  },
                                  {
                                    key: 'Piazzole di sosta – carreggiata DX 1',
                                    type: 'radio',
                                    defaultValue: '',
                                    className: 'sub-sec radio-sec flex-2',
                                    templateOptions: {
                                      key: 'Piazzole di sosta – carreggiata DX 1',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      flex: true,
                                      options: [
                                        { value: 'SI', label: 'SI' },
                                        { value: 'NO', label: 'NO' },
                                      ],
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-right-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1 ',
                                    key: 'numero 1',
                                    type: 'cube-number-type',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'numero ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set',
                                fieldGroup: [
                                  {
                                    className: 'width-100 flex-2 margin-auto',
                                    template: `<p>Piazzole di sosta – carreggiata DX</p>`,
                                  },
                                  {
                                    key: 'Piazzole di sosta – carreggiata DX 2',
                                    type: 'radio',
                                    defaultValue: '',
                                    className: 'sub-sec radio-sec flex-2',
                                    templateOptions: {
                                      key: 'Piazzole di sosta – carreggiata DX 2',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      flex: true,
                                      options: [
                                        { value: 'SI', label: 'SI' },
                                        { value: 'NO', label: 'NO' },
                                      ],
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-right-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1 ',
                                    key: 'numero 2',
                                    type: 'cube-number-type',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'numero ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set',
                                fieldGroup: [
                                  {
                                    className: 'width-100 flex-2 margin-auto',
                                    template: `<p>Vie di fuga</p>`,
                                  },
                                  {
                                    key: 'Vie di fuga',
                                    type: 'radio',
                                    defaultValue: '',
                                    className: 'sub-sec radio-sec flex-2',
                                    templateOptions: {
                                      key: 'Vie di fuga',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      flex: true,
                                      options: [
                                        { value: 'SI', label: 'SI' },
                                        { value: 'NO', label: 'NO' },
                                      ],
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set',
                                fieldGroup: [
                                  {
                                    className: 'width-100 flex-2 margin-auto',
                                    template: `<p>Cunicolo di esodo sotto il piano viabile</p>`,
                                  },
                                  {
                                    key: 'Cunicolo di esodo sotto il piano viabile',
                                    type: 'radio',
                                    defaultValue: '',
                                    className: 'sub-sec radio-sec flex-2',
                                    templateOptions: {
                                      key: 'Cunicolo di esodo sotto il piano viabile',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      flex: true,
                                      options: [
                                        { value: 'SI', label: 'SI' },
                                        { value: 'NO', label: 'NO' },
                                      ],
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set',
                                fieldGroup: [
                                  {
                                    className: 'width-100 flex-2 margin-auto',
                                    template: `<p>Cunicolo di esodo sopra il piano viabile</p>`,
                                  },
                                  {
                                    key: 'Cunicolo di esodo sopra il piano viabile',
                                    type: 'radio',
                                    defaultValue: '',
                                    className: 'sub-sec radio-sec flex-2',
                                    templateOptions: {
                                      key: 'Cunicolo di esodo sopra il piano viabile',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      flex: true,
                                      options: [
                                        { value: 'SI', label: 'SI' },
                                        { value: 'NO', label: 'NO' },
                                      ],
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set',
                                fieldGroup: [
                                  {
                                    className: 'width-100 flex-2 margin-auto',
                                    template: `<p>Resistenza al fuoco delle strutture</p>`,
                                  },
                                  {
                                    key: 'Resistenza al fuoco delle strutture',
                                    type: 'radio',
                                    defaultValue: '',
                                    className: 'sub-sec radio-sec flex-2',
                                    templateOptions: {
                                      key: 'Resistenza al fuoco delle strutture',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      flex: true,
                                      options: [
                                        { value: 'SI', label: 'SI' },
                                        { value: 'NO', label: 'NO' },
                                      ],
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set',
                                fieldGroup: [
                                  {
                                    className: 'width-100 flex-2 margin-auto',
                                    template: `<p>By-Pass / Uscite emergenza</p>`,
                                  },
                                  {
                                    key: 'By-Pass Uscite emergenza',
                                    type: 'radio',
                                    defaultValue: '',
                                    className: 'sub-sec radio-sec flex-2',
                                    templateOptions: {
                                      key: 'By-Pass  Uscite emergenza  ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      flex: true,
                                      options: [
                                        { value: 'SI', label: 'SI' },
                                        { value: 'NO', label: 'NO' },
                                      ],
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-right-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1 ',
                                    key: 'numero 3',
                                    type: 'cube-number-type',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'numero ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-right-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Superficie parete divisoria 1',
                                    type: 'cube-number-type',
                                    defaultValue: '',
                                    templateOptions: {
                                      label:
                                        'Superficie parete divisoria [m²]  ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-right-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Larghezza porte 1',
                                    type: 'cube-number-type',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Larghezza porte [m]   ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set',
                                fieldGroup: [
                                  {
                                    className: 'width-100 flex-2 margin-auto',
                                    template: `<p>Compartimentazione antincendio</p>`,
                                  },
                                  {
                                    key: 'Compartimentazione antincendio',
                                    type: 'radio',
                                    defaultValue: '',
                                    className: 'sub-sec radio-sec flex-2',
                                    templateOptions: {
                                      key: 'Compartimentazione antincendio',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      flex: true,
                                      options: [
                                        { value: 'SI', label: 'SI' },
                                        { value: 'NO', label: 'NO' },
                                      ],
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set',
                                fieldGroup: [
                                  {
                                    className: 'width-100 flex-2 margin-auto',
                                    template: `<p>By-Pass carrabili</p>`,
                                  },
                                  {
                                    key: 'By-Pass carrabili',
                                    type: 'radio',
                                    defaultValue: '',
                                    className: 'sub-sec radio-sec flex-2',
                                    templateOptions: {
                                      key: 'By-Pass carrabili',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      flex: true,
                                      options: [
                                        { value: 'SI', label: 'SI' },
                                        { value: 'NO', label: 'NO' },
                                      ],
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-right-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'numero 4',
                                    type: 'cube-number-type',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'numero   ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-right-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Superficie parete divisoria 2',
                                    type: 'cube-number-type',
                                    defaultValue: '',
                                    templateOptions: {
                                      label:
                                        'Superficie parete divisoria [m²]  ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-right-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Larghezza portone carrabile',
                                    type: 'cube-number-type',
                                    defaultValue: '',
                                    templateOptions: {
                                      label:
                                        'Larghezza portone carrabile [m]  ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set',
                                fieldGroup: [
                                  {
                                    className: 'width-100 flex-2 margin-auto',
                                    template: `<p> Vani tecnici</p>`,
                                  },
                                  {
                                    key: 'Vani tecnici',
                                    type: 'radio',
                                    defaultValue: '',
                                    className: 'sub-sec radio-sec flex-2',
                                    templateOptions: {
                                      key: 'Vani tecnici',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      flex: true,
                                      options: [
                                        { value: 'SI', label: 'SI' },
                                        { value: 'NO', label: 'NO' },
                                      ],
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-right-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Superficie parete divisoria 3',
                                    type: 'cube-number-type',
                                    defaultValue: '',
                                    templateOptions: {
                                      label:
                                        'Superficie parete divisoria [m²]  ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-right-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Larghezza porte 2',
                                    type: 'cube-number-type',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Larghezza porte [m]  ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set',
                                fieldGroup: [
                                  {
                                    className: 'width-100 flex-2 margin-auto',
                                    template: `<p>Idranti</p>`,
                                  },
                                  {
                                    key: 'Idranti',
                                    type: 'radio',
                                    defaultValue: '',
                                    className: 'sub-sec radio-sec flex-2',
                                    templateOptions: {
                                      key: 'Idranti',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      flex: true,
                                      options: [
                                        { value: 'SI', label: 'SI' },
                                        { value: 'NO', label: 'NO' },
                                      ],
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-right-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'numero 5',
                                    type: 'cube-number-type',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'numero   ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set ',
                                fieldGroup: [
                                  {
                                    className: 'width-100 flex-2 margin-auto',
                                    template: `<p>Stazioni di emergenza</p>`,
                                  },
                                  {
                                    key: 'Stazioni di emergenza',
                                    type: 'radio',
                                    defaultValue: '',
                                    className: 'sub-sec radio-sec flex-2',
                                    templateOptions: {
                                      key: 'Stazioni di emergenza',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                      flex: true,
                                      options: [
                                        { value: 'SI', label: 'SI' },
                                        { value: 'NO', label: 'NO' },
                                      ],
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-right-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'numero 6',
                                    type: 'cube-number-type',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'numero  ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            className: 'flex-1',
                            fieldGroup: [
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-left-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Interasse medio 1',
                                    type: 'cube-number-type',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Interasse medio [m]  ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-left-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'dimensioni 1',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'dimensioni [m]',
                                      placeholder: '(larghezza x lunghezza)',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-left-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Interasse medio 2',
                                    type: 'cube-number-type',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Interasse medio [m]  ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-left-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'dimensioni 2',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'dimensioni [m] ',
                                      placeholder: '(larghezza x lunghezza)',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-left-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Note 1',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Note:  ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-left-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Note 2',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Note:  ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-left-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Note 3',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Note:  ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-left-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Note 4',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Note:  ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-left-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Interasse medio 3',
                                    type: 'cube-number-type',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Interasse medio [m]  ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-left-10',
                                fieldGroup: [],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-left-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Resistenza 1',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Resistenza (REI)  ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-left-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Resistenza 2',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Resistenza (REI)  ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-left-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'tipo',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'tipo  ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-left-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Interasse medio 4',
                                    type: 'cube-number-type',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Interasse medio [m]  ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-left-10',
                                fieldGroup: [],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-left-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Resistenza 3',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Resistenza (REI)  ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-left-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Resistenza 4',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Resistenza (REI)  ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-left-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'numero 7',
                                    type: 'cube-number-type',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'numero ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-left-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Resistenza',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Resistenza (REI)  ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-left-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Resistenza 5',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Resistenza (REI)  ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-left-10',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Interasse medio 5',
                                    type: 'cube-number-type',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Interasse medio [m]  ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-left-10',
                                fieldGroup: [],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-left-10 ',
                                fieldGroup: [
                                  {
                                    className: 'flex-1',
                                    key: 'Interasse medio 6',
                                    type: 'cube-number-type',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Interasse medio [m]  ',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName:
                                  'display-flex border-bottom height-set padding-left-10',
                                fieldGroup: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              // Installazioni sospese ed elementi ausiliari
              {
                key: 'Installazioni sospese ed elementi ausiliari',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Installazioni sospese ed elementi ausiliari',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                wrappers: ['cube-sub-title-wrapper'],
                                templateOptions: {
                                  title: 'Illuminazione',
                                },
                              },
                              {
                                key: 'Illuminazione',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Illuminazione',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10 ',
                        key: 'Tecnologia SAP LED',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Tecnologia (SAP /LED)',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-2 ',
                                template: `<p>Posizione proiettori</p>`,
                              },
                              {
                                key: 'sulla carreggiata',
                                type: 'checkbox',
                                defaultValue: false,
                                className: ' radio-sec flex-1',
                                templateOptions: {
                                  key: 'sulla carreggiata',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  options: [
                                    { value: true, label: 'sulla carreggiata' },
                                  ],
                                },
                              },
                              {
                                key: 'sui piedritti',
                                type: 'checkbox',
                                defaultValue: false,
                                className: '  radio-sec flex-1',
                                templateOptions: {
                                  key: 'sui piedritti',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  options: [
                                    { value: true, label: 'sui piedritti' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10 ',
                        key: 'numero file',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'numero file',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                wrappers: ['cube-sub-title-wrapper'],
                                templateOptions: {
                                  title: 'Ventilazione',
                                },
                              },
                              {
                                key: 'Ventilazione',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Ventilazione',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10 ',
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex border-bottom',
                    fieldGroup: [
                      {
                        fieldGroupClassName: ' display-flex grid-gap flex-1',
                        className: 'flex-2',
                        fieldGroup: [
                          {
                            key: 'longitudinale',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'radio-sec flex-1',
                            templateOptions: {
                              key: 'longitudinale',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'longitudinale',
                                },
                              ],
                            },
                          },
                          {
                            key: 'semi-trasversale',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'radio-sec flex-1',
                            templateOptions: {
                              key: 'semi-trasversale',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'semi-trasversale',
                                },
                              ],
                            },
                          },
                          {
                            key: 'trasversale',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'radio-sec flex-1',
                            templateOptions: {
                              key: 'trasversale',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'trasversale',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName:
                          'display-inline-flex flex-1 width-100',
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            key: 'Altro',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Altro',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Altro',
                                },
                              ],
                              change: (field) => {
                                if (field.formControl.value !== true) {
                                  field.formControl.parent
                                    ?.get(['Altro Input'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Altro Input'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Altro Input'])
                                    ?.enable();
                                }
                              },
                              onReset: (field) => {
                                if (field.formControl.value !== true) {
                                  field.formControl.parent
                                    ?.get(['Altro Input'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Altro Input'])
                                    ?.disable();
                                }
                              },
                            },
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (field.formControl.value !== true) {
                                    field.formControl.parent
                                      ?.get(['Altro Input'])
                                      ?.setValue(null);
                                    field.formControl.parent
                                      ?.get(['Altro Input'])
                                      ?.disable();
                                  } else {
                                    field.formControl.parent
                                      ?.get(['Altro Input'])
                                      ?.enable();
                                  }
                                }, 0);
                              },
                            },
                          },
                          {
                            className: 'sub-sec flex-2',
                            type: 'input',
                            defaultValue: '',
                            key: 'Altro Input',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              label: 'Altro',
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                template: `<p>Ventilatori (Jet-fan) in volta</p>`,
                              },
                              {
                                key: 'Ventilatori Jet-fan in volta',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Ventilatori Jet-fan in volta',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10 ',
                        key: 'numero ventilatori',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'numero ventilatori',
                          mode: 'integer',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex border-bottom',
                    fieldGroup: [
                      {
                        fieldGroupClassName: ' display-flex grid-gap flex-1',
                        className: 'flex-2',
                        fieldGroup: [
                          {
                            className: 'flex-1 margin-auto',
                            template: `<p>Disposizione ventilatori</p>`,
                          },
                          {
                            key: 'singoli',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'radio-sec flex-1',
                            templateOptions: {
                              key: 'singoli',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'singoli',
                                },
                              ],
                            },
                          },
                          {
                            key: 'coppie',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'radio-sec flex-1',
                            templateOptions: {
                              key: 'coppie',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'coppie',
                                },
                              ],
                            },
                          },
                          {
                            key: 'terne',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'radio-sec flex-1',
                            templateOptions: {
                              key: 'terne',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'terne',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName:
                          'display-inline-flex flex-1 width-100',
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            key: 'Altro 1',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'width-100 radio-sec flex-1',
                            templateOptions: {
                              key: 'Altro 1',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Altro',
                                },
                              ],
                              change: (field) => {
                                if (field.formControl.value !== true) {
                                  field.formControl.parent
                                    ?.get(['Altro Input 1'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Altro Input 1'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Altro Input 1'])
                                    ?.enable();
                                }
                              },
                              onReset: (field) => {
                                if (field.formControl.value !== true) {
                                  field.formControl.parent
                                    ?.get(['Altro Input 1'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Altro Input 1'])
                                    ?.disable();
                                }
                              },
                            },
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (field.formControl.value !== true) {
                                    field.formControl.parent
                                      ?.get(['Altro Input 1'])
                                      ?.setValue(null);
                                    field.formControl.parent
                                      ?.get(['Altro Input 1'])
                                      ?.disable();
                                  } else {
                                    field.formControl.parent
                                      ?.get(['Altro Input 1'])
                                      ?.enable();
                                  }
                                }, 0);
                              },
                            },
                          },
                          {
                            className: 'sub-sec flex-2',
                            type: 'input',
                            defaultValue: '',
                            key: 'Altro Input 1',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              label: 'Altro',
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                template: `<p>Controllo vibrazione</p>`,
                              },
                              {
                                key: 'Controllo vibrazione',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Controllo vibrazione',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Tipo di ancoraggio'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Tipo di ancoraggio'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Tipo di ancoraggio'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Tipo di ancoraggio'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Tipo di ancoraggio'])
                                        ?.disable();
                                    }
                                  },
                                },
                                hooks: {
                                  onInit: (field) => {
                                    setTimeout(() => {
                                      if (field.formControl.value !== 'SI') {
                                        field.formControl.parent
                                          ?.get(['Tipo di ancoraggio'])
                                          ?.setValue(null);
                                        field.formControl.parent
                                          ?.get(['Tipo di ancoraggio'])
                                          ?.disable();
                                      } else {
                                        field.formControl.parent
                                          ?.get(['Tipo di ancoraggio'])
                                          ?.enable();
                                      }
                                    }, 0);
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10 ',
                        key: 'Tipo di ancoraggio',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Tipo di ancoraggio',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                template: `<p>Catena di sicurezza</p>`,
                              },
                              {
                                key: 'Catena di sicurezza',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Catena di sicurezza',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10 ',
                        key: 'Note 1',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Note: ',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                template: `<p>Prove di collaudo distacco ventilazione</p>`,
                                templateOptions: {
                                  title:
                                    'Prove di collaudo distacco ventilazione',
                                },
                              },
                              {
                                key: 'Prove di collaudo distacco ventilazione',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Prove di collaudo distacco ventilazione',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10 ',
                        key: 'Note 2',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Note: ',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                wrappers: ['cube-sub-title-wrapper'],
                                templateOptions: {
                                  title: 'Alimentazione elettrica',
                                },
                              },
                              {
                                key: 'Alimentazione elettrica',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Alimentazione elettrica',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10 ',
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                template: `<p>Alimentazione di emergenza</p>`,
                              },
                              {
                                key: 'Alimentazione di emergenza',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Alimentazione di emergenza',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Autonomia 1'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Autonomia 1'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Autonomia 1'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Autonomia 1'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Autonomia 1'])
                                        ?.disable();
                                    }
                                  },
                                },
                                hooks: {
                                  onInit: (field) => {
                                    setTimeout(() => {
                                      if (field.formControl.value !== 'SI') {
                                        field.formControl.parent
                                          ?.get(['Autonomia 1'])
                                          ?.setValue(null);
                                        field.formControl.parent
                                          ?.get(['Autonomia 1'])
                                          ?.disable();
                                      } else {
                                        field.formControl.parent
                                          ?.get(['Autonomia 1'])
                                          ?.enable();
                                      }
                                    }, 0);
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10 ',
                        key: 'Autonomia 1',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Autonomia [ore]',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                template: `<p>Alimentazione sicurezza (UPS)</p>`,
                              },
                              {
                                key: 'Alimentazione sicurezza',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Alimentazione sicurezza',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Autonomia 2'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Autonomia 2'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Autonomia 2'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Autonomia 2'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Autonomia 2'])
                                        ?.disable();
                                    }
                                  },
                                },
                                hooks: {
                                  onInit: (field) => {
                                    setTimeout(() => {
                                      if (field.formControl.value !== 'SI') {
                                        field.formControl.parent
                                          ?.get(['Autonomia 2'])
                                          ?.setValue(null);
                                        field.formControl.parent
                                          ?.get(['Autonomia 2'])
                                          ?.disable();
                                      } else {
                                        field.formControl.parent
                                          ?.get(['Autonomia 2'])
                                          ?.enable();
                                      }
                                    }, 0);
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10 ',
                        key: 'Autonomia 2',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Autonomia [minuti]',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                template: `<p>Distribuzione in volta</p>`,
                              },
                              {
                                key: 'Distribuzione in volta',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Distribuzione in volta',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10 ',
                        key: 'Tipo 1',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          defaultValue: 'Tipo',
                          label: 'Tipo',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                template: `<p>Prove di collaudo distacco apparecchi elettrici</p>`,
                              },
                              {
                                key: 'Prove di collaudo distacco apparecchi elettrici',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Prove di collaudo distacco apparecchi elettrici',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10 ',
                        key: 'Note 3',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Note: ',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                wrappers: ['cube-sub-title-wrapper'],
                                templateOptions: {
                                  title: 'Rilevatori lineari d’incendio',
                                },
                              },
                              {
                                key: 'Rilevatori lineari d’incendio',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Rilevatori lineari d’incendio',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10 ',
                        key: 'Tipo 2',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Tipo',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                wrappers: ['cube-sub-title-wrapper'],
                                templateOptions: {
                                  title: 'Erogazione idrica',
                                },
                              },
                              {
                                key: 'Erogazione idrica',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Erogazione idrica',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10 ',
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                template: `<p>Distribuzione idrica</p>`,
                              },
                              {
                                key: 'Distribuzione idrica',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Distribuzione idrica',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'interrata', label: 'interrata' },
                                    { value: 'a vista', label: 'a vista' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10 ',
                        key: 'Note 4',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Note: ',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                wrappers: ['cube-sub-title-wrapper'],
                                templateOptions: {
                                  title: 'Stazioni di emergenza',
                                },
                              },
                              {
                                key: 'Stazioni di emergenza',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Stazioni di emergenza',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10 ',
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                template: `<p>Localizzazione in nicchia</p>`,
                              },
                              {
                                key: 'Localizzazione in nicchia',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Localizzazione in nicchia',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10 ',
                        key: 'distanza massima',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'distanza massima [m]',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          maxDecimalPlaces: 3,
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                wrappers: ['cube-sub-title-wrapper'],
                                templateOptions: {
                                  title: 'Segnaletica verticale',
                                },
                              },
                              {
                                key: 'Segnaletica verticale',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Segnaletica verticale',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10 ',
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                template: `<p>Segnaletica luminosa</p>`,
                              },
                              {
                                key: 'Segnaletica luminosa',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Segnaletica luminosa',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10 ',
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                template: `<p>Segnaletica sulla carreggiata</p>`,
                              },
                              {
                                key: 'Segnaletica sulla carreggiata',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Segnaletica sulla carreggiata',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10 ',
                        key: 'Superficie cartelli',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Superficie cartelli [m²] ',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          maxDecimalPlaces: 3,
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex margin-auto',
                            fieldGroup: [
                              {
                                className: 'flex-1',
                                template: `<p>Segnaletica esterna alla carreggiata</p>`,
                              },
                              {
                                key: 'Segnaletica esterna alla carreggiata',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Segnaletica esterna alla carreggiata',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['altezza'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['larghezza'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['diametro'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['altezza'])
                                        ?.disable();
                                      field.formControl.parent
                                        ?.get(['larghezza'])
                                        ?.disable();
                                      field.formControl.parent
                                        ?.get(['diametro'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['altezza'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['larghezza'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['diametro'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['altezza'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['altezza'])
                                        ?.disable();
                                      field.formControl.parent
                                        ?.get(['larghezza'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['larghezza'])
                                        ?.disable();
                                      field.formControl.parent
                                        ?.get(['diametro'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['diametro'])
                                        ?.disable();
                                    }
                                  },
                                },
                                hooks: {
                                  onInit: (field) => {
                                    setTimeout(() => {
                                      if (field.formControl.value !== 'SI') {
                                        field.formControl.parent
                                          ?.get(['altezza'])
                                          ?.setValue(null);
                                        field.formControl.parent
                                          ?.get(['larghezza'])
                                          ?.setValue(null);
                                        field.formControl.parent
                                          ?.get(['diametro'])
                                          ?.setValue(null);
                                        field.formControl.parent
                                          ?.get(['altezza'])
                                          ?.disable();
                                        field.formControl.parent
                                          ?.get(['larghezza'])
                                          ?.disable();
                                        field.formControl.parent
                                          ?.get(['diametro'])
                                          ?.disable();
                                      } else {
                                        field.formControl.parent
                                          ?.get(['altezza'])
                                          ?.enable();
                                        field.formControl.parent
                                          ?.get(['larghezza'])
                                          ?.enable();
                                        field.formControl.parent
                                          ?.get(['diametro'])
                                          ?.enable();
                                      }
                                    }, 0);
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex-wrap grid-gap',
                        className: 'flex-1 border-left',
                        fieldGroup: [
                          {
                            className: 'flex-1',
                            template: `<p>Dimensioni cartelli [cm]</p>`,
                          },
                          {
                            className: 'flex-1 padding-left-10',
                            key: 'altezza',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label: 'altezza',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                          {
                            className: 'flex-1',
                            key: 'larghezza',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label: 'larghezza',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                          {
                            className: 'flex-1',
                            key: 'diametro',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label: 'diametro',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                template: `<p>Segnaletica verticale agli imbocchi</p>`,
                              },
                              {
                                key: 'Segnaletica verticale agli imbocchi',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Segnaletica verticale agli imbocchi',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10 ',
                        key: 'Note 5',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Note: ',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                template: `<p>Segnaletica di esodo</p>`,
                              },
                              {
                                key: 'Segnaletica di esodo',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Segnaletica di esodo',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10 ',
                        key: 'Note 6',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Note:',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Pannelli a messaggio variabile',
                    },
                  },
                  {
                    fieldGroupClassName:
                      'display-flex grid-gap border-bottom border-top',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                template: `<p>Pannelli alfanumerici</p>`,
                              },
                              {
                                key: 'Pannelli alfanumerici',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Pannelli alfanumerici',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10 ',
                        key: 'numero 1',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'numero',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: 'flex-1 margin-auto',
                                template: `<p>Pannelli a pittogrammi</p>`,
                              },
                              {
                                key: 'Pannelli a pittogrammi',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Pannelli a pittogrammi',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10 ',
                        key: 'numero 2',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'numero',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                template: `<p>Indicatori di corsia (freccia-croce)</p>`,
                              },
                              {
                                key: 'Indicatori di corsia',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Indicatori di corsia',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10',
                        key: 'numero 3',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'numero',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Impianto per chiudere la galleria',
                    },
                  },
                  {
                    fieldGroupClassName:
                      'display-flex grid-gap border-bottom border-top',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                template: `<p>Semafori agli imbocchi</p>`,
                              },
                              {
                                key: 'Semafori agli imbocchi',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Semafori agli imbocchi',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10 ',
                      },
                    ],
                  },
                  {
                    fieldGroupClassName:
                      'display-flex grid-gap border-bottom border-top',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                template: `<p>barriere</p>`,
                              },
                              {
                                key: 'barriere',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'barriere',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10 ',
                      },
                    ],
                  },
                  {
                    fieldGroupClassName:
                      'display-flex grid-gap border-bottom border-top',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                wrappers: ['cube-sub-title-wrapper'],
                                templateOptions: {
                                  title: 'Telecamere in galleria',
                                },
                              },
                              {
                                key: 'Telecamere in galleria',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Telecamere in galleria',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['numero 4'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['numero 4'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['numero 4'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['numero 4'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['numero 4'])
                                        ?.disable();
                                    }
                                  },
                                },
                                hooks: {
                                  onInit: (field) => {
                                    setTimeout(() => {
                                      if (field.formControl.value !== 'SI') {
                                        field.formControl.parent
                                          ?.get(['numero 4'])
                                          ?.setValue(null);
                                        field.formControl.parent
                                          ?.get(['numero 4'])
                                          ?.disable();
                                      } else {
                                        field.formControl.parent
                                          ?.get(['numero 4'])
                                          ?.enable();
                                      }
                                    }, 0);
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10',
                        key: 'numero 4',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'numero',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex',
                            fieldGroup: [
                              {
                                className: 'flex-1',
                                template: `<p>Centro di controllo</p>`,
                              },
                              {
                                key: 'Centro di controllo',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Centro di controllo',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Operatività'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Operatività'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Operatività'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Operatività'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Operatività'])
                                        ?.disable();
                                    }
                                  },
                                },
                                hooks: {
                                  onInit: (field) => {
                                    setTimeout(() => {
                                      if (field.formControl.value !== 'SI') {
                                        field.formControl.parent
                                          ?.get(['Operatività'])
                                          ?.setValue(null);
                                        field.formControl.parent
                                          ?.get(['Operatività'])
                                          ?.disable();
                                      } else {
                                        field.formControl.parent
                                          ?.get(['Operatività'])
                                          ?.enable();
                                      }
                                    }, 0);
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        className: 'flex-1 border-left',
                        fieldGroup: [
                          {
                            className: 'flex-1',
                            key: 'Operatività',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Operatività',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                          {
                            className: 'flex-1 display-flex align-items-center',
                            template: `<div> h / 24 (numero di ore al giorno di controllo)</div>`,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldGroupClassName:
                      'display-flex grid-gap border-bottom border-top',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                wrappers: ['cube-sub-title-wrapper'],
                                templateOptions: {
                                  title: 'Varco spartitraffico imbocchi',
                                },
                              },
                              {
                                key: 'Varco spartitraffico imbocchi',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Varco spartitraffico imbocchi',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10 ',
                        key: 'Note 7',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Note:',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                ],
              },
              // Descrizione degli eventuali interventi strutturali eseguiti
              {
                key: 'Descrizione degli eventuali interventi strutturali eseguiti',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'Descrizione degli eventuali interventi strutturali eseguiti',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    key: 'Consolidamento della volta',
                    fieldGroup: [
                      {
                        key: 'Consolidamento della volta',
                        type: 'checkbox',
                        defaultValue: false,
                        className:
                          'width-100 radio-sec border-bottom border-right flex-1',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          key: 'Consolidamento della volta',
                          isBold: true,
                          options: [
                            {
                              value: true,
                              label: 'Consolidamento della volta',
                            },
                          ],
                          change: (field) => {
                            if (field.formControl.value !== true) {
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.setValue(null);
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.disable();
                            } else {
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.enable();
                            }
                          },
                          onReset: (field) => {
                            if (field.formControl.value !== true) {
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.setValue(null);
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.disable();
                            }
                          },
                        },
                        hooks: {
                          onInit: (field) => {
                            setTimeout(() => {
                              if (field.formControl.value !== true) {
                                field.formControl.parent
                                  ?.get(['Descrizione'])
                                  ?.setValue(null);
                                field.formControl.parent
                                  ?.get(['Descrizione'])
                                  ?.disable();
                              } else {
                                field.formControl.parent
                                  ?.get(['Descrizione'])
                                  ?.enable();
                              }
                            }, 0);
                          },
                        },
                      },
                      {
                        key: 'Descrizione',
                        type: 'textarea',
                        defaultValue: '',
                        className: 'flex-1 border-bottom padding-left-10',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          label: 'Descrizione:',
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    key: 'Riparazione Sostituzione di elementi strutturali',
                    fieldGroup: [
                      {
                        key: 'Riparazione Sostituzione di elementi strutturali',
                        type: 'checkbox',
                        defaultValue: false,
                        className:
                          'width-100 radio-sec border-bottom border-right flex-1',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          key: 'Riparazione Sostituzione di elementi strutturali',
                          isBold: true,
                          options: [
                            {
                              value: true,
                              label:
                                'Riparazione/Sostituzione di elementi strutturali',
                            },
                          ],
                          change: (field) => {
                            if (field.formControl.value !== true) {
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.setValue(null);
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.disable();
                            } else {
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.enable();
                            }
                          },
                          onReset: (field) => {
                            if (field.formControl.value !== true) {
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.setValue(null);
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.disable();
                            }
                          },
                        },
                        hooks: {
                          onInit: (field) => {
                            setTimeout(() => {
                              if (field.formControl.value !== true) {
                                field.formControl.parent
                                  ?.get(['Descrizione'])
                                  ?.setValue(null);
                                field.formControl.parent
                                  ?.get(['Descrizione'])
                                  ?.disable();
                              } else {
                                field.formControl.parent
                                  ?.get(['Descrizione'])
                                  ?.enable();
                              }
                            }, 0);
                          },
                        },
                      },
                      {
                        key: 'Descrizione',
                        type: 'textarea',
                        defaultValue: '',
                        className: 'flex-1 border-bottom padding-left-10',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          label: 'Descrizione:',
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    key: 'Ampliamento di carreggiata e delle strutture',
                    fieldGroup: [
                      {
                        key: 'Ampliamento di carreggiata e delle strutture',
                        type: 'checkbox',
                        defaultValue: false,
                        className:
                          'width-100 radio-sec border-bottom border-right flex-1',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          key: 'Ampliamento di carreggiata e delle strutture',
                          isBold: true,
                          options: [
                            {
                              value: true,
                              label:
                                'Ampliamento di carreggiata e delle strutture',
                            },
                          ],
                          change: (field) => {
                            if (field.formControl.value !== true) {
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.setValue(null);
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.disable();
                            } else {
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.enable();
                            }
                          },
                          onReset: (field) => {
                            if (field.formControl.value !== true) {
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.setValue(null);
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.disable();
                            }
                          },
                        },
                        hooks: {
                          onInit: (field) => {
                            setTimeout(() => {
                              if (field.formControl.value !== true) {
                                field.formControl.parent
                                  ?.get(['Descrizione'])
                                  ?.setValue(null);
                                field.formControl.parent
                                  ?.get(['Descrizione'])
                                  ?.disable();
                              } else {
                                field.formControl.parent
                                  ?.get(['Descrizione'])
                                  ?.enable();
                              }
                            }, 0);
                          },
                        },
                      },
                      {
                        key: 'Descrizione',
                        type: 'textarea',
                        defaultValue: '',
                        className: 'flex-1 border-bottom padding-left-10',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          label: 'Descrizione:',
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    key: 'Elementi strutturali aggiuntivi/consolidamento',
                    fieldGroup: [
                      {
                        key: 'Elementi strutturali aggiuntivi/consolidamento',
                        type: 'checkbox',
                        defaultValue: false,
                        className:
                          'width-100 radio-sec border-bottom border-right flex-1',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          key: 'Elementi strutturali aggiuntivi/consolidamento',
                          isBold: true,
                          options: [
                            {
                              value: true,
                              label:
                                'Elementi strutturali aggiuntivi/consolidamento',
                            },
                          ],
                          change: (field) => {
                            if (field.formControl.value !== true) {
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.setValue(null);
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.disable();
                            } else {
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.enable();
                            }
                          },
                          onReset: (field) => {
                            if (field.formControl.value !== true) {
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.setValue(null);
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.disable();
                            }
                          },
                        },
                        hooks: {
                          onInit: (field) => {
                            setTimeout(() => {
                              if (field.formControl.value !== true) {
                                field.formControl.parent
                                  ?.get(['Descrizione'])
                                  ?.setValue(null);
                                field.formControl.parent
                                  ?.get(['Descrizione'])
                                  ?.disable();
                              } else {
                                field.formControl.parent
                                  ?.get(['Descrizione'])
                                  ?.enable();
                              }
                            }, 0);
                          },
                        },
                      },
                      {
                        key: 'Descrizione',
                        type: 'textarea',
                        defaultValue: '',
                        className: 'flex-1 border-bottom padding-left-10',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          label: 'Descrizione:',
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    key: 'Interventi di carattere geotecnico',
                    fieldGroup: [
                      {
                        key: 'Interventi di carattere geotecnico',
                        type: 'checkbox',
                        defaultValue: false,
                        className:
                          'width-100 radio-sec border-bottom border-right flex-1',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          key: 'Interventi di carattere geotecnico',
                          isBold: true,
                          options: [
                            {
                              value: true,
                              label: 'Interventi di carattere geotecnico',
                            },
                          ],
                          change: (field) => {
                            if (field.formControl.value !== true) {
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.setValue(null);
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.disable();
                            } else {
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.enable();
                            }
                          },
                          onReset: (field) => {
                            if (field.formControl.value !== true) {
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.setValue(null);
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.disable();
                            }
                          },
                        },
                        hooks: {
                          onInit: (field) => {
                            setTimeout(() => {
                              if (field.formControl.value !== true) {
                                field.formControl.parent
                                  ?.get(['Descrizione'])
                                  ?.setValue(null);
                                field.formControl.parent
                                  ?.get(['Descrizione'])
                                  ?.disable();
                              } else {
                                field.formControl.parent
                                  ?.get(['Descrizione'])
                                  ?.enable();
                              }
                            }, 0);
                          },
                        },
                      },
                      {
                        key: 'Descrizione',
                        type: 'textarea',
                        defaultValue: '',
                        className: 'flex-1 border-bottom padding-left-10',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          label: 'Descrizione:',
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    key: 'Interventi di mitigazione/protezione dall’erosione',
                    fieldGroup: [
                      {
                        key: 'Interventi di mitigazione/protezione dall’erosione',
                        type: 'checkbox',
                        defaultValue: false,
                        className:
                          'width-100 radio-sec border-bottom border-right flex-1',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          key: 'Interventi di mitigazione/protezione dall’erosione',
                          isBold: true,
                          options: [
                            {
                              value: true,
                              label:
                                'Interventi di mitigazione/protezione dall’erosione',
                            },
                          ],
                          change: (field) => {
                            if (field.formControl.value !== true) {
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.setValue(null);
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.disable();
                            } else {
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.enable();
                            }
                          },
                          onReset: (field) => {
                            if (field.formControl.value !== true) {
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.setValue(null);
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.disable();
                            }
                          },
                        },
                        hooks: {
                          onInit: (field) => {
                            setTimeout(() => {
                              if (field.formControl.value !== true) {
                                field.formControl.parent
                                  ?.get(['Descrizione'])
                                  ?.setValue(null);
                                field.formControl.parent
                                  ?.get(['Descrizione'])
                                  ?.disable();
                              } else {
                                field.formControl.parent
                                  ?.get(['Descrizione'])
                                  ?.enable();
                              }
                            }, 0);
                          },
                        },
                      },
                      {
                        key: 'Descrizione',
                        type: 'textarea',
                        defaultValue: '',
                        className: 'flex-1 border-bottom padding-left-10',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          label: 'Descrizione:',
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    key: 'Fresatura del rivestimento interno',
                    fieldGroup: [
                      {
                        key: 'Fresatura del rivestimento interno',
                        type: 'checkbox',
                        defaultValue: false,
                        className:
                          'width-100 radio-sec border-bottom border-right flex-1 font-weight-bolder',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          key: 'Fresatura del rivestimento interno',
                          isBold: true,
                          options: [
                            {
                              value: true,
                              label: 'Fresatura del rivestimento interno',
                            },
                          ],
                          change: (field) => {
                            if (field.formControl.value !== true) {
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.setValue(null);
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.disable();
                            } else {
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.enable();
                            }
                          },
                          onReset: (field) => {
                            if (field.formControl.value !== true) {
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.setValue(null);
                              field.formControl.parent
                                ?.get(['Descrizione'])
                                ?.disable();
                            }
                          },
                        },
                        hooks: {
                          onInit: (field) => {
                            setTimeout(() => {
                              if (field.formControl.value !== true) {
                                field.formControl.parent
                                  ?.get(['Descrizione'])
                                  ?.setValue(null);
                                field.formControl.parent
                                  ?.get(['Descrizione'])
                                  ?.disable();
                              } else {
                                field.formControl.parent
                                  ?.get(['Descrizione'])
                                  ?.enable();
                              }
                            }, 0);
                          },
                        },
                      },
                      {
                        key: 'Descrizione',
                        type: 'textarea',
                        defaultValue: '',
                        className: 'flex-1 border-bottom padding-left-10',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          label: 'Descrizione:',
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex border-bottom',
                    key: '',
                    fieldGroup: [
                      {
                        key: 'Altro',
                        type: 'checkbox',
                        defaultValue: false,
                        className: 'width-100 radio-sec flex-1',
                        templateOptions: {
                          key: 'Altro',
                          isBold: true,
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          options: [
                            {
                              value: true,
                              label: 'Altro',
                            },
                          ],
                          change: (field) => {
                            if (field.formControl.value !== true) {
                              field.formControl.parent
                                ?.get(['Altro Input'])
                                ?.setValue(null);
                              field.formControl.parent
                                ?.get(['Altro Input'])
                                ?.disable();
                            } else {
                              field.formControl.parent
                                ?.get(['Altro Input'])
                                ?.enable();
                            }
                          },
                          onReset: (field) => {
                            if (field.formControl.value !== true) {
                              field.formControl.parent
                                ?.get(['Altro Input'])
                                ?.setValue(null);
                              field.formControl.parent
                                ?.get(['Altro Input'])
                                ?.disable();
                            }
                          },
                        },
                        hooks: {
                          onInit: (field) => {
                            setTimeout(() => {
                              if (field.formControl.value !== true) {
                                field.formControl.parent
                                  ?.get(['Altro Input'])
                                  ?.setValue(null);
                                field.formControl.parent
                                  ?.get(['Altro Input'])
                                  ?.disable();
                              } else {
                                field.formControl.parent
                                  ?.get(['Altro Input'])
                                  ?.enable();
                              }
                            }, 0);
                          },
                        },
                      },
                      {
                        className: 'sub-sec flex-2',
                        type: 'input',
                        defaultValue: '',
                        key: 'Altro Input',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          label: 'Altro',
                        },
                      },
                    ],
                  },
                ],
              },
              // Interventi di manutenzione
              {
                wrappers: ['cube-section-title-wrapper'],
                templateOptions: {
                  title: 'Interventi di manutenzione',
                },
              },
              {
                key: 'Interventi di manutenzione',
                fieldGroup: [
                  {
                    key: 'Interventi di manutenzione',
                    type: 'radio',
                    defaultValue: '',
                    className: 'sub-sec radio-sec',
                    templateOptions: {
                      key: 'Interventi di manutenzione',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      flex: 'true',
                      options: [
                        {
                          value: 'Presenti',
                          label: 'Presenti',
                        },
                        {
                          value: 'Assenti',
                          label: 'Assenti',
                        },
                        {
                          value: 'Non noti',
                          label: 'Non noti',
                        },
                      ],
                    },
                  },
                  {
                    fieldGroupClassName: 'grid-gap',
                    fieldGroup: [
                      {
                        key: 'Piano di manutenzione',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          className: 'font-weight-bolder',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          label: 'Piano di manutenzione',
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        key: 'Numero di interventi manutentivi effettuati',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          label: 'Numero di interventi manutentivi effettuati',
                        },
                      },
                      {
                        className: 'flex-1',
                        key: 'Data ultimo intervento',
                        type: 'cube-datepicker-type',
                        defaultValue: '',
                        templateOptions: {
                          readonly: true,
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          label: 'Data ultimo intervento',
                          max: new Date(),
                        },
                      },
                      {
                        className: 'flex-1',
                        key: 'Periodicità se più di 1 intervento',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          label: 'Periodicità (se più di 1 intervento)',
                        },
                      },
                    ],
                  },
                  {
                    wrappers: ['cube-repeat-section-wrapper'],
                    templateOptions: {
                      minWidth: '1080px',
                    },
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex repeat-header',
                        fieldGroup: [
                          {
                            className: 'flex-2 border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Tipo di manutenzione',
                              class: 'text-align-center light-grey',
                            },
                          },
                          {
                            className: 'flex-1 border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Documentazione relativa',
                              class: 'text-align-center light-grey',
                            },
                          },
                        ],
                      },
                      {
                        key: 'Tipo di manutenzione section',
                        type: 'cube-repeat-section-type',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          onDeleteRow: (index) => {
                            tunnelInputTemplateComponent.deleteUploadedFiles(
                              tunnelInputTemplateComponent.model[
                                TunnelConstants.levels.l0
                              ]['Tipo di manutenzione section'][index][
                                'Allegato n'
                              ]
                            );
                            tunnelInputTemplateComponent.model[
                              TunnelConstants.levels.l0
                            ]['Tipo di manutenzione section'].splice(index, 1);
                            tunnelInputTemplateComponent.refreshFields();
                          },
                        },
                        fieldArray: {
                          fieldGroupClassName: 'display-flex',
                          fieldGroup: [
                            {
                              className: 'flex-2 border-right',
                              key: 'Intervento n',
                              fieldGroupClassName: 'display-flex grid-gap',
                              fieldGroup: [
                                {
                                  className:
                                    'flex-1 repeat-cell remove-underline border-bottom',
                                  key: 'Intervento n Data',
                                  type: 'cube-datepicker-type',
                                  defaultValue: '',
                                  templateOptions: {
                                    readonly: true,
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                    max: new Date(),
                                  },
                                  expressionProperties: {
                                    'templateOptions.label':
                                      "'Intervento n.' + (+field.parent.parent.key + 1)+ ' (Data)'",
                                  },
                                },
                                {
                                  className:
                                    'flex-1 repeat-cell remove-underline border-bottom',
                                  key: 'Intervento n Data text',
                                  type: 'input',
                                  defaultValue: '',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                              ],
                            },
                            {
                              className:
                                'width-100 flex-1 border-right repeat-cell remove-underline border-bottom',
                              key: 'Allegato n',
                              type: 'cube-file-upload-type',
                              defaultValue: '',
                              templateOptions: {
                                disabled:
                                  tunnelInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                                token: tunnelInputTemplateComponent.token,
                                modelData: tunnelInputTemplateComponent.model,
                              },
                              expressionProperties: {
                                'templateOptions.label': "'Allegato n.'",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
              // Ispezioni pregresse
              {
                wrappers: ['cube-section-title-wrapper'],
                templateOptions: { title: 'Ispezioni pregresse' },
              },
              {
                key: 'Ispezioni pregresse',
                fieldGroup: [
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title:
                        'Ispezioni pregresse ai sensi delle Linee guida sulle attività di indagini, ispezioni, manutenzione e messa in sicurezza delle gallerie stradali esistenti',
                    },
                  },
                  {
                    key: 'Ispezioni pregresse',
                    type: 'radio',
                    defaultValue: '',
                    className: 'sub-sec radio-sec',
                    templateOptions: {
                      key: 'Ispezioni pregresse',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      flex: 'true',
                      options: [
                        {
                          value: 'Presenti',
                          label: 'Presenti',
                        },
                        {
                          value: 'Assenti',
                          label: 'Assenti',
                        },
                        {
                          value: 'Non note',
                          label: 'Non note',
                        },
                      ],
                    },
                  },
                  {
                    fieldGroupClassName: 'grid-gap',
                    fieldGroup: [
                      {
                        key: 'Programma di ispezioni',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          label: 'Programma di ispezioni',
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        key: 'Numero di ispezioni effettuate',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          label: 'Numero di ispezioni effettuate',
                        },
                      },
                      {
                        className: 'flex-1',
                        key: 'Data ultima ispezione',
                        type: 'cube-datepicker-type',
                        defaultValue: '',
                        templateOptions: {
                          readonly: true,
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          label: 'Data ultima ispezione',
                          max: new Date(),
                        },
                      },
                      {
                        className: 'flex-1',
                        key: 'Periodicità se più di 1 ispezione',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          label: 'Periodicità (se più di 1 ispezione)',
                        },
                      },
                    ],
                  },
                  {
                    wrappers: ['cube-repeat-section-wrapper'],
                    templateOptions: {
                      minWidth: '1080px',
                    },
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex repeat-header',
                        fieldGroup: [
                          {
                            className: 'flex-2 border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Metodologia ispezioni',
                              class: 'text-align-center light-grey',
                            },
                          },
                          {
                            className: 'flex-1 border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Documentazione relativa',
                              class: 'text-align-center light-grey',
                            },
                          },
                          {
                            className: 'flex-1 border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Ente ispettivo',
                              class: 'text-align-center light-grey',
                            },
                          },
                        ],
                      },
                      {
                        key: 'Metodologia ispezioni section',
                        type: 'cube-repeat-section-type',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          onDeleteRow: (index) => {
                            tunnelInputTemplateComponent.deleteUploadedFiles(
                              tunnelInputTemplateComponent.model[
                                TunnelConstants.levels.l0
                              ]['Metodologia ispezioni section'][index][
                                'Allegato n'
                              ]
                            );
                            tunnelInputTemplateComponent.model[
                              TunnelConstants.levels.l0
                            ]['Metodologia ispezioni section'].splice(index, 1);
                            tunnelInputTemplateComponent.refreshFields();
                          },
                        },
                        fieldArray: {
                          fieldGroupClassName: 'display-flex',
                          fieldGroup: [
                            {
                              className: 'flex-2 border-right',
                              key: 'Ispezione n',
                              fieldGroupClassName: 'display-flex grid-gap',
                              fieldGroup: [
                                {
                                  className:
                                    'flex-1 repeat-cell remove-underline border-bottom',
                                  key: 'Ispezione n Data',
                                  type: 'cube-datepicker-type',
                                  defaultValue: '',
                                  templateOptions: {
                                    readonly: true,
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                    max: new Date(),
                                  },
                                  expressionProperties: {
                                    'templateOptions.label':
                                      "'Ispezione n.' + (+field.parent.parent.key + 1)+ ' (Data)'",
                                  },
                                },
                                {
                                  className:
                                    'flex-1 repeat-cell remove-underline border-bottom',
                                  key: 'Ispezione n Data text',
                                  type: 'input',
                                  defaultValue: '',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                              ],
                            },
                            {
                              className:
                                'width-100 flex-1 border-right repeat-cell remove-underline border-bottom',
                              key: 'Allegato n',
                              type: 'cube-file-upload-type',
                              defaultValue: '',
                              templateOptions: {
                                disabled:
                                  tunnelInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                                token: tunnelInputTemplateComponent.token,
                                modelData: tunnelInputTemplateComponent.model,
                              },
                              expressionProperties: {
                                'templateOptions.label':
                                  "'Allegato n.' + (+field.parent.key + 1)+ ' (Data)'",
                              },
                            },
                            {
                              className:
                                'flex-1 repeat-cell remove-underline border-bottom border-right',
                              key: 'Ente ispettivo',
                              type: 'input',
                              defaultValue: '',
                              templateOptions: {
                                disabled:
                                  tunnelInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    className: 'margin-auto',
                    key: 'Risultati significativi',
                    type: 'textarea',
                    defaultValue: '',
                    templateOptions: {
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      label: 'Risultati significativi',
                      theme: 'custom',
                      rows: 4,
                      grow: false,
                    },
                  },
                  {
                    template: `<hr>`,
                  },
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title:
                        'Ispezioni pregresse secondo il “Manuale ispezioni delle gallerie” della Direzione Generale per la Vigilanza sulle Concessionarie Autostradali del Ministero delle Infrastrutture e dei Trasporti (prot. n. 13407 del 1 giugno 2020)',
                    },
                  },
                  {
                    key: 'Ispezioni pregresse 1',
                    type: 'radio',
                    defaultValue: '',
                    className: 'sub-sec radio-sec',
                    templateOptions: {
                      key: 'Ispezioni pregresse 1',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      flex: 'true',
                      options: [
                        {
                          value: 'Presenti 1',
                          label: 'Presenti',
                        },
                        {
                          value: 'Assenti 1',
                          label: 'Assenti',
                        },
                        {
                          value: 'Non note 1',
                          label: 'Non note',
                        },
                      ],
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        key: 'Numero di ispezioni effettuate 1',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          label: 'Numero di ispezioni effettuate',
                        },
                      },
                      {
                        className: 'flex-1',
                        key: 'Data ultima ispezione 1',
                        type: 'cube-datepicker-type',
                        defaultValue: '',
                        templateOptions: {
                          readonly: true,
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          label: 'Data ultima ispezione',
                          max: new Date(),
                        },
                      },
                      {
                        className: 'flex-1',
                        key: 'Periodicità se più di 1 ispezione 1',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          label: 'Periodicità (se più di 1 ispezione)',
                        },
                      },
                    ],
                  },
                  {
                    key: 'Risultati significativi 1',
                    type: 'textarea',
                    defaultValue: '',
                    templateOptions: {
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      label: 'Risultati significativi',
                      theme: 'custom',
                      rows: 4,
                      grow: false,
                    },
                  },
                ],
              },
              //Attività di monitoraggio pregresse o in corso
              {
                wrappers: ['cube-section-title-wrapper'],
                templateOptions: {
                  title: 'Attività di monitoraggio pregresse o in corso',
                },
              },
              {
                key: 'Attività di monitoraggio pregresse o in corso',
                fieldGroup: [
                  {
                    key: 'Attività di monitoraggio pregresse o in corso',
                    type: 'radio',
                    defaultValue: '',
                    className: 'sub-sec radio-sec',
                    templateOptions: {
                      key: 'Attività di monitoraggio pregresse o in corso',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      flex: 'true',
                      options: [
                        {
                          value: 'Presenti',
                          label: 'Presenti',
                        },
                        {
                          value: 'Assenti',
                          label: 'Assenti',
                        },
                        {
                          value: 'Non note',
                          label: 'Non note',
                        },
                      ],
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        key: 'Tipo rilevamento',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Tipo rilevamento',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                      {
                        className: 'flex-1 border-left',
                        key: 'Data inizio',
                        type: 'cube-datepicker-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Data inizio',
                          readonly: true,
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          max: new Date(),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        key: 'Metodologia monitoraggio',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Metodologia monitoraggio',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                      {
                        className: 'flex-1 border-left',
                        key: 'Data ultimo aggiornamento',
                        type: 'cube-datepicker-type',
                        defaultValue: '',
                        templateOptions: {
                          readonly: true,
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          label: 'Data ultimo aggiornamento',
                          max: new Date(),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        key: 'text',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                      {
                        className: 'flex-1 border-left',
                        key: 'Data fine',
                        type: 'cube-datepicker-type',
                        defaultValue: '',
                        templateOptions: {
                          readonly: true,
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          label: 'Data fine',
                          max: new Date(),
                        },
                      },
                    ],
                  },
                  {
                    key: 'Tipologia strumentazione',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Tipologia strumentazione',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                    },
                  },
                  {
                    key: 'Grandezze misurate',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Grandezze misurate',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                    },
                  },
                  {
                    template: `<hr>`,
                  },
                  {
                    key: 'Risultati significativi',
                    type: 'textarea',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Risultati significativi',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      theme: 'custom',
                      rows: 4,
                      grow: false,
                    },
                  },
                  {
                    template: `<hr>`,
                  },
                  {
                    key: 'Livello Allerta',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Livello Allerta',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        key: 'Documentazione relativa',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Documentazione relativa',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                      {
                        className: 'width-100 flex-1 input-line',
                        key: 'Allegato n',
                        type: 'cube-file-upload-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Allegato n',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          token: tunnelInputTemplateComponent.token,
                          modelData: tunnelInputTemplateComponent.model,
                        },
                      },
                    ],
                  },
                  {
                    className: 'width-100 flex-1',
                    key: 'Attività di monitoraggio pregresse o in corso text',
                    type: 'textarea',
                    defaultValue: '',
                    templateOptions: {
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      label: '',
                      theme: 'custom',
                      rows: 6,
                      grow: false,
                    },
                  },
                ],
              },
              // Condizioni e regole di circolazione
              {
                key: 'Condizioni e regole di circolazione',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Condizioni e regole di circolazione',
                    },
                  },
                  {
                    className: 'flex-1 border-bottom',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Condizioni di circolazione',
                      className: 'border-bottom',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-top',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex border-right',
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            className: 'flex-1',
                            key: 'TGM estivo',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label: 'TGM estivo †³',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                          {
                            className:
                              ' display-flex align-items-center padding-right-10',
                            template: `<div>veicoli/giorno</div>`,
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            className: 'flex-1 padding-left-10',
                            key: 'Traffico d’ora di punta',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Traffico d’ora di punta †³',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                          {
                            className: 'display-flex align-items-center',
                            template: `<div>veicoli/ora</div>`,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    template: `<div><sup> †3</sup>Secondo definizione di cui al Glossario.</div>`,
                  },
                  {
                    template: `<hr>`,
                  },
                  {
                    className: 'flex-1',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Regole di circolazione',
                    },
                  },
                  {
                    fieldGroupClassName:
                      'display-flex grid-gap border-bottom border-top',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                template: `<p>Limite di velocità localizzato</p>`,
                              },
                              {
                                key: 'Limite di velocità localizzato',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Limite di velocità localizzato',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10',
                        key: 'Velocità massima consentita',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Velocità massima consentita [km/h]',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                template: `<p>Divieto di sorpasso per gli autocarri</p>`,
                              },
                              {
                                key: 'Divieto di sorpasso per gli autocarri',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Divieto di sorpasso per gli autocarri',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10',
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto  ',
                                template: `<p>Distanziamento minimo obbligatorio</p>`,
                              },
                              {
                                key: 'Distanziamento minimo obbligatorio',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Distanziamento minimo obbligatorio',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 border-left padding-left-10',
                        key: 'Distanza minima imposta',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Distanza minima imposta †² [m]',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName:
                      'display-flex grid-gap border-bottom border-top',
                    fieldGroup: [
                      {
                        className: 'flex-2',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: 'flex-2 margin-auto',
                                template: `<p>Limitazione di sagoma</p>`,
                              },
                              {
                                key: 'Limitazione di sagoma',
                                type: 'radio',
                                defaultValue: '',
                                className: 'flex-3 sub-sec radio-sec',
                                templateOptions: {
                                  key: 'Limitazione di sagoma',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    {
                                      value: 'Nessuna limitazione presente',
                                      label: 'Nessuna limitazione presente',
                                    },
                                    {
                                      value: 'Altezza massima massa consentita',
                                      label:
                                        'Altezza massima massa consentita [m]',
                                    },
                                  ],
                                  change: (field) => {
                                    if (
                                      field.formControl.value !==
                                      'Altezza massima massa consentita'
                                    ) {
                                      field.formControl.parent
                                        ?.get([
                                          'Altezza massima massa consentita text',
                                        ])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get([
                                          'Altezza massima massa consentita text',
                                        ])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get([
                                          'Altezza massima massa consentita text',
                                        ])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (
                                      field.formControl.value !==
                                      'Altezza massima massa consentita'
                                    ) {
                                      field.formControl.parent
                                        ?.get([
                                          'Altezza massima massa consentita text',
                                        ])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get([
                                          'Altezza massima massa consentita text',
                                        ])
                                        ?.disable();
                                    }
                                  },
                                },
                                hooks: {
                                  onInit: (field) => {
                                    setTimeout(() => {
                                      if (
                                        field.formControl.value !==
                                        'Altezza massima massa consentita'
                                      ) {
                                        field.formControl.parent
                                          ?.get([
                                            'Altezza massima massa consentita text',
                                          ])
                                          ?.setValue(null);
                                        field.formControl.parent
                                          ?.get([
                                            'Altezza massima massa consentita text',
                                          ])
                                          ?.disable();
                                      } else {
                                        field.formControl.parent
                                          ?.get([
                                            'Altezza massima massa consentita text',
                                          ])
                                          ?.enable();
                                      }
                                    }, 0);
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 padding-left-10 ',
                        key: 'Altezza massima massa consentita text',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          required: true,
                          maxDecimalPlaces: 3,
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto  ',
                                template: `<p>Transito di veicoli trasportanti merci pericolose ammesso</p>`,
                              },
                              {
                                key: 'Transito di veicoli trasportanti merci pericolose ammesso',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Transito di veicoli trasportanti merci pericolose ammesso',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'SI', label: 'SI' },
                                    { value: 'NO', label: 'NO' },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['libero scortato'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['libero scortato'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['libero scortato'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['libero scortato'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['libero scortato'])
                                        ?.disable();
                                    }
                                  },
                                },
                                hooks: {
                                  onInit: (field) => {
                                    setTimeout(() => {
                                      if (field.formControl.value !== 'SI') {
                                        field.formControl.parent
                                          ?.get(['libero scortato'])
                                          ?.setValue(null);
                                        field.formControl.parent
                                          ?.get(['libero scortato'])
                                          ?.disable();
                                      } else {
                                        field.formControl.parent
                                          ?.get(['libero scortato'])
                                          ?.enable();
                                      }
                                    }, 0);
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        className: 'flex-1 padding-left-10',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                key: 'libero scortato',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'libero scortato',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'libero', label: 'libero' },
                                    { value: 'scortato', label: 'scortato' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex grid-gap border-bottom',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            fieldGroupClassName: 'display-flex ',
                            fieldGroup: [
                              {
                                className: ' flex-1 margin-auto',
                                template: `<p>Restrizioni al transito di veicoli trasportanti merci pericolose (ADR cap. 8.6)</p>`,
                              },
                              {
                                key: 'Restrizioni al transito di veicoli trasportanti merci pericolose',
                                type: 'radio',
                                defaultValue: '',
                                className: 'sub-sec radio-sec flex-1',
                                templateOptions: {
                                  key: 'Restrizioni al transito di veicoli trasportanti merci pericolose',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  flex: true,
                                  options: [
                                    { value: 'A', label: 'A' },
                                    { value: 'B', label: 'B' },
                                    { value: 'C', label: 'C' },
                                    { value: 'D', label: 'D' },
                                    { value: 'E', label: 'E' },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    template: `<div>
                          <sup> †2</sup>Se il distanziamento è imposto solo a determinate categorie di veicoli, specificare la categoria cui si applica, o distinguere le diverse distanze minime eventualmente imposte a diverse categorie di veicoli.
                      </div>`,
                  },
                  {
                    template: `<hr>`,
                  },
                ],
              },
              // Classi di conseguenza (secondo definizione EN 1990:2002)
              {
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'Classi di conseguenza (secondo definizione EN 1990:2002)',
                    },
                  },
                  {
                    key: 'Classi di conseguenza secondo definizione EN 1990:2002',
                    fieldGroup: [
                      {
                        key: 'Classi di conseguenza secondo definizione EN 1990:2002',
                        className: 'remove-underline',
                        type: 'radio',
                        defaultValue: '',
                        templateOptions: {
                          key: 'Classi di conseguenza secondo definizione EN 1990:2002',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          options: [
                            {
                              label:
                                'CC3 (Elevate conseguenze per perdita di vite umane, o con conseguenze molto gravi in termini economici, sociali o ambientali)',
                              value: 'CC3',
                            },
                          ],
                          change: (field, e) => {
                            if (field.formControl.value !== 'Minore di CC3') {
                              field.formControl.parent
                                ?.get(['Minore di CC3'])
                                ?.setValue(null);
                              field.formControl.parent
                                ?.get(['Minore di CC3'])
                                ?.disable();
                            } else {
                              field.formControl.parent
                                ?.get(['Minore di CC3'])
                                ?.enable();
                            }
                          },
                          onReset: (field) => {
                            field.formControl.parent
                              ?.get(['Minore di CC3'])
                              ?.disable();
                          },
                        },
                        hooks: {
                          onInit: (field) => {
                            setTimeout(() => {
                              if (field.formControl.value !== 'Minore di CC3') {
                                field.formControl.parent
                                  ?.get(['Minore di CC3'])
                                  ?.setValue(null);
                                field.formControl.parent
                                  ?.get(['Minore di CC3'])
                                  ?.disable();
                              } else {
                                const isFieldDisabled =
                                  tunnelInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  );
                                if (!isFieldDisabled) {
                                  field.formControl.parent
                                    ?.get(['Minore di CC3'])
                                    ?.enable();
                                }
                              }
                            }, 0);
                          },
                        },
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            key: 'Classi di conseguenza secondo definizione EN 1990:2002',
                            className: 'remove-underline',
                            type: 'radio',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              key: 'Classi di conseguenza secondo definizione EN 1990:2002',
                              options: [
                                {
                                  label: 'Minore di CC3',
                                  value: 'Minore di CC3',
                                },
                              ],
                              change: (field, e) => {
                                if (
                                  field.formControl.value !== 'Minore di CC3'
                                ) {
                                  field.formControl.parent
                                    ?.get(['Minore di CC3'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Minore di CC3'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Minore di CC3'])
                                    ?.enable();
                                }
                              },
                              onReset: (field) => {
                                field.formControl.parent
                                  ?.get(['Minore di CC3'])
                                  ?.disable();
                              },
                            },
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.value !== 'Minore di CC3'
                                  ) {
                                    field.formControl.parent
                                      ?.get(['Minore di CC3'])
                                      ?.setValue(null);
                                    field.formControl.parent
                                      ?.get(['Minore di CC3'])
                                      ?.disable();
                                  } else {
                                    if (!isDisabled) {
                                      field.formControl.parent
                                        ?.get(['Minore di CC3'])
                                        ?.enable();
                                    }
                                  }
                                }, 0);
                              },
                            },
                          },
                          {
                            key: 'Minore di CC3',
                            type: 'radio',
                            className: 'width-100 sub-sec radio-sec',
                            templateOptions: {
                              key: 'Minore di CC3',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value:
                                    'CC2 (Conseguenze medie per perdita di vite umane, o con conseguenze considerevoli in termini economici, sociali o ambientali considerevoli)',
                                  label:
                                    'CC2 (Conseguenze medie per perdita di vite umane, o con conseguenze considerevoli in termini economici, sociali o ambientali considerevoli)',
                                },
                                {
                                  value:
                                    'CC1 (Conseguenze basse per perdita di vite umane, o con conseguenze modeste o trascurabili in termini economici, sociali o ambientali)',
                                  label:
                                    'CC1 (Conseguenze basse per perdita di vite umane, o con conseguenze modeste o trascurabili in termini economici, sociali o ambientali)',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        className: 'width-100 flex-1',
                        key: 'In caso di classe di conseguenza minore di CC3, fornire adeguata giustificazione',
                        type: 'textarea',
                        defaultValue: '',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          label:
                            'In caso di classe di conseguenza minore di CC3, fornire adeguata giustificazione:',
                        },
                      },
                    ],
                  },
                ],
              },
              // Documenti progettuali disponibili
              {
                key: 'Documenti progettuali disponibili',
                fieldGroup: [
                  {
                    key: 'Documentazione iniziale',
                    fieldGroup: [
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Documenti progettuali disponibili',
                        },
                      },
                      {
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className:
                              'width-100 flex-1 margin-auto border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Documentazione iniziale',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Fonte',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Allegato n.',
                            },
                          },
                        ],
                      },
                      {
                        key: 'Strumenti del finanziamento',
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Strumenti del finanziamento',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    field.formControl.get(['Fonte'])?.enable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.enable();
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Strumenti del finanziamento',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  label: 'Fonte',
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Strumenti del finanziamento',
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  multiple: true,
                                  token: tunnelInputTemplateComponent.token,
                                  modelData: tunnelInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Piani e strumento di programmazione',
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Piani e strumento di programmazione',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    field.formControl.get(['Fonte'])?.enable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.enable();
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Piani e strumento di programmazione',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  label: 'Fonte',
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Piani e strumento di programmazione',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  multiple: true,
                                  token: tunnelInputTemplateComponent.token,
                                  modelData: tunnelInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Progetto preliminare/di massima',
                    fieldGroup: [
                      {
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className:
                              'width-100 flex-1 margin-auto border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Progetto preliminare/di massima',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Fonte',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Allegato n.',
                            },
                          },
                        ],
                      },
                      {
                        key: 'Documenti descrittivi',
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Documenti descrittivi',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    field.formControl.get(['Fonte'])?.enable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.enable();
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Documenti descrittivi',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  label: 'Fonte',
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Documenti descrittivi',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  multiple: true,
                                  token: tunnelInputTemplateComponent.token,
                                  modelData: tunnelInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Documenti grafici',
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Documenti grafici',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    field.formControl.get(['Fonte'])?.enable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.enable();
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Documenti grafici',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Fonte',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Documenti grafici',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  multiple: true,
                                  token: tunnelInputTemplateComponent.token,
                                  modelData: tunnelInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Documenti inerenti l’iter di approvazione',
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title:
                                'Documenti inerenti l’iter di approvazione',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    field.formControl.get(['Fonte'])?.enable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.enable();
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Documenti inerenti l’iter di approvazione',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Fonte',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Documenti inerenti l’iter di approvazione',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  multiple: true,
                                  token: tunnelInputTemplateComponent.token,
                                  modelData: tunnelInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Progetto definitivo/esecutivo',
                    fieldGroup: [
                      {
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className:
                              'width-100 flex-1 margin-auto border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Progetto definitivo/esecutivo',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Fonte',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Allegato n.',
                            },
                          },
                        ],
                      },
                      {
                        key: 'Documenti descrittivi',
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Documenti descrittivi',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    field.formControl.get(['Fonte'])?.enable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.enable();
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Documenti descrittivi2',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Fonte',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Documenti descrittivi2',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  multiple: true,
                                  token: tunnelInputTemplateComponent.token,
                                  modelData: tunnelInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Documenti grafici',
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Documenti grafici',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    field.formControl.get(['Fonte'])?.enable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.enable();
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Documenti grafici2',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Fonte',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Documenti grafici2',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  multiple: true,
                                  token: tunnelInputTemplateComponent.token,
                                  modelData: tunnelInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Documenti inerenti l’iter di approvazione',
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title:
                                'Documenti inerenti l’iter di approvazione',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    field.formControl.get(['Fonte'])?.enable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.enable();
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Documenti inerenti l’iter di approvazione1',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Fonte',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Documenti inerenti l’iter di approvazione1',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  multiple: true,
                                  token: tunnelInputTemplateComponent.token,
                                  modelData: tunnelInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Progetto esecutivo/cantierabile',
                    fieldGroup: [
                      {
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className:
                              'width-100 flex-1 margin-auto border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Progetto esecutivo/cantierabile',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Fonte',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Allegato n.',
                            },
                          },
                        ],
                      },
                      {
                        key: 'Documenti descrittivi',
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Documenti descrittivi',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    field.formControl.get(['Fonte'])?.enable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.enable();
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Documenti descrittivi3',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Fonte',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Documenti descrittivi3',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  multiple: true,
                                  token: tunnelInputTemplateComponent.token,
                                  modelData: tunnelInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Documenti grafici',
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Documenti grafici',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    field.formControl.get(['Fonte'])?.enable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.enable();
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Documenti grafici3',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Fonte',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Documenti grafici3',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  multiple: true,
                                  token: tunnelInputTemplateComponent.token,
                                  modelData: tunnelInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Documenti inerenti l’iter di approvazione',
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title:
                                'Documenti inerenti l’iter di approvazione',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    field.formControl.get(['Fonte'])?.enable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.enable();
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Documenti inerenti l’iter di approvazione3',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Fonte',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Documenti inerenti l’iter di approvazione3',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  multiple: true,
                                  token: tunnelInputTemplateComponent.token,
                                  modelData: tunnelInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Documenti inerenti la direzione dei lavori',
                    fieldGroup: [
                      {
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className:
                              'width-100 flex-1 margin-auto border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title:
                                'Documenti inerenti la direzione dei lavori',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 text-align-center border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Fonte',
                            },
                          },
                          {
                            className: 'width-100 flex-3 text-align-center',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Allegato n.',
                            },
                          },
                        ],
                      },
                      {
                        key: 'Documenti contabili6',
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Documenti contabili',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    field.formControl.get(['Fonte'])?.enable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.enable();
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Documenti contabili6',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  label: 'Fonte',
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Documenti contabili6',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  multiple: true,
                                  token: tunnelInputTemplateComponent.token,
                                  modelData: tunnelInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Grafici allegati alla contabilità dell’appalto',
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title:
                                'Grafici allegati alla contabilità dell’appalto',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    field.formControl.get(['Fonte'])?.enable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.enable();
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Grafici allegati alla contabilità dell’appalto4',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Fonte',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Grafici allegati alla contabilità dell’appalto4',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  multiple: true,
                                  token: tunnelInputTemplateComponent.token,
                                  modelData: tunnelInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Documenti inerenti la realizzazione',
                    fieldGroup: [
                      {
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className:
                              'width-100 flex-1 margin-auto border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Documenti inerenti la realizzazione',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 text-align-center border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Fonte',
                            },
                          },
                          {
                            className: 'width-100 flex-3 text-align-center',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Allegato n.',
                            },
                          },
                        ],
                      },
                      {
                        key: 'Documenti contrattuali',
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Documenti contrattuali',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    field.formControl.get(['Fonte'])?.enable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.enable();
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Documenti contrattuali5',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Fonte',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Documenti contrattuali5',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  multiple: true,
                                  token: tunnelInputTemplateComponent.token,
                                  modelData: tunnelInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Documenti contabili',
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Documenti contabili',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    field.formControl.get(['Fonte'])?.enable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.enable();
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Documenti contabili5',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Fonte',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Documenti contabili5',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  multiple: true,
                                  token: tunnelInputTemplateComponent.token,
                                  modelData: tunnelInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Varianti in corso d’opera',
                    fieldGroup: [
                      {
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className:
                              'width-100 flex-1 margin-auto border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Varianti in corso d’opera',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Fonte',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Allegato n.',
                            },
                          },
                        ],
                      },
                      {
                        fieldGroup: [
                          {
                            key: 'Varianti',
                            type: 'cube-repeat-section-type',
                            templateOptions: {
                              addButtonText: 'Add Variante',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                            fieldArray: {
                              fieldGroup: [
                                {
                                  fieldGroupClassName:
                                    'display-flex border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      expressionProperties: {
                                        'templateOptions.title':
                                          "'Variante ' + (+field.parent.parent.key + 1)",
                                      },
                                    },
                                    {},
                                  ],
                                },
                                {
                                  key: 'Documenti descrittivi Variante',
                                  fieldGroupClassName:
                                    'display-flex border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      templateOptions: {
                                        title: 'Documenti descrittivi',
                                      },
                                    },
                                    {
                                      className:
                                        'flex-6 remove-underline border-left',
                                      fieldGroupClassName:
                                        'display-flex align-items-center input-field-aligment padding-10',
                                      hooks: {
                                        onInit: (field) => {
                                          setTimeout(() => {
                                            if (
                                              field.formControl.get(['radio'])
                                                .value !== 'SI'
                                            ) {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.setValue(null);
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.disable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.setValue('');
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.disable();
                                            } else {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.enable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.enable();
                                            }
                                          }, 0);
                                        },
                                      },
                                      fieldGroup: [
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti descrittivi Variante ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            options: [
                                              {
                                                value: 'SI',
                                                label: 'SI',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Fonte',
                                          type: 'input',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Fonte',
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                          },
                                        },
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti descrittivi Variante ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            options: [
                                              {
                                                value: 'NO',
                                                label: 'NO',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Allegato n',
                                          type: 'cube-file-upload-type',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Allegato n',
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            multiple: true,
                                            token:
                                              tunnelInputTemplateComponent.token,
                                            modelData:
                                              tunnelInputTemplateComponent.model,
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  key: 'Documenti grafici Variante',
                                  fieldGroupClassName:
                                    'display-flex border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      templateOptions: {
                                        title: 'Documenti grafici',
                                      },
                                    },
                                    {
                                      className:
                                        'flex-6 remove-underline border-left',
                                      fieldGroupClassName:
                                        'display-flex align-items-center input-field-aligment padding-10',
                                      hooks: {
                                        onInit: (field) => {
                                          setTimeout(() => {
                                            if (
                                              field.formControl.get(['radio'])
                                                .value !== 'SI'
                                            ) {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.setValue(null);
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.disable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.setValue('');
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.disable();
                                            } else {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.enable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.enable();
                                            }
                                          }, 0);
                                        },
                                      },
                                      fieldGroup: [
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti grafici Variante ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            options: [
                                              {
                                                value: 'SI',
                                                label: 'SI',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Fonte',
                                          type: 'input',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Fonte',
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                          },
                                        },
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti grafici Variante ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            options: [
                                              {
                                                value: 'NO',
                                                label: 'NO',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Allegato n',
                                          type: 'cube-file-upload-type',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Allegato n',
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            multiple: true,
                                            token:
                                              tunnelInputTemplateComponent.token,
                                            modelData:
                                              tunnelInputTemplateComponent.model,
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  key: 'Documenti contrattuali e contabili Variante',
                                  fieldGroupClassName:
                                    'display-flex border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      templateOptions: {
                                        title:
                                          'Documenti contrattuali e contabili',
                                      },
                                    },
                                    {
                                      className:
                                        'flex-6 remove-underline border-left',
                                      fieldGroupClassName:
                                        'display-flex align-items-center input-field-aligment padding-10',
                                      hooks: {
                                        onInit: (field) => {
                                          setTimeout(() => {
                                            if (
                                              field.formControl.get(['radio'])
                                                .value !== 'SI'
                                            ) {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.setValue(null);
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.disable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.setValue('');
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.disable();
                                            } else {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.enable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.enable();
                                            }
                                          }, 0);
                                        },
                                      },
                                      fieldGroup: [
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti contrattuali e contabili Variante ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            options: [
                                              {
                                                value: 'SI',
                                                label: 'SI',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Fonte',
                                          type: 'input',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Fonte',
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                          },
                                        },
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti contrattuali e contabili Variante ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            options: [
                                              {
                                                value: 'NO',
                                                label: 'NO',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Allegato n',
                                          type: 'cube-file-upload-type',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Allegato n',
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            multiple: true,
                                            token:
                                              tunnelInputTemplateComponent.token,
                                            modelData:
                                              tunnelInputTemplateComponent.model,
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Documenti inerenti il collaudo',
                    fieldGroup: [
                      {
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className:
                              'width-100 flex-1 margin-auto border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Documenti inerenti il collaudo',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Fonte',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Allegato n.',
                            },
                          },
                        ],
                      },
                      {
                        key: 'Relazione di collaudo',
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Relazione di collaudo',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    field.formControl.get(['Fonte'])?.enable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.enable();
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Relazione di collaudo8',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Fonte',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Relazione di collaudo8',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  multiple: true,
                                  token: tunnelInputTemplateComponent.token,
                                  modelData: tunnelInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Allegati alla relazione di collaudo',
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Allegati alla relazione di collaudo',
                            },
                          },
                          {
                            className: 'flex-6 remove-underline border-left',
                            fieldGroupClassName:
                              'display-flex align-items-center input-field-aligment padding-10',
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (
                                    field.formControl.get(['radio']).value !==
                                    'SI'
                                  ) {
                                    field.formControl
                                      .get(['Fonte'])
                                      ?.setValue(null);
                                    field.formControl.get(['Fonte'])?.disable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.setValue('');
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.disable();
                                  } else {
                                    field.formControl.get(['Fonte'])?.enable();
                                    field.formControl
                                      .get(['Allegato n'])
                                      ?.enable();
                                  }
                                }, 0);
                              },
                            },
                            fieldGroup: [
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Allegati alla relazione di collaudo8',
                                  options: [
                                    {
                                      value: 'SI',
                                      label: 'SI',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Fonte',
                                type: 'input',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Fonte',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                },
                              },
                              {
                                key: 'radio',
                                type: 'radio',
                                className: 'radio-sec padding-10',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  key: 'Allegati alla relazione di collaudo8',
                                  options: [
                                    {
                                      value: 'NO',
                                      label: 'NO',
                                    },
                                  ],
                                  change: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                  onReset: (field) => {
                                    if (field.formControl.value !== 'SI') {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.setValue(null);
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.disable();
                                      tunnelInputTemplateComponent.deleteUploadedFiles(
                                        field.formControl.parent?.get([
                                          'Allegato n',
                                        ])?.value
                                      );
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.setValue('');
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.disable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Fonte'])
                                        ?.enable();
                                      field.formControl.parent
                                        ?.get(['Allegato n'])
                                        ?.enable();
                                    }
                                  },
                                },
                              },
                              {
                                className: 'width-100 flex-1 input-line',
                                key: 'Allegato n',
                                type: 'cube-file-upload-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: 'Allegato n',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  multiple: true,
                                  token: tunnelInputTemplateComponent.token,
                                  modelData: tunnelInputTemplateComponent.model,
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Interventi di manutenzione',
                    fieldGroup: [
                      {
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className:
                              'width-100 flex-1 margin-auto border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Interventi di manutenzione',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Fonte',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Allegato n.',
                            },
                          },
                        ],
                      },
                      {
                        fieldGroup: [
                          {
                            key: 'Manutenzione',
                            type: 'cube-repeat-section-type',
                            templateOptions: {
                              addButtonText: 'Add Manutenzione',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                            fieldArray: {
                              fieldGroup: [
                                {
                                  fieldGroupClassName:
                                    'display-flex border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      expressionProperties: {
                                        'templateOptions.title':
                                          "'Manutenzione ' + (+field.parent.parent.key + 1)",
                                      },
                                    },
                                    {},
                                  ],
                                },
                                {
                                  key: 'Documenti descrittivi Manutenzione',
                                  fieldGroupClassName:
                                    'display-flex border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      templateOptions: {
                                        title: 'Documenti descrittivi',
                                      },
                                    },
                                    {
                                      className:
                                        'flex-6 remove-underline border-left',
                                      fieldGroupClassName:
                                        'display-flex align-items-center input-field-aligment padding-10',
                                      hooks: {
                                        onInit: (field) => {
                                          setTimeout(() => {
                                            if (
                                              field.formControl.get(['radio'])
                                                .value !== 'SI'
                                            ) {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.setValue(null);
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.disable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.setValue('');
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.disable();
                                            } else {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.enable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.enable();
                                            }
                                          }, 0);
                                        },
                                      },
                                      fieldGroup: [
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti descrittivi Manutenzione ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            options: [
                                              {
                                                value: 'SI',
                                                label: 'SI',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Fonte',
                                          type: 'input',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Fonte',
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                          },
                                        },
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti descrittivi Manutenzione ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            options: [
                                              {
                                                value: 'NO',
                                                label: 'NO',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Allegato n',
                                          type: 'cube-file-upload-type',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Allegato n',
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            multiple: true,
                                            token:
                                              tunnelInputTemplateComponent.token,
                                            modelData:
                                              tunnelInputTemplateComponent.model,
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  key: 'Documenti grafici Manutenzione',
                                  fieldGroupClassName:
                                    'display-flex border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      templateOptions: {
                                        title: 'Documenti grafici',
                                      },
                                    },
                                    {
                                      className:
                                        'flex-6 remove-underline border-left',
                                      fieldGroupClassName:
                                        'display-flex align-items-center input-field-aligment padding-10',
                                      hooks: {
                                        onInit: (field) => {
                                          setTimeout(() => {
                                            if (
                                              field.formControl.get(['radio'])
                                                .value !== 'SI'
                                            ) {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.setValue(null);
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.disable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.setValue('');
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.disable();
                                            } else {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.enable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.enable();
                                            }
                                          }, 0);
                                        },
                                      },
                                      fieldGroup: [
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti grafici Manutenzione ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            options: [
                                              {
                                                value: 'SI',
                                                label: 'SI',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Fonte',
                                          type: 'input',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Fonte',
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                          },
                                        },
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti grafici Manutenzione ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            options: [
                                              {
                                                value: 'NO',
                                                label: 'NO',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Allegato n',
                                          type: 'cube-file-upload-type',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Allegato n',
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            multiple: true,
                                            token:
                                              tunnelInputTemplateComponent.token,
                                            modelData:
                                              tunnelInputTemplateComponent.model,
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  key: 'Documenti contrattuali e contabili Manutenzione',
                                  fieldGroupClassName:
                                    'display-flex border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      templateOptions: {
                                        title:
                                          'Documenti contrattuali e contabili',
                                      },
                                    },
                                    {
                                      className:
                                        'flex-6 remove-underline border-left',
                                      fieldGroupClassName:
                                        'display-flex align-items-center input-field-aligment padding-10',
                                      hooks: {
                                        onInit: (field) => {
                                          setTimeout(() => {
                                            if (
                                              field.formControl.get(['radio'])
                                                .value !== 'SI'
                                            ) {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.setValue(null);
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.disable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.setValue('');
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.disable();
                                            } else {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.enable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.enable();
                                            }
                                          }, 0);
                                        },
                                      },
                                      fieldGroup: [
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti contrattuali e contabili Manutenzione ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            options: [
                                              {
                                                value: 'SI',
                                                label: 'SI',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Fonte',
                                          type: 'input',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Fonte',
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                          },
                                        },
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti contrattuali e contabili Manutenzione ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            options: [
                                              {
                                                value: 'NO',
                                                label: 'NO',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Allegato n',
                                          type: 'cube-file-upload-type',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Allegato n',
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            multiple: true,
                                            token:
                                              tunnelInputTemplateComponent.token,
                                            modelData:
                                              tunnelInputTemplateComponent.model,
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Segnalazioni',
                    fieldGroup: [
                      {
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className:
                              'width-100 flex-1 margin-auto border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Segnalazioni',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Fonte',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 margin-auto text-align-center',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Allegato n.',
                            },
                          },
                        ],
                      },
                      {
                        fieldGroup: [
                          {
                            key: 'Segnalazione',
                            type: 'cube-repeat-section-type',
                            templateOptions: {
                              addButtonText: 'Add Segnalazione',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                            fieldArray: {
                              fieldGroup: [
                                {
                                  fieldGroupClassName:
                                    'display-flex border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      expressionProperties: {
                                        'templateOptions.title':
                                          "'Segnalazione ' + (+field.parent.parent.key + 1)",
                                      },
                                    },
                                    {},
                                  ],
                                },
                                {
                                  key: 'Documenti descrittivi con date Segnalazione',
                                  fieldGroupClassName:
                                    'display-flex border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      templateOptions: {
                                        title: 'Documenti descrittivi con date',
                                      },
                                    },
                                    {
                                      className:
                                        'flex-6 remove-underline border-left',
                                      fieldGroupClassName:
                                        'display-flex align-items-center input-field-aligment padding-10',
                                      hooks: {
                                        onInit: (field) => {
                                          setTimeout(() => {
                                            if (
                                              field.formControl.get(['radio'])
                                                .value !== 'SI'
                                            ) {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.setValue(null);
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.disable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.setValue('');
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.disable();
                                            } else {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.enable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.enable();
                                            }
                                          }, 0);
                                        },
                                      },
                                      fieldGroup: [
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti descrittivi con date Segnalazione ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            options: [
                                              {
                                                value: 'SI',
                                                label: 'SI',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Fonte',
                                          type: 'input',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Fonte',
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                          },
                                        },
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti descrittivi con date Segnalazione ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            options: [
                                              {
                                                value: 'NO',
                                                label: 'NO',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Allegato n',
                                          type: 'cube-file-upload-type',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Allegato n',
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            multiple: true,
                                            token:
                                              tunnelInputTemplateComponent.token,
                                            modelData:
                                              tunnelInputTemplateComponent.model,
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  key: 'Documenti grafici con date Segnalazione',
                                  fieldGroupClassName:
                                    'display-flex border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      templateOptions: {
                                        title: 'Documenti grafici con date',
                                      },
                                    },
                                    {
                                      className:
                                        'flex-6 remove-underline border-left',
                                      fieldGroupClassName:
                                        'display-flex align-items-center input-field-aligment padding-10',
                                      hooks: {
                                        onInit: (field) => {
                                          setTimeout(() => {
                                            if (
                                              field.formControl.get(['radio'])
                                                .value !== 'SI'
                                            ) {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.setValue(null);
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.disable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.setValue('');
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.disable();
                                            } else {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.enable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.enable();
                                            }
                                          }, 0);
                                        },
                                      },
                                      fieldGroup: [
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti grafici con date Segnalazione ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            options: [
                                              {
                                                value: 'SI',
                                                label: 'SI',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Fonte',
                                          type: 'input',
                                          defaultValue: '',
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            label: 'Fonte',
                                          },
                                        },
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti grafici con date Segnalazione ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            options: [
                                              {
                                                value: 'NO',
                                                label: 'NO',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Allegato n',
                                          type: 'cube-file-upload-type',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Allegato n',
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            multiple: true,
                                            token:
                                              tunnelInputTemplateComponent.token,
                                            modelData:
                                              tunnelInputTemplateComponent.model,
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Progetto di incremento del grado di sicurezza',
                    fieldGroup: [
                      {
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className:
                              'width-100 flex-1 margin-auto border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title:
                                'Progetto di incremento del grado di sicurezza',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 text-align-center border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Fonte',
                            },
                          },
                          {
                            className: 'width-100 flex-3 text-align-center',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Allegato n.',
                            },
                          },
                        ],
                      },
                      {
                        fieldGroup: [
                          {
                            key: 'Progetto',
                            type: 'cube-repeat-section-type',
                            templateOptions: {
                              addButtonText: 'Add Progetto',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                            fieldArray: {
                              fieldGroup: [
                                {
                                  fieldGroupClassName:
                                    'display-flex border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      expressionProperties: {
                                        'templateOptions.title':
                                          "'Progetto ' + (+field.parent.parent.key + 1)",
                                      },
                                    },
                                    {},
                                  ],
                                },
                                {
                                  key: 'Documenti descrittivi Progetto',
                                  fieldGroupClassName:
                                    'display-flex border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      templateOptions: {
                                        title: 'Documenti descrittivi',
                                      },
                                    },
                                    {
                                      className:
                                        'flex-6 remove-underline border-left',
                                      fieldGroupClassName:
                                        'display-flex align-items-center input-field-aligment padding-10',
                                      hooks: {
                                        onInit: (field) => {
                                          setTimeout(() => {
                                            if (
                                              field.formControl.get(['radio'])
                                                .value !== 'SI'
                                            ) {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.setValue(null);
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.disable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.setValue('');
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.disable();
                                            } else {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.enable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.enable();
                                            }
                                          }, 0);
                                        },
                                      },
                                      fieldGroup: [
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti descrittivi Progetto ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            options: [
                                              {
                                                value: 'SI',
                                                label: 'SI',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Fonte',
                                          type: 'input',
                                          defaultValue: '',
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            label: 'Fonte',
                                          },
                                        },
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti descrittivi Progetto ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            options: [
                                              {
                                                value: 'NO',
                                                label: 'NO',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Allegato n',
                                          type: 'cube-file-upload-type',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Allegato n',
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            multiple: true,
                                            token:
                                              tunnelInputTemplateComponent.token,
                                            modelData:
                                              tunnelInputTemplateComponent.model,
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  key: 'Documenti grafici Progetto',
                                  fieldGroupClassName:
                                    'display-flex border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      templateOptions: {
                                        title: 'Documenti grafici',
                                      },
                                    },
                                    {
                                      className:
                                        'flex-6 remove-underline border-left',
                                      fieldGroupClassName:
                                        'display-flex align-items-center input-field-aligment padding-10',
                                      hooks: {
                                        onInit: (field) => {
                                          setTimeout(() => {
                                            if (
                                              field.formControl.get(['radio'])
                                                .value !== 'SI'
                                            ) {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.setValue(null);
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.disable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.setValue('');
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.disable();
                                            } else {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.enable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.enable();
                                            }
                                          }, 0);
                                        },
                                      },
                                      fieldGroup: [
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti grafici Progetto ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            options: [
                                              {
                                                value: 'SI',
                                                label: 'SI',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Fonte',
                                          type: 'input',
                                          defaultValue: '',
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            label: 'Fonte',
                                          },
                                        },
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti grafici Progetto ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            options: [
                                              {
                                                value: 'NO',
                                                label: 'NO',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Allegato n',
                                          type: 'cube-file-upload-type',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Allegato n',
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            multiple: true,
                                            token:
                                              tunnelInputTemplateComponent.token,
                                            modelData:
                                              tunnelInputTemplateComponent.model,
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Interventi di incremento del grado di sicurezza',
                    fieldGroup: [
                      {
                        fieldGroupClassName:
                          'display-flex border-all repeat-header',
                        fieldGroup: [
                          {
                            className:
                              'width-100 flex-1 margin-auto border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title:
                                'Interventi di incremento del grado di sicurezza',
                            },
                          },
                          {
                            className:
                              'width-100 flex-3 text-align-center border-right',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Fonte',
                            },
                          },
                          {
                            className: 'width-100 flex-3 text-align-center',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Allegato n.',
                            },
                          },
                        ],
                      },
                      {
                        fieldGroup: [
                          {
                            key: 'Intervento',
                            type: 'cube-repeat-section-type',
                            templateOptions: {
                              addButtonText: 'Add Intervento',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                            fieldArray: {
                              fieldGroup: [
                                {
                                  fieldGroupClassName:
                                    'display-flex border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      expressionProperties: {
                                        'templateOptions.title':
                                          "'Intervento ' + (+field.parent.parent.key + 1)",
                                      },
                                    },
                                    {},
                                  ],
                                },
                                {
                                  key: 'Documenti descrittivi Intervento',
                                  fieldGroupClassName:
                                    'display-flex border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      templateOptions: {
                                        title: 'Documenti descrittivi',
                                      },
                                    },
                                    {
                                      className:
                                        'flex-6 remove-underline border-left',
                                      fieldGroupClassName:
                                        'display-flex align-items-center input-field-aligment padding-10',
                                      hooks: {
                                        onInit: (field) => {
                                          setTimeout(() => {
                                            if (
                                              field.formControl.get(['radio'])
                                                .value !== 'SI'
                                            ) {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.setValue(null);
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.disable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.setValue('');
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.disable();
                                            } else {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.enable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.enable();
                                            }
                                          }, 0);
                                        },
                                      },
                                      fieldGroup: [
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti descrittivi Intervento ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            options: [
                                              {
                                                value: 'SI',
                                                label: 'SI',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Fonte',
                                          type: 'input',
                                          defaultValue: '',
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            label: 'Fonte',
                                          },
                                        },
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti descrittivi Intervento ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            options: [
                                              {
                                                value: 'NO',
                                                label: 'NO',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Allegato n',
                                          type: 'cube-file-upload-type',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Allegato n',
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            multiple: true,
                                            token:
                                              tunnelInputTemplateComponent.token,
                                            modelData:
                                              tunnelInputTemplateComponent.model,
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  key: 'Documenti grafici Intervento',
                                  fieldGroupClassName:
                                    'display-flex border-all',
                                  fieldGroup: [
                                    {
                                      className: 'width-100 flex-1 margin-auto',
                                      wrappers: ['cube-sub-title-wrapper'],
                                      templateOptions: {
                                        title: 'Documenti grafici',
                                      },
                                    },
                                    {
                                      className:
                                        'flex-6 remove-underline border-left',
                                      fieldGroupClassName:
                                        'display-flex align-items-center input-field-aligment padding-10',
                                      hooks: {
                                        onInit: (field) => {
                                          setTimeout(() => {
                                            if (
                                              field.formControl.get(['radio'])
                                                .value !== 'SI'
                                            ) {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.setValue(null);
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.disable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.setValue('');
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.disable();
                                            } else {
                                              field.formControl
                                                .get(['Fonte'])
                                                ?.enable();
                                              field.formControl
                                                .get(['Allegato n'])
                                                ?.enable();
                                            }
                                          }, 0);
                                        },
                                      },
                                      fieldGroup: [
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti grafici Intervento ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            options: [
                                              {
                                                value: 'SI',
                                                label: 'SI',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Fonte',
                                          type: 'input',
                                          defaultValue: '',
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            label: 'Fonte',
                                          },
                                        },
                                        {
                                          key: 'radio',
                                          type: 'radio',
                                          className: 'radio-sec padding-10',
                                          expressionProperties: {
                                            'templateOptions.key':
                                              "'Documenti grafici Intervento ' + (+field.parent.parent.parent.key + 1)",
                                          },
                                          templateOptions: {
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            options: [
                                              {
                                                value: 'NO',
                                                label: 'NO',
                                              },
                                            ],
                                            change: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                            onReset: (field) => {
                                              if (
                                                field.formControl.value !== 'SI'
                                              ) {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.setValue(null);
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.disable();
                                                tunnelInputTemplateComponent.deleteUploadedFiles(
                                                  field.formControl.parent?.get(
                                                    ['Allegato n']
                                                  )?.value
                                                );
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.setValue('');
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.disable();
                                              } else {
                                                field.formControl.parent
                                                  ?.get(['Fonte'])
                                                  ?.enable();
                                                field.formControl.parent
                                                  ?.get(['Allegato n'])
                                                  ?.enable();
                                              }
                                            },
                                          },
                                        },
                                        {
                                          className:
                                            'width-100 flex-1 input-line',
                                          key: 'Allegato n',
                                          type: 'cube-file-upload-type',
                                          defaultValue: '',
                                          templateOptions: {
                                            label: 'Allegato n',
                                            disabled:
                                              tunnelInputTemplateComponent.isFieldDisabled(
                                                [],
                                                isDisabled
                                              ),
                                            multiple: true,
                                            token:
                                              tunnelInputTemplateComponent.token,
                                            modelData:
                                              tunnelInputTemplateComponent.model,
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                // Documenti sullo stato dell’opera (“Asbuilt”)
                key: 'Documenti sullo stato dell opera Asbuilt',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Documenti sullo stato dell’opera (“Asbuilt”)',
                    },
                  },
                  {
                    fieldGroupClassName:
                      'display-flex border-all repeat-header',
                    fieldGroup: [
                      {
                        className: 'width-100 flex-1 margin-auto border-right',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title: 'Asbuilt',
                        },
                      },
                      {
                        className:
                          'width-100 flex-3 margin-auto text-align-center border-right',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title: 'Fonte',
                        },
                      },
                      {
                        className:
                          'width-100 flex-3 margin-auto text-align-center',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title: 'Allegato n.',
                        },
                      },
                    ],
                  },
                  {
                    key: 'Documenti descrittivi',
                    fieldGroupClassName:
                      'display-flex border-all repeat-header',
                    fieldGroup: [
                      {
                        className: 'width-100 flex-1 margin-auto',
                        template: `<p>Documenti descrittivi</p>`,
                      },
                      {
                        className: 'flex-6 remove-underline border-left',
                        fieldGroupClassName:
                          'display-flex align-items-center input-field-aligment padding-10',
                        hooks: {
                          onInit: (field) => {
                            setTimeout(() => {
                              if (
                                field.formControl.get(['radio']).value !== 'SI'
                              ) {
                                field.formControl
                                  .get(['Fonte'])
                                  ?.setValue(null);
                                field.formControl.get(['Fonte'])?.disable();
                                field.formControl
                                  .get(['Allegato n'])
                                  ?.setValue('');
                                field.formControl
                                  .get(['Allegato n'])
                                  ?.disable();
                              } else {
                                field.formControl.get(['Fonte'])?.enable();
                                field.formControl.get(['Allegato n'])?.enable();
                              }
                            }, 0);
                          },
                        },
                        fieldGroup: [
                          {
                            key: 'radio',
                            type: 'radio',
                            className: 'radio-sec padding-10',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              key: 'Documenti descrittivi16',
                              options: [
                                {
                                  value: 'SI',
                                  label: 'SI',
                                },
                              ],
                              change: (field) => {
                                if (field.formControl.value !== 'SI') {
                                  field.formControl.parent
                                    ?.get(['Fonte'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Fonte'])
                                    ?.disable();
                                  tunnelInputTemplateComponent.deleteUploadedFiles(
                                    field.formControl.parent?.get([
                                      'Allegato n',
                                    ])?.value
                                  );
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.setValue('');
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Fonte'])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.enable();
                                }
                              },
                              onReset: (field) => {
                                if (field.formControl.value !== 'SI') {
                                  field.formControl.parent
                                    ?.get(['Fonte'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Fonte'])
                                    ?.disable();
                                  tunnelInputTemplateComponent.deleteUploadedFiles(
                                    field.formControl.parent?.get([
                                      'Allegato n',
                                    ])?.value
                                  );
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.setValue('');
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Fonte'])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.enable();
                                }
                              },
                            },
                          },
                          {
                            className: 'width-100 flex-1 input-line',
                            key: 'Fonte',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              label: 'Fonte',
                            },
                          },
                          {
                            key: 'radio',
                            type: 'radio',
                            className: 'radio-sec padding-10',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              key: 'Documenti descrittivi16',
                              options: [
                                {
                                  value: 'NO',
                                  label: 'NO',
                                },
                              ],
                              change: (field) => {
                                if (field.formControl.value !== 'SI') {
                                  field.formControl.parent
                                    ?.get(['Fonte'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Fonte'])
                                    ?.disable();
                                  tunnelInputTemplateComponent.deleteUploadedFiles(
                                    field.formControl.parent?.get([
                                      'Allegato n',
                                    ])?.value
                                  );
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.setValue('');
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Fonte'])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.enable();
                                }
                              },
                              onReset: (field) => {
                                if (field.formControl.value !== 'SI') {
                                  field.formControl.parent
                                    ?.get(['Fonte'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Fonte'])
                                    ?.disable();
                                  tunnelInputTemplateComponent.deleteUploadedFiles(
                                    field.formControl.parent?.get([
                                      'Allegato n',
                                    ])?.value
                                  );
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.setValue('');
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Fonte'])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.enable();
                                }
                              },
                            },
                          },
                          {
                            className: 'width-100 flex-1 input-line',
                            key: 'Allegato n',
                            type: 'cube-file-upload-type',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Allegato n',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              token: tunnelInputTemplateComponent.token,
                              modelData: tunnelInputTemplateComponent.model,
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    key: 'Documenti grafici',
                    fieldGroupClassName:
                      'display-flex border-all repeat-header',
                    fieldGroup: [
                      {
                        className: 'width-100 flex-1 margin-auto',
                        template: `<p>Documenti grafici</p>`,
                      },
                      {
                        className: 'flex-6 remove-underline border-left',
                        fieldGroupClassName:
                          'display-flex align-items-center input-field-aligment padding-10',
                        hooks: {
                          onInit: (field) => {
                            setTimeout(() => {
                              if (
                                field.formControl.get(['radio']).value !== 'SI'
                              ) {
                                field.formControl
                                  .get(['Fonte'])
                                  ?.setValue(null);
                                field.formControl.get(['Fonte'])?.disable();
                                field.formControl
                                  .get(['Allegato n'])
                                  ?.setValue('');
                                field.formControl
                                  .get(['Allegato n'])
                                  ?.disable();
                              } else {
                                field.formControl.get(['Fonte'])?.enable();
                                field.formControl.get(['Allegato n'])?.enable();
                              }
                            }, 0);
                          },
                        },
                        fieldGroup: [
                          {
                            key: 'radio',
                            type: 'radio',
                            className: 'radio-sec padding-10',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              key: 'Documenti grafici16',
                              options: [
                                {
                                  value: 'SI',
                                  label: 'SI',
                                },
                              ],
                              change: (field) => {
                                if (field.formControl.value !== 'SI') {
                                  field.formControl.parent
                                    ?.get(['Fonte'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Fonte'])
                                    ?.disable();
                                  tunnelInputTemplateComponent.deleteUploadedFiles(
                                    field.formControl.parent?.get([
                                      'Allegato n',
                                    ])?.value
                                  );
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.setValue('');
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Fonte'])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.enable();
                                }
                              },
                              onReset: (field) => {
                                if (field.formControl.value !== 'SI') {
                                  field.formControl.parent
                                    ?.get(['Fonte'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Fonte'])
                                    ?.disable();
                                  tunnelInputTemplateComponent.deleteUploadedFiles(
                                    field.formControl.parent?.get([
                                      'Allegato n',
                                    ])?.value
                                  );
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.setValue('');
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Fonte'])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.enable();
                                }
                              },
                            },
                          },
                          {
                            className: 'width-100 flex-1 input-line',
                            key: 'Fonte',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              label: 'Fonte',
                            },
                          },
                          {
                            key: 'radio',
                            type: 'radio',
                            className: 'radio-sec padding-10',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              key: 'Documenti grafici16',
                              options: [
                                {
                                  value: 'NO',
                                  label: 'NO',
                                },
                              ],
                              change: (field) => {
                                if (field.formControl.value !== 'SI') {
                                  field.formControl.parent
                                    ?.get(['Fonte'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Fonte'])
                                    ?.disable();
                                  tunnelInputTemplateComponent.deleteUploadedFiles(
                                    field.formControl.parent?.get([
                                      'Allegato n',
                                    ])?.value
                                  );
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.setValue('');
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Fonte'])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.enable();
                                }
                              },
                              onReset: (field) => {
                                if (field.formControl.value !== 'SI') {
                                  field.formControl.parent
                                    ?.get(['Fonte'])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get(['Fonte'])
                                    ?.disable();
                                  tunnelInputTemplateComponent.deleteUploadedFiles(
                                    field.formControl.parent?.get([
                                      'Allegato n',
                                    ])?.value
                                  );
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.setValue('');
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get(['Fonte'])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.enable();
                                }
                              },
                            },
                          },
                          {
                            className: 'width-100 flex-1 input-line',
                            key: 'Allegato n',
                            type: 'cube-file-upload-type',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Allegato n',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              token: tunnelInputTemplateComponent.token,
                              modelData: tunnelInputTemplateComponent.model,
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                // Documenti progettuali disponibili inerenti alle caratteristiche dell’ammasso attraversato
                key: 'Documenti progettuali disponibili inerenti alle caratteristiche dell ammasso attraversato',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'Documenti progettuali disponibili inerenti alle caratteristiche dell’ammasso attraversato',
                    },
                  },
                  {
                    key: 'geologia',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex border-bottom',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            template: `<p>geologia</p>`,
                          },
                          {
                            key: 'geologia - SI/NO',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec flex-2',
                            templateOptions: {
                              key: 'geologia - SI/NO',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                {
                                  value: 'SI',
                                  label: 'SI',
                                },
                                {
                                  value: 'NO',
                                  label: 'NO',
                                },
                              ],
                              change: (field) => {
                                if (field.formControl.value !== 'SI') {
                                  field.formControl.parent
                                    ?.get([
                                      'geologia - aggiornati/parzialmente',
                                    ])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get([
                                      'geologia - aggiornati/parzialmente',
                                    ])
                                    ?.disable();
                                  field.formControl.parent
                                    ?.get([
                                      'geologia - aggiornati/parzialmente',
                                    ])
                                    ?.setValue('');
                                  field.formControl.parent
                                    ?.get([
                                      'geologia - aggiornati/parzialmente',
                                    ])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get([
                                      'geologia - aggiornati/parzialmente',
                                    ])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get([
                                      'geologia - aggiornati/parzialmente',
                                    ])
                                    ?.enable();
                                }
                              },
                              onReset: (field) => {
                                if (field.formControl.value !== 'SI') {
                                  field.formControl.parent
                                    ?.get([
                                      'geologia - aggiornati/parzialmente',
                                    ])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get([
                                      'geologia - aggiornati/parzialmente',
                                    ])
                                    ?.disable();
                                  field.formControl.parent
                                    ?.get([
                                      'geologia - aggiornati/parzialmente',
                                    ])
                                    ?.setValue('');
                                  field.formControl.parent
                                    ?.get([
                                      'geologia - aggiornati/parzialmente',
                                    ])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get([
                                      'geologia - aggiornati/parzialmente',
                                    ])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.enable();
                                }
                              },
                            },
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (field.formControl.value !== 'SI') {
                                    field.formControl.parent
                                      ?.get([
                                        'geologia - aggiornati/parzialmente',
                                      ])
                                      ?.setValue(null);
                                    field.formControl.parent
                                      ?.get([
                                        'geologia - aggiornati/parzialmente',
                                      ])
                                      ?.disable();
                                    field.formControl.parent
                                      ?.get([
                                        'geologia - aggiornati/parzialmente',
                                      ])
                                      ?.setValue('');
                                    field.formControl.parent
                                      ?.get([
                                        'geologia - aggiornati/parzialmente',
                                      ])
                                      ?.disable();
                                  } else {
                                    field.formControl.parent
                                      ?.get([
                                        'geologia - aggiornati/parzialmente',
                                      ])
                                      ?.enable();
                                    field.formControl.parent
                                      ?.get([
                                        'geologia - aggiornati/parzialmente',
                                      ])
                                      ?.enable();
                                  }
                                }, 0);
                              },
                            },
                          },
                          {
                            key: 'geologia - aggiornati/parzialmente',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec flex-2',
                            templateOptions: {
                              key: 'geologia - aggiornati/parzialmente',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                {
                                  value: 'aggiornati',
                                  label: 'aggiornati',
                                },
                                {
                                  value: 'parzialmente non aggiornati',
                                  label: 'parzialmente/non aggiornati',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        className:
                          'width-100 input-line flex-1 border-bottom remove-underline',
                        key: 'geologia - file',
                        type: 'cube-file-upload-type',
                        defaultValue: '',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          token: tunnelInputTemplateComponent.token,
                          modelData: tunnelInputTemplateComponent.model,
                        },
                      },
                    ],
                  },
                  {
                    key: 'sezione geologica di progetto',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex border-bottom',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            template: `<p>sezione geologica di progetto</p>`,
                          },
                          {
                            key: 'sezione geologica di progetto',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec flex-4',
                            templateOptions: {
                              key: 'sezione geologica di progetto',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                {
                                  value: 'SÌ',
                                  label: 'SÌ',
                                },
                                {
                                  value: 'NO',
                                  label: 'NO (da allegare se disponibile)',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        className:
                          'width-100 input-line flex-1 border-bottom remove-underline',
                        key: 'sezione geologica di progetto - file',
                        type: 'cube-file-upload-type',
                        defaultValue: '',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          token: tunnelInputTemplateComponent.token,
                          modelData: tunnelInputTemplateComponent.model,
                        },
                      },
                    ],
                  },
                  {
                    key: 'idrogeologia',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex border-bottom',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            template: `<p>idrogeologia</p>`,
                          },
                          {
                            key: 'idrogeologia - SI/NO',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec flex-2',
                            templateOptions: {
                              key: 'idrogeologia - SI/NO',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                {
                                  value: 'SI',
                                  label: 'SI',
                                },
                                {
                                  value: 'NO',
                                  label: 'NO',
                                },
                              ],
                              change: (field) => {
                                if (field.formControl.value !== 'SI') {
                                  field.formControl.parent
                                    ?.get([
                                      'idrogeologia - aggiornati/parzialmente',
                                    ])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get([
                                      'idrogeologia - aggiornati/parzialmente',
                                    ])
                                    ?.disable();
                                  field.formControl.parent
                                    ?.get([
                                      'idrogeologia - aggiornati/parzialmente',
                                    ])
                                    ?.setValue('');
                                  field.formControl.parent
                                    ?.get([
                                      'idrogeologia - aggiornati/parzialmente',
                                    ])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get([
                                      'idrogeologia - aggiornati/parzialmente',
                                    ])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get([
                                      'idrogeologia - aggiornati/parzialmente',
                                    ])
                                    ?.enable();
                                }
                              },
                              onReset: (field) => {
                                if (field.formControl.value !== 'SI') {
                                  field.formControl.parent
                                    ?.get([
                                      'idrogeologia - aggiornati/parzialmente',
                                    ])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get([
                                      'idrogeologia - aggiornati/parzialmente',
                                    ])
                                    ?.disable();
                                  field.formControl.parent
                                    ?.get([
                                      'idrogeologia - aggiornati/parzialmente',
                                    ])
                                    ?.setValue('');
                                  field.formControl.parent
                                    ?.get([
                                      'idrogeologia - aggiornati/parzialmente',
                                    ])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get([
                                      'idrogeologia - aggiornati/parzialmente',
                                    ])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.enable();
                                }
                              },
                            },
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (field.formControl.value !== 'SI') {
                                    field.formControl.parent
                                      ?.get([
                                        'idrogeologia - aggiornati/parzialmente',
                                      ])
                                      ?.setValue(null);
                                    field.formControl.parent
                                      ?.get([
                                        'idrogeologia - aggiornati/parzialmente',
                                      ])
                                      ?.disable();
                                    field.formControl.parent
                                      ?.get([
                                        'idrogeologia - aggiornati/parzialmente',
                                      ])
                                      ?.setValue('');
                                    field.formControl.parent
                                      ?.get([
                                        'idrogeologia - aggiornati/parzialmente',
                                      ])
                                      ?.disable();
                                  } else {
                                    field.formControl.parent
                                      ?.get([
                                        'idrogeologia - aggiornati/parzialmente',
                                      ])
                                      ?.enable();
                                    field.formControl.parent
                                      ?.get([
                                        'idrogeologia - aggiornati/parzialmente',
                                      ])
                                      ?.enable();
                                  }
                                }, 0);
                              },
                            },
                          },
                          {
                            key: 'idrogeologia - aggiornati/parzialmente',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec flex-2',
                            templateOptions: {
                              key: 'idrogeologia - aggiornati/parzialmente',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                {
                                  value: 'aggiornati',
                                  label: 'aggiornati',
                                },
                                {
                                  value: 'parzialmente non aggiornati',
                                  label: 'parzialmente/non aggiornati',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        className:
                          'width-100 input-line flex-1 border-bottom remove-underline',
                        key: 'idrogeologia - file',
                        type: 'cube-file-upload-type',
                        defaultValue: '',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          token: tunnelInputTemplateComponent.token,
                          modelData: tunnelInputTemplateComponent.model,
                        },
                      },
                    ],
                  },
                  {
                    key: 'geomorfologia',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex border-bottom',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            template: `<p>geomorfologia</p>`,
                          },
                          {
                            key: 'geomorfologia - SI/NO',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec flex-2',
                            templateOptions: {
                              key: 'geomorfologia - SI/NO',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                {
                                  value: 'SI',
                                  label: 'SI',
                                },
                                {
                                  value: 'NO',
                                  label: 'NO',
                                },
                              ],
                              change: (field) => {
                                if (field.formControl.value !== 'SI') {
                                  field.formControl.parent
                                    ?.get([
                                      'geomorfologia - aggiornati/parzialmente',
                                    ])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get([
                                      'geomorfologia - aggiornati/parzialmente',
                                    ])
                                    ?.disable();
                                  field.formControl.parent
                                    ?.get([
                                      'geomorfologia - aggiornati/parzialmente',
                                    ])
                                    ?.setValue('');
                                  field.formControl.parent
                                    ?.get([
                                      'geomorfologia - aggiornati/parzialmente',
                                    ])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get([
                                      'geomorfologia - aggiornati/parzialmente',
                                    ])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get([
                                      'geomorfologia - aggiornati/parzialmente',
                                    ])
                                    ?.enable();
                                }
                              },
                              onReset: (field) => {
                                if (field.formControl.value !== 'SI') {
                                  field.formControl.parent
                                    ?.get([
                                      'geomorfologia - aggiornati/parzialmente',
                                    ])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get([
                                      'geomorfologia - aggiornati/parzialmente',
                                    ])
                                    ?.disable();
                                  field.formControl.parent
                                    ?.get([
                                      'geomorfologia - aggiornati/parzialmente',
                                    ])
                                    ?.setValue('');
                                  field.formControl.parent
                                    ?.get([
                                      'geomorfologia - aggiornati/parzialmente',
                                    ])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get([
                                      'geomorfologia - aggiornati/parzialmente',
                                    ])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.enable();
                                }
                              },
                            },
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (field.formControl.value !== 'SI') {
                                    field.formControl.parent
                                      ?.get([
                                        'geomorfologia - aggiornati/parzialmente',
                                      ])
                                      ?.setValue(null);
                                    field.formControl.parent
                                      ?.get([
                                        'geomorfologia - aggiornati/parzialmente',
                                      ])
                                      ?.disable();
                                    field.formControl.parent
                                      ?.get([
                                        'geomorfologia - aggiornati/parzialmente',
                                      ])
                                      ?.setValue('');
                                    field.formControl.parent
                                      ?.get([
                                        'geomorfologia - aggiornati/parzialmente',
                                      ])
                                      ?.disable();
                                  } else {
                                    field.formControl.parent
                                      ?.get([
                                        'geomorfologia - aggiornati/parzialmente',
                                      ])
                                      ?.enable();
                                    field.formControl.parent
                                      ?.get([
                                        'geomorfologia - aggiornati/parzialmente',
                                      ])
                                      ?.enable();
                                  }
                                }, 0);
                              },
                            },
                          },
                          {
                            key: 'geomorfologia - aggiornati/parzialmente',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec flex-2',
                            templateOptions: {
                              key: 'geomorfologia - aggiornati/parzialmente',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                {
                                  value: 'aggiornati',
                                  label: 'aggiornati',
                                },
                                {
                                  value: 'parzialmente non aggiornati',
                                  label: 'parzialmente/non aggiornati',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        className:
                          'width-100 input-line flex-1 border-bottom remove-underline',
                        key: 'geomorfologia - file',
                        type: 'cube-file-upload-type',
                        defaultValue: '',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          token: tunnelInputTemplateComponent.token,
                          modelData: tunnelInputTemplateComponent.model,
                        },
                      },
                    ],
                  },
                  {
                    key: 'geotecnica',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex border-bottom',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            template: `<p>geotecnica</p>`,
                          },
                          {
                            key: 'geotecnica - SI/NO',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec flex-2',
                            templateOptions: {
                              key: 'geotecnica - SI/NO',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                {
                                  value: 'SI',
                                  label: 'SI',
                                },
                                {
                                  value: 'NO',
                                  label: 'NO',
                                },
                              ],
                              change: (field) => {
                                if (field.formControl.value !== 'SI') {
                                  field.formControl.parent
                                    ?.get([
                                      'geotecnica - aggiornati/parzialmente',
                                    ])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get([
                                      'geotecnica - aggiornati/parzialmente',
                                    ])
                                    ?.disable();
                                  field.formControl.parent
                                    ?.get([
                                      'geotecnica - aggiornati/parzialmente',
                                    ])
                                    ?.setValue('');
                                  field.formControl.parent
                                    ?.get([
                                      'geotecnica - aggiornati/parzialmente',
                                    ])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get([
                                      'geotecnica - aggiornati/parzialmente',
                                    ])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get([
                                      'geotecnica - aggiornati/parzialmente',
                                    ])
                                    ?.enable();
                                }
                              },
                              onReset: (field) => {
                                if (field.formControl.value !== 'SI') {
                                  field.formControl.parent
                                    ?.get([
                                      'geotecnica - aggiornati/parzialmente',
                                    ])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get([
                                      'geotecnica - aggiornati/parzialmente',
                                    ])
                                    ?.disable();
                                  field.formControl.parent
                                    ?.get([
                                      'geotecnica - aggiornati/parzialmente',
                                    ])
                                    ?.setValue('');
                                  field.formControl.parent
                                    ?.get([
                                      'geotecnica - aggiornati/parzialmente',
                                    ])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get([
                                      'geotecnica - aggiornati/parzialmente',
                                    ])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.enable();
                                }
                              },
                            },
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (field.formControl.value !== 'SI') {
                                    field.formControl.parent
                                      ?.get([
                                        'geotecnica - aggiornati/parzialmente',
                                      ])
                                      ?.setValue(null);
                                    field.formControl.parent
                                      ?.get([
                                        'geotecnica - aggiornati/parzialmente',
                                      ])
                                      ?.disable();
                                    field.formControl.parent
                                      ?.get([
                                        'geotecnica - aggiornati/parzialmente',
                                      ])
                                      ?.setValue('');
                                    field.formControl.parent
                                      ?.get([
                                        'geotecnica - aggiornati/parzialmente',
                                      ])
                                      ?.disable();
                                  } else {
                                    field.formControl.parent
                                      ?.get([
                                        'geotecnica - aggiornati/parzialmente',
                                      ])
                                      ?.enable();
                                    field.formControl.parent
                                      ?.get([
                                        'geotecnica - aggiornati/parzialmente',
                                      ])
                                      ?.enable();
                                  }
                                }, 0);
                              },
                            },
                          },
                          {
                            key: 'geotecnica - aggiornati/parzialmente',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec flex-2',
                            templateOptions: {
                              key: 'geotecnica - aggiornati/parzialmente',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                {
                                  value: 'aggiornati',
                                  label: 'aggiornati',
                                },
                                {
                                  value: 'parzialmente non aggiornati',
                                  label: 'parzialmente/non aggiornati',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        className:
                          'width-100 input-line flex-1 border-bottom remove-underline',
                        key: 'geotecnica - file',
                        type: 'cube-file-upload-type',
                        defaultValue: '',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          token: tunnelInputTemplateComponent.token,
                          modelData: tunnelInputTemplateComponent.model,
                        },
                      },
                    ],
                  },
                  {
                    key: 'geomeccanica',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex border-bottom',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1 margin-auto',
                            template: `<p>geomeccanica</p>`,
                          },
                          {
                            key: 'geomeccanica - SI/NO',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec flex-2',
                            templateOptions: {
                              key: 'geomeccanica - SI/NO',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                {
                                  value: 'SI',
                                  label: 'SI',
                                },
                                {
                                  value: 'NO',
                                  label: 'NO',
                                },
                              ],
                              change: (field) => {
                                if (field.formControl.value !== 'SI') {
                                  field.formControl.parent
                                    ?.get([
                                      'geomeccanica - aggiornati/parzialmente',
                                    ])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get([
                                      'geomeccanica - aggiornati/parzialmente',
                                    ])
                                    ?.disable();
                                  field.formControl.parent
                                    ?.get([
                                      'geomeccanica - aggiornati/parzialmente',
                                    ])
                                    ?.setValue('');
                                  field.formControl.parent
                                    ?.get([
                                      'geomeccanica - aggiornati/parzialmente',
                                    ])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get([
                                      'geomeccanica - aggiornati/parzialmente',
                                    ])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get([
                                      'geomeccanica - aggiornati/parzialmente',
                                    ])
                                    ?.enable();
                                }
                              },
                              onReset: (field) => {
                                if (field.formControl.value !== 'SI') {
                                  field.formControl.parent
                                    ?.get([
                                      'geomeccanica - aggiornati/parzialmente',
                                    ])
                                    ?.setValue(null);
                                  field.formControl.parent
                                    ?.get([
                                      'geomeccanica - aggiornati/parzialmente',
                                    ])
                                    ?.disable();
                                  field.formControl.parent
                                    ?.get([
                                      'geomeccanica - aggiornati/parzialmente',
                                    ])
                                    ?.setValue('');
                                  field.formControl.parent
                                    ?.get([
                                      'geomeccanica - aggiornati/parzialmente',
                                    ])
                                    ?.disable();
                                } else {
                                  field.formControl.parent
                                    ?.get([
                                      'geomeccanica - aggiornati/parzialmente',
                                    ])
                                    ?.enable();
                                  field.formControl.parent
                                    ?.get(['Allegato n'])
                                    ?.enable();
                                }
                              },
                            },
                            hooks: {
                              onInit: (field) => {
                                setTimeout(() => {
                                  if (field.formControl.value !== 'SI') {
                                    field.formControl.parent
                                      ?.get([
                                        'geomeccanica - aggiornati/parzialmente',
                                      ])
                                      ?.setValue(null);
                                    field.formControl.parent
                                      ?.get([
                                        'geomeccanica - aggiornati/parzialmente',
                                      ])
                                      ?.disable();
                                    field.formControl.parent
                                      ?.get([
                                        'geomeccanica - aggiornati/parzialmente',
                                      ])
                                      ?.setValue('');
                                    field.formControl.parent
                                      ?.get([
                                        'geomeccanica - aggiornati/parzialmente',
                                      ])
                                      ?.disable();
                                  } else {
                                    field.formControl.parent
                                      ?.get([
                                        'geomeccanica - aggiornati/parzialmente',
                                      ])
                                      ?.enable();
                                    field.formControl.parent
                                      ?.get([
                                        'geomeccanica - aggiornati/parzialmente',
                                      ])
                                      ?.enable();
                                  }
                                }, 0);
                              },
                            },
                          },
                          {
                            key: 'geomeccanica - aggiornati/parzialmente',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec flex-2',
                            templateOptions: {
                              key: 'geomeccanica - aggiornati/parzialmente',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                {
                                  value: 'aggiornati',
                                  label: 'aggiornati',
                                },
                                {
                                  value: 'parzialmente non aggiornati',
                                  label: 'parzialmente/non aggiornati',
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        className:
                          'width-100 input-line flex-1 border-bottom remove-underline',
                        key: 'geomeccanica - file',
                        type: 'cube-file-upload-type',
                        defaultValue: '',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          token: tunnelInputTemplateComponent.token,
                          modelData: tunnelInputTemplateComponent.model,
                        },
                      },
                    ],
                  },
                ],
              },
              {
                // Documenti disponibili inerenti alle condizioni di rischio idrogeologico
                key: 'Documenti disponibili inerenti alle condizioni di rischio idrogeologico',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'Documenti disponibili inerenti alle condizioni di rischio idrogeologico',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className:
                          'width-100 input-line flex-1 border-right remove-underline',
                        key: 'Rischio idraulico - file',
                        type: 'cube-file-upload-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Rischio idraulico',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          token: tunnelInputTemplateComponent.token,
                          modelData: tunnelInputTemplateComponent.model,
                        },
                      },
                      {
                        key: 'Rischio idraulico',
                        type: 'input',
                        defaultValue: '',
                        className: 'flex-3',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className:
                          'width-100 input-line flex-1 border-right remove-underline',
                        key: 'Rischio frane - file',
                        type: 'cube-file-upload-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Rischio frane',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          token: tunnelInputTemplateComponent.token,
                          modelData: tunnelInputTemplateComponent.model,
                        },
                      },
                      {
                        key: 'Rischio frane',
                        type: 'input',
                        defaultValue: '',
                        className: 'flex-3',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    fieldGroup: [
                      {
                        className: 'flex-1  border-bottom',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title:
                            'Altre documentazioni (es. zonazioni da Enti locali)',
                        },
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline border-right',
                            key: 'Altre documentazioni es zonazioni da Enti locali - file',
                            type: 'cube-file-upload-type',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              token: tunnelInputTemplateComponent.token,
                              modelData: tunnelInputTemplateComponent.model,
                            },
                          },
                          {
                            className: 'flex-1 border-bottom',
                            key: 'Altre documentazioni es zonazioni da Enti locali',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                // Documenti disponibili inerenti alle condizioni climatiche e meteorologiche
                key: 'Documenti disponibili inerenti alle condizioni climatiche e meteorologiche',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'Documenti disponibili inerenti alle condizioni climatiche e meteorologiche',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'flex-1 border-right border-bottom',
                        template: `<p>Temperatura atmosferica</p>`,
                      },
                      {
                        className:
                          'width-100 input-line flex-1 border-bottom remove-underline',
                        key: 'Temperatura atmosferica',
                        type: 'cube-file-upload-type',
                        defaultValue: '',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          token: tunnelInputTemplateComponent.token,
                          modelData: tunnelInputTemplateComponent.model,
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'flex-1 border-right border-bottom',
                        template: `<p>Temperatura ambientale all’interno della galleria</p>`,
                      },
                      {
                        className:
                          'width-100 input-line flex-1 border-bottom remove-underline',
                        key: 'Temperatura ambientale all interno della galleria',
                        type: 'cube-file-upload-type',
                        defaultValue: '',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          token: tunnelInputTemplateComponent.token,
                          modelData: tunnelInputTemplateComponent.model,
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'flex-1 border-right border-bottom',
                        template: `<p>Precipitazioni atmosferiche (pioggia, neve, ecc.)</p>`,
                      },
                      {
                        className:
                          'width-100 input-line flex-1 border-bottom remove-underline',
                        key: 'Precipitazioni atmosferiche pioggia neve ecc',
                        type: 'cube-file-upload-type',
                        defaultValue: '',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          token: tunnelInputTemplateComponent.token,
                          modelData: tunnelInputTemplateComponent.model,
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'flex-1 border-right border-bottom',
                        template: `<p>Condizioni anemometriche (velocità, direzione del vento)</p>`,
                      },
                      {
                        className:
                          'width-100 input-line flex-1 border-bottom remove-underline',
                        key: 'Condizioni anemometriche velocità direzione del vento',
                        type: 'cube-file-upload-type',
                        defaultValue: '',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          token: tunnelInputTemplateComponent.token,
                          modelData: tunnelInputTemplateComponent.model,
                        },
                      },
                    ],
                  },
                  {
                    fieldGroup: [
                      {
                        className: 'flex-1  border-bottom',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title:
                            'Altre documentazioni (es. frequenza di gelo nelle zone di imbocco)',
                        },
                      },
                      {
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline',
                            key: 'Altre documentazioni es frequenza di gelo nelle zone di imbocco',
                            type: 'cube-file-upload-type',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              token: tunnelInputTemplateComponent.token,
                              modelData: tunnelInputTemplateComponent.model,
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                wrappers: ['cube-section-title-wrapper'],
                templateOptions: {
                  title:
                    'Documenti disponibili sui requisiti minimi di sicurezza ai sensi del D.Lgs 264 del 2006',
                },
              },
              {
                // Documenti disponibili sui requisiti minimi di sicurezza ai sensi del D.Lgs 264 del 2006
                fieldGroup: [
                  {
                    key: 'Documenti disponibili sui requisiti minimi di sicurezza ai sensi del D Lgs 264 del 2006',
                    type: 'cube-repeat-section-type',
                    templateOptions: {
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      onDeleteRow: (index) => {
                        tunnelInputTemplateComponent.deleteUploadedFiles(
                          tunnelInputTemplateComponent.model[
                            TunnelConstants.levels.l0
                          ][
                            'Documenti disponibili sui requisiti minimi di sicurezza ai sensi del D Lgs 264 del 2006'
                          ][index][
                            'Documenti disponibili sui requisiti minimi di sicurezza ai sensi del D Lgs 264 del 2006 - files'
                          ]
                        );
                        tunnelInputTemplateComponent.model[
                          TunnelConstants.levels.l0
                        ][
                          'Documenti disponibili sui requisiti minimi di sicurezza ai sensi del D Lgs 264 del 2006'
                        ].splice(index, 1);
                        tunnelInputTemplateComponent.refreshFields();
                      },
                    },
                    fieldArray: {
                      fieldGroupClassName: 'display-flex border-bottom',
                      fieldGroup: [
                        {
                          className:
                            'flex-1 border-right repeat-cell remove-underline',
                          key: 'Documenti disponibili sui requisiti minimi di sicurezza ai sensi del D Lgs 264 del 2006 - text',
                          type: 'input',
                          defaultValue: '',
                          templateOptions: {
                            label: '',
                          },
                          expressionProperties: {
                            'templateOptions.label':
                              "'n.' + (+field.parent.key + 1)",
                          },
                        },
                        {
                          className:
                            'width-100 flex-1 remove-underline repeat-cell remove-underline',
                          key: 'Documenti disponibili sui requisiti minimi di sicurezza ai sensi del D Lgs 264 del 2006 - files',
                          type: 'cube-file-upload-type',
                          defaultValue: '',
                          templateOptions: {
                            label: '',
                            disabled:
                              tunnelInputTemplateComponent.isFieldDisabled(
                                [],
                                isDisabled
                              ),
                            multiple: true,
                            token: tunnelInputTemplateComponent.token,
                            modelData: tunnelInputTemplateComponent.model,
                          },
                          expressionProperties: {
                            'templateOptions.label':
                              "'n.' + (+field.parent.key + 1)",
                          },
                        },
                      ],
                    },
                  },
                ],
              },
              {
                // Storico di eventi rilevanti registrati in galleria
                key: 'Storico di eventi rilevanti registrati in galleria',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'Storico di eventi rilevanti registrati in galleria',
                    },
                  },
                  {
                    key: 'Eventi sulle strutture es distacco di materiale',
                    type: 'cube-repeat-section-type',
                    templateOptions: {
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      onDeleteRow: (index) => {
                        tunnelInputTemplateComponent.deleteUploadedFiles(
                          tunnelInputTemplateComponent.model[
                            TunnelConstants.levels.l0
                          ][
                            'Storico di eventi rilevanti registrati in galleria'
                          ]['Eventi sulle strutture es distacco di materiale'][
                            index
                          ][
                            'Eventi sulle strutture es distacco di materiale - files'
                          ]
                        );
                        tunnelInputTemplateComponent.model[
                          TunnelConstants.levels.l0
                        ]['Storico di eventi rilevanti registrati in galleria'][
                          'Eventi sulle strutture es distacco di materiale'
                        ].splice(index, 1);
                        tunnelInputTemplateComponent.refreshFields();
                      },
                    },
                    fieldArray: {
                      fieldGroupClassName: 'display-flex',
                      fieldGroup: [
                        {
                          className: 'flex-1 border-right border-bottom',
                          template: `<p>Eventi sulle strutture (es. distacco di materiale)</p>`,
                        },
                        {
                          className: 'flex-1 border-right border-bottom',
                          key: 'Eventi sulle strutture es distacco di materiale - text',
                          type: 'input',
                          defaultValue: '',
                          templateOptions: {
                            label: '',
                          },
                          expressionProperties: {
                            'templateOptions.label':
                              "'n.' + (+field.parent.key + 1)",
                          },
                        },
                        {
                          className:
                            'width-100 input-line flex-1 remove-underline border-bottom',
                          key: 'Eventi sulle strutture es distacco di materiale - files',
                          type: 'cube-file-upload-type',
                          defaultValue: '',
                          templateOptions: {
                            label: '',
                            disabled:
                              tunnelInputTemplateComponent.isFieldDisabled(
                                [],
                                isDisabled
                              ),
                            multiple: true,
                            token: tunnelInputTemplateComponent.token,
                            modelData: tunnelInputTemplateComponent.model,
                          },
                          expressionProperties: {
                            'templateOptions.label':
                              "'n.' + (+field.parent.key + 1)",
                          },
                        },
                      ],
                    },
                  },
                  {
                    key: 'Eventi idraulici es venute d’acqua allagamenti',
                    type: 'cube-repeat-section-type',
                    templateOptions: {
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      onDeleteRow: (index) => {
                        tunnelInputTemplateComponent.deleteUploadedFiles(
                          tunnelInputTemplateComponent.model[
                            TunnelConstants.levels.l0
                          ][
                            'Storico di eventi rilevanti registrati in galleria'
                          ]['Eventi idraulici es venute d’acqua allagamenti'][
                            index
                          ][
                            'Eventi idraulici es venute d’acqua allagamenti - files'
                          ]
                        );
                        tunnelInputTemplateComponent.model[
                          TunnelConstants.levels.l0
                        ]['Storico di eventi rilevanti registrati in galleria'][
                          'Eventi idraulici es venute d’acqua allagamenti'
                        ].splice(index, 1);
                        tunnelInputTemplateComponent.refreshFields();
                      },
                    },
                    fieldArray: {
                      fieldGroupClassName: 'display-flex',
                      fieldGroup: [
                        {
                          className: 'flex-1 border-right border-bottom',
                          template: `<p>Eventi idraulici (es. venute d’acqua,allagamenti)</p>`,
                        },
                        {
                          className: 'flex-1 border-right border-bottom',
                          key: 'Eventi idraulici es venute d’acqua allagamenti - text',
                          type: 'input',
                          defaultValue: '',
                          templateOptions: {
                            label: '',
                          },
                          expressionProperties: {
                            'templateOptions.label':
                              "'n.' + (+field.parent.key + 1)",
                          },
                        },
                        {
                          className:
                            'width-100 input-line flex-1 remove-underline border-bottom',
                          key: 'Eventi idraulici es venute d’acqua allagamenti - files',
                          type: 'cube-file-upload-type',
                          defaultValue: '',
                          templateOptions: {
                            label: '',
                            disabled:
                              tunnelInputTemplateComponent.isFieldDisabled(
                                [],
                                isDisabled
                              ),
                            multiple: true,
                            token: tunnelInputTemplateComponent.token,
                            modelData: tunnelInputTemplateComponent.model,
                          },
                          expressionProperties: {
                            'templateOptions.label':
                              "'n.' + (+field.parent.key + 1)",
                          },
                        },
                      ],
                    },
                  },
                  {
                    key: 'Eventi di guasti ai sistemi di galleria es guasti impianti incendi materiali elettrici',
                    type: 'cube-repeat-section-type',
                    templateOptions: {
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      onDeleteRow: (index) => {
                        tunnelInputTemplateComponent.deleteUploadedFiles(
                          tunnelInputTemplateComponent.model[
                            TunnelConstants.levels.l0
                          ][
                            'Storico di eventi rilevanti registrati in galleria'
                          ][
                            'Eventi di guasti ai sistemi di galleria es guasti impianti incendi materiali elettrici'
                          ][index][
                            'Eventi di guasti ai sistemi di galleria es guasti impianti incendi materiali elettrici - files'
                          ]
                        );
                        tunnelInputTemplateComponent.model[
                          TunnelConstants.levels.l0
                        ]['Storico di eventi rilevanti registrati in galleria'][
                          'Eventi di guasti ai sistemi di galleria es guasti impianti incendi materiali elettrici'
                        ].splice(index, 1);
                        tunnelInputTemplateComponent.refreshFields();
                      },
                    },
                    fieldArray: {
                      fieldGroupClassName: 'display-flex',
                      fieldGroup: [
                        {
                          className: 'flex-1 border-right border-bottom',
                          template: `<p>Eventi di guasti ai sistemi di galleria (es. guasti impianti, incendi materiali elettrici)</p>`,
                        },
                        {
                          className: 'flex-1 border-right border-bottom',
                          key: 'Eventi di guasti ai sistemi di galleria es guasti impianti incendi materiali elettrici - text',
                          type: 'input',
                          defaultValue: '',
                          templateOptions: {
                            label: '',
                          },
                          expressionProperties: {
                            'templateOptions.label':
                              "'n.' + (+field.parent.key + 1)",
                          },
                        },
                        {
                          className:
                            'width-100 input-line flex-1 remove-underline border-bottom',
                          key: 'Eventi di guasti ai sistemi di galleria es guasti impianti incendi materiali elettrici - files',
                          type: 'cube-file-upload-type',
                          defaultValue: '',
                          templateOptions: {
                            label: '',
                            disabled:
                              tunnelInputTemplateComponent.isFieldDisabled(
                                [],
                                isDisabled
                              ),
                            multiple: true,
                            token: tunnelInputTemplateComponent.token,
                            modelData: tunnelInputTemplateComponent.model,
                          },
                          expressionProperties: {
                            'templateOptions.label':
                              "'n.' + (+field.parent.key + 1)",
                          },
                        },
                      ],
                    },
                  },
                  {
                    key: 'Eventi sulla circolazione veicolare es incidenti incendi sversamento di merci pericolose',
                    type: 'cube-repeat-section-type',
                    templateOptions: {
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      onDeleteRow: (index) => {
                        tunnelInputTemplateComponent.deleteUploadedFiles(
                          tunnelInputTemplateComponent.model[
                            TunnelConstants.levels.l0
                          ][
                            'Storico di eventi rilevanti registrati in galleria'
                          ][
                            'Eventi sulla circolazione veicolare es incidenti incendi sversamento di merci pericolose'
                          ][index][
                            'Eventi sulla circolazione veicolare es incidenti incendi sversamento di merci pericolose - files'
                          ]
                        );
                        tunnelInputTemplateComponent.model[
                          TunnelConstants.levels.l0
                        ]['Storico di eventi rilevanti registrati in galleria'][
                          'Eventi sulla circolazione veicolare es incidenti incendi sversamento di merci pericolose'
                        ].splice(index, 1);
                        tunnelInputTemplateComponent.refreshFields();
                      },
                    },
                    fieldArray: {
                      fieldGroupClassName: 'display-flex',
                      fieldGroup: [
                        {
                          className: 'flex-1 border-right border-bottom',
                          template: `<p>Eventi sulla circolazione veicolare (es. incidenti, incendi, sversamento di merci pericolose)</p>`,
                        },
                        {
                          className: 'flex-1 border-right border-bottom',
                          key: 'Eventi sulla circolazione veicolare es incidenti incendi sversamento di merci pericolose - text',
                          type: 'input',
                          defaultValue: '',
                          templateOptions: {
                            label: '',
                          },
                          expressionProperties: {
                            'templateOptions.label':
                              "'n.' + (+field.parent.key + 1)",
                          },
                        },
                        {
                          className:
                            'width-100 input-line flex-1 remove-underline border-bottom',
                          key: 'Eventi sulla circolazione veicolare es incidenti incendi sversamento di merci pericolose - files',
                          type: 'cube-file-upload-type',
                          defaultValue: '',
                          templateOptions: {
                            label: '',
                            disabled:
                              tunnelInputTemplateComponent.isFieldDisabled(
                                [],
                                isDisabled
                              ),
                            multiple: true,
                            token: tunnelInputTemplateComponent.token,
                            modelData: tunnelInputTemplateComponent.model,
                          },
                          expressionProperties: {
                            'templateOptions.label':
                              "'n.' + (+field.parent.key + 1)",
                          },
                        },
                      ],
                    },
                  },
                  {
                    className: 'flex-1  border-bottom',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title:
                        'Ritorni di esperienza dagli eventi rilevanti in galleria (note)',
                    },
                  },
                  {
                    key: 'Ritorni di esperienza dagli eventi rilevanti in galleria note',
                    type: 'cube-repeat-section-type',
                    templateOptions: {
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      onDeleteRow: (index) => {
                        tunnelInputTemplateComponent.deleteUploadedFiles(
                          tunnelInputTemplateComponent.model[
                            TunnelConstants.levels.l0
                          ][
                            'Storico di eventi rilevanti registrati in galleria'
                          ][
                            'Ritorni di esperienza dagli eventi rilevanti in galleria note'
                          ][index][
                            'Ritorni di esperienza dagli eventi rilevanti in galleria note - files'
                          ]
                        );
                        tunnelInputTemplateComponent.model[
                          TunnelConstants.levels.l0
                        ]['Storico di eventi rilevanti registrati in galleria'][
                          'Ritorni di esperienza dagli eventi rilevanti in galleria note'
                        ].splice(index, 1);
                        tunnelInputTemplateComponent.refreshFields();
                      },
                    },
                    fieldArray: {
                      fieldGroupClassName: 'display-flex',
                      fieldGroup: [
                        {
                          className: 'flex-1 border-right border-bottom',
                          key: 'Ritorni di esperienza dagli eventi rilevanti in galleria note - text',
                          type: 'input',
                          defaultValue: '',
                          templateOptions: {
                            label: '',
                          },
                          expressionProperties: {
                            'templateOptions.label':
                              "'n.' + (+field.parent.key + 1)",
                          },
                        },
                        {
                          className:
                            'width-100 input-line flex-1 remove-underline border-bottom',
                          key: 'Ritorni di esperienza dagli eventi rilevanti in galleria note - files',
                          type: 'cube-file-upload-type',
                          defaultValue: '',
                          templateOptions: {
                            label: '',
                            disabled:
                              tunnelInputTemplateComponent.isFieldDisabled(
                                [],
                                isDisabled
                              ),
                            multiple: true,
                            token: tunnelInputTemplateComponent.token,
                            modelData: tunnelInputTemplateComponent.model,
                          },
                          expressionProperties: {
                            'templateOptions.label':
                              "'n.' + (+field.parent.key + 1)",
                          },
                        },
                      ],
                    },
                  },
                ],
              },
              {
                // Storico dei rilievi pregressi dell’ambiente stradale in galleria
                key: 'Storico dei rilievi pregressi dell’ambiente stradale in galleria',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'Storico dei rilievi pregressi dell’ambiente stradale in galleria',
                    },
                  },
                  {
                    key: 'Rilievi in continuo della sezione stradale larghezza altezza',
                    type: 'cube-repeat-section-type',
                    templateOptions: {
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      onDeleteRow: (index) => {
                        tunnelInputTemplateComponent.deleteUploadedFiles(
                          tunnelInputTemplateComponent.model[
                            TunnelConstants.levels.l0
                          ][
                            'Storico dei rilievi pregressi dell’ambiente stradale in galleria'
                          ][
                            'Rilievi in continuo della sezione stradale larghezza altezza'
                          ][index][
                            'Rilievi in continuo della sezione stradale larghezza altezza - files'
                          ]
                        );
                        tunnelInputTemplateComponent.model[
                          TunnelConstants.levels.l0
                        ][
                          'Storico dei rilievi pregressi dell’ambiente stradale in galleria'
                        ][
                          'Rilievi in continuo della sezione stradale larghezza altezza'
                        ].splice(index, 1);
                        tunnelInputTemplateComponent.refreshFields();
                      },
                    },
                    fieldArray: {
                      fieldGroupClassName: 'display-flex',
                      fieldGroup: [
                        {
                          className: 'flex-1 border-right border-bottom',
                          template: `<p>Rilievi in continuo della sezione stradale (larghezza, altezza)</p>`,
                        },
                        {
                          className: 'flex-1 border-right border-bottom',
                          key: 'Rilievi in continuo della sezione stradale larghezza altezza - text',
                          type: 'input',
                          defaultValue: '',
                          templateOptions: {
                            label: '',
                          },
                          expressionProperties: {
                            'templateOptions.label':
                              "'n.' + (+field.parent.key + 1)",
                          },
                        },
                        {
                          className:
                            'width-100 input-line flex-1 remove-underline border-bottom',
                          key: 'Rilievi in continuo della sezione stradale larghezza altezza - files',
                          type: 'cube-file-upload-type',
                          defaultValue: '',
                          templateOptions: {
                            label: '',
                            disabled:
                              tunnelInputTemplateComponent.isFieldDisabled(
                                [],
                                isDisabled
                              ),
                            multiple: true,
                            token: tunnelInputTemplateComponent.token,
                            modelData: tunnelInputTemplateComponent.model,
                          },
                          expressionProperties: {
                            'templateOptions.label':
                              "'n.' + (+field.parent.key + 1)",
                          },
                        },
                      ],
                    },
                  },
                  {
                    key: 'Rilievi in continuo dell’allineamento stradale planimetrico altimetrico',
                    type: 'cube-repeat-section-type',
                    templateOptions: {
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      onDeleteRow: (index) => {
                        tunnelInputTemplateComponent.deleteUploadedFiles(
                          tunnelInputTemplateComponent.model[
                            TunnelConstants.levels.l0
                          ][
                            'Storico dei rilievi pregressi dell’ambiente stradale in galleria'
                          ][
                            'Rilievi in continuo dell’allineamento stradale planimetrico altimetrico'
                          ][index][
                            'Rilievi in continuo dell’allineamento stradale planimetrico altimetrico - files'
                          ]
                        );
                        tunnelInputTemplateComponent.model[
                          TunnelConstants.levels.l0
                        ][
                          'Storico dei rilievi pregressi dell’ambiente stradale in galleria'
                        ][
                          'Rilievi in continuo dell’allineamento stradale planimetrico altimetrico'
                        ].splice(index, 1);
                        tunnelInputTemplateComponent.refreshFields();
                      },
                    },
                    fieldArray: {
                      fieldGroupClassName: 'display-flex',
                      fieldGroup: [
                        {
                          className: 'flex-1 border-right border-bottom',
                          template: `<p>Rilievi in continuo dell’allineamento stradale (planimetrico, altimetrico)</p>`,
                        },
                        {
                          className: 'flex-1 border-right border-bottom',
                          key: 'Rilievi in continuo dell’allineamento stradale planimetrico altimetrico - text',
                          type: 'input',
                          defaultValue: '',
                          templateOptions: {
                            label: '',
                          },
                          expressionProperties: {
                            'templateOptions.label':
                              "'n.' + (+field.parent.key + 1)",
                          },
                        },
                        {
                          className:
                            'width-100 input-line flex-1 remove-underline border-bottom',
                          key: 'Rilievi in continuo dell’allineamento stradale planimetrico altimetrico - files',
                          type: 'cube-file-upload-type',
                          defaultValue: '',
                          templateOptions: {
                            label: '',
                            disabled:
                              tunnelInputTemplateComponent.isFieldDisabled(
                                [],
                                isDisabled
                              ),
                            multiple: true,
                            token: tunnelInputTemplateComponent.token,
                            modelData: tunnelInputTemplateComponent.model,
                          },
                          expressionProperties: {
                            'templateOptions.label':
                              "'n.' + (+field.parent.key + 1)",
                          },
                        },
                      ],
                    },
                  },
                  {
                    key: 'Rilievi in continuo dei margini della carreggiata stradale banchine marciapiede',
                    type: 'cube-repeat-section-type',
                    templateOptions: {
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      onDeleteRow: (index) => {
                        tunnelInputTemplateComponent.deleteUploadedFiles(
                          tunnelInputTemplateComponent.model[
                            TunnelConstants.levels.l0
                          ][
                            'Storico dei rilievi pregressi dell’ambiente stradale in galleria'
                          ][
                            'Rilievi in continuo dei margini della carreggiata stradale banchine marciapiede'
                          ][index][
                            'Rilievi in continuo dei margini della carreggiata stradale banchine marciapiede - files'
                          ]
                        );
                        tunnelInputTemplateComponent.model[
                          TunnelConstants.levels.l0
                        ][
                          'Storico dei rilievi pregressi dell’ambiente stradale in galleria'
                        ][
                          'Rilievi in continuo dei margini della carreggiata stradale banchine marciapiede'
                        ].splice(index, 1);
                        tunnelInputTemplateComponent.refreshFields();
                      },
                    },
                    fieldArray: {
                      fieldGroupClassName: 'display-flex',
                      fieldGroup: [
                        {
                          className: 'flex-1 border-right border-bottom',
                          template: `<p>Rilievi in continuo dei margini della carreggiata stradale (banchine, marciapiede)</p>`,
                        },
                        {
                          className: 'flex-1 border-right border-bottom',
                          key: 'Rilievi in continuo dei margini della carreggiata stradale banchine marciapiede - text',
                          type: 'input',
                          defaultValue: '',
                          templateOptions: {
                            label: '',
                          },
                          expressionProperties: {
                            'templateOptions.label':
                              "'n.' + (+field.parent.key + 1)",
                          },
                        },
                        {
                          className:
                            'width-100 input-line flex-1 remove-underline border-bottom',
                          key: 'Rilievi in continuo dei margini della carreggiata stradale banchine marciapiede - files',
                          type: 'cube-file-upload-type',
                          defaultValue: '',
                          templateOptions: {
                            label: '',
                            disabled:
                              tunnelInputTemplateComponent.isFieldDisabled(
                                [],
                                isDisabled
                              ),
                            multiple: true,
                            token: tunnelInputTemplateComponent.token,
                            modelData: tunnelInputTemplateComponent.model,
                          },
                          expressionProperties: {
                            'templateOptions.label':
                              "'n.' + (+field.parent.key + 1)",
                          },
                        },
                      ],
                    },
                  },
                  {
                    className: 'flex-1  border-bottom',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Altra documentazione di rilievo',
                    },
                  },
                  {
                    key: 'Altra documentazione di rilievo',
                    type: 'cube-repeat-section-type',
                    templateOptions: {
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      onDeleteRow: (index) => {
                        tunnelInputTemplateComponent.deleteUploadedFiles(
                          tunnelInputTemplateComponent.model[
                            TunnelConstants.levels.l0
                          ][
                            'Storico dei rilievi pregressi dell’ambiente stradale in galleria'
                          ]['Altra documentazione di rilievo'][index][
                            'Altra documentazione di rilievo - files'
                          ]
                        );
                        tunnelInputTemplateComponent.model[
                          TunnelConstants.levels.l0
                        ][
                          'Storico dei rilievi pregressi dell’ambiente stradale in galleria'
                        ]['Altra documentazione di rilievo'].splice(index, 1);
                        tunnelInputTemplateComponent.refreshFields();
                      },
                    },
                    fieldArray: {
                      fieldGroupClassName: 'display-flex',
                      fieldGroup: [
                        {
                          className: 'flex-1 border-right border-bottom',
                          key: 'Altra documentazione di rilievo - text',
                          type: 'input',
                          defaultValue: '',
                          templateOptions: {
                            label: '',
                          },
                          expressionProperties: {
                            'templateOptions.label':
                              "'n.' + (+field.parent.key + 1)",
                          },
                        },
                        {
                          className:
                            'width-100 input-line flex-1 remove-underline border-bottom',
                          key: 'Altra documentazione di rilievo - files',
                          type: 'cube-file-upload-type',
                          defaultValue: '',
                          templateOptions: {
                            label: '',
                            disabled:
                              tunnelInputTemplateComponent.isFieldDisabled(
                                [],
                                isDisabled
                              ),

                            multiple: true,
                            token: tunnelInputTemplateComponent.token,
                            modelData: tunnelInputTemplateComponent.model,
                          },
                          expressionProperties: {
                            'templateOptions.label':
                              "'n.' + (+field.parent.key + 1)",
                          },
                        },
                      ],
                    },
                  },
                ],
              },
              {
                // Considerazioni conclusive
                key: 'Considerazioni conclusive',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Considerazioni conclusive',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'flex-1 border-right border-bottom',
                        template: `<p>Giudizio sintetico sul grado delle conoscenze già disponibili</p>`,
                      },
                      {
                        key: 'Giudizio sintetico sul grado delle conoscenze già disponibili',
                        type: 'textarea',
                        defaultValue: '',
                        className:
                          'width-100 input-line flex-1 border-bottom remove-underline',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          theme: 'custom',
                          rows: 4,
                          grow: false,
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'flex-1 border-right border-bottom',
                        template: `<p>Individuazione preliminare degli indicatori di pericolosità di maggiore interesse fra quelli che concorrono alla individuazione delle classi di attenzione da verificare in sede di Ispezione iniziale</p>`,
                      },
                      {
                        key: 'Individuazione preliminare degli indicatori di pericolosità',
                        type: 'textarea',
                        defaultValue: '',
                        className:
                          'width-100 input-line flex-1 border-bottom remove-underline',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          theme: 'custom',
                          rows: 4,
                          grow: false,
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'flex-1 border-right border-bottom',
                        template: `<p>Particolari zone di interesse lungo lo sviluppo della galleria da prendere in esame nel corso dell’Ispezione iniziale</p>`,
                      },
                      {
                        key: 'Particolari zone di interesse lungo lo sviluppo della galleria',
                        type: 'textarea',
                        defaultValue: '',
                        className:
                          'width-100 input-line flex-1 border-bottom remove-underline',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          theme: 'custom',
                          rows: 4,
                          grow: false,
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'flex-1 border-right border-bottom',
                        template: `<p>Necessità o meno di dare corso ad eventuali indagini nel corso del livello 1, con indicazioni per la loro programmazione, qualora non siano già disponibili elementi sufficienti da attività pregresse o, se esistenti non siano più</p>`,
                      },
                      {
                        key: 'Necessità o meno di dare corso ad eventuali indagini',
                        type: 'textarea',
                        defaultValue: '',
                        className:
                          'width-100 input-line flex-1 border-bottom remove-underline',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          theme: 'custom',
                          rows: 4,
                          grow: false,
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'flex-1 border-right border-bottom',
                        template: `<p>attuali in relazione al quadro evolutivo dello stato di conservazione della galleria</p>`,
                      },
                      {
                        key: 'attuali in relazione al quadro evolutivo dello stato di conservazione della galleria',
                        type: 'textarea',
                        defaultValue: '',
                        className:
                          'width-100 input-line flex-1 border-bottom remove-underline',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          theme: 'custom',
                          rows: 4,
                          grow: false,
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex',
                    fieldGroup: [
                      {
                        className: 'flex-1 border-right border-bottom',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title: 'Altre osservazioni',
                        },
                      },
                      {
                        key: 'Altre osservazioni',
                        type: 'textarea',
                        defaultValue: '',
                        className:
                          'width-100 input-line flex-1 border-bottom remove-underline',
                        templateOptions: {
                          label: '',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          theme: 'custom',
                          rows: 4,
                          grow: false,
                        },
                      },
                    ],
                  },
                  {
                    fieldGroup: [
                      {
                        className: 'flex-1  border-bottom',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title:
                            'Progetto-Costruzione-Manutenzioni-Indagini e studi pregressi',
                        },
                      },
                      {
                        key: 'Progetto-Costruzione-Manutenzioni-Indagini e studi pregressi',
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            key: 'Elenco documenti disponibili',
                            type: 'textarea',
                            defaultValue: '',
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline',
                            templateOptions: {
                              label: 'Elenco documenti disponibili',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              theme: 'custom',
                              rows: 4,
                              grow: false,
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldGroup: [
                      {
                        className: 'flex-1  border-bottom',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title: 'Allegati',
                        },
                      },
                      {
                        key: 'Allegati',
                        fieldGroupClassName: 'display-flex',
                        fieldGroup: [
                          {
                            className:
                              'width-100 input-line flex-1 border-bottom remove-underline',
                            key: 'Elenco documenti allegati',
                            type: 'cube-file-upload-type',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Elenco documenti allegati',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              multiple: true,
                              token: tunnelInputTemplateComponent.token,
                              modelData: tunnelInputTemplateComponent.model,
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldGroup: [
                      {
                        className: 'flex-1  border-bottom',
                        template: `<p>Data</p>`,
                      },
                      {
                        className: 'flex-1',
                        key: 'Data',
                        type: 'cube-datepicker-type',
                        defaultValue: '',
                        templateOptions: {
                          readonly: true,
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          max: new Date(),
                        },
                      },
                    ],
                  },
                ],
              },
              {
                // Scheda di censimento conoscenza galleria – Glossario
                fieldGroupClassName: 'page-heading',
                fieldGroup: [
                  {
                    className: 'width-100',
                    template: `
                      <div class="page-heading-text">
                        <span class="prefix-block">LIVELLO 0</span>Scheda di censimento conoscenza galleria
                      </div>`,
                  },
                  {
                    className: 'page-heading-image',
                    template: `<img src="assets/rina/RINA-dark.png">`,
                  },
                ],
              },
              {
                fieldGroupClassName: 'page-heading',
                fieldGroup: [
                  {
                    className: 'width-100',
                    template: `
                      <div class="page-heading-text">
                        <span class="font-weight-normal">
                          <span class="prefix-block">Livello 0</span>Scheda di censimento conoscenza galleria
                        </span> – Glossario
                      </div>`,
                  },
                ],
              },
              {
                fieldGroup: [
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Acque aggressive',
                    },
                  },
                  {
                    className: 'width-100',
                    template: `<div class="margin-top-15">Acque in grado di aggredire chimicamente i calcestruzzi (pH minore di 6,5) e quindi provocare il disfacimento del rivestimento definitivo dell’opera. Il loro rinvenimento dipende dai caratteri litologici delle formazioni attraversate dalle acque che raggiungono la galleria.</div>`,
                  },
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'ADR',
                    },
                  },
                  {
                    className: 'width-100',
                    template: `<div class="margin-top-15">Accordo europeo relativo al trasporto internazionale delle merci pericolose su strada.</div>`,
                  },
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Banchina',
                    },
                  },
                  {
                    className: 'width-100',
                    template: `<div class="margin-top-15">Parte della strada, libera da qualsiasi ostacolo (segnaletica verticale, delineatori di margine, dispositivi di ritenuta), di norma pavimentata, compresa tra il margine della carreggiata e il più vicino tra il marciapiede o il piedritto della galleria (v. art. 3 del D.Lgs. 30 aprile 1992, n. 285).</div>`,
                  },
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'By-Pass',
                    },
                  },
                  {
                    className: 'width-100',
                    template: `<div class="margin-top-15">Galleria trasversale di collegamento tra due fornici di una stessa galleria. Il By-pass può avere funzione di collegamento ad uso pedonale o ad uso carrabile.</div>`,
                  },
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Carreggiata',
                    },
                  },
                  {
                    className: 'width-100',
                    template: `<div class="margin-top-15">Parte della strada destinata allo scorrimento dei veicoli; essa è composta da una o più corsie di marcia, è pavimentata ed è delimitata da strisce di margine (segnaletica orizzontale).</div>`,
                  },
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Corsia',
                    },
                  },
                  {
                    className: 'width-100',
                    template: `<div class="margin-top-15">Parte longitudinale della strada, normalmente delimitata da segnaletica orizzontale, di larghezza idonea a permettere il transito di una sola fila di veicoli.</div>`,
                  },
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Classe di attenzione',
                    },
                  },
                  {
                    className: 'width-100',
                    template: `<div class="margin-top-15">Classificazione delle gallerie basata su una stima approssimata dei fattori di rischio, utile per la definizione di un ordine di priorità per l’approfondimento delle indagini/verifiche/controlli nonché per la programmazione degli interventi manutentivi e strutturali necessari.</div>`,
                  },
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Classificazione d’uso stradale',
                    },
                  },
                  {
                    className: 'width-100',
                    template: `<div class="margin-top-15">Classificazione riguardo alle caratteristiche costruttive, tecniche e funzionali delle strade, di cui all’art. 2 comma 2 del D.Lgs. 30 aprile 1992, n. 285 “Nuovo codice della strada”.</div>`,
                  },
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Classificazione RMR',
                    },
                  },
                  {
                    className: 'width-100',
                    template: `<div class="margin-top-15">Classificazione delle rocce di Bieniawski (RMR, Rock Mass Rating) adottato per la correlazione delle caratteristiche dell’ammasso roccioso e dei carichi sul rivestimento delle gallerie. Nelle presenti linee guida si fa riferimento all’indice RMR definito nella versione del 1989.</div>`,
                  },
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Cunicolo di esodo',
                    },
                  },
                  {
                    className: 'width-100',
                    template: `<div class="margin-top-15">Una struttura pedonale destinata all’esodo degli utenti per lo svuotamento della galleria, ricavata all’interno della sezione di scavo della galleria e da essa separata mediante elementi strutturali.</div>`,
                  },
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Galleria',
                    },
                  },
                  {
                    className: 'width-100',
                    template: `<div class="margin-top-15">Opera in sotterraneo continuativamente chiusa su quattro lati, in cui una dimensione è prevalente sulle altre due, di lunghezza superiore o uguale a 200 m.</div>`,
                  },
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Galleria artificiale',
                    },
                  },
                  {
                    className: 'width-100',
                    template: `<div class="margin-top-15">Galleria superficiale, realizzata mediante scavo preliminare di trincea (scavo a cielo aperto) e successiva copertura.</div>`,
                  },
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Galleria naturale',
                    },
                  },
                  {
                    className: 'width-100',
                    template: `<div class="margin-top-15">Galleria profonda, in cui le rocce e/o terreni inglobano completamente la galleria (scavo a foro cieco).</div>`,
                  },
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'IRI',
                    },
                  },
                  {
                    className: 'width-100',
                    template: `<div class="margin-top-15">Indicatore convenzionale di regolarità della superficie stradale (acronimo di “International Roughness Index”) espresso in mm/m.</div>`,
                  },
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Larghezza corsia',
                    },
                  },
                  {
                    className: 'width-100',
                    template: `<div class="margin-top-15">Distanza tra gli assi delle strisce che delimitano le corsie.</div>`,
                  },
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Livelletta',
                    },
                  },
                  {
                    className: 'width-100',
                    template: `<div class="margin-top-15">Tratto di strada a pendenza longitudinale costante.</div>`,
                  },
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Paramassi',
                    },
                  },
                  {
                    className: 'width-100',
                    template: `<div class="margin-top-15">Opere realizzate per proteggere la sede stradale dalla caduta di massi, strutturalmente confinata su quattro lati ma non continuativamente chiusa (es. con finestrature laterali oppure a mensola con un lato aperto), in cui una dimensione è  prevalente sulle altre due, di lunghezza superiore o uguale a 200 m.</div>`,
                  },
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Piattaforma',
                    },
                  },
                  {
                    className: 'width-100',
                    template: `<div class="margin-top-15">Parte della sede stradale che comprende la carreggiata, le banchine in destra e in sinistra, le corsie riservate, le corsie specializzate, le fasce di sosta laterale e le piazzole di sosta o di fermata dei mezzi pubblici (se esistenti, v. art. 3 del D.Lgs. 30 aprile 1992, n. 285).</div>`,
                  },
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Pregalleria',
                    },
                  },
                  {
                    className: 'width-100',
                    template: `<div class="margin-top-15">Tratta stradale in prossimità del portale della galleria, confinata da elementi marginali caratterizzati da continuità strutturale con il paramento della galleria ma non classificabile essa stessa come galleria (v. muri andatori, becco di flauto, ecc.).</div>`,
                  },
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Sottopasso',
                    },
                  },
                  {
                    className: 'width-100',
                    template: `<div class="margin-top-15">Opera in sotterraneo atta a risolvere un'interferenza con altra infrastruttura lineare e limitatamente a quanto non già previsto dalle Linee guida per la classificazione del rischio, la valutazione della sicurezza ed il monitoraggio dei ponti esistenti.</div>`,
                  },
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Traffico Medio Giornaliero (TGM)',
                    },
                  },
                  {
                    className: 'width-100',
                    template: `<div class="margin-top-15">Media annua del traffico giornaliero della galleria (espressa in veicoli/giorno).</div>`,
                  },
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'TGM per corsia',
                    },
                  },
                  {
                    className: 'width-100',
                    template: `<div class="margin-top-15">Media annua del traffico giornaliero della galleria diviso per il numero di corsie di marcia disponibili in galleria (espressa in veicoli/giorno∙corsia).</div>`,
                  },
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'TGM estivo',
                    },
                  },
                  {
                    className: 'width-100',
                    template: `<div class="margin-top-15">Media del traffico giornaliero della galleria calcolata sul traffico in transito nei giorni dei mesi estivi (espressa in veicoli/giorno).</div>`,
                  },
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Traffico d’ora di punta',
                    },
                  },
                  {
                    className: 'width-100',
                    template: `<div class="margin-top-15">Traffico in transito nell’ora di maggior traffico nell’intervallo temporale di osservazione.</div>`,
                  },
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Veicoli ADR',
                    },
                  },
                  {
                    className: 'width-100',
                    template: `<div class="margin-top-15">Veicoli trasportanti merci pericolose regolamentate dall’Accordo ADR.</div>`,
                  },
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Veicoli pesanti (VP)',
                    },
                  },
                  {
                    className: 'width-100',
                    template: `<div class="margin-top-15">Veicoli di massa superiore a 3,5 tonnellate.</div>`,
                  },
                  {
                    className: 'width-100 flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: '% VP',
                    },
                  },
                  {
                    className: 'width-100',
                    template: `<div class="margin-top-15">Rapporto tra il numero di veicoli di massa superiore a 3,5 tonnellate in transito in galleria in un anno, ed il numero di veicoli totali in transito, espresso in percentuale.</div>`,
                  },
                ],
              },
            ],
          },
          {
            templateOptions: { label: 'Level 1' },
            fieldGroup: [
              {
                key: 'opereparzialilist',
                type: 'cube-ag-grid-table-type',
                className: 'ag-theme-balham',
                templateOptions: {
                  hasModelData: true,
                  height: '100%',
                  width: '100%',
                  gridOptions: {
                    defaultColDef: {
                      flex: 1,
                      minWidth: 130,
                    },
                    columnDefs: [
                      {
                        headerName: 'N° Concio',
                        headerClass: 'header-no-child',
                        field: 'N° Concio',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                      },
                      {
                        headerName: 'N° 1 - Difetti relativi alla struttura',
                        headerClass: 'header-no-child',
                        field: 'N° 1 - Difetti relativi alla struttura',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                      },
                      {
                        headerName: 'N° 2 - Difetti relativi alla struttura',
                        headerClass: 'header-no-child',
                        field: 'N° 2 - Difetti relativi alla struttura',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                      },
                      {
                        headerName: 'N° 3 - Difetti relativi alla struttura',
                        headerClass: 'header-no-child',
                        field: 'N° 3 - Difetti relativi alla struttura',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                      },
                      {
                        headerName:
                          'N° Difetti relativi alla piattaforma stradale/autostradale',
                        headerClass: 'header-no-child',
                        field:
                          'N° Difetti relativi alla piattaforma stradale/autostradale',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                      },
                      {
                        headerName:
                          'N° Difetti relativi agli elementi non strutturali e impianti',
                        headerClass: 'header-no-child',
                        field:
                          'N° Difetti relativi agli elementi non strutturali e impianti',
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        minWidth: 175,
                      },
                      {
                        headerName: 'Action',
                        headerClass: 'header-no-child',
                        field: 'action',
                        cellStyle: { textAlign: 'center' },
                        cellRenderer: actionButtonsRendererComponent,
                        cellRendererParams: {
                          isViewMode:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          onEdit: (index) => {
                            tunnelInputTemplateComponent.model.activeLevel =
                              TunnelConstants.levels.l1;
                            tunnelInputTemplateComponent.migrateDataToChildLevel(
                              index,
                              TunnelConstants.levels.l1,
                              tunnelInputTemplateComponent.model[
                                TunnelConstants.levels.l0
                              ]['opereparzialilist']
                            );
                          },
                        },
                      },
                    ],
                  },
                },
                fieldArray: {
                  fieldGroup: [
                    {
                      key: 'N° Concio',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'N° CONCIO',
                        readonly: true,
                      },
                    },
                    {
                      key: 'N° 1 - Difetti relativi alla struttura',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'N° 1 - Difetti relativi alla struttura',
                        readonly: true,
                      },
                    },
                    {
                      key: 'N° 2 - Difetti relativi alla struttura',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'N° 2 - Difetti relativi alla struttura',
                        readonly: true,
                      },
                    },
                    {
                      key: 'N° 3 - Difetti relativi alla struttura',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: 'N° 3 - Difetti relativi alla struttura',
                        readonly: true,
                      },
                    },
                    {
                      key: 'N° Difetti relativi alla piattaforma stradale/autostradale',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label:
                          'N° Difetti relativi alla piattaforma stradale/autostradale',
                        readonly: true,
                      },
                    },
                    {
                      key: 'N° Difetti relativi agli elementi non strutturali e impianti',
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label:
                          'N° Difetti relativi agli elementi non strutturali e impianti',
                        readonly: true,
                      },
                    },
                  ],
                },
              },
              {
                // Add New L1
                type: 'cube-button-type',
                className: 'remove-underline',
                templateOptions: {
                  text: 'Add New',
                  color: 'primary',
                  onClick: (e) => {
                    tunnelInputTemplateComponent.l0BackupData =
                      tunnelInputTemplateComponent.getDeepCopy(
                        tunnelInputTemplateComponent.model[
                          TunnelConstants.levels.l0
                        ]
                      );
                    tunnelInputTemplateComponent.l1InitBackupData =
                      tunnelInputTemplateComponent.getDeepCopy(
                        tunnelInputTemplateComponent.model[
                          TunnelConstants.levels.l1
                        ]
                      );
                    tunnelInputTemplateComponent.setImages(
                      TunnelConstants.levels.l0
                    );
                    tunnelInputTemplateComponent.model.activeLevel =
                      TunnelConstants.levels.l1;
                  },
                },
                hideExpression: () => {
                  const isFieldDisabled =
                    tunnelInputTemplateComponent.isFieldDisabled(
                      [],
                      isDisabled
                    );
                  const isLevel1Added =
                    tunnelInputTemplateComponent.model[
                      TunnelConstants.levels.l0
                    ].opereparzialilist.length > 0;

                  return isLevel1Added || isFieldDisabled;
                },
              },
            ],
          },
          {
            templateOptions: { label: 'Media' },
            key: 'MediaList',
            fieldGroup: [
              {
                key: 'Media',
                type: 'cube-file-upload-media-type',
                templateOptions: {
                  disabled: tunnelInputTemplateComponent.isFieldDisabled(
                    [],
                    isDisabled
                  ),
                  multiple: true,
                  modelData: tunnelInputTemplateComponent.model,
                  token: tunnelInputTemplateComponent.token,
                },
                className: 'width-100 remove-underline upload-section',
              },
            ],
          },
          {
            templateOptions: { label: 'Notes' },
            fieldGroup: [
              {
                type: 'textarea',
                defaultValue: '',
                key: 'Notes',
                templateOptions: {
                  disabled: tunnelInputTemplateComponent.isFieldDisabled(
                    [],
                    isDisabled
                  ),
                  label: 'Notes',
                  theme: 'custom',
                  rows: 10,
                },
              },
            ],
          },
        ],
      },
      {
        // Level: Level 1
        key: TunnelConstants.levels.l1,
        type: 'cube-tabs-type',
        templateOptions: {
          devMode: tunnelInputTemplateComponent.devMode, // TEMP: For development
        },
        hideExpression: () =>
          tunnelInputTemplateComponent.model.activeLevel !==
          TunnelConstants.levels.l1,
        fieldGroup: [
          {
            templateOptions: { label: 'Level 1' },
            fieldGroup: [
              {
                // Top section
                resetOnHide: true,
                fieldGroup: [
                  {
                    fieldGroupClassName: 'page-heading',
                    fieldGroup: [
                      {
                        className: 'width-100 ',
                        template: `<div class="page-heading-text" style="margin: auto 0px;">Schede di ispezione gallerie di Livello 1  </div>`,
                      },
                      {
                        className: 'page-heading-image',
                        template: `<div class="text-align-center">
                              <img src="assets/rina/RINA-dark.png" >
                          </div>`,
                      },
                    ],
                  },
                  {
                    key: 'Scheda fornice',
                    fieldGroupClassName:
                      'display-flex-wrap grid-gap margin-top-30',
                    fieldGroup: [
                      {
                        className: 'flex-1 padding-top-10',
                        key: 'Scheda fornice n',
                        type: 'cube-number-type',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Scheda fornice n.',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          onChange: (field, e) => {
                            tunnelInputTemplateComponent.syncSharedModelData(
                              'schedaForniceN',
                              e.target.value
                            );
                          },
                        },
                      },
                      {
                        key: 'radio',
                        defaultValue: '',
                        type: 'radio',
                        className: 'remove-underline padding-left-10',
                        hooks: {
                          onInit: (field) => {
                            setTimeout(() => {
                              if (
                                field.formControl.value !== 'Unidirezionale'
                              ) {
                                field.formControl.parent
                                  ?.get(['direzione'])
                                  ?.setValue(null);
                                field.formControl.parent
                                  ?.get(['direzione'])
                                  ?.disable();
                              } else {
                                if (!isDisabled) {
                                  field.formControl.parent
                                    ?.get(['direzione'])
                                    ?.enable();
                                }
                              }
                            }, 0);
                          },
                        },
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          key: 'Scheda fornice n - radio',
                          options: [
                            {
                              value: 'Unidirezionale',
                              label: 'Unidirezionale',
                            },
                          ],
                          change: (field, e) => {
                            if (field.formControl.value !== 'Unidirezionale') {
                              field.formControl.parent
                                ?.get(['direzione'])
                                ?.setValue(null);
                              field.formControl.parent
                                ?.get(['direzione'])
                                ?.disable();
                            } else {
                              field.formControl.parent
                                ?.get(['direzione'])
                                ?.enable();
                            }
                            tunnelInputTemplateComponent.syncSharedModelData(
                              'schedaForniceNRadio',
                              field.formControl.value
                            );
                          },
                          onReset: (field, e) => {
                            if (field.formControl.value !== 'Unidirezionale') {
                              field.formControl.parent
                                ?.get(['direzione'])
                                ?.setValue(null);
                              field.formControl.parent
                                ?.get(['direzione'])
                                ?.disable();
                              tunnelInputTemplateComponent.syncSharedModelData(
                                'schedaForniceNRadio',
                                field.formControl.value
                              );
                              tunnelInputTemplateComponent.syncSharedModelData(
                                'uniDirezionaleDirezione',
                                field.formControl.value
                              );
                            } else {
                              field.formControl.parent
                                ?.get(['direzione'])
                                ?.enable();
                            }
                          },
                        },
                      },
                      {
                        className: 'input-line width-35 padding-top-10',
                        key: 'direzione',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          label: 'direzione',
                          change: (field) => {
                            tunnelInputTemplateComponent.syncSharedModelData(
                              'uniDirezionaleDirezione',
                              field.formControl.value
                            );
                          },
                        },
                      },
                      {
                        key: 'radio',
                        type: 'radio',
                        className: 'remove-underline padding-left-10',
                        defaultValue: '',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          key: 'Scheda fornice n - radio',
                          options: [
                            {
                              value: 'Bidirezionale',
                              label: 'Bidirezionale',
                            },
                          ],
                          change: (field, e) => {
                            if (field.formControl.value !== 'Unidirezionale') {
                              field.formControl.parent
                                ?.get(['direzione'])
                                ?.setValue(null);
                              field.formControl.parent
                                ?.get(['direzione'])
                                ?.disable();
                            } else {
                              field.formControl.parent
                                ?.get(['direzione'])
                                ?.enable();
                            }
                            tunnelInputTemplateComponent.syncSharedModelData(
                              'schedaForniceNRadio',
                              field.formControl.value
                            );
                          },
                          onReset: (field, e) => {
                            if (field.formControl.value !== 'Unidirezionale') {
                              field.formControl.parent
                                ?.get(['direzione'])
                                ?.setValue(null);
                              field.formControl.parent
                                ?.get(['direzione'])
                                ?.disable();
                              tunnelInputTemplateComponent.syncSharedModelData(
                                'schedaForniceNRadio',
                                field.formControl.value
                              );
                              tunnelInputTemplateComponent.syncSharedModelData(
                                'uniDirezionaleDirezione',
                                field.formControl.value
                              );
                            } else {
                              field.formControl.parent
                                ?.get(['direzione'])
                                ?.enable();
                            }
                          },
                        },
                      },
                    ],
                  },
                  {
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex-wrap grid-gap',
                        fieldGroup: [
                          {
                            className: 'flex-1',
                            key: 'Codice IOP',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Codice IOP',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              change: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'codiceIop',
                                  e.target.value
                                );
                                tunnelInputTemplateComponent.syncSharedModelDataForList(
                                  'codiceIop',
                                  e.target.value
                                );
                              },
                            },
                          },
                          {
                            className: 'flex-1',
                            key: 'Nome Galleria/paramassi/sottopasso',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Nome Galleria/paramassi/sottopasso',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              change: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'nomeGalleria',
                                  e.target.value
                                );
                              },
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex-wrap grid-gap',
                        fieldGroup: [
                          {
                            className: 'flex-1',
                            key: 'Direzione (E/O, N/S)',
                            type: 'select',
                            defaultValue: null,
                            templateOptions: {
                              label: 'Direzione (E/O, N/S):',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                { label: 'E/O', value: 'E/O' },
                                { label: 'N/S', value: 'N/S' },
                              ],
                            },
                          },
                          {
                            className: 'flex-1',
                            key: 'Direzione principale',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Direzione principale:',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex-wrap grid-gap',
                        fieldGroup: [
                          {
                            className: 'flex-2',
                            key: 'Strada di appartenenza',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Strada di appartenenza:',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              change: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'stradaDiAppartenenza',
                                  e.target.value
                                );
                              },
                            },
                          },
                          {
                            className: 'flex-1',
                            key: 'Progressiva km iniziale',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Progressiva km iniziale',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              change: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'progressivaKmIniziale',
                                  e.target.value
                                );
                              },
                            },
                          },
                          {
                            className: 'flex-1',
                            key: 'Progressiva km finale',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Progressiva km finale',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              change: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'progressivaKmFinale',
                                  e.target.value
                                );
                              },
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex-wrap grid-gap',
                        fieldGroup: [
                          {
                            className: 'width-50 width-100-mobile',
                            key: 'Tratta stradale/autostradale',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Tratta stradale/autostradale:',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                //Localizzazione
                key: 'Localizzazione',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: { title: 'Localizzazione' },
                  },
                  {
                    fieldGroupClassName: 'display-flex-column',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex-wrap',
                        fieldGroup: [
                          {
                            className: 'flex-1',
                            fieldGroupClassName: 'display-flex-column',
                            fieldGroup: [
                              {
                                type: 'input',
                                defaultValue: '',
                                key: 'Provincia/Regione',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  label: 'Provincia/Regione',
                                  change: (field, e) => {
                                    tunnelInputTemplateComponent.syncSharedModelData(
                                      'provinciaRegione',
                                      e.target.value
                                    );
                                  },
                                },
                              },
                              {
                                type: 'input',
                                defaultValue: '',
                                key: 'Comune',
                                templateOptions: {
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  label: 'Comune',
                                  change: (field, e) => {
                                    tunnelInputTemplateComponent.syncSharedModelData(
                                      'comune',
                                      e.target.value
                                    );
                                  },
                                },
                              },
                              {
                                type: 'input',
                                defaultValue: '',
                                key: 'Località',
                                templateOptions: {
                                  label: 'Località',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  change: (field, e) => {
                                    tunnelInputTemplateComponent.syncSharedModelData(
                                      'localita',
                                      e.target.value
                                    );
                                  },
                                },
                              },
                            ],
                          },
                          {
                            className: 'flex-2',
                            key: 'Coordinate Geografiche',
                            type: 'cube-block-table-type',
                            templateOptions: {
                              grouptitle: 'Coordinate Geografiche',
                            },
                            fieldGroup: [
                              {
                                key: 'Coordinate Geografiche',
                                type: 'radio',
                                defaultValue: 'ETRF2000',
                                className:
                                  'sub-sec radio-sec text-align-center radio-sec-custom-aligment',
                                templateOptions: {
                                  key: 'Coordinate Geografiche',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  required: true,
                                  options: [
                                    {
                                      value: 'ETRF2000',
                                      label: 'ETRF2000',
                                    },
                                    {
                                      value: 'WGS84',
                                      label: 'WGS84',
                                    },
                                  ],
                                  // change: (field, e) => {
                                  //   tunnelInputTemplateComponent.syncSharedModelData(
                                  //     'coordinateGeografiche',
                                  //     field.formControl.value
                                  //   );
                                  // },
                                },
                              },
                              {
                                fieldGroup: [
                                  {
                                    key: 'Iniziale',
                                    wrappers: [
                                      'cube-coordinate-section-row-wrapper',
                                    ],
                                    templateOptions: {
                                      rowTitle: 'Iniziale',
                                    },
                                    fieldGroupClassName: 'display-flex-column',
                                    fieldGroup: [
                                      {
                                        key: 'Quota slm m',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          label: 'Quota s.l.m. [m]',
                                          disabled:
                                            tunnelInputTemplateComponent.isFieldDisabled(
                                              [],
                                              isDisabled
                                            ),
                                          required: true,
                                          change: (field, e) => {
                                            tunnelInputTemplateComponent.syncSharedModelData(
                                              'inizialeQuota',
                                              e.target.value
                                            );
                                          },
                                        },
                                      },
                                      {
                                        fieldGroupClassName:
                                          'display-flex grid-gap',
                                        fieldGroup: [
                                          {
                                            className: 'flex-1',
                                            key: 'Longitudine',
                                            type: 'cube-number-type',
                                            defaultValue: '',
                                            templateOptions: {
                                              label: 'Longitudine',
                                              required: true,
                                              disabled:
                                                tunnelInputTemplateComponent.isFieldDisabled(
                                                  [],
                                                  isDisabled
                                                ),
                                              onChange: (field, e) => {
                                                tunnelInputTemplateComponent.syncSharedModelData(
                                                  'inizialeLongitudine',
                                                  e.target.value
                                                );
                                              },
                                            },
                                          },
                                          {
                                            className: 'flex-1',
                                            key: 'Latitudine',
                                            type: 'cube-number-type',
                                            defaultValue: '',
                                            templateOptions: {
                                              label: 'Latitudine',
                                              required: true,
                                              disabled:
                                                tunnelInputTemplateComponent.isFieldDisabled(
                                                  [],
                                                  isDisabled
                                                ),
                                              onChange: (field, e) => {
                                                tunnelInputTemplateComponent.syncSharedModelData(
                                                  'inizialeLatitudine',
                                                  e.target.value
                                                );
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    key: 'Finale',
                                    wrappers: [
                                      'cube-coordinate-section-row-wrapper',
                                    ],
                                    templateOptions: {
                                      rowTitle: 'Finale',
                                    },
                                    fieldGroupClassName: 'display-flex-column',
                                    fieldGroup: [
                                      {
                                        key: 'Quota slm m',
                                        type: 'input',
                                        defaultValue: '',
                                        templateOptions: {
                                          label: 'Quota s.l.m. [m]',
                                          disabled:
                                            tunnelInputTemplateComponent.isFieldDisabled(
                                              [],
                                              isDisabled
                                            ),
                                          required: true,
                                          change: (field, e) => {
                                            tunnelInputTemplateComponent.syncSharedModelData(
                                              'finaleQuota',
                                              e.target.value
                                            );
                                          },
                                        },
                                      },
                                      {
                                        fieldGroupClassName:
                                          'display-flex grid-gap',
                                        fieldGroup: [
                                          {
                                            className: 'flex-1',
                                            key: 'Longitudine',
                                            type: 'cube-number-type',
                                            defaultValue: '',
                                            templateOptions: {
                                              label: 'Longitudine',
                                              disabled:
                                                tunnelInputTemplateComponent.isFieldDisabled(
                                                  [],
                                                  isDisabled
                                                ),
                                              required: true,
                                              onChange: (field, e) => {
                                                tunnelInputTemplateComponent.syncSharedModelData(
                                                  'finaleLongitudine',
                                                  e.target.value
                                                );
                                              },
                                            },
                                          },
                                          {
                                            className: 'flex-1',
                                            key: 'Latitudine',
                                            type: 'cube-number-type',
                                            defaultValue: '',
                                            templateOptions: {
                                              label: 'Latitudine',
                                              disabled:
                                                tunnelInputTemplateComponent.isFieldDisabled(
                                                  [],
                                                  isDisabled
                                                ),
                                              required: true,
                                              onChange: (field, e) => {
                                                tunnelInputTemplateComponent.syncSharedModelData(
                                                  'finaleLatitudine',
                                                  e.target.value
                                                );
                                              },
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      { template: `<hr/>` },
                    ],
                  },
                ],
              },
              {
                //Informazioni Ispezione
                key: 'Informazioni Ispezione',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: { title: 'Informazioni Ispezione' },
                  },
                  {
                    fieldGroupClassName:
                      'display-flex-wrap flex-row-to-column-mobile grid-gap',
                    fieldGroup: [
                      {
                        className: 'flex-1 width-100-mobile',
                        key: 'Data di ispezione',
                        type: 'cube-datepicker-type',
                        defaultValue: '',
                        templateOptions: {
                          readonly: true,
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          label: 'Data di ispezione [dd/mm/yy]',
                          max: new Date(),
                        },
                      },
                      {
                        className:
                          'flex-1 white-space-no-wrap width-100-mobile',
                        key: 'Fascia oraria',
                        type: 'cube-time-range-picker-type',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          label: 'Fascia oraria:',
                          hourFormat: '12',
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName:
                      'display-flex-wrap flex-row-to-column-mobile grid-gap',
                    fieldGroup: [
                      {
                        className: 'flex-1 width-100-mobile',
                        key: 'Società',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          label: 'Società',
                        },
                      },
                      {
                        className: 'flex-1 width-100-mobile',
                        key: 'Validatore',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          label: 'Validatore',
                        },
                      },
                    ],
                  },
                  {
                    key: 'Nome e firma degli ispettori',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex-wrap grid-gap',
                        fieldGroup: [
                          {
                            className: 'flex-1',
                            key: 'Nome e firma degli ispettori 1',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              label: 'Nome e firma degli ispettori 1',
                            },
                          },
                          {
                            className: 'flex-1',
                            key: 'Firma 1',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              label: 'Firma 1',
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex-wrap grid-gap',
                        fieldGroup: [
                          {
                            className: 'flex-1',
                            key: 'Nome e firma degli ispettori 2',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              label: 'Nome e firma degli ispettori 2',
                            },
                          },
                          {
                            className: 'flex-1',
                            key: 'Firma 2',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              label: 'Firma 2',
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex-wrap grid-gap',
                        fieldGroup: [
                          {
                            className: 'flex-1',
                            key: 'Nome e firma degli ispettori 3',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              label: 'Nome e firma degli ispettori 3',
                            },
                          },
                          {
                            className: 'flex-1',
                            key: 'Firma 3',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              label: 'Firma 3',
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    className: 'width-100 flex-1',
                    key: 'Eventuale altro personale presente',
                    type: 'textarea',
                    defaultValue: '',
                    templateOptions: {
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      label: 'Eventuale altro personale presente',
                      rows: 4,
                    },
                  },
                  {
                    className: 'width-100 flex-1',
                    key: "Identificazione squadra incaricata dal Committente a supporto dell'ispezione",
                    type: 'textarea',
                    defaultValue: '',
                    templateOptions: {
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      label:
                        "Identificazione squadra incaricata dal Committente a supporto dell'ispezione",
                      rows: 4,
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap ',
                    key: 'Possibilità di ispezionare tutta la struttura',
                    fieldGroup: [
                      {
                        className: 'width-100 flex-1 margin-auto',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title:
                            'Possibilità di ispezionare tutta la struttura',
                        },
                      },
                      {
                        key: 'radio',
                        type: 'radio',
                        defaultValue: '',
                        className: 'sub-sec radio-sec flex-2',
                        templateOptions: {
                          key: 'Limitazione di carico',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          flex: true,
                          options: [
                            {
                              value: 'SI',
                              label: 'SI',
                            },
                            {
                              value: 'NO',
                              label: 'NO',
                            },
                          ],
                          change: (field) => {
                            if (field.formControl.value !== 'NO') {
                              field.formControl.parent
                                ?.get(['Porzione ispezionata'])
                                ?.setValue(null);
                              field.formControl.parent
                                ?.get(['Porzione ispezionata'])
                                ?.disable();
                            } else {
                              field.formControl.parent
                                ?.get(['Porzione ispezionata'])
                                ?.enable();
                            }
                          },
                          onReset: (field) => {
                            if (field.formControl.value !== 'NO') {
                              field.formControl.parent
                                ?.get(['Porzione ispezionata'])
                                ?.setValue(null);
                              field.formControl.parent
                                ?.get(['Porzione ispezionata'])
                                ?.disable();
                            }
                          },
                        },
                        hooks: {
                          onInit: (field) => {
                            setTimeout(() => {
                              if (field.formControl.value !== 'NO') {
                                field.formControl.parent
                                  ?.get(['Porzione ispezionata'])
                                  ?.setValue(null);
                                field.formControl.parent
                                  ?.get(['Porzione ispezionata'])
                                  ?.disable();
                              } else {
                                field.formControl.parent
                                  ?.get(['Porzione ispezionata'])
                                  ?.enable();
                              }
                            }, 0);
                          },
                        },
                      },
                      {
                        className: 'width-100 flex-1 padding-top-10',
                        key: 'Porzione ispezionata',
                        type: 'input',
                        defaultValue: '',
                        templateOptions: {
                          label: 'Porzione ispezionata',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                        },
                      },
                    ],
                  },
                  {
                    className: 'width-100 flex-1',
                    key: TunnelConstants.eventualiNote,
                    type: 'textarea',
                    defaultValue: '',
                    templateOptions: {
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      label: TunnelConstants.eventualiNote,
                      rows: 4,
                    },
                  },
                ],
              },
              {
                //Tipologia di opera e classificazione d’uso stradale
                key: "Tipologia di opera e classificazione d'uso stradale",
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        "Tipologia di opera e classificazione d'uso stradale",
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap grid-gap',
                    fieldGroup: [
                      {
                        className: 'width-100 flex-1 margin-auto',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title: 'Tipologia di opera †',
                        },
                      },
                    ],
                  },
                  {
                    key: 'Tipologia di opera',
                    fieldGroupClassName: 'display-flex-wrap  grid-gap',
                    fieldGroup: [
                      {
                        key: 'Galleria',
                        type: 'checkbox',
                        defaultValue: false,
                        className: 'radio-sec flex-1 border-right',
                        templateOptions: {
                          key: 'Galleria',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          options: [
                            {
                              value: true,
                              label: 'Galleria',
                            },
                          ],
                        },
                      },
                      {
                        key: 'Paramassi',
                        type: 'checkbox',
                        defaultValue: false,
                        className: 'radio-sec flex-1 border-right',
                        templateOptions: {
                          key: 'Paramassi',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          options: [
                            {
                              value: true,
                              label: 'Paramassi',
                            },
                          ],
                        },
                      },
                      {
                        fieldGroup: [
                          {
                            key: 'Sottopasso',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'radio-sec flex-1',
                            templateOptions: {
                              key: 'Sottopasso',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Sottopasso',
                                },
                              ],
                              change: (field) => {
                                field.formControl.parent
                                  .get('Evidente rischio di allagamento*2')
                                  .setValue(!field.formControl.value);
                              },
                            },
                          },
                          {
                            key: 'Evidente rischio di allagamento*2',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'radio-sec flex-1',
                            templateOptions: {
                              key: 'Evidente rischio di allagamento*2',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Evidente rischio di allagamento †2',
                                },
                              ],
                              change: (field) => {
                                field.formControl.parent
                                  .get(['Sottopasso'])
                                  .setValue(!field.formControl.value);
                              },
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap-column',
                    fieldGroup: [
                      {
                        className: 'width-100 flex-1',
                        template: `<p> † Secondo definizione di cui al § 1.1</p>`,
                      },
                      {
                        className: 'width-100 flex-1',
                        template: `<p> †2 In tal caso dovranno essere condotte specifiche valutazioni.</p>`,
                      },
                    ],
                  },
                ],
              },
              {
                //Caratteristiche strutturali
                key: 'Caratteristiche strutturali',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Caratteristiche strutturali',
                    },
                  },
                  {
                    fieldGroupClassName: 'display-flex-wrap grid-gap',
                    fieldGroup: [
                      {
                        className: 'margin-auto flex-1',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title: 'Tipo di galleria',
                        },
                      },
                      {
                        key: 'Tipo di galleria',
                        className: 'flex-2',
                        fieldGroupClassName: 'display-flex-wrap  grid-gap',
                        fieldGroup: [
                          {
                            fieldGroup: [
                              {
                                key: 'Naturale',
                                type: 'checkbox',
                                defaultValue: false,
                                className:
                                  'radio-sec flex-1 border-right width-100 display-inline-flex',
                                templateOptions: {
                                  key: 'Naturale',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  options: [
                                    {
                                      value: true,
                                      label: 'Naturale',
                                    },
                                  ],
                                  change: (field, e) => {
                                    tunnelInputTemplateComponent.syncSharedModelData(
                                      'tipoDiGalleriaNaturale',
                                      field.formControl.value
                                    );
                                  },
                                },
                              },
                              {
                                key: 'Naturale / artificiale',
                                type: 'checkbox',
                                defaultValue: false,
                                className:
                                  'radio-sec flex-1 border-right width-100 display-inline-flex',
                                templateOptions: {
                                  key: 'Naturale / artificiale',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  options: [
                                    {
                                      value: true,
                                      label: 'Naturale / artificiale',
                                    },
                                  ],
                                  change: (field, e) => {
                                    tunnelInputTemplateComponent.syncSharedModelData(
                                      'tipoDiGalleriaNatureArtificiale',
                                      field.formControl.value
                                    );
                                  },
                                },
                              },
                            ],
                          },
                          {
                            fieldGroup: [
                              {
                                key: 'Artificiale',
                                type: 'checkbox',
                                defaultValue: false,
                                className: 'radio-sec flex-1',
                                templateOptions: {
                                  key: 'Artificiale',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  options: [
                                    {
                                      value: true,
                                      label: 'Artificiale',
                                    },
                                  ],
                                  change: (field, e) => {
                                    tunnelInputTemplateComponent.syncSharedModelData(
                                      'tipoDiGalleriaArtificiale',
                                      field.formControl.value
                                    );
                                  },
                                },
                              },
                              {
                                key: 'Presenza di finestrature',
                                type: 'checkbox',
                                defaultValue: false,
                                className: 'radio-sec flex-1',
                                templateOptions: {
                                  key: 'Presenza di finestrature',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  options: [
                                    {
                                      value: true,
                                      label: 'Presenza di finestrature',
                                    },
                                  ],
                                  change: (field, e) => {
                                    tunnelInputTemplateComponent.syncSharedModelData(
                                      'tipoDiGalleriaPresenzaDiFinestrature',
                                      field.formControl.value
                                    );
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex padding-top-10',
                    fieldGroup: [
                      {
                        className: 'flex-1 border-right',
                        fieldGroup: [
                          {
                            key: 'Sviluppo galleria naturale m',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Sviluppo galleria naturale [m]',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              change: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'sviluppoGalleriaNaturale',
                                  e.target.value
                                );
                              },
                            },
                          },
                          {
                            key: 'Sviluppo tratti finestrati m',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Sviluppo tratti finestrati [m]',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              change: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'sviluppoTrattiFinestrati',
                                  e.target.value
                                );
                              },
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-1',
                        fieldGroup: [
                          {
                            key: 'Sviluppo galleria artificiale m',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Sviluppo galleria artificiale [m]',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              change: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'sviluppoGalleriaArtificiale',
                                  e.target.value
                                );
                              },
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    className: 'margin-auto flex-1',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'Pregalleria †',
                    },
                  },
                  {
                    key: 'Pregalleria',
                    fieldGroup: [
                      {
                        key: 'Imbocco',
                        fieldGroupClassName:
                          'display-flex-wrap border-top border-left border-bottom border-right',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 border-bottom border-right padding-left-10',
                            template: `<p>Imbocco</p>`,
                          },
                          {
                            key: 'radio',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec  flex-1 border-right',
                            templateOptions: {
                              key: 'Imbocco',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                { value: 'SÌ', label: 'SÌ' },
                                { value: 'NO', label: 'NO' },
                              ],
                              change: (field) => {
                                if (field.formControl.value === 'NO') {
                                  field.formControl.parent
                                    .get('Lunghezza m')
                                    .enable();
                                } else {
                                  field.formControl.parent
                                    .get('Lunghezza m')
                                    .reset();
                                  field.formControl.parent
                                    .get('Lunghezza m')
                                    .disable();
                                }

                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'imbocco',
                                  field.formControl.value
                                );
                              },
                              hooks: {
                                onInit: (field) => {
                                  setTimeout(() => {
                                    if (field.formControl.value === 'NO') {
                                      field.formControl.parent
                                        ?.get(['Lunghezza m'])
                                        ?.enable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Lunghezza m'])
                                        ?.reset(null);
                                      field.formControl.parent
                                        ?.get(['Lunghezza m'])
                                        ?.disable();
                                    }
                                  }, 0);
                                },
                              },
                            },
                          },
                          {
                            className: 'flex-1 border-right  padding-left-10',
                            template: `<p>Lunghezza [m]</p>`,
                          },
                          {
                            className:
                              'width-100 input-line flex-1  remove-underline',
                            key: 'Lunghezza m',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              change: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'imboccoLunghezza',
                                  e.target.value
                                );
                              },
                            },
                          },
                        ],
                      },
                      {
                        key: 'Sbocco',
                        fieldGroupClassName:
                          'display-flex-wrap border-top border-left border-bottom border-right',
                        fieldGroup: [
                          {
                            className:
                              'flex-1 border-bottom border-right padding-left-10',
                            template: `<p>Sbocco</p>`,
                          },
                          {
                            key: 'radio',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec  flex-1 border-right',
                            templateOptions: {
                              key: 'Sbocco',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              flex: true,
                              options: [
                                { value: 'SÌ', label: 'SÌ' },
                                { value: 'NO', label: 'NO' },
                              ],
                              change: (field) => {
                                if (field.formControl.value === 'NO') {
                                  field.formControl.parent
                                    .get('Lunghezza m')
                                    .enable();
                                } else {
                                  field.formControl.parent
                                    .get('Lunghezza m')
                                    .reset();
                                  field.formControl.parent
                                    .get('Lunghezza m')
                                    .disable();
                                }

                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'sbocco',
                                  field.formControl.value
                                );
                              },
                              hooks: {
                                onInit: (field) => {
                                  setTimeout(() => {
                                    if (field.formControl.value === 'NO') {
                                      field.formControl.parent
                                        ?.get(['Lunghezza m'])
                                        ?.enable();
                                    } else {
                                      field.formControl.parent
                                        ?.get(['Lunghezza m'])
                                        ?.reset(null);
                                      field.formControl.parent
                                        ?.get(['Lunghezza m'])
                                        ?.disable();
                                    }
                                  }, 0);
                                },
                              },
                            },
                          },
                          {
                            className: 'flex-1 border-right  padding-left-10',
                            template: `<p>Lunghezza [m]</p>`,
                          },
                          {
                            className:
                              'width-100 input-line flex-1  remove-underline',
                            key: 'Lunghezza m',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              change: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'sboccoLunghezza',
                                  e.target.value
                                );
                              },
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    fieldGroupClassName:
                      'display-flex-wrap border-top border-left border-bottom border-right margin-top-20',
                    fieldGroup: [
                      {
                        className:
                          'flex-1 border-bottom border-right padding-left-10',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title: 'Fossi di guardia',
                        },
                      },
                      {
                        key: 'Fossi di guardia',
                        type: 'radio',
                        defaultValue: '',
                        className: 'sub-sec radio-sec  flex-1 border-right',
                        templateOptions: {
                          key: 'Fossi di guardia',
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          flex: true,
                          options: [
                            { value: 'SÌ', label: 'SÌ' },
                            { value: 'NO*²', label: 'NO †²' },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    fieldGroupClassName: 'display-flex-column',
                    fieldGroup: [
                      {
                        className: 'width-100 flex-1',
                        template: `<p> † Secondo definizione di cui all’Allegato A</p>`,
                      },
                      {
                        className: 'width-100 flex-1',
                        template: `<p> †2 L’assenza di fossi di guardia comporta la necessità di specifiche valutazioni.</p>`,
                      },
                    ],
                  },
                ],
              },
              {
                //Tipologia e caratteristiche del rivestimento
                key: 'Tipologia e caratteristiche del rivestimento',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Tipologia e caratteristiche del rivestimento',
                    },
                  },
                  {
                    key: 'Rivestimento definitivo',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'display-flex-wrap grid-gap',
                        fieldGroup: [
                          {
                            className: 'flex-1',
                            wrappers: ['cube-sub-title-wrapper'],
                            templateOptions: {
                              title: 'Rivestimento definitivo',
                            },
                          },
                          {
                            className: 'flex-1',
                            fieldGroupClassName: 'display-flex-wrap grid-gap',
                            fieldGroup: [
                              {
                                className: 'flex-1',
                                wrappers: ['cube-sub-title-wrapper'],
                                templateOptions: {
                                  title: 'spessore [m]',
                                },
                              },
                              {
                                className: 'flex-1',
                                key: 'spessore m',
                                type: 'cube-number-type',
                                defaultValue: '',
                                templateOptions: {
                                  label: '',
                                  disabled:
                                    tunnelInputTemplateComponent.isFieldDisabled(
                                      [],
                                      isDisabled
                                    ),
                                  onChange: (field, e) => {
                                    tunnelInputTemplateComponent.syncSharedModelData(
                                      'spessore',
                                      e.target.value
                                    );
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        fieldGroupClassName:
                          'display-flex-wrap border-top border-left border-bottom border-right',
                        fieldGroup: [
                          {
                            key: 'Calcestruzzo armato',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'radio-sec flex-1 border-right',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Calcestruzzo armato',
                                },
                              ],
                              change: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'calcestruzzoArmato',
                                  field.formControl.value
                                );
                              },
                            },
                          },
                          {
                            key: 'Calcestruzzo non armato',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'radio-sec flex-1 border-right',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Calcestruzzo non armato',
                                },
                              ],
                              change: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'calcestruzzoNonArmato',
                                  field.formControl.value
                                );
                              },
                            },
                          },
                          {
                            key: 'Calcestruzzo proiettato',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'radio-sec flex-1 border-right',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Calcestruzzo proiettato',
                                },
                              ],
                              change: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'calcestruzzoProiettato',
                                  field.formControl.value
                                );
                              },
                            },
                          },
                          {
                            key: 'Muratura',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'radio-sec flex-1',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Muratura',
                                },
                              ],
                              change: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'muratura',
                                  field.formControl.value
                                );
                              },
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName:
                          'display-flex-wrap  border-left border-bottom border-right',
                        fieldGroup: [
                          {
                            key: 'Legno',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'radio-sec flex-1 border-right',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Legno',
                                },
                              ],
                              change: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'legno',
                                  field.formControl.value
                                );
                              },
                            },
                          },
                          {
                            key: 'Conci prefabbricati',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'radio-sec flex-1 border-right',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Conci prefabbricati',
                                },
                              ],
                              change: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'conciPrefabbricati',
                                  field.formControl.value
                                );
                              },
                            },
                          },
                          {
                            key: 'Altro',
                            type: 'checkbox',
                            defaultValue: false,
                            className: 'radio-sec flex-1 border-right',
                            templateOptions: {
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              options: [
                                {
                                  value: true,
                                  label: 'Altro',
                                },
                              ],
                              change: (field) => {
                                if (field.formControl.value) {
                                  field.formControl.parent
                                    .get('Altro text')
                                    .reset();
                                  field.formControl.parent
                                    .get('Altro text')
                                    .enable();
                                } else {
                                  field.formControl.parent
                                    .get('Altro text')
                                    .reset();
                                  field.formControl.parent
                                    .get('Altro text')
                                    .disable();
                                }
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'altro',
                                  field.formControl.value
                                );
                              },
                              hooks: {
                                onInit: (field) => {
                                  if (field.formControl.value) {
                                    field.formControl.parent
                                      .get('Altro text')
                                      .reset();
                                    field.formControl.parent
                                      .get('Altro text')
                                      .enable();
                                  } else {
                                    field.formControl.parent
                                      .get('Altro text')
                                      .reset();
                                    field.formControl.parent
                                      .get('Altro text')
                                      .disable();
                                  }
                                },
                              },
                            },
                          },
                          {
                            className: 'flex-1',
                            key: 'Altro text',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              change: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'altroText',
                                  e.target.value
                                );
                              },
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName:
                          'display-flex-wrap  border-left border-bottom border-right',
                        key: 'Armatura',
                        fieldGroup: [
                          {
                            className: 'flex-1 border-right padding-left-10',
                            template: `<p>Armatura<p>`,
                          },
                          {
                            key: 'radio',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec flex-2 border-right',
                            templateOptions: {
                              key: 'radio',
                              flex: true,
                              options: [
                                { value: 'SI', label: 'SI' },
                                { value: 'NO', label: 'NO' },
                              ],
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              change: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'armatura',
                                  field.formControl.value
                                );
                              },
                            },
                          },
                          {
                            className: 'flex-1 border-right padding-left-10',
                            template: `<p>Diametro [mm]<p>`,
                          },
                          {
                            className: 'flex-1',
                            key: 'Diametro mm',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              onChange: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'diametro',
                                  e.target.value
                                );
                              },
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName:
                          'display-flex-wrap  border-left border-bottom border-right',
                        fieldGroup: [
                          {
                            className: 'flex-1 border-right padding-left-10',
                            template: `<p>Copriferro [mm]<p>`,
                          },
                          {
                            className: 'flex-2',
                            key: 'Copriferro mm',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              change: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'copriferro',
                                  e.target.value
                                );
                              },
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName:
                          'display-flex-wrap  border-left border-bottom border-right',
                        key: 'Fibre metalliche',
                        fieldGroup: [
                          {
                            className: 'flex-1 border-right padding-left-10',
                            template: `<p>Fibre metalliche<p>`,
                          },
                          {
                            key: 'Fibre metalliche radio',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec flex-2 border-right',
                            templateOptions: {
                              key: 'Fibre metalliche radio',
                              flex: true,
                              options: [
                                { value: 'SI', label: 'SI' },
                                { value: 'NO', label: 'NO' },
                              ],
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              change: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'fibreMetallicheRadio',
                                  field.formControl.value
                                );
                              },
                            },
                          },
                          {
                            className: 'flex-1 border-right padding-left-10',
                            template: `<p>Caratteristiche<p>`,
                          },
                          {
                            className: 'flex-1',
                            key: 'Caratteristiche',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              change: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'caratteristiche',
                                  e.target.value
                                );
                              },
                            },
                          },
                        ],
                      },
                      {
                        className:
                          'display-flex-wrap  border-left border-bottom border-right padding-10 background-color-grey font-color-black',
                        template: `<div>In caso di utilizzo dii conci prefabbricati:</div>`,
                      },
                      {
                        fieldGroupClassName:
                          'display-flex-wrap  border-left border-bottom border-right',
                        key: 'In caso di utilizzo dii conci prefabbricati',
                        fieldGroup: [
                          {
                            className: 'flex-2 border-right',
                            key: 'Numero di conci',
                            type: 'cube-number-type',
                            defaultValue: '',
                            templateOptions: {
                              label: 'Numero di conci',
                              required: true,
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              min: 1,
                              onChange: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'numeroDiConci',
                                  e.target.value
                                );
                              },
                              onBlur: (field, e) => {
                                const concioNo = field.formControl.value;
                                const conciolist =
                                  tunnelInputTemplateComponent.model[
                                    TunnelConstants.levels.l1
                                  ].conciolist;

                                // If concios have already been added
                                if (conciolist?.length) {
                                  const previousConcioNo =
                                    tunnelInputTemplateComponent.concioNoList
                                      .length;

                                  // If concio no has been decreased
                                  if (concioNo < previousConcioNo) {
                                    // Finding highest concio number which has already been added
                                    const conciNosList = conciolist.map(
                                      (concio) => concio[TunnelConstants.partNo]
                                    );
                                    const highestConciNo = Math.max(
                                      ...conciNosList
                                    );

                                    if (concioNo < highestConciNo) {
                                      // If new concio no is less than the highest concio number
                                      tunnelInputTemplateComponent.getConfirmation(
                                        {
                                          confirmButtonText: 'Ok',
                                          isCancelButtonVisible: false,
                                          message: `Details for concio no. ${highestConciNo} have already been added. Numero di conci cannot be less than ${highestConciNo}.`,
                                          title: 'Info',
                                        }
                                      );

                                      // Reverting the concio number to previous concio number
                                      tunnelInputTemplateComponent.syncSharedModelData(
                                        'numeroDiConci',
                                        previousConcioNo
                                      );

                                      return;
                                    }
                                  }
                                }

                                // Setting concio no list
                                tunnelInputTemplateComponent.concioNoList =
                                  Array(concioNo)
                                    .fill(0)
                                    .map((x, i) => ({
                                      value: i + 1,
                                      label: i + 1,
                                    }));
                              },
                            },
                          },
                          {
                            className: 'flex-1 border-right padding-left-10',
                            template: `<p>geometria conci<p>`,
                          },
                          {
                            className: 'flex-2',
                            key: 'geometria conci',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              change: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'geometriaConci',
                                  e.target.value
                                );
                              },
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName:
                          'display-flex-wrap  border-left border-bottom border-right',
                        key: 'In caso di utilizzo dii conci prefabbricati',
                        fieldGroup: [
                          {
                            className: 'flex-1 border-right padding-left-10',
                            template: `<p>Tipologia delle guarnizioni (gasket)<p>`,
                          },
                          {
                            className: 'flex-2 border-right',
                            key: 'Tipologia delle guarnizioni (gasket)',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                              change: (field, e) => {
                                tunnelInputTemplateComponent.syncSharedModelData(
                                  'tipologiaDelleGuarnizioniGasket',
                                  e.target.value
                                );
                              },
                            },
                          },
                        ],
                      },
                      {
                        className: 'flex-1 padding-top-30',
                        wrappers: ['cube-sub-title-wrapper'],
                        templateOptions: {
                          title:
                            'Finitura rivestimento interno (es. pannelli metallici, onduline, rivestimento ceramico, ecc.)',
                        },
                      },
                      {
                        key: 'Rivestimento interno',
                        fieldGroupClassName:
                          'display-flex-wrap  border-left border-top border-bottom border-right',
                        fieldGroup: [
                          {
                            className: 'flex-1 border-right padding-left-10',
                            template: `<p>Rivestimento interno<p>`,
                          },
                          {
                            key: 'Rivestimento interno',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec flex-2 border-right',
                            templateOptions: {
                              key: 'Rivestimento interno',
                              flex: true,
                              options: [
                                { value: 'SI', label: 'SI' },
                                { value: 'NO', label: 'NO' },
                              ],
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                          {
                            className: 'flex-1 border-right padding-left-10',
                            template: `<p>Tipo<p>`,
                          },
                          {
                            className: 'flex-1',
                            key: 'Tipo',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName:
                          'display-flex-wrap  border-left border-top border-bottom border-right',
                        fieldGroup: [
                          {
                            className: 'flex-1 border-right padding-left-10',
                            template: `<p>Modalità di ancoraggio al rivestimento definitivo<p>`,
                          },
                          {
                            className: 'flex-3',
                            key: 'Modalità di ancoraggio al rivestimento definitivo',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                      {
                        key: 'Sviluppo',
                        fieldGroupClassName:
                          'display-flex-wrap  border-left border-top border-bottom border-right',
                        fieldGroup: [
                          {
                            className: 'flex-1 border-right padding-left-10',
                            template: `<p>Sviluppo<p>`,
                          },
                          {
                            key: 'Sviluppo',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec flex-2 border-right',
                            templateOptions: {
                              key: 'Sviluppo',
                              flex: true,
                              options: [
                                {
                                  value: 'integrale*',
                                  label: 'integrale †',
                                },
                                { value: 'parziale', label: 'parziale' },
                              ],
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                          {
                            className: 'flex-1 border-right padding-left-10',
                            template: `<p>Sviluppo [m]<p>`,
                          },
                          {
                            className: 'flex-1',
                            key: 'Sviluppo m',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                      {
                        key: 'Altezza (dal piano viabile)',
                        fieldGroupClassName:
                          'display-flex-wrap  border-left border-top border-bottom border-right',
                        fieldGroup: [
                          {
                            className: 'flex-1 border-right padding-left-10',
                            template: `<p>Altezza (dal piano viabile)<p>`,
                          },
                          {
                            key: 'Altezza radio',
                            type: 'radio',
                            defaultValue: '',
                            className: 'sub-sec radio-sec flex-2 border-right',
                            templateOptions: {
                              key: 'Altezza radio',
                              flex: true,
                              options: [
                                {
                                  value: 'integrale*²',
                                  label: 'integrale †²',
                                },
                                { value: 'parziale', label: 'parziale' },
                              ],
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                          {
                            className: 'flex-1 border-right padding-left-10',
                            template: `<p>altezza [m]<p>`,
                          },
                          {
                            className: 'flex-1',
                            key: 'altezza m',
                            type: 'input',
                            defaultValue: '',
                            templateOptions: {
                              label: '',
                              disabled:
                                tunnelInputTemplateComponent.isFieldDisabled(
                                  [],
                                  isDisabled
                                ),
                            },
                          },
                        ],
                      },
                      {
                        fieldGroupClassName: 'display-flex-column',
                        fieldGroup: [
                          {
                            className: 'width-100 flex-1',
                            template: `<p> † per l’intero sviluppo della galleria.</p>`,
                          },
                          {
                            className: 'width-100 flex-1',
                            template: `<p> †2 per l’intero sviluppo dell’intradosso del rivestimento della galleria.</p>`,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                className: 'text-align-center font-color-red',
                template: `<h3>OCCORRE COMPILARE UNA SCHEDA DESCRITTIVA DI ISPEZIONE E LE SCHEDE DI RILIEVO DEI DIFETTI PER OGNI SINGOLO CONCIO/SEZIONE, OPPORTUNAMENTE IDENTIFICATO.</h3>`,
              },
              {
                fieldGroup: [
                  {
                    className: 'flex-1',
                    fieldGroup: [
                      {
                        className: 'text-align-center file-upload-sub-header ',
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title:
                            'Identificazione dei conci/sezioni con apposita marcatura',
                        },
                      },
                      {
                        key: 'Identificazione dei conci/sezioni con apposita marcatura',
                        type: 'cube-file-upload-with-preview-type',
                        className:
                          'border-bottom width-100 remove-underline upload-section',
                        templateOptions: {
                          acceptedFileTypes:
                            environment.file_upload.accepted_file_types
                              .imagesAndPdf,
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          modelData: tunnelInputTemplateComponent.model,
                          token: tunnelInputTemplateComponent.token,
                        },
                      },
                    ],
                  },
                ],
              },
              {
                fieldGroupClassName: 'display-flex-wrap ',
                fieldGroup: [
                  {
                    className: 'flex-1 margin-auto',
                    wrappers: ['cube-sub-title-wrapper'],
                    templateOptions: {
                      title: 'IDENTIFICATIVO CONCIO/SEZIONE',
                    },
                  },
                  {
                    className: 'flex-3 padding-top-10',
                    key: 'IDENTIFICATIVO CONCIO/SEZIONE',
                    type: 'input',
                    defaultValue: '',
                    templateOptions: {
                      label: '',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                    },
                  },
                ],
              },
              {
                className: 'font-color-red',
                template: `<h3>Rilievo della geometria</h3>`,
              },
              {
                //Schemi geometrici di massima (Indicare le dimensioni principali ed eventuali quadri fessurativi)
                key: 'Schemi geometrici di massima (Indicare le dimensioni principali ed eventuali quadri fessurativi)',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'Schemi geometrici di massima (Indicare le dimensioni principali ed eventuali quadri fessurativi)',
                    },
                  },
                  {
                    fieldGroupClassName:
                      'display-flex-wrap grid-gap padding-top margin-bottom-30',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        key: 'Schemi geometrici di massima (Indicare le dimensioni principali ed eventuali quadri fessurativi) section',
                        type: 'cube-repeat-section-type',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          onDeleteRow: (index) => {
                            let deletedFiles = [];
                            deletedFiles = Object.values(
                              tunnelInputTemplateComponent.model[
                                TunnelConstants.levels.l1
                              ][
                                'Schemi geometrici di massima (Indicare le dimensioni principali ed eventuali quadri fessurativi)'
                              ][
                                'Schemi geometrici di massima (Indicare le dimensioni principali ed eventuali quadri fessurativi) section'
                              ][index] || {}
                            ).filter((res) => res);
                            tunnelInputTemplateComponent.deleteUploadedFiles(
                              deletedFiles
                            );
                            tunnelInputTemplateComponent.model[
                              TunnelConstants.levels.l1
                            ][
                              'Schemi geometrici di massima (Indicare le dimensioni principali ed eventuali quadri fessurativi)'
                            ][
                              'Schemi geometrici di massima (Indicare le dimensioni principali ed eventuali quadri fessurativi) section'
                            ].splice(index, 1);
                            tunnelInputTemplateComponent.refreshFields();
                          },
                        },
                        fieldArray: {
                          fieldGroupClassName:
                            'display-flex-column file-upload-sub-header',
                          fieldGroup: [
                            {
                              fieldGroupClassName:
                                'display-flex-wrap  file-upload-sub-header',
                              fieldGroup: [
                                // Sezione trasversale
                                {
                                  className: 'flex-1',
                                  fieldGroup: [
                                    {
                                      className:
                                        'text-align-center file-upload-sub-header ',
                                      wrappers: ['cube-section-title-wrapper'],
                                      expressionProperties: {
                                        'templateOptions.title':
                                          "'Sezione trasversale n.' + (+field.parent.parent.parent.key + 1)",
                                      },
                                    },
                                    {
                                      key: 'Sezione trasversale',
                                      type: 'cube-file-upload-with-preview-type',
                                      className:
                                        'border-bottom width-100 remove-underline upload-section',
                                      templateOptions: {
                                        acceptedFileTypes:
                                          environment.file_upload
                                            .accepted_file_types.imagesAndPdf,
                                        disabled:
                                          tunnelInputTemplateComponent.isFieldDisabled(
                                            [],
                                            isDisabled
                                          ),
                                        modelData:
                                          tunnelInputTemplateComponent.model,
                                        token:
                                          tunnelInputTemplateComponent.token,
                                      },
                                    },
                                  ],
                                },
                                // Sezione longitudinale
                                {
                                  className: 'flex-1 border-left ',
                                  fieldGroup: [
                                    {
                                      className:
                                        'text-align-center file-upload-sub-header',
                                      wrappers: ['cube-section-title-wrapper'],
                                      expressionProperties: {
                                        'templateOptions.title':
                                          "'Sezione longitudinale n.' + (+field.parent.parent.parent.key + 1)",
                                      },
                                    },
                                    {
                                      key: 'Sezione longitudinale',
                                      type: 'cube-file-upload-with-preview-type',
                                      className:
                                        'border-bottom width-100 remove-underline upload-section',
                                      templateOptions: {
                                        acceptedFileTypes:
                                          environment.file_upload
                                            .accepted_file_types.imagesAndPdf,
                                        disabled:
                                          tunnelInputTemplateComponent.isFieldDisabled(
                                            [],
                                            isDisabled
                                          ),
                                        modelData:
                                          tunnelInputTemplateComponent.model,
                                        token:
                                          tunnelInputTemplateComponent.token,
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                            // Pianta
                            {
                              fieldGroupClassName: 'file-upload-sub-header',
                              fieldGroup: [
                                {
                                  className:
                                    'text-align-center file-upload-sub-header ',
                                  wrappers: ['cube-section-title-wrapper'],
                                  expressionProperties: {
                                    'templateOptions.title':
                                      "'Pianta n.' + (+field.parent.parent.key + 1)",
                                  },
                                },
                                {
                                  key: 'Pianta',
                                  type: 'cube-file-upload-with-preview-type',
                                  className:
                                    'border-bottom width-100 remove-underline upload-section',
                                  templateOptions: {
                                    acceptedFileTypes:
                                      environment.file_upload
                                        .accepted_file_types.imagesAndPdf,
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                    modelData:
                                      tunnelInputTemplateComponent.model,
                                    token: tunnelInputTemplateComponent.token,
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                  // Vista tridimensionale
                  {
                    fieldGroupClassName: 'file-upload-sub-header',
                    fieldGroup: [
                      {
                        className: 'text-align-center file-upload-sub-header ',
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Vista tridimensionale',
                        },
                      },
                      {
                        key: 'Vista tridimensionale',
                        type: 'cube-file-upload-with-preview-type',
                        className:
                          'border-bottom width-100 remove-underline upload-section',
                        templateOptions: {
                          acceptedFileTypes:
                            environment.file_upload.accepted_file_types
                              .imagesAndPdf,
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          modelData: tunnelInputTemplateComponent.model,
                          token: tunnelInputTemplateComponent.token,
                        },
                      },
                    ],
                  },
                ],
              },
              // Mappatura degli elementi non strutturali, impianti e servizi
              {
                key: 'Mappatura degli elementi non strutturali impianti e servizi',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'Mappatura degli elementi non strutturali, impianti e servizi',
                    },
                  },
                  {
                    fieldGroupClassName:
                      'display-flex grid-gap padding-top margin-bottom-30',
                    fieldGroup: [
                      {
                        className: 'flex-1',
                        key: 'Mappatura degli elementi non strutturali impianti e servizi section',
                        type: 'cube-repeat-section-type',
                        templateOptions: {
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          onDeleteRow: (index) => {
                            let deletedFiles = [];
                            deletedFiles = Object.values(
                              tunnelInputTemplateComponent.model[
                                TunnelConstants.levels.l1
                              ][
                                'Mappatura degli elementi non strutturali impianti e servizi'
                              ][
                                'Mappatura degli elementi non strutturali impianti e servizi section'
                              ][index] || {}
                            ).filter((res) => res);
                            tunnelInputTemplateComponent.deleteUploadedFiles(
                              deletedFiles
                            );
                            tunnelInputTemplateComponent.model[
                              TunnelConstants.levels.l1
                            ][
                              'Mappatura degli elementi non strutturali impianti e servizi'
                            ][
                              'Mappatura degli elementi non strutturali impianti e servizi section'
                            ].splice(index, 1);
                            tunnelInputTemplateComponent.refreshFields();
                          },
                        },
                        fieldArray: {
                          fieldGroupClassName:
                            'display-flex-column file-upload-sub-header',
                          fieldGroup: [
                            {
                              fieldGroupClassName:
                                'display-flex-wrap  file-upload-sub-header',
                              fieldGroup: [
                                // Sezione trasversale
                                {
                                  className: 'flex-1',
                                  fieldGroup: [
                                    {
                                      className:
                                        'text-align-center file-upload-sub-header ',
                                      wrappers: ['cube-section-title-wrapper'],
                                      expressionProperties: {
                                        'templateOptions.title':
                                          "'Sezione trasversale n.' + (+field.parent.parent.parent.key + 1)",
                                      },
                                    },
                                    {
                                      key: 'Sezione trasversale',
                                      type: 'cube-file-upload-with-preview-type',
                                      className:
                                        'border-bottom width-100 remove-underline upload-section',
                                      templateOptions: {
                                        acceptedFileTypes:
                                          environment.file_upload
                                            .accepted_file_types.imagesAndPdf,
                                        disabled:
                                          tunnelInputTemplateComponent.isFieldDisabled(
                                            [],
                                            isDisabled
                                          ),
                                        modelData:
                                          tunnelInputTemplateComponent.model,
                                        token:
                                          tunnelInputTemplateComponent.token,
                                      },
                                    },
                                  ],
                                },
                                // Sezione longitudinale
                                {
                                  className: 'flex-1 border-left',
                                  fieldGroup: [
                                    {
                                      className:
                                        'text-align-center file-upload-sub-header',
                                      wrappers: ['cube-section-title-wrapper'],
                                      expressionProperties: {
                                        'templateOptions.title':
                                          "'Sezione longitudinale n.' + (+field.parent.parent.parent.key + 1)",
                                      },
                                    },
                                    {
                                      key: 'Sezione longitudinale',
                                      type: 'cube-file-upload-with-preview-type',
                                      className:
                                        'border-bottom width-100 remove-underline upload-section',
                                      templateOptions: {
                                        acceptedFileTypes:
                                          environment.file_upload
                                            .accepted_file_types.imagesAndPdf,
                                        disabled:
                                          tunnelInputTemplateComponent.isFieldDisabled(
                                            [],
                                            isDisabled
                                          ),
                                        modelData:
                                          tunnelInputTemplateComponent.model,
                                        token:
                                          tunnelInputTemplateComponent.token,
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                            // Pianta
                            {
                              fieldGroupClassName: 'file-upload-sub-header',
                              fieldGroup: [
                                {
                                  className:
                                    'text-align-center file-upload-sub-header ',
                                  wrappers: ['cube-section-title-wrapper'],
                                  expressionProperties: {
                                    'templateOptions.title':
                                      "'Pianta n.' + (+field.parent.parent.key + 1)",
                                  },
                                },
                                {
                                  key: 'Pianta',
                                  type: 'cube-file-upload-with-preview-type',
                                  className:
                                    'border-bottom width-100 remove-underline upload-section',
                                  templateOptions: {
                                    acceptedFileTypes:
                                      environment.file_upload
                                        .accepted_file_types.imagesAndPdf,
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                    modelData:
                                      tunnelInputTemplateComponent.model,
                                    token: tunnelInputTemplateComponent.token,
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                  // Vista tridimensionale
                  {
                    fieldGroupClassName: 'file-upload-sub-header',
                    fieldGroup: [
                      {
                        className: 'text-align-center file-upload-sub-header ',
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Vista tridimensionale',
                        },
                      },
                      {
                        key: 'Vista tridimensionale',
                        type: 'cube-file-upload-with-preview-type',
                        className:
                          'border-bottom width-100 remove-underline upload-section',
                        templateOptions: {
                          acceptedFileTypes:
                            environment.file_upload.accepted_file_types
                              .imagesAndPdf,
                          disabled:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          modelData: tunnelInputTemplateComponent.model,
                          token: tunnelInputTemplateComponent.token,
                        },
                      },
                      { template: `<hr/>` },
                    ],
                  },
                ],
              },
              // Restituzione delle indagini termografiche
              {
                key: 'Restituzione delle indagini termografiche',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'Restituzione delle indagini termografiche (eventuali)',
                    },
                  },
                  {
                    key: 'indagini termografiche',
                    type: 'cube-file-upload-with-preview-type',
                    className:
                      'border-bottom width-100 remove-underline upload-section',
                    templateOptions: {
                      acceptedFileTypes:
                        environment.file_upload.accepted_file_types
                          .imagesAndPdf,
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      modelData: tunnelInputTemplateComponent.model,
                      token: tunnelInputTemplateComponent.token,
                    },
                  },
                  { template: `<hr/>` },
                ],
              },
              // Descrizione dei difetti principali rilevati
              {
                key: 'Descrizione dei difetti principali rilevati',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Descrizione dei difetti principali rilevati',
                    },
                  },
                  {
                    key: 'Descrizione sintetica dei difetti',
                    type: 'textarea',
                    defaultValue: '',
                    templateOptions: {
                      label:
                        'Descrizione sintetica dei difetti (anomalie lineari e superficiali), supportata da eventuali schemi, e degli aspetti principali rilevati durante l’ispezione:',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      theme: 'custom',
                      rows: 4,
                      grow: false,
                    },
                  },
                  {
                    key: 'Descrizione sintetica dei difetti upload section',
                    type: 'cube-file-upload-with-preview-type',
                    className:
                      'border-bottom width-100 remove-underline  upload-section',
                    templateOptions: {
                      acceptedFileTypes:
                        environment.file_upload.accepted_file_types
                          .imagesAndPdf,
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      modelData: tunnelInputTemplateComponent.model,
                      token: tunnelInputTemplateComponent.token,
                    },
                  },
                  { template: `<hr/>` },
                  {
                    key: 'Descrizione dellaspetto generale',
                    type: 'textarea',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Descrizione dellaspetto generale:',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      theme: 'custom',
                      rows: 4,
                      grow: false,
                    },
                  },
                  {
                    key: 'Descrizione dellaspetto generale upload section',
                    type: 'cube-file-upload-with-preview-type',
                    className:
                      'border-bottom width-100 remove-underline upload-section',
                    templateOptions: {
                      acceptedFileTypes:
                        environment.file_upload.accepted_file_types
                          .imagesAndPdf,
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      modelData: tunnelInputTemplateComponent.model,
                      token: tunnelInputTemplateComponent.token,
                    },
                  },
                  { template: `<hr/>` },
                  {
                    key: 'Descrizione dei giunti',
                    type: 'textarea',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Descrizione dei giunti:',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      theme: 'custom',
                      rows: 4,
                      grow: false,
                    },
                  },
                  {
                    key: 'Descrizione dei giunti upload section',
                    type: 'cube-file-upload-with-preview-type',
                    className:
                      'border-bottom width-100 remove-underline upload-section',
                    templateOptions: {
                      acceptedFileTypes:
                        environment.file_upload.accepted_file_types
                          .imagesAndPdf,
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      modelData: tunnelInputTemplateComponent.model,
                      token: tunnelInputTemplateComponent.token,
                    },
                  },
                  { template: `<hr/>` },
                  {
                    key: 'Descrizione del quadro fessurativo',
                    type: 'textarea',
                    defaultValue: '',
                    templateOptions: {
                      label:
                        'Descrizione del quadro fessurativo (ritiro, rottura, etc.), aree di suono cave, venute d’acqua, deterioramenti vari:',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      theme: 'custom',
                      rows: 4,
                      grow: false,
                    },
                  },
                  {
                    key: 'Descrizione del quadro fessurativo upload section',
                    type: 'cube-file-upload-with-preview-type',
                    className:
                      'border-bottom width-100 remove-underline upload-section',
                    templateOptions: {
                      acceptedFileTypes:
                        environment.file_upload.accepted_file_types
                          .imagesAndPdf,
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      modelData: tunnelInputTemplateComponent.model,
                      token: tunnelInputTemplateComponent.token,
                    },
                  },
                  { template: `<hr/>` },
                ],
              },
              // Descrizione di eventuali ispezioni di approfondimento
              {
                key: 'Descrizione di eventuali ispezioni di approfondimento',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title:
                        'Descrizione di eventuali ispezioni di approfondimento',
                    },
                  },
                  {
                    key: 'Descrizione di eventuali ispezioni',
                    type: 'textarea',
                    defaultValue: '',
                    templateOptions: {
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      theme: 'custom',
                      rows: 4,
                      grow: false,
                    },
                  },
                  {
                    key: 'Descrizione di eventuali ispezioni upload section',
                    type: 'cube-file-upload-with-preview-type',
                    className:
                      'border-bottom width-100 remove-underline upload-section',
                    templateOptions: {
                      acceptedFileTypes:
                        environment.file_upload.accepted_file_types
                          .imagesAndPdf,
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      modelData: tunnelInputTemplateComponent.model,
                      token: tunnelInputTemplateComponent.token,
                    },
                  },
                  { template: `<hr/>` },
                  {
                    key: 'Descrizione delle indagini strumentali',
                    type: 'textarea',
                    defaultValue: '',
                    templateOptions: {
                      label:
                        'Descrizione delle indagini strumentali effettuate e dei relativi risultati e loro interpretazione:',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      theme: 'custom',
                      rows: 4,
                      grow: false,
                    },
                  },
                  {
                    key: 'Descrizione delle indagini strumentali upload section',
                    type: 'cube-file-upload-with-preview-type',
                    className:
                      'border-bottom width-100 remove-underline upload-section',
                    templateOptions: {
                      acceptedFileTypes:
                        environment.file_upload.accepted_file_types
                          .imagesAndPdf,
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      modelData: tunnelInputTemplateComponent.model,
                      token: tunnelInputTemplateComponent.token,
                    },
                  },
                  { template: `<hr/>` },
                  {
                    key: 'Analisi delle indagini georadar',
                    type: 'textarea',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Analisi delle indagini georadar:',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      theme: 'custom',
                      rows: 4,
                      grow: false,
                    },
                  },
                  {
                    key: 'Analisi delle indagini georadar upload section',
                    type: 'cube-file-upload-with-preview-type',
                    className:
                      'border-bottom width-100 remove-underline upload-section',
                    templateOptions: {
                      acceptedFileTypes:
                        environment.file_upload.accepted_file_types
                          .imagesAndPdf,
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      modelData: tunnelInputTemplateComponent.model,
                      token: tunnelInputTemplateComponent.token,
                    },
                  },
                  { template: `<hr/>` },
                  {
                    key: 'Analisi di microcarotaggi e endoscopie',
                    type: 'textarea',
                    defaultValue: '',
                    templateOptions: {
                      label: 'Analisi di microcarotaggi e endoscopie:',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      theme: 'custom',
                      rows: 4,
                      grow: false,
                    },
                  },
                  {
                    key: 'Analisi di microcarotaggi e endoscopie upload section',
                    type: 'cube-file-upload-with-preview-type',
                    className:
                      'border-bottom width-100 remove-underline upload-section',
                    templateOptions: {
                      acceptedFileTypes:
                        environment.file_upload.accepted_file_types
                          .imagesAndPdf,
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      modelData: tunnelInputTemplateComponent.model,
                      token: tunnelInputTemplateComponent.token,
                    },
                  },
                  { template: `<hr/>` },
                  {
                    key: 'Eventuali raccomandazioni per nuove indagini',
                    type: 'textarea',
                    defaultValue: '',
                    templateOptions: {
                      label:
                        'Eventuali raccomandazioni per nuove indagini/monitoraggi:',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      theme: 'custom',
                      rows: 4,
                      grow: false,
                    },
                  },
                  {
                    key: 'Eventuali raccomandazioni per nuove indagini upload section',
                    type: 'cube-file-upload-with-preview-type',
                    className:
                      'border-bottom width-100 remove-underline upload-section',
                    templateOptions: {
                      acceptedFileTypes:
                        environment.file_upload.accepted_file_types
                          .imagesAndPdf,
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      modelData: tunnelInputTemplateComponent.model,
                      token: tunnelInputTemplateComponent.token,
                    },
                  },
                  { template: `<hr/>` },
                ],
              },
              // Documentazione fotografica e grafica
              {
                key: 'Documentazione fotografica e grafica',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Documentazione fotografica e grafica',
                    },
                  },
                  {
                    className: 'width-100 flex-1 input-line',
                    key: 'Georeferenziazione delle foto scattate durante lispezione',
                    type: 'cube-file-upload-type',
                    defaultValue: '',
                    templateOptions: {
                      acceptedFileTypes:
                        environment.file_upload.accepted_file_types.pdf,
                      label:
                        'Georeferenziazione delle foto scattate durante l’ispezione:',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      token: tunnelInputTemplateComponent.token,
                      modelData: tunnelInputTemplateComponent.model,
                    },
                  },
                ],
              },
              // Conclusioni e giudizio esperto su ciascun concio
              {
                key: 'Conclusioni e giudizio esperto su ciascun concio',
                fieldGroup: [
                  {
                    wrappers: ['cube-section-title-wrapper'],
                    templateOptions: {
                      title: 'Conclusioni e giudizio esperto su ciascun concio',
                    },
                  },
                  {
                    key: 'Conclusioni e giudizio esperto',
                    type: 'textarea',
                    defaultValue: '',
                    templateOptions: {
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      theme: 'custom',
                      rows: 4,
                      grow: false,
                    },
                  },
                  {
                    key: 'Conclusioni e giudizio esperto upload section',
                    type: 'cube-file-upload-with-preview-type',
                    className:
                      'border-bottom width-100 remove-underline upload-section',
                    templateOptions: {
                      acceptedFileTypes:
                        environment.file_upload.accepted_file_types
                          .imagesAndPdf,
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      modelData: tunnelInputTemplateComponent.model,
                      token: tunnelInputTemplateComponent.token,
                    },
                  },
                  { template: `<hr/>` },
                  {
                    key: 'Variazioni rispetto alle precedenti ispezioni',
                    type: 'textarea',
                    defaultValue: '',
                    templateOptions: {
                      label:
                        'Variazioni rispetto alle precedenti ispezioni (se disponibili):',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      theme: 'custom',
                      rows: 4,
                      grow: false,
                    },
                  },
                  {
                    key: 'Variazioni rispetto alle precedenti ispezioni upload section',
                    type: 'cube-file-upload-with-preview-type',
                    className:
                      'border-bottom width-100 remove-underline upload-section',
                    templateOptions: {
                      acceptedFileTypes:
                        environment.file_upload.accepted_file_types
                          .imagesAndPdf,
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      modelData: tunnelInputTemplateComponent.model,
                      token: tunnelInputTemplateComponent.token,
                    },
                  },
                  { template: `<hr/>` },
                  {
                    key: 'Eventuali provvedimenti urgenti',
                    type: 'textarea',
                    defaultValue: '',
                    templateOptions: {
                      label:
                        'Eventuali provvedimenti urgenti per la sicurezza degli utenti:',
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      theme: 'custom',
                      rows: 4,
                      grow: false,
                    },
                  },
                  {
                    key: 'Eventuali provvedimenti urgenti upload section',
                    type: 'cube-file-upload-with-preview-type',
                    className:
                      'border-bottom width-100 remove-underline upload-section',
                    templateOptions: {
                      acceptedFileTypes:
                        environment.file_upload.accepted_file_types
                          .imagesAndPdf,
                      disabled: tunnelInputTemplateComponent.isFieldDisabled(
                        [],
                        isDisabled
                      ),
                      modelData: tunnelInputTemplateComponent.model,
                      token: tunnelInputTemplateComponent.token,
                    },
                  },
                ],
              },
            ],
          },
          {
            templateOptions: { label: 'Conci' },
            fieldGroup: [
              {
                key: 'conciolist',
                type: 'cube-ag-grid-table-type',
                className: 'ag-theme-balham',
                templateOptions: {
                  hasModelData: true,
                  height: '100%',
                  width: '100%',
                  gridOptions: {
                    columnDefs: [
                      {
                        headerName: TunnelConstants.partNo,
                        headerClass: 'header-no-child',
                        field: TunnelConstants.partNo,
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 150,
                      },
                      {
                        headerName: 'Action',
                        headerClass: 'header-no-child',
                        field: 'action',
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 300,
                        cellRenderer: actionButtonsRendererComponent,
                        cellRendererParams: {
                          isViewMode:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          isDeleteVisible: true,
                          onEdit: (index) => {
                            tunnelInputTemplateComponent.model.activeLevel =
                              TunnelConstants.levels.l2;
                            tunnelInputTemplateComponent.migrateDataToChildLevel(
                              index,
                              TunnelConstants.levels.l2,
                              tunnelInputTemplateComponent.model[
                                TunnelConstants.levels.l1
                              ].conciolist
                            );
                          },
                          onDelete: async (index) => {
                            const isConfirmed =
                              await tunnelInputTemplateComponent.getConfirmation(
                                {
                                  confirmButtonText: 'Delete',
                                  message: 'Sei sicuro di eliminare la conci?',
                                  title: 'Attenzione',
                                }
                              );

                            if (!isConfirmed) return;

                            // Deleting images under concio
                            const urlList = [
                              `${TunnelConstants.levels.defect1}list`,
                              `${TunnelConstants.levels.defect2}list`,
                              `${TunnelConstants.levels.defect3}list`,
                              `${TunnelConstants.levels.defect4}list`,
                              `${TunnelConstants.levels.defect5}list`,
                            ].reduce((imageArray, elementListName) => {
                              const elementList =
                                tunnelInputTemplateComponent.model[
                                  TunnelConstants.levels.l1
                                ].conciolist[index]?.modelstructure?.[
                                  elementListName
                                ];

                              elementList?.forEach((element) => {
                                element.modelstructure[
                                  'Scheda Ispezione Ponti di Livello 1'
                                ][
                                  'Scheda Ispezione Ponti di Livello 1 List1'
                                ].forEach((defect) => {
                                  const fotoFields = {};

                                  for (const key in defect['L']) {
                                    if (
                                      defect['L'].hasOwnProperty(key) &&
                                      defect['L'][key]['N° foto']
                                    ) {
                                      Object.assign(
                                        fotoFields,
                                        defect['L'][key]['N° foto']
                                      );
                                    }
                                  }

                                  if (fotoFields) {
                                    const imageUrls =
                                      Object.entries(fotoFields);
                                    imageArray = imageArray.concat(imageUrls);
                                  }
                                });
                              });

                              return imageArray;
                            }, []);

                            if (urlList.length)
                              tunnelInputTemplateComponent.deleteUploadedDefectFiles(
                                urlList
                              );

                            tunnelInputTemplateComponent.model[
                              TunnelConstants.levels.l1
                            ].conciolist.splice(index, 1);
                            tunnelInputTemplateComponent.model[
                              TunnelConstants.levels.l1
                            ].conciolist = [].concat(
                              tunnelInputTemplateComponent.model[
                                TunnelConstants.levels.l1
                              ].conciolist
                            );
                            tunnelInputTemplateComponent.concioCount -= 1;
                            tunnelInputTemplateComponent.refreshFields();
                          },
                        },
                      },
                    ],
                  },
                },
                fieldArray: {
                  fieldGroup: [
                    {
                      key: TunnelConstants.partNo,
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: TunnelConstants.partNo,
                        readonly: true,
                      },
                    },
                  ],
                },
              },
              {
                // Add New Concio
                type: 'cube-button-type',
                className: 'remove-underline',
                templateOptions: {
                  text: 'Add New',
                  color: 'primary',
                  disabled: tunnelInputTemplateComponent.isFieldDisabled(
                    [],
                    isDisabled
                  ),
                  onClick: (e) => {
                    tunnelInputTemplateComponent.l1BackupData =
                      tunnelInputTemplateComponent.getDeepCopy(
                        tunnelInputTemplateComponent.model[
                          TunnelConstants.levels.l1
                        ]
                      );
                    tunnelInputTemplateComponent.setImages(
                      TunnelConstants.levels.l1
                    );
                    tunnelInputTemplateComponent.model.activeLevel =
                      TunnelConstants.levels.l2;
                  },
                },
              },
            ],
          },
        ],
      },
      {
        // Level: Level 2
        key: TunnelConstants.levels.l2,
        type: 'cube-tabs-type',
        templateOptions: {
          devMode: tunnelInputTemplateComponent.devMode, // TEMP: For development
        },
        hideExpression: () =>
          tunnelInputTemplateComponent.model.activeLevel !==
          TunnelConstants.levels.l2,
        fieldGroup: [
          {
            templateOptions: { label: 'Concio' },
            fieldGroup: [
              {
                className: 'flex-1',
                key: TunnelConstants.partNo,
                type: 'select',
                templateOptions: {
                  disabled: tunnelInputTemplateComponent.isFieldDisabled(
                    [],
                    isDisabled
                  ),
                  required: true,
                  label: 'Conci No',
                  options: [],
                  change: (field, e) => {
                    const concioNo =
                      tunnelInputTemplateComponent.model[
                        TunnelConstants.levels.l2
                      ][TunnelConstants.partNo];
                    const isConcioNoAlreadyAdded =
                      tunnelInputTemplateComponent.model[
                        TunnelConstants.levels.l1
                      ].conciolist?.some(
                        (obj) => obj[TunnelConstants.partNo] === concioNo
                      );

                    if (isConcioNoAlreadyAdded) {
                      // If concio number is already added
                      tunnelInputTemplateComponent.getConfirmation({
                        isCancelButtonVisible: false,
                        message:
                          'Concio number already added. Please choose another concio number.',
                        title: 'Info',
                      });

                      // Reverting concio number to previous number if it was already selected earlier
                      const elementsList = [].concat(
                        tunnelInputTemplateComponent.model[
                          TunnelConstants.levels.l2
                        ][`${TunnelConstants.levels.defect1}list`],
                        tunnelInputTemplateComponent.model[
                          TunnelConstants.levels.l2
                        ][`${TunnelConstants.levels.defect2}list`],
                        tunnelInputTemplateComponent.model[
                          TunnelConstants.levels.l2
                        ][`${TunnelConstants.levels.defect3}list`],
                        tunnelInputTemplateComponent.model[
                          TunnelConstants.levels.l2
                        ][`${TunnelConstants.levels.defect4}list`],
                        tunnelInputTemplateComponent.model[
                          TunnelConstants.levels.l2
                        ][`${TunnelConstants.levels.defect5}list`]
                      );
                      tunnelInputTemplateComponent.model[
                        TunnelConstants.levels.l2
                      ][TunnelConstants.partNo] = elementsList.length
                        ? elementsList[0].modelstructure[
                            'Scheda Ispezione Ponti di Livello 1'
                          ][TunnelConstants.partNo]
                        : '';
                      tunnelInputTemplateComponent.refreshFields();

                      return;
                    }

                    // If concio number has not been added already
                    const defectLevelNames = [
                      TunnelConstants.levels.defect1,
                      TunnelConstants.levels.defect2,
                      TunnelConstants.levels.defect3,
                      TunnelConstants.levels.defect4,
                      TunnelConstants.levels.defect5,
                    ];
                    for (const defectLevelName of defectLevelNames) {
                      // Updating all elements added under concio
                      const elementListName = `${defectLevelName}list`;
                      const elementList =
                        tunnelInputTemplateComponent.model[
                          TunnelConstants.levels.l2
                        ][elementListName];

                      for (const element of elementList) {
                        // Updating all defects under this element
                        element[TunnelConstants.partNo] = concioNo;
                        element.modelstructure[TunnelConstants.partNo] =
                          concioNo;
                        const modelstructure =
                          element.modelstructure[
                            'Scheda Ispezione Ponti di Livello 1'
                          ];

                        // Concio No
                        modelstructure[TunnelConstants.partNo] = concioNo;

                        // Unique code
                        const uniqueCode =
                          modelstructure['uniquecode'].split('.');
                        element.modelstructure[
                          'uniquecode'
                        ] = `${uniqueCode[0]}.${concioNo}`;
                        modelstructure[
                          'uniquecode'
                        ] = `${uniqueCode[0]}.${concioNo}`;
                      }

                      tunnelInputTemplateComponent.model[
                        TunnelConstants.levels.l2
                      ][elementListName] = [].concat(
                        tunnelInputTemplateComponent.model[
                          TunnelConstants.levels.l2
                        ][elementListName]
                      );
                    }

                    tunnelInputTemplateComponent.refreshFields();
                  },
                },
                hooks: {
                  onInit: (field) => {
                    field.templateOptions.options =
                      tunnelInputTemplateComponent.concioNoList;
                  },
                },
                hideExpression: () =>
                  tunnelInputTemplateComponent.l2UpdateStatus,
              },
              {
                className: 'flex-1',
                key: TunnelConstants.partNo,
                type: 'input',
                templateOptions: {
                  readonly: true,
                  required: true,
                  label: TunnelConstants.partNo,
                },
                hideExpression: () =>
                  !tunnelInputTemplateComponent.l2UpdateStatus,
              },
            ],
          },
          {
            // defect1
            templateOptions: { label: '1 - Difetti relativi alla struttura' },
            fieldGroup: [
              {
                key: `${TunnelConstants.levels.defect1}list`,
                type: 'cube-ag-grid-table-type',
                className: 'ag-theme-balham',
                templateOptions: {
                  hasModelData: true,
                  height: '100%',
                  width: '100%',
                  gridOptions: {
                    columnDefs: [
                      {
                        headerName: TunnelConstants.partNo,
                        headerClass: 'header-no-child',
                        field: TunnelConstants.partNo,
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 150,
                      },
                      {
                        headerName: 'Action',
                        headerClass: 'header-no-child',
                        field: 'action',
                        cellStyle: { textAlign: 'center' },
                        cellRenderer: actionButtonsRendererComponent,
                        cellRendererParams: {
                          isViewMode:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          isDeleteVisible: true,
                          isDuplicateVisible: true,
                          onEdit: (index) => {
                            tunnelInputTemplateComponent.model.activeLevel =
                              TunnelConstants.levels.defect1;
                            tunnelInputTemplateComponent.migrateDataToChildLevel(
                              index,
                              TunnelConstants.levels.defect1,
                              tunnelInputTemplateComponent.model[
                                TunnelConstants.levels.l2
                              ][`${TunnelConstants.levels.defect1}list`]
                            );
                          },
                          onDuplicate: (index) => {
                            tunnelInputTemplateComponent.duplicateDefect(
                              index,
                              tunnelInputTemplateComponent.model[
                                TunnelConstants.levels.l2
                              ][`${TunnelConstants.levels.defect1}list`],
                              `${TunnelConstants.levels.defect1}list`
                            );
                          },
                          onDelete: async (index) => {
                            const isConfirmed =
                              await tunnelInputTemplateComponent.getConfirmation(
                                {
                                  confirmButtonText: 'Delete',
                                  message: 'Sei sicuro di eliminare la scheda?',
                                  title: 'Attenzione',
                                }
                              );

                            if (!isConfirmed) return;

                            // Deleting images of deleted element
                            let urlList = [];
                            tunnelInputTemplateComponent.model[
                              TunnelConstants.levels.l2
                            ][`${TunnelConstants.levels.defect1}list`][
                              index
                            ].modelstructure[
                              'Scheda Ispezione Ponti di Livello 1'
                            ][
                              'Scheda Ispezione Ponti di Livello 1 List1'
                            ].forEach((defect) => {
                              const fotoFields = {};

                              for (const key in defect['L']) {
                                if (
                                  defect['L'].hasOwnProperty(key) &&
                                  defect['L'][key]['N° foto']
                                ) {
                                  Object.assign(
                                    fotoFields,
                                    defect['L'][key]['N° foto']
                                  );
                                }
                              }

                              if (fotoFields) {
                                const imageUrls = Object.entries(fotoFields);
                                urlList = urlList.concat(imageUrls);
                              }
                            });

                            if (urlList.length)
                              tunnelInputTemplateComponent.deleteUploadedDefectFiles(
                                urlList
                              );

                            tunnelInputTemplateComponent.model[
                              TunnelConstants.levels.l2
                            ][`${TunnelConstants.levels.defect1}list`].splice(
                              index,
                              1
                            );
                            tunnelInputTemplateComponent.model[
                              TunnelConstants.levels.l2
                            ][`${TunnelConstants.levels.defect1}list`] =
                              [].concat(
                                tunnelInputTemplateComponent.model[
                                  TunnelConstants.levels.l2
                                ][`${TunnelConstants.levels.defect1}list`]
                              );
                            tunnelInputTemplateComponent.refreshFields();
                          },
                        },
                        flex: 1,
                        minWidth: 180,
                      },
                    ],
                  },
                },
                fieldArray: {
                  fieldGroup: [
                    {
                      key: TunnelConstants.partNo,
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: TunnelConstants.partNo,
                        readonly: true,
                      },
                    },
                  ],
                },
              },
              {
                // Add New Defect 1
                type: 'cube-button-type',
                className: 'remove-underline',
                templateOptions: {
                  text: 'Add New',
                  color: 'primary',
                  disabled: tunnelInputTemplateComponent.isFieldDisabled(
                    [],
                    isDisabled
                  ),
                  onClick: (e) => {
                    tunnelInputTemplateComponent.model[
                      TunnelConstants.levels.defect1
                    ]['Scheda Ispezione Ponti di Livello 1'][
                      TunnelConstants.partNo
                    ] =
                      tunnelInputTemplateComponent.model[
                        TunnelConstants.levels.l2
                      ][TunnelConstants.partNo];
                    tunnelInputTemplateComponent.model[
                      TunnelConstants.levels.defect1
                    ]['Scheda Ispezione Ponti di Livello 1']['uniquecode'] =
                      '1.' +
                      tunnelInputTemplateComponent.model[
                        TunnelConstants.levels.l2
                      ][TunnelConstants.partNo];

                    tunnelInputTemplateComponent.model[
                      TunnelConstants.levels.defect1
                    ]['Scheda Ispezione Ponti di Livello 1']['Codice IOP'] =
                      tunnelInputTemplateComponent.model[
                        TunnelConstants.levels.l0
                      ]['Codice IOP'];

                    tunnelInputTemplateComponent.model.activeLevel =
                      TunnelConstants.levels.defect1;
                  },
                },
              },
            ],
          },
          {
            // defect2
            templateOptions: { label: '2 - Difetti relativi alla struttura' },
            fieldGroup: [
              {
                key: `${TunnelConstants.levels.defect2}list`,
                type: 'cube-ag-grid-table-type',
                className: 'ag-theme-balham',
                templateOptions: {
                  hasModelData: true,
                  height: '100%',
                  width: '100%',
                  gridOptions: {
                    columnDefs: [
                      {
                        headerName: TunnelConstants.partNo,
                        headerClass: 'header-no-child',
                        field: TunnelConstants.partNo,
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 150,
                      },
                      {
                        headerName: 'Action',
                        headerClass: 'header-no-child',
                        field: 'action',
                        cellStyle: { textAlign: 'center' },
                        cellRenderer: actionButtonsRendererComponent,
                        cellRendererParams: {
                          isViewMode:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          isDeleteVisible: true,
                          isDuplicateVisible: true,
                          onEdit: (index) => {
                            tunnelInputTemplateComponent.model.activeLevel =
                              TunnelConstants.levels.defect2;
                            tunnelInputTemplateComponent.migrateDataToChildLevel(
                              index,
                              TunnelConstants.levels.defect2,
                              tunnelInputTemplateComponent.model[
                                TunnelConstants.levels.l2
                              ][`${TunnelConstants.levels.defect2}list`]
                            );
                          },
                          onDuplicate: (index) => {
                            tunnelInputTemplateComponent.duplicateDefect(
                              index,
                              tunnelInputTemplateComponent.model[
                                TunnelConstants.levels.l2
                              ][`${TunnelConstants.levels.defect2}list`],
                              `${TunnelConstants.levels.defect2}list`
                            );
                          },
                          onDelete: async (index) => {
                            const isConfirmed =
                              await tunnelInputTemplateComponent.getConfirmation(
                                {
                                  confirmButtonText: 'Delete',
                                  message: 'Sei sicuro di eliminare la scheda?',
                                  title: 'Attenzione',
                                }
                              );

                            if (!isConfirmed) return;

                            // Deleting images of deleted element
                            let urlList = [];
                            tunnelInputTemplateComponent.model[
                              TunnelConstants.levels.l2
                            ][`${TunnelConstants.levels.defect2}list`][
                              index
                            ].modelstructure[
                              'Scheda Ispezione Ponti di Livello 1'
                            ][
                              'Scheda Ispezione Ponti di Livello 1 List1'
                            ].forEach((defect) => {
                              const fotoFields = {};

                              for (const key in defect['L']) {
                                if (
                                  defect['L'].hasOwnProperty(key) &&
                                  defect['L'][key]['N° foto']
                                ) {
                                  Object.assign(
                                    fotoFields,
                                    defect['L'][key]['N° foto']
                                  );
                                }
                              }

                              if (fotoFields) {
                                const imageUrls = Object.entries(fotoFields);
                                urlList = urlList.concat(imageUrls);
                              }
                            });

                            if (urlList.length)
                              tunnelInputTemplateComponent.deleteUploadedDefectFiles(
                                urlList
                              );

                            tunnelInputTemplateComponent.model[
                              TunnelConstants.levels.l2
                            ][`${TunnelConstants.levels.defect2}list`].splice(
                              index,
                              1
                            );
                            tunnelInputTemplateComponent.model[
                              TunnelConstants.levels.l2
                            ][`${TunnelConstants.levels.defect2}list`] =
                              [].concat(
                                tunnelInputTemplateComponent.model[
                                  TunnelConstants.levels.l2
                                ][`${TunnelConstants.levels.defect2}list`]
                              );
                            tunnelInputTemplateComponent.refreshFields();
                          },
                        },
                        flex: 1,
                        minWidth: 180,
                      },
                    ],
                  },
                },
                fieldArray: {
                  fieldGroup: [
                    {
                      key: TunnelConstants.partNo,
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: TunnelConstants.partNo,
                        readonly: true,
                      },
                    },
                  ],
                },
              },
              {
                // Add New Defect 2
                type: 'cube-button-type',
                className: 'remove-underline',
                templateOptions: {
                  text: 'Add New',
                  color: 'primary',
                  disabled: tunnelInputTemplateComponent.isFieldDisabled(
                    [],
                    isDisabled
                  ),
                  onClick: (e) => {
                    tunnelInputTemplateComponent.model[
                      TunnelConstants.levels.defect2
                    ]['Scheda Ispezione Ponti di Livello 1'][
                      TunnelConstants.partNo
                    ] =
                      tunnelInputTemplateComponent.model[
                        TunnelConstants.levels.l2
                      ][TunnelConstants.partNo];

                    tunnelInputTemplateComponent.model[
                      TunnelConstants.levels.defect2
                    ]['Scheda Ispezione Ponti di Livello 1']['uniquecode'] =
                      '2.' +
                      tunnelInputTemplateComponent.model[
                        TunnelConstants.levels.l2
                      ][TunnelConstants.partNo];

                    tunnelInputTemplateComponent.model[
                      TunnelConstants.levels.defect2
                    ]['Scheda Ispezione Ponti di Livello 1']['Codice IOP'] =
                      tunnelInputTemplateComponent.model[
                        TunnelConstants.levels.l0
                      ]['Codice IOP'];

                    tunnelInputTemplateComponent.model.activeLevel =
                      TunnelConstants.levels.defect2;
                  },
                },
              },
            ],
          },
          {
            // defect3
            templateOptions: { label: '3 - Difetti relativi alla struttura' },
            fieldGroup: [
              {
                key: `${TunnelConstants.levels.defect3}list`,
                type: 'cube-ag-grid-table-type',
                className: 'ag-theme-balham',
                templateOptions: {
                  hasModelData: true,
                  height: '100%',
                  width: '100%',
                  gridOptions: {
                    columnDefs: [
                      {
                        headerName: TunnelConstants.partNo,
                        headerClass: 'header-no-child',
                        field: TunnelConstants.partNo,
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 150,
                      },
                      {
                        headerName: 'Action',
                        headerClass: 'header-no-child',
                        field: 'action',
                        cellStyle: { textAlign: 'center' },
                        cellRenderer: actionButtonsRendererComponent,
                        cellRendererParams: {
                          isViewMode:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          isDeleteVisible: true,
                          isDuplicateVisible: true,
                          onEdit: (index) => {
                            tunnelInputTemplateComponent.model.activeLevel =
                              TunnelConstants.levels.defect3;
                            tunnelInputTemplateComponent.migrateDataToChildLevel(
                              index,
                              TunnelConstants.levels.defect3,
                              tunnelInputTemplateComponent.model[
                                TunnelConstants.levels.l2
                              ][`${TunnelConstants.levels.defect3}list`]
                            );
                          },
                          onDuplicate: (index) => {
                            tunnelInputTemplateComponent.duplicateDefect(
                              index,
                              tunnelInputTemplateComponent.model[
                                TunnelConstants.levels.l2
                              ][`${TunnelConstants.levels.defect3}list`],
                              `${TunnelConstants.levels.defect3}list`
                            );
                          },
                          onDelete: async (index) => {
                            const isConfirmed =
                              await tunnelInputTemplateComponent.getConfirmation(
                                {
                                  confirmButtonText: 'Delete',
                                  message: 'Sei sicuro di eliminare la scheda?',
                                  title: 'Attenzione',
                                }
                              );

                            if (!isConfirmed) return;

                            // Deleting images of deleted element
                            let urlList = [];
                            tunnelInputTemplateComponent.model[
                              TunnelConstants.levels.l2
                            ][`${TunnelConstants.levels.defect3}list`][
                              index
                            ].modelstructure[
                              'Scheda Ispezione Ponti di Livello 1'
                            ][
                              'Scheda Ispezione Ponti di Livello 1 List1'
                            ].forEach((defect) => {
                              const fotoFields = {};

                              for (const key in defect['L']) {
                                if (
                                  defect['L'].hasOwnProperty(key) &&
                                  defect['L'][key]['N° foto']
                                ) {
                                  Object.assign(
                                    fotoFields,
                                    defect['L'][key]['N° foto']
                                  );
                                }
                              }

                              if (fotoFields) {
                                const imageUrls = Object.entries(fotoFields);
                                urlList = urlList.concat(imageUrls);
                              }
                            });

                            if (urlList.length)
                              tunnelInputTemplateComponent.deleteUploadedDefectFiles(
                                urlList
                              );

                            tunnelInputTemplateComponent.model[
                              TunnelConstants.levels.l2
                            ][`${TunnelConstants.levels.defect3}list`].splice(
                              index,
                              1
                            );
                            tunnelInputTemplateComponent.model[
                              TunnelConstants.levels.l2
                            ][`${TunnelConstants.levels.defect3}list`] =
                              [].concat(
                                tunnelInputTemplateComponent.model[
                                  TunnelConstants.levels.l2
                                ][`${TunnelConstants.levels.defect3}list`]
                              );
                            tunnelInputTemplateComponent.refreshFields();
                          },
                        },
                        flex: 1,
                        minWidth: 180,
                      },
                    ],
                  },
                },
                fieldArray: {
                  fieldGroup: [
                    {
                      key: TunnelConstants.partNo,
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: TunnelConstants.partNo,
                        readonly: true,
                      },
                    },
                  ],
                },
              },
              {
                // Add New Defect 3
                type: 'cube-button-type',
                className: 'remove-underline',
                templateOptions: {
                  text: 'Add New',
                  color: 'primary',
                  disabled: tunnelInputTemplateComponent.isFieldDisabled(
                    [],
                    isDisabled
                  ),
                  onClick: (e) => {
                    tunnelInputTemplateComponent.model[
                      TunnelConstants.levels.defect3
                    ]['Scheda Ispezione Ponti di Livello 1'][
                      TunnelConstants.partNo
                    ] =
                      tunnelInputTemplateComponent.model[
                        TunnelConstants.levels.l2
                      ][TunnelConstants.partNo];

                    tunnelInputTemplateComponent.model[
                      TunnelConstants.levels.defect3
                    ]['Scheda Ispezione Ponti di Livello 1']['uniquecode'] =
                      '3.' +
                      tunnelInputTemplateComponent.model[
                        TunnelConstants.levels.l2
                      ][TunnelConstants.partNo];

                    tunnelInputTemplateComponent.model[
                      TunnelConstants.levels.defect3
                    ]['Scheda Ispezione Ponti di Livello 1']['Codice IOP'] =
                      tunnelInputTemplateComponent.model[
                        TunnelConstants.levels.l0
                      ]['Codice IOP'];

                    tunnelInputTemplateComponent.model.activeLevel =
                      TunnelConstants.levels.defect3;
                  },
                },
              },
            ],
          },
          {
            // defect4
            templateOptions: {
              label: 'Difetti relativi alla piattaforma stradale/autostradale',
            },
            fieldGroup: [
              {
                key: `${TunnelConstants.levels.defect4}list`,
                type: 'cube-ag-grid-table-type',
                className: 'ag-theme-balham',
                templateOptions: {
                  hasModelData: true,
                  height: '100%',
                  width: '100%',
                  gridOptions: {
                    columnDefs: [
                      {
                        headerName: TunnelConstants.partNo,
                        headerClass: 'header-no-child',
                        field: TunnelConstants.partNo,
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 150,
                      },
                      {
                        headerName: 'Action',
                        headerClass: 'header-no-child',
                        field: 'action',
                        cellStyle: { textAlign: 'center' },
                        cellRenderer: actionButtonsRendererComponent,
                        cellRendererParams: {
                          isViewMode:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          isDeleteVisible: true,
                          isDuplicateVisible: true,
                          onEdit: (index) => {
                            tunnelInputTemplateComponent.model.activeLevel =
                              TunnelConstants.levels.defect4;
                            tunnelInputTemplateComponent.migrateDataToChildLevel(
                              index,
                              TunnelConstants.levels.defect4,
                              tunnelInputTemplateComponent.model[
                                TunnelConstants.levels.l2
                              ][`${TunnelConstants.levels.defect4}list`]
                            );
                          },
                          onDuplicate: (index) => {
                            tunnelInputTemplateComponent.duplicateDefect(
                              index,
                              tunnelInputTemplateComponent.model[
                                TunnelConstants.levels.l2
                              ][`${TunnelConstants.levels.defect4}list`],
                              `${TunnelConstants.levels.defect4}list`
                            );
                          },
                          onDelete: async (index) => {
                            const isConfirmed =
                              await tunnelInputTemplateComponent.getConfirmation(
                                {
                                  confirmButtonText: 'Delete',
                                  message: 'Sei sicuro di eliminare la scheda?',
                                  title: 'Attenzione',
                                }
                              );

                            if (!isConfirmed) return;

                            // Deleting images of deleted element
                            let urlList = [];
                            tunnelInputTemplateComponent.model[
                              TunnelConstants.levels.l2
                            ][`${TunnelConstants.levels.defect4}list`][
                              index
                            ].modelstructure[
                              'Scheda Ispezione Ponti di Livello 1'
                            ][
                              'Scheda Ispezione Ponti di Livello 1 List1'
                            ].forEach((defect) => {
                              const fotoFields = {};

                              for (const key in defect['L']) {
                                if (
                                  defect['L'].hasOwnProperty(key) &&
                                  defect['L'][key]['N° foto']
                                ) {
                                  Object.assign(
                                    fotoFields,
                                    defect['L'][key]['N° foto']
                                  );
                                }
                              }

                              if (fotoFields) {
                                const imageUrls = Object.entries(fotoFields);
                                urlList = urlList.concat(imageUrls);
                              }
                            });

                            if (urlList.length)
                              tunnelInputTemplateComponent.deleteUploadedDefectFiles(
                                urlList
                              );

                            tunnelInputTemplateComponent.model[
                              TunnelConstants.levels.l2
                            ][`${TunnelConstants.levels.defect4}list`].splice(
                              index,
                              1
                            );
                            tunnelInputTemplateComponent.model[
                              TunnelConstants.levels.l2
                            ][`${TunnelConstants.levels.defect4}list`] =
                              [].concat(
                                tunnelInputTemplateComponent.model[
                                  TunnelConstants.levels.l2
                                ][`${TunnelConstants.levels.defect4}list`]
                              );
                            tunnelInputTemplateComponent.refreshFields();
                          },
                        },
                        flex: 1,
                        minWidth: 180,
                      },
                    ],
                  },
                },
                fieldArray: {
                  fieldGroup: [
                    {
                      key: TunnelConstants.partNo,
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: TunnelConstants.partNo,
                        readonly: true,
                      },
                    },
                  ],
                },
              },
              {
                // Add New Defect 4
                type: 'cube-button-type',
                className: 'remove-underline',
                templateOptions: {
                  text: 'Add New',
                  color: 'primary',
                  disabled: tunnelInputTemplateComponent.isFieldDisabled(
                    [],
                    isDisabled
                  ),
                  onClick: (e) => {
                    tunnelInputTemplateComponent.model[
                      TunnelConstants.levels.defect4
                    ]['Scheda Ispezione Ponti di Livello 1'][
                      TunnelConstants.partNo
                    ] =
                      tunnelInputTemplateComponent.model[
                        TunnelConstants.levels.l2
                      ][TunnelConstants.partNo];

                    tunnelInputTemplateComponent.model[
                      TunnelConstants.levels.defect4
                    ]['Scheda Ispezione Ponti di Livello 1']['uniquecode'] =
                      '4.' +
                      tunnelInputTemplateComponent.model[
                        TunnelConstants.levels.l2
                      ][TunnelConstants.partNo];

                    tunnelInputTemplateComponent.model[
                      TunnelConstants.levels.defect4
                    ]['Scheda Ispezione Ponti di Livello 1']['Codice IOP'] =
                      tunnelInputTemplateComponent.model[
                        TunnelConstants.levels.l0
                      ]['Codice IOP'];

                    tunnelInputTemplateComponent.model.activeLevel =
                      TunnelConstants.levels.defect4;
                  },
                },
              },
            ],
          },
          {
            // defect5
            templateOptions: {
              label:
                'Difetti relativi agli elementi non strutturali e impianti',
            },
            fieldGroup: [
              {
                key: `${TunnelConstants.levels.defect5}list`,
                type: 'cube-ag-grid-table-type',
                className: 'ag-theme-balham',
                templateOptions: {
                  hasModelData: true,
                  height: '100%',
                  width: '100%',
                  gridOptions: {
                    columnDefs: [
                      {
                        headerName: TunnelConstants.partNo,
                        headerClass: 'header-no-child',
                        field: TunnelConstants.partNo,
                        cellRenderer: textOnlyRendererComponent,
                        cellStyle: { textAlign: 'center' },
                        flex: 1,
                        minWidth: 150,
                      },
                      {
                        headerName: 'Action',
                        headerClass: 'header-no-child',
                        field: 'action',
                        cellStyle: { textAlign: 'center' },
                        cellRenderer: actionButtonsRendererComponent,
                        cellRendererParams: {
                          isViewMode:
                            tunnelInputTemplateComponent.isFieldDisabled(
                              [],
                              isDisabled
                            ),
                          isDeleteVisible: true,
                          isDuplicateVisible: true,
                          onEdit: (index) => {
                            tunnelInputTemplateComponent.model.activeLevel =
                              TunnelConstants.levels.defect5;
                            tunnelInputTemplateComponent.migrateDataToChildLevel(
                              index,
                              TunnelConstants.levels.defect5,
                              tunnelInputTemplateComponent.model[
                                TunnelConstants.levels.l2
                              ][`${TunnelConstants.levels.defect5}list`]
                            );
                          },
                          onDuplicate: (index) => {
                            tunnelInputTemplateComponent.duplicateDefect(
                              index,
                              tunnelInputTemplateComponent.model[
                                TunnelConstants.levels.l2
                              ][`${TunnelConstants.levels.defect5}list`],
                              `${TunnelConstants.levels.defect5}list`
                            );
                          },
                          onDelete: async (index) => {
                            const isConfirmed =
                              await tunnelInputTemplateComponent.getConfirmation(
                                {
                                  confirmButtonText: 'Delete',
                                  message: 'Sei sicuro di eliminare la scheda?',
                                  title: 'Attenzione',
                                }
                              );

                            if (!isConfirmed) return;

                            // Deleting images of deleted element
                            let urlList = [];
                            tunnelInputTemplateComponent.model[
                              TunnelConstants.levels.l2
                            ][`${TunnelConstants.levels.defect5}list`][
                              index
                            ].modelstructure[
                              'Scheda Ispezione Ponti di Livello 1'
                            ][
                              'Scheda Ispezione Ponti di Livello 1 List1'
                            ].forEach((defect) => {
                              const fotoFields = {};

                              for (const key in defect['L']) {
                                if (
                                  defect['L'].hasOwnProperty(key) &&
                                  defect['L'][key]['N° foto']
                                ) {
                                  Object.assign(
                                    fotoFields,
                                    defect['L'][key]['N° foto']
                                  );
                                }
                              }

                              if (fotoFields) {
                                const imageUrls = Object.entries(fotoFields);
                                urlList = urlList.concat(imageUrls);
                              }
                            });

                            if (urlList.length)
                              tunnelInputTemplateComponent.deleteUploadedDefectFiles(
                                urlList
                              );

                            tunnelInputTemplateComponent.model[
                              TunnelConstants.levels.l2
                            ][`${TunnelConstants.levels.defect5}list`].splice(
                              index,
                              1
                            );
                            tunnelInputTemplateComponent.model[
                              TunnelConstants.levels.l2
                            ][`${TunnelConstants.levels.defect5}list`] =
                              [].concat(
                                tunnelInputTemplateComponent.model[
                                  TunnelConstants.levels.l2
                                ][`${TunnelConstants.levels.defect5}list`]
                              );
                            tunnelInputTemplateComponent.refreshFields();
                          },
                        },
                        flex: 1,
                        minWidth: 180,
                      },
                    ],
                  },
                },
                fieldArray: {
                  fieldGroup: [
                    {
                      key: TunnelConstants.partNo,
                      type: 'input',
                      defaultValue: '',
                      templateOptions: {
                        label: TunnelConstants.partNo,
                        readonly: true,
                      },
                    },
                  ],
                },
              },
              {
                // Add New Defect 5
                type: 'cube-button-type',
                className: 'remove-underline',
                templateOptions: {
                  text: 'Add New',
                  color: 'primary',
                  disabled: tunnelInputTemplateComponent.isFieldDisabled(
                    [],
                    isDisabled
                  ),
                  onClick: (e) => {
                    tunnelInputTemplateComponent.model[
                      TunnelConstants.levels.defect5
                    ]['Scheda Ispezione Ponti di Livello 1'][
                      TunnelConstants.partNo
                    ] =
                      tunnelInputTemplateComponent.model[
                        TunnelConstants.levels.l2
                      ][TunnelConstants.partNo];

                    tunnelInputTemplateComponent.model[
                      TunnelConstants.levels.defect5
                    ]['Scheda Ispezione Ponti di Livello 1']['uniquecode'] =
                      '5.' +
                      tunnelInputTemplateComponent.model[
                        TunnelConstants.levels.l2
                      ][TunnelConstants.partNo];

                    tunnelInputTemplateComponent.model[
                      TunnelConstants.levels.defect5
                    ]['Scheda Ispezione Ponti di Livello 1']['Codice IOP'] =
                      tunnelInputTemplateComponent.model[
                        TunnelConstants.levels.l0
                      ]['Codice IOP'];

                    tunnelInputTemplateComponent.model.activeLevel =
                      TunnelConstants.levels.defect5;
                  },
                },
              },
            ],
          },
        ],
      },
      {
        // Level: Defect Template 1
        key: TunnelConstants.levels.defect1,
        resetOnHide: true,
        type: 'cube-tabs-type',
        templateOptions: {
          devMode: tunnelInputTemplateComponent.devMode, // TEMP: For development
        },
        hideExpression: () =>
          tunnelInputTemplateComponent.model.activeLevel !==
          TunnelConstants.levels.defect1,
        fieldGroup: [
          {
            templateOptions: { label: 'Difetti relativi alla struttura' },
            fieldGroup: [
              {
                hooks: {
                  onInit: (field) => {
                    tunnelInputTemplateComponent.getDefectsList(
                      TunnelConstants.levels.defect1
                    );
                  },
                },
                fieldGroup: [
                  {
                    key: 'Scheda Ispezione Ponti di Livello 1',
                    fieldGroup: [
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Scheda Ispezione Gallerie di Livello 1',
                        },
                      },
                      {
                        fieldGroupClassName:
                          'display-flex defect-header-input-color grid-gap-extra',
                        fieldGroup: [
                          {
                            className: 'flex-5',
                            fieldGroup: [
                              {
                                fieldGroupClassName:
                                  'display-flex grid-gap repeat-header',
                                className: 'flex-2',
                                fieldGroup: [
                                  {
                                    className: 'unique-code-sec',
                                    key: 'uniquecode',
                                    type: 'cube-unique-code-type',
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'Nome galleria',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Nome galleria',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'Strada di appartenenza',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Strada di appartenenza',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'Progressiva km',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Progressiva km',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName: 'display-flex',
                                fieldGroup: [
                                  {
                                    className: 'flex-2',
                                    fieldGroup: [
                                      {
                                        key: 'InspectionDate',
                                        type: 'cube-repeat-section-type',
                                        templateOptions: {
                                          disabled:
                                            tunnelInputTemplateComponent.isFieldDisabled(
                                              [],
                                              isDisabled
                                            ),
                                        },
                                        hooks: {
                                          onInit: () => {
                                            if (
                                              !tunnelInputTemplateComponent.defectUpdateStatus
                                            ) {
                                              tunnelInputTemplateComponent.model[
                                                TunnelConstants.levels.defect1
                                              ][
                                                'Scheda Ispezione Ponti di Livello 1'
                                              ].InspectionDate = tunnelInputTemplateComponent.getDeepCopy(
                                                tunnelInputTemplateComponent
                                                  .model[
                                                  TunnelConstants.levels.l0
                                                ]['Inspection date']
                                              );
                                              tunnelInputTemplateComponent.refreshFields();
                                            }
                                          },
                                        },
                                        fieldArray: {
                                          fieldGroupClassName:
                                            'display-flex grid-gap',
                                          fieldGroup: [
                                            {
                                              className: 'flex-1',
                                              key: 'Tecnico Rilevatore',
                                              type: 'input',
                                              defaultValue: '',
                                              templateOptions: {
                                                disabled:
                                                  tunnelInputTemplateComponent.isFieldDisabled(
                                                    [],
                                                    isDisabled
                                                  ),
                                              },
                                              expressionProperties: {
                                                'templateOptions.label':
                                                  "'Tecnico Rilevatore n.' + (+field.parent.key + 1)",
                                              },
                                            },
                                            {
                                              className: 'flex-1',
                                              key: 'Data Ispezione',
                                              type: 'cube-datepicker-type',
                                              defaultValue: '',
                                              templateOptions: {
                                                readonly: true,
                                                disabled:
                                                  tunnelInputTemplateComponent.isFieldDisabled(
                                                    [],
                                                    isDisabled
                                                  ),
                                                max: new Date(),
                                              },
                                              expressionProperties: {
                                                'templateOptions.label':
                                                  "'Data Ispezione n.' + (+field.parent.key + 1)",
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        fieldGroupClassName:
                                          'display-flex grid-gap align-items-center repeat-header',
                                        fieldGroup: [
                                          {
                                            key: 'Sup Elem',
                                            type: 'input',
                                            defaultValue: '',
                                            className: 'flex-1',
                                            templateOptions: {
                                              label: 'Sup. Elem.',
                                              disabled:
                                                tunnelInputTemplateComponent.isFieldDisabled(
                                                  [],
                                                  isDisabled
                                                ),
                                            },
                                          },
                                          {
                                            key: 'Codice IOP',
                                            type: 'cube-text-only-type',
                                            className: 'font-bold flex-1',
                                            templateOptions: {
                                              pre: 'Codice IOP: ',
                                            },
                                          },
                                          {
                                            className: 'font-bold',
                                            key: TunnelConstants.partNo,
                                            type: 'cube-text-only-type',
                                            templateOptions: {
                                              pre: 'Concio N.: ',
                                              post: 'Y',
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            className: 'defect-header-input-color',
                            fieldGroup: [
                              {
                                className: 'page-heading-image-only',
                                template: `<div class="defect-rina-logo">
                                      <img src="assets/rina/RINA-dark.png">
                                  </div>`,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Scheda Ispezione Ponti di Livello 1 List1',
                        type: 'cube-ag-grid-table-type',
                        className: 'ag-theme-balham',
                        templateOptions: {
                          hasModelData: true,
                          height: '100%',
                          width: '100%',
                          gridOptions: {
                            defaultColDef: {
                              cellClass: [
                                'align-items-center',
                                'display-flex',
                                'justify-content-center',
                              ],
                            },
                            columnDefs: [
                              {
                                headerName: 'Codice difetto',
                                headerClass: 'header-no-child',
                                field: 'Codice difetto',
                                width: 100,
                                autoHeight: true,
                                colSpan: (params) => {
                                  if (
                                    params.data['Codice difetto'] ===
                                      TunnelConstants.eventualiNote ||
                                    params.data.sectionHeader
                                  ) {
                                    // have all Eventuali note and header columns width 2
                                    return 2;
                                  } else {
                                    return 1;
                                  }
                                },
                                cellClassRules: {
                                  'cell-pass': (params) => params.data.G >= 5,
                                  'cell-fail': (params) => params.data.G < 5,
                                  'section-header': (params) =>
                                    params.data.sectionHeader,
                                  'cell-wrapping': (params) =>
                                    params.data.sectionHeader,
                                },
                              },
                              {
                                headerName: 'Descrizione difetto',
                                headerClass: 'header-no-child',
                                field: 'Descrizione difetto',
                                minWidth: 200,
                                flex: 2,
                                autoHeight: true,
                                cellClass: [
                                  'cell-wrapping',
                                  'text-align-center',
                                ],
                                cellClassRules: {
                                  'cell-pass': (params) => params.data.G >= 5,
                                  'cell-fail': (params) => params.data.G < 5,
                                },
                              },
                              {
                                headerName: `A cura dell'ispettore`,
                                headerClass: 'border-bottom',
                                children: [
                                  {
                                    headerName: 'Estensione K1',
                                    headerClass: 'remove-border-top',
                                    children: [
                                      {
                                        headerClass: 'custom-radio-table',
                                        headerComponent:
                                          defectTableHeaderRendererComponent,
                                        headerComponentParams: {
                                          title: ['0.2', '0.5', '1'],
                                          sortable: false,
                                        },
                                        field: 'Estensione K1',
                                        cellRendererSelector: (params) => {
                                          if (
                                            params.data['Codice difetto'] ===
                                              TunnelConstants.eventualiNote ||
                                            params.data.sectionHeader
                                          )
                                            return null;

                                          return {
                                            component:
                                              defectTableRadioButtonsRendererComponent,
                                            params: {},
                                          };
                                        },
                                        disabled: (params) =>
                                          params.data['Codice difetto'] ===
                                          TunnelConstants.eventualiNote
                                            ? !isDisabled
                                            : isDisabled,
                                        editable: (params) =>
                                          params.data['Codice difetto'] ===
                                          TunnelConstants.eventualiNote
                                            ? !isDisabled
                                            : isDisabled,
                                        width: 140,
                                        colSpan: (params) => {
                                          if (
                                            params.data['Codice difetto'] ===
                                              TunnelConstants.eventualiNote ||
                                            params.data.sectionHeader
                                          ) {
                                            return 17;
                                          }
                                          return 1;
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    headerName: 'Intensità K2',
                                    headerClass: 'remove-border-top',
                                    children: [
                                      {
                                        headerClass: 'custom-radio-table',
                                        headerComponent:
                                          defectTableHeaderRendererComponent,
                                        headerComponentParams: {
                                          title: ['0.2', '0.5', '1'],
                                          sortable: false,
                                        },
                                        field: 'Intensità K2',
                                        cellRenderer:
                                          defectTableRadioButtonsRendererComponent,
                                        disabled:
                                          tunnelInputTemplateComponent.isFieldDisabled(
                                            [],
                                            isDisabled
                                          ),
                                        width: 140,
                                      },
                                    ],
                                  },
                                  {
                                    headerName: 'N° foto',
                                    headerClass: 'header-no-child',
                                    field: 'N° foto',
                                    cellRenderer:
                                      defectTableLocalizzazioneRendererComponent,
                                    cellRendererParams: {
                                      acceptedFileTypes:
                                        environment.file_upload
                                          .accepted_file_types.images,
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                    width: 70,
                                    modelData:
                                      tunnelInputTemplateComponent.model,
                                    token: tunnelInputTemplateComponent.token,
                                    componentType:
                                      TunnelConstants.levels.defect1,
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                  {
                                    headerName: 'NA',
                                    headerClass: 'header-no-child',
                                    field: 'NA',
                                    width: 40,
                                    cellRenderer:
                                      'defectTableCheckboxRendererComponent',
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                    onCellValueChanged: (params) => {
                                      if (
                                        params.oldValue !== params.newValue &&
                                        (params.data['Intensità K2'] != null ||
                                          params.data['Estensione K1'] != null)
                                      ) {
                                        params.data.NA = false;
                                        params.api.refreshCells({
                                          rowNodes: [params.node],
                                          columns: [params.column],
                                        });
                                        tunnelInputTemplateComponent.getConfirmation(
                                          {
                                            isCancelButtonVisible: false,
                                            message:
                                              'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                            title: 'Info',
                                          }
                                        );
                                      }
                                      params.data.NR = false;
                                      params.data.NP = false;
                                      params.api.refreshCells({
                                        rowNodes: [params.node],
                                      });
                                    },
                                  },
                                  {
                                    headerName: 'NR',
                                    headerClass: 'header-no-child',
                                    field: 'NR',
                                    width: 40,
                                    cellRenderer:
                                      'defectTableCheckboxRendererComponent',
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                    onCellValueChanged: (params) => {
                                      if (
                                        params.oldValue !== params.newValue &&
                                        (params.data['Intensità K2'] != null ||
                                          params.data['Estensione K1'] != null)
                                      ) {
                                        params.data.NR = false;
                                        params.api.refreshCells({
                                          rowNodes: [params.node],
                                          columns: [params.column],
                                        });
                                        tunnelInputTemplateComponent.getConfirmation(
                                          {
                                            isCancelButtonVisible: false,
                                            message:
                                              'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                            title: 'Info',
                                          }
                                        );
                                      }
                                      params.data.NA = false;
                                      params.data.NP = false;
                                      params.api.refreshCells({
                                        rowNodes: [params.node],
                                      });
                                    },
                                  },
                                  {
                                    headerName: 'NP',
                                    headerClass: 'header-no-child',
                                    field: 'NP',
                                    width: 40,
                                    cellRenderer:
                                      'defectTableCheckboxRendererComponent',
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                    onCellValueChanged: (params) => {
                                      if (
                                        params.oldValue !== params.newValue &&
                                        (params.data['Intensità K2'] != null ||
                                          params.data['Estensione K1'] != null)
                                      ) {
                                        params.data.NP = false;
                                        params.api.refreshCells({
                                          rowNodes: [params.node],
                                          columns: [params.column],
                                        });
                                        tunnelInputTemplateComponent.getConfirmation(
                                          {
                                            isCancelButtonVisible: false,
                                            message:
                                              'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                            title: 'Info',
                                          }
                                        );
                                      }
                                      params.data.NA = false;
                                      params.data.NR = false;
                                      params.api.refreshCells({
                                        rowNodes: [params.node],
                                      });
                                    },
                                  },
                                  {
                                    headerName: 'L',
                                    headerClass: 'header-no-child',
                                    field: 'L',
                                    width: 70,
                                    cellRenderer:
                                      defectTableLocalizzazioneRendererComponent,
                                    cellRendererParams: {
                                      acceptedFileTypes:
                                        environment.file_upload
                                          .accepted_file_types.images,
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                    modelData:
                                      tunnelInputTemplateComponent.model,
                                    token: tunnelInputTemplateComponent.token,
                                    componentType:
                                      TunnelConstants.levels.defect1,
                                    cellClass: [
                                      'cell-wrapping',
                                      'cursor-pointer',
                                    ],
                                  },
                                  {
                                    headerName: 'Sup. Est. Dif. [m^2]',
                                    headerClass: 'header-no-child',
                                    field: 'Sup Est Dif',
                                    minWidth: 100,
                                    flex: 1,
                                    cellClass: ['remove-lines'],
                                    editable: !isDisabled,
                                  },
                                ],
                              },
                              {
                                headerName: 'Giudizio esperto',
                                headerClass: 'border-bottom',
                                children: [
                                  {
                                    headerName: '',
                                    headerClass: 'remove-border-top',
                                    children: [
                                      {
                                        headerName: 'G',
                                        headerClass: 'header-no-child',
                                        field: 'G',
                                        width: 40,
                                        editable:
                                          tunnelInputTemplateComponent.isFieldDisabled(
                                            [UserRole.auditor],
                                            tunnelInputTemplateComponent.model
                                              .status ===
                                              InspectionStatus.toReview
                                          ),
                                      },
                                    ],
                                  },
                                  {
                                    headerName:
                                      'Correlazione con altri difetti',
                                    headerClass: 'header-no-child',
                                    field: 'Correlazione con altri difetti',
                                    minWidth: 100,
                                    flex: 1,
                                    cellClass: ['remove-lines'],
                                    editable:
                                      userRole === UserRole.auditor &&
                                      tunnelInputTemplateComponent.model
                                        .status === InspectionStatus.toReview,
                                  },
                                  {
                                    headerName: 'PS g',
                                    headerClass: 'header-no-child',
                                    field: 'PS g',
                                    cellRenderer:
                                      'defectTableCheckboxRendererComponent',
                                    disabled:
                                      userRole !== UserRole.auditor ||
                                      tunnelInputTemplateComponent.model
                                        .status !== InspectionStatus.toReview,
                                    width: 40,
                                  },
                                  {
                                    headerName: 'PS I',
                                    headerClass: 'header-no-child',
                                    field: 'PS I',
                                    cellRenderer:
                                      'defectTableCheckboxRendererComponent',
                                    disabled:
                                      userRole !== UserRole.auditor ||
                                      tunnelInputTemplateComponent.model
                                        .status !== InspectionStatus.toReview,
                                    width: 40,
                                  },
                                ],
                              },
                              {
                                headerName: 'Note',
                                headerClass: 'header-no-child',
                                field: 'Note',
                                minWidth: 100,
                                autoHeight: true,
                                flex: 1,
                                cellClass: ['remove-lines'],
                                cellRenderer: defectTableNoteRendererComponent,
                                cellRendererParams: {
                                  mode: DefectNoteMode.tunnelDefect,
                                },
                                disabled:
                                  tunnelInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                              },
                            ],
                            components: {
                              defectTableCheckboxRendererComponent,
                              defectTableNoteRendererComponent,
                            },
                          },
                        },
                        fieldArray: {
                          fieldGroup: [
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Codice difetto',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Descrizione difetto',
                            },
                            {
                              fieldGroup: [
                                {
                                  fieldGroup: [
                                    {
                                      key: 'Estensione K1',
                                      type: 'input',
                                      defaultValue: '',
                                      templateOptions: {
                                        disabled: true,
                                      },
                                    },
                                  ],
                                },
                                {
                                  fieldGroup: [
                                    {
                                      type: 'input',
                                      defaultValue: '',
                                      key: 'Intensità K2',
                                      templateOptions: {
                                        disabled: true,
                                      },
                                    },
                                  ],
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'N° foto',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'NA',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'NR',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'NP',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'L',
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'Sup Est Dif',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                              ],
                            },
                            {
                              fieldGroup: [
                                {
                                  type: 'cube-text-only-type',
                                  defaultValue: '',
                                  key: 'G',
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'Correlazione con altri difetti',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'PS g',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'PS I',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                              ],
                            },
                            {
                              type: 'textarea',
                              defaultValue: '',
                              key: 'Note',
                              templateOptions: {
                                disabled:
                                  tunnelInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
                hideExpression: `formState.${TunnelConstants.levels.defect1}`,
              },
            ],
          },
        ],
      },
      {
        // Level: Defect Template 2
        key: TunnelConstants.levels.defect2,
        resetOnHide: true,
        type: 'cube-tabs-type',
        templateOptions: {
          devMode: tunnelInputTemplateComponent.devMode, // TEMP: For development
        },
        hideExpression: () =>
          tunnelInputTemplateComponent.model.activeLevel !==
          TunnelConstants.levels.defect2,
        fieldGroup: [
          {
            templateOptions: { label: 'Difetti relativi alla struttura' },
            fieldGroup: [
              {
                hooks: {
                  onInit: (field) => {
                    tunnelInputTemplateComponent.getDefectsList(
                      TunnelConstants.levels.defect2
                    );
                  },
                },
                fieldGroup: [
                  {
                    key: 'Scheda Ispezione Ponti di Livello 1',
                    fieldGroup: [
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Scheda Ispezione Gallerie di Livello 1',
                        },
                      },
                      {
                        fieldGroupClassName:
                          'display-flex defect-header-input-color grid-gap-extra',
                        fieldGroup: [
                          {
                            className: 'flex-5',
                            fieldGroup: [
                              {
                                fieldGroupClassName:
                                  'display-flex grid-gap repeat-header',
                                className: 'flex-2',
                                fieldGroup: [
                                  {
                                    className: 'unique-code-sec',
                                    key: 'uniquecode',
                                    type: 'cube-unique-code-type',
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'Nome galleria',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Nome galleria',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'Strada di appartenenza',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Strada di appartenenza',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'Progressiva km',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Progressiva km',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName: 'display-flex',
                                fieldGroup: [
                                  {
                                    className: 'flex-2',
                                    fieldGroup: [
                                      {
                                        key: 'InspectionDate',
                                        type: 'cube-repeat-section-type',
                                        templateOptions: {
                                          disabled:
                                            tunnelInputTemplateComponent.isFieldDisabled(
                                              [],
                                              isDisabled
                                            ),
                                        },
                                        hooks: {
                                          onInit: () => {
                                            if (
                                              !tunnelInputTemplateComponent.defectUpdateStatus
                                            ) {
                                              tunnelInputTemplateComponent.model[
                                                TunnelConstants.levels.defect2
                                              ][
                                                'Scheda Ispezione Ponti di Livello 1'
                                              ].InspectionDate = tunnelInputTemplateComponent.getDeepCopy(
                                                tunnelInputTemplateComponent
                                                  .model[
                                                  TunnelConstants.levels.l0
                                                ]['Inspection date']
                                              );
                                              tunnelInputTemplateComponent.refreshFields();
                                            }
                                          },
                                        },
                                        fieldArray: {
                                          fieldGroupClassName:
                                            'display-flex grid-gap',
                                          fieldGroup: [
                                            {
                                              className: 'flex-1',
                                              key: 'Tecnico Rilevatore',
                                              type: 'input',
                                              defaultValue: '',
                                              templateOptions: {
                                                disabled:
                                                  tunnelInputTemplateComponent.isFieldDisabled(
                                                    [],
                                                    isDisabled
                                                  ),
                                              },
                                              expressionProperties: {
                                                'templateOptions.label':
                                                  "'Tecnico Rilevatore n.' + (+field.parent.key + 1)",
                                              },
                                            },
                                            {
                                              className: 'flex-1',
                                              key: 'Data Ispezione',
                                              type: 'cube-datepicker-type',
                                              defaultValue: '',
                                              templateOptions: {
                                                readonly: true,
                                                disabled:
                                                  tunnelInputTemplateComponent.isFieldDisabled(
                                                    [],
                                                    isDisabled
                                                  ),
                                                max: new Date(),
                                              },
                                              expressionProperties: {
                                                'templateOptions.label':
                                                  "'Data Ispezione n.' + (+field.parent.key + 1)",
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        fieldGroupClassName:
                                          'display-flex grid-gap align-items-center repeat-header',
                                        fieldGroup: [
                                          {
                                            key: 'Sup Elem',
                                            type: 'input',
                                            defaultValue: '',
                                            className: 'flex-1',
                                            templateOptions: {
                                              label: 'Sup. Elem.',
                                              disabled:
                                                tunnelInputTemplateComponent.isFieldDisabled(
                                                  [],
                                                  isDisabled
                                                ),
                                            },
                                          },
                                          {
                                            key: 'Codice IOP',
                                            type: 'cube-text-only-type',
                                            className: 'font-bold flex-1',
                                            templateOptions: {
                                              pre: 'Codice IOP: ',
                                            },
                                          },
                                          {
                                            className: 'font-bold',
                                            key: TunnelConstants.partNo,
                                            type: 'cube-text-only-type',
                                            templateOptions: {
                                              pre: 'Concio N.: ',
                                              post: 'Y',
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            className: 'defect-header-input-color',
                            fieldGroup: [
                              {
                                className: 'page-heading-image-only',
                                template: `<div class="defect-rina-logo">
                                      <img src="assets/rina/RINA-dark.png">
                                  </div>`,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Scheda Ispezione Ponti di Livello 1 List1',
                        type: 'cube-ag-grid-table-type',
                        className: 'ag-theme-balham',
                        templateOptions: {
                          hasModelData: true,
                          height: '100%',
                          width: '100%',
                          gridOptions: {
                            defaultColDef: {
                              cellClass: [
                                'align-items-center',
                                'display-flex',
                                'justify-content-center',
                              ],
                            },
                            columnDefs: [
                              {
                                headerName: 'Codice difetto',
                                headerClass: 'header-no-child',
                                field: 'Codice difetto',
                                width: 100,
                                autoHeight: true,
                                colSpan: (params) => {
                                  if (
                                    params.data['Codice difetto'] ===
                                      TunnelConstants.eventualiNote ||
                                    params.data.sectionHeader
                                  ) {
                                    // have all Eventuali note and header columns width 2
                                    return 2;
                                  } else {
                                    return 1;
                                  }
                                },
                                cellClassRules: {
                                  'cell-pass': (params) => params.data.G >= 5,
                                  'cell-fail': (params) => params.data.G < 5,
                                  'section-header': (params) =>
                                    params.data.sectionHeader,
                                  'cell-wrapping': (params) =>
                                    params.data.sectionHeader,
                                },
                              },
                              {
                                headerName: 'Descrizione difetto',
                                headerClass: 'header-no-child',
                                field: 'Descrizione difetto',
                                minWidth: 200,
                                flex: 2,
                                autoHeight: true,
                                cellClass: [
                                  'cell-wrapping',
                                  'text-align-center',
                                ],
                                cellClassRules: {
                                  'cell-pass': (params) => params.data.G >= 5,
                                  'cell-fail': (params) => params.data.G < 5,
                                },
                              },
                              {
                                headerName: `A cura dell'ispettore`,
                                headerClass: 'border-bottom',
                                children: [
                                  {
                                    headerName: 'Estensione K1',
                                    headerClass: 'remove-border-top',
                                    children: [
                                      {
                                        headerClass: 'custom-radio-table',
                                        headerComponent:
                                          defectTableHeaderRendererComponent,
                                        headerComponentParams: {
                                          title: ['0.2', '0.5', '1'],
                                          sortable: false,
                                        },
                                        field: 'Estensione K1',
                                        cellRendererSelector: (params) => {
                                          if (
                                            params.data['Codice difetto'] ===
                                              TunnelConstants.eventualiNote ||
                                            params.data.sectionHeader
                                          ) {
                                            return null;
                                          }
                                          return {
                                            component:
                                              defectTableRadioButtonsRendererComponent,
                                            params: {},
                                          };
                                        },
                                        disabled: (params) =>
                                          params.data['Codice difetto'] ===
                                          TunnelConstants.eventualiNote
                                            ? !isDisabled
                                            : isDisabled,
                                        editable: (params) =>
                                          params.data['Codice difetto'] ===
                                          TunnelConstants.eventualiNote
                                            ? !isDisabled
                                            : isDisabled,
                                        width: 140,
                                        colSpan: (params) => {
                                          if (
                                            params.data['Codice difetto'] ===
                                              TunnelConstants.eventualiNote ||
                                            params.data.sectionHeader
                                          ) {
                                            return 17;
                                          }
                                          return 1;
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    headerName: 'Intensità K2',
                                    headerClass: 'remove-border-top',
                                    children: [
                                      {
                                        headerClass: 'custom-radio-table',
                                        headerComponent:
                                          defectTableHeaderRendererComponent,
                                        headerComponentParams: {
                                          title: ['0.2', '0.5', '1'],
                                          sortable: false,
                                        },
                                        field: 'Intensità K2',
                                        cellRenderer:
                                          defectTableRadioButtonsRendererComponent,
                                        disabled:
                                          tunnelInputTemplateComponent.isFieldDisabled(
                                            [],
                                            isDisabled
                                          ),
                                        width: 140,
                                      },
                                    ],
                                  },
                                  {
                                    headerName: 'N° foto',
                                    headerClass: 'header-no-child',
                                    field: 'N° foto',
                                    cellRenderer:
                                      defectTableLocalizzazioneRendererComponent,
                                    cellRendererParams: {
                                      acceptedFileTypes:
                                        environment.file_upload
                                          .accepted_file_types.images,
                                    },
                                    width: 70,
                                    modelData:
                                      tunnelInputTemplateComponent.model,
                                    token: tunnelInputTemplateComponent.token,
                                    componentType:
                                      TunnelConstants.levels.defect2,
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                  {
                                    headerName: 'NA',
                                    headerClass: 'header-no-child',
                                    field: 'NA',
                                    width: 40,
                                    cellRenderer:
                                      'defectTableCheckboxRendererComponent',
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                    onCellValueChanged: (params) => {
                                      if (
                                        params.oldValue !== params.newValue &&
                                        (params.data['Intensità K2'] != null ||
                                          params.data['Estensione K1'] != null)
                                      ) {
                                        params.data.NA = false;
                                        params.api.refreshCells({
                                          rowNodes: [params.node],
                                          columns: [params.column],
                                        });
                                        tunnelInputTemplateComponent.getConfirmation(
                                          {
                                            isCancelButtonVisible: false,
                                            message:
                                              'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                            title: 'Info',
                                          }
                                        );
                                      }
                                      params.data.NR = false;
                                      params.data.NP = false;
                                      params.api.refreshCells({
                                        rowNodes: [params.node],
                                      });
                                    },
                                  },
                                  {
                                    headerName: 'NR',
                                    headerClass: 'header-no-child',
                                    field: 'NR',
                                    width: 40,
                                    cellRenderer:
                                      'defectTableCheckboxRendererComponent',
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                    onCellValueChanged: (params) => {
                                      if (
                                        params.oldValue !== params.newValue &&
                                        (params.data['Intensità K2'] != null ||
                                          params.data['Estensione K1'] != null)
                                      ) {
                                        params.data.NR = false;
                                        params.api.refreshCells({
                                          rowNodes: [params.node],
                                          columns: [params.column],
                                        });
                                        tunnelInputTemplateComponent.getConfirmation(
                                          {
                                            isCancelButtonVisible: false,
                                            message:
                                              'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                            title: 'Info',
                                          }
                                        );
                                      }
                                      params.data.NA = false;
                                      params.data.NP = false;
                                      params.api.refreshCells({
                                        rowNodes: [params.node],
                                      });
                                    },
                                  },
                                  {
                                    headerName: 'NP',
                                    headerClass: 'header-no-child',
                                    field: 'NP',
                                    width: 40,
                                    cellRenderer:
                                      'defectTableCheckboxRendererComponent',
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                    onCellValueChanged: (params) => {
                                      if (
                                        params.oldValue !== params.newValue &&
                                        (params.data['Intensità K2'] != null ||
                                          params.data['Estensione K1'] != null)
                                      ) {
                                        params.data.NP = false;
                                        params.api.refreshCells({
                                          rowNodes: [params.node],
                                          columns: [params.column],
                                        });
                                        tunnelInputTemplateComponent.getConfirmation(
                                          {
                                            isCancelButtonVisible: false,
                                            message:
                                              'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                            title: 'Info',
                                          }
                                        );
                                      }
                                      params.data.NA = false;
                                      params.data.NR = false;
                                      params.api.refreshCells({
                                        rowNodes: [params.node],
                                      });
                                    },
                                  },
                                  {
                                    headerName: 'L',
                                    headerClass: 'header-no-child',
                                    field: 'L',
                                    width: 70,
                                    cellRenderer:
                                      defectTableLocalizzazioneRendererComponent,
                                    cellRendererParams: {
                                      acceptedFileTypes:
                                        environment.file_upload
                                          .accepted_file_types.images,
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                    modelData:
                                      tunnelInputTemplateComponent.model,
                                    token: tunnelInputTemplateComponent.token,
                                    componentType:
                                      TunnelConstants.levels.defect2,
                                    cellClass: [
                                      'cell-wrapping',
                                      'cursor-pointer',
                                    ],
                                  },
                                  {
                                    headerName: 'Sup. Est. Dif. [m^2]',
                                    headerClass: 'header-no-child',
                                    field: 'Sup Est Dif',
                                    minWidth: 100,
                                    flex: 1,
                                    cellClass: ['remove-lines'],
                                    editable: !isDisabled,
                                  },
                                ],
                              },
                              {
                                headerName: 'Giudizio esperto',
                                headerClass: 'border-bottom',
                                children: [
                                  {
                                    headerName: '',
                                    headerClass: 'remove-border-top',
                                    children: [
                                      {
                                        headerName: 'G',
                                        headerClass: 'header-no-child',
                                        field: 'G',
                                        width: 40,
                                        editable:
                                          tunnelInputTemplateComponent.isFieldDisabled(
                                            [UserRole.auditor],
                                            tunnelInputTemplateComponent.model
                                              .status ===
                                              InspectionStatus.toReview
                                          ),
                                      },
                                    ],
                                  },
                                  {
                                    headerName:
                                      'Correlazione con altri difetti',
                                    headerClass: 'header-no-child',
                                    field: 'Correlazione con altri difetti',
                                    minWidth: 100,
                                    flex: 1,
                                    cellClass: ['remove-lines'],
                                    editable:
                                      userRole === UserRole.auditor &&
                                      tunnelInputTemplateComponent.model
                                        .status === InspectionStatus.toReview,
                                  },
                                  {
                                    headerName: 'PS g',
                                    headerClass: 'header-no-child',
                                    field: 'PS g',
                                    cellRenderer:
                                      'defectTableCheckboxRendererComponent',
                                    disabled:
                                      userRole !== UserRole.auditor ||
                                      tunnelInputTemplateComponent.model
                                        .status !== InspectionStatus.toReview,
                                    width: 40,
                                  },
                                  {
                                    headerName: 'PS I',
                                    headerClass: 'header-no-child',
                                    field: 'PS I',
                                    cellRenderer:
                                      'defectTableCheckboxRendererComponent',
                                    disabled:
                                      userRole !== UserRole.auditor ||
                                      tunnelInputTemplateComponent.model
                                        .status !== InspectionStatus.toReview,
                                    width: 40,
                                  },
                                ],
                              },
                              {
                                headerName: 'Note',
                                headerClass: 'header-no-child',
                                field: 'Note',
                                minWidth: 100,
                                autoHeight: true,
                                flex: 1,
                                cellClass: ['remove-lines'],
                                cellRenderer: defectTableNoteRendererComponent,
                                cellRendererParams: {
                                  mode: DefectNoteMode.tunnelDefect,
                                },
                                disabled:
                                  tunnelInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                              },
                            ],
                            components: {
                              defectTableCheckboxRendererComponent,
                              defectTableNoteRendererComponent,
                            },
                          },
                        },
                        fieldArray: {
                          fieldGroup: [
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Codice difetto',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Descrizione difetto',
                            },
                            {
                              fieldGroup: [
                                {
                                  fieldGroup: [
                                    {
                                      fieldGroup: [
                                        {
                                          key: 'Estensione K1',
                                          type: 'input',
                                          defaultValue: '',
                                          templateOptions: {
                                            disabled: true,
                                          },
                                        },
                                      ],
                                    },
                                    {
                                      fieldGroup: [
                                        {
                                          type: 'input',
                                          defaultValue: '',
                                          key: 'Intensità K2',
                                          templateOptions: {
                                            disabled: true,
                                          },
                                        },
                                      ],
                                    },
                                    {
                                      type: 'input',
                                      defaultValue: '',
                                      key: 'N° foto',
                                      templateOptions: {
                                        disabled:
                                          tunnelInputTemplateComponent.isFieldDisabled(
                                            [],
                                            isDisabled
                                          ),
                                      },
                                    },
                                    {
                                      type: 'input',
                                      defaultValue: '',
                                      key: 'NA',
                                      templateOptions: {
                                        disabled:
                                          tunnelInputTemplateComponent.isFieldDisabled(
                                            [],
                                            isDisabled
                                          ),
                                      },
                                    },
                                    {
                                      type: 'input',
                                      defaultValue: '',
                                      key: 'NR',
                                      templateOptions: {
                                        disabled:
                                          tunnelInputTemplateComponent.isFieldDisabled(
                                            [],
                                            isDisabled
                                          ),
                                      },
                                    },
                                    {
                                      type: 'input',
                                      defaultValue: '',
                                      key: 'NP',
                                      templateOptions: {
                                        disabled:
                                          tunnelInputTemplateComponent.isFieldDisabled(
                                            [],
                                            isDisabled
                                          ),
                                      },
                                    },
                                    {
                                      type: 'input',
                                      defaultValue: '',
                                      key: 'L',
                                    },
                                    {
                                      type: 'input',
                                      defaultValue: '',
                                      key: 'Sup Est Dif',
                                      templateOptions: {
                                        disabled:
                                          tunnelInputTemplateComponent.isFieldDisabled(
                                            [],
                                            isDisabled
                                          ),
                                      },
                                    },
                                  ],
                                },
                                {
                                  type: 'cube-text-only-type',
                                  defaultValue: '',
                                  key: 'G',
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'Correlazione con altri difetti',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'PS g',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'PS I',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                              ],
                            },
                            {
                              type: 'textarea',
                              defaultValue: '',
                              key: 'Note',
                              templateOptions: {
                                disabled:
                                  tunnelInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
                hideExpression: `formState.${TunnelConstants.levels.defect2}`,
              },
            ],
          },
        ],
      },
      {
        // Level: Defect Template 3
        key: TunnelConstants.levels.defect3,
        resetOnHide: true,
        type: 'cube-tabs-type',
        templateOptions: {
          devMode: tunnelInputTemplateComponent.devMode, // TEMP: For development
        },
        hideExpression: () =>
          tunnelInputTemplateComponent.model.activeLevel !==
          TunnelConstants.levels.defect3,
        fieldGroup: [
          {
            templateOptions: { label: 'Difetti relativi alla struttura' },
            fieldGroup: [
              {
                hooks: {
                  onInit: (field) => {
                    tunnelInputTemplateComponent.getDefectsList(
                      TunnelConstants.levels.defect3
                    );
                  },
                },
                fieldGroup: [
                  {
                    key: 'Scheda Ispezione Ponti di Livello 1',
                    fieldGroup: [
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Scheda Ispezione Gallerie di Livello 1',
                        },
                      },
                      {
                        fieldGroupClassName:
                          'display-flex defect-header-input-color grid-gap-extra',
                        fieldGroup: [
                          {
                            className: 'flex-5',
                            fieldGroup: [
                              {
                                fieldGroupClassName:
                                  'display-flex grid-gap repeat-header',
                                className: 'flex-2',
                                fieldGroup: [
                                  {
                                    className: 'unique-code-sec',
                                    key: 'uniquecode',
                                    type: 'cube-unique-code-type',
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'Nome galleria',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Nome galleria',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'Strada di appartenenza',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Strada di appartenenza',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'Progressiva km',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Progressiva km',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName: 'display-flex',
                                fieldGroup: [
                                  {
                                    className: 'flex-2',
                                    fieldGroup: [
                                      {
                                        key: 'InspectionDate',
                                        type: 'cube-repeat-section-type',
                                        templateOptions: {
                                          disabled:
                                            tunnelInputTemplateComponent.isFieldDisabled(
                                              [],
                                              isDisabled
                                            ),
                                        },
                                        hooks: {
                                          onInit: () => {
                                            if (
                                              !tunnelInputTemplateComponent.defectUpdateStatus
                                            ) {
                                              tunnelInputTemplateComponent.model[
                                                TunnelConstants.levels.defect3
                                              ][
                                                'Scheda Ispezione Ponti di Livello 1'
                                              ].InspectionDate = tunnelInputTemplateComponent.getDeepCopy(
                                                tunnelInputTemplateComponent
                                                  .model[
                                                  TunnelConstants.levels.l0
                                                ]['Inspection date']
                                              );
                                              tunnelInputTemplateComponent.refreshFields();
                                            }
                                          },
                                        },
                                        fieldArray: {
                                          fieldGroupClassName:
                                            'display-flex grid-gap',
                                          fieldGroup: [
                                            {
                                              className: 'flex-1',
                                              key: 'Tecnico Rilevatore',
                                              type: 'input',
                                              defaultValue: '',
                                              templateOptions: {
                                                disabled:
                                                  tunnelInputTemplateComponent.isFieldDisabled(
                                                    [],
                                                    isDisabled
                                                  ),
                                              },
                                              expressionProperties: {
                                                'templateOptions.label':
                                                  "'Tecnico Rilevatore n.' + (+field.parent.key + 1)",
                                              },
                                            },
                                            {
                                              className: 'flex-1',
                                              key: 'Data Ispezione',
                                              type: 'cube-datepicker-type',
                                              defaultValue: '',
                                              templateOptions: {
                                                readonly: true,
                                                disabled:
                                                  tunnelInputTemplateComponent.isFieldDisabled(
                                                    [],
                                                    isDisabled
                                                  ),
                                                max: new Date(),
                                              },
                                              expressionProperties: {
                                                'templateOptions.label':
                                                  "'Data Ispezione n.' + (+field.parent.key + 1)",
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        fieldGroupClassName:
                                          'display-flex grid-gap align-items-center repeat-header',
                                        fieldGroup: [
                                          {
                                            key: 'Sup Elem',
                                            type: 'input',
                                            defaultValue: '',
                                            className: 'flex-1',
                                            templateOptions: {
                                              label: 'Sup. Elem.',
                                              disabled:
                                                tunnelInputTemplateComponent.isFieldDisabled(
                                                  [],
                                                  isDisabled
                                                ),
                                            },
                                          },
                                          {
                                            key: 'Codice IOP',
                                            type: 'cube-text-only-type',
                                            className: 'font-bold flex-1',
                                            templateOptions: {
                                              pre: 'Codice IOP: ',
                                            },
                                          },
                                          {
                                            className: 'font-bold',
                                            key: TunnelConstants.partNo,
                                            type: 'cube-text-only-type',
                                            templateOptions: {
                                              pre: 'Concio N.: ',
                                              post: 'Y',
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            className: 'defect-header-input-color',
                            fieldGroup: [
                              {
                                className: 'page-heading-image-only',
                                template: `<div class="defect-rina-logo">
                                      <img src="assets/rina/RINA-dark.png">
                                  </div>`,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Scheda Ispezione Ponti di Livello 1 List1',
                        type: 'cube-ag-grid-table-type',
                        className: 'ag-theme-balham',
                        templateOptions: {
                          hasModelData: true,
                          height: '100%',
                          width: '100%',
                          gridOptions: {
                            defaultColDef: {
                              cellClass: [
                                'align-items-center',
                                'display-flex',
                                'justify-content-center',
                              ],
                            },
                            columnDefs: [
                              {
                                headerName: 'Codice difetto',
                                headerClass: 'header-no-child',
                                field: 'Codice difetto',
                                width: 100,
                                autoHeight: true,
                                colSpan: (params) => {
                                  if (
                                    params.data['Codice difetto'] ===
                                      TunnelConstants.eventualiNote ||
                                    params.data.sectionHeader
                                  ) {
                                    // have all Eventuali note and header columns width 2
                                    return 2;
                                  } else {
                                    return 1;
                                  }
                                },
                                cellClassRules: {
                                  'cell-pass': (params) => params.data.G >= 5,
                                  'cell-fail': (params) => params.data.G < 5,
                                  'section-header': (params) =>
                                    params.data.sectionHeader,
                                  'cell-wrapping': (params) =>
                                    params.data.sectionHeader,
                                },
                              },
                              {
                                headerName: 'Descrizione difetto',
                                headerClass: 'header-no-child',
                                field: 'Descrizione difetto',
                                minWidth: 200,
                                flex: 2,
                                autoHeight: true,
                                cellClass: [
                                  'cell-wrapping',
                                  'text-align-center',
                                ],
                                cellClassRules: {
                                  'cell-pass': (params) => params.data.G >= 5,
                                  'cell-fail': (params) => params.data.G < 5,
                                },
                              },
                              {
                                headerName: `A cura dell'ispettore`,
                                headerClass: 'border-bottom',
                                children: [
                                  {
                                    headerName: 'Estensione K1',
                                    headerClass: 'remove-border-top',
                                    children: [
                                      {
                                        headerClass: 'custom-radio-table',
                                        headerComponent:
                                          defectTableHeaderRendererComponent,
                                        headerComponentParams: {
                                          title: ['0.2', '0.5', '1'],
                                          sortable: false,
                                        },
                                        field: 'Estensione K1',
                                        cellRendererSelector: (params) => {
                                          if (
                                            params.data['Codice difetto'] ===
                                              TunnelConstants.eventualiNote ||
                                            params.data.sectionHeader
                                          ) {
                                            return null;
                                          }
                                          return {
                                            component:
                                              defectTableRadioButtonsRendererComponent,
                                            params: {},
                                          };
                                        },
                                        disabled: (params) =>
                                          params.data['Codice difetto'] ===
                                          TunnelConstants.eventualiNote
                                            ? !isDisabled
                                            : isDisabled,
                                        editable: (params) =>
                                          params.data['Codice difetto'] ===
                                          TunnelConstants.eventualiNote
                                            ? !isDisabled
                                            : isDisabled,
                                        width: 140,
                                        colSpan: (params) => {
                                          if (
                                            params.data['Codice difetto'] ===
                                              TunnelConstants.eventualiNote ||
                                            params.data.sectionHeader
                                          ) {
                                            return 17;
                                          }
                                          return 1;
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    headerName: 'Intensità K2',
                                    headerClass: 'remove-border-top',
                                    children: [
                                      {
                                        headerClass: 'custom-radio-table',
                                        headerComponent:
                                          defectTableHeaderRendererComponent,
                                        headerComponentParams: {
                                          title: ['0.2', '0.5', '1'],
                                          sortable: false,
                                        },
                                        field: 'Intensità K2',
                                        cellRenderer:
                                          defectTableRadioButtonsRendererComponent,
                                        disabled:
                                          tunnelInputTemplateComponent.isFieldDisabled(
                                            [],
                                            isDisabled
                                          ),
                                        width: 140,
                                      },
                                    ],
                                  },
                                  {
                                    headerName: 'N° foto',
                                    headerClass: 'header-no-child',
                                    field: 'N° foto',
                                    cellRenderer:
                                      defectTableLocalizzazioneRendererComponent,
                                    cellRendererParams: {
                                      acceptedFileTypes:
                                        environment.file_upload
                                          .accepted_file_types.images,
                                    },
                                    width: 70,
                                    modelData:
                                      tunnelInputTemplateComponent.model,
                                    token: tunnelInputTemplateComponent.token,
                                    componentType:
                                      TunnelConstants.levels.defect3,
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                  {
                                    headerName: 'NA',
                                    headerClass: 'header-no-child',
                                    field: 'NA',
                                    width: 40,
                                    cellRenderer:
                                      'defectTableCheckboxRendererComponent',
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                    onCellValueChanged: (params) => {
                                      if (
                                        params.oldValue !== params.newValue &&
                                        (params.data['Intensità K2'] != null ||
                                          params.data['Estensione K1'] != null)
                                      ) {
                                        params.data.NA = false;
                                        params.api.refreshCells({
                                          rowNodes: [params.node],
                                          columns: [params.column],
                                        });
                                        tunnelInputTemplateComponent.getConfirmation(
                                          {
                                            isCancelButtonVisible: false,
                                            message:
                                              'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                            title: 'Info',
                                          }
                                        );
                                      }
                                      params.data.NR = false;
                                      params.data.NP = false;
                                      params.api.refreshCells({
                                        rowNodes: [params.node],
                                      });
                                    },
                                  },
                                  {
                                    headerName: 'NR',
                                    headerClass: 'header-no-child',
                                    field: 'NR',
                                    width: 40,
                                    cellRenderer:
                                      'defectTableCheckboxRendererComponent',
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                    onCellValueChanged: (params) => {
                                      if (
                                        params.oldValue !== params.newValue &&
                                        (params.data['Intensità K2'] != null ||
                                          params.data['Estensione K1'] != null)
                                      ) {
                                        params.data.NR = false;
                                        params.api.refreshCells({
                                          rowNodes: [params.node],
                                          columns: [params.column],
                                        });
                                        tunnelInputTemplateComponent.getConfirmation(
                                          {
                                            isCancelButtonVisible: false,
                                            message:
                                              'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                            title: 'Info',
                                          }
                                        );
                                      }
                                      params.data.NA = false;
                                      params.data.NP = false;
                                      params.api.refreshCells({
                                        rowNodes: [params.node],
                                      });
                                    },
                                  },
                                  {
                                    headerName: 'NP',
                                    headerClass: 'header-no-child',
                                    field: 'NP',
                                    width: 40,
                                    cellRenderer:
                                      'defectTableCheckboxRendererComponent',
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                    onCellValueChanged: (params) => {
                                      if (
                                        params.oldValue !== params.newValue &&
                                        (params.data['Intensità K2'] != null ||
                                          params.data['Estensione K1'] != null)
                                      ) {
                                        params.data.NP = false;
                                        params.api.refreshCells({
                                          rowNodes: [params.node],
                                          columns: [params.column],
                                        });
                                        tunnelInputTemplateComponent.getConfirmation(
                                          {
                                            isCancelButtonVisible: false,
                                            message:
                                              'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                            title: 'Info',
                                          }
                                        );
                                      }
                                      params.data.NA = false;
                                      params.data.NR = false;
                                      params.api.refreshCells({
                                        rowNodes: [params.node],
                                      });
                                    },
                                  },
                                  {
                                    headerName: 'L',
                                    headerClass: 'header-no-child',
                                    field: 'L',
                                    width: 70,
                                    cellRenderer:
                                      defectTableLocalizzazioneRendererComponent,
                                    cellRendererParams: {
                                      acceptedFileTypes:
                                        environment.file_upload
                                          .accepted_file_types.images,
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                    modelData:
                                      tunnelInputTemplateComponent.model,
                                    token: tunnelInputTemplateComponent.token,
                                    componentType:
                                      TunnelConstants.levels.defect3,
                                    cellClass: [
                                      'cell-wrapping',
                                      'cursor-pointer',
                                    ],
                                  },
                                  {
                                    headerName: 'Sup. Est. Dif. [m^2]',
                                    headerClass: 'header-no-child',
                                    field: 'Sup Est Dif',
                                    minWidth: 100,
                                    flex: 1,
                                    cellClass: ['remove-lines'],
                                    editable: !isDisabled,
                                  },
                                ],
                              },
                              {
                                headerName: 'Giudizio esperto',
                                headerClass: 'border-bottom',
                                children: [
                                  {
                                    headerName: '',
                                    headerClass: 'remove-border-top',
                                    children: [
                                      {
                                        headerName: 'G',
                                        headerClass: 'header-no-child',
                                        field: 'G',
                                        width: 40,
                                        editable:
                                          tunnelInputTemplateComponent.isFieldDisabled(
                                            [UserRole.auditor],
                                            tunnelInputTemplateComponent.model
                                              .status ===
                                              InspectionStatus.toReview
                                          ),
                                      },
                                    ],
                                  },
                                  {
                                    headerName:
                                      'Correlazione con altri difetti',
                                    headerClass: 'header-no-child',
                                    field: 'Correlazione con altri difetti',
                                    minWidth: 100,
                                    flex: 1,
                                    cellClass: ['remove-lines'],
                                    editable:
                                      userRole === UserRole.auditor &&
                                      tunnelInputTemplateComponent.model
                                        .status === InspectionStatus.toReview,
                                  },
                                  {
                                    headerName: 'PS g',
                                    headerClass: 'header-no-child',
                                    field: 'PS g',
                                    cellRenderer:
                                      'defectTableCheckboxRendererComponent',
                                    disabled:
                                      userRole !== UserRole.auditor ||
                                      tunnelInputTemplateComponent.model
                                        .status !== InspectionStatus.toReview,
                                    width: 40,
                                  },
                                  {
                                    headerName: 'PS I',
                                    headerClass: 'header-no-child',
                                    field: 'PS I',
                                    cellRenderer:
                                      'defectTableCheckboxRendererComponent',
                                    disabled:
                                      userRole !== UserRole.auditor ||
                                      tunnelInputTemplateComponent.model
                                        .status !== InspectionStatus.toReview,
                                    width: 40,
                                  },
                                ],
                              },
                              {
                                headerName: 'Note',
                                headerClass: 'header-no-child',
                                field: 'Note',
                                minWidth: 100,
                                autoHeight: true,
                                flex: 1,
                                cellClass: ['remove-lines'],
                                cellRenderer: defectTableNoteRendererComponent,
                                cellRendererParams: {
                                  mode: DefectNoteMode.tunnelDefect,
                                },
                                disabled:
                                  tunnelInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                              },
                            ],
                            components: {
                              defectTableCheckboxRendererComponent,
                              defectTableNoteRendererComponent,
                            },
                          },
                        },
                        fieldArray: {
                          fieldGroup: [
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Codice difetto',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Descrizione difetto',
                            },
                            {
                              fieldGroup: [
                                {
                                  fieldGroup: [
                                    {
                                      key: 'Estensione K1',
                                      type: 'input',
                                      defaultValue: '',
                                      templateOptions: {
                                        disabled: true,
                                      },
                                    },
                                  ],
                                },
                                {
                                  fieldGroup: [
                                    {
                                      type: 'input',
                                      defaultValue: '',
                                      key: 'Intensità K2',
                                      templateOptions: {
                                        disabled: true,
                                      },
                                    },
                                  ],
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'N° foto',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'NA',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'NR',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'NP',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'L',
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'Sup Est Dif',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                              ],
                            },
                            {
                              fieldGroup: [
                                {
                                  type: 'cube-text-only-type',
                                  defaultValue: '',
                                  key: 'G',
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'Correlazione con altri difetti',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'PS g',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'PS I',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                              ],
                            },
                            {
                              type: 'textarea',
                              defaultValue: '',
                              key: 'Note',
                              templateOptions: {
                                disabled:
                                  tunnelInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
                hideExpression: `formState.${TunnelConstants.levels.defect3}`,
              },
            ],
          },
        ],
      },
      {
        // Level: Defect Template 4
        key: TunnelConstants.levels.defect4,
        resetOnHide: true,
        type: 'cube-tabs-type',
        templateOptions: {
          devMode: tunnelInputTemplateComponent.devMode, // TEMP: For development
        },
        hideExpression: () =>
          tunnelInputTemplateComponent.model.activeLevel !==
          TunnelConstants.levels.defect4,
        fieldGroup: [
          {
            templateOptions: {
              label: 'Difetti relativi alla piattaforma stradale/autostradale',
            },
            fieldGroup: [
              {
                hooks: {
                  onInit: (field) => {
                    tunnelInputTemplateComponent.getDefectsList(
                      TunnelConstants.levels.defect4
                    );
                  },
                },
                fieldGroup: [
                  {
                    key: 'Scheda Ispezione Ponti di Livello 1',
                    fieldGroup: [
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Scheda Ispezione Gallerie di Livello 1',
                        },
                      },
                      {
                        fieldGroupClassName:
                          'display-flex defect-header-input-color grid-gap-extra',
                        fieldGroup: [
                          {
                            className: 'flex-5',
                            fieldGroup: [
                              {
                                fieldGroupClassName:
                                  'display-flex grid-gap repeat-header',
                                className: 'flex-2',
                                fieldGroup: [
                                  {
                                    className: 'unique-code-sec',
                                    key: 'uniquecode',
                                    type: 'cube-unique-code-type',
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'Nome galleria',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Nome galleria',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'Strada di appartenenza',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Strada di appartenenza',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'Progressiva km',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Progressiva km',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName: 'display-flex',
                                fieldGroup: [
                                  {
                                    className: 'flex-2',
                                    fieldGroup: [
                                      {
                                        key: 'InspectionDate',
                                        type: 'cube-repeat-section-type',
                                        templateOptions: {
                                          disabled:
                                            tunnelInputTemplateComponent.isFieldDisabled(
                                              [],
                                              isDisabled
                                            ),
                                        },
                                        hooks: {
                                          onInit: () => {
                                            if (
                                              !tunnelInputTemplateComponent.defectUpdateStatus
                                            ) {
                                              tunnelInputTemplateComponent.model[
                                                TunnelConstants.levels.defect4
                                              ][
                                                'Scheda Ispezione Ponti di Livello 1'
                                              ].InspectionDate = tunnelInputTemplateComponent.getDeepCopy(
                                                tunnelInputTemplateComponent
                                                  .model[
                                                  TunnelConstants.levels.l0
                                                ]['Inspection date']
                                              );
                                              tunnelInputTemplateComponent.refreshFields();
                                            }
                                          },
                                        },
                                        fieldArray: {
                                          fieldGroupClassName:
                                            'display-flex grid-gap',
                                          fieldGroup: [
                                            {
                                              className: 'flex-1',
                                              key: 'Tecnico Rilevatore',
                                              type: 'input',
                                              defaultValue: '',
                                              templateOptions: {
                                                disabled:
                                                  tunnelInputTemplateComponent.isFieldDisabled(
                                                    [],
                                                    isDisabled
                                                  ),
                                              },
                                              expressionProperties: {
                                                'templateOptions.label':
                                                  "'Tecnico Rilevatore n.' + (+field.parent.key + 1)",
                                              },
                                            },
                                            {
                                              className: 'flex-1',
                                              key: 'Data Ispezione',
                                              type: 'cube-datepicker-type',
                                              defaultValue: '',
                                              templateOptions: {
                                                readonly: true,
                                                disabled:
                                                  tunnelInputTemplateComponent.isFieldDisabled(
                                                    [],
                                                    isDisabled
                                                  ),
                                                max: new Date(),
                                              },
                                              expressionProperties: {
                                                'templateOptions.label':
                                                  "'Data Ispezione n.' + (+field.parent.key + 1)",
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        fieldGroupClassName:
                                          'display-flex grid-gap align-items-center repeat-header',
                                        fieldGroup: [
                                          {
                                            key: 'Sup Elem',
                                            type: 'input',
                                            defaultValue: '',
                                            className: 'flex-1',
                                            templateOptions: {
                                              label: 'Sup. Elem.',
                                              disabled:
                                                tunnelInputTemplateComponent.isFieldDisabled(
                                                  [],
                                                  isDisabled
                                                ),
                                            },
                                          },
                                          {
                                            key: 'Codice IOP',
                                            type: 'cube-text-only-type',
                                            className: 'font-bold flex-1',
                                            templateOptions: {
                                              pre: 'Codice IOP: ',
                                            },
                                          },
                                          {
                                            className: 'font-bold',
                                            key: TunnelConstants.partNo,
                                            type: 'cube-text-only-type',
                                            templateOptions: {
                                              pre: 'Concio N.: ',
                                              post: 'Y',
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            className: 'defect-header-input-color',
                            fieldGroup: [
                              {
                                className: 'page-heading-image-only',
                                template: `<div class="defect-rina-logo">
                                      <img src="assets/rina/RINA-dark.png">
                                  </div>`,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Scheda Ispezione Ponti di Livello 1 List1',
                        type: 'cube-ag-grid-table-type',
                        className: 'ag-theme-balham',
                        templateOptions: {
                          hasModelData: true,
                          height: '100%',
                          width: '100%',
                          gridOptions: {
                            defaultColDef: {
                              cellClass: [
                                'align-items-center',
                                'display-flex',
                                'justify-content-center',
                              ],
                            },
                            columnDefs: [
                              {
                                headerName: 'Codice difetto',
                                headerClass: 'header-no-child',
                                field: 'Codice difetto',
                                width: 100,
                                autoHeight: true,
                                colSpan: (params) => {
                                  if (
                                    params.data['Codice difetto'] ===
                                      TunnelConstants.eventualiNote ||
                                    params.data.sectionHeader
                                  ) {
                                    // have all Eventuali note and header columns width 2
                                    return 2;
                                  } else {
                                    return 1;
                                  }
                                },
                                cellClassRules: {
                                  'cell-pass': (params) => params.data.G >= 5,
                                  'cell-fail': (params) => params.data.G < 5,
                                  'section-header': (params) =>
                                    params.data.sectionHeader,
                                  'cell-wrapping': (params) =>
                                    params.data.sectionHeader,
                                },
                              },
                              {
                                headerName: 'Descrizione difetto',
                                headerClass: 'header-no-child',
                                field: 'Descrizione difetto',
                                minWidth: 200,
                                flex: 2,
                                autoHeight: true,
                                cellClass: [
                                  'cell-wrapping',
                                  'text-align-center',
                                ],
                                cellClassRules: {
                                  'cell-pass': (params) => params.data.G >= 5,
                                  'cell-fail': (params) => params.data.G < 5,
                                },
                              },
                              {
                                headerName: `A cura dell'ispettore`,
                                headerClass: 'border-bottom',
                                children: [
                                  {
                                    headerName: 'Estensione K1',
                                    headerClass: 'remove-border-top',
                                    children: [
                                      {
                                        headerClass: 'custom-radio-table',
                                        headerComponent:
                                          defectTableHeaderRendererComponent,
                                        headerComponentParams: {
                                          title: ['0.2', '0.5', '1'],
                                          sortable: false,
                                        },
                                        field: 'Estensione K1',
                                        cellRendererSelector: (params) => {
                                          if (
                                            params.data['Codice difetto'] ===
                                              TunnelConstants.eventualiNote ||
                                            params.data.sectionHeader
                                          ) {
                                            return null;
                                          }
                                          return {
                                            component:
                                              defectTableRadioButtonsRendererComponent,
                                            params: {},
                                          };
                                        },
                                        disabled: (params) =>
                                          params.data['Codice difetto'] ===
                                          TunnelConstants.eventualiNote
                                            ? !isDisabled
                                            : isDisabled,
                                        editable: (params) =>
                                          params.data['Codice difetto'] ===
                                          TunnelConstants.eventualiNote
                                            ? !isDisabled
                                            : isDisabled,
                                        width: 140,
                                        colSpan: (params) => {
                                          if (
                                            params.data['Codice difetto'] ===
                                              TunnelConstants.eventualiNote ||
                                            params.data.sectionHeader
                                          ) {
                                            return 17;
                                          }
                                          return 1;
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    headerName: 'Intensità K2',
                                    headerClass: 'remove-border-top',
                                    children: [
                                      {
                                        headerClass: 'custom-radio-table',
                                        headerComponent:
                                          defectTableHeaderRendererComponent,
                                        headerComponentParams: {
                                          title: ['0.2', '0.5', '1'],
                                          sortable: false,
                                        },
                                        field: 'Intensità K2',
                                        cellRenderer:
                                          defectTableRadioButtonsRendererComponent,
                                        disabled:
                                          tunnelInputTemplateComponent.isFieldDisabled(
                                            [],
                                            isDisabled
                                          ),
                                        width: 140,
                                      },
                                    ],
                                  },
                                  {
                                    headerName: 'N° foto',
                                    headerClass: 'header-no-child',
                                    field: 'N° foto',
                                    cellRenderer:
                                      defectTableLocalizzazioneRendererComponent,
                                    cellRendererParams: {
                                      acceptedFileTypes:
                                        environment.file_upload
                                          .accepted_file_types.images,
                                    },
                                    width: 70,
                                    modelData:
                                      tunnelInputTemplateComponent.model,
                                    token: tunnelInputTemplateComponent.token,
                                    componentType:
                                      TunnelConstants.levels.defect4,
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                  {
                                    headerName: 'NA',
                                    headerClass: 'header-no-child',
                                    field: 'NA',
                                    width: 40,
                                    cellRenderer:
                                      'defectTableCheckboxRendererComponent',
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                    onCellValueChanged: (params) => {
                                      if (
                                        params.oldValue !== params.newValue &&
                                        (params.data['Intensità K2'] != null ||
                                          params.data['Estensione K1'] != null)
                                      ) {
                                        params.data.NA = false;
                                        params.api.refreshCells({
                                          rowNodes: [params.node],
                                          columns: [params.column],
                                        });
                                        tunnelInputTemplateComponent.getConfirmation(
                                          {
                                            isCancelButtonVisible: false,
                                            message:
                                              'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                            title: 'Info',
                                          }
                                        );
                                      }
                                      params.data.NR = false;
                                      params.data.NP = false;
                                      params.api.refreshCells({
                                        rowNodes: [params.node],
                                      });
                                    },
                                  },
                                  {
                                    headerName: 'NR',
                                    headerClass: 'header-no-child',
                                    field: 'NR',
                                    width: 40,
                                    cellRenderer:
                                      'defectTableCheckboxRendererComponent',
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                    onCellValueChanged: (params) => {
                                      if (
                                        params.oldValue !== params.newValue &&
                                        (params.data['Intensità K2'] != null ||
                                          params.data['Estensione K1'] != null)
                                      ) {
                                        params.data.NR = false;
                                        params.api.refreshCells({
                                          rowNodes: [params.node],
                                          columns: [params.column],
                                        });
                                        tunnelInputTemplateComponent.getConfirmation(
                                          {
                                            isCancelButtonVisible: false,
                                            message:
                                              'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                            title: 'Info',
                                          }
                                        );
                                      }
                                      params.data.NA = false;
                                      params.data.NP = false;
                                      params.api.refreshCells({
                                        rowNodes: [params.node],
                                      });
                                    },
                                  },
                                  {
                                    headerName: 'NP',
                                    headerClass: 'header-no-child',
                                    field: 'NP',
                                    width: 40,
                                    cellRenderer:
                                      'defectTableCheckboxRendererComponent',
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                    onCellValueChanged: (params) => {
                                      if (
                                        params.oldValue !== params.newValue &&
                                        (params.data['Intensità K2'] != null ||
                                          params.data['Estensione K1'] != null)
                                      ) {
                                        params.data.NP = false;
                                        params.api.refreshCells({
                                          rowNodes: [params.node],
                                          columns: [params.column],
                                        });
                                        tunnelInputTemplateComponent.getConfirmation(
                                          {
                                            isCancelButtonVisible: false,
                                            message:
                                              'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                            title: 'Info',
                                          }
                                        );
                                      }
                                      params.data.NA = false;
                                      params.data.NR = false;
                                      params.api.refreshCells({
                                        rowNodes: [params.node],
                                      });
                                    },
                                  },
                                  {
                                    headerName: 'L',
                                    headerClass: 'header-no-child',
                                    field: 'L',
                                    width: 70,
                                    cellRenderer:
                                      defectTableLocalizzazioneRendererComponent,
                                    cellRendererParams: {
                                      acceptedFileTypes:
                                        environment.file_upload
                                          .accepted_file_types.images,
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                    modelData:
                                      tunnelInputTemplateComponent.model,
                                    token: tunnelInputTemplateComponent.token,
                                    componentType:
                                      TunnelConstants.levels.defect4,
                                    cellClass: [
                                      'cell-wrapping',
                                      'cursor-pointer',
                                    ],
                                  },
                                  {
                                    headerName: 'Sup. Est. Dif. [m^2]',
                                    headerClass: 'header-no-child',
                                    field: 'Sup Est Dif',
                                    minWidth: 100,
                                    flex: 1,
                                    cellClass: ['remove-lines'],
                                    editable: !isDisabled,
                                  },
                                ],
                              },
                              {
                                headerName: 'Giudizio esperto',
                                headerClass: 'border-bottom',
                                children: [
                                  {
                                    headerName: '',
                                    headerClass: 'remove-border-top',
                                    children: [
                                      {
                                        headerName: 'G',
                                        headerClass: 'header-no-child',
                                        field: 'G',
                                        width: 40,
                                        editable:
                                          tunnelInputTemplateComponent.isFieldDisabled(
                                            [UserRole.auditor],
                                            tunnelInputTemplateComponent.model
                                              .status ===
                                              InspectionStatus.toReview
                                          ),
                                      },
                                    ],
                                  },
                                  {
                                    headerName:
                                      'Correlazione con altri difetti',
                                    headerClass: 'header-no-child',
                                    field: 'Correlazione con altri difetti',
                                    minWidth: 100,
                                    flex: 1,
                                    cellClass: ['remove-lines'],
                                    editable:
                                      userRole === UserRole.auditor &&
                                      tunnelInputTemplateComponent.model
                                        .status === InspectionStatus.toReview,
                                  },
                                  {
                                    headerName: 'PS g',
                                    headerClass: 'header-no-child',
                                    field: 'PS g',
                                    cellRenderer:
                                      'defectTableCheckboxRendererComponent',
                                    disabled:
                                      userRole !== UserRole.auditor ||
                                      tunnelInputTemplateComponent.model
                                        .status !== InspectionStatus.toReview,
                                    width: 40,
                                  },
                                  {
                                    headerName: 'PS I',
                                    headerClass: 'header-no-child',
                                    field: 'PS I',
                                    cellRenderer:
                                      'defectTableCheckboxRendererComponent',
                                    disabled:
                                      userRole !== UserRole.auditor ||
                                      tunnelInputTemplateComponent.model
                                        .status !== InspectionStatus.toReview,
                                    width: 40,
                                  },
                                ],
                              },
                              {
                                headerName: 'Note',
                                headerClass: 'header-no-child',
                                field: 'Note',
                                minWidth: 100,
                                autoHeight: true,
                                flex: 1,
                                cellClass: ['remove-lines'],
                                cellRenderer: defectTableNoteRendererComponent,
                                cellRendererParams: {
                                  mode: DefectNoteMode.tunnelDefect,
                                },
                                disabled:
                                  tunnelInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                              },
                            ],
                            components: {
                              defectTableCheckboxRendererComponent,
                              defectTableNoteRendererComponent,
                            },
                          },
                        },
                        fieldArray: {
                          fieldGroup: [
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Codice difetto',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Descrizione difetto',
                            },
                            {
                              fieldGroup: [
                                {
                                  fieldGroup: [
                                    {
                                      key: 'Estensione K1',
                                      type: 'input',
                                      defaultValue: '',
                                      templateOptions: {
                                        disabled: true,
                                      },
                                    },
                                  ],
                                },
                                {
                                  fieldGroup: [
                                    {
                                      type: 'input',
                                      defaultValue: '',
                                      key: 'Intensità K2',
                                      templateOptions: {
                                        disabled: true,
                                      },
                                    },
                                  ],
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'N° foto',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'NA',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'NR',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'NP',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'L',
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'Sup Est Dif',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                              ],
                            },
                            {
                              fieldGroup: [
                                {
                                  type: 'cube-text-only-type',
                                  defaultValue: '',
                                  key: 'G',
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'Correlazione con altri difetti',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'PS g',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'PS I',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                              ],
                            },
                            {
                              type: 'textarea',
                              defaultValue: '',
                              key: 'Note',
                              templateOptions: {
                                disabled:
                                  tunnelInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
                hideExpression: `formState.${TunnelConstants.levels.defect4}`,
              },
            ],
          },
        ],
      },
      {
        // Level: Defect Template 5
        key: TunnelConstants.levels.defect5,
        resetOnHide: true,
        type: 'cube-tabs-type',
        templateOptions: {
          devMode: tunnelInputTemplateComponent.devMode, // TEMP: For development
        },
        hideExpression: () =>
          tunnelInputTemplateComponent.model.activeLevel !==
          TunnelConstants.levels.defect5,
        fieldGroup: [
          {
            templateOptions: {
              label:
                'Difetti relativi agli elementi non strutturali e impianti',
            },
            fieldGroup: [
              {
                hooks: {
                  onInit: (field) => {
                    tunnelInputTemplateComponent.getDefectsList(
                      TunnelConstants.levels.defect5
                    );
                  },
                },
                fieldGroup: [
                  {
                    key: 'Scheda Ispezione Ponti di Livello 1',
                    fieldGroup: [
                      {
                        wrappers: ['cube-section-title-wrapper'],
                        templateOptions: {
                          title: 'Scheda Ispezione Gallerie di Livello 1',
                        },
                      },
                      {
                        fieldGroupClassName:
                          'display-flex defect-header-input-color grid-gap-extra',
                        fieldGroup: [
                          {
                            className: 'flex-5',
                            fieldGroup: [
                              {
                                fieldGroupClassName:
                                  'display-flex grid-gap repeat-header',
                                className: 'flex-2',
                                fieldGroup: [
                                  {
                                    className: 'unique-code-sec',
                                    key: 'uniquecode',
                                    type: 'cube-unique-code-type',
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'Nome galleria',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Nome galleria',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'Strada di appartenenza',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Strada di appartenenza',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                  {
                                    className: 'flex-1',
                                    key: 'Progressiva km',
                                    type: 'input',
                                    defaultValue: '',
                                    templateOptions: {
                                      label: 'Progressiva km',
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                  },
                                ],
                              },
                              {
                                fieldGroupClassName: 'display-flex',
                                fieldGroup: [
                                  {
                                    className: 'flex-2',
                                    fieldGroup: [
                                      {
                                        key: 'InspectionDate',
                                        type: 'cube-repeat-section-type',
                                        templateOptions: {
                                          disabled:
                                            tunnelInputTemplateComponent.isFieldDisabled(
                                              [],
                                              isDisabled
                                            ),
                                        },
                                        hooks: {
                                          onInit: () => {
                                            if (
                                              !tunnelInputTemplateComponent.defectUpdateStatus
                                            ) {
                                              tunnelInputTemplateComponent.model[
                                                TunnelConstants.levels.defect5
                                              ][
                                                'Scheda Ispezione Ponti di Livello 1'
                                              ].InspectionDate = tunnelInputTemplateComponent.getDeepCopy(
                                                tunnelInputTemplateComponent
                                                  .model[
                                                  TunnelConstants.levels.l0
                                                ]['Inspection date']
                                              );
                                              tunnelInputTemplateComponent.refreshFields();
                                            }
                                          },
                                        },
                                        fieldArray: {
                                          fieldGroupClassName:
                                            'display-flex grid-gap',
                                          fieldGroup: [
                                            {
                                              className: 'flex-1',
                                              key: 'Tecnico Rilevatore',
                                              type: 'input',
                                              defaultValue: '',
                                              templateOptions: {
                                                disabled:
                                                  tunnelInputTemplateComponent.isFieldDisabled(
                                                    [],
                                                    isDisabled
                                                  ),
                                              },
                                              expressionProperties: {
                                                'templateOptions.label':
                                                  "'Tecnico Rilevatore n.' + (+field.parent.key + 1)",
                                              },
                                            },
                                            {
                                              className: 'flex-1',
                                              key: 'Data Ispezione',
                                              type: 'cube-datepicker-type',
                                              defaultValue: '',
                                              templateOptions: {
                                                readonly: true,
                                                disabled:
                                                  tunnelInputTemplateComponent.isFieldDisabled(
                                                    [],
                                                    isDisabled
                                                  ),
                                                max: new Date(),
                                              },
                                              expressionProperties: {
                                                'templateOptions.label':
                                                  "'Data Ispezione n.' + (+field.parent.key + 1)",
                                              },
                                            },
                                          ],
                                        },
                                      },
                                      {
                                        fieldGroupClassName:
                                          'display-flex grid-gap align-items-center repeat-header',
                                        fieldGroup: [
                                          {
                                            key: 'Sup Elem',
                                            type: 'input',
                                            defaultValue: '',
                                            className: 'flex-1',
                                            templateOptions: {
                                              label: 'Sup. Elem.',
                                              disabled:
                                                tunnelInputTemplateComponent.isFieldDisabled(
                                                  [],
                                                  isDisabled
                                                ),
                                            },
                                          },
                                          {
                                            key: 'Codice IOP',
                                            type: 'cube-text-only-type',
                                            className: 'font-bold flex-1',
                                            templateOptions: {
                                              pre: 'Codice IOP: ',
                                            },
                                          },
                                          {
                                            className: 'font-bold',
                                            key: TunnelConstants.partNo,
                                            type: 'cube-text-only-type',
                                            templateOptions: {
                                              pre: 'Concio N.: ',
                                              post: 'Y',
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            className: 'defect-header-input-color',
                            fieldGroup: [
                              {
                                className: 'page-heading-image-only',
                                template: `<div class="defect-rina-logo">
                                      <img src="assets/rina/RINA-dark.png">
                                  </div>`,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        key: 'Scheda Ispezione Ponti di Livello 1 List1',
                        type: 'cube-ag-grid-table-type',
                        className: 'ag-theme-balham',
                        templateOptions: {
                          hasModelData: true,
                          height: '100%',
                          width: '100%',
                          gridOptions: {
                            defaultColDef: {
                              cellClass: [
                                'align-items-center',
                                'display-flex',
                                'justify-content-center',
                              ],
                            },
                            columnDefs: [
                              {
                                headerName: 'Codice difetto',
                                headerClass: 'header-no-child',
                                field: 'Codice difetto',
                                width: 100,
                                autoHeight: true,
                                colSpan: (params) => {
                                  if (
                                    params.data['Codice difetto'] ===
                                      TunnelConstants.eventualiNote ||
                                    params.data.sectionHeader
                                  ) {
                                    // have all Eventuali note and header columns width 2
                                    return 2;
                                  } else {
                                    return 1;
                                  }
                                },
                                cellClassRules: {
                                  'cell-pass': (params) => params.data.G >= 5,
                                  'cell-fail': (params) => params.data.G < 5,
                                  'section-header': (params) =>
                                    params.data.sectionHeader,
                                  'cell-wrapping': (params) =>
                                    params.data.sectionHeader,
                                },
                              },
                              {
                                headerName: 'Descrizione difetto',
                                headerClass: 'header-no-child',
                                field: 'Descrizione difetto',
                                minWidth: 200,
                                flex: 2,
                                autoHeight: true,
                                cellClass: [
                                  'cell-wrapping',
                                  'text-align-center',
                                ],
                                cellClassRules: {
                                  'cell-pass': (params) => params.data.G >= 5,
                                  'cell-fail': (params) => params.data.G < 5,
                                },
                              },
                              {
                                headerName: `A cura dell'ispettore`,
                                headerClass: 'border-bottom',
                                children: [
                                  {
                                    headerName: 'Estensione K1',
                                    headerClass: 'remove-border-top',
                                    children: [
                                      {
                                        headerClass: 'custom-radio-table',
                                        headerComponent:
                                          defectTableHeaderRendererComponent,
                                        headerComponentParams: {
                                          title: ['0.2', '0.5', '1'],
                                          sortable: false,
                                        },
                                        field: 'Estensione K1',
                                        cellRendererSelector: (params) => {
                                          if (
                                            params.data['Codice difetto'] ===
                                              TunnelConstants.eventualiNote ||
                                            params.data.sectionHeader
                                          ) {
                                            return null;
                                          }
                                          return {
                                            component:
                                              defectTableRadioButtonsRendererComponent,
                                            params: {},
                                          };
                                        },
                                        disabled: (params) =>
                                          params.data['Codice difetto'] ===
                                          TunnelConstants.eventualiNote
                                            ? !isDisabled
                                            : isDisabled,
                                        editable: (params) =>
                                          params.data['Codice difetto'] ===
                                          TunnelConstants.eventualiNote
                                            ? !isDisabled
                                            : isDisabled,
                                        width: 140,
                                        colSpan: (params) => {
                                          if (
                                            params.data['Codice difetto'] ===
                                              TunnelConstants.eventualiNote ||
                                            params.data.sectionHeader
                                          ) {
                                            return 17;
                                          }
                                          return 1;
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    headerName: 'Intensità K2',
                                    headerClass: 'remove-border-top',
                                    children: [
                                      {
                                        headerClass: 'custom-radio-table',
                                        headerComponent:
                                          defectTableHeaderRendererComponent,
                                        headerComponentParams: {
                                          title: ['0.2', '0.5', '1'],
                                          sortable: false,
                                        },
                                        field: 'Intensità K2',
                                        cellRenderer:
                                          defectTableRadioButtonsRendererComponent,
                                        disabled:
                                          tunnelInputTemplateComponent.isFieldDisabled(
                                            [],
                                            isDisabled
                                          ),
                                        width: 140,
                                      },
                                    ],
                                  },
                                  {
                                    headerName: 'N° foto',
                                    headerClass: 'header-no-child',
                                    field: 'N° foto',
                                    cellRenderer:
                                      defectTableLocalizzazioneRendererComponent,
                                    cellRendererParams: {
                                      acceptedFileTypes:
                                        environment.file_upload
                                          .accepted_file_types.images,
                                    },
                                    width: 70,
                                    modelData:
                                      tunnelInputTemplateComponent.model,
                                    token: tunnelInputTemplateComponent.token,
                                    componentType:
                                      TunnelConstants.levels.defect5,
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                  {
                                    headerName: 'NA',
                                    headerClass: 'header-no-child',
                                    field: 'NA',
                                    width: 40,
                                    cellRenderer:
                                      'defectTableCheckboxRendererComponent',
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                    onCellValueChanged: (params) => {
                                      if (
                                        params.oldValue !== params.newValue &&
                                        (params.data['Intensità K2'] != null ||
                                          params.data['Estensione K1'] != null)
                                      ) {
                                        params.data.NA = false;
                                        params.api.refreshCells({
                                          rowNodes: [params.node],
                                          columns: [params.column],
                                        });
                                        tunnelInputTemplateComponent.getConfirmation(
                                          {
                                            isCancelButtonVisible: false,
                                            message:
                                              'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                            title: 'Info',
                                          }
                                        );
                                      }
                                      params.data.NR = false;
                                      params.data.NP = false;
                                      params.api.refreshCells({
                                        rowNodes: [params.node],
                                      });
                                    },
                                  },
                                  {
                                    headerName: 'NR',
                                    headerClass: 'header-no-child',
                                    field: 'NR',
                                    width: 40,
                                    cellRenderer:
                                      'defectTableCheckboxRendererComponent',
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                    onCellValueChanged: (params) => {
                                      if (
                                        params.oldValue !== params.newValue &&
                                        (params.data['Intensità K2'] != null ||
                                          params.data['Estensione K1'] != null)
                                      ) {
                                        params.data.NR = false;
                                        params.api.refreshCells({
                                          rowNodes: [params.node],
                                          columns: [params.column],
                                        });
                                        tunnelInputTemplateComponent.getConfirmation(
                                          {
                                            isCancelButtonVisible: false,
                                            message:
                                              'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                            title: 'Info',
                                          }
                                        );
                                      }
                                      params.data.NA = false;
                                      params.data.NP = false;
                                      params.api.refreshCells({
                                        rowNodes: [params.node],
                                      });
                                    },
                                  },
                                  {
                                    headerName: 'NP',
                                    headerClass: 'header-no-child',
                                    field: 'NP',
                                    width: 40,
                                    cellRenderer:
                                      'defectTableCheckboxRendererComponent',
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                    onCellValueChanged: (params) => {
                                      if (
                                        params.oldValue !== params.newValue &&
                                        (params.data['Intensità K2'] != null ||
                                          params.data['Estensione K1'] != null)
                                      ) {
                                        params.data.NP = false;
                                        params.api.refreshCells({
                                          rowNodes: [params.node],
                                          columns: [params.column],
                                        });
                                        tunnelInputTemplateComponent.getConfirmation(
                                          {
                                            isCancelButtonVisible: false,
                                            message:
                                              'Cannot select NA, NR or NP values when K1 or K2 are selected.',
                                            title: 'Info',
                                          }
                                        );
                                      }
                                      params.data.NA = false;
                                      params.data.NR = false;
                                      params.api.refreshCells({
                                        rowNodes: [params.node],
                                      });
                                    },
                                  },
                                  {
                                    headerName: 'L',
                                    headerClass: 'header-no-child',
                                    field: 'L',
                                    width: 70,
                                    cellRenderer:
                                      defectTableLocalizzazioneRendererComponent,
                                    cellRendererParams: {
                                      acceptedFileTypes:
                                        environment.file_upload
                                          .accepted_file_types.images,
                                      disabled:
                                        tunnelInputTemplateComponent.isFieldDisabled(
                                          [],
                                          isDisabled
                                        ),
                                    },
                                    modelData:
                                      tunnelInputTemplateComponent.model,
                                    token: tunnelInputTemplateComponent.token,
                                    componentType:
                                      TunnelConstants.levels.defect5,
                                    cellClass: [
                                      'cell-wrapping',
                                      'cursor-pointer',
                                    ],
                                  },
                                  {
                                    headerName: 'Sup. Est. Dif. [m^2]',
                                    headerClass: 'header-no-child',
                                    field: 'Sup Est Dif',
                                    minWidth: 100,
                                    flex: 1,
                                    cellClass: ['remove-lines'],
                                    editable: !isDisabled,
                                  },
                                ],
                              },
                              {
                                headerName: 'Giudizio esperto',
                                headerClass: 'border-bottom',
                                children: [
                                  {
                                    headerName: '',
                                    headerClass: 'remove-border-top',
                                    children: [
                                      {
                                        headerName: 'G',
                                        headerClass: 'header-no-child',
                                        field: 'G',
                                        width: 40,
                                        editable:
                                          tunnelInputTemplateComponent.isFieldDisabled(
                                            [UserRole.auditor],
                                            tunnelInputTemplateComponent.model
                                              .status ===
                                              InspectionStatus.toReview
                                          ),
                                      },
                                    ],
                                  },
                                  {
                                    headerName:
                                      'Correlazione con altri difetti',
                                    headerClass: 'header-no-child',
                                    field: 'Correlazione con altri difetti',
                                    minWidth: 100,
                                    flex: 1,
                                    cellClass: ['remove-lines'],
                                    editable:
                                      userRole === UserRole.auditor &&
                                      tunnelInputTemplateComponent.model
                                        .status === InspectionStatus.toReview,
                                  },
                                  {
                                    headerName: 'PS g',
                                    headerClass: 'header-no-child',
                                    field: 'PS g',
                                    cellRenderer:
                                      'defectTableCheckboxRendererComponent',
                                    disabled:
                                      userRole !== UserRole.auditor ||
                                      tunnelInputTemplateComponent.model
                                        .status !== InspectionStatus.toReview,
                                    width: 40,
                                  },
                                  {
                                    headerName: 'PS I',
                                    headerClass: 'header-no-child',
                                    field: 'PS I',
                                    cellRenderer:
                                      'defectTableCheckboxRendererComponent',
                                    disabled:
                                      userRole !== UserRole.auditor ||
                                      tunnelInputTemplateComponent.model
                                        .status !== InspectionStatus.toReview,
                                    width: 40,
                                  },
                                ],
                              },
                              {
                                headerName: 'Note',
                                headerClass: 'header-no-child',
                                field: 'Note',
                                minWidth: 100,
                                autoHeight: true,
                                flex: 1,
                                cellClass: ['remove-lines'],
                                cellRenderer: defectTableNoteRendererComponent,
                                cellRendererParams: {
                                  mode: DefectNoteMode.tunnelDefect,
                                },
                                disabled:
                                  tunnelInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                              },
                            ],
                            components: {
                              defectTableCheckboxRendererComponent,
                              defectTableNoteRendererComponent,
                            },
                          },
                        },
                        fieldArray: {
                          fieldGroup: [
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Codice difetto',
                            },
                            {
                              type: 'cube-text-only-type',
                              defaultValue: '',
                              key: 'Descrizione difetto',
                            },
                            {
                              fieldGroup: [
                                {
                                  fieldGroup: [
                                    {
                                      key: 'Estensione K1',
                                      type: 'input',
                                      defaultValue: '',
                                      templateOptions: {
                                        disabled: true,
                                      },
                                    },
                                  ],
                                },
                                {
                                  fieldGroup: [
                                    {
                                      type: 'input',
                                      defaultValue: '',
                                      key: 'Intensità K2',
                                      templateOptions: {
                                        disabled: true,
                                      },
                                    },
                                  ],
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'N° foto',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'NA',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'NR',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'NP',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'L',
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'Sup Est Dif',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                              ],
                            },
                            {
                              fieldGroup: [
                                {
                                  type: 'cube-text-only-type',
                                  defaultValue: '',
                                  key: 'G',
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'Correlazione con altri difetti',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'PS g',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                                {
                                  type: 'input',
                                  defaultValue: '',
                                  key: 'PS I',
                                  templateOptions: {
                                    disabled:
                                      tunnelInputTemplateComponent.isFieldDisabled(
                                        [],
                                        isDisabled
                                      ),
                                  },
                                },
                              ],
                            },
                            {
                              type: 'textarea',
                              defaultValue: '',
                              key: 'Note',
                              templateOptions: {
                                disabled:
                                  tunnelInputTemplateComponent.isFieldDisabled(
                                    [],
                                    isDisabled
                                  ),
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
                hideExpression: `formState.${TunnelConstants.levels.defect5}`,
              },
            ],
          },
        ],
      },
    ],
  };
}
