import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { AppFeature } from '../../shared/configs/app-feature.config';
import { UserService } from '../../services/user/user.service';

@Directive({
  selector: '[cubeFeature]',
})
export class FeatureDirective implements OnInit {
  @Input() set appFeatureIsNotAllowed(isNotAllowed: boolean) {
    this.isNotAllowedMode = isNotAllowed;
  }
  @Input() set cubeFeature(featuresList: AppFeature[]) {
    this.featuresList = featuresList;
  }

  private featuresList!: AppFeature[];
  private isNotAllowedMode: boolean;

  constructor(
    private templateRef: TemplateRef<any>,
    private userService: UserService,
    private viewContainer: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.toggleTemplateVisibility();
  }

  private toggleTemplateVisibility(): void {
    if (this.isUserAllowedByFeature()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private isUserAllowedByFeature(): boolean {
    if (this.featuresList == null || this.featuresList.length === 0)
      return true;

    const isUserAllowedByFeature = this.userService.isUserAllowedByFeature(
      this.featuresList
    );

    return this.isNotAllowedMode
      ? !isUserAllowedByFeature
      : isUserAllowedByFeature;
  }
}
