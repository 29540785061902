<!-- Close Button -->
<button
  class="close-icon"
  tabindex="-1"
  type="button"
  mat-dialog-close
  mat-icon-button
>
  <mat-icon>close</mat-icon>
</button>

<!-- Title -->
<h5 mat-dialog-title>
  {{ "download_report_dialog.download_report" | translate }}
</h5>

<!-- Content -->
<mat-dialog-content>
  <div class="dialog-form">
    <p>
      {{ "download_report_dialog.download_report_description" | translate }}
    </p>

    <div class="report-details">
      <ng-container [ngSwitch]="reportMode">
        <!-- Single Report - Codice IOP -->
        <p *ngSwitchCase="reportModeConfig.single">
          <span>{{ "download_report_dialog.codice_iop" | translate }}: </span>
          {{ jobDetails.codiceIop }}
        </p>

        <!-- Periodic Report - Period -->
        <p *ngSwitchCase="reportModeConfig.periodic">
          <span>{{ "download_report_dialog.period" | translate }}: </span>
          {{ jobDetails.quarter + "/" + jobDetails.year }}
        </p>

        <!-- IOP Request CSV Report - Created Date -->
        <p *ngSwitchCase="reportModeConfig.iop_request">
          <span>{{ "download_report_dialog.created_date" | translate }}: </span>
          {{ jobDetails.createdDate | date : "dd-MM-yyyy hh:mm a" }}
        </p>
      </ng-container>

      <!-- Report Name -->
      <p>
        <span>{{ "download_report_dialog.report_name" | translate }}: </span>
        {{ jobDetails.reportName | translate }}
      </p>
    </div>

    <!-- Buttons -->
    <div class="action-buttons">
      <!-- Download -->
      <button
        type="button"
        mat-flat-button
        color="primary"
        (click)="onClickDownload()"
      >
        {{ "common.download" | translate }}
      </button>

      <!-- Cancel -->
      <button type="button" mat-dialog-close mat-flat-button color="warn">
        {{ "common.cancel" | translate }}
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
    </div>
  </div>
</mat-dialog-content>
