import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private readonly _storage = localStorage;

  addToStorage(key: string, value: any): void {
    this._storage.setItem(key, JSON.stringify(value));
  }

  getFromStorage(key: string): any {
    return JSON.parse(this._storage.getItem(key));
  }

  deleteFromStorage(key: string): any {
    this._storage.removeItem(key);
  }
}

export const STORAGE_KEYS = {
  activeRole: 'active-role',
  currentTenant: 'current-tenant',
  // currentWTGs: 'current-wtgs', // Not needed in BMS
  darkTheme: 'is-dark-theme',
  deletedFiles: 'deletedFiles',
  // excludedMap: 'excluded-map', // Not needed in BMS
  // invisibleWTGs: 'invisible-wtgs', // Not needed in BMS
  language: 'language',
  localTimeFormat: 'local_time_format',
  // mapBaseLayer: 'map-base-layer', // Not needed in BMS
  savedImages: 'savedImages',
  sub: 'sub',
  token: 'token',
  version: 'version',
};
