import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { StorageService, STORAGE_KEYS } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  defaultLanguage: string = 'en';
  languageChange$: Observable<LangChangeEvent> =
    this.translateService.onLangChange;

  constructor(
    private storageService: StorageService,
    private translateService: TranslateService
  ) {
    translateService.addLangs(['en', 'it']);
  }

  getLanguage(): string {
    return this.translateService.currentLang;
  }

  setDefaultLanguage(): void {
    const browserLang = this.translateService.getBrowserLang();
    const browserLangGet = /en|it/.exec(browserLang)
      ? browserLang
      : this.defaultLanguage;
    const defaultLanguage =
      this.storageService.getFromStorage(STORAGE_KEYS.language) ??
      browserLangGet;

    this.translateService.setDefaultLang(defaultLanguage);
    this.setLanguage(defaultLanguage);
  }

  setLanguage(language: string): void {
    this.translateService.use(language);
    this.storageService.addToStorage(STORAGE_KEYS.language, language);
  }
}
