import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ReportJobDetails } from '../../../models/report';
import { ReportService } from '../../../services/api/report/report.service';
import { ReportMode } from '../../configs/report-mode.config';

@Component({
  selector: 'cube-request-report-dialog',
  templateUrl: './request-report-dialog.component.html',
  styleUrls: ['./request-report-dialog.component.scss'],
})
export class RequestReportDialogComponent implements OnInit {
  reportStatusPageUrl: string;

  private jobDetails: Partial<ReportJobDetails>;

  constructor(
    @Inject(MAT_DIALOG_DATA) private matDialogData: any,
    private reportService: ReportService
  ) {
    this.jobDetails = this.matDialogData.jobDetails;
    this.reportStatusPageUrl = this.getReportStatusPageUrl();
  }

  ngOnInit(): void {
    this.addJobToReportWebWorker();
  }

  private addJobToReportWebWorker(): void {
    this.reportService.addJobToReportWebWorker(this.jobDetails);
  }

  private getReportStatusPageUrl(): string {
    const reportMode = this.reportService.getReportMode(
      this.jobDetails.reportType
    );

    switch (reportMode) {
      case ReportMode.iop_request:
        return 'bms-portal/iop-request/request-report-status';

      case ReportMode.periodic:
        return 'bms-portal/periodic-reporting/periodic-report-status';

      case ReportMode.single:
        return 'bms-portal/inspections/single-report-status';
    }
  }
}
