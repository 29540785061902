export const StructureConstants = {
  idbFile: '_imageName_',
  levels: {
    l0: 'l0',
    l1: 'l1',
    l2: 'l2',
  },
  sectionNo: 'sectionNo_',
  syncPathNo: 'syncPathNo',
  tenantStructure: '_tenantStructure_',
  webcamImage: 'image_capture_',
};
