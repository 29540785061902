export enum ImportWorkerMessageType {
  addAllOngoingImportJobs = 'addAllOngoingImportJobs',
  addJob = 'addJob',
  importCompleted = 'importCompleted',
  importFailed = 'importFailed',
  importOngoing = 'importOngoing',
  tokenUpdated = 'tokenUpdated',
  workerClosed = 'workerClosed',
  workerInitialization = 'workerInitialization',
}
